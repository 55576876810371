import * as React from "react"
import {View, SafeAreaView, ViewStyle} from "react-native"
import {FC, useState} from "react"
import {CommonActions, ParamListBase, RouteProp} from "@react-navigation/native"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Header, Text, TextField} from "../../../components"
import {color} from "../../../theme";
import {Button} from "../../../components"
import {NavigatorParamList} from "../../../navigators";
import {LoadingModal} from "../../../components/backdrop-modal/loading-modal";
import {useStores} from "../../../models";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";

export interface ResetPasswordScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'resetPasswordScreen'>
}

export const ResetPasswordScreen: FC<ResetPasswordScreenProps> = ({navigation, route}) => {
  const code = route.params?.code
  const {app} = useStores();
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const handleUpdatePassword = async () => {
    console.log("CODE=", code)
    if (!password || !confirmPassword) {
      AlertModalStore.alert('Error', 'Please enter require fields.')
      return
    }
    if (password !== confirmPassword) {
      AlertModalStore.alert('Error', 'Password mismatch.')
      return
    }
    if (password.length < 8) {
      AlertModalStore.alert('Error', 'Invalid password length.')
      return
    }
    if (!code) {
      AlertModalStore.alert('Error', 'Invalid token.')
      return
    }
    setLoading(true)
    try {
      const res = await app.environment.api.resetPassword(password, code);

      if (res.kind === 'ok') {
        navigation.navigate('resetPasswordResultScreen')
      } else {
        AlertModalStore.alert('Error', res.message || '')
      }

    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const goHome = () => {
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [
          {name: 'main'},
        ],
      })
    );
  }

  return (
    <>
      <SafeAreaView style={CONTAINER}>
        <Header
          preset={"transparent"}
          rightIcon={'times'} rightIconPreset="smallBlue" onRightPress={goHome}
        />
        <View style={{paddingHorizontal: 16, flex: 1, justifyContent: 'space-between'}}>
          <View>
            <Text preset={"h1Blue"}>Update your password</Text>
            <Text preset={"body1"} style={{
              marginTop: 16, marginBottom: 24
            }}>Please Update your password below. This will be your new password and you will be asked to enter new
              password on login.</Text>
            <TextField
              value={password} onChangeText={setPassword}
              placeholder="Minimum 8 characters"
              label="Password*"
              labelPreset="primary"
              secureTextEntry={true}
              rightIcon="lock"
            />
            <View style={{height: 20}}/>
            <TextField
              value={confirmPassword} onChangeText={setConfirmPassword}
              placeholder="Minimum 8 characters"
              label="Re-type Password*"
              labelPreset="primary"
              secureTextEntry={true}
              rightIcon="lock"
            />
          </View>
          <View style={BUTTON_CONTAINER}>
            <Button
              onPress={handleUpdatePassword}
              text="Update" preset="primary"/>
          </View>
        </View>
        <LoadingModal visible={loading}/>
      </SafeAreaView>
      <AlertModal/>
    </>
  )
}

const CONTAINER: ViewStyle = {
  flex: 1,
  backgroundColor: color.palette.white,
}

const BUTTON_CONTAINER: ViewStyle = {
  marginHorizontal: 12,
  marginVertical: 8,
}
