import { createNativeStackNavigator } from "@react-navigation/native-stack"
import React from "react"
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs"
import { TabBar } from "../../components/tab-bar/tab-bar"
import { SettingsScreen } from "../../screens/shared"
import { WorkerMessageScreen } from "../../screens/worker/messages/worker-message-screen";
import { WorkerHomeScreen, WalletScreen } from "../../screens/worker"
import { WorkerJobListScreen } from "../../screens/worker/jobs-list"

export type WorkerParamList = {
  tabNavigator: undefined

  homeScreen: undefined
  manageJobScreen: { activeTabId?: number }
  workerMessageScreen: undefined
  walletScreen: undefined
  settingScreen: undefined
}

const Tab = createBottomTabNavigator<WorkerParamList>()

const TabNavigator = () => (
  <Tab.Navigator
    screenOptions={{
      headerShown: false,
    }}
    tabBar={(props) => <TabBar {...props} />}
  >
    <Tab.Screen name={"homeScreen"} component={WorkerHomeScreen} options={{tabBarLabel: "Dashboard"}}/>
    <Tab.Screen name={"manageJobScreen"} component={WorkerJobListScreen} options={{tabBarLabel: "Job Ads"}}/>
    <Tab.Screen name={"workerMessageScreen"} component={WorkerMessageScreen} options={{tabBarLabel: "Messages"}}/>
    <Tab.Screen name={"walletScreen"} component={WalletScreen} options={{tabBarLabel: "Wallet"}}/>
    <Tab.Screen name={"settingScreen"} component={SettingsScreen} options={{tabBarLabel: "Settings"}}/>
  </Tab.Navigator>
)

const WorkerStack = createNativeStackNavigator<WorkerParamList>()

export function WorkerNavigator() {
  return (
    <WorkerStack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName={"tabNavigator"}
    >
      <WorkerStack.Screen name="tabNavigator" component={TabNavigator}/>
    </WorkerStack.Navigator>
  )
}
