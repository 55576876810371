import * as React from "react"
import {View, ViewStyle} from "react-native";
import {SvgIconProps} from "../icon/svg-icon.props";
import {color} from "../../theme";
import {SvgIcon} from "../icon/svg-icon";


export interface AvatarImageProps extends SvgIconProps {
  px?: number
  py?: number
  dot?: boolean
  containerStyle?: ViewStyle
}

const DOT_STYLE: ViewStyle = {
  width: 10,
  height: 10,
  backgroundColor: color.palette.red,
  borderRadius: 10,
  borderWidth: 2,
  borderColor: color.palette.white,
  position: 'absolute',
  top: 8,
  right: 8,
}
export const AvatarSvg = (props: AvatarImageProps) => {
  const {containerStyle, dot, px, py, ...iconProps} = props

  return (
    <View style={[{
      position: 'relative',
      paddingHorizontal: px ?? 10, paddingVertical: py ?? 10
    }, containerStyle]}>
      <SvgIcon {...iconProps}/>
      {dot && <View style={DOT_STYLE}/>}
    </View>
  )
}
