import React, { FC, useCallback, useState } from 'react';
import { View, FlatList, StyleSheet, SafeAreaView } from 'react-native';

import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { ParamListBase, useFocusEffect } from "@react-navigation/native";
import { observer } from "mobx-react-lite";
import { ContactItem } from "./contacts-item";
import { useStores } from "../../../models";
import { CONTACT_STATUS, IBusinessContactModel } from "../../../models/app/contact";
import { constants, DEFAULT_LIMIT } from "../../../utils/constants";

export interface ContactListScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  listItem: Array<IBusinessContactModel>
  setListItem: (listItem: Array<IBusinessContactModel>) => void
}

export const ContactListScreen: FC<ContactListScreenProps> = observer(
  ({navigation, listItem, setListItem}) => {
    const {app} = useStores();
    const [refreshing, setRefreshing] = useState(false)

    const loadData = async () => {
      try {
        setRefreshing(true)
        const isManager = app?.user?.role === constants.roles.MANAGER
        const res = await app.environment.api.getContacts({
          'filters[businessProfile][id][$eq]': isManager
            ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          'businessProfileId': isManager
            ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          'filters[status][$ne]': CONTACT_STATUS.rejected.key,
          "pagination[pageSize]": DEFAULT_LIMIT
        })
        if (res.kind === 'ok') {
          setListItem(res.items)
        }
      } catch (e) {
        console.log(e)
      } finally {
        setRefreshing(false)
      }
    }

    useFocusEffect(useCallback(() => {
      loadData()
    }, []))

    const renderItem = ({item}) => <ContactItem item={item} navigation={navigation}/>

    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.mainContainer}>
          <FlatList
            contentContainerStyle={styles.contentContainerStyle}
            onRefresh={loadData} refreshing={refreshing}
            data={listItem}
            renderItem={renderItem}
            keyExtractor={(item, index) => index.toString()}
            ListFooterComponent={<View style={{height: 200}}/>}
          />
        </View>
      </SafeAreaView>
    )
  },
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  mainContainer: {
    flex: 1
  },
  contentContainerStyle: {
    paddingHorizontal: 10,
    marginVertical: 20,
  },
  modalBtn: {
    marginVertical: 8
  },
});
