import React, { FC, useCallback, useRef, useState } from "react"
import {SafeAreaView, ScrollView, TouchableOpacity, View, ViewStyle} from "react-native"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {observer} from "mobx-react-lite"
import {Header, Text, GradientBackground} from "../../../components"
import {color} from "../../../theme"
import {CommonActions, ParamListBase, RouteProp, useFocusEffect} from "@react-navigation/native"
import {SearchBar} from "../../../components/search-bar/search-bar"
import {AvailableJobTab} from "./available-jobs"
import {MyApplicationTab} from "./my-applications"
import {NavigatorParamList} from "../../../navigators";
import {useStores} from "../../../models";
import { constants, DEFAULT_LIMIT } from "../../../utils/constants";
import {IJobRoleAdModel} from "../../../models/app/job-role-ad";
import {ApplicationStatus, IWorkerApplicationModel} from "../../../models/app/application";
import {BackdropModal} from "../../../components/backdrop-modal/backdrop-modal";
import {SvgIcon} from "../../../components/icon/svg-icon";
import {IJobRoleBookmarkModel} from "../../../models/app/job-role";
import moment from "moment";
import {IUserNotificationModel} from "../../../models/app/user-account";
import { JobInvites } from "./job-invites"
import TabButton from "../../../components/tab-bar/tab-button"

export interface WorkerJobListScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'jobListScreen'>
}

export const WorkerJobListScreenTabs = {
  job: {key: 1, label: "Jobs"},
  application: {key: 2, label: "Applications"},
  invites: {key: 4, label: "Invites"},
  pinned: {key: 3, label: "Saved"},
}

export const WorkerJobListScreenTags = {
  responses: {key: 1, label: "New Responses", selectLabel: 'Responses'},
  newJobs: {key: 2, label: "New Jobs", selectLabel: "New Jobs"},
  unread: {key: 3, label: "Unread", selectLabel: "Unread"},
}

export const WorkerJobListScreen: FC<WorkerJobListScreenProps> = observer(({navigation, route}) => {
  const activeTabId = route.params?.activeTabId
  const {app} = useStores()
  const activeTab = app.tabStore.workerJobListScreenTab || (WorkerJobListScreenTabs.job.key)
  const jobTabs = ([constants.roles.BUSINESS, constants.roles.MANAGER].includes(app.user.role))
    ? [] : Object.values(WorkerJobListScreenTabs)
  const [search, setSearch] = useState("")
  const [isLoadMyApplication, setIsLoadMyApplication] = useState(false)
  const [isLoadAvailableJob, setIsLoadAvailableJob] = useState(false)
  const [jobRoleBookmarks, setJobRoleBookmarks] = useState<Array<IJobRoleBookmarkModel>>([])
  const [jobRoles, setJobRoles] = useState<Array<IJobRoleAdModel>>([])
  const [jobInvites, setJobInvites] = useState<Array<IJobRoleAdModel>>([])
  const [isLoadInvites, setIsLoadInvites] = useState(false);
  const [jobApplications, setJobApplications] = useState<Array<IWorkerApplicationModel>>([])
  const [visibleModal, setVisibleModal] = useState(false)
  const isShowEmpty = useRef(false);

  useFocusEffect(useCallback(() => {
    if (app?.hasSearchJobRole) setSearch(app?.searchJobRoleText)
    app?.setHasSearchJobRole(false)
  }, [activeTabId]))

  useFocusEffect(useCallback(() => {
    if (app.manageJobScreenFilter) {
      // setTimeout(() => {
      //   app?.setFilterJobRoleText(search)
      //   navigation.navigate('searchScreen')
      // }, 100)
      app?.setManageJobScreenFilter(false)
      app?.setFilterJobRoleText(search)
      navigation.navigate('searchScreen')
    }
  }, [app.manageJobScreenFilter]))

  const loadMyApplicationData = async () => {
    if (isLoadMyApplication) return
    setIsLoadMyApplication(true)
    try {
      let notification: IUserNotificationModel = null
      let showUnread = WorkerJobListScreenTags.responses.key === app.filterStore.workerJobListFilterId
      const notificationRes = await app.environment.api.getNotification()
      if (notificationRes.kind === 'ok') notification = notificationRes.item
      if (showUnread && notification?.responses?.jobRoles?.length <= 0) {
        setJobApplications([])
        return
      }

      const res = await app.environment.api.getWorkerApplications({
        "filters[workerProfile][id][$eq]": app?.userAccount?.workerProfile?.id,
        "filters[status][$ne]": ApplicationStatus.viewing,
        ...(showUnread ? {'filters[jobRole][id][$eq]': notification?.responses?.jobRoles} : null),
        "pagination[pageSize]": DEFAULT_LIMIT
      })
      if (res.kind === 'ok') {
        setJobApplications(res.applications?.map(it => ({
          ...it,
          jobRole: {
            ...it.jobRole,
            // newChatMessages: it?.newChatMessages,
            newChatMessages: notification?.responses?.jobRoles?.includes(it?.jobRole?.id) ? 1 : 0
          }
        })))
      }
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoadMyApplication(false)
    }
  }

  const loadAvailableJobData = async () => {
    if (isLoadAvailableJob) return
    setIsLoadAvailableJob(true)
    try {
      const bookmarkRes = await app.environment.api.getJobRoleBookmark({
        "filters[user][id][$eq]": app?.userAccount?.id,
        "pagination[pageSize]": DEFAULT_LIMIT
      })
      const _jobRoleBookmarks = bookmarkRes.kind === 'ok' ? bookmarkRes.items : []
      if (bookmarkRes.kind === 'ok') setJobRoleBookmarks(_jobRoleBookmarks)
      const filterId = app.filterStore.workerJobListFilterId
      const res = await app.environment.api.getJobRoleAds({
        "filters[jobAd][isDraft][$eq]": false,
        "filters[jobAd][closed][$eq]": false,
        ...(filterId === WorkerJobListScreenTags.newJobs.key
          ? {"filters[createdAt][$gte]": moment().subtract(1, 'days').toISOString()} : null),
        "pagination[pageSize]": DEFAULT_LIMIT
      })
      if (res.kind === 'ok') {
        setJobRoles(res.jobRoles?.map(it => ({...it, newChatMessages: 0})))
      }
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoadAvailableJob(false)
    }
  }

  const loadListInvites = async () => {
    if (isLoadInvites) return
    isShowEmpty.current = false
    setIsLoadInvites(true)
    try{
      const res = await app.environment.api.getListInvites({
        "pagination[pageSize]": DEFAULT_LIMIT,
        "filters[status]": ApplicationStatus.pending,
      });
      if (res.kind === 'ok') {
        setJobInvites(res.invites)
      }
    }catch (e){
      console.log('error get list job invite', e)
    } finally {
      isShowEmpty.current = true
      setIsLoadInvites(false)
    }

  }

  useFocusEffect(useCallback(() => {
    isShowEmpty.current = false;
    if (activeTab === WorkerJobListScreenTabs.application.key) loadMyApplicationData()
    else if (activeTab === WorkerJobListScreenTabs.job.key) loadAvailableJobData()
    else if(activeTab === WorkerJobListScreenTabs.invites.key) loadListInvites()
    else if (activeTab === WorkerJobListScreenTabs.pinned.key) loadAvailableJobData()
  }, [activeTab]))

  const handleMyApplicationPress = (item: IWorkerApplicationModel) => {
    if (!item.status && item.workerBookmark) {
      navigation.navigate('jobsAdDetailScreen', {id: item?.jobRole?.id})
      return
    }
    navigation.navigate("userApplicantMessageScreen", {
      toUserId: item?.jobRole?.jobAd?.owner?.id, applicationId: item?.id,
    })
  }

  const toggleBookmark = async (jobRoleId: number) => {
    const item = jobRoleBookmarks.find(it => it?.jobRole?.id === jobRoleId)
    if (!item || !item.id) {
      await app.environment.api.createJobRoleBookmark({
        user: app?.userAccount?.id,
        jobRole: jobRoleId,
      })
    } else {
      await app.environment.api.deleteJobRoleBookmark(item?.id)
    }
    loadAvailableJobData()
  }

  let _jobRoles = app.user.role !== constants.roles.WORKER
    ? jobRoles
    : jobRoles.filter(it => !jobApplications.find(_it => _it.jobRole.id === it.id))

  if (activeTab === WorkerJobListScreenTabs.pinned.key) _jobRoles = _jobRoles?.filter(it => (
    jobRoleBookmarks?.find(_it => _it?.jobRole?.id === it?.id)
  ))

  const renderTab = () => {
    if (activeTab === WorkerJobListScreenTabs.application.key) {
      return <MyApplicationTab
        jobApplications={jobApplications}
        search={search}
        refreshing={isLoadMyApplication} loadData={loadMyApplicationData}
        onLogoPress={(id) => navigation.navigate('businessProfileScreen', {businessProfileId: id})}
        onPress={handleMyApplicationPress}
      />
    } else if(activeTab === WorkerJobListScreenTabs.invites.key){
      return (
        <>
          {(isShowEmpty.current && jobInvites.length === 0 && !isLoadInvites)? <View style={{width: '100%', justifyContent: 'center', alignItems: 'center'}}>
            <Text preset={'smallPrimary'}>No Job Invites</Text>
          </View>: <JobInvites
            jobInvites={jobInvites}
            refreshing={isLoadInvites} loadData={loadListInvites}
            onPress={(item) => navigation.navigate('jobsAdInvitesDetailScreen', {id: item.id})}
          />}
        </>

      )
    }
    else {
      return <AvailableJobTab
        jobRoleBookmarks={jobRoleBookmarks} toggleBookmark={toggleBookmark}
        jobRoles={_jobRoles}
        search={search}
        refreshing={isLoadAvailableJob} loadData={loadAvailableJobData}
        onLogoPress={(id) => navigation.navigate('businessProfileScreen', {businessProfileId: id})}
        onPress={(item) => navigation.navigate('jobsAdDetailScreen', {id: item.id})}/>
    }
  }

  const handleFilterPress = async () => {
    setVisibleModal(true)
  }

  const handleTabPress = (key: number) => {
    if (key === WorkerJobListScreenTabs.application.key && app.user.role !== constants.roles.WORKER) {
      if (!app.user.role || app.user.role === constants.roles.GUEST) {
        navigation.navigate('signInEmailCheckScreen')
      } else if (!app.userAccount.userRoles.find(it => it.key === constants.roles.WORKER)) {
        navigation.navigate('createWorkerProfileScreen')
      }
      return
    }
    app.tabStore.setWorkerJobListScreenTab(key)
  }

  const goHome = () => {
    navigation.dispatch(
      CommonActions.reset({
        index: 0, routes: [{name: 'main'}],
      })
    );
  }

  const renderTabItem = (it) => {
    const isActive = it.key === activeTab
    if (it.key === WorkerJobListScreenTabs.pinned.key) {
      return (
        <TouchableOpacity onPress={() => handleTabPress(it.key)} key={it.key} style={{justifyContent: 'center', height: 50}}>
          <View style={isActive ? ACTIVE_TAB : INACTIVE_TAB}>
            <SvgIcon preset={!isActive ? 'tabIcon' : 'secondary'} theme={'regular'} icon={'bookmark'}/>
          </View>
        </TouchableOpacity>
      )
    }
    return (
      <TabButton
        isActive={isActive}
        value={it.label}
        key={it.key}
        onPress={() => handleTabPress(it.key)}/>
    )
  }

  const renderFilterItem = (it) => {
    return <View key={it.key} style={{marginRight: 8}}>
      <TouchableOpacity onPress={() => handleSelectKey(it.key)}>
        <View style={{
          flexDirection: 'row', alignItems: 'center',
          backgroundColor: color.palette.greyLight,
          paddingHorizontal: 12,
          paddingVertical: 11,
          borderRadius: 100
        }}>
          <Text preset={'body'} style={{color: color.palette.blue, fontWeight: '400'}}>
            {it.label}
          </Text>
          <View style={{paddingLeft: 8}}>
            <SvgIcon preset={'primary'} icon={'times-circle-dark'}/>
          </View>
        </View>
      </TouchableOpacity>
    </View>

  }

  const handleSelectKey = (key: number) => {
    app.filterStore.setWorkerJobListFilterId(app.filterStore.workerJobListFilterId === key ? null : key)
    if (activeTab === WorkerJobListScreenTabs.application.key) loadMyApplicationData()
    else if (activeTab === WorkerJobListScreenTabs.job.key) loadAvailableJobData()
    else if (activeTab === WorkerJobListScreenTabs.pinned.key) loadAvailableJobData()
  }

  const workerJobListScreenTags = activeTab === WorkerJobListScreenTabs.application.key
    ? [WorkerJobListScreenTags.responses]
    : [WorkerJobListScreenTags.newJobs, WorkerJobListScreenTags.unread,]
  return (
    <SafeAreaView style={CONTAINER}>
      <GradientBackground colors={[color.palette.gradientStart, color.palette.gradientStop]}/>
      <Header
        {...({
          leftIcon: 'arrow-left', leftIconPreset: 'primary', onLeftPress: goHome
        })}
        titlePreset={"headerTitle"} headerText={"Job Adverts"} preset={"primary"}/>
      <View>
        <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
          <View style={{
            flexDirection: "row", marginHorizontal: 20, marginVertical: 20,
            alignItems: 'flex-end', justifyContent: 'flex-start'
          }}>
            {jobTabs.map(renderTabItem)}
          </View>
        </ScrollView>
      </View>
      {activeTab !== WorkerJobListScreenTabs.invites.key &&  <SearchBar
        value={search} onChangeText={setSearch}
        onTouchStart={() => {
          app?.setFilterJobRoleText(search)
          navigation.navigate('searchScreen')
        }}
        // onFilterPress={() => navigation.navigate('jobAdvertFilterScreen')}
        onFilterPress={handleFilterPress}
      />}

      <View style={{
        flexDirection: "row", marginHorizontal: 20,
        marginTop: 8, marginBottom: !!app.filterStore.workerJobListFilterId ? 20 : 0,
        alignItems: 'flex-end', justifyContent: 'flex-start'
      }}>
        {Object.values(workerJobListScreenTags).filter(item => app.filterStore.workerJobListFilterId === item.key)
          .map(renderFilterItem)}
      </View>
      {renderTab()}
      <BackdropModal preset={'zeroPad'} visible={visibleModal} onClose={() => setVisibleModal(!visibleModal)}>
        <View style={{paddingTop: 20, paddingBottom: 40}}>
          <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
            <Text preset={"h1"} text={'Filters'} color={color.palette.blue}/>
            <SvgIcon onPress={() => setVisibleModal(!visibleModal)} icon={'times'} preset={'normal'}/>
          </View>
          <View style={{marginTop: 65}}>
            {Object.values(workerJobListScreenTags).map((item) => (
              <TouchableOpacity
                key={item?.key}
                onPress={() => handleSelectKey(item.key)}
                style={{marginBottom: 26, alignItems: 'center'}}>
                <Text preset={"h1"} text={item.selectLabel} color={color.palette.blue}/>
              </TouchableOpacity>
            ))}
          </View>
        </View>
      </BackdropModal>
    </SafeAreaView>
  )
})

const CONTAINER: ViewStyle = {
  flex: 1,
}
const BASE_TAB: ViewStyle = {
  alignItems: "center",
  justifyContent: "center",
  paddingHorizontal: 2,
}
const ACTIVE_TAB: ViewStyle = {
  ...BASE_TAB,
  borderWidth: 3,
  borderColor: color.palette.secondary,
  borderRadius: 22,
  paddingHorizontal: 10,
  paddingVertical: 8
}
const INACTIVE_TAB: ViewStyle = {
  ...BASE_TAB,
  borderWidth: 1,
  borderColor: color.palette.blue,
  borderRadius: 22,
  paddingHorizontal: 10,
  paddingVertical: 8
}
