import React from 'react';
import {StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle} from 'react-native';
import {SvgIcon} from '../icon/svg-icon';
import {Text} from "../"
import {color} from '../../theme';

export interface JobTagRowProps {
  text: string
  onPress?: () => void
  onItemPress?: () => void
  numberOfLines?: number
  style?: StyleProp<ViewStyle>
  tagStyle?: StyleProp<ViewStyle>
}

export function JobTagRow(props: JobTagRowProps) {
  const {text, onPress, onItemPress, numberOfLines, style, tagStyle} = props
  return (
    <View style={[styles.tagWrapper, style]}>
      <View style={[styles.tagContainer, tagStyle]}>
        <TouchableOpacity onPress={onPress} style={styles.tagDeleteBtn}>
          <SvgIcon icon="times" preset={"dark"}/>
        </TouchableOpacity>
        <TouchableOpacity onPress={onItemPress} style={{maxWidth: '90%'}}>
          <Text {...(numberOfLines ? {numberOfLines} : null)} text={text}/>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  tagWrapper: {alignItems: 'flex-start', marginRight: 8},
  tagContainer: {
    marginVertical: 10, backgroundColor: color.palette.greyLight, padding: 3, paddingRight: 12, borderRadius: 6,
    flexDirection: "row", alignItems: "center"
  },
  tagDeleteBtn: {backgroundColor: color.palette.white, padding: 5, marginRight: 8, borderRadius: 5,},
})
