import * as React from "react"
import {View, SafeAreaView, ScrollView, ViewStyle} from "react-native"
import {FC, useState} from "react"
import {CommonActions, ParamListBase, RouteProp} from "@react-navigation/native"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Text,Header,Button} from "../../../components"
import {color, spacing} from "../../../theme";


import {SvgIcon} from "../../../components/icon/svg-icon";
import {useStores} from "../../../models";
import {CONTACT_STATUS} from "../../../models/app/contact";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {NavigatorParamList} from "../../../navigators";
import {BackdropModal} from "../../../components/backdrop-modal/backdrop-modal";
import {MODAL, VIEW_MODAL} from "../job-ad/styles";
import {BUSINESS_ACCESS_STATUS} from "../../../models/app/business-access";

export interface ChooseAccessInviteScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'chooseAccessInviteScreen'>
}

let timeoutVal: NodeJS.Timeout = null

export const ChooseAccessInviteScreen: FC<ChooseAccessInviteScreenProps> = ({navigation, route}) => {
  const businessId = route.params?.businessId || 0
  const code = route.params?.code || ''
  const {app} = useStores()
  const [resultVisible, setResultVisible] = useState(false);

  const goHome = async () => {
    navigation.dispatch(
      CommonActions.reset({index: 0, routes: [{name: 'main'}],})
    );
  }

  const handleCloseResult = async () => {
    if (timeoutVal) clearTimeout(timeoutVal)
    setResultVisible(false)
    goHome()
  }

  const showResult = () => {
    if (timeoutVal) clearTimeout(timeoutVal)
    setResultVisible(true)
    timeoutVal = setTimeout(handleCloseResult, 1500)
  }

  const handleAction = async (accept: boolean) => {
    const res = await app?.environment?.api?.updateBusinessManager(
      businessId,
      {code, status: accept ? BUSINESS_ACCESS_STATUS.accepted.key : CONTACT_STATUS.rejected.key}
    )
    if (res.kind !== 'ok') {
      AlertModalStore.alert('Error', res.message)
    } else {
      window.location.href = `${process.env.REACT_APP_SCHEMES}main`
      showResult()
    }
  }

  return (
    <SafeAreaView style={CONTAINER}>
      <Header
        leftIcon={
          () => <SvgIcon icon="arrow-left" preset="normal" onPress={() => navigation.goBack()}/>
        }
        preset={"bgWhite"}
      />
      <ScrollView style={BODY}>
        <Text preset={"headerTitle"}>Business Manager Invitation</Text>
        <Text preset={"body1"} style={{
          marginTop: spacing[3],
        }}>Accept Business Manager Invitation</Text>

        <View style={{height: spacing[7]}}/>

        <View style={BUTTON_CONTAINER}>
          <Button
            text="Decline" preset="primary"
            onPress={() => handleAction(false)}
          />
        </View>
        <View style={BUTTON_CONTAINER}>
          <Button
            text="Accept" preset="primary"
            onPress={() => handleAction(true)}
          />
        </View>
      </ScrollView>
      <BackdropModal visible={resultVisible} onClose={handleCloseResult}>
        <View style={MODAL}>
          <SvgIcon icon={"check-circle"} preset={"alertIcon"}/>
          <View style={VIEW_MODAL}>
            <Text preset={"alertTitle"}>Done</Text>
          </View>
        </View>
      </BackdropModal>
    </SafeAreaView>
  )
}

export const CONTAINER: ViewStyle = {
  flex: 1,
  backgroundColor: color.palette.white,
}

export const BODY: ViewStyle = {
  paddingHorizontal: spacing[4],
}

export const BUTTON_CONTAINER: ViewStyle = {
  marginHorizontal: spacing[3],
  marginVertical: spacing[2],
}
