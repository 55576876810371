import React, {FC, useCallback, useState} from 'react';
import {View, FlatList, StyleSheet, SafeAreaView} from 'react-native';

import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {observer} from "mobx-react-lite";
import {UserMessage} from "../../../components/user-message/user-message";
import {color} from "../../../theme";
import { Button, GradientBackground, Header, Text } from "../../../components"
import {ParamListBase, RouteProp, useFocusEffect} from "@react-navigation/native";
import {useStores} from "../../../models";
import {IBusinessContactModel} from "../../../models/app/contact";
import {stringToDateStr} from "../../../utils/utils";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {LoadingModal} from "../../../components/backdrop-modal/loading-modal";
import {NavigatorParamList} from "../../../navigators";
import {IUserMessageModel} from "../../../models/app/message";
import { BottomModal } from "../../../components/backdrop-modal/bottom-modal"
import BulkMessageContactsDelete from "./bulk-message-contacts-delete"
import { PureBottomModal } from "../../../components/backdrop-modal/pure-bottom-modal";
import { AlertModal } from "../../../components/backdrop-modal/alert-modal";
import { TrackEvents, trackWithUserAccount } from "../../../services/track";

export interface BusinessMessageScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'businessMessageScreen'>
}

export const BusinessMessageScreen: FC<BusinessMessageScreenProps> = observer(
  ({navigation, route}) => {
    // const groupId = route.params?.groupId
    const messageId = route.params?.messageId

    const [refreshing, setRefreshing] = useState(false)
    const {app} = useStores()
    // const isManager = app?.user?.role === constants.roles.MANAGER
    const [data, setData] = useState<IUserMessageModel>()
    const filteredContacts = data?.group?.contacts?.filter(item => (
      !data?.group?.hideContacts?.find(hit => hit?.id === item?.id)
    )) || []
    const [menuBulkVisible, setMenuBulkVisible] = useState(false)
    const [isShowContactsDelete, setShowContactsDelete] = useState(false)

    const loadData = async () => {
      setRefreshing(true)
      try {
        const res = await app.environment.api.getMessageDetail(messageId, {})
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        setData(res.item)
      } catch (e) {
        console.log(e)
      } finally {
        setRefreshing(false)
      }
    }

    useFocusEffect(useCallback(() => {
      if (messageId) loadData()
    }, [messageId]))

    const onRightPress = () => {
      setMenuBulkVisible(true)
    }

    const createBulkMsg = () => {
      setMenuBulkVisible(false)
      setMenuBulkVisible(false)
      // navigation.navigate('bulkMessageScreen')
      app.setListSelectedContact([])
      navigation.navigate('groupNewMessageScreen', {
        groupId: data?.group?.id
      })
      trackWithUserAccount(TrackEvents.CreatedGroup, app?.userAccount)
    }

    const handleCloseContactsDelete = async (isRecall: boolean, isBack?: boolean) => {
      setShowContactsDelete(false);
      if(isBack){
        navigation.goBack()
      }
      if(isRecall){
        await loadData()
      }
    }

    const handleShowModalDeleteContacts = () => {
      setMenuBulkVisible(false)
      setShowContactsDelete(true)
    };


    const renderItem = ({item}: { item: IBusinessContactModel }) => {
      const name = (!item?.user?.lastName && !item?.user?.firstName)
        ? item?.user?.email :
        [item?.user?.firstName, item?.user?.lastName]?.join(' ')?.trim()
      return (
        <UserMessage
          onLogoPress={() => navigation.navigate("workerProfileScreen", {id: item?.user?.id})}
          avatar={item?.user?.avatar?.url}
          dot={!!item?.newChatMessages}
          title={name || ''}
          subTitle={item?.previewChatMessage || ''}
          time={stringToDateStr(item?.lastSentDate || item?.createdAt)}
          onPress={() => navigation.navigate('businessNewMessageScreen', {
            messageId: messageId,
            toUserId: item?.user?.id
          })}/>
      );
    }

    const bulkMsgInfo = (
      <View style={styles.infoBulk}>
        <Text preset="textName" style={{fontWeight: 'bold'}}>
          {data?.title}
        </Text>
        <View style={styles.txtContent}>
          <Text preset="body2" style={{color: color.palette.lightGrey, fontStyle: 'italic'}}>
            {data?.content}
          </Text>
        </View>
      </View>
    )

    return (
      <>
        <PureBottomModal onClose={() => navigation.goBack()}>
          <SafeAreaView style={styles.container}>
            <GradientBackground colors={[color.palette.gradientStart, color.palette.gradientStop]}/>
            <View style={styles.mainContainer}>
              <Header
                preset={"modalHeader"}
                titlePreset={"headerTitle"}
                headerText={data?.group?.name || data?.title}
                leftIcon={'arrow-left'}
                leftIconPreset={'normal'}
                onLeftPress={() => navigation.goBack()}
                rightIcon="ellipsis-h"
                rightIconTheme="solid"
                rightIconPreset="smallBrand"
                onRightPress={onRightPress}
              />
              {bulkMsgInfo}
              <FlatList
                contentContainerStyle={styles.contentContainerStyle}
                data={filteredContacts}
                renderItem={renderItem}
                keyExtractor={(item, index) => String(item.id + '' + index)}
                ListFooterComponent={<View style={{height: 50}}/>}
              />
            </View>
          </SafeAreaView>
        </PureBottomModal>
        {isShowContactsDelete &&
        <BulkMessageContactsDelete
          isOpen={isShowContactsDelete}
          data={filteredContacts}
          onClose={handleCloseContactsDelete}
          nameGroup={data?.group?.name || data?.title}
          idBulk={data?.group?.id}
        />
        }
        <BottomModal visible={menuBulkVisible} onClose={() => setMenuBulkVisible(false)}>
          <View>
            <Button
              preset={'primary'}
              text={'Create New Bulk Message'}
              onPress={createBulkMsg}
            />
            <Button
              preset={'brand'}
              text={'Delete Bulk Message(s)'}
              style={{marginTop: 14}}
              onPress={handleShowModalDeleteContacts}
            />
            <Button
              onPress={() => setMenuBulkVisible(false)}
              style={{marginTop: 14}}
              preset={'primaryOutLine'}
              text={'Cancel'}
            />
          </View>
        </BottomModal>
        <LoadingModal visible={refreshing}/>
        <AlertModal/>
      </>
    )
  },
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainerStyle: {
    marginVertical: 20,
    paddingHorizontal: 10,
  },
  mainContainer: {
    flex: 1,
  },
  statusBar: {
  },
  infoBulk:{
    justifyContent: 'center',
    flexDirection: 'column',
    paddingHorizontal: 20
  },
  txtContent:{
    marginTop: 10,
    borderRadius: 10,
    borderColor: '#D9D9D9',
    borderWidth: 1,
    paddingHorizontal: 10,
    paddingVertical: 10,
    minHeight: 50
  }
});
