import React, {FC, useCallback, useState} from 'react';
import {View, ScrollView} from 'react-native';

import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {useFocusEffect} from "@react-navigation/native";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../models";
import {Button, Header, Text, TextField} from "../../../components";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import {AwSelectButton} from "../../../components/button/aw-select-button";
import {color} from "../../../theme";
import {isAndroid} from "../../../utils/platform";
import {BusinessUserParamList} from "../../../navigators/app/business-user-navigator";
import UserCard from "../../../components/card/user-card";
import {TypeUserModel} from "../../../models/app/kv-item";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {constants} from "../../../utils/constants";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";
import {NavigatorParamList} from "../../../navigators";

export interface CreateGroupChatScreenProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
}

export const CreateGroupChatScreen: FC<CreateGroupChatScreenProps> = observer(
  ({navigation}) => {
    // @ts-ignore
    const {app} = useStores();
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState('')
    const [currentHeight, setCurrentHeight] = useState(0)
    const listSelectedContact = app?.getListSelectedContact();

    const loadData = async () => {
      try {
        if (loading) return
        setLoading(true)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    const toggleSelectItem = (id: number) => {
      app.setListSelectedContact(listSelectedContact.map((item) => ({
        ...item, checked: item.id === id ? !item.checked : item.checked
      } as TypeUserModel)))
    }

    const handleCreateNewGroup = async () => {
      if (loading) return
      try {
        setLoading(true)
        const isManager = app?.user?.role === constants.roles.MANAGER
        const res = await app.environment.api.createNewGroup({
          isChatGroup: true,
          name: name, contacts: listSelectedContact.filter(it => it.checked).map(it => it.id),
          businessProfile: isManager ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          businessProfileId: isManager ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        navigation.popToTop()
        navigation.navigate('businessGroupChatScreen', {groupId: res?.item?.id, groupName: res?.item?.name})
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    const onClose = () => {
      app.setListSelectedContact([])
      navigation.goBack()
    }

    useFocusEffect(useCallback(() => {
      loadData()
    }, []))

    const disableBtnDone = !name || listSelectedContact.length < 1
    return (
      <>
        <PureBottomModal onClose={onClose}>
          <View style={{flex: 1}}>
            <View>
              <Header
                preset={'modalHeader'}
                headerText={'Create Group Chat'} titlePreset={'headerTitle'}
                rightIcon={'times'} rightIconPreset={'smallBlue'} onRightPress={onClose}
              />
            </View>
            <View
              onLayout={event => setCurrentHeight(event.nativeEvent.layout?.height)}
              style={[
                isAndroid && currentHeight > 0 ? {height: currentHeight} : {flex: 1},
                {paddingHorizontal: 20, marginTop: 10}
              ]}>
              <TextField
                value={name} onChangeText={setName}
                label={'Name *'} labelPreset={'label'} preset={'brand'}
                placeholder={'Enter Group Name *'}
              />
              <View style={{marginTop: 40}}>
                <View style={{marginBottom: 20}}>
                  <AwSelectButton
                    onPress={() => navigation.navigate('addExistingContactScreen', {isNeedSaveContact: true})}
                    text={'Add Existing Contact(s)'}
                  />
                </View>
                <View style={{marginBottom: 20}}>
                  <AwSelectButton
                    onPress={() => navigation.navigate('addContactScreen')}
                    text={'Invite New Contact(s)'}
                  />
                </View>
              </View>
              <View style={{justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center'}}>
                <Text preset="body" style={{fontWeight: '700'}}>
                  Group Members
                </Text>
              </View>
              {listSelectedContact.length > 0 ?
                <View style={{marginTop: 20, flex: 1}}>
                  <ScrollView
                    showsVerticalScrollIndicator={false} style={{flex: 1}}>
                    {listSelectedContact.map((item, index) => (
                      <UserCard
                        key={`${index}-${item.id}`}
                        onClick={() => toggleSelectItem(item.id)}
                        isCheck={item.checked}
                        name={item.name}
                        avatar={item.avatar}
                      />
                    ))}
                  </ScrollView>
                </View>
                : (
                  <View style={{marginTop: 25}}>
                    <Text preset="body" style={{color: color.palette.primary, fontStyle: 'italic'}}>
                      No Contacts have been added
                    </Text>
                  </View>
                )}
            </View>
            <View style={{paddingTop: 12, paddingBottom: 20, paddingHorizontal: 20}}>
              <Button
                onPress={handleCreateNewGroup}
                disabled={disableBtnDone}
                preset={disableBtnDone ? 'disabled' : 'brand'}>
                <Text preset={'whiteBold'}>Create New Group</Text>
              </Button>
            </View>
          </View>
        </PureBottomModal>
        <AlertModal/>
      </>
    )
  },
)
