import {Instance, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {constants} from "../../utils/constants";
import {BusinessProfileUserModel, IBusinessProfileUserModel} from "./business-profile-user";

export const UserModel = types
  .model("UserModel")
  .props({
    firstLaunch: types.optional(types.boolean, true),
    role: types.optional(types.string, constants.roles.GUEST),
    businessProfile: types.maybeNull(BusinessProfileUserModel),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setRole: (role: string) => {
      self.role = role
    },
    setFirstLaunch: (firstLaunch: boolean) => {
      self.firstLaunch = firstLaunch
    },
    setBusinessProfile: (businessProfile: IBusinessProfileUserModel) => {
      self.businessProfile = businessProfile
    },
  }))
  .actions((self) => ({}))
  .views((self) => ({}))
export type UserType = Instance<typeof UserModel>
