import React from "react"
import { StyleSheet, View } from "react-native"
import { Text } from "../../../../components"
import { SvgIcon } from "../../../../components/icon/svg-icon"

interface Props{
  title: string;
  onClick?: () => void;
  disableAdd?: boolean
}

const LineTitle = ({title, onClick, disableAdd}: Props) => {
  return(
    <View style={styles.wrapper}>
      <Text preset="dark">{title}</Text>
      <View style={styles.right}>
        <View style={styles.divider}/>
        {!disableAdd && (
          <SvgIcon icon={'plus'} preset={'orange'} onPress={onClick}/>
        )}
      </View>
    </View>
  )
};

const styles = StyleSheet.create({
 divider:{
    backgroundColor: "#aaaaaa",
    height: 1,
    marginRight: 12,
   width: 237
  },
  right:{
    alignItems: 'center',
    display: "flex",
    flexDirection: 'row'
  },
  wrapper:{
    alignItems: "center",
    display: "flex",
    flexDirection: 'row',
    justifyContent: 'space-between',
 }
});

export default LineTitle;
