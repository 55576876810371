import React from "react"
import { PublicHomeScreen } from "../../screens/public/home/public-home-screen"
import { GroupMessageScreen } from "../../screens/business/messages/group-message-screen"
import { BusinessMessageScreen } from "../../screens/business/messages/business-message-screen"
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs"
import { TabBar } from "../../components/tab-bar/tab-bar"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import { JobListScreen } from "../../screens/shared/jobs-list";
import InboxScreen from "../../screens/guest/InboxScreen"
import WalletScreen from "../../screens/guest/WalletScreen"

export type PublicParamList = {
  tabNavigator: undefined

  homeScreen: undefined
  manageJobScreen: undefined
  inboxScreen: undefined
  contactScreen: undefined
  settingScreen: undefined
  groupMessageScreen: undefined
  userMessageScreen: undefined
}

const Tab = createBottomTabNavigator<PublicParamList>()

const TabNavigator = () => (
  <Tab.Navigator
    screenOptions={{
      headerShown: false,
    }}
    tabBar={(props) => <TabBar {...props} />}
  >
    <Tab.Screen name={"homeScreen"} component={PublicHomeScreen} options={{tabBarLabel: "Dashboard"}}/>
    <Tab.Screen name={"manageJobScreen"} options={{tabBarLabel: "Job Ads"}}>
      {(props) => <JobListScreen {...props} route={props?.route as any} fullScreen={true}/>}
    </Tab.Screen>
    <Tab.Screen name={"inboxScreen"} component={InboxScreen} options={{tabBarLabel: "Messages"}}/>
    <Tab.Screen name={"contactScreen"} component={WalletScreen} options={{tabBarLabel: "Contacts"}}/>
    <Tab.Screen name={"settingScreen"} component={PublicHomeScreen} options={{tabBarLabel: "Settings"}}/>
  </Tab.Navigator>
)

const Stack = createNativeStackNavigator<PublicParamList>()

export function PublicNavigator() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName={"tabNavigator"}
    >
      <Stack.Screen name={"tabNavigator"} component={TabNavigator} />
      <Stack.Screen name={"groupMessageScreen"} component={GroupMessageScreen} />
      <Stack.Screen name={"userMessageScreen"} component={BusinessMessageScreen} />
    </Stack.Navigator>
  )
}
