import * as React from "react"
import {TouchableOpacity, TouchableOpacityProps, TextStyle, ViewStyle, View} from "react-native";
import {SvgIcon} from "./svg-icon";
import {SvgIconProps} from "./svg-icon.props";
import {Text} from "../../components/text/text";
import {TextPresets} from "../text/text.presets";
import {color} from "../../theme";

const BASE: ViewStyle = {
  flexDirection: 'row',
  alignItems: 'center',
}

const BASE_LEFT_ICON: ViewStyle = {
  backgroundColor: color.palette.bgBlue,
  width: 32,
  height: 32,
  borderRadius: 48,
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 8,
}

export const presets = {
  default: BASE,
  primary: {
    ...BASE,

    shadowColor: color.palette.black,
    shadowOffset: {width: 0, height: 1},
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    // shadowOffset: {width: 4, height: 4},
    // shadowOpacity: isAndroid ? 0.005 : 0.1,
    // shadowRadius: 3,
    // elevation: isAndroid ? 12 : 4,

    backgroundColor: color.palette.white,
    padding: 8,
    borderRadius: 8,
    flex: 1
  },
  defaultLeftIcon: BASE_LEFT_ICON,
  bigIconButton: {
    ...BASE,
    backgroundColor: color.palette.bgBlue,
    paddingVertical: 16,
    paddingHorizontal: 24,
    borderRadius: 12,
  },
  white: {
    ...BASE,
    backgroundColor: color.palette.white,
    padding: 8,
    borderRadius: 8,
  },
  settingIcon: {
    width: 32,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 8,
  } as ViewStyle,
}

export type IconButtonPresets = keyof typeof presets

export interface IconButtonProps extends TouchableOpacityProps {
  onPress?: (ev: any) => void
  style?: any
  iconProps?: SvgIconProps
  text?: string
  textStyle?: TextStyle
  textPreset?: TextPresets
  preset?: IconButtonPresets
  leftIconProps?: SvgIconProps
  leftPreset?: IconButtonPresets
}

export const IconButton = (props: IconButtonProps) => {
  const {
    iconProps,
    text,
    textStyle,
    textPreset,
    preset,
    leftIconProps,
    leftPreset,
    ...touchProps
  } = props
  const CONTAINER = presets[preset] || presets['default'];
  const LEFT_ICON_CONTAINER = presets[leftPreset] || presets['defaultLeftIcon']

  return (
    <TouchableOpacity style={CONTAINER} {...touchProps}>
      {
        !!leftIconProps &&
        <View style={LEFT_ICON_CONTAINER}>
          <SvgIcon {...leftIconProps}/>
        </View>
      }
      {
        !!text && (
          <View style={[{marginLeft: leftIconProps ? 6 : 0}, textStyle]}>
            <Text preset={textPreset}>{text}</Text>
          </View>
        )
      }
      {
        !!iconProps && (
          <View style={{marginLeft: text ? 6 : 0}}>
            <SvgIcon {...iconProps} onPress={touchProps.onPress}/>
          </View>
        )
      }
    </TouchableOpacity>
  )
}
