import React, {FC} from "react"
import {View, FlatList} from "react-native"
import {IJobRoleAdModel} from "../../../models/app/job-role-ad";
import {searchIgnoreCase} from "../../../utils/utils";
import {FlatJobRoleCard} from "../../../components/card/job-card";

export interface AvailableJobsTabProps {
  onPress: (item: IJobRoleAdModel) => void
  onLogoPress?: (id: number) => void
  search: string

  jobRoles: Array<IJobRoleAdModel>
  refreshing: boolean
  loadData?: () => void
  onEndReached?: () => void
}

export const AvailableJobsTab: FC<AvailableJobsTabProps> = (
  {onPress, onLogoPress, search, jobRoles, refreshing, loadData, onEndReached}
) => {
  const renderItem = ({item}: { item: IJobRoleAdModel }) => {
    return <FlatJobRoleCard
      onLogoPress={onLogoPress} messageCount={true}
      item={item} onPress={() => onPress(item)}/>
  }

  const filteredData = search ? jobRoles.filter(item => (
    searchIgnoreCase(item?.role?.name, search)
  )) : jobRoles
  return (
    <View style={{flex: 1, marginHorizontal: 5}}>
      <FlatList
        style={{flex: 1}}
        onRefresh={loadData}
        refreshing={refreshing}
        data={filteredData}
        renderItem={renderItem}
        ListFooterComponent={<View style={{height: 100}}/>}
        keyExtractor={(item, index) => `${index}_${item.id}`}
        onEndReached={onEndReached}
        onEndReachedThreshold={0.2}
      />
    </View>
  )
}
