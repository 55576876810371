import * as React from "react"
import {
  View,
  SafeAreaView,
  FlatList,
  TouchableOpacity,
  ScrollView,
  StyleSheet,
  RefreshControl, ViewStyle
} from "react-native"
import {FC, useCallback, useEffect, useState} from "react"
import {ParamListBase} from "@react-navigation/native"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Text, GradientBackground, IconButton} from "../../../components"
import {SvgIcon} from "../../../components/icon/svg-icon"
import {color} from "../../../theme"
import {AvatarImage} from "../../../components/avatar-image/avatar-image"
import {SearchBar} from "../../../components/search-bar/search-bar"
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {SearchBarSuggest} from "../../../components/search-bar/search-bar-suggest";
import {useStores} from "../../../models";
import {constants} from "../../../utils/constants";
import {Observer, observer} from "mobx-react-lite";
import {IJobRoleAdModel} from "../../../models/app/job-role-ad";
import {convertKvItemModel, IKvItemModel, KvItemPath} from "../../../models/app/kv-item";
import {debounce} from "../../../utils/debounce";
import {AwBannerCard} from "../../../components/card/aw-banner-card";
import {FilterJobType} from "../../../models/app/filter-job";
import {JobRoleCard} from "../../../components/card/job-card";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {BREAKPOINT_MOBILE, height, width} from "../../../utils/global";
import {convertJobAdvertModel} from "../../../models/app/job-advert";
import {AdvertRoleStore} from "../../../models/app/advert-role";

const avatar = require("../../../../assets/images/default-photo.png")
const bannerImage = require("../../../../assets/images/people.png")

export interface HomeScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
}

export const UserHomeScreen: FC<HomeScreenProps> = observer(({navigation}) => {
  const {app} = useStores()
  const {userAccount} = app
  const insets = useSafeAreaInsets();
  const [focusSearch, setFocusSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [jobRoles, setJobRoles] = useState<Array<IJobRoleAdModel>>([])
  const [jobRoleSuggest, setJobRoleSuggest] = useState<Array<IKvItemModel>>([])
  const [refreshing, setRefreshing] = useState(false)
  const [filterData, setFilterData] = useState<FilterJobType>()
  const isDesktop =  width > BREAKPOINT_MOBILE;

  let _mount: boolean = true

  const loadData = async () => {
    if (!_mount) return
    if (app?.hasFilterJob) {
      setFilterData({
        locations: app?.filterJob?.locations?.map(convertKvItemModel),
        industries: app?.filterJob?.industries?.map(convertKvItemModel),
        roles: app?.filterJob?.roles?.map(convertKvItemModel),
        experience: app?.filterJob?.experience?.map(convertKvItemModel),
        skills: app?.filterJob?.skills?.map(convertKvItemModel),
        qualifications: app?.filterJob?.qualifications?.map(convertKvItemModel),
      })
      await app.setHasFilterJob(false)
    } else {
      setFilterData({
        locations: [],
        industries: [],
        roles: [],
        experience: [],
        skills: [],
        qualifications: [],
      })
    }
    await app.filterJob.reset()
    if (refreshing) return
    setRefreshing(true)
    try {
      const res = await app.environment.api.getJobRoleAds({
        "filters[jobAd][isDraft][$eq]": false,
        "filters[jobAd][closed][$eq]": false,
      })
      if (res.kind === 'ok') {
        setJobRoles(res.jobRoles)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setRefreshing(false)
    }
  }

  useEffect(() => {
    loadData()
    _mount = true
    return () => {
      _mount = false
    }
  }, [])

  const onChooseAccess = () => {
    navigation.navigate("chooseAccessScreen")
  }

  const handleSearchSuggest = async (text: string) => {
    try {
      const res = await app.environment.api.getKvItemList(KvItemPath.role, {
        customFilter: true,
        ...(text ? {'filters[name][$containsi]': text} : null)
      })
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message)
        return
      }
      const _jobRoleSuggest: Array<IKvItemModel> = []
      res?.items?.forEach(it => {
        if (!it?.name?.toLowerCase().includes(text?.toLowerCase())) return
        if (_jobRoleSuggest.find(jit => jit.name?.trim() === it.name?.trim())) return
        _jobRoleSuggest.push({...it, name: it.name?.trim()})
      })
      setJobRoleSuggest(_jobRoleSuggest)
    } catch (e) {
      console.log(e)
    }
  }
  const delaySearchSuggest = useCallback(debounce(handleSearchSuggest, 800), []);

  const handleSearchText = (text) => {
    setFocusSearch(!!text);
    setSearchText(text);
    // @ts-ignore
    if (text) delaySearchSuggest(text)
  }

  const handleCreateJobAd = () => {
    app.setJobAdvertCreate(convertJobAdvertModel({}))
    AdvertRoleStore.reset()
    app.setSelectedContact(null)
    navigation.navigate("jobAdvertEditScreen")
  }

  const handlePostJob = () => {
    // navigation.navigate('signInEmailCheckScreen')
    // return
    if (app?.userAccount?.userRoles?.find(it => it.key === constants.roles.BUSINESS)) {
      AlertModalStore.alert('Info', 'Login as business role to post job.')
    } else {
      // navigation.navigate('businessAccountEditScreen', {isCreate: true})
      handleCreateJobAd()
    }
  }

  const renderItem = ({item}: { item: IJobRoleAdModel }) => {
    return (
      <JobRoleCard
        onLogoPress={(id) => navigation.navigate('businessProfileScreen', {businessProfileId: id})}
        onPress={() => navigation.navigate('jobsAdDetailScreen', {id: item.id})} item={item}/>
    )
  }

  const handleFilterPress = async () => {
    await app.filterJob.setFilterJobData(filterData)
    await app.setHasFilterJob(true)
    navigation.navigate('jobFilterScreen', {nextScreen: 'jobListScreen', replace: true})
  }

  const renderJobList = () => {
    return (
      <FlatList
        horizontal={!isDesktop}
        data={jobRoles}
        keyExtractor={(item, index) => String(item.id + '' + index)}
        renderItem={renderItem}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={isDesktop && styles.listDk}
        style={isDesktop && {height: height - 100}}
      />
    )
  }

  const renderMobile = () => {
    return(
        <ScrollView
            showsHorizontalScrollIndicator={false}
            style={{flex: 1}}
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={loadData}/>}
        >
          <View style={{marginTop: 10, marginHorizontal: 20, flexDirection: "row",}}>
            <Text
                text="Job Adverts"
                preset={'h1'}
                style={{color: color.palette.dark}}/>
            <View style={{alignItems: "flex-end", flex: 1,}}>
              <View style={{flexDirection: 'row'}}>
                <IconButton
                    onPress={() => navigation.navigate('jobListScreen')}
                    text="See All" textPreset={'bodyGrey'}
                    iconProps={{icon: 'triangle-right', preset: 'triangleIcon'}}
                />
              </View>
            </View>
          </View>
          <View style={{marginLeft: 10, marginTop: 12}}>
            {renderJobList()}
          </View>
          <View style={{marginBottom: 20, marginTop: 18}}>
            <View style={{paddingHorizontal: 20}}>
              <Text preset={'h2'} style={{fontWeight: '600'}}>Looking for Workers?</Text>
            </View>
            <View style={{marginHorizontal: 20, marginTop: 16}}>
              <AwBannerCard
                  title={'Post a free job ad today!'}
                  buttonText={'Post Job'} buttonPreset={'brand'}
                  onPress={handlePostJob}
                  image={bannerImage}
              />
            </View>
          </View>
        </ScrollView>
    )
  }

  const renderDesktop = () => {
    return(
        <View style={styles.wrapperDk}>
          <View style={styles.contentDk}>
            <View style={{paddingRight: 20}}>
              <View style={styles.topContentDk}>
                <Text
                  text="Job Adverts"
                  preset={'h2'}
                  style={{color: color.palette.dark}}/>
                <View style={{alignItems: "flex-end", flex: 1,}}>
                  <View style={{flexDirection: 'row'}}>
                    <IconButton
                      onPress={() => navigation.navigate('jobListScreen')}
                      text="See All" textPreset={'bodyGrey'}
                      iconProps={{icon: 'triangle-right', preset: 'triangleIcon'}}
                    />
                  </View>
                </View>
              </View>
              <Observer>{renderJobList}</Observer>
            </View>
          </View>
          <AwBannerCard
              isDesktop={isDesktop}
              title={'Post a free job ad today!'}
              buttonText={'Post Job'} buttonPreset={'brand'}
              onPress={handlePostJob}
              image={bannerImage}
          />
        </View>
    )
  }

  return (
    <SafeAreaView style={{flex: 1,}}>
      <GradientBackground colors={[color.palette.gradientStart, color.palette.gradientStop]}/>
      <View style={focusSearch ? styles.focusHeaderArea : styles.headerArea}>
        <View style={focusSearch ? {flex: 1, marginTop: insets.top} : {}}>
          <View style={{}}>
            <div id="mobile-header">
              <View style={HEADER_STYLE}>
                <TouchableOpacity style={{flexDirection: "row", alignItems: "center"}} onPress={onChooseAccess}>
                  <Text text={`Hi, ${userAccount?.firstName}`}
                        preset={'h1'} style={{color: color.palette.primary}}/>
                  <SvgIcon icon={"chevron-down"} style={{marginLeft: 10,}} preset={"primary"}/>
                </TouchableOpacity>
                <View style={{flex: 1, alignItems: "flex-end",}}>
                  <AvatarImage
                    onPress={() => navigation.navigate('settingScreen')}
                    source={avatar} preset={"round"}/>
                </View>
              </View>
            </div>
            <View style={{marginVertical: 10}}>
              <SearchBar
                value={searchText}
                onChangeText={handleSearchText}
                onBlur={() => setFocusSearch(false)}
                onFilterPress={handleFilterPress}
                // onFilterPress={() => navigation.navigate('jobAdvertFilterScreen')}
                // onTouchStart={() => navigation.navigate("searchScreen")}
              />
            </View>
          </View>
          {focusSearch && (
            <SearchBarSuggest
              onItemPress={(item) => {
                setSearchText('')
                app.setHasSearchJobRole(true)
                app.setFilterJobRoleText(item?.name)
                navigation.navigate('jobListScreen')
              }}
              data={jobRoleSuggest}
              searchText={searchText}/>
          )}
        </View>
      </View>
      {isDesktop ? renderDesktop() : renderMobile()}
    </SafeAreaView>
  )
})

const styles = StyleSheet.create({
  headerArea: {},
  focusHeaderArea: {
    position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 99,
    backgroundColor: color.palette.white
  },
  wrapperDk:{
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  contentDk:{
    flexDirection: 'column',
    flex: 1,
    paddingLeft: 20,
    paddingRight: 0,
  },
  topContentDk:{
    marginBottom: 20,
    flexDirection: 'row'
  },
  listDk:{
    paddingBottom: 300,
  },
});
const HEADER_STYLE: ViewStyle = {
  marginHorizontal: 20,
  flexDirection: "row",
  alignItems: "center",
  marginTop: 20,
  marginBottom: 10,
}
