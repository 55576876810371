import {ApolloClient, HttpLink, ApolloLink, InMemoryCache, from} from '@apollo/client';
import {onError} from "@apollo/client/link/error";
import {getRootStoreVal} from "../../models";
import {AlertModalStore} from "../../models/app/alert-modal-store";

const API_URL = "https://api.tap4work.com.au/graphql"

const httpLink = new HttpLink({uri: API_URL});

const logoutLink = onError(({graphQLErrors, networkError}) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      console.log('ERROR= ', err)
      switch (err.extensions.code) {
        // Apollo Server sets code to UNAUTHENTICATED
        // when an AuthenticationError is thrown in a resolver
        case 'UNAUTHENTICATED':
          AlertModalStore.alert('Error', "Your session has expired.", {
            onOk: {
              text: "Ok",
              onPress: async () => {
                const store = getRootStoreVal()
                store?.app?.onSignOut()
              }
            }
          })
      }
    }
  }

  // To retry on network errors, we recommend the RetryLink
  // instead of the onError link. This just logs the error.
  if (networkError) {
    console.log('NET ERROR= ', networkError)
  }
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const {app} = getRootStoreVal()
  const authorization = app?.auth ? `Bearer ${app?.auth}` : ''

  // add the authorization to the headers
  operation.setContext(({headers = {}}) => ({
    headers: {
      ...headers,
      authorization: authorization,
    }
  }));

  return forward(operation);
})

const activityMiddleware = new ApolloLink((operation, forward) => {
  console.log('PARAMS= ', JSON.stringify(operation.variables))
  return forward(operation).map(response => {
    console.log('RES= ', JSON.stringify(response))
    return response;
  });
})

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([
    authMiddleware,
    activityMiddleware,
    logoutLink.concat(httpLink)
  ]),
});
