import React, {FC, useCallback, useState} from "react"
import {
  View,
  SafeAreaView, ScrollView, ViewStyle, RefreshControl
} from "react-native"
import {observer} from "mobx-react-lite"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Header, GradientBackground, Text, TextField, Button} from "../../../components"
import {color, spacing} from "../../../theme"
import {INPUT_PLACEHOLDER, SelectTextField} from "../../../components/text-field/select-text-field";
import {IKvItemModel} from "../../../models/app/kv-item";
import {useStores} from "../../../models";
import {JobTags} from "../../../models/app/job-advert";
import {generateSearchItem, searchIgnoreCase} from "../../../utils/utils";
import {NavigatorParamList} from "../../../navigators";
import {useFocusEffect} from "@react-navigation/native";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import {isAndroid} from "../../../utils/platform";
import {debounce} from "../../../utils/debounce";
import {parseLocation} from "../../../models/app/location";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";

export interface WorkerPreferredLocationScreenProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
}

export const WorkerPreferredLocationScreen: FC<WorkerPreferredLocationScreenProps> = observer(
  ({navigation}) => {
    const {app} = useStores();
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState("")
    const [data, setData] = useState<Array<IKvItemModel>>([]);
    const [selectedItems, setSelectedItems] = useState<Array<IKvItemModel>>([]);

    const loadData = async (text?: string) => {
      if (!text || text?.length < 3) return
      setLoading(true)
      try {
        const res = await app.environment.api.getJobLocation({
          ...(text ? {
            'filters[$or][0][suburb][$containsi]': text,
            'filters[$or][1][state][$containsi]': text,
            'filters[$or][2][postcode][$containsi]': text,
          } : null),
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        setData(res?.items?.map(it => ({
          id: it.id,
          name: parseLocation(it),
          checked: false,
        })))
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }
    useFocusEffect(useCallback(() => {
      loadData()
    }, []))

    const toggleSelectItem = (_item: IKvItemModel) => {
      if (selectedItems.find(it => it?.id === _item.id)) {
        setSelectedItems(selectedItems.filter(it => it?.id !== _item.id))
      } else setSelectedItems([...selectedItems, _item])
    }

    const onDone = () => {
      app.setPreferredLocations(selectedItems)
      navigation.goBack()
    }

    const delayLoadData = useCallback(debounce(loadData, 600), []);
    const handleChangeSearch = (text: string) => {
      // @ts-ignore
      delayLoadData(text)
      setSearch(text)
    }
    const loadDataFromState = () => {
      loadData(search)
    }

    const renderItem = (item: IKvItemModel) => {
      const checked = selectedItems?.find(it => it.id === item.id)
      return (
        <SelectTextField
          key={String(item.id)}
          value={item.name} onPress={() => toggleSelectItem(item)}
          style={{marginBottom: 10}}
          rightIcon={checked ? 'check-square' : 'square-regular'}
          rightIconTheme={checked ? 'solid' : 'regular'}
          rightIconPreset={checked ? 'primary' : 'default'}
          preset={checked ? 'itemSelected' : 'transparent'}
        >
          <View style={{flex: 1}}>
            <Text style={INPUT_PLACEHOLDER}>{generateSearchItem(item.name, search).map((it, index) => (
              <Text
                key={index.toString()}
                preset={it.highlight ? 'bold' : 'default'}>{it.text}</Text>
            ))}</Text>
          </View>
        </SelectTextField>
      )
    }

    const filteredData = search ? data.filter(item => searchIgnoreCase(item?.name, search)) : data
    const showBtnDone = selectedItems?.length > 0
    return (
      <>
        <PureBottomModal onClose={() => navigation.goBack()}>
          <SafeAreaView style={CONTAINER}>
            <GradientBackground
              colors={[color.palette.gradientStart, color.palette.gradientStop]}
            />
            <Header
              preset={"modalHeader"}
              titlePreset={"headerTitle"}
              headerText={JobTags.locations.text}
              rightIcon={"times"}
              rightIconPreset={"smallBlue"}
              onRightPress={() => navigation.goBack()}
            />
            <View style={VIEW_CONTAINER}>
              <Text preset={"label"}>Search and select your Preferred Location(s)</Text>
              <View style={{marginTop: spacing[5], marginBottom: spacing[2]}}>
                <TextField
                  value={search} onChangeText={handleChangeSearch}
                  labelPreset={"default"}
                  rightIcon={"times-circle-fill"}
                  rightIconPreset={"default"}
                  rightIconPress={() => handleChangeSearch('')}
                />
              </View>
              <ScrollView
                refreshControl={<RefreshControl refreshing={loading} onRefresh={loadDataFromState}/>}
                showsVerticalScrollIndicator={false} style={{flex: 1}}>
                <View style={{marginTop: 16, paddingBottom: 16}}>
                  {data ? (
                    <View>
                      <View style={{marginHorizontal: 16}}>
                        <Text preset={"mediumBlue"}>Results</Text>
                      </View>
                      <View style={{height: 10}}/>
                      {filteredData.map(renderItem)}
                    </View>
                  ) : null}
                </View>
              </ScrollView>
            </View>
            <View style={{paddingHorizontal: 20}}>
              <View style={{display: showBtnDone ? 'flex' : 'none', paddingTop: 10, marginBottom: isAndroid ? 10 : 5}}>
                <Button preset={"brand"} onPress={onDone} text={'Done'}/>
              </View>
            </View>
          </SafeAreaView>
        </PureBottomModal>
        <AlertModal/>
      </>
    )
  },
)

const CONTAINER: ViewStyle = {
  flex: 1,
  backgroundColor: color.palette.white,
}

const VIEW_CONTAINER: ViewStyle = {
  paddingHorizontal: 20,
  flex: 1
}
