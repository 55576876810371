import React, {FC, useState, useEffect} from "react"
import {View, SafeAreaView, Image, ViewStyle, TouchableOpacity} from "react-native"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {TextField, Header, Button, Text, Screen} from "../../../components"
import {observer} from "mobx-react-lite";
import MobileInput from "../../../components/text-field/mobile-input";
// import PhoneInput from "react-native-phone-number-input"
import {color} from "../../../theme";
import {useStores} from "../../../models";
import {NavigatorParamList} from "../../../navigators";
// import {CountryCode} from "react-native-country-picker-modal";
import type {RouteProp} from "@react-navigation/native";
import {CommonActions} from "@react-navigation/native";
import {LoadingModal} from "../../../components/backdrop-modal/loading-modal";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";
import {isAndroid} from "../../../utils/platform";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import {USER_STATUS} from "../../../models/app/user-account";
import {SvgIcon} from "../../../components/icon/svg-icon";
import {BackdropModal} from "../../../components/backdrop-modal/backdrop-modal";
import {AvatarImage} from "../../../components/avatar-image/avatar-image";
import {identifyWithUserAccount, TrackEvents} from "../../../services/track";
import {MIN_PASS_LENGTH} from "../../../utils/constants";

const backgroundImage = require("../../../../assets/images/icons/cloud_top.png")

export interface UserAccountEditScreenProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
  route: RouteProp<NavigatorParamList, 'userAccountEditScreen'>
}

export const UserAccountEditScreen: FC<UserAccountEditScreenProps> = observer(({route, navigation}) => {
  const {app} = useStores();
  const {userAccount} = app;
  const [loading, setLoading] = useState(false)

  const editType = route.params.type;
  const isEdit = editType !== 'create';
  const [requirePassword, setRequirePassword] = useState(route.params.requirePassword);
  const firstName = userAccount?.firstName || '';
  const lastName = userAccount?.lastName || '';
  const email = userAccount?.email || '';
  const mobile = ({
    value: userAccount?.mobile || '',
    country: userAccount?.countryCode || 'AU',
  });
  const [mailVerifyVisible, setMailVerifyVisible] = useState(false)

  const setFirstName = (firstName) => {
    app.setUserAccount({...userAccount, firstName})
  }
  const setLastName = (lastName) => {
    app.setUserAccount({...userAccount, lastName})
  }
  const setEmail = (email) => {
    app.setUserAccount({...userAccount, email})
  }
  const setMobile = ({value, country}) => {
    app.setUserAccount({...userAccount, mobile: value, countryCode: country})
  }

  const [password, setPassword] = useState('');
  const hasUnsavedChanges = isEdit && requirePassword && password?.length < MIN_PASS_LENGTH;
  const hasUnsavedRequires = !firstName?.trim() || !lastName?.trim() || !email?.trim();

  const [isAccept, setIsAccept] = useState(false);

  const onSetPhoneNumber = (data: any) => {
    setMobile(data)
  }

  const onSave = async () => {
    if (!isEdit && !isAccept) {
      AlertModalStore.alert('Error', `Please accept the Terms & Conditions and Privacy Policy`)
      return
    }
    if (hasUnsavedChanges) {
      AlertModalStore.alert('Error', `Please set password`)
      return
    }
    if (hasUnsavedRequires) {
      AlertModalStore.alert('Error', 'Please enter require fields')
      return
    }
    if (
      (isEdit && password && password?.length < MIN_PASS_LENGTH)
      || (!isEdit && password?.length < MIN_PASS_LENGTH)
    ) {
      AlertModalStore.alert('Error', `Password must at least ${MIN_PASS_LENGTH} characters length`)
      return
    }
    setRequirePassword(false)
    setLoading(true)
    try {
      if (!isEdit) {
        // await app.user.setRole(constants.roles.USER)
        // await app.getUserRoles()
        const res = await app.onSignUp({
          firstName, lastName, username: email, email, mobile: mobile.value, countryCode: mobile?.country,
          userRoles: [], password
        });

        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message || '')
        } else {
          identifyWithUserAccount(TrackEvents.SignedUp, res?.userAccount)
          setMailVerifyVisible(true)
        }

      } else {
        const res = await app.onUpdateUser({
          firstName,
          lastName,
          username: email,
          email: email,
          mobile: mobile.value,
          countryCode: mobile?.country,
          ...(password ? {password} : null),
          ...(route.params.requirePassword ? {status: USER_STATUS.active.key} : null),
        });

        if (res.kind === 'ok') {
          AlertModalStore.alert('Success', 'Saved.', {
            onOk: {
              text: 'Ok', onPress: () => {
                if (requirePassword) {
                  navigation.dispatch(
                    CommonActions.reset({index: 0, routes: [{name: 'main'}],})
                  )
                } else navigation.goBack()
              }, preset: 'brand'
            },
          })
        } else {
          AlertModalStore.alert('Error', res.message || '')
        }
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  // const phoneRef = useRef<PhoneInput>();

  useEffect(() => {
    return navigation.addListener('beforeRemove', (e) => {
      if (!hasUnsavedChanges && !hasUnsavedRequires) return
      if (hasUnsavedChanges) {
        e.preventDefault();
        AlertModalStore.alert(
          'Discard changes?',
          'You can not login until your password set. Are you sure ?',
          {
            onOk: {text: "Discard", onPress: () => navigation.dispatch(e.data.action)},
            onCancel: {text: "Don't leave"}
          }
        )
      } else if (hasUnsavedRequires) {
        e.preventDefault();
        AlertModalStore.alert(
          'Discard changes?',
          'You are not enter the require fields. Are you sure ?',
          {
            onOk: {text: "Discard", onPress: () => navigation.dispatch(e.data.action)},
            onCancel: {text: "Don't leave"}
          }
        )
      }
    })
  }, [navigation, hasUnsavedRequires, hasUnsavedChanges]);

  const handleDeleteAccount = () => {
    AlertModalStore.alert(
      'Delete account?',
      'All your data will be lost. Are you sure ?',
      {
        onOk: {
          text: "Yes", onPress: async () => {
            navigation.dispatch(
              CommonActions.reset({
                index: 0, routes: [{name: 'main'}],
              })
            );
            await app.environment.api.onUpdateUser({blocked: true})
            await app.onSignOut()
          }
        },
        onCancel: {text: "No"}
      }
    )

  }

  const hideMailVerify = () => {
    setMailVerifyVisible(false)
    navigation.goBack()
  }

  const handleTermPress = () => {
    navigation.navigate('inAppBrowser', {url: 'https://www.tap4work.com.au/terms'})
  }

  const handlePrivacyPress = () => {
    navigation.navigate('inAppBrowser', {url: 'https://www.tap4work.com.au/privacy'})
  }

  return (
    <>
      <PureBottomModal onClose={() => navigation.goBack()}>
        <SafeAreaView style={{flex: 1, backgroundColor: color.palette.white,}}>
          <View style={{
            position: "absolute", top: isAndroid ? 20 : 40, left: 20, right: 20, zIndex: -9,
            backgroundColor: color.palette.white,
            overflow: 'hidden'
          }}>
            <Image
              style={{width: '100%', height: 120, resizeMode: 'stretch'}}
              source={backgroundImage}/>
          </View>
          <Header
            preset={"transparent"} style={{marginTop: 10, marginHorizontal: 10}}
            titlePreset="h1Blue"
            rightIcon="times" rightIconPreset="smallBlue" onRightPress={() => navigation.goBack()}
          />
          <View style={{flex: 1}}>
            <Screen preset="scroll" keyboardOffset={'primary'} unsafe={true} backgroundColor={'transparent'}>
              <View style={{paddingHorizontal: isAndroid ? 20 : 26, marginTop: 62, marginBottom: 16}}>
                {isEdit ? (
                  <View>
                    <Text preset={"h1"}>User account details</Text>
                    <View style={{height: 30}}/>
                  </View>
                ) : (
                  <View>
                    <Text preset={"h1"}>Looks like you’re new!</Text>
                    <View style={{height: 10}}/>
                    <Text preset={"body"} style={{
                      color: color.palette.greyLink,
                      fontSize: isAndroid ? 16 : 18, fontWeight: isAndroid ? 'bold' : '500', opacity: 0.84
                    }}>Let’s get you signed up.</Text>
                    <View style={{height: 30}}/>
                  </View>
                )}
              </View>
              <View style={{paddingHorizontal: isAndroid ? 20 : 26, marginBottom: 16}}>
                <View style={{flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20}}>
                  <TextField
                    value={firstName} onChangeText={setFirstName}
                    preset={'primary'}
                    style={{flex: 1}}
                    placeholder="First name"
                    label="First Name*"
                    labelPreset="primary"
                  />
                  <View style={{width: isAndroid ? 10 : 30}}/>
                  <TextField
                    value={lastName} onChangeText={setLastName}
                    preset={'primary'}
                    style={{flex: 1}}
                    placeholder="Last name"
                    label="Last Name*"
                    labelPreset="primary"
                  />
                </View>
                <TextField
                  autoCapitalize='none'
                  value={email} onChangeText={setEmail}
                  preset={'primary'}
                  style={{marginBottom: 20}}
                  placeholder="Enter email address"
                  label="Email Address*"
                  labelPreset="primary"
                />
                <View style={{marginHorizontal: isAndroid ? 4 : 0,}}>
                  <Text preset={'primary'} text={'Mobile Number'}/>
                </View>
                <MobileInput
                  // phoneRef={phoneRef}
                  setPhoneNumber={onSetPhoneNumber}
                  defaultValue={mobile?.value}
                  placeholder={'Enter your number'}
                  codeTextStyle={{color: color.palette.black80}}
                  defaultCode={mobile?.country}
                  textInputProps={{maxLength: 10}}
                />
                <View style={{height: 20}}/>
                <TextField
                  value={password} onChangeText={setPassword}
                  preset={'primary'}
                  style={{marginBottom: 20}}
                  placeholder="Minimum 8 characters"
                  label="Password*"
                  labelPreset="primary"
                  secureTextEntry={true}
                  rightIcon="lock"
                />

                <View style={{height: 28}}/>
                <View style={{flexDirection: 'row', marginBottom: 16, display: !isEdit ? 'flex' : 'none', alignItems: 'center'}}>
                  <View style={{}}>
                    <TouchableOpacity onPress={() => setIsAccept(!isAccept)}>
                      <SvgIcon
                        preset={'smallBrand'}
                        icon={isAccept ? 'check-square' : 'square-regular'}
                        theme={isAccept ? 'solid' : 'regular'}
                      />
                    </TouchableOpacity>
                  </View>
                  <View style={{flex: 1, paddingLeft: 8, flexDirection: 'row', flexWrap: 'wrap'}}>
                    <Text preset={'mediumBold'} style={{}}>I accept the </Text>
                    <TouchableOpacity onPress={handleTermPress}>
                      <Text preset={'mediumBoldBlue'} style={{textDecorationLine: 'underline'}}>
                        Terms & Conditions
                      </Text>
                    </TouchableOpacity>
                    <Text preset={'mediumBold'} style={{}}> and </Text>
                    <TouchableOpacity onPress={handlePrivacyPress}>
                      <Text preset={'mediumBoldBlue'} style={{textDecorationLine: 'underline'}}> Privacy Policy</Text>
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={BUTTON_CONTAINER}>
                  {
                    (!isEdit) ? (
                      <Button
                        onPress={onSave}
                        text={"Continue"} preset="brand"/>
                    ) : (
                      <View>
                        <Button
                          onPress={onSave}
                          text="Save" preset="brand"/>
                        <View style={{marginTop: 12}}>
                          <Button
                            onPress={handleDeleteAccount}
                            text={"Delete Account"} preset="brandDangerOutLine"/>
                        </View>
                      </View>
                    )
                  }
                </View>
              </View>
            </Screen>
          </View>
        </SafeAreaView>
      </PureBottomModal>
      <LoadingModal visible={loading}/>
      <BackdropModal visible={mailVerifyVisible} onClose={hideMailVerify}>
        <View>
          <View
            style={{alignItems: "center", justifyContent: "center", padding: 10}}
          >
            <AvatarImage
              preset={'mailImage'}
              source={require('../../../../assets/images/icons/Frame_3.png')}/>
            <View style={{paddingTop: 18, paddingBottom: 8}}>
              <Text preset={"alertTitle"} style={{textAlign: 'center'}} text={'Verification Required!'}/>
              <View style={{marginTop: 10}}>
                <Text preset={"alertBody"} style={{textAlign: 'center'}} text={'Check your email'}/>
              </View>
              <View style={{marginTop: 20}}>
                <Button onPress={hideMailVerify} text={"Ok"} preset="brand"/>
              </View>
            </View>
          </View>
        </View>
      </BackdropModal>
      <AlertModal/>
    </>
  )
});

const BUTTON_CONTAINER: ViewStyle = {
  marginTop: 8, marginBottom: 32, justifyContent: 'space-between',
}
