import {ParamListBase, useFocusEffect} from "@react-navigation/native"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {observer} from "mobx-react-lite"
import React, {FC, useCallback, useEffect, useState} from "react"
import {Alert, SafeAreaView, ScrollView, View, ViewStyle} from "react-native"
import {Header, TextField, Text, Button, Switch} from "../../../components"
import {ListDiv} from "../../../components/list-div/list-div"
import {color, spacing} from "../../../theme"
import {SelectTextField} from "../../../components/text-field/select-text-field";
import {useStores} from "../../../models";
import {LoadingModal} from "../../../components/backdrop-modal/loading-modal";
import {DefaultKvItemFile, IKvItemCheckModel, IKvItemFileModel, IKvItemModel} from "../../../models/app/kv-item";
import moment from "moment";
import {PickerItemOption, PickerSelectField} from "../../../components/picker-select/picker-select";
import {GENDER} from "../../../models/app/worker-profile";
import {DatePickerField} from "../../../components/picker-select/date-picker";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";
import {useFilePicker} from "use-file-picker";
import {TrackEvents, trackWithUserAccount} from "../../../services/track";
import {AvatarImage} from "../../../components/avatar-image/avatar-image";
import {SvgIcon} from "../../../components/icon/svg-icon";
import {DEFAULT_PAGE_LIMIT} from "../../../utils/constants";
import {WorkerWalletItemPath} from "../../../models/app/advanced-skill";
import {MainAppStore} from "../../../models/app/main-app-store";
import {SelectTypeSections} from "../wallet/document/upload-select-type-screen";

export interface WorkerDetailsProps {
  navigation: NativeStackNavigationProp<ParamListBase>
}

const UploadType = {
  photo: 'photo',
  cv: 'cv',
  right: 'right',
}
const defaultPhoto = require("../../../../assets/images/default-photo.png")

export const WorkerDetailsScreen: FC<WorkerDetailsProps> = observer(({navigation}) => {
  const {app} = useStores();
  const [loading, setLoading] = useState(false)
  const [avatar, setAvatar] = useState<IKvItemFileModel>(DefaultKvItemFile)
  const [bio, setBio] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postcode, setPostcode] = useState('');
  const [dob, setDob] = useState(new Date());
  const [datePickerVisible, setDatePickerVisible] = useState(false)

  const [genders, setGenders] = useState<Array<IKvItemCheckModel>>(Object.values(GENDER).map(item => ({
    ...item,
    checked: false
  })))
  const [returnedWorker, setReturnedWorker] = useState(false);
  const [exServicePerson, setExServicePerson] = useState(false);

  const [rightToWorkDocs, setRightToWorkDocs] = useState<Array<IKvItemModel>>([]);

  const [indigenous, setIndigenous] = useState<Array<IKvItemCheckModel>>([])
  const [rightToWork, setRightToWork] = useState<Array<IKvItemCheckModel>>([])

  const [listStateItem, setListStateItem] = useState<Array<PickerItemOption>>([]);
  const [stateOpen, setStateOpen] = useState(false)

  const [uploadType, setUploadType] = useState('')
  const [openPhotoFileSelector, photoFileResult] = useFilePicker({
    accept: 'image/*',
    maxFileSize: 2,
  });
  const photoDoc: File = photoFileResult.plainFiles?.length > 0 ? photoFileResult.plainFiles[0] : null
  const [openDocFileSelector, cvDocFileResult] = useFilePicker({
    accept: ['.pdf', '.doc', '.docx', 'image/*'],
    maxFileSize: 2,
  });
  const myDoc: File = cvDocFileResult.plainFiles?.length > 0 ? cvDocFileResult.plainFiles[0] : null

  const loadData = async () => {
    try {
      setLoading(true)
      const res = await app.getMe()
      const [indigenousRes, rightToWorkRes] = await Promise.all([
        app.environment.api.getKvItemList('indigenous-statuses'),
        app.environment.api.getKvItemList('right-to-works'),
      ])
      const stateRes = await app?.environment?.api?.getKnItemList('states')
      if (res.kind === 'ok') {
        const workerProfile = app.userAccount.workerProfile
        setAvatar({
          id: app.userAccount?.avatar.id || 0,
          name: app.userAccount?.avatar.name || '',
          url: app.userAccount?.avatar.url || '',
        })
        setBio(workerProfile?.bio);
        setAddressLine1(workerProfile?.addressLine1 || '');
        setAddressLine2(workerProfile?.addressLine2 || '');
        setCity(workerProfile?.city || '');
        setState(workerProfile?.state);
        setPostcode(workerProfile?.postcode || '');
        setDob(workerProfile?.dob ? new Date(workerProfile?.dob) : null);

        loadRTW()
        setGenders(genders.map(item => ({...item, checked: item.id === workerProfile?.gender})));
        setReturnedWorker(workerProfile?.returnedWorker);
        setExServicePerson(workerProfile?.exServicePerson);

        if (stateRes.kind === 'ok') {
          setListStateItem(stateRes?.items.map(item => ({key: item.key, value: item.key, label: item.name})))
        }
        if (indigenousRes.kind === 'ok') {
          setIndigenous(indigenousRes?.items.map(item => ({
            ...item, checked: !!workerProfile.indigenousStatuses.find(it => it.id === item.id)
          })))
        }
        if (rightToWorkRes.kind === 'ok') {
          setRightToWork(rightToWorkRes?.items.map(item => ({
            ...item, checked: workerProfile.rightToWork.id === item.id
          })))
        }
      } else {
        AlertModalStore.alert('Error', res.message)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }
  const loadRTW = async () => {
    const workerProfile = app.userAccount.workerProfile
    const itemRes = await app.environment.api.getWorkerWalletItemList(WorkerWalletItemPath.workerRightToWorks, {
      'filters[workerProfile][id][$eq]': workerProfile?.id,
      "pagination[pageSize]": DEFAULT_PAGE_LIMIT,
      populate: "url",
    })
    if (itemRes.kind === 'ok') {
      setRightToWorkDocs(itemRes?.items?.map((item) => ({
        id: item.id || 0, name: item?.url?.name || '',
      })));
    }
  }

  useFocusEffect(useCallback(() => {
    loadData()
  }, []))

  const handleSetDate = (date: Date) => {
    console.log(date)
    setDob(date)
    setDatePickerVisible(false)
  }

  const toggleSelectIndigenous = (index) => {
    setIndigenous(indigenous.map((it, id) => (id === index ? {...it, checked: !it.checked} : it)))
  }

  const toggleSelectRighttowork = (index) => {
    setRightToWork(
      rightToWork.map((it, id) => ({...it, checked: id === index})),
    )
  }

  const toggleSelectGender = (index) => {
    setGenders(genders.map((it, id) => ({...it, checked: id === index})))
  }

  const removeRightToWorkDocs = async (index) => {
    try {
      setLoading(true);
      const res = await app.environment.api.generalApiDelete(WorkerWalletItemPath.workerRightToWorks, rightToWorkDocs[index].id)
      if (res.kind === 'ok') {
        setRightToWorkDocs(
          rightToWorkDocs.filter((it, id) => (id !== index)),
        )
      }
      loadRTW()
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false);
    }
  }

  const handleUploadFile = async () => {
    if (!myDoc) return
    setLoading(true);
    try {
      const res = await app.environment.api.uploadFile(myDoc)
      if (res.kind === 'ok') {
        // if (uploadType === UploadType.cv) {
        //   setMyCv({id: res.file.id, name: res.file.name})
        // } else if (uploadType === UploadType.right) {
        //   setRightToWorkDocs([...rightToWorkDocs, {
        //     id: res.file.id, name: res.file.name
        //   }])
        // }
        if (uploadType === UploadType.photo) {
          if (avatar?.id) await app.environment.api.deleteFile(avatar.id)
          await app.environment.api.onUpdateUser({avatar: res.file.id})
          setAvatar({id: res.file.id, name: res.file.name, url: res.file.url})
        }
      } else {
        Alert.alert('Error', 'Can not upload file')
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false);
    }
  }

  const handleUploadCv = () => {
    MainAppStore.setSelectTypeSection({
      parentId: SelectTypeSections.cv.key, name: SelectTypeSections.cv.name, key: SelectTypeSections.cv.key, id: 0
    })
    navigation.navigate('workerUploadDocumentScreen')
  }
  const handleUploadRight = () => {
    MainAppStore.setSelectTypeSection({
      parentId: SelectTypeSections.rightToWork.key,
      name: SelectTypeSections.rightToWork.name,
      key: SelectTypeSections.rightToWork.key,
      id: 0
    })
    navigation.navigate('workerUploadDocumentScreen')
  }

  const onPickerResult = async () => {
    if (photoDoc) {
      setLoading(true);
      try {
        const res = await app.environment.api.uploadFile(photoDoc)
        if (res.kind === 'ok') {
          if (uploadType === UploadType.photo) {
            if (avatar?.id) await app.environment.api.deleteFile(avatar.id)
            await app.environment.api.onUpdateUser({avatar: res.file.id})
            setAvatar({id: res.file.id, name: res.file.name, url: res.file.url})
          }
        } else {
          AlertModalStore.alert('Error', 'Can not upload file')
        }
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false);
      }
    }
  }

  const handleUploadImage = async () => {
    await openPhotoFileSelector()
  }

  useEffect(() => {
    onPickerResult()
  }, [photoDoc])

  useEffect(() => {
    handleUploadFile()
  }, [myDoc])

  const toggleStateOpen = () => {
    setStateOpen(!stateOpen)
  }

  const handleSave = async () => {
    if (!postcode || postcode?.length > 4 || isNaN(parseInt(postcode))) {
      Alert.alert('Error', 'Postcode must 4 digits')
      return
    }
    const workerProfile = app.userAccount.workerProfile
    const rightToWorkId = rightToWork?.find(item => item.checked)?.id
    const _gender = genders.find(it => it.checked)
    const res = await app.environment.api.updateWorkerProfileData(workerProfile.id, {
      bio: bio,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      city: city,
      state: state,
      postcode: postcode,
      ...(dob ? {dob: new Date(dob).toISOString()} : null),
      indigenousStatuses: indigenous.filter(item => item.checked).map(it => it.id),
      ...(rightToWorkId ? {rightToWork: rightToWorkId} : null),
      rightToWorkDocs: rightToWorkDocs?.map(it => it.id),
      gender: _gender ? _gender.id : 0,
      returnedWorker: returnedWorker,
      exServicePerson: exServicePerson,
    })
    if (res.kind !== 'ok') {
      AlertModalStore.alert('Error', res.message)
      return
    }
    trackWithUserAccount(TrackEvents.SetupWorkerProfile, app?.userAccount)
    app.getMe()
    AlertModalStore.alert('Success', 'Saved')
  }

  const handleAvatarPress = async () => {
    if (avatar?.id) {
      try {
        setLoading(true)
        await app.environment.api.deleteFile(avatar.id)
        setAvatar(DefaultKvItemFile)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    } else handleUploadImage()
  }

  return (
    <>
      <SafeAreaView style={{backgroundColor: color.palette.white}}/>
      <SafeAreaView style={{flex: 1, backgroundColor: color.palette.white}}>
        <Header
          headerText="Worker Profile" titlePreset={"headerTitle"}
          preset={"primary"}
          rightIcon={"times"} rightIconPreset={"smallBlue"}
          onRightPress={() => navigation.goBack()}
        />
        <ScrollView style={{flex: 1}}>
          <View style={{marginHorizontal: 20}}>
            <View style={{alignItems: 'center', marginBottom: 20}}>
              <View>
                <AvatarImage
                  onPress={handleAvatarPress}
                  preset={'lgRound'}
                  source={avatar.url ? {uri: avatar.url} : defaultPhoto}/>
                <View style={{position: 'absolute', right: 4, top: 4, display: avatar?.id ? 'flex' : 'none'}}>
                  <SvgIcon onPress={handleAvatarPress} preset={'dark'} icon={'times'} theme={'regular'}/>
                </View>
                <View style={{position: 'absolute', right: 4, bottom: 4}}>
                  <SvgIcon onPress={handleUploadImage} preset={'dark'} icon={'camera'} theme={'solid'}/>
                </View>
              </View>
            </View>
            <View style={MARGIN}>
              <TextField
                value={bio} onChangeText={setBio}
                preset={'primary'}
                placeholder="Few lines about yourself"
                label="Biography" labelPreset="primary"
                multiline
                inputStyle={{height: 120}}
              />
            </View>
            <View style={MARGIN}>
              <View style={{marginBottom: 18}}>
                <SelectTextField
                  numberOfLines={1}
                  preset={'primary'}
                  placeholder="Select from list"
                  label={'Upload CV/ Resume'} labelPreset="primary"
                  rightIcon={'upload'}
                  onPress={handleUploadCv}
                />
              </View>
            </View>
            <View style={MARGIN}>
              <Text preset="primary">Address</Text>
              <TextField
                value={addressLine1} onChangeText={setAddressLine1}
                preset={'primary'}
                placeholder="Address line  1*" style={{marginTop: spacing[2]}}/>
              <TextField
                value={addressLine2} onChangeText={setAddressLine2}
                preset={'primary'}
                placeholder="Address line (if applicable)"
                style={{marginTop: spacing[2]}}
              />
              <TextField
                value={city} onChangeText={setCity}
                preset={'primary'} placeholder="Town/City*" style={{marginTop: spacing[2]}}/>
            </View>
            <View style={[MARGIN, {zIndex: 101}]}>
              <View style={{flexDirection: "row", marginTop: spacing[2]}}>
                <View style={{flex: 1}}>
                  <PickerSelectField
                    onOpen={toggleStateOpen}
                    onClose={toggleStateOpen}
                    value={state}
                    onValueChange={setState} items={listStateItem}
                    preset={"default"}
                    placeholder="State"
                    rightIcon={stateOpen ? 'chevron-down' : 'chevron-right'}
                  />
                </View>
                <View style={{flex: 1, marginLeft: spacing[3]}}>
                  <TextField
                    value={postcode} onChangeText={setPostcode}
                    preset={'primary'} placeholder="Postcode"/>
                </View>
              </View>
            </View>
            <View style={[MARGIN, {zIndex: 100}]}>
              <DatePickerField
                visible={datePickerVisible}
                onChange={handleSetDate}
                value={dob} maxDate={new Date()}
                customInput={
                  <SelectTextField
                    value={dob ? moment(dob).format('DD/MM/YYYY') : null}
                    onPress={() => setDatePickerVisible(!datePickerVisible)}
                    preset={'primary'}
                    placeholder="DD/MM/YY" label="Date Of Birth" labelPreset="primary"/>
                }
              />
            </View>
            <View style={MARGIN}>
              <Text preset="primary" style={{marginBottom: spacing[4]}}>
                Indigenous Status
              </Text>
              <ListDiv
                data={indigenous}
                itemValue={(index) => indigenous[index].name}
                renderIcon={(id) => ({
                  icon: indigenous[id].checked ? "check-square" : "square-regular",
                  theme: indigenous[id].checked ? "solid" : "regular",
                  preset: "primary",
                })}
                onPress={(index) => toggleSelectIndigenous(index)}
              />
            </View>
            <View style={MARGIN}>
              <Text preset="primary" style={{marginBottom: spacing[4]}}>
                Right To Work
              </Text>
              <ListDiv
                data={rightToWork}
                itemValue={(index) => rightToWork[index].name}
                renderIcon={(id) => ({
                  icon: rightToWork[id].checked ? "check-circle" : "circle",
                  theme: rightToWork[id].checked ? "solid" : "regular",
                  preset: "primary",
                })}
                onPress={(index) => toggleSelectRighttowork(index)}
              />
            </View>
            <View style={MARGIN}>
              <SelectTextField
                preset={'primary'}
                placeholder="Upload more"
                label="Upload Right to work"
                labelPreset="primary"
                rightIcon="upload"
                onPress={handleUploadRight}
              />
              <View style={{height: 10}}/>
              <ListDiv
                data={rightToWorkDocs}
                preset={'lightLink'}
                selectPreset={'lightLink'}
                itemValue={(index) => rightToWorkDocs[index].name}
                renderIcon={() => ({
                  icon: "times",
                  theme: "regular",
                  preset: "primary",
                })}
                iconPress={removeRightToWorkDocs}
              />
              <View style={{height: 10}}/>
            </View>
            <View style={MARGIN}>
              <Text preset="primary" style={{marginBottom: spacing[4]}}>Gender</Text>
              <ListDiv
                data={genders}
                itemValue={(index) => genders[index].name}
                renderIcon={(id) => ({
                  icon: genders[id].checked ? "check-circle" : "circle",
                  theme: genders[id].checked ? "solid" : "regular",
                  preset: "primary",
                })}
                onPress={toggleSelectGender}
              />
            </View>
            <View style={MARGIN}>
              <View style={SWITCH}>
                <Text preset={'body1'} style={{fontWeight: 'bold'}}>Returned Worker</Text>
                <View style={{flex: 1, alignItems: "flex-end"}}>
                  <Switch value={returnedWorker} onToggle={setReturnedWorker}/>
                </View>
              </View>
            </View>
            <View style={MARGIN}>
              <View style={SWITCH}>
                <Text preset={'body1'} style={{fontWeight: 'bold'}}>Ex Service Person</Text>
                <View style={{flex: 1, alignItems: "flex-end"}}>
                  <Switch value={exServicePerson} onToggle={setExServicePerson}/>
                </View>
              </View>
            </View>
            <View style={{marginVertical: 60}}>
              <Button onPress={handleSave} preset={'brand'} disabled={loading} text={"Save"}/>
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
      <LoadingModal visible={loading}/>
      <AlertModal/>
    </>
  )
})

export const MARGIN: ViewStyle = {
  marginTop: 20,
}
export const SWITCH: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
}
