import {Instance, types} from "mobx-state-tree"
import {BusinessJobRoleModel, convertBusinessJobRoleModel, convertJobRoleModel, JobRoleModel} from "./job-role";
import {convertKvItemModel, KvItemModel} from "./kv-item";
import {BusinessProfileModel, convertBusinessProfileModel} from "./business-profile";
import {convertUserAccountModel, UserAccountModel} from "./user-account";


export interface IJobTagGroup {
  id: string,
  icon: string,
  text: string,
}

export const JobTags = {
  locations: {
    id: "locations",
    icon: "map",
    text: "Locations",
    label: "Search and select locations for this role",
  },
  industries: {
    id: "industries",
    icon: "industry-alt",
    text: "Industries",
    label: "Search and select industries for this role"
  },
  roles: {
    id: "roles",
    icon: "user-hard-hat",
    text: "Roles",
    label: "Search and select roles for this role",
  },
  experience: {
    id: "experience",
    icon: "diploma",
    text: "Experience",
    label: "Search and select experience for this role"
  },
  skills: {
    id: "skills",
    icon: "certificate",
    text: "Skills",
    label: "Search and select skills for this role",
  },
  qualifications: {
    id: "qualifications",
    icon: "id-badge",
    text: "Qualifications",
    label: "Search and select qualifications for this role"
  },
}

export const CreateJobTags = {
  locations: {
    id: "locations",
    icon: "map",
    text: "Location",
    label: "Search and select location for this role",
    hint: "Search and select location for this role",
  },
  industries: {
    id: "industries",
    icon: "industry-alt",
    text: "Industry",
    label: "Search and select industry for this role",
    hint: "Search and select industry for this role",
  },
  roles: {
    id: "roles",
    icon: "user-hard-hat",
    text: "Role",
    label: "Search and select role for this role",
    hint: "Search and select role for this role",
  },
  experience: {
    id: "experience",
    icon: "diploma",
    text: "Experience",
    label: "Search and select experience for this role",
    hint: "Eg Residential Extensions, 2 year Customer Service",
  },
  skills: {
    id: "skills",
    icon: "certificate",
    text: "Skills",
    label: "Search and select skills for this role",
    hint: "Eg Licence - Forklift",
  },
  qualifications: {
    id: "qualifications",
    icon: "id-badge",
    text: "Qualifications",
    label: "Search and select qualifications for this role",
    hint: "Eg Diploma - Project Management",
  },
}

export type JobTagType = keyof typeof JobTags
export const JobAdvertModel = types.model({
  id: types.optional(types.number, 0),
  title: types.optional(types.string, ''),
  description: types.optional(types.string, ''),
  responsibilities: types.optional(types.string, ''),
  jobRoles: types.optional(types.array(JobRoleModel), []),
  paymentTypes: types.optional(types.array(KvItemModel), []),
  isDraft: types.optional(types.boolean, false),
  closed: types.optional(types.boolean, false),
  businessProfile: types.maybeNull(BusinessProfileModel),
  qualifications: types.optional(types.array(KvItemModel), []),
  owner: types.optional(UserAccountModel, {}),
})
export type IJobAdvertModel = Instance<typeof JobAdvertModel>
export const convertJobAdvertModel = (raw): IJobAdvertModel => ({
  id: raw?.id || 0,
  title: raw?.title || '',
  description: raw?.description || '',
  responsibilities: raw?.responsibilities || '',
  jobRoles: raw?.jobRoles?.data ? raw?.jobRoles?.data?.map(item => convertJobRoleModel(
    item?.attributes ? {
      ...item?.attributes, id: item?.id
    } : item
  )) : raw?.jobRoles?.map(convertKvItemModel),
  paymentTypes: raw?.paymentTypes?.data ? raw?.paymentTypes?.data?.map(item => convertKvItemModel(
    item?.attributes ? {
      ...item?.attributes, id: item?.id
    } : item
  )) : raw?.paymentTypes?.map(convertKvItemModel),
  isDraft: raw?.isDraft || false,
  closed: raw?.closed || false,
  businessProfile: convertBusinessProfileModel(
    raw?.businessProfile?.data?.attributes ? {
      ...raw?.businessProfile?.data?.attributes,
      id: raw?.businessProfile?.data?.id
    } : raw?.businessProfile?.data ? raw?.businessProfile?.data : {
      ...raw?.owner?.data?.attributes?.businessProfile?.data?.attributes,
      id: raw?.owner?.data?.attributes?.businessProfile?.data?.id
    }
  ),
  qualifications: raw?.qualifications?.data?.map((item) => convertKvItemModel({
    ...item?.attributes,
    id: item?.id
  })) || [],
  owner: convertUserAccountModel(raw?.owner?.data?.attributes ? {
    ...raw?.owner?.data?.attributes, id: raw?.owner?.data?.id
  } : raw?.owner?.attributes ? {...raw?.owner?.attributes, id: raw?.owner?.id} : raw?.owner),
})

export const BusinessJobAdvertModel = types.model({
  id: types.optional(types.number, 0),
  title: types.optional(types.string, ''),
  description: types.optional(types.string, ''),
  responsibilities: types.optional(types.string, ''),
  jobRoles: types.optional(types.array(BusinessJobRoleModel), []),
  paymentTypes: types.optional(types.array(KvItemModel), []),
  isDraft: types.optional(types.boolean, false),
  closed: types.optional(types.boolean, false),
  businessProfile: types.maybeNull(BusinessProfileModel),
  qualifications: types.optional(types.array(KvItemModel), []),
})
export type IBusinessJobAdvertModel = Instance<typeof BusinessJobAdvertModel>
export const convertBusinessJobAdvertModel = (raw): IBusinessJobAdvertModel => ({
  id: raw?.id || 0,
  title: raw?.title || '',
  description: raw?.description || '',
  responsibilities: raw?.responsibilities || '',
  jobRoles: raw?.jobRoles?.data ? raw?.jobRoles?.data?.map(item => convertBusinessJobRoleModel(
    item?.attributes ? {
      ...item?.attributes, id: item?.id
    } : item
  )) : raw?.jobRoles?.map(convertKvItemModel),
  paymentTypes: raw?.paymentTypes?.data ? raw?.paymentTypes?.data?.map(item => convertKvItemModel(
    item?.attributes ? {
      ...item?.attributes, id: item?.id
    } : item
  )) : raw?.paymentTypes?.map(convertKvItemModel),
  isDraft: raw?.isDraft || false,
  closed: raw?.closed || false,
  businessProfile: convertBusinessProfileModel(
    raw?.businessProfile?.data?.attributes ? {
      ...raw?.businessProfile?.data?.attributes,
      id: raw?.businessProfile?.data?.id
    } : raw?.businessProfile?.data
  ),
  qualifications: raw?.qualifications?.data?.map((item) => convertKvItemModel({
    ...item?.attributes,
    id: item?.id
  })) || [],
})
