import React from 'react';
import {TouchableOpacity, View, StyleSheet, SafeAreaView} from 'react-native';
import {color} from "../../theme";
import {BREAKPOINT_MOBILE, isDesktop} from "../../utils/global";

export const tagPresets = {
  default: {},
  zeroPad: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  spin: {
    paddingVertical: 20,
    paddingHorizontal: 20,
    alignSelf: 'center'
  },
  alert: {
    paddingVertical: 20,
    paddingHorizontal: 20,
    alignSelf: 'center',
    width: '100%'
  },
}
export type TagPresets = keyof typeof tagPresets

export interface PureBackdropModalProps {
  visible: boolean
  onClose: () => void
  children: React.ReactNode
  preset?: TagPresets,
}

export function PureBackdropModal(props: PureBackdropModalProps) {
  const {visible, onClose, children, preset} = props;

  const modalViewStyle = [
    styles.modalView,
    tagPresets[preset] || tagPresets.default
  ]

  if (!visible) return null;
  return (
    <View style={styles.modal}>
      <View style={styles.modalContainer}>
        <TouchableOpacity style={styles.modalBackdrop} onPress={onClose}/>
        <SafeAreaView style={[styles.modalFullWidth, isDesktop && {width: BREAKPOINT_MOBILE - 210}]}>
          <View style={modalViewStyle}>
            {children}
          </View>
        </SafeAreaView>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  modal: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 8,
  },
  modalBackdrop: {
    backgroundColor: '#00000055',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9,
  },
  modalContainer: {flex: 1, justifyContent: 'center', alignItems: 'center'},
  modalFullWidth: {
    width: '82%',
    zIndex: 99,
  },
  modalView: {
    paddingVertical: 40,
    paddingHorizontal: 20,
    backgroundColor: color.palette.white,
    borderRadius: 10,
  },
});
