import React, {FC, useState} from 'react';
import {View, StyleSheet, SafeAreaView} from 'react-native';

import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {observer} from "mobx-react-lite";
import {color} from "../../../theme";
import {Button, GradientBackground, Header, Text, TextField} from "../../../components";
import {ListDiv} from "../../../components/list-div/list-div";
import {BackdropModal} from "../../../components/backdrop-modal/backdrop-modal";
import {SvgIcon} from "../../../components/icon/svg-icon";
import {validateEmail} from "../../../utils/utils";
import {useStores} from "../../../models";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import {constants} from "../../../utils/constants";
import {isAndroid} from "../../../utils/platform";
import MobileInput from "../../../components/text-field/mobile-input";
import {TypeUserModel} from "../../../models/app/kv-item";
import {NavigatorParamList} from "../../../navigators";

export interface IUserContact {
  firstName: string
  email: string
}

export interface AddContactScreenProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
}

let timeoutVal: NodeJS.Timeout = null

export const AddContactScreen: FC<AddContactScreenProps> = observer(
  ({navigation}) => {
    const {app} = useStores();
    const [resultVisible, setResultVisible] = useState(false);
    const [firstName, setFirstName] = useState('')
    const [email, setEmail] = useState('')
    const [users, setUsers] = useState<Array<IUserContact>>([])
    const [mobile, setMobile] = useState({
      value: '',
      country: 'AU',
    });

    const onSetPhoneNumber = (data: any) => {
      setMobile(data)
    }

    const onDone = () => {
      setResultVisible(false)
      navigation.goBack()
    }

    // const addUser = () => {
    //   if (!validateEmail(email)) {
    //     AlertModalStore.alert('Error', 'Invalid email')
    //     return
    //   }
    //   setUsers([...users, {email, firstName}])
    //   setEmail('')
    // }

    const removeUser = (index) => {
      setUsers(users.filter((it, id) => id !== index))
    }

    const handleCloseResult = async () => {
      if (timeoutVal) clearTimeout(timeoutVal)
      onDone()
    }

    const showResult = () => {
      if (timeoutVal) clearTimeout(timeoutVal)
      setResultVisible(true)
      timeoutVal = setTimeout(handleCloseResult, 1000)
    }

    const handleInvite = async () => {
      // if (!validateEmail(email)) {
      //   AlertModalStore.alert('Error', 'Invalid email')
      //   return
      // }
      const invUser = {
        fistName: firstName,
        email: email,
        mobile: mobile?.value,
        countryCode: mobile?.country,
      }
      const isManager = app?.user?.role === constants.roles.MANAGER
      const res = await app.environment.api.inviteNewContact({
        businessProfileId: isManager
          ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
        invitedUsers: [invUser]
      })
      if (res.kind === 'ok') {
        if (res?.items?.length > 0) {
          app.setListSelectedContact([
            ...app?.listSelectedContact,
            {id: res?.items[0]?.id, name: invUser.email, checked: true, avatar: ''} as TypeUserModel,
          ])
        }
        showResult()
      } else {
        AlertModalStore.alert('Error', res.message)
      }
    }

    const isValidEmail = validateEmail(email)
    const isValid = (isValidEmail || mobile?.value) && firstName.trim().length !== 0;
    return (
      <>
        <PureBottomModal onClose={() => navigation.goBack()}>
          <SafeAreaView style={styles.container}>
            <GradientBackground colors={[color.palette.gradientStart, color.palette.gradientStop]}/>
            <Header
              preset={'modalHeader'}
              headerText={'Invite New Contact'}
              titlePreset={'headerTitle'}
              rightIcon={'times'} rightIconPreset={'smallBlue'} onRightPress={() => navigation.goBack()}
            />
            <View style={styles.body}>
              <View style={{flex: 1}}>
                <Text preset="textName" style={{color: color.palette.black, fontStyle: 'italic', lineHeight: 24}}>
                  You will need to enter your Contact(s) name and their Email Address
                </Text>
                <View style={{marginTop: 30, paddingBottom: 20, flexDirection: 'column'}}>
                  <TextField
                    preset={'brand'}
                    autoCapitalize='none'
                    value={firstName}
                    onChangeText={setFirstName}
                    label={'Display Name *'}
                    labelPreset={'label'}
                    placeholder={'Enter Full Name'}
                    style={{marginBottom: 20}}
                  />
                  <TextField
                    preset={'brand'}
                    autoCapitalize='none'
                    value={email}
                    onChangeText={setEmail}
                    label={'Email Address'} labelPreset={'label'}
                    placeholder={'Enter Email Address'}
                  />
                  <View style={{
                    marginTop: 4,
                    marginHorizontal: isAndroid ? 4 : 0,
                    display: (!email || isValidEmail) ? 'none' : 'flex'
                  }}>
                    <Text preset={'danger'} text={'Invalid email'}/>
                  </View>
                </View>
                <View style={{display: 'none'}}>
                  <View style={{marginHorizontal: isAndroid ? 4 : 0,}}>
                    <Text preset={'primary'} text={'Mobile Number'}/>
                  </View>
                  <View style={{zIndex: 999}}>
                    <MobileInput
                      setPhoneNumber={onSetPhoneNumber}
                      defaultValue={mobile?.value}
                      placeholder={'Enter your number'}
                      codeTextStyle={{color: color.palette.black80}}
                      defaultCode={mobile?.country}
                      textInputProps={{maxLength: 10}}
                      preset="brand"
                    />
                  </View>
                </View>
                <View>
                  <ListDiv
                    data={users}
                    itemValue={(index) => users[index]?.email}
                    renderIcon={() => ({icon: 'minus-circle', theme: 'regular', preset: 'default'})}
                    iconPress={(index) => removeUser(index)}
                  />
                </View>
              </View>
              <View style={{paddingTop: 10, marginBottom: isAndroid ? 30 : 10}}>
                <Button
                  disabled={!isValid}
                  preset={!isValid ? 'disabled' : 'brand'}
                  onPress={handleInvite}
                  text={'Invite Contact'}/>
              </View>
            </View>
          </SafeAreaView>
        </PureBottomModal>
        <BackdropModal visible={resultVisible} onClose={onDone}>
          <View>
            <View style={{
              alignItems: "center",
              justifyContent: 'center',
              padding: 10,
            }}>
              <SvgIcon icon={'check-circle'} preset={'alertIcon'}/>
              <View style={{paddingTop: 18, paddingBottom: 8}}>
                <Text preset={'alertTitle'}>Done</Text>
              </View>
            </View>
          </View>
        </BackdropModal>
        <AlertModal/>
      </>
    )
  },
)

const styles = StyleSheet.create({
  body: {
    flex: 1,
    paddingHorizontal: 20,
  },
  container: {
    flex: 1,
  },
  contentContainerStyle: {
    marginVertical: 20,
    paddingHorizontal: 10,
  },
  mainContainer: {},
  modalBtn: {
    marginVertical: 8
  },
});
