import {Instance, types} from "mobx-state-tree"
import {convertFileUploadModel, FileUploadModel} from "./file-upload";
import {convertKvItemModel, KvItemModel} from "./kv-item";
import {convertWorkerProfileModel, WorkerProfileModel} from "./worker-profile";

export const BusinessApplicationModel = types.model({
  id: types.optional(types.number, 0),
  workerBookmark: types.optional(types.boolean, false),
  myCv: types.optional(FileUploadModel, {}),
  workerProfile: types.maybeNull(WorkerProfileModel),
  publishedAt: types.optional(types.string, ''),
  createdAt: types.optional(types.string, ''),
  approved: types.optional(types.boolean, false),
  shortlisted: types.optional(types.boolean, false),
  status: types.optional(types.string, ''),
  rate: types.optional(types.number, 0),

  experiences: types.optional(types.array(KvItemModel), []),
  skills: types.optional(types.array(KvItemModel), []),
  qualifications: types.optional(types.array(KvItemModel), []),
})
export type IBusinessApplicationModel = Instance<typeof BusinessApplicationModel>
export const convertBusinessApplicationModel = (raw): IBusinessApplicationModel => ({
  id: raw?.id || 0,
  workerBookmark: raw?.workerBookmark || false,
  myCv: convertFileUploadModel(raw?.myCv?.data?.attributes ? {
    ...raw?.myCv?.data?.attributes,
    id: raw?.myCv?.data?.id
  } : {
    ...raw?.myCv?.attributes,
    id: raw?.myCv?.id
  }),
  workerProfile: convertWorkerProfileModel(
    raw?.workerProfile?.data?.attributes ? {
      ...raw?.workerProfile?.data?.attributes,
      id: raw?.workerProfile?.data?.id
    } : raw?.workerProfile?.data
  ),
  publishedAt: raw?.publishedAt || '',
  createdAt: raw?.createdAt || '',
  approved: raw?.approved || false,
  shortlisted: raw?.shortlisted || false,
  status: raw?.status || '',
  rate: raw?.rate || 0,

  experiences: raw?.experiences?.data?.map((item) => convertKvItemModel(
    {...item?.attributes, id: item?.id}
    )) || [],
  skills: raw?.skills?.data?.map((item) => convertKvItemModel(
    {...item?.attributes, id: item?.id}
    )) || [],
  qualifications: raw?.qualifications?.data?.map((item) => convertKvItemModel(
    {...item?.attributes, id: item?.id}
    )) || [],
})
