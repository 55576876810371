import * as Types from "../api/api.types";
import {ApolloClient, FetchResult, gql} from "@apollo/client";
import {MutationBaseOptions} from "@apollo/client/core/watchQueryOptions";
import {client} from "./api-config";
import {convertUserAccountModel} from "../../models/app/user-account";

export type FetchFunc = (options: MutationBaseOptions) => PromiseLike<FetchResult<any>>
export const ApolloQuery = {
  userLoginQuery: gql`
    mutation login($identifier: String!, $password: String!) {
      login(input:{identifier: $identifier, password: $password}) {
        jwt
      }
    }
  `,
  getMeQuery: gql`
    query {
      me {
        id
        username
        email
        confirmed
        role {
          id
          name
          description
          type
        }
      }
    }
  `,
  // getMeQuery: gql`
  //   query me {
  //     me {
  //       user {
  //         id
  //         firstName
  //         lastName
  //         username
  //         email
  //         mobile
  //         countryCode
  //         provider
  //         confirmed
  //         blocked
  //         userRoles
  //         businessProfile {
  //           city
  //           countryCode
  //           createdAt
  //           fbLink
  //           id
  //           instaLink
  //           logo {
  //             id
  //             name
  //             alternativeText
  //             caption
  //             width
  //             height
  //             url
  //             previewUrl
  //             provider
  //           }
  //           name
  //           outline
  //           postcode
  //           publishedAt
  //           telephone
  //           twLink
  //           updatedAt
  //           website
  //         }
  //         workerProfile {
  //           id
  //           travelDistance
  //           bio
  //           preferredTravelDistance
  //           availableWeeks
  //           unavailableWeeks
  //           availableTime
  //           addressLine1
  //           addressLine2
  //           city
  //           state
  //           postcode
  //           dob
  //           gender
  //           covidVacStatus
  //           returnedWorker
  //           exServicePerson
  //           preferredLocations {
  //             id
  //             name
  //           }
  //           myLocation
  //           industry {
  //             id
  //             name
  //           }
  //           industries {
  //             id
  //             name
  //           }
  //           experiences {
  //             id
  //             name
  //           }
  //           skills {
  //             id
  //             name
  //           }
  //           qualifications {
  //             id
  //             name
  //           }
  //           workerRoles {
  //             id
  //             name
  //           }
  //           rightToWorkDocs {
  //             id
  //             name
  //             alternativeText
  //             caption
  //             width
  //             height
  //             url
  //             previewUrl
  //             provider
  //           }
  //           document {
  //             id
  //             name
  //             alternativeText
  //             caption
  //             width
  //             height
  //             url
  //             previewUrl
  //             provider
  //           }
  //           myCv {
  //             id
  //             name
  //             alternativeText
  //             caption
  //             width
  //             height
  //             url
  //             previewUrl
  //             provider
  //           }
  //           photo {
  //             id
  //             name
  //             alternativeText
  //             caption
  //             width
  //             height
  //             url
  //             previewUrl
  //             provider
  //           }
  //         }
  //       }
  //     }
  //   }
  // `,
}

export class ApolloApi {
  client: ApolloClient<any>

  constructor() {
    this.client = client
  }

  async userLogin(email: string, password: string): Promise<Types.LoginResult> {
    try {
      const response = await this.client.mutate({
        mutation: ApolloQuery.userLoginQuery,
        variables: {
          identifier: email,
          password: password
        }
      })
      const data = response?.data?.login

      return {kind: "ok", userAccount: convertUserAccountModel(data?.user), jwt: data?.jwt}
    } catch (e) {
      return {kind: "bad-data", message: e?.message}
    }
  }

  async getMe(): Promise<Types.GetMeResult> {
    try {
      const response = await this.client.query({
        query: ApolloQuery.getMeQuery
      })
      const data = response?.data?.me
      console.log(data, 11)
      const userAccount = convertUserAccountModel(data)
      return {kind: "ok", userAccount}
    } catch (e) {
      return {kind: "bad-data", message: e?.message}
    }
  }

}
