import React from 'react';
import {View, StyleProp, ViewStyle} from 'react-native';
import {color} from "../../theme";
import {Text} from "../text/text";
import {TextPresets} from "../text/text.presets";

export interface FlatCardProps {
  title: string
  noBorder?: boolean
  titlePreset?: TextPresets
  children: React.ReactNode
}

const CONTAINER: StyleProp<ViewStyle> = {
  paddingVertical: 18,
}

export function FlatCard(props: FlatCardProps) {
  const {title, noBorder, titlePreset, children} = props;

  return (
    <View style={CONTAINER}>
      <View style={{
        paddingBottom: 6, marginBottom: 12,
        borderBottomWidth: 1, borderBottomColor: noBorder ? color.palette.transparent : color.palette.bgBlue,
      }}>
        <Text preset={titlePreset || 'mediumBold'}>{title}</Text>
      </View>
      <View>
        {children}
      </View>
    </View>
  );
}
