// Use this import if you want to use "env.js" file
// const { API_URL } = require("../../config/env")
// Or just specify it directly like this:
// const API_URL = "https://api.tap4work.com.au/api"
// const API_SOCKET_URL = "wss://api.tap4work.com.au/"
const API_URL = process.env.REACT_APP_API_URL
const API_SOCKET_URL = process.env.REACT_APP_API_SOCKET_URL
// const API_URL = "http://localhost:1337/api"
// const API_SOCKET_URL = "ws://localhost:1337/"

/**
 * The options used to configure the API.
 */
export interface ApiConfig {
  /**
   * The URL of the api.
   */
  url: string

  socketUrl: string

  /**
   * Milliseconds before we timeout the request.
   */
  timeout: number
}

/**
 * The default configuration for the app.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  url: API_URL || "https://jsonplaceholder.typicode.com",
  socketUrl: API_SOCKET_URL || "https://jsonplaceholder.typicode.com",
  timeout: 80000,
}
