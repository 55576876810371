import React, {FC} from "react";
import {SafeAreaView, View, TouchableOpacity} from "react-native";
import {NativeStackNavigationProp} from "react-native-screens/native-stack";
import {CommonActions, ParamListBase, RouteProp} from "@react-navigation/native";
import {color} from "../../theme";
import {AlertModalStore} from "../../models/app/alert-modal-store";
import {Button, GradientBackground, Header, Text} from "../../components";
import fonts from "../../theme/base_fonts";
import {useStores} from "../../models";
import {AlertModal} from "../../components/backdrop-modal/alert-modal";
import {NavigatorParamList} from "../../navigators";
import {constants} from "../../utils/constants";
import {PureBottomModal} from "../../components/backdrop-modal/pure-bottom-modal";

// const CELL_COUNT = 4
const CELL_LIST = [0, 1, 2, 3]
let sendDate
const MIN_SECONDS = 30

export interface SignInPasscodeScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'signInPasscodeScreen'>
}

export const SignInPasscodeScreen: FC<SignInPasscodeScreenProps> = ({navigation, route}) => {
  const email = route.params.email
  const {app} = useStores();
  const [value, setValue] = React.useState("");

  const checkPasscode = async () => {
    if (value.length >= 4) {
      const res = await app.environment.api.validatePin(email, value)
      if (res.kind === 'ok' && res.jwt) {
        await app.setAuth(res.jwt)
        const getMe = await app.environment.api.getMe()
        if (getMe.kind !== 'ok') {
          AlertModalStore.alert('Error', getMe.message)
        } else {
          await app.setUserAccount(getMe.userAccount)
          await app.user.setRole(constants.roles.USER)
          await app.getUserRoles()
          navigation.dispatch(
            CommonActions.reset({
              index: 1, routes: [
                {name: 'main'},
                {name: 'userAccountEditScreen', params: {type: 'edit', requirePassword: true}}
              ],
            })
          );
          // navigation.goBack()
          // navigation.navigate('userAccountEditScreen', {type: 'edit', requirePassword: true})
        }
      } else {
        AlertModalStore.alert('Error', 'Wrong PIN')
      }
    }
  }

  const onNext = () => {
    if (value.length >= 4) checkPasscode()
    else AlertModalStore.alert("Oops", "Invalid Passcode");
  };

  const handleResendPin = async () => {
    const deltaTime = (new Date().getTime() - sendDate) / 1000
    if (deltaTime <= MIN_SECONDS) {
      AlertModalStore.alert('Error',
        `You may request for another PIN ${MIN_SECONDS} seconds after your initial request.`
      )
      return
    }
    const res = await app.environment.api.sendPin(email)
    if (res.kind !== 'ok') {
      AlertModalStore.alert("Error", res.message)
    } else if (!res.userAccount.id) {
      AlertModalStore.alert("Error", 'Account not exists.')
    } else {
      sendDate = new Date().getTime()
      AlertModalStore.alert('Success', 'Pin resent.')
    }
  };

  return (
    <>
      <PureBottomModal onClose={() => navigation.goBack()} isStatic={true}>
        <SafeAreaView style={{flex: 1, backgroundColor: color.palette.white}}>
          <GradientBackground colors={[color.palette.gradientStart, color.palette.gradientStop]}/>
          <Header
            preset={"modalHeader"}
            rightIcon={'times'} rightIconPreset="smallBlue" onRightPress={() => navigation.goBack()}
          />
          <View style={{flex: 1, paddingHorizontal: 15, paddingTop: 80}}>
            <View style={{paddingHorizontal: 14}}>
              <Text preset={'h1'}>Enter Your PIN</Text>
              <View style={{height: 6}}/>
              <Text preset={'body1'}>
                We sent an auth code to your email. please enter the code to activate your account.
              </Text>
            </View>
            <View style={{flexDirection: 'row', marginTop: 24}}>
              {CELL_LIST.map((item, index) => {
                const symbol = true
                return (
                  <View
                    key={index}
                    style={{
                      backgroundColor: color.palette.white, flex: 1, aspectRatio: 1,
                      marginLeft: index !== 0 ? 12 : 0,
                      borderRadius: 16, alignItems: "center", justifyContent: "center",
                      shadowColor: color.palette.shadow,
                      shadowOffset: {width: 0, height: 0},
                      shadowOpacity: 0.1,
                      shadowRadius: 6,
                      elevation: 5,
                    }}
                  >
                    <input
                      id={`pin-${item}`}
                      onInput={() => {
                        if (item < 3) document.getElementById(`pin-${item + 1}`).focus()
                        setValue(
                          CELL_LIST
                            .map(cel => (document.getElementById(`pin-${cel}`) as HTMLInputElement)?.value)
                            .join('')
                        )
                      }}
                      onFocus={(ev) => {
                        ev.target.select()
                      }}
                      style={{
                        ...fonts.type.base(40, color.palette.dark), fontWeight: symbol ? 'bold' : 'normal',
                        marginTop: symbol ? 0 : -8, borderWidth: 0, textAlign: "center",
                        width: '100%', height: '100%'
                      }} maxLength={1}/>
                  </View>
                )
              })}
            </View>
            {/*
        <CodeField
          autoFocus
          // accessibilityValue={value}
          ref={ref}
          value={value}
          cellCount={CELL_COUNT}
          onChangeText={setValue}
          rootStyle={{marginTop: 24}}
          renderCell={({index, symbol, isFocused}) => (
            <View
              key={index}
              style={{
                backgroundColor: color.palette.white, flex: 1, aspectRatio: 1,
                marginLeft: index !== 0 ? 12 : 0,
                borderRadius: 16, alignItems: "center", justifyContent: "center",
                shadowColor: "#000",
                shadowOffset: {width: 0, height: 1,},
                shadowOpacity: 0.04, shadowRadius: 9.84, elevation: 5,
              }}
            >
              <Text
                style={{
                  ...fonts.type.base(40, color.palette.dark), fontWeight: symbol ? 'bold' : 'normal',
                  marginTop: symbol ? 0 : -8,
                }}
                onLayout={getCellOnLayoutHandler(index)}
              >{symbol || (isFocused ? <Cursor/> : null)}</Text>
            </View>
          )}
          keyboardType="number-pad"
        />
*/}
            <TouchableOpacity onPress={handleResendPin}>
              <Text
                style={{
                  ...fonts.type.base(14, color.palette.primary),
                  marginTop: 24, marginHorizontal: 13, textDecorationLine: "underline",
                }}
              >
                Resend PIN
              </Text>
            </TouchableOpacity>
          </View>
          <View style={{justifyContent: "center", marginBottom: 20, paddingHorizontal: 20}}>
            <Button preset={'brand'} onPress={onNext} text={'Continue'}/>
          </View>
        </SafeAreaView>
      </PureBottomModal>
      <AlertModal/>
    </>
  );
};
