import * as React from "react"
import {View, SafeAreaView} from "react-native"
import {FC} from "react"
import {ParamListBase, RouteProp, CommonActions} from "@react-navigation/native"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Button, Text} from "../../components"
import {color} from "../../theme";
import {Header} from "../../components";
import {NavigatorParamList} from "../../navigators";

export interface ContactPreviewScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'contactPreviewScreen'>
}

export const ContactPreviewScreen: FC<ContactPreviewScreenProps> = ({navigation, route}) => {
  const goHome = () => {
    navigation.dispatch(
      CommonActions.reset({index: 0, routes: [{name: 'main'}],})
    );
  }

  const continueApp = () => {
    window.location.href = `${process.env.REACT_APP_SCHEMES}main`
    goHome()
  }

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: color.palette.white,}}>
      <Header
        preset={"transparent"}
        rightIcon={'times'} rightIconPreset="smallBlue" onRightPress={goHome}
      />
      <View style={{paddingHorizontal: 16, flex: 1, justifyContent: 'space-between'}}>
        <View>
          <Text preset={"headerTitle"}>Accept Invite</Text>
          <View style={{height: 12}}/>
          <Text preset={"body1"}>Please open app to accept invite.</Text>
        </View>

        <View style={{}}>
          <Button
            onPress={continueApp}
            text="Continue to App" preset="primary"/>
        </View>
      </View>
    </SafeAreaView>
  )
}

