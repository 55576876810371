import React, {FC, useEffect, useState} from "react"
import {SafeAreaView, ScrollView, View, ViewStyle} from "react-native"
import {Button, Header, TextField} from "../../../components"
import {color} from "../../../theme"
import {KvSkillPath} from "../../../models/app/advanced-skill";
import {useStores} from "../../../models";
import {IKvItemModel} from "../../../models/app/kv-item";
import {SelectTextField} from "../../../components/text-field/select-text-field";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {WorkerSkillStore} from "../../../models/app/worker-skill-store";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {ParamListBase, RouteProp} from "@react-navigation/native";
import {observer} from "mobx-react-lite";
import {NavigatorParamList} from "../../../navigators";
import {useFilePicker} from "use-file-picker";
import {PickerSelectField} from "../../../components/picker-select/picker-select";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";

const MONTHS: string[] = Array(12).fill(1)
  .map((value, idx) => String(value + idx).padStart(2, '0'))

const MAX_YEAR = new Date().getFullYear(), MIN_YEAR = 1960
const YEARS: string[] = Array(MAX_YEAR - MIN_YEAR + 1).fill(MAX_YEAR)
  .map((value, idx) => String(value - idx))

export interface WorkerAddSkillScreenProps {
  route: RouteProp<NavigatorParamList, 'workerAddSkillScreen'>
  navigation: NativeStackNavigationProp<ParamListBase>
}

export const WorkerAddSkillScreen: FC<WorkerAddSkillScreenProps> = observer(({route, navigation}) => {
  const {app} = useStores();
  const originId = route.params?.originId;
  const skillCategory = WorkerSkillStore.skillCategory;
  const [loading, setLoading] = useState(false)

  const [description, setDescription] = useState('')
  const [licenceRegistrationNo, setLicenceRegistrationNo] = useState('')
  const [skillClass, setSkillClass] = useState('')
  const [state, setState] = useState(0)
  const [stateOpen, setStateOpen] = useState(false)
  const [stateList, setStateList] = useState<Array<IKvItemModel>>([])
  const [document, setDocument] = useState<IKvItemModel>({id: 0, name: null})
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [openDocFileSelector, docFileResult] = useFilePicker({
    accept: ['.pdf', '.doc', '.docx', 'image/*'],
    maxFileSize: 2,
  });
  const myDoc: File = docFileResult.plainFiles?.length > 0 ? docFileResult.plainFiles[0] : null

  const loadData = async () => {
    try {
      setLoading(true)
      const stateRes = await app.environment.api.getKvItemList(KvSkillPath.states)
      if (stateRes.kind === 'ok') {
        setStateList(stateRes.items)
      }
      if (originId) {
        const res = await app.environment.api.getUserSkillDetail(originId)
        if (res.kind === 'ok') {
          WorkerSkillStore.setSkillCategory(res.item?.skillCategory)
          setDescription(res.item?.description)
        } else {
          AlertModalStore.alert('Error', res.message)
        }
      } else {
        WorkerSkillStore.setSkillCategory(null)
        setDescription('')
      }
      setLicenceRegistrationNo('')
      setSkillClass('')
      setMonth('')
      setYear('')
      setState(0)
      setDocument({id: 0, name: null})
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    loadData()
  }, [])

  const toggleStateOpen = () => {
    setStateOpen(!stateOpen)
  }

  useEffect(() => {
    handleUploadFile()
  }, [myDoc])

  const handleUploadFile = async () => {
    if (!myDoc) return
    try {
      setLoading(true)
      const res = await app.environment.api.uploadFile(myDoc)
      if (res.kind === 'ok') {
        setDocument({id: res.file.id, name: res.file.name})
      } else {
        AlertModalStore.alert('Error', 'Can not upload file')
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = async () => {
    if (!originId && (
      !skillCategory?.id || !description || !state
    )) {
      AlertModalStore.alert('Error', 'Please enter require fields.')
      return
    }
    let skillId = originId
    if (!originId) {
      const res = await app.environment.api.editUserSkill({
        skillCategory: skillCategory?.id,
        ...(description ? {description} : null),
      })
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message)
        return
      }
      skillId = res.item.id
    }
    const workerSkillsRes = await app.environment.api.generalApiCreate(KvSkillPath.workerSkills, {
      skill: skillId,
      skillClass,
      ...(state ? {state} : null),
      ...(document.id ? {document: document.id} : null),
      ...(licenceRegistrationNo ? {licenceRegistrationNo} : null),
      month, year
    })
    if (workerSkillsRes.kind !== 'ok') {
      AlertModalStore.alert('Error', workerSkillsRes.message)
      return
    }
    await app.environment.api.updateWorkerProfileData(app?.userAccount?.workerProfile?.id, {
      skills: [...app?.userAccount?.workerProfile?.skills?.map(it => it.id), skillId],
    })
    onClose()
  }

  const onClose = () => navigation.goBack()
  const onSelectSkillCategory = () => navigation.navigate('workerSkillCategoryScreen')

  return (
    <>
      <PureBottomModal onClose={onClose}>
        <SafeAreaView style={{flex: 1}}>
          <Header
            preset={"modalHeader"}
            titlePreset={"headerTitle"}
            headerText={'Skills'}
            rightIcon={"times"}
            rightIconPreset={"smallBlue"}
            onRightPress={onClose}
          />
          <ScrollView style={{flex: 1}} showsVerticalScrollIndicator={false}>
            <View style={{paddingHorizontal: 20, paddingBottom: 50}}>
              <View style={INPUT}>
                <SelectTextField
                  preset={originId ? 'primaryDisabled' : 'primary'}
                  label={"*Category"} labelPreset={"h4"}
                  value={skillCategory?.name} onPress={originId ? null : onSelectSkillCategory}
                />
              </View>
              <View style={INPUT}>
                <TextField
                  preset={originId ? 'primaryDisabled' : 'primary'} editable={!originId}
                  label={"*Description"} labelPreset={"h4"}
                  value={description} onChangeText={setDescription}
                />
              </View>
              <View style={INPUT}>
                <TextField
                  preset={'primary'}
                  label={"Licence/Registration No."} labelPreset={"h4"}
                  value={licenceRegistrationNo} onChangeText={setLicenceRegistrationNo}
                />
              </View>
              <View style={INPUT}>
                <TextField
                  preset={'primary'}
                  labelPreset={"h4"}
                  label={"Class (if any)"}
                  value={skillClass} onChangeText={setSkillClass}
                />
              </View>
              <View style={{flexDirection: "row", paddingBottom: 15, zIndex: 999}}>
                <View style={{flex: 1, paddingRight: 15}}>
                  <PickerSelectField
                    onValueChange={setMonth} value={month}
                    items={MONTHS.map(item => ({key: item, value: item, label: item}))}
                    labelPreset={"h4"} label={"Month"}
                    placeholder="..."
                    rightIcon={'chevron-down'}
                  />
                </View>
                <View style={{flex: 1}}>
                  <PickerSelectField
                    onValueChange={setYear} value={year}
                    items={YEARS.map(item => ({key: item, value: item, label: item}))}
                    labelPreset={"h4"} label={"Year"}
                    placeholder="..."
                    rightIcon={'chevron-down'}
                  />
                </View>
              </View>

              <View style={[INPUT, {zIndex: 99}]}>
                <PickerSelectField
                  onOpen={toggleStateOpen}
                  onClose={toggleStateOpen}
                  value={state}
                  onValueChange={setState} items={stateList.map(item => ({
                  key: item?.id, value: item?.id, label: item?.name
                }))}
                  preset={"default"}
                  label={"State/Territory"} labelPreset={"h4"}
                  placeholder="Select from list"
                  rightIcon={stateOpen ? 'chevron-down' : 'chevron-right'}
                />
              </View>
              <View style={[INPUT, {zIndex: 9}]}>
                <SelectTextField
                  value={document?.name || ''} onPress={openDocFileSelector}
                  numberOfLines={1}
                  preset={'primary'}
                  rightIcon={'upload'} rightIconTheme={'solid'} rightIconPreset={'primary'}
                  labelPreset={"h4"} label={'Upload Document'}
                  placeholder={"Select"}/>
              </View>
              <Button text="Submit" disabled={loading} preset={loading ? 'disabled' : 'brand'} onPress={handleSubmit}/>
            </View>
          </ScrollView>
        </SafeAreaView>
      </PureBottomModal>
      <AlertModal/>
    </>
  )
})

export const CONTAINER: ViewStyle = {
  paddingBottom: 10,
  backgroundColor: color.palette.white,
}
export const INPUT: ViewStyle = {
  paddingBottom: 25,
}
