import * as React from "react"
import {View, SafeAreaView, ViewStyle, ImageBackground, Dimensions} from "react-native"
import {FC, useCallback, useEffect, useState} from "react"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Text, TextField, Header, Button} from "../../../components"


import {NavigatorParamList} from "../../../navigators";
import {useStores} from "../../../models";
import {convertUserAccountModel, USER_STATUS} from "../../../models/app/user-account";
import {validateEmail} from "../../../utils/utils";
import {debounce} from "../../../utils/debounce";
import {SpinIcon} from "../../../components/icon/spin-icon";
import {CommonActions, RouteProp, useFocusEffect} from "@react-navigation/native";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";
import {isAndroid} from "../../../utils/platform";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import {BREAKPOINT_MOBILE, width} from "../../../utils/global";
import {color} from "../../../theme";

const backgroundImage = require("../../../../assets/images/icons/cloud_bg.png")

export interface EmailCheckProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
  route: RouteProp<NavigatorParamList, 'signInEmailCheckScreen'>
}

const EmailType = {
  exist: 'Exist',
  notExist: 'NotExist',
  unknown: 'Unknown',
}
export const EmailCheckScreen: FC<EmailCheckProps> = ({navigation, route}) => {
  const loadEmail = route.params?.loadEmail
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailType, setEmailType] = useState(EmailType.unknown);
  const [accountStatus, setAccountStatus] = useState('');
  const isDesktop =  width > BREAKPOINT_MOBILE;
  const {app} = useStores();

  let _mount: boolean = true

  useEffect(() => {
    _mount = true
    app.setAuth(null)
    if (loadEmail) setEmail(app?.userAccount?.email || '')
    return () => {
      _mount = false
    }
  }, [])

  useFocusEffect(useCallback(() => {
    if (app?.userAccount?.email) handleChangeEmail(app?.userAccount?.email)
  }, []))

  const onNext = async () => {
    app.setUserAccount(convertUserAccountModel({email: email}))
    navigation.navigate('userAccountEditScreen', {type: 'create'})
  }

  const onContinue = async () => {
    if (!email || !validateEmail(email)) {
      AlertModalStore.alert('Error', 'Email is invalid')
      return
    }
    app.setUserAccount(convertUserAccountModel({email: email}))
    if (accountStatus === USER_STATUS.unregistered.key || accountStatus === USER_STATUS.pinVerified.key) {
      app.environment.api.sendPin(email)
      navigation.navigate('signInPasscodeScreen', {email})
    } else navigation.navigate('signInPasswordScreen', {jobRoleId: route?.params?.jobRoleId, email})
  }

  const checkMail = async (email) => {
    if (!_mount) return
    try {
      const res = await app.environment.api.findMe(email)
      if (res.kind === 'ok' && res.userAccount.id) {
        setEmailType(EmailType.exist)
        setAccountStatus(res?.userAccount?.status)
      } else if (res.kind === 'ok') {
        setEmailType(EmailType.notExist)
      } else {
        AlertModalStore.alert('Error', res.message)
        setEmailType(EmailType.unknown)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false);
    }
  }

  const delayCheckMail = useCallback(debounce(checkMail, 600), []);

  const handleChangeEmail = (val) => {
    if (validateEmail(val)) {
      if (!loading) setLoading(true)
      // @ts-ignore
      delayCheckMail(val)
    }
    setEmail(val?.toLowerCase())
  }

  const heightImage = Dimensions.get('window').height

  const handleGoBack = () => {
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [
          {name: 'main'},
        ],
      })
    );
    // navigation.goBack();
  }

  return (
    <>
      <PureBottomModal onClose={handleGoBack} isStatic={true}>
        <SafeAreaView style={{flex: 1}}>
          <ImageBackground source={backgroundImage} style={{
            width: isDesktop ?  '100%' : document.body.clientWidth,
            height: heightImage,
            position: "absolute", top: isAndroid ? 0 : 20, left: 0, right: 0, bottom: 0, zIndex: -9,
            backgroundColor: color.palette.white
          }} imageStyle={{resizeMode: 'stretch'}}/>
          <View>
            <Header
              preset={"modalHeader"} style={{marginHorizontal: 10}}
              rightIcon={'times'} rightIconPreset="smallBlue" onRightPress={handleGoBack}
            />
            <View style={{paddingHorizontal: 16, marginTop: isAndroid ? 20 : 90}}>
              <View style={{marginHorizontal: isAndroid ? 4 : 0}}>
                <Text preset={"h1"}>It all starts with an email</Text>
              </View>
              <View style={{height: 16}}/>
              <TextField
                autoCapitalize='none'
                value={email} onChangeText={handleChangeEmail}
                preset={'primary'}
                placeholder="Enter your email here"
                rightIcon={loading ? <SpinIcon icon={'circle-notch'}/> : null}
              />

              {emailType === EmailType.exist && (
                <View style={BUTTON_CONTAINER}>
                  <Button
                    text="Continue" preset="brand"
                    onPress={onContinue}/>
                </View>
              )}
              {emailType === EmailType.notExist && (
                <View style={BUTTON_CONTAINER}>
                  <Button
                    text="Next" preset="brand"
                    onPress={onNext}
                  />
                </View>
              )}
            </View>
          </View>
        </SafeAreaView>
      </PureBottomModal>
      <AlertModal/>
    </>
  )
}

const BUTTON_CONTAINER: ViewStyle = {
  width: '100%',
  marginVertical: 18,
}
