import React, {FC, useState} from "react"
import {View, ScrollView, SafeAreaView, TouchableOpacity, StyleSheet} from "react-native"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {observer} from "mobx-react-lite"
import {Text, Tag, Header, Button} from "../../../components"
import {color, spacing} from "../../../theme"
import {SvgIcon} from "../../../components/icon/svg-icon"
import {AvatarImage} from "../../../components/avatar-image/avatar-image"
import type {RouteProp} from "@react-navigation/native";
import {NavigatorParamList} from "../../../navigators";
import {ParamListBase} from "@react-navigation/native";
import {useStores} from "../../../models";
import {IJobRoleAdModel} from "../../../models/app/job-role-ad";
import {IKvItemModel} from "../../../models/app/kv-item";
import {SelectTextField} from "../../../components/text-field/select-text-field";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import {stringToDateStr} from "../../../utils/utils";
import {AdvertRoleStore} from "../../../models/app/advert-role";
import {constants} from "../../../utils/constants";
import AvatarDefault from "../../../components/avatar-image/AvatarDefault";

export interface JobsAdPreviewScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'jobsAdDetailScreen'>
}


export const JobsAdPreviewScreen: FC<JobsAdPreviewScreenProps> = observer(({navigation}) => {
  const {app} = useStores()
  const [seeMore, setSeeMore] = useState(false)

  const jobRole: IJobRoleAdModel = AdvertRoleStore.previewJobRoleAd
  const experiences: IKvItemModel[] = AdvertRoleStore?.experience
  const skills: IKvItemModel[] = AdvertRoleStore?.skills
  const qualifications: IKvItemModel[] = AdvertRoleStore?.qualifications

  const location = AdvertRoleStore?.locations?.length > 0 ? AdvertRoleStore?.locations[0]?.name : ''
  const roleTitle = jobRole?.jobAd?.title || ''
  const roleDescription = jobRole?.role?.name || ''
  const isManager = app?.user?.role === constants.roles.MANAGER
  const logo = isManager
    ? app?.user?.businessProfile?.logo?.url :
    !app?.userAccount?.businessProfile?.id
      ? AdvertRoleStore.previewLogo?.url : app?.userAccount?.businessProfile?.logo?.url
  const paymentType = jobRole?.jobAd?.paymentTypes?.length > 0 ? jobRole?.jobAd?.paymentTypes[0]?.name : ''
  return (
    <>
      <PureBottomModal onClose={() => navigation.goBack()}>
        <SafeAreaView style={{flex: 1}}>
          <Header
            preset={"transparent"}
            header={() => (
              <View style={{flexDirection: 'row', alignItems: "center"}}>
                <View style={{}}>
                  {logo ?
                      <AvatarImage
                      source={{uri: logo}}
                      preset={"businessAvatar"}/> :
                      <AvatarDefault name={app?.user?.businessProfile?.name} preset={"businessAvatar"}/>}
                </View>
              </View>
            )}
            style={{marginRight: 10}}
            rightIcon={'times'} rightIconPreset={'smallBlue'} onRightPress={() => navigation.goBack()}
          />
          <ScrollView style={{flex: 1}} showsVerticalScrollIndicator={false}>
            <View style={{paddingHorizontal: 20}}>
              <TouchableOpacity style={{marginTop: 12}}>
                <Text text={roleDescription} preset="h1"/>
                <View style={{marginTop: 9}}/>
                <Text text={roleTitle} preset="h2" style={{color: color.palette.blue, fontSize: 20}}/>
              </TouchableOpacity>
              <View style={{marginTop: 24}}>
                <View style={styles.line}>
                  <Text preset="h4Dark">Job Ad Description </Text>
                </View>
                <Text preset="body1" style={{marginTop: 8}} numberOfLines={seeMore ? undefined : 3}>
                  {jobRole?.jobAd?.description}
                </Text>
                <TouchableOpacity
                  style={{flexDirection: "row", alignItems: 'center', marginTop: 10}}
                  onPress={() => setSeeMore(!seeMore)}>
                  <Text
                    text={seeMore ? "See less" : "See more"} preset="body2"
                    style={{fontWeight: 'bold', color: color.palette.blue}}/>
                  <SvgIcon icon={seeMore ? "chevron-up" : "chevron-down"} preset={"tiny"} style={{marginLeft: 8}}/>
                </TouchableOpacity>
              </View>
              <View style={{marginTop: 24}}>
                <View style={styles.line}>
                  <Text preset="h4Dark">Responsibilites</Text>
                </View>
                <Text preset="body1" style={{marginTop: 8}}>
                  {jobRole?.jobAd?.responsibilities}
                </Text>
              </View>

              <View style={{marginTop: 24}}>
                <View style={styles.line}>
                  <Text preset="h4Dark">Job Details</Text>
                </View>
                <View style={{marginTop: 8, flexDirection: "row", flexWrap: 'wrap'}}>
                  <Tag
                    icon="user"
                    children={String(jobRole?.numberOfPositions)}
                    textPreset="body" textStyle={{color: color.palette.dark}}
                    preset="smaller"
                    containerStyle={{marginBottom: 10, marginRight: 4}}
                  />
                  <Tag
                    preset="smaller"
                    icon="calendar-day"
                    children={stringToDateStr(jobRole?.startDate)}
                    textPreset={'body'} textStyle={{color: color.palette.dark}}
                    containerStyle={{marginBottom: 10, marginRight: 4}}
                  />
                  <Tag
                    preset="iconSmall"
                    icon="pen-field"
                    children={jobRole?.workType}
                    textPreset={'body'} textStyle={{color: color.palette.dark}}
                    containerStyle={{marginBottom: 10, marginRight: 4}}
                  />
                  <Tag
                    icon="money-check-dollar"
                    preset={"iconSmall"}
                    children={`${paymentType} ${jobRole?.amount}`}
                    textPreset="body" textStyle={{color: color.palette.dark}}
                    containerStyle={{marginBottom: 10, marginRight: 4}}
                  />
                  <Tag
                    icon="map-marker-alt"
                    children={location}
                    textPreset="body" textStyle={{color: color.palette.dark}}
                    preset="smaller"
                    containerStyle={{marginBottom: 10, marginRight: 4}}
                  />
                </View>
              </View>

              <View style={{marginTop: 24}}>
                <View style={styles.line}>
                  <Text preset="h4Dark">Experience</Text>
                </View>
                <Text
                  preset="body2Grey"
                  style={{color: color.palette.greyHigh, marginTop: 8}}
                >
                  Tick all the boxes that apply to you. This will appear in your application.
                </Text>
                <View style={{marginTop: spacing[4]}}>
                  <View style={{flexDirection: "row", flexWrap: 'wrap'}}>
                    {experiences?.map((item, index) => (
                      <View key={index.toString()} style={styles.checkboxContainer}>
                        <Text text={item.name}/>
                      </View>
                    ))}
                  </View>
                </View>
              </View>

              <View style={{marginTop: 24}}>
                <View style={styles.line}>
                  <Text preset="h4Dark">Skills</Text>
                </View>
                <Text
                  preset="body2Grey"
                  style={{color: color.palette.greyHigh, marginTop: 8}}
                >
                  Tick all the boxes that apply to you. This will appear in your application.
                </Text>
                <View style={{marginTop: 16, backgroundColor: color.palette.white}}>
                  <View style={{flexDirection: "row", flexWrap: 'wrap'}}>
                    {skills?.map((item, index) => (
                      <View key={index.toString()} style={styles.checkboxContainer}>
                        <Text text={item.name}/>
                      </View>
                    ))}
                  </View>
                </View>
              </View>

              <View style={{marginTop: 24}}>
                <View style={styles.line}>
                  <Text preset="h4Dark">Qualification</Text>
                </View>
                <Text
                  preset="body2Grey"
                  style={{color: color.palette.greyHigh, marginTop: 8}}
                >
                  Add all the qualifications that apply to you. This will appear in your application.
                </Text>
                <View style={{marginTop: 16, backgroundColor: color.palette.white}}>
                  <View style={{flexDirection: "row", flexWrap: 'wrap'}}>
                    {qualifications?.map((item, index) => (
                      <View key={index.toString()} style={styles.checkboxContainer}>
                        <Text text={item.name}/>
                      </View>
                    ))}
                  </View>
                </View>
              </View>
              <View style={{marginTop: 24}}>
                <SelectTextField
                  value={'Upload CV'} numberOfLines={1}
                  style={{flex: 1}}
                  rightIcon={"file-upload"}
                  label={"My CV"} labelPreset={"bold"}
                  placeholder={"Upload CV"}
                />
              </View>
              <View style={{marginTop: 24}}>
                <Button
                  text={"Close Preview"}
                  preset={"primaryOutLine"}
                  onPress={() => navigation.goBack()}
                />
              </View>
              <View style={{height: 200}}/>
            </View>
          </ScrollView>

        </SafeAreaView>
      </PureBottomModal>
      <AlertModal/>
    </>
  )
})

const styles = StyleSheet.create({
  checkboxLight: {
    paddingTop: 4, paddingBottom: 6, paddingHorizontal: 8, borderRadius: 5
  },
  checkboxPlus: {
    backgroundColor: '#F6F9FF',
    paddingTop: 4, paddingBottom: 6, paddingHorizontal: 8, borderRadius: 5
  },
  checkboxContainer: {
    flex: 1,
    flexBasis: '50%',
    alignItems: "flex-start",
    marginBottom: 10,
    overflow: 'hidden',
    paddingRight: 20,
    paddingLeft: 4,
  },
  line: {
    borderBottomWidth: 2,
    borderColor: color.palette.bgBlue,
    paddingBottom: 10
  }
})
