import {types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"

export const TabStore = types
  .model("TabStore")
  .props({
    businessGroupMessageTab: types.maybeNull(types.number),
    workerMessageScreenTab: types.maybeNull(types.number),
    workerJobListScreenTab: types.maybeNull(types.number),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setBusinessGroupMessageTab: (businessGroupMessageTab: number) => {
      self.businessGroupMessageTab = businessGroupMessageTab;
    },
    setWorkerMessageScreenTab: (workerMessageScreenTab: number) => {
      self.workerMessageScreenTab = workerMessageScreenTab;
    },
    setWorkerJobListScreenTab: (workerJobListScreenTab: number) => {
      self.workerJobListScreenTab = workerJobListScreenTab;
    },
  }))
  .views((self) => ({}))
