import * as React from "react"
import {
  View,
  SafeAreaView,
  FlatList,
  TouchableOpacity,
  ScrollView,
  RefreshControl,
  ViewStyle,
  StyleSheet,
} from "react-native"
import {FC, useCallback, useEffect, useState} from "react"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Text, GradientBackground, IconButton, Button} from "../../../components"
import {SvgIcon} from "../../../components/icon/svg-icon"
import {color} from "../../../theme"
import {AvatarImage} from "../../../components/avatar-image/avatar-image"
import {QuickFilterTag, QuickFilterTagItem} from "../../../components/tag/quick-filter-tag"
import {useStores} from "../../../models";
import {observer} from "mobx-react-lite";
import {listToStr} from "../../../utils/utils";
import {IJobRoleAdModel} from "../../../models/app/job-role-ad";
import {ParamListBase, useFocusEffect} from "@react-navigation/native";
import {convertJobAdvertModel} from "../../../models/app/job-advert";
import {AwBannerCard} from "../../../components/card/aw-banner-card";
import {JobRoleCard} from "../../../components/card/job-card";
import {constants} from "../../../utils/constants";
import {FilterStoreKey} from "../../../models/app/filter-store";
import {JobManageListScreenTags} from "../job-adverts-manage/job-manage-list-screen";
import {BusinessMessageScreenTabs} from "../messages/group-message-screen";
import {AdvertRoleStore} from "../../../models/app/advert-role";
import {IUserNotificationModel} from "../../../models/app/user-account";
import {BREAKPOINT_MOBILE, height, width} from "../../../utils/global";
import AvatarDefault from "../../../components/avatar-image/AvatarDefault";

const bannerImage = require("../../../../assets/images/banner-avatar-worker.png")

const QuickFilterKey = {
  response: "response",
  message: "message",
  application: "application",
}
const BusinessQuickFilters: Array<QuickFilterTagItem> = [
  {id: QuickFilterKey.response, name: "Responses", dot: false},
  {id: QuickFilterKey.message, name: "Messages", dot: false},
  {id: QuickFilterKey.application, name: "Applicants", dot: false},
]

export interface HomeProps {
  navigation: NativeStackNavigationProp<ParamListBase>
}

export const BusinessHomeScreen: FC<HomeProps> = observer(({navigation}) => {
  const {app} = useStores()
  const {userAccount} = app
  const [jobRoles, setJobRoles] = useState<Array<IJobRoleAdModel>>([])
  const [notification, setNotification] = useState<IUserNotificationModel>()
  const businessQuickFilters = BusinessQuickFilters.map(item => {
    const newItem = {...item}
    switch (item.id) {
      case QuickFilterKey.response:
        newItem.dot = !!notification?.responses?.count
        break;
      case QuickFilterKey.message:
        newItem.dot = !!notification?.messages?.count
        break;
      case QuickFilterKey.application:
        newItem.dot = !!notification?.applicants?.count
        break;
    }
    return newItem
  })
  const isDesktop = width > BREAKPOINT_MOBILE;
  const [refreshing, setRefreshing] = useState(false)

  const loadData = async () => {
    if (refreshing) return
    setRefreshing(true)
    try {
      const isManager = app?.user?.role === constants.roles.MANAGER
      const businessProfile = isManager ? app?.user?.businessProfile : userAccount?.businessProfile
      const notificationRes = await app.environment.api.getNotification({
        businessProfileId: businessProfile.id
      })
      if (notificationRes.kind === 'ok') setNotification(notificationRes.item)
      const res = await app.environment.api.getJobRoleAds({
        "filters[jobAd][isDraft][$eq]": false,
        "filters[jobAd][closed][$eq]": false,
      })
      if (res.kind === 'ok') setJobRoles(res.jobRoles)
    } catch (e) {
      console.log(e)
    } finally {
      setRefreshing(false)
    }
  }

  useFocusEffect(useCallback(() => {
    loadData()
  }, []))

  useEffect(() => {
    window.addEventListener(
      "chooseAccessScreen",
      function () {
        onProfilePress();
      },
      false
    );
  }, []);

  useEffect(() => {
    window.addEventListener(
      "settingScreen",
      function () {
        navigation.navigate('settingScreen');
      },
      false
    );
  }, []);

  const onProfilePress = () => {
    navigation.navigate("chooseAccessScreen")
  }

  const handleCreateJobAd = () => {
    app.setJobAdvertCreate(convertJobAdvertModel({}))
    AdvertRoleStore.reset()
    app.setSelectedContact(null)
    navigation.navigate("jobAdvertEditScreen")
  }

  const renderJobList = () => {
    return (
      <FlatList
        horizontal={!isDesktop}
        data={jobRoles}
        keyExtractor={(item, index) => String(item?.id + '' + index)}
        renderItem={renderItem}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={isDesktop && styles.listDk}
        style={isDesktop && {height: height - 100}}
      />
    )
  }

  const renderItem = ({item}: { item: IJobRoleAdModel }) => {
    return (
      <JobRoleCard
        onLogoPress={(id) => navigation.navigate('businessProfileScreen', {businessProfileId: id})}
        onPress={() => navigation.navigate('jobsAdDetailScreen', {id: item.id})} item={item}/>
    )
  }

  const handleItemPress = (item: QuickFilterTagItem) => {
    switch (item.id) {
      case QuickFilterKey.response:
        app.filterStore.setBusinessMessageFilterId(FilterStoreKey.response)
        app.tabStore.setBusinessGroupMessageTab(BusinessMessageScreenTabs.jobAd.key)
        navigation.navigate('groupMessageScreen')
        break
      case QuickFilterKey.message:
        app.filterStore.setBusinessMessageFilterId(FilterStoreKey.message)
        app.tabStore.setBusinessGroupMessageTab(BusinessMessageScreenTabs.bulk.key)
        navigation.navigate('groupMessageScreen')
        break
      case QuickFilterKey.application:
        app.filterStore.setBusinessJobListFilter(true)
        app.filterStore.setBusinessJobListFilterId(JobManageListScreenTags.newApplicants.key)
        navigation.navigate('manageJobScreen')
        break
    }
  }

  const isManager = app?.user?.role === constants.roles.MANAGER
  const businessProfile = isManager ? app?.user?.businessProfile : userAccount?.businessProfile

  const renderMobile = () => {
    return(
        <ScrollView
            showsHorizontalScrollIndicator={false}
            refreshControl={
              <RefreshControl
                  refreshing={refreshing}
                  onRefresh={loadData}
              />
            }
        >
          <View style={{marginBottom: 20, flex: 1}}>
            <QuickFilterTag items={businessQuickFilters} onItemPress={handleItemPress}/>

            <View style={{marginHorizontal: 20, paddingTop: 12}}>
              <Button
                  preset="brand"
                  text="Create Job Ad"
                  onPress={handleCreateJobAd}
              />
            </View>

            <View style={{marginHorizontal: 20, marginTop: 16}}>
              <AwBannerCard
                  title={'Want to advertise your business on Tap4Work?'}
                  buttonText={'Get in Touch'} buttonPreset={'brand'}
                  image={bannerImage}
                  onPress={() => window.location.href = 'mailto:support@tap4work.com.au'}
              />
            </View>

            <View style={{marginHorizontal: 20, marginTop: 25, flexDirection: "row",}}>
              <Text text="Job Adverts"
                    preset={'h1'}
                    style={{color: color.palette.dark}}/>
              <View style={{alignItems: "flex-end", flex: 1,}}>
                <IconButton
                    onPress={() => navigation.navigate('jobListScreen')}
                    text="See All" textPreset={'bodyGrey'}
                    iconProps={{icon: 'triangle-right', preset: 'triangleIcon'}}
                />
              </View>
            </View>
            <View style={{marginLeft: 10, marginTop: 12}}>
              {renderJobList()}
            </View>
          </View>
        </ScrollView>
    )
  }

  const renderDesktop = () => {
    return(
        <View style={styles.wrapperDk}>
          <View style={styles.contentDk}>
            <View style={{paddingRight: 20}}>
              <View style={styles.topContentDk}>
                <QuickFilterTag items={businessQuickFilters} styleCustom={{marginHorizontal: 0}}
                                onItemPress={handleItemPress}/>
                <View style={{paddingTop: 12}}>
                  <Button
                    preset="brand"
                    text="Create Job Ad"
                    onPress={handleCreateJobAd}
                  />
                </View>
              </View>
              <View style={{marginTop: 25, flexDirection: "row",}}>
                <Text text="Job Adverts"
                      preset={'h1'}
                      style={{color: color.palette.dark}}/>
                <View style={{alignItems: "flex-end", flex: 1,}}>
                  <IconButton
                    onPress={() => navigation.navigate('jobListScreen')}
                    text="See All" textPreset={'bodyGrey'}
                    iconProps={{icon: 'triangle-right', preset: 'triangleIcon'}}
                  />
                </View>
              </View>
              <View style={{marginTop: 12}}>
                {renderJobList()}
              </View>
            </View>
          </View>
          <AwBannerCard
              isDesktop={isDesktop}
              title={'Want to advertise your business on Tap4Work?'}
              buttonText={'Get in Touch'} buttonPreset={'brand'}
              image={bannerImage}
              onPress={() => window.location.href = 'mailto:support@tap4work.com.au'}
          />
        </View>
    )
  }

  return (
    <SafeAreaView style={{flex: 1}}>
      <GradientBackground colors={[color.palette.gradientStart, color.palette.gradientStop]}/>
      <View style={{}}>
        <div id="mobile-header">
          <View style={HEADER_STYLE}>
            <TouchableOpacity
              style={{flexDirection: "row", alignItems: "center",}}
              onPress={onProfilePress}>
              <View>
                <Text
                  text={listToStr([userAccount?.firstName, userAccount?.lastName])}
                  preset={'h1'} style={{color: color.palette.primary, fontWeight: '600'}}/>
                <View style={{flexDirection: 'row', marginTop: 2, alignItems: 'center'}}>
                  <Text text={businessProfile?.name} preset={'body'} style={{color: color.palette.primary}}/>
                  <SvgIcon icon={"chevron-down"} preset={"tiny"} style={{marginLeft: 3}}/>
                </View>
              </View>
            </TouchableOpacity>
            <View style={{flex: 1, alignItems: "flex-end",}}>
              {!!businessProfile?.logo?.url ?  <AvatarImage
                  onPress={() => navigation.navigate('settingScreen')}
                  source={{uri: businessProfile?.logo?.url}}
                  preset={"round"}/>: <AvatarDefault onPress={() => navigation.navigate('settingScreen')} name={businessProfile?.name} preset={"round"}/>}
            </View>
          </View>
        </div>
      </View>
      {isDesktop ? renderDesktop() : renderMobile()}
    </SafeAreaView>
  )
})

const HEADER_STYLE: ViewStyle = {
  marginHorizontal: 20,
  flexDirection: "row",
  alignItems: "center",
  marginTop: 10,
  marginBottom: 10,
}

const styles = StyleSheet.create({
  wrapperDk:{
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 40,
  },
  contentDk:{
    flexDirection: 'column',
    flex: 1,
    paddingLeft: 20,
    paddingRight: 0,
  },
  topContentDk:{
    marginBottom: 20,
    flexDirection: 'row'
  },
  listDk:{
    paddingBottom: 300,
  },
})
