import React, {FC, useEffect, useState} from 'react';
import {View, StyleSheet, SafeAreaView} from 'react-native';

import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {observer} from "mobx-react-lite";
import {color} from "../../../theme";
import {Button, Header, Text} from "../../../components";
import {BackdropModal} from "../../../components/backdrop-modal/backdrop-modal";
import {SvgIcon} from "../../../components/icon/svg-icon";
import {AvatarImage} from "../../../components/avatar-image/avatar-image";
import {WorkerProfilePreview} from "./worker-profile-preview";
import {WorkerProfileAbout} from "./worker-profile-about";
import {useStores} from "../../../models";
import {ParamListBase, RouteProp} from "@react-navigation/native";
import {IUserAccountModel} from "../../../models/app/user-account";
import {listToStr} from "../../../utils/utils";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import {NavigatorParamList} from "../../../navigators";
import {getCountryCallingCode} from "react-phone-number-input";
import TabButton from "../../../components/tab-bar/tab-button";
import {WorkerProfileWallet} from "./worker-profile-wallet";
import {CONTACT_STATUS} from "../../../models/app/contact";
import {constants} from "../../../utils/constants";
import { LoadingModal } from "../../../components/backdrop-modal/loading-modal";

const WorkerProfileTabs = {
  about: {key: 1, label: 'About'},
  internal: {key: 2, label: 'Internal'},
  wallet: {key: 3, label: 'Wallet'},
}

export interface WorkerProfileScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'workerProfileScreen'>
}

const avatar = require('../../../../assets/images/default-photo.png')
export const WorkerProfileScreen: FC<WorkerProfileScreenProps> = observer(
  ({navigation, route}) => {
    const id = route?.params?.id;
    const {app} = useStores()
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [refreshing, setRefreshing] = useState(false)
    const [isContact, setIsContact] = useState(false)
    const [userAccount, setUserAccount] = useState<IUserAccountModel>()
    const [callingCode, setCallingCode] = useState('')
    const [selectedTab, setSelectedTab] = useState<number>(WorkerProfileTabs.about.key)

    const loadData = async () => {
      if (refreshing) return
      setRefreshing(true)
      try {
        const res = await app.environment.api.getMe(id)
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        setUserAccount(res.userAccount)
        const countryCode = res.userAccount?.countryCode
        if (countryCode) {
          const callingCode = countryCode ? getCountryCallingCode(countryCode as any) : ''
          // const callingCode = await getCallingCode(userAccount?.countryCode as CountryCode)
          setCallingCode(callingCode.toString())
        }
        const isManager = app?.user?.role === constants.roles.MANAGER
        const contactRes = await app.environment.api.getContacts({
          'filters[businessProfile][id][$eq]': isManager
            ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          'businessProfileId': isManager
            ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          'filters[status][$eq]': CONTACT_STATUS.accepted.key,
          'filters[user][id][$eq]': id,
        })
        if (contactRes.kind === 'ok' && contactRes.items.length > 0) {
          setIsContact(true)
        }
      } catch (e) {
        console.log(e)
      } finally {
        setRefreshing(false)
      }
    }
    useEffect(() => {
      loadData()
    }, [])

    const workerProfile = userAccount?.workerProfile
    const renderActiveTab = () => {
      if (selectedTab === WorkerProfileTabs.about.key)
        return <WorkerProfileAbout
          workerProfile={workerProfile}
        />
      if (selectedTab === WorkerProfileTabs.internal.key)
        return (
          <WorkerProfilePreview
            loading={refreshing} setLoading={setRefreshing}
            userAccount={userAccount}
          />
        )
      return <WorkerProfileWallet
        navigation={navigation}
        userAccount={userAccount}
      />
    }

    return (
      <>
        <PureBottomModal onClose={() => navigation.goBack()}>
          <SafeAreaView style={styles.container}>
            <Header
              preset={"modalHeader"}
              titlePreset={"headerTitle"}
              header={() => (
                <View style={{paddingTop: 4}}>
                  <Text preset={'h2'}
                        color={color.palette.black}>{listToStr([userAccount?.firstName, userAccount?.lastName])}</Text>
                  <View style={styles.smLabel}>
                    <SvgIcon
                      icon={'mobile-screen-button-solid'}
                      preset={'captionBlack'} theme={'solid'}/>
                    <View style={{width: 4}}/>
                    <Text preset={'body'} style={{fontWeight: '400', color: color.palette.black}}>
                      {`+${callingCode} ${userAccount?.mobile || ''}`}
                    </Text>
                  </View>
                </View>
              )}
              leftIcon={() => (
                <AvatarImage
                  preset={"medium"}
                  source={userAccount?.avatar?.url ? {uri: userAccount?.avatar?.url} : avatar}/>
              )}
              rightIcon={"times"}
              rightIconPreset={"smallBlue"}
              onRightPress={() => navigation.goBack()}
            />
            <View style={{flexDirection: 'row', paddingHorizontal: 20,}}>
              {Object.values(WorkerProfileTabs)
                .filter(it => isContact ? true : (it.key !== WorkerProfileTabs.wallet.key))
                .map((it) => {
                  return (
                    <TabButton
                      key={it.key}
                      styleCustom={{}} isActive={it.key === selectedTab} value={it.label}
                      onPress={() => setSelectedTab(it.key)}/>
                  )
                })}
            </View>
            <View style={styles.mainContainer}>
              {renderActiveTab()}
            </View>
            <BackdropModal visible={confirmVisible} onClose={() => setConfirmVisible(false)}>
              <View>
                <View style={{
                  alignItems: "center",
                  justifyContent: 'center',
                  padding: 10,
                }}>
                  <SvgIcon preset={'alertIcon'}/>
                  <View style={{paddingTop: 18, paddingBottom: 8}}>
                    <Text preset={'alertTitle'}>Are you sure?</Text>
                  </View>
                </View>
                <Button
                  style={styles.modalBtn}
                  disabled={false}
                  text={'Yes'}
                />
                <Button
                  onPress={() => setConfirmVisible(false)}
                  style={styles.modalBtn}
                  disabled={false}
                  preset={'destructive'}
                  text={'Cancel'}
                />
              </View>
            </BackdropModal>
          </SafeAreaView>
        </PureBottomModal>
        <AlertModal/>
        <LoadingModal visible={refreshing}/>
      </>
    )
  },
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    flexDirection: 'row', alignItems: 'flex-start',
    backgroundColor: color.palette.blue,
    paddingVertical: 15, paddingHorizontal: 10
  },
  icon: {
    paddingHorizontal: 3
  },
  mainContainer: {
    backgroundColor: color.palette.white,
    flex: 1
  },
  modalBtn: {
    marginVertical: 8
  },
  smLabel: {
    flexDirection: 'row',
    marginTop: 6
  }
});
