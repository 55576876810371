import React, {FC, useEffect} from "react";
import {SafeAreaView, StyleSheet, TouchableOpacity, Text} from "react-native";
import {SvgIcon} from "../icon/svg-icon";
import {color} from "../../theme";
import {BottomTabBarProps} from "@react-navigation/bottom-tabs";
import {observer} from "mobx-react-lite";
import {useStores} from "../../models";
import {constants} from "../../utils/constants";
import './styles.css';
import {Button} from "../button/button";



export const TabBar: FC<BottomTabBarProps> = observer(({state, descriptors, navigation}) => {
  const {app} = useStores();
  const role = app.user.role || constants.roles.GUEST;

  useEffect(() => {
    let child = document.getElementById('bottom-nav')
    if(child){
      let parent = child.parentNode as HTMLElement
      parent.classList.add('rowReverse')
    }
  }, []);

  const renderUserIcon = (route) => {
    let icon = '';
    switch (route.name) {
      case 'homeScreen':
        icon = 'home';
        break;
      case 'manageJobScreen':
        icon = 'officecase';
        break;
      case 'groupMessageScreen':
      case 'inboxScreen':
        icon = 'chatbubble-message';
        break;
      case 'walletScreen':
        icon = 'wallet';
        break;
      case 'settingScreen':
        icon = 'gearring';
        break;
    }
    return icon;
  }
  const renderWorkerIcon = (route) => {
    let icon = '';
    switch (route.name) {
      case 'homeScreen':
        icon = 'home';
        break;
      case 'manageJobScreen':
        icon = 'officecase';
        break;
      case 'groupMessageScreen':
      case 'workerMessageScreen':
        icon = 'chatbubble-message';
        break;
      case 'walletScreen':
        icon = 'wallet';
        break;
      case 'settingScreen':
        icon = 'gearring';
        break;
    }
    return icon;
  }
  const renderBusinessUserIcon = (route) => {
    let icon = '';
    switch (route.name) {
      case 'homeScreen':
        icon = 'home';
        break;
      case 'manageJobScreen':
        icon = 'officecase';
        break;
      case 'groupMessageScreen':
      case 'inboxScreen':
        icon = 'chatbubble-message';
        break;
      case 'contactScreen':
        icon = 'usergroup';
        break;
      case 'settingScreen':
        icon = 'gearring';
        break;
    }
    return icon;
  }
  const renderPublicIcon = (route) => {
    let icon = '';
    switch (route.name) {
      case 'homeScreen':
        icon = 'home';
        break;
      case 'manageJobScreen':
        icon = 'officecase';
        break;
      case 'groupMessageScreen':
      case 'inboxScreen':
        icon = 'chatbubble-message';
        break;
      case 'contactScreen':
        icon = 'usergroup';
        break;
      case 'settingScreen':
        icon = 'gearring';
        break;
    }
    return icon;
  }

  const renderIcon = (route) => {
    switch (role) {
      case constants.roles.USER:
        return renderUserIcon(route);
      case constants.roles.WORKER:
        return renderWorkerIcon(route);
      case constants.roles.BUSINESS:
        return renderBusinessUserIcon(route);
      default:
        return renderPublicIcon(route);
    }

  }
  return (
    <div id={'bottom-nav'} >
      <SafeAreaView style={styles.bottomContainer}>
        {state.routes.map((route, index) => {
          const {options} = descriptors[route.key];
          if (!options.tabBarLabel) return null;
          const label = options.tabBarLabel;
          const isFocused = state.index === index;
          let icon = renderIcon(route);

          const onPress = () => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
              canPreventDefault: true,
            });

            if (!isFocused && !event.defaultPrevented) {

              if (role === constants.roles.USER && (route.name === 'manageJobScreen' || route.name === 'walletScreen')) {
                if (app?.userAccount?.userRoles?.find(it => it.key === constants.roles.WORKER)) {
                  navigation.navigate('chooseAccessScreen');
                } else {
                  navigation.navigate('createWorkerProfileScreen');
                }
                return;
              } else if (role === constants.roles.GUEST
                  && route.name !== 'contactScreen'
                  && route.name !== 'inboxScreen'
                  && route.name !== 'manageJobScreen'
                  && route.name !== 'homeScreen') {
                navigation.navigate('signInEmailCheckScreen');
                return;
              }
              // navigation.navigate({name: route.name, merge: true});
              navigation.navigate(route.name);
            }
          };

          const onLongPress = () => {
            navigation.emit({
              type: 'tabLongPress',
              target: route.key,
            });
          };

          return (
            <TouchableOpacity
              key={index}
              accessibilityRole="button"
              accessibilityState={isFocused ? {selected: true} : {}}
              accessibilityLabel={options.tabBarAccessibilityLabel}
              testID={options.tabBarTestID}
              onPress={onPress}
              onLongPress={onLongPress}
              style={styles.btnTab}
            >
              <SvgIcon icon={icon} preset={'tabIcon'} theme={isFocused ? 'solid' : 'regular'}/>
              <div className='block-desktop'>
                <Text
                  style={[styles.txtBar, {color: isFocused ? color.palette.orange : color.palette.primary}]}>{label}</Text>
              </div>
            </TouchableOpacity>
          );
        }).filter(it => it !== null)}
      </SafeAreaView>
      <div id={'help-btn-desktop'}>
        <Button
          style={{paddingHorizontal: 8,}}
          text={'Help'} preset={'brand'} onPress={() => window.location.href = 'mailto:support@tap4work.com.au'}/>
      </div>
    </div>
  );
})

const styles = StyleSheet.create({
  bottomContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: color.palette.white,
    borderTopWidth: 1,
    borderTopColor: color.palette.blueLine,
  },
  btnTab:{
    // flex: 1,
    // paddingTop: 14,
    // paddingBottom: 10,
    alignItems: 'center'
  },
  txtBar:{
    marginLeft: 39,
    fontWeight: 'bold', color: color.palette.primary
  }
})
