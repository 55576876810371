import React from "react"
import { ScrollView, StyleSheet, TouchableOpacity } from "react-native"
import { Text } from "../../../../components"
import { color } from "../../../../theme"
import { IKvItemModel } from "../../../../models/app/kv-item";

interface Props {
  data: IKvItemModel[]
  selectedItem?: IKvItemModel
  setSelectedItem: (selectedItem: IKvItemModel) => void
}

const Tags = ({data, selectedItem, setSelectedItem}: Props) => {
  return (
    <ScrollView contentContainerStyle={styles.wrapper} horizontal showsHorizontalScrollIndicator={false}>
      {data.map((item) => {
        const isSelected = selectedItem?.id === item?.id
        const words = item?.name?.split(' ')
        const _title = words?.length > 2 ? words?.map(item => item ? item[0] : '').join('') : item?.name
        return (
          <TouchableOpacity
            onPress={() => setSelectedItem(isSelected ? null : item)}
            key={`tag-${item?.id}`} activeOpacity={0.8}
            style={[styles.btnTag, isSelected && styles.active]}
          >
            <Text preset="textTags" style={isSelected && {color: color.palette.orange}}>{_title}</Text>
          </TouchableOpacity>
        )
      })}
    </ScrollView>
  )
};

const styles = StyleSheet.create({
  active: {
    borderColor: color.palette.orange,
    borderWidth: 2
  },
  btnTag: {
    borderColor: color.palette.primary,
    borderRadius: 8,
    borderWidth: 1,
    justifyContent: 'center',
    marginRight: 15,
    paddingHorizontal: 5,
    paddingVertical: 2,
  },
  wrapper: {
    display: "flex", marginVertical: 15
  }
});

export default Tags;
