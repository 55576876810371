import React, {FC, useCallback, useState} from "react"
import {SafeAreaView, TouchableOpacity, View} from "react-native"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Header, GradientBackground, Text} from "../../../components"
import {color} from "../../../theme"
import {ParamListBase, RouteProp, useFocusEffect} from "@react-navigation/native"
import {SearchBar} from "../../../components/search-bar/search-bar"
import {AvailableJobsTab} from "./available-jobs"
import {observer} from "mobx-react-lite";
import {useStores} from "../../../models";
import {BusinessUserParamList} from "../../../navigators/app/business-user-navigator";
import {SvgIcon} from "../../../components/icon/svg-icon";
import {convertJobAdvertModel} from "../../../models/app/job-advert";
import {constants, DEFAULT_PAGE_LIMIT} from "../../../utils/constants";
import {IJobRoleAdModel} from "../../../models/app/job-role-ad";
import {BackdropModal} from "../../../components/backdrop-modal/backdrop-modal";
import {AdvertRoleStore} from "../../../models/app/advert-role";
import {IUserNotificationModel} from "../../../models/app/user-account";

export interface JobGroupListScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<BusinessUserParamList, 'manageJobScreen'>
}

export const JobManageListScreenTags = {
  newApplicants: {key: 1, label: "New Applicants", selectLabel: 'New Applicants'},
  responses: {key: 2, label: "New Responses", selectLabel: 'Responses'},
  draft: {key: 3, label: "Draft", selectLabel: 'Draft Job Ads'},
}

export const JobManageListScreen: FC<JobGroupListScreenProps> = observer(({navigation}) => {
  const {app} = useStores()
  const [jobRoles, setJobRoles] = useState<Array<IJobRoleAdModel>>([])
  const [refreshing, setRefreshing] = useState(false)
  const [search, setSearch] = useState("")
  const [visibleModal, setVisibleModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [notification, setNotification] = useState<IUserNotificationModel>()

  const loadAvailableJobsData = async () => {
    if (refreshing) return
    setRefreshing(true)
    try {
      const isManager = app?.user?.role === constants.roles.MANAGER
      const businessProfileId = isManager ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id
      const filterId = app.filterStore.businessJobListFilterId

      let _notification: IUserNotificationModel = null
      const showUnread = filterId === JobManageListScreenTags.newApplicants.key
      const notificationRes = await app.environment.api.getNotification({businessProfileId})
      if (notificationRes.kind === 'ok') _notification = notificationRes.item
      if (showUnread && _notification?.applicants?.applications?.length <= 0) {
        setJobRoles([])
        return
      }
      setNotification(_notification)

      const res = await app.environment.api.getJobRoleAds({
        businessProfileId,
        'filters[jobAd][businessProfile][id][$eq]': businessProfileId,
        ...(filterId === JobManageListScreenTags.draft.key ? {"filters[jobAd][isDraft][$eq]": true} : null),
        ...(showUnread ? {'filters[applications][id][$eq]': _notification?.applicants?.applications} : null),
        "pagination[pageSize]": DEFAULT_PAGE_LIMIT
      })
      if (res.kind === 'ok') {
        setJobRoles(res.jobRoles?.map(it => ({
          ...it,
          newApplicants: _notification?.applicants?.jobAds?.includes(it?.jobAd?.id) ? 1 : 0
        })))
        setCurrentPage(1)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setRefreshing(false)
    }
  }

  const nextAvailableJobsData = async () => {
    if (refreshing) return
    if (jobRoles?.length % DEFAULT_PAGE_LIMIT !== 0) return
    setRefreshing(true)
    try {
      const isManager = app?.user?.role === constants.roles.MANAGER
      const businessProfileId = isManager ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id
      const filterId = app.filterStore.businessJobListFilterId
      const _currentPage = currentPage + 1

      const showUnread = filterId === JobManageListScreenTags.newApplicants.key
      if (showUnread && notification?.applicants?.applications?.length <= 0) {
        setJobRoles([])
        return
      }

      const res = await app.environment.api.getJobRoleAds({
        businessProfileId,
        'filters[jobAd][businessProfile][id][$eq]': businessProfileId,
        ...(filterId === JobManageListScreenTags.draft.key ? {"filters[jobAd][isDraft][$eq]": true} : null),
        ...(showUnread ? {'filters[applications][id][$eq]': notification?.applicants?.applications} : null),
        "pagination[page]": _currentPage,
        "pagination[pageSize]": DEFAULT_PAGE_LIMIT
      })
      if (res.kind === 'ok') {
        setJobRoles(jobRoles.concat(
          res.jobRoles?.map(it => ({
            ...it,
            newApplicants: notification?.applicants?.jobAds?.includes(it?.jobAd?.id) ? 1 : 0
          }))
        ))
        setCurrentPage(_currentPage)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setRefreshing(false)
    }
  }

  useFocusEffect(useCallback(() => {
    if (app?.hasSearchJobRole) setSearch(app?.searchJobRoleText)
    app?.setHasSearchJobRole(false)
    loadAvailableJobsData()
  }, []))

  const handleFilterPress = () => {
    setVisibleModal(true)
  }

  const handleSelectKey = (key: number) => {
    app.filterStore.setBusinessJobListFilterId(app.filterStore.businessJobListFilterId === key ? null : key)
    loadAvailableJobsData()
  }

  const renderTabItem = (it) => {
    return <View key={it.key} style={{marginRight: 8}}>
      <TouchableOpacity onPress={() => handleSelectKey(it.key)}>
        <View style={{
          flexDirection: 'row', alignItems: 'center',
          backgroundColor: color.palette.greyLight,
          paddingHorizontal: 12,
          paddingVertical: 11,
          borderRadius: 100
        }}>
          <Text preset={'body'} style={{color: color.palette.blue, fontWeight: '400'}}>
            {it.label}
          </Text>
          <View style={{paddingLeft: 8}}>
            <SvgIcon preset={'primary'} icon={'times-circle-dark'}/>
          </View>
        </View>
      </TouchableOpacity>
    </View>

  }

  const handleCreateJobAd = () => {
    app.setJobAdvertCreate(convertJobAdvertModel({}))
    AdvertRoleStore.reset()
    app.setSelectedContact(null)
    navigation.navigate("jobAdvertEditScreen")
  }

  return (
    <SafeAreaView style={{flex: 1}}>
      <GradientBackground colors={[color.palette.gradientStart, color.palette.gradientStop]}/>
      <Header
        titlePreset={"headerTitle"}
        headerText={"My Job Adverts"}
        preset={"primary"}
        rightIcon="plus" rightIconPreset="smallBrand" onRightPress={handleCreateJobAd}
      />
      <SearchBar
        value={search} onChangeText={setSearch}
        onTouchStart={() => {
          app?.setFilterJobRoleText(search)
          navigation.navigate('searchScreen')
        }}
        onFilterPress={handleFilterPress}/>
      <View style={{
        flexDirection: "row", marginHorizontal: 20,
        marginTop: 8, marginBottom: !!app.filterStore.businessJobListFilterId ? 20 : 0,
        alignItems: 'flex-end', justifyContent: 'flex-start'
      }}>
        {Object.values(JobManageListScreenTags)
          .filter(item => app.filterStore.businessJobListFilterId === item.key)
          .map(renderTabItem)}
      </View>
      <View style={{flex: 1}}>
        <AvailableJobsTab
          onLogoPress={(id) => navigation.navigate('businessProfileScreen', {businessProfileId: id})}
          search={search} jobRoles={jobRoles} refreshing={refreshing} loadData={loadAvailableJobsData}
          onEndReached={nextAvailableJobsData}
          onPress={(item) => navigation.navigate('jobStatusListScreen', {jobAdId: item?.jobAd?.id, showWorkerTab: item?.jobAd?.businessProfile?.showWorkerTab})}/>
      </View>
      <BackdropModal preset={'zeroPadDesktop'} visible={visibleModal} onClose={() => setVisibleModal(!visibleModal)}>
        <View style={{paddingTop: 20, paddingBottom: 40}}>
          <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
            <Text preset={"h1"} text={'Filters'} color={color.palette.blue}/>
            <SvgIcon onPress={() => setVisibleModal(!visibleModal)} icon={'times'} preset={'normal'}/>
          </View>
          <View style={{marginTop: 65}}>
            {Object.values(JobManageListScreenTags).map((item) => (
              <TouchableOpacity
                key={item?.key}
                onPress={() => handleSelectKey(item.key)}
                style={{marginBottom: 26, alignItems: 'center'}}>
                <Text preset={"h1"} text={item.selectLabel} color={color.palette.blue}/>
              </TouchableOpacity>
            ))}
          </View>
        </View>
      </BackdropModal>
    </SafeAreaView>
  )
})
