import React, {FC, useCallback, useEffect, useState} from "react"
import {View, ScrollView, SafeAreaView, TouchableOpacity, StyleSheet} from "react-native"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {observer} from "mobx-react-lite"
import {
  VIEW_CONTAIN_BOTTOM,
  MODAL,
  VIEW_MODAL,
  SEE_ALL,
  LINE,
} from "./styles"
import {Text, Tag, Button, Header} from "../../../components"
import {color, spacing} from "../../../theme"
import {SvgIcon} from "../../../components/icon/svg-icon"
import {BottomContainer} from "../../../components/bottom-container/bottom-container"
import {AvatarImage} from "../../../components/avatar-image/avatar-image"
import {BackdropModal} from "../../../components/backdrop-modal/backdrop-modal"
import {Checkbox} from "../../../components/checkbox/checkbox"
import type {RouteProp} from "@react-navigation/native";
import {NavigatorParamList} from "../../../navigators";
import {ParamListBase, useFocusEffect} from "@react-navigation/native";
import {useStores} from "../../../models";
import {IJobRoleAdModel} from "../../../models/app/job-role-ad";
import {IKvItemCheckModel, IKvItemModel} from "../../../models/app/kv-item";
import {SelectTextField} from "../../../components/text-field/select-text-field";
import {constants, DEFAULT_LIMIT} from "../../../utils/constants";
import {AlertModalStore, IAlertModalAction} from "../../../models/app/alert-modal-store";
import {useFilePicker} from "use-file-picker";
import {ApplicationStatus, IWorkerApplicationModel} from "../../../models/app/application";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";
import {TrackEvents, trackWithUserAccount} from "../../../services/track";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import {WorkerJobListScreenTabs} from "../../worker/jobs-list";
import {parseLocation} from "../../../models/app/location";
import {stringToDateStr} from "../../../utils/utils";
import {
  getUserQualificationStr,
  getUserSkillStr,
  IWorkerWalletItemModel,
  WorkerWalletItemPath
} from "../../../models/app/advanced-skill";
import AvatarDefault from "../../../components/avatar-image/AvatarDefault";

let timeoutVal: NodeJS.Timeout = null

export interface JobsAdProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'jobsAdDetailScreen'>
}

const store = {
  applicationId: 0
}

export const JobsAdDetailScreen: FC<JobsAdProps> = observer(({navigation, route}) => {
  const id = route.params?.id
  const {app} = useStores()
  const role = app.user.role || constants.roles.GUEST;
  const workerProfile = app?.userAccount?.workerProfile
  const _workerProfileExperiences = workerProfile?.experiences?.map(item => item.id) || []
  const _workerProfileSkills = workerProfile?.skills?.map(item => item.id) || []
  const _workerProfileQualifications = workerProfile?.qualifications?.map(item => item.id) || []
  const [resultVisible, setResultVisible] = useState(false)
  const [seeMore, setSeeMore] = useState(false)
  const [jobRole, setJobRole] = useState<IJobRoleAdModel>(null)

  const location = jobRole?.locations?.length > 0 ? parseLocation(jobRole?.locations[0]) : ''
  const roleTitle = jobRole?.jobAd?.title || ''
  const roleDescription = jobRole?.role.name || ''
  const logo = jobRole?.jobAd?.businessProfile?.logo?.url
  const paymentType = jobRole?.jobAd?.paymentTypes?.length > 0 ? jobRole?.jobAd?.paymentTypes[0]?.name : ''

  const [experiences, setExperiences] = useState<Array<IKvItemCheckModel>>([])
  const [skills, setSkills] = useState<Array<IKvItemCheckModel>>([])
  const [qualifications, setQualifications] = useState<Array<IKvItemCheckModel>>([])
  const [loading, setLoading] = useState(false)
  const [pinnedId, setPinnedId] = useState(0)
  const [myCv, setMyCv] = useState<IKvItemModel>({id: 0, name: null})
  // Todo : CRUD applicationId
  // @ts-ignore
  const [applicationId, setApplicationId] = useState(0)
  const [openCvFileSelector, {plainFiles}] = useFilePicker({
    accept: ['.pdf', '.doc', '.docx', 'image/*'],
    maxFileSize: 2,
  });

  const loadData = async () => {
    if (loading) return
    try {
      setLoading(true)
      await app.getMe()
      const res = await app.environment.api.getJobRoleAdDetail(id)
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message)
        return
      }
      const _jobRole = res.jobRole
      setJobRole(res.jobRole)
      navigation.setOptions({title: res?.jobRole?.jobAd?.title || ''})
      if (role !== constants.roles.WORKER) {
        setExperiences(_jobRole?.experiences.map(item => ({...item, checked: false})))
        setSkills(_jobRole?.skills.map(item => ({
          id: item.id, name: getUserSkillStr(item), checked: false
        })))
        setQualifications(_jobRole?.qualifications.map(item => ({
          id: item.id, name: getUserQualificationStr(item), checked: false
        })))
        return
      }
      const bookmarkRes = await app.environment.api.getJobRoleBookmark({
        "filters[user][id][$eq]": app?.userAccount?.id,
        "filters[jobRole][id][$eq]": id,
        "pagination[pageSize]": DEFAULT_LIMIT
      })
      if (bookmarkRes.kind === 'ok' && bookmarkRes.items?.length > 0) {
        setPinnedId(bookmarkRes.items[0].id)
      }
      const applicationRes = await app.environment.api.getWorkerApplications({
        "filters[workerProfile][id][$eq]": workerProfile?.id,
        "filters[jobRole][id][$eq]": id,
        "pagination[pageSize]": DEFAULT_LIMIT
      })
      if (applicationRes.kind !== 'ok') {
        AlertModalStore.alert('Error', applicationRes.message)
        return
      }
      const _application: IWorkerApplicationModel = applicationRes?.applications?.length > 0 ? applicationRes?.applications[0] : null
      if (!_application?.id) {
        const itemRes = await app.environment.api.getWorkerWalletItemList(WorkerWalletItemPath.workerCvs, {
          'filters[workerProfile][id][$eq]': workerProfile?.id,
          "pagination[pageSize]": 1,
          populate: "url",
        })
        if (itemRes.kind === 'ok') {
          const item: IWorkerWalletItemModel | null = itemRes?.items?.length > 0 ? itemRes?.items[0] : null
          if (item && item?.url?.id) setMyCv({id: item?.url?.id, name: item?.url?.name})
        }
      } else {
        setApplicationId(_application?.id)
        const _myCv = _application?.myCv
        setMyCv({id: _myCv?.id, name: _myCv?.name})
      }

      const _experiences = _application?.experiences?.map(item => item.id) || []
      workerProfile?.experiences?.forEach(item => {
        if (!_experiences.includes(item?.id)) _experiences.push(item?.id)
      })
      setExperiences(_jobRole?.experiences.map(item => ({
        ...item, checked: _experiences.includes(item.id)
      })))
      const _skills = _application?.skills?.map(item => item.id) || []
      workerProfile?.skills?.forEach(item => {
        if (!_skills.includes(item?.id)) _skills.push(item?.id)
      })
      setSkills(_jobRole?.skills.map(item => ({
        id: item.id, name: getUserSkillStr(item), checked: _skills.includes(item.id)
      })))
      const _qualifications = _application?.qualifications?.map(item => item.id) || []
      workerProfile?.qualifications?.forEach(item => {
        if (!_qualifications.includes(item?.id)) _qualifications.push(item?.id)
      })
      setQualifications(_jobRole?.qualifications.map(item => ({
        id: item.id, name: getUserQualificationStr(item), checked: _qualifications.includes(item.id)
      })))
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useFocusEffect(useCallback(() => {
    if (id) {
      window.location.href = `${process.env.REACT_APP_SCHEMES}job-detail/${id}`
      loadData()
    }
  }, []))

  useEffect(() => {
    if (jobRole?.id) {
      trackWithUserAccount(TrackEvents.ViewedJobAd, app?.userAccount, {
        jobName: `${roleTitle} - ${roleDescription}`
      })
    }
  }, [jobRole])

  const handleChangeExperiences = (id: number, val: boolean) => {
    setExperiences(experiences.map(item => ({...item, checked: item.id === id ? val : item.checked})))
  }
  const handleChangeSkills = (id: number, val: boolean) => {
    if (!_workerProfileSkills.includes(id) && role === constants.roles.WORKER) {
      navigation.push('workerAddSkillScreen', {originId: id})
      return
    }
    setSkills(skills.map(item => ({...item, checked: item.id === id ? val : item.checked})))
  }
  const handleChangeQualifications = (id: number, val: boolean) => {
    if (!_workerProfileQualifications.includes(id) && role === constants.roles.WORKER) {
      navigation.push('workerAddQualificationScreen', {originId: id})
      return
    }
    setQualifications(qualifications.map(item => ({...item, checked: item.id === id ? val : item.checked})))
  }

  const handleUploadFile = async () => {
    if (role === constants.roles.GUEST) {
      navigation.push('signInEmailCheckScreen', {jobRoleId: id})
      return
    } else if (!app.userAccount.userRoles.find(it => it.key === constants.roles.WORKER)) {
      navigation.push('createWorkerProfileScreen', {jobRoleId: id})
      return
    } else if (role !== constants.roles.WORKER) return
    openCvFileSelector()
  }
  const onUploadFile = async (response: File[]) => {
    if (role !== constants.roles.WORKER) return
    if (!Array.isArray(response) || response.length <= 0) return
    let firstAsset = response[0];
    setLoading(true);
    try {
      if (myCv.id) await app.environment.api.deleteFile(myCv.id)
      const res = await app.environment.api.uploadFile(firstAsset)
      if (res.kind === 'ok') {
        setMyCv({id: res.file.id, name: res.file.name})
      } else {
        AlertModalStore.alert('Error', 'Can not upload file')
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!plainFiles || plainFiles.length <= 0) return
    onUploadFile(plainFiles)
  }, [plainFiles])

  const handleCreateWorker = () => {
    navigation.push('createWorkerProfileScreen')
  }

  const switchToWorker = async () => {
    await app.user.setRole(constants.roles.WORKER)
    // await app.getMe()
    AlertModalStore.alert('Success', 'Switched. Please submit again.')
  }

  const showMyApplication = () => {
    setResultVisible(false)
    navigation.goBack()
    app.tabStore.setBusinessGroupMessageTab(WorkerJobListScreenTabs.application.key)
    navigation.push('manageJobScreen')
    navigation.push("userApplicantMessageScreen", {
      toUserId: jobRole?.jobAd?.owner?.id, applicationId: store.applicationId,
    })
  }
  const showSubmitApplicationResult = () => {
    if (timeoutVal) clearTimeout(timeoutVal)
    setResultVisible(true)
    timeoutVal = setTimeout(showMyApplication, 1500)
  }
  const handleSubmitApplication = async () => {
    if (role !== constants.roles.WORKER) {
      if (role === constants.roles.GUEST) {
        navigation.push('signInEmailCheckScreen', {jobRoleId: id})
      } else if (app.userAccount.userRoles.find(it => it.key === constants.roles.WORKER)) {
        AlertModalStore.alert('Info', 'Switch to worker role?', {
          onOk: {text: 'Yes', onPress: switchToWorker},
          onCancel: {text: 'Cancel'},
        } as IAlertModalAction)
      } else {
        AlertModalStore.alert('Info', 'Please create worker profile', {
          onOk: {text: 'Ok', onPress: handleCreateWorker},
          onCancel: {text: 'Cancel'},
        } as IAlertModalAction)
      }
      return
    }
    if (jobRole.jobAd.owner.id === app.userAccount.id) {
      AlertModalStore.alert('Error', 'Can not apply your own job.')
      return
    }
    if (applicationId) {
      showSubmitApplicationResult()
      return
    }
    try {
      setLoading(true)
      const res = await app.environment.api.editWorkerApplicationCv({
        jobRole: {id},
        ...(myCv.id ? {myCv: myCv.id} : null),
        experiences: experiences.filter(item => item.checked).map(item => item.id),
        skills: skills.filter(item => item.checked).map(item => item.id),
        qualifications: qualifications.filter(item => item.checked).map(item => item.id),
        status: ApplicationStatus.submitted
      })
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message)
        return
      }
      // setApplicationId(res.application.id)
      store.applicationId = res.application.id
      const _workerExperiences = [..._workerProfileExperiences];
      const _workerSkills = [..._workerProfileSkills];
      const _workerQualifications = [..._workerProfileQualifications];
      experiences.forEach(item => {
        if (item.checked && !_workerExperiences.includes(item.id)) _workerExperiences.push(item.id)
      })
      skills.forEach(item => {
        if (item.checked && !_workerSkills.includes(item.id)) _workerSkills.push(item.id)
      })
      qualifications.forEach(item => {
        if (item.checked && !_workerQualifications.includes(item.id)) _workerQualifications.push(item.id)
      })
      app.environment.api.updateWorkerProfileData(workerProfile?.id, {
        experiences: _workerExperiences,
        skills: _workerSkills,
        qualifications: _workerQualifications,
      })
      const trackOptions = {
        email: app?.userAccount?.email,
        role: jobRole?.id,
        locations: JSON.stringify(jobRole?.locations?.map(it => it.id)),
        industries: JSON.stringify(jobRole?.industries?.map(it => it.id)),
      }
      trackWithUserAccount(TrackEvents.AppliedForJob, app?.userAccount, trackOptions)
      trackWithUserAccount(TrackEvents.NewApplication, app?.userAccount, trackOptions)
      showSubmitApplicationResult()
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  // const handleShareApplication = async () => {
  //   try {
  //     // const shareResponse = await Share.open({
  //     //   url: 'https://tap4work-web.testbox.com.au/job-detail?id=' + id,
  //     //   title: 'Tap4Word'
  //     // });
  //     // console.log(shareResponse);
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  // }

  const handlePinApplication = async () => {
    try {
      setLoading(true)

      if (pinnedId) {
        const res = await app.environment.api.deleteJobRoleBookmark(pinnedId)
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        setPinnedId(0)
      } else {
        const res = await app.environment.api.createJobRoleBookmark({
          user: app?.userAccount?.id,
          jobRole: id,
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        setPinnedId(res.item?.id)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const handleMessagePress = async () => {
    if (!app.user.role || app.user.role === constants.roles.GUEST) {
      navigation.push('signInEmailCheckScreen', {jobRoleId: id})
    } else if (jobRole?.jobAd?.owner?.id === app?.userAccount?.id) {
      AlertModalStore.alert('Error', 'Can chat in your own job.')
    } else if (applicationId && role === constants.roles.WORKER) {
      navigation.goBack()
      navigation.push("userApplicantMessageScreen", {
        toUserId: jobRole?.jobAd?.owner?.id, applicationId: applicationId,
      })
    } else {
      const res = await app.environment.api.editWorkerApplicationCv({
        jobRole: {id}, status: ApplicationStatus.viewing
      })
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message)
        return
      }
      navigation.goBack()
      navigation.push("userApplicantMessageScreen", {
        toUserId: jobRole?.jobAd?.owner?.id, applicationId: res?.application?.id,
      })
    }
  }

  const handleViewBusinessProfile = () => navigation.push('businessProfileScreen', {
    businessProfileId: jobRole?.jobAd?.businessProfile?.id
  })

  const renderCheckIcon = (item: IKvItemCheckModel, data: number[]) => {
    return (
      <SvgIcon
        preset={item.checked || data.includes(item.id) ? "primary" : "dark"}
        icon={
          item.checked ? "check-square"
            : data.includes(item.id) ? "square-regular"
            : "square-plus"
        }
        theme={
          item.checked ? "solid" : data.includes(item.id) ? "regular" : "solid"
        }
      />
    )
  }

  const showCheckboxButton = (item: IKvItemCheckModel, data: number[]) => {
    return item.checked || data.includes(item.id) || role !== constants.roles.WORKER
  }

  return (
    <>
      <PureBottomModal onClose={() => navigation.goBack()}>
        <SafeAreaView style={{flex: 1}}>
          <Header
            preset={'transparent'}
            header={() => (
              <View style={{flexDirection: 'row', alignItems: "center"}}>
                <View style={{}}>
                  {logo ? <AvatarImage
                      onPress={handleViewBusinessProfile}
                      source={{uri: logo}}
                      preset={"medium"}/> :
                    <AvatarDefault onPress={handleViewBusinessProfile} name={jobRole?.jobAd?.businessProfile?.name}
                                   preset={"medium"}/>}

                </View>
                {role !== constants.roles.GUEST && (
                  <View style={{flex: 1, flexDirection: 'row', alignItems: "center", justifyContent: 'flex-end'}}>
                    {role === constants.roles.WORKER && (
                      <SvgIcon
                        onPress={handlePinApplication}
                        icon={"bookmark"} theme={pinnedId ? "solid" : "regular"} preset="normal"/>
                    )}
                  </View>
                )}
              </View>
            )}
            style={{marginRight: 10}}
            rightIcon={'times'} rightIconPreset={'smallBlue'} onRightPress={() => navigation.goBack()}
          />
          <ScrollView style={{flex: 1}} showsVerticalScrollIndicator={false}>
            <View style={{paddingHorizontal: 20}}>
              <TouchableOpacity style={{marginTop: 12}}>
                <Text text={roleDescription} preset="h1"/>
                <View style={{marginTop: 9}}/>
                <Text text={roleTitle} preset="h2" style={{color: color.palette.blue, fontSize: 20}}/>
              </TouchableOpacity>
              <View style={{marginTop: 24}}>
                <View style={LINE}>
                  <Text preset="h4Dark">Job Ad Description </Text>
                </View>
                <Text preset="body1" style={{marginTop: 8}} numberOfLines={seeMore ? undefined : 3}>
                  {jobRole?.jobAd?.description}
                </Text>
                <TouchableOpacity style={SEE_ALL} onPress={() => setSeeMore(!seeMore)}>
                  <Text
                    text={seeMore ? "See less" : "See more"} preset="body2"
                    style={{fontWeight: 'bold', color: color.palette.blue}}/>
                  <SvgIcon icon={seeMore ? "chevron-up" : "chevron-down"} preset={"tiny"} style={{marginLeft: 8}}/>
                </TouchableOpacity>
              </View>
              <View style={{marginTop: 24}}>
                <View style={LINE}>
                  <Text preset="h4Dark">Responsibilites</Text>
                </View>
                <Text preset="body1" style={{marginTop: 8}}>
                  {jobRole?.jobAd?.responsibilities}
                </Text>
              </View>

              <View style={{marginTop: 24}}>
                <View style={LINE}>
                  <Text preset="h4Dark">Job Details</Text>
                </View>
                <View style={{marginTop: 8, flexDirection: "row", flexWrap: 'wrap'}}>
                  <Tag
                    icon="user"
                    children={String(jobRole?.numberOfPositions)}
                    textPreset="body" textStyle={{color: color.palette.dark}}
                    preset="smaller"
                    containerStyle={{marginBottom: 10, marginRight: 4}}
                  />
                  <Tag
                    preset="smaller"
                    icon="calendar-day"
                    children={stringToDateStr(jobRole?.startDate)}
                    textPreset={'body'} textStyle={{color: color.palette.dark}}
                    containerStyle={{marginBottom: 10, marginRight: 4}}
                  />
                  <Tag
                    preset="iconSmall"
                    icon="pen-field"
                    children={jobRole?.workType}
                    textPreset={'body'} textStyle={{color: color.palette.dark}}
                    containerStyle={{marginBottom: 10, marginRight: 4}}
                  />
                  <Tag
                    icon="money-check-dollar"
                    preset={"iconSmall"}
                    children={`${paymentType} ${jobRole?.amount}`}
                    textPreset="body" textStyle={{color: color.palette.dark}}
                    containerStyle={{marginBottom: 10, marginRight: 4}}
                  />
                  <Tag
                    icon="map-marker-alt"
                    children={location}
                    textPreset="body" textStyle={{color: color.palette.dark}}
                    preset="smaller"
                    containerStyle={{marginBottom: 10, marginRight: 4}}
                  />
                </View>
              </View>

              <View style={{marginTop: 24}}>
                <View style={LINE}>
                  <Text preset="h4Dark">Experience</Text>
                </View>
                <Text
                  preset="body2Grey"
                  style={{color: color.palette.greyHigh, marginTop: 8}}
                >
                  Tick all the boxes that apply to you. This will appear in your application.
                </Text>
                <View style={{marginTop: spacing[4]}}>
                  <View style={{flexDirection: "row", flexWrap: 'wrap'}}>
                    {experiences?.map((item, index) => (
                      <View key={index.toString()} style={styles.checkboxContainer}>
                        <Checkbox
                          style={showCheckboxButton(item, _workerProfileSkills) ? styles.checkboxLight : styles.checkboxPlus}
                          textStyle={role !== constants.roles.WORKER ? {paddingLeft: 0} : null}
                          outlineStyle={{display: role !== constants.roles.WORKER ? 'none' : 'flex'}}
                          value={item.checked}
                          onToggle={(val) => handleChangeExperiences(item.id, val)} text={item?.name}
                          multiline={true}
                        />
                      </View>
                    ))}
                  </View>
                </View>
              </View>

              <View style={{marginTop: 24}}>
                <View style={LINE}>
                  <Text preset="h4Dark">Skills</Text>
                </View>
                <Text
                  preset="body2Grey"
                  style={{color: color.palette.greyHigh, marginTop: 8}}
                >
                  Tick all the boxes that apply to you. This will appear in your application.
                </Text>
                <View style={{marginTop: 16, backgroundColor: color.palette.white}}>
                  <View style={{flexDirection: "row", flexWrap: 'wrap'}}>
                    {skills?.map((item, index) => (
                      <View key={index.toString()} style={styles.checkboxContainer}>
                        <Checkbox
                          style={showCheckboxButton(item, _workerProfileSkills) ? styles.checkboxLight : styles.checkboxPlus}
                          textStyle={role !== constants.roles.WORKER ? {paddingLeft: 0} : null}
                          outlineStyle={{display: role !== constants.roles.WORKER ? 'none' : 'flex'}}
                          value={item.checked}
                          onToggle={(val) => handleChangeSkills(item.id, val)} text={item?.name}
                          checkIcon={renderCheckIcon(item, _workerProfileSkills)}
                          multiline={true}
                        />
                      </View>
                    ))}
                  </View>
                </View>
              </View>

              <View style={{marginTop: 24}}>
                <View style={LINE}>
                  <Text preset="h4Dark">Qualification</Text>
                </View>
                <Text
                  preset="body2Grey"
                  style={{color: color.palette.greyHigh, marginTop: 8}}
                >
                  Add all the qualifications that apply to you. This will appear in your application.
                </Text>
                <View style={{marginTop: 16, backgroundColor: color.palette.white}}>
                  <View style={{flexDirection: "row", flexWrap: 'wrap'}}>
                    {qualifications?.map((item, index) => (
                      <View key={index.toString()} style={styles.checkboxContainer}>
                        <Checkbox
                          style={showCheckboxButton(item, _workerProfileQualifications) ? styles.checkboxLight : styles.checkboxPlus}
                          textStyle={role !== constants.roles.WORKER ? {paddingLeft: 0} : null}
                          outlineStyle={{display: role !== constants.roles.WORKER ? 'none' : 'flex'}}
                          value={item.checked}
                          onToggle={(val) => handleChangeQualifications(item.id, val)} text={item?.name}
                          checkIcon={renderCheckIcon(item, _workerProfileQualifications)}
                          multiline={true}
                        />
                      </View>
                    ))}
                  </View>
                </View>
              </View>
              <View style={{marginTop: 24, display: role !== constants.roles.WORKER ? 'none' : 'flex'}}>
                <SelectTextField
                  value={myCv?.name} numberOfLines={1}
                  style={{flex: 1}} onPress={handleUploadFile}
                  rightIcon={"file-upload"} preset={'primary'}
                  label={"My CV"} labelPreset={"bold"}
                  placeholder={"Upload CV"}
                />
              </View>
              <View style={{height: 200}}/>
            </View>
          </ScrollView>

          <BottomContainer backgroundColor={color.palette.white}>
            <View style={VIEW_CONTAIN_BOTTOM}>
              <TouchableOpacity onPress={handleMessagePress} style={{
                marginRight: 20,
                display: role !== constants.roles.WORKER ? 'none' : 'flex',
                // opacity: role !== constants.roles.WORKER ? 0 : 1,
              }}>
                <SvgIcon icon="chatbubble-message" preset={"smallBlue"}/>
              </TouchableOpacity>
              <View style={{flex: 1}}>
                <Button
                  disabled={loading}
                  onPress={handleSubmitApplication}
                  // preset={role !== constants.roles.WORKER ? "brandDisabled" : "brand"}
                  preset={"brand"}
                  text={"Submit Application"}
                />
              </View>
            </View>
          </BottomContainer>
          <BackdropModal visible={resultVisible} onClose={showMyApplication}>
            <View style={MODAL}>
              <SvgIcon icon={"check-circle"} preset={"alertIcon"}/>
              <View style={VIEW_MODAL}>
                <Text preset={"alertTitle"}>Application submitted</Text>
              </View>
            </View>
          </BackdropModal>
        </SafeAreaView>
      </PureBottomModal>
      <AlertModal/>
    </>
  )
})

const styles = StyleSheet.create({
  checkboxLight: {
    paddingTop: 4, paddingBottom: 6, paddingHorizontal: 8, borderRadius: 5
  },
  checkboxPlus: {
    backgroundColor: '#F6F9FF',
    paddingTop: 4, paddingBottom: 6, paddingHorizontal: 8, borderRadius: 5
  },
  checkboxContainer: {
    flex: 1,
    flexBasis: '50%',
    alignItems: "center",
    marginBottom: 10,
    overflow: 'hidden',
    paddingRight: 20,
  }
})
