import React, {FC} from "react"
import {View, ViewStyle, TextInput, Image, TouchableOpacity, TextInputProps, StyleProp} from "react-native"
import {SvgIcon} from "../icon/svg-icon"
import {Text} from ".."
import {color} from "../../theme"

export interface SearchBarProps extends TextInputProps {
  value?: any
  placeholder?: any
  editable?: any
  onFilterPress?: () => void
  onChangeText?: (e: any) => void
  onTouchStart?: (e?: any) => void
  onBlur?: (e?: any) => void
  style?: StyleProp<ViewStyle>
  searchOnly?: boolean
}

export const SearchBar: FC<SearchBarProps> = (props) => {
  const {onFilterPress, style, searchOnly, ...rest} = props;
  return (
    <View style={[CONTAINER, style]}>
      <View style={VIEW_CONTAINER}>
        <View style={{justifyContent: 'center'}}>
          <Image
            source={require('../../../assets/images/search-icon.png')}
            resizeMode={'contain'}
            style={{width: 20, height: 20}}
          />
        </View>
        <View style={SEARCH}>
          <TextInput
            placeholder="Search..."
            placeholderTextColor={color.palette.textMuted}
            style={TEXT_SEARCH}
            {...rest}
          />
        </View>
        {!searchOnly && (
          <View style={VIEW_FILTER}>
            <TouchableOpacity style={FILTER} onPress={onFilterPress}>
              <SvgIcon icon={"filteruser"}/>
              <Text preset="mediumBlue" text="Filters" style={{marginLeft: 8}}/>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </View>
  )
}

export const CONTAINER: ViewStyle = {
  marginHorizontal: 20,
  marginTop: 10,
  marginBottom: 20,
}

export const VIEW_CONTAINER: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
}

export const VIEW_FILTER: ViewStyle = {
  flex: 1,
  alignItems: "flex-end",
}

export const FILTER: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
}

export const SEARCH: ViewStyle = {
  flex: 3,
  marginLeft: 10,
  borderLeftWidth: 2,
  borderColor: color.palette.blue,
}

export const TEXT_SEARCH = {
  height: 20,
  marginLeft: 10,
  color: color.palette.dark,
  fontSize: 16,
  paddingVertical: 0, marginVertical: 0,
  outline: '0 solid transparent',
}
