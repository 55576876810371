import * as React from "react"
import {
  ImageSourcePropType,
  ImageStyle,
  StyleProp,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewStyle
} from "react-native";
import {color} from "../../theme";
import {AutoImage as Image} from "../auto-image/auto-image";


const BASE = {
  width: 40,
  height: 40,
  resizeMode: 'cover'
}

export const presets = {
  default: BASE,
  small: {resizeMode: 'cover', width: 40, height: 40},
  medium: {resizeMode: 'cover', width: 50, height: 50},
  mediumSquare: {borderRadius: 0, resizeMode: 'cover', width: 50, height: 50},
  avatar: {borderRadius: 10, width: 48, height: 48},
  socialIcon: {width: 48, height: 48},
  businessAvatar: {width: 68, height: 68},
  large: {resizeMode: 'cover', width: 76, height: 76, borderRadius: 16,},
  round: {width: 50, height: 50, borderRadius: 50, resizeMode: 'cover'},
  mediumRound: {width: 60, height: 60, borderRadius: 60, resizeMode: 'cover'},
  lgRound: {width: 120, height: 120, borderRadius: 120, resizeMode: 'cover'},
  smallRound: {width: 40, height: 40, borderRadius: 40},
  tinyRound: {width: 24, height: 24, borderRadius: 20},
  businessLogo: {width: 95, height: 95},
  walletImageIcon: {width: 40, height: 48, borderRadius: 0},
  walletPdfIcon: {width: 48, height: 48, borderRadius: 0},
  walletFile: {width: 30, height: 32, borderRadius: 0},
  mailImage: {width: 129, height: 83, borderRadius: 0},
}
export type AvatarImagePresets = keyof typeof presets

export interface AvatarImageProps extends TouchableOpacityProps {
  containerStyle?: StyleProp<ViewStyle>
  source: ImageSourcePropType
  preset?: AvatarImagePresets
  dot?: boolean
  px?: number
  py?: number
  onPress?: () => any
  style?: any
}

const DOT_STYLE: ViewStyle = {
  width: 10,
  height: 10,
  backgroundColor: color.palette.red,
  borderRadius: 10,
  borderWidth: 2,
  borderColor: color.palette.white,
  position: 'absolute',
  top: 3,
  right: 3,
}
const ROOT: ImageStyle = {
  resizeMode: "contain",
  borderRadius: 10
}
export const AvatarImage = (props: AvatarImageProps) => {
  const {source, preset, containerStyle, dot, px, py, onPress} = props
  const styleOverride = presets[preset] || presets.default

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={[{
        position: 'relative',
        paddingHorizontal: px ?? 6, paddingVertical: py ?? 6
      }, containerStyle]}>
        <Image style={[ROOT, styleOverride]} source={source}/>
        {dot && <View style={DOT_STYLE}/>}
      </View>
    </TouchableOpacity>
  )
}
