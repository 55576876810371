import React, {FC, useCallback, useEffect, useState} from "react"
import {
  View,
  SafeAreaView, ScrollView,
} from "react-native"
import {observer} from "mobx-react-lite"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Header, GradientBackground, Text, TextField, Button} from "../../../../components"
import {color} from "../../../../theme"
import {SelectTextField} from "../../../../components/text-field/select-text-field";
import {IKvItemCheckModel, KvItemPath} from "../../../../models/app/kv-item";
import {useStores} from "../../../../models";
import {JobTags} from "../../../../models/app/job-advert";
import {ModalEdit} from "../../skill-modal/modal-edit";
import {searchCompare, searchIgnoreCase} from "../../../../utils/utils";
import {NavigatorParamList} from "../../../../navigators";
import {AlertModalStore} from "../../../../models/app/alert-modal-store";
import {PureBottomModal} from "../../../../components/backdrop-modal/pure-bottom-modal";
import {isAndroid} from "../../../../utils/platform";
import {debounce} from "../../../../utils/debounce";
import {constants} from "../../../../utils/constants";

export interface JobTagRolesViewProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
}

export const JobTagRolesView: FC<JobTagRolesViewProps> = observer(
  ({navigation}) => {
    const {app} = useStores();
    const {filterJob} = app
    const isManager = app?.user?.role === constants.roles.MANAGER
    const businessProfileId = isManager ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState("")
    const [data, setData] = useState<Array<IKvItemCheckModel>>([]);
    const [customIds, setCustomIds] = useState<Array<number>>([]);
    const [selectedItems, setSelectedItems] = useState<Array<IKvItemCheckModel>>([]);
    const [visibleModal, setVisibleModal] = useState(false)
    const [newRole, setNewRole] = useState('')

    const loadData = async (text?: string) => {
        if (!text || text?.length < 3) return;

        try {
          text = text?.trim();
        const userRes = await app.environment.api.getKvItemBusinessList(KvItemPath.role, {
          ...(text ? {'filters[name][$containsi]': text} : null),
          'filters[businessProfile][id]': businessProfileId,
          businessProfileId: businessProfileId,
        })
        if (userRes.kind !== 'ok') return
        setCustomIds(userRes?.items?.map(it => it.id))
        const sysRes = await app.environment.api.getKvItemBusinessList(KvItemPath.role, {
          ...(text ? {'filters[name][$containsi]': text} : null),
        })
        if (sysRes.kind === 'ok') {
          setData(
            [...userRes?.items, ...sysRes?.items].map(it => ({
              ...it, checked: false
            }))
          )
        }
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }
    useEffect(() => {
      loadData()
    }, [])

    const toggleSelectItem = (id) => {
      let _item = null
      const newData = data.map((item) => {
        if (item.id === id) _item = item
        return ({
          ...item, checked: item.id === id ? !item.checked : item.checked
        })
      })
      setData(newData)
      if (selectedItems.find(it => it?.id === id)) setSelectedItems(selectedItems.filter(it => it?.id !== id))
      else setSelectedItems([...selectedItems, _item])
    }

    const onDone = () => {
      filterJob.setSelectedRoles(selectedItems)
      navigation.goBack()
    }

    const handleAddRole = async () => {
      if (!newRole || loading) return
      setLoading(true)
      try {
        const res = await app.environment.api.editKvItemBusiness(KvItemPath.role, {
          name: newRole,
          businessProfile: isManager ? app?.user?.businessProfile : app?.userAccount?.businessProfile,
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }

        setVisibleModal(false)
        setNewRole('')
        setSearch('')
        await loadData()
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    const delayLoadData = useCallback(debounce(loadData, 600), []);
    const handleChangeSearch = (text: string) => {
        setSearch(text)
        setLoading(true)
        // @ts-ignore
        delayLoadData(text)
    }

    const handleAddOther = () => {
      setNewRole(search)
      setVisibleModal(true)
    }

    const _systemData = data.filter(item => !customIds.includes(item.id) && searchIgnoreCase(item?.name, search))
    const _customData = data.filter(item => customIds.includes(item.id) && searchIgnoreCase(item?.name, search))
    const showBtnDone = selectedItems?.length > 0
    const showBtnAdd = !loading && !!search && data.find( item => searchCompare(item?.name, search)) === undefined;

    return (
      <PureBottomModal onClose={() => navigation.goBack()}>
        <SafeAreaView style={{flex: 1, backgroundColor: color.palette.white}}>
          <GradientBackground
            colors={[color.palette.gradientStart, color.palette.gradientStop]}
          />
          <Header
            preset={"modalHeader"}
            titlePreset={"headerTitle"}
            headerText={JobTags.roles.text}
            rightIcon={"times"}
            rightIconPreset={"smallBlue"}
            onRightPress={() => navigation.goBack()}
          />
          <View style={{paddingHorizontal: 20, flex: 1}}>
            <View style={{flex: 1}}>
              <View style={{}}>
                <Text preset={"label"}>{JobTags.roles.label}</Text>
                <View style={{marginTop: 24, marginBottom: 8}}>
                  <TextField
                    value={search} onChangeText={handleChangeSearch}
                    labelPreset={"default"}
                    rightIcon={"times-circle-fill"}
                    rightIconPreset={"default"}
                    rightIconPress={() => handleChangeSearch('')}
                  />
                </View>
                {showBtnAdd && (
                  <Button preset={"brandDisabled"} onPress={handleAddOther}>
                    <Text preset={"mediumBoldBlue"}>Add other</Text>
                  </Button>
                )}
              </View>
              {data.length > 0 ? (
                <View style={{marginTop: 24, flex: 1}}>
                  <ScrollView showsVerticalScrollIndicator={false} style={{flex: 1}}>
                    <View>
                      {_customData && _customData.length > 0 ? (
                        <View>
                          <View style={{marginHorizontal: 16}}>
                            <Text preset={"mediumBlue"}>Other</Text>
                          </View>
                          <View style={{height: 10}}/>
                          {_customData.map((item, index) => (
                            <SelectTextField
                              key={`${index}-${item.id}`}
                              value={item.name} onPress={() => toggleSelectItem(item.id)}
                              style={{marginBottom: 10}}
                              preset={item.checked ? 'customItemSelected' : 'transparent'}
                            />
                          ))}
                        </View>
                      ) : null}
                      {data ? (
                        <View>
                          <View style={{marginHorizontal: 16, marginBottom: 10}}>
                            <Text preset={"mediumBlue"}>Results</Text>
                          </View>
                          {_systemData.map((item, index) => (
                            <SelectTextField
                              key={`${index}-${item.id}`}
                              value={item.name} onPress={() => toggleSelectItem(item.id)}
                              style={{marginBottom: 10}}
                              preset={item.checked ? 'itemSelected' : 'transparent'}
                            />
                          ))}
                        </View>
                      ) : null}
                    </View>
                  </ScrollView>
                </View>
              ) : null}
            </View>
            <View style={{display: showBtnDone ? 'flex' : 'none', paddingTop: 10, marginBottom: isAndroid ? 10 : 5}}>
              <Button preset={"brand"} onPress={onDone} text={'Done'}/>
            </View>
          </View>
          <ModalEdit
            value={newRole} onChange={setNewRole}
            title={'Add Role'} label={'Add Role'}
            onClose={() => setVisibleModal(false)}
            onSubmit={handleAddRole}
            isVisible={visibleModal}/>
        </SafeAreaView>
      </PureBottomModal>
    )
  },
)
