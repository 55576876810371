import {Animated, Easing, View} from "react-native";
import {SvgIcon} from "./svg-icon";
import React, {useEffect} from "react";
import {SvgIconProps} from "./svg-icon.props";

export interface SpinIconProps extends SvgIconProps {
}

export const SpinIcon = (props: SpinIconProps) => {
  useEffect(() => {
    Animated.loop(
      Animated.timing(
        spinValue,
        {
          toValue: 1,
          duration: 1000,
          easing: Easing.linear,
          useNativeDriver: true
        }
      )
    ).start()
  })

  const spinValue = new Animated.Value(0);
  const spin = spinValue?.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg']
  })

  return (
    <View style={{flexDirection: 'row', justifyContent: 'center'}}>
      <Animated.View style={{transform: [{rotate: spin}]}}>
        <SvgIcon {...props}/>
      </Animated.View>
    </View>

  )
}
