import React, {useEffect, useRef, useState} from "react"
import {ScrollView, View} from "react-native"
import {Button, Header, Text} from "../../../../components"
import styles from "../styles"
import RNCamera from "react-webcam"
import {AlertModalStore} from "../../../../models/app/alert-modal-store"
import {useStores} from "../../../../models"
import {LoadingModal} from "../../../../components/backdrop-modal/loading-modal"
import {AlertModal} from "../../../../components/backdrop-modal/alert-modal";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { NavigatorParamList } from "../../../../navigators";
import { PureBottomModal } from "../../../../components/backdrop-modal/pure-bottom-modal";
import html2canvas from "html2canvas";

export type TakePhotoType = {
  uri: string;
  type: string;
  name: string;
  id: number
}

interface Props {
  navigation: NativeStackNavigationProp<NavigatorParamList>
}

const TakePhotoModalScreen = ({navigation}: Props) => {
  const {app} = useStores();
  const [step, setStep] = useState(0); // 0: font, 1: back
  const [tookPhoto, setTookPhoto] = useState(false);
  const [showCamera, setShowCamera] = useState(false);
  const [pictureFont, setPictureFont] = useState<any>();
  const [pictureBack, setPictureBack] = useState<any>();
  const imgFont = useRef(null);
  const imgBack = useRef(null);
  const [loading, setLoading] = useState(false)
  const cameraRef = useRef<RNCamera>(null)
  const vref = useRef(null)
  const [currentWidth, setCurrentWidth] = useState(0)

  useEffect(() => {
    const timeOut = setTimeout(() => {
      handleShowCamera(true);
      clearTimeout(timeOut);
    }, 1000);
    handleInit();
  }, []);

  const handleSavePhoto = async () => {
    if (step === 0) {
      setTookPhoto(false);
      setStep(1);
      await handleSaveImg(pictureFont.uri, true)
      handleShowCamera(true);
    } else {
      await handleSaveImg(pictureBack.uri, false)
    }
  }

  const handleInit = () => {
    setTookPhoto(false);
    setStep(0);
  }

  const handleShowCamera = (_showCamera) => {
    setShowCamera(_showCamera)
    if (_showCamera && cameraRef && cameraRef?.current) {
      // cameraRef?.current?.resumePreview()
    }
  }

  const handleReTake = () => {
    handleShowCamera(true);
    setTookPhoto(false);
  }

  const takePicture = async () => {
    setTookPhoto(true);
    if (cameraRef?.current) {
      // const options = {quality: 0.5, orientation: 'portrait', forceUpOrientation: true}
      // const response = await cameraRef?.current?.takePictureAsync(options);
      // const response = await cameraRef?.current?.getScreenshot();
      const response = (await html2canvas(vref.current)).toDataURL("image/png", 1.0);

      // await cameraRef?.current?.pausePreview();
      if (!response) return
      if (step === 0) {
        setPictureFont({
          uri: response,
          type: 'image/jpeg',
          name: `t4w_${new Date().getTime()}.jpg`
        });
      } else {
        setPictureBack({
          uri: response,
          type: 'image/jpeg',
          name: `t4w_${new Date().getTime()}.jpg`
        });
      }
      handleShowCamera(false)
    }
  };

  const sendTakePhoto = (fontPhoto: TakePhotoType, backPhoto: TakePhotoType) => {
    navigation.navigate('createCardDetailWallet', {
      fontId: fontPhoto.id,
      backId: backPhoto.id,
    })
  }

  const handleSaveImg = async (response: string, isFont) => {
    try {
      setLoading(true);

      const blRes: Response = await fetch(response);
      const blob: Blob = await blRes.blob();
      const res = await app.environment.api.uploadFile(
        new File([blob], `t4w_${new Date().getTime()}.jpg`, {type: 'image/jpeg'})
      )

      if (res.kind === 'ok') {
        if (isFont) {
          imgFont.current = {id: res.file.id, name: res.file.name}
        } else {
          imgBack.current = {id: res.file.id, name: res.file.name}
          sendTakePhoto(imgFont.current, imgBack.current);
        }
      } else {
        AlertModalStore.alert('Error', 'Can not upload file')
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false);
    }
  }

  const onClose = () => {
    navigation.goBack()
  }

  const stateCamera = step === 0 ? 'Font' : 'Back'

  return (
    <PureBottomModal onClose={onClose}>
      <Header
        preset={'modalHeader'}
        headerText={`Card-${stateCamera}`}
        titlePreset={'headerTitle'}
        rightIcon={'times'} rightIconPreset={'smallBlue'} onRightPress={onClose}
      />
      <View style={styles.bodyModal}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Text preset="textName" style={styles.titleModal}>
            {`Take a photo of the ${stateCamera} of your Card`}
          </Text>
          <View style={styles.wrapperImage}>
            <View
              onLayout={event => setCurrentWidth(event.nativeEvent.layout?.width)}
              style={[styles.cardImage, {height: currentWidth ? (currentWidth / 2) : 0}]}>
              {!!showCamera && (
                <View
                  ref={vref}
                  style={{width: '100%', height: currentWidth ? (currentWidth / 2) : '100%'}}>
                  <RNCamera
                    ref={cameraRef}
                    style={{}}
                    audio={false}
                    screenshotFormat="image/jpeg"
                  />
                </View>
              )}
              {step === 0 && !showCamera && pictureFont &&
              <img alt={'t4w-image'} src={pictureFont?.uri} style={{width: '100%'}}/>}
              {step === 1 && !showCamera && pictureBack &&
              <img alt={'t4w-image'} src={pictureBack?.uri} style={{width: '100%'}}/>}
            </View>
          </View>
          <Text preset="textName" style={styles.subModal}>
            {`Position card fully in the box above`}
          </Text>
          {tookPhoto ?
            <View>
              <Button preset="primaryOutLine" onPress={handleReTake} text={'Retake Photo'} style={styles.heightBtn}/>
              <Button preset="brand" onPress={handleSavePhoto} text={'Save & Next'} style={styles.heightBtn}/>
            </View>
            :
            <Button preset="primary" onPress={takePicture} text={'Take Photo'} style={styles.heightBtn}/>
          }
        </ScrollView>
      </View>
      <LoadingModal visible={loading}/>
      <AlertModal/>
    </PureBottomModal>
  )
};

export default TakePhotoModalScreen;
