import React from "react";
import {StyleProp, TextStyle, ViewStyle} from "react-native"
import {TxKeyPath} from "../../i18n"
import {color} from "../../theme";
import {SvgIconPresets, ThemeTypes} from "../icon/svg-icon.props";
import {TextPresets} from "../text/text.presets";

const BASE: ViewStyle = {
  flexDirection: "row",
  paddingHorizontal: 10,
  alignItems: "center",
  paddingVertical: 20,
  justifyContent: "flex-start",
  backgroundColor: color.palette.blue,
}

export const presets = {
  default: BASE,
  primary: {
    ...BASE,
    backgroundColor: color.palette.white,
  },
  headerTab: {
    ...BASE,
    paddingVertical: 0,
  },
  bgWhite: {
    ...BASE,
    backgroundColor: color.palette.white,
  },
  bgOrange: {
    ...BASE,
    backgroundColor: color.palette.orange
  },
  light: {
    ...BASE,
    paddingVertical: 10,
    backgroundColor: color.palette.bgBlue,
  },
  transparent: {
    ...BASE,
    backgroundColor: color.transparent,
    paddingVertical: 0,
    paddingHorizontal: 0,
  },
  pureTransparent: {
    ...BASE,
    backgroundColor: color.transparent,
    paddingVertical: 0,
  },
  transparentNormal: {
    ...BASE,
    backgroundColor: color.transparent,
  },
  modalHeader: {
    flexDirection: "row",
    paddingHorizontal: 10,
    alignItems: "center",
    paddingBottom: 20,
    justifyContent: "flex-start",
    backgroundColor: color.transparent,
  }
}

export type HeaderPresets = keyof typeof presets;

export interface HeaderProps {
  /**
   * Main header, e.g. POWERED BY IGNITE
   */
  headerTx?: TxKeyPath

  /**
   * header non-i18n
   */
  header?: (() => React.ReactNode)
  headerText?: string

  /**
   * Icon that should appear on the left
   */
  leftIcon?: string | (() => React.ReactNode)
  leftIconPreset?: SvgIconPresets
  leftIconTheme?: ThemeTypes

  /**
   * What happens when you press the left icon
   */
  onLeftPress?(): void

  /**
   * Icon that should appear on the right
   */
  rightIcon?: string | (() => React.ReactNode)
  rightIconPreset?: SvgIconPresets
  rightIconTheme?: ThemeTypes

  /**
   * What happens when you press the right icon
   */
  onRightPress?(): void

  /**
   * Container style overrides.
   */
  style?: StyleProp<ViewStyle>

  /**
   * Title style overrides.
   */
  titlePreset?: TextPresets
  titleStyle?: StyleProp<TextStyle>
  preset?: HeaderPresets
}
