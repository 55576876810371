import React, {FC, useCallback, useEffect, useState} from "react"
import {
  View,
  SafeAreaView, ScrollView, RefreshControl
} from "react-native"
import {observer} from "mobx-react-lite"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Header, GradientBackground, Text, TextField, Button} from "../../../../components"
import {color} from "../../../../theme"
import {SelectTextField} from "../../../../components/text-field/select-text-field";
import {IKvItemModel, KvItemPath} from "../../../../models/app/kv-item";
import {useStores} from "../../../../models";
import {AdvertRoleStore} from "../../../../models/app/advert-role";
import {CreateJobTags} from "../../../../models/app/job-advert";
import {searchCompare, searchIgnoreCase} from "../../../../utils/utils";
import {AlertModalStore} from "../../../../models/app/alert-modal-store";
import {PureBottomModal} from "../../../../components/backdrop-modal/pure-bottom-modal";
import {isAndroid} from "../../../../utils/platform";
import {debounce} from "../../../../utils/debounce";
import {constants} from "../../../../utils/constants";
import {PickerSelectField} from "../../../../components/picker-select/picker-select";
import {getUserQualificationStr, IUserQualificationModel} from "../../../../models/app/advanced-skill";
import {NavigatorParamList} from "../../../../navigators";

export interface JobTagQualificationsViewProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
}

export const JobTagQualificationsView: FC<JobTagQualificationsViewProps> = observer(
  ({navigation}) => {
    const {app} = useStores();
    const isManager = app?.user?.role === constants.roles.MANAGER
    const businessProfileId = isManager ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState("")
    const [data, setData] = useState<Array<IKvItemModel>>([]);
    const [customIds, setCustomIds] = useState<Array<number>>([]);
    const [qualificationEducationLevel, setEducationLevel] = useState(0)
    const [educationOpen, setEducationOpen] = useState(false)
    const [listEducations, setListEducations] = useState<Array<IKvItemModel>>([]);

    const initData = async () => {
      const categoryRes = await app.environment.api.getKvItemList('qualification-education-levels')
      if (categoryRes.kind !== 'ok') {
        AlertModalStore.alert('Error', categoryRes.message)
        return
      }
      setListEducations(categoryRes.items)
    }
    const loadData = async (text?: string, qualificationEducationLevel?: number) => {
      if (!text || text?.length < 3) return
      try {
        text = text?.trim();
        let userItems: IUserQualificationModel[] = []
        if (businessProfileId) {
          const userRes = await app.environment.api.getUserQualification({
            ...(qualificationEducationLevel ? {'filters[qualificationEducationLevel][id]': qualificationEducationLevel} : null),
            ...(text ? {'filters[courseName][$containsi]': text} : null),
            'filters[businessProfile][id]': businessProfileId,
            businessProfileId: businessProfileId,
          })
          if (userRes.kind !== 'ok') return
          userItems = userRes?.items
          setCustomIds(userRes?.items?.map(it => it.id))
        }
        const sysRes = await app.environment.api.getUserQualification({
          ...(qualificationEducationLevel ? {'filters[qualificationEducationLevel][id]': qualificationEducationLevel} : null),
          ...(text ? {'filters[courseName][$containsi]': text} : null),
        })
        if (sysRes.kind === 'ok') {
          setData(
            [...userItems, ...sysRes?.items].map(it => ({id: it.id, name: getUserQualificationStr(it)}))
          )
        }
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }
    useEffect(() => {
      initData()
    }, [])

    const toggleSelectItem = (id) => {
      AdvertRoleStore.toggleQualificationsTag(data?.find(it => it.id === id))
    }

    const onDone = () => {
      navigation.goBack()
    }

    const handleAddItem = async (courseName?: string) => {
      if (!courseName || loading) return
      setLoading(true)
      try {
        const _qualificationEducationLevel = listEducations?.find(it => it.id === qualificationEducationLevel)
        if (!_qualificationEducationLevel) return
        const res = await app.environment.api.editKvItemBusiness(KvItemPath.qualification, {
          courseName,
          qualificationEducationLevel,
          businessProfile: isManager ? app?.user?.businessProfile : app?.userAccount?.businessProfile,
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }

        setSearch('')
        loadData(courseName, qualificationEducationLevel)
        AdvertRoleStore.toggleQualificationsTag({
          id: res?.item?.id, name: _qualificationEducationLevel?.name
            ? [_qualificationEducationLevel?.name, courseName].join(' - ') : courseName,
        } as IKvItemModel)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    const delayLoadData = useCallback(debounce(loadData, 600), []);
    const handleChangeSearch = (text: string) => {
        setSearch(text)
        setLoading(true)
        // @ts-ignore
      delayLoadData(text, qualificationEducationLevel)
    }
    const loadDataFromState = () => {
      loadData(search, qualificationEducationLevel)
    }
    const changeQualificationEducationLevel = () => {
      loadData(search, qualificationEducationLevel)
    }

    const handleAddOther = () => {
      handleAddItem(search)
    }

    const renderItem = (item: IKvItemModel) => {
      const checked = AdvertRoleStore.qualifications?.find(it => it.id === item.id)
      return (
        <SelectTextField
          key={String(item.id)}
          value={item.name} onPress={() => toggleSelectItem(item.id)}
          style={{marginBottom: 10}}
          rightIcon={checked ? 'check-square' : 'square-regular'}
          rightIconTheme={checked ? 'solid' : 'regular'}
          rightIconPreset={checked ? 'primary' : 'default'}
          preset={checked ? 'itemSelected' : 'transparent'}
        />
      )
    }

    const _systemData = data.filter(item => !customIds.includes(item.id) && searchIgnoreCase(item?.name, search))
    const _customData = data.filter(item => customIds.includes(item.id) && searchIgnoreCase(item?.name, search))
    const showBtnAdd = !loading && search?.length >= 3 && qualificationEducationLevel && data.find( item => searchCompare(item?.name, search)) === undefined;
    const showBtnDone = AdvertRoleStore.qualifications?.length > 0
    return (
      <PureBottomModal onClose={() => navigation.goBack()}>
        <SafeAreaView style={{flex: 1}}>
          <GradientBackground
            colors={[color.palette.gradientStart, color.palette.gradientStop]}
          />
          <Header
            preset={"modalHeader"}
            titlePreset={"headerTitle"}
            headerText={CreateJobTags.qualifications.text}
            rightIcon={"times"}
            rightIconPreset={"smallBlue"}
            onRightPress={() => navigation.goBack()}
          />
          <View style={{paddingHorizontal: 20, flex: 1}}>
            <View style={{flex: 1}}>
              <View style={{zIndex: 9}}>
                <View style={{marginTop: 0, marginBottom: 8}}>
                  <View style={{display: 'none'}}>
                    <TextField
                      value={search} onChangeText={handleChangeSearch}
                      labelPreset={"default"}
                      rightIcon={"times-circle-fill"}
                      rightIconPreset={"default"}
                      rightIconPress={() => handleChangeSearch('')}
                    />
                  </View>
                  <View style={{marginBottom: 20, zIndex: 9}}>
                    <PickerSelectField
                      onOpen={() => setEducationOpen(true)}
                      onClose={() => setEducationOpen(false)}
                      onValueChange={(val) => {
                        setEducationLevel(val)
                        changeQualificationEducationLevel()
                      }}
                      items={listEducations.map(item => ({
                        key: item?.id, value: item?.id, label: item?.name
                      }))}
                      value={qualificationEducationLevel}
                      preset={"default"} placeholder="Select"
                      label={"Education Level"} labelPreset={"h4"}
                      rightIcon={educationOpen ? 'chevron-down' : 'chevron-right'}
                    />
                  </View>
                  <TextField
                    value={search} onChangeText={handleChangeSearch}
                    preset={qualificationEducationLevel ? 'primary' : 'primaryDisabled'}
                    editable={!!qualificationEducationLevel}
                    labelPreset={"h4"} label={'Course Name'}/>
                </View>
                {showBtnAdd && (
                  <Button preset={"brandDisabled"} onPress={handleAddOther} disabled={loading}>
                    <Text preset={"mediumBoldBlue"}>Add other</Text>
                  </Button>
                )}
              </View>
              {data.length > 0 ? (
                <View style={{marginTop: 24, flex: 1}}>
                  <ScrollView
                    refreshControl={<RefreshControl refreshing={loading} onRefresh={loadDataFromState}/>}
                    showsVerticalScrollIndicator={false} style={{flex: 1}}>
                    <View>
                      {_customData && _customData.length > 0 ? (
                        <View>
                          <View style={{marginHorizontal: 16}}>
                            <Text preset={"mediumBlue"}>Other</Text>
                          </View>
                          <View style={{height: 10}}/>
                          {_customData.map(renderItem)}
                        </View>
                      ) : null}
                      {data ? (
                        <View>
                          <View style={{marginHorizontal: 16, marginBottom: 10}}>
                            <Text preset={"mediumBlue"}>Results</Text>
                          </View>
                          {_systemData.map(renderItem)}
                        </View>
                      ) : null}
                    </View>
                  </ScrollView>
                </View>
              ) : null}
            </View>
            <View style={{display: showBtnDone ? 'flex' : 'none', paddingTop: 10, marginBottom: isAndroid ? 10 : 5}}>
              <Button preset={"brand"} onPress={onDone} text={'Done'} disabled={loading}/>
            </View>
          </View>
        </SafeAreaView>
      </PureBottomModal>
    )
  },
)
