import { ApisauceInstance, create, ApiResponse } from "apisauce"
import { getGeneralApiProblem } from "./api-problem"
import { ApiConfig, DEFAULT_API_CONFIG } from "./api-config"
import * as Types from "./api.types"
import { getRootStoreVal } from "../../models"
import {
  convertChatSeenMessagesModel,
  convertUserAccountModel,
  convertUserRoleModel
} from "../../models/app/user-account"
import { convertBusinessProfileModel, convertKvItemBusinessModel } from "../../models/app/business-profile"
import { convertFileUploadModel } from "../../models/app/file-upload"
import { convertKnItemModel, convertKvItemModel, IKvItemModel } from "../../models/app/kv-item"
import { convertWorkerProfileModel } from "../../models/app/worker-profile"
import { convertJobRoleBookmarkModel, convertJobRoleModel } from "../../models/app/job-role"
import { convertBusinessJobAdvertModel, convertJobAdvertModel } from "../../models/app/job-advert"
import {
  convertBusinessContactModel,
  convertBusinessGroupModel,
  CreateUserInput,
} from "../../models/app/contact"
import { convertJobRoleAdModel } from "../../models/app/job-role-ad"
import { convertWorkerApplicationModel } from "../../models/app/application"
import { convertBusinessAccessModel } from "../../models/app/business-access"
import { convertBusinessProfileUserModel } from "../../models/app/business-profile-user"
import { convertUserChatModel, convertUserMessageModel } from "../../models/app/message";
import {
  convertUserQualificationModel,
  convertUserSkillModel, convertWorkerQualificationModel,
  convertWorkerSkillModel, convertWorkerWalletItemModel,
} from "../../models/app/advanced-skill";
import { AlertModalStore } from "../../models/app/alert-modal-store";
import { convertWorkerNoteModel } from "../../models/app/worker-note";
import { convertJobLocationModel } from "../../models/app/location";

const POPULATE_WORKER_STR = [
  "indigenousStatuses",
  "rightToWork",
  "photo",
  "myCv",
  "document",
  "rightToWorkDocs",
  "industry",
  "myLocation",
  "industries",
  "workerRoles",
  "experiences",
  "skills",
  "skills.skillCategory",
  "qualifications",
  "qualifications.qualificationEducationLevel",
  "preferredLocations",
].join(",")

const POPULATE_USER_STR = [
  "avatar",
  "userRoles",
  "businessAccesses",
  "businessProfile",
  "businessProfile.logo",
  "businessProfile.industry",
  "businessProfile.location",
  "lastUserRole",
  "lastBusinessProfile",
  "lastBusinessProfile.logo",
  "lastBusinessProfile.industry",
  "workerProfile",
  "workerProfile.indigenousStatuses",
  "workerProfile.rightToWork",
  "workerProfile.photo",
  "workerProfile.myCv",
  "workerProfile.document",
  "workerProfile.rightToWorkDocs",
  "workerProfile.industry",
  "workerProfile.myLocation",
  "workerProfile.industries",
  "workerProfile.workerRoles",
  "workerProfile.experiences",
  "workerProfile.skills",
  "workerProfile.skills.skillCategory",
  "workerProfile.qualifications",
  "workerProfile.qualifications.qualificationEducationLevel",
  "workerProfile.preferredLocations",
].join(",")

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce: ApisauceInstance

  /**
   * Configurable options.
   */
  config: ApiConfig

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      withCredentials: false,
      headers: {
        Accept: "application/json",
      },
    })
    this.apisauce.addMonitor(this.monitor)
  }

  setAuth = (auth: string) => {
    __DEV__ && console.log("auth", auth)
    if (!auth) this.apisauce.deleteHeader("Authorization")
    else this.apisauce.setHeader("Authorization", `Bearer ${auth}`)
  }

  getAuth = () => {
    return this.apisauce.headers.Authorization
  }

  monitor = (response: ApiResponse<any>) => {
    __DEV__ && console.log(`${response.config.method} ${response.config.url}`)
    __DEV__ && console.log("RESPONSE", response)

    if (response.config.method === "get" && response.config.params !== undefined) {
      __DEV__ && console.log("Params", response.config.params)
    } else if (response.config.data !== undefined) {
      __DEV__ && console.log("Params", JSON.parse(response.config.data))
    }
    if (response.status === 403) {
      console.log("Error", response?.data?.data?.message || "Forbidden")
      // AlertModalStore.alert("Error", response?.data?.data?.message || "Forbidden")
    } else if (response.status === 401) {
      __DEV__ && console.log("No session")
      const store = getRootStoreVal()
      store?.app?.onSignOut()
      AlertModalStore.alert("Error", "Your session has expired.", {
        onOk: {
          text: "Ok",
          onPress: () => {
          },
        }
      })
    }
  }

  /**
   * Gets a list of users.
   */
  async getUsers(): Promise<Types.GetUsersResult> {
    // make the api call
    const response: ApiResponse<any> = await this.apisauce.get(`/users`)

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    const convertUser = (raw) => {
      return {
        id: raw.id,
        name: raw.name,
      }
    }

    // transform the data into the format we are expecting
    try {
      const rawUsers = response.data
      const resultUsers: Types.User[] = rawUsers.map(convertUser)
      return {kind: "ok", users: resultUsers}
    } catch {
      return {kind: "bad-data"}
    }
  }

  /**
   * Gets a single user by ID
   */

  async getUser(id: string): Promise<Types.GetUserResult> {
    // make the api call
    const response: ApiResponse<any> = await this.apisauce.get(`/users/${id}`, {
      populate: POPULATE_USER_STR,
    })

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {
      const resultUser: Types.User = {
        id: response.data.id,
        name: response.data.name,
      }
      return {kind: "ok", user: resultUser}
    } catch {
      return {kind: "bad-data"}
    }
  }

  // AUTH
  async userLogin(email: string, password: string): Promise<Types.LoginResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/auth/local`, {
      identifier: email,
      password: password,
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      return {
        kind: "ok",
        userAccount: convertUserAccountModel(response?.data?.user),
        jwt: response?.data?.jwt,
      }
    } catch {
      return {kind: "bad-data"}
    }
  }

  async userSignUp(data: any): Promise<Types.LoginResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/auth/local/register`, data)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      return {
        kind: "ok",
        userAccount: convertUserAccountModel(response?.data?.user),
        jwt: response?.data?.jwt,
      }
    } catch {
      return {kind: "bad-data"}
    }
  }

  async userResendMail(email: string): Promise<Types.GeneralApiResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/auth/send-email-confirmation`, {email})

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    if (response.data?.sent) return {kind: "ok"}
    return {kind: "unknown", temporary: true}
  }

  async onUpdateUser(data: any): Promise<Types.GetMeResult> {
    const response: ApiResponse<any> = await this.apisauce.put(
      `/users/me`, data,
      {
        params: {populate: POPULATE_USER_STR},
      },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      return {kind: "ok", userAccount: convertUserAccountModel(response?.data)}
    } catch {
      return {kind: "bad-data"}
    }
  }

  async updateUserProfile(
    {workerProfile, businessProfile}: { workerProfile?: number; businessProfile?: number },
    roles: number[],
  ): Promise<Types.GetMeResult> {
    const response: ApiResponse<any> = await this.apisauce.put(
      `/users/me`,
      {
        ...(workerProfile ? {workerProfile} : {}),
        ...(businessProfile ? {businessProfile} : {}),
        userRoles: roles,
      },
      {
        params: {populate: POPULATE_USER_STR},
      },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      return {kind: "ok", userAccount: convertUserAccountModel(response?.data)}
    } catch {
      return {kind: "bad-data"}
    }
  }

  async getMe(id?: number): Promise<Types.GetMeResult> {
    const response: ApiResponse<any> = await this.apisauce.get(id ? `users/${id}` : `/users/me`, {
      populate: POPULATE_USER_STR,
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const userAccount = convertUserAccountModel(response?.data)
      return {kind: "ok", userAccount}
    } catch (e) {
      console.log(e)
      return {kind: "bad-data", message: e.toString()}
    }
  }

  async createBusinessProfile(data: any): Promise<Types.GetBusinessProfileResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/business-profiles`, {data})

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response.data?.data
      return {
        kind: "ok",
        businessProfile: convertBusinessProfileModel({
          ...item?.attributes,
          id: item?.id,
        }),
      }
    } catch {
      return {kind: "bad-data"}
    }
  }

  async updateWorkerProfileRate(id: number, rate: number): Promise<Types.GeneralApiResult> {
    const response: ApiResponse<any> = await this.apisauce.post(
      `/worker-profiles/${id}/rate`,
      {data: {rate},},
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    return {kind: "ok"}
  }

  async createWorkerProfileData(data: any): Promise<Types.GetWorkerProfileResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/worker-profiles`,
      {data},
      {params: {populate: POPULATE_WORKER_STR},}
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response.data?.data
      return {
        kind: "ok",
        workerProfile: convertWorkerProfileModel({...item?.attributes, id: item?.id,}),
      }
    } catch (e) {
      console.log(437, e)
      return {kind: "bad-data"}
    }
  }

  async updateWorkerProfileData(id: number, data: any): Promise<Types.GetWorkerProfileResult> {
    const response: ApiResponse<any> = await this.apisauce.put(`/worker-profiles/${id}`,
      {data},
      {params: {populate: POPULATE_WORKER_STR},}
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response.data?.data
      return {kind: "ok", workerProfile: convertWorkerProfileModel({...item?.attributes, id: item?.id,}),}
    } catch (e) {
      console.log(437, e)
      return {kind: "bad-data"}
    }
  }

  async findMe(email: string): Promise<Types.GetMeResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/users/find-me`, {
      email,
      populate: "userRoles,businessAccesses",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const userAccount = convertUserAccountModel(response?.data)
      return {kind: "ok", userAccount}
    } catch {
      return {kind: "bad-data"}
    }
  }

  async sendPin(email: string): Promise<Types.GetMeResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/users/send-pin`, {
      email,
      populate: "userRoles,businessAccesses",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const userAccount = convertUserAccountModel(response?.data)
      return {kind: "ok", userAccount}
    } catch {
      return {kind: "bad-data"}
    }
  }

  async validatePin(email: string, pin: string): Promise<Types.ValidatePinResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/users/validate-pin`, {
      email, pin
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const userAccount = convertUserAccountModel(response?.data?.user)
      const jwt = response?.data?.jwt || ''
      return {kind: "ok", jwt, userAccount}
    } catch {
      return {kind: "bad-data"}
    }
  }

  async getUserRoles(): Promise<Types.GetUserRolesResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/user-roles`)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const userRoles = response?.data?.data.map((it) => ({
        ...convertUserRoleModel(it?.attributes),
        id: it?.id,
      }))
      return {kind: "ok", userRoles}
    } catch {
      return {kind: "bad-data"}
    }
  }

  async onForgotPassword(email: string): Promise<Types.GeneralApiResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/auth/forgot-password`, {
      email,
      username: email,
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    if (response.data?.ok) return {kind: "ok"}
    return {kind: "unknown", temporary: true}
  }

  async getBusinessProfileDetail(id: number): Promise<Types.GetBusinessProfileUserResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/business-profiles/${id}`, {
      populate: "logo,user",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response.data?.data
      return {
        kind: "ok",
        businessProfile: convertBusinessProfileUserModel({
          ...item?.attributes,
          id: item?.id,
        }),
      }
    } catch {
      return {kind: "bad-data"}
    }
  }

  async updateBusinessProfile(
    id: number, data: any
  ): Promise<Types.GetBusinessProfileResult> {
    const response: ApiResponse<any> = await this.apisauce.put(`/business-profiles/${id}`, {data})

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response.data?.data
      return {
        kind: "ok",
        businessProfile: convertBusinessProfileModel({...item?.attributes, id: item?.id}),
      }
    } catch {
      return {kind: "bad-data"}
    }
  }

  async uploadFile(file: File): Promise<Types.UploadFileResult> {
    let form = new FormData()
    form.append("files", file)
    const response: ApiResponse<any> = await this.apisauce.post(`/upload`, form)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      return {kind: "ok", file: convertFileUploadModel(response.data[0])}
    } catch {
      return {kind: "bad-data"}
    }
  }

  async deleteFile(id: number): Promise<Types.GeneralApiResult> {
    const response: ApiResponse<any> = await this.apisauce.delete(`/upload/files/${id}`)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    return {kind: "ok"}
  }

  async getKvItemList(type: string, filters?: any): Promise<Types.GetKvItemsResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/${type}`, {
      ...(filters || null),
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const items = response.data?.data?.map((item) =>
        convertKvItemModel({
          ...item?.attributes,
          id: item?.id,
        }),
      )
      return {kind: "ok", items}
    } catch {
      return {kind: "bad-data"}
    }
  }

  async getKnItemList(type: string): Promise<Types.GetKnItemsResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/${type}`)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const items = response.data?.data?.map((item) => convertKnItemModel({...item?.attributes, id: item?.id}),)
      return {kind: "ok", items}
    } catch {
      return {kind: "bad-data"}
    }
  }

  async getKvItemBusinessList(type: string, filters?: any): Promise<Types.GetKvItemsBusinessResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/${type}`, {
      ...(filters || null),
      populate: "businessProfile,user",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const items = response.data?.data?.map((item) =>
        convertKvItemBusinessModel({
          ...item?.attributes,
          id: item?.id,
        }),
      )
      return {kind: "ok", items}
    } catch {
      return {kind: "bad-data"}
    }
  }

  async editKvItemList(type: string, createdBy: number, item: IKvItemModel): Promise<Types.GetKvItemResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/${type}`, {
      data: {
        ...(item.id ? {id: item.id} : null),
        name: item.name,
        createdBy,
      },
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response.data?.data
      return {kind: "ok", item: convertKvItemModel({...item?.attributes, id: item?.id})}
    } catch {
      return {kind: "bad-data"}
    }
  }

  async editKvItemBusiness(
    type: string,
    data: any
  ): Promise<Types.GetKvItemResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/${type}`, {data})

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response.data?.data
      return {kind: "ok", item: convertKvItemModel({...item?.attributes, id: item?.id})}
    } catch {
      return {kind: "bad-data"}
    }
  }

  async getJobRoleAds(filters?: any): Promise<Types.GetJobRoleAdsResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/job-roles`, {
      populate: "locations,industries,role,experiences,skills,skills.skillCategory,qualifications," +
        "jobAd,jobAd.paymentTypes,jobAd.owner,jobAd.businessProfile,jobAd.businessProfile.logo," +
        "jobAd.owner.businessProfile.logo," +
        "contact,contact.user,contact.businessProfile,contact.businessProfile.logo",
      ...(filters || null),
      sort: "id:DESC",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const jobRoles = response?.data?.data.map((it) => ({
        ...convertJobRoleAdModel(it?.attributes),
        id: it?.id,
      }))
      return {kind: "ok", jobRoles}
    } catch {
      return {kind: "bad-data"}
    }
  }

  async getJobRoleAdDetail(id: number): Promise<Types.GetJobRoleAdResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/job-roles/${id}`, {
      populate:
        "locations,industries,role,experiences," +
        "skills,skills.skillCategory,qualifications,qualifications.qualificationEducationLevel," +
        "jobAd,jobAd.paymentTypes,jobAd.businessProfile,jobAd.businessProfile.logo," +
        "jobAd.owner,jobAd.owner.businessProfile.logo",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response?.data?.data
      const jobRole = {
        ...convertJobRoleAdModel(item?.attributes),
        id: item?.id,
      }
      return {kind: "ok", jobRole}
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async createJobAdvert(data: any): Promise<Types.GetJobAdvertResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/job-ads`, {data})

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response.data?.data
      return {
        kind: "ok",
        jobAdvert: {...convertJobAdvertModel(item?.attributes), id: item?.id},
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async getJobAdvertDetail(id: number): Promise<Types.GetJobAdvertResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/job-ads/${id}`, {
      // populate: 'jobRoles,jobRoles.locations,jobRoles.role,jobRoles.contact,paymentTypes'
      populate:
        "jobRoles," +
        "jobRoles.locations,jobRoles.industries,jobRoles.role,jobRoles.experiences," +
        "jobRoles.skills,jobRoles.skills.skillCategory," +
        "jobRoles.qualifications,jobRoles.qualifications.qualificationEducationLevel," +
        "jobRoles.contact,jobRoles.contact.user,paymentTypes,qualifications",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response.data?.data
      return {
        kind: "ok",
        jobAdvert: {...convertJobAdvertModel(item?.attributes), id: item?.id},
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async getBusinessJobAdvertDetail(id: number): Promise<Types.GetBusinessJobAdvertResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/job-ads/${id}`, {
      // populate: 'jobRoles,jobRoles.locations,jobRoles.role,jobRoles.contact,paymentTypes'
      populate:
        "paymentTypes,jobRoles," +
        "jobRoles.locations,jobRoles.industries,jobRoles.role," +
        "jobRoles.experiences," +
        "jobRoles.skills,jobRoles.skills.skillCategory," +
        "jobRoles.qualifications,jobRoles.qualifications.qualificationEducationLevel," +
        "jobRoles.contact,jobRoles.contact.user,paymentTypes,qualifications," +
        "jobRoles.applications," +
        "jobRoles.applications.workerProfile,jobRoles.applications.workerProfile.photo," +
        "jobRoles.applications.workerProfile.user,jobRoles.applications.workerProfile.user.avatar",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response.data?.data
      return {
        kind: "ok",
        jobAdvert: convertBusinessJobAdvertModel({...item?.attributes, id: item?.id}),
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async editJobAdvertDetail(
    id: number,
    data: any,
  ): Promise<Types.GetJobAdvertResult> {
    const response: ApiResponse<any> = await this.apisauce.put(
      `/job-ads/${id}`, {data},
      {
        params: {populate: "jobRoles,jobRoles.locations,jobRoles.role,paymentTypes"},
      },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response.data?.data
      return {
        kind: "ok",
        jobAdvert: {...convertJobAdvertModel(item?.attributes), id: item?.id},
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async createJobAdvertRoleByData(
    data: any
  ): Promise<Types.GetJobRoleResult> {
    const response: ApiResponse<any> = await this.apisauce.post(
      `/job-roles`, {data},
      {
        params: {populate: "locations,industries,role,experiences,skills,qualifications,contact"},
      },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response.data?.data
      return {
        kind: "ok",
        jobRole: convertJobRoleModel({...item?.attributes, id: item?.id}),
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async editJobAdvertRoleByData(id: number, data: any): Promise<Types.GetJobRoleResult> {
    const response: ApiResponse<any> = await this.apisauce.put(
      `/job-roles/${id}`, {data},
      {
        params: {populate: "locations,industries,role,experiences,skills,qualifications,contact"},
      },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response.data?.data
      return {
        kind: "ok",
        jobRole: convertJobRoleModel({...item?.attributes, id: item?.id}),
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async getContacts(filters?: any): Promise<Types.GetContactsResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/contacts`, {
      populate: "businessProfile,businessProfile.logo,user,user.avatar,user.workerProfile,user.workerProfile.photo",
      ...(filters || null),
      sort: "updatedAt:DESC",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const data = response.data?.data
      const items = data?.map((item) =>
        convertBusinessContactModel({
          ...item?.attributes,
          id: item?.id,
        }),
      )
      return {
        kind: "ok",
        items: filters['filters[status][$eq]']
          ? items.filter((item) => item.status === filters['filters[status][$eq]'])
          : items,
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async deleteContacts(filters?: any): Promise<Types.GeneralApiResult> {
    const response: ApiResponse<any> = await this.apisauce.delete(`/contacts/delete-multiple`, {...(filters || null)})

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    return {kind: "ok",}
  }

  async getWorkerContacts(filters?: any): Promise<Types.GetContactsResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/contacts/user`, {
      populate: "businessProfile,businessProfile.logo,user",
      ...(filters || null),
      sort: "updatedAt:DESC",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const data = response.data?.data
      const items = data?.map((item) => convertBusinessContactModel({...item?.attributes, id: item?.id}))
      return {
        kind: "ok", items: items.filter((item) => item.status === filters['filters[status][$eq]']),
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async getContactDetail(id: number): Promise<Types.GetContactResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/contacts/${id}`, {
      populate: "businessProfile,user",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const data = response.data?.data
      return {
        kind: "ok",
        item: convertBusinessContactModel({...data?.attributes, id: data?.id}),
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async inviteNewContact(data: any): Promise<Types.GetContactsResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/contacts`, {data})

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const data = response.data?.data
      return {kind: "ok", items: data?.map(item => convertBusinessContactModel({...item?.attributes, id: item?.id}))}
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async editContact(id: number, data: any): Promise<Types.GeneralApiResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/contacts/${id}/invitation`, data)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    return {kind: "ok",}
  }

  async getGroups(filters?: any): Promise<Types.GetGroupsResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/groups`, {
      populate: "businessProfile,contacts",
      ...(filters || null),
      sort: "updatedAt:DESC",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const data = response.data?.data
      return {
        kind: "ok",
        items: data?.map((item) =>
          convertBusinessGroupModel({
            ...item?.attributes,
            id: item?.id,
          }),
        ),
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async getGroupForMessage(filters?: any): Promise<Types.GetGroupsResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/groups`, {
      ...(filters || null),
      populate: "businessProfile,businessProfile.logo,createdUser,contacts",
      sort: "updatedAt:DESC",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const data = response.data?.data
      return {
        kind: "ok",
        items: data?.map((item) =>
          convertBusinessGroupModel({
            ...item?.attributes,
            id: item?.id,
          }),
        ),
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async getGroupForChat(filters?: any): Promise<Types.GetGroupsResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/groups/chat`, {
      ...(filters || null),
      populate: "businessProfile,businessProfile.logo,createdUser,contacts,contacts.user",
      sort: "updatedAt:DESC",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const data = response.data?.data
      return {
        kind: "ok",
        items: data?.map((item) =>
          convertBusinessGroupModel({
            ...item?.attributes,
            id: item?.id,
          }),
        ),
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async getGroupUnread(filters?: any): Promise<Types.GetGroupNotificationResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/groups/unread`, {
      ...(filters || null),
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      return {kind: "ok", item: {
          count: response.data?.count,
          groups: response.data?.groups?.map(item => isNaN(item) ? item?.id : item)
      }}
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async updateGroup(id: number, data: any): Promise<Types.GeneralApiResult> {
    const response: ApiResponse<any> = await this.apisauce.put(`/groups/${id}`, {data})

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    return {kind: "ok"}
  }

  async updateGroupContact(id: number, data: any): Promise<Types.GeneralApiResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/groups/${id}/contacts`, {data})

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    return {kind: "ok"}
  }

  async getGroupDetail(id: number, filters?: any): Promise<Types.GetGroupResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/groups/${id}`, {
      populate: "contacts,contacts.businessProfile," +
        "contacts.user,contacts.user.avatar,contacts.user.workerProfile,contacts.user.workerProfile.photo," +
        "contacts.businessProfile.logo",
      ...(filters || null),
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const data = response.data?.data
      return {kind: "ok", item: convertBusinessGroupModel({...data?.attributes, id: data?.id})}
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async deleteGroup(id: number, params?: any): Promise<Types.GeneralApiResult> {
    const response: ApiResponse<any> = await this.apisauce.delete(`/groups/${id}`, params)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    return {kind: "ok"}
  }

  async deleteGroups(filters?: any): Promise<Types.GeneralApiResult> {
    const response: ApiResponse<any> = await this.apisauce.delete(`/groups/multi`, {...(filters || null)})

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    return {kind: "ok",}
  }

  async createNewGroup(data: any): Promise<Types.GetGroupResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/groups`, {data})

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const data = response.data?.data
      return {
        kind: "ok",
        item: convertBusinessGroupModel({
          ...data?.attributes,
          id: data?.id,
        }),
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async getBusinessManagers(filters?: any): Promise<Types.GetBusinessAccessesResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/business-accesses`, {
      populate: "businessProfile,businessProfile.logo,user",
      ...(filters || null),
      sort: "updatedAt:DESC",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const data = response.data?.data
      return {
        kind: "ok",
        items: data.map((item) =>
          convertBusinessAccessModel({
            ...item?.attributes,
            id: item?.id,
          }),
        ),
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async getBusinessManagerDetail(id: number, filters?: any): Promise<Types.GetBusinessAccessResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/business-accesses/${id}`, {
      populate: "businessProfile,businessProfile.logo,user",
      ...(filters || null),
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response.data?.data
      return {
        kind: "ok",
        item: convertBusinessAccessModel({...item?.attributes, id: item?.id}),
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async getBusinessManagersByUser(filters?: any): Promise<Types.GetBusinessAccessesResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/business-accesses/user`, {
      populate: "businessProfile,businessProfile.logo,user",
      ...(filters || null),
      sort: "updatedAt:DESC",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const data = response.data?.data
      return {
        kind: "ok",
        items: data.map((item) =>
          convertBusinessAccessModel({
            ...item?.attributes,
            id: item?.id,
          }),
        ),
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async createBusinessManager(input: CreateUserInput): Promise<Types.GeneralApiResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/business-accesses`, {
      data: [input],
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    return {kind: "ok"}
  }

  async updateBusinessManager(id: number, data: any): Promise<Types.GeneralApiResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/business-accesses/${id}/invitation`, data)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    return {kind: "ok"}
  }

  async getPaymentTypes(): Promise<Types.GetKvItemsResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/payment-types`)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const items = response.data?.data?.map((item) =>
        convertKvItemModel({
          ...item?.attributes,
          id: item?.id,
        }),
      )
      return {kind: "ok", items}
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async resetPassword(password: string, code: string): Promise<Types.GeneralApiResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/auth/reset-password`, {
      code: code,
      password: password,
      passwordConfirmation: password,
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    return {kind: "ok"}
  }

  async getWorkerApplications(filters?: any): Promise<Types.GetWorkerApplicationsResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/applications`, {
      populate: "workerProfile,workerProfile.user,workerProfile.user.avatar,workerProfile.photo,myCv," +
        "jobRole,jobRole.locations,jobRole.industries,jobRole.role,jobRole.experiences,jobRole.skills,jobRole.qualifications," +
        "jobRole.jobAd,jobRole.jobAd.businessProfile.logo,jobRole.jobAd.owner.businessProfile.logo," +
        "jobRole.contact,jobRole.contact.user,jobRole.contact.businessProfile,jobRole.contact.businessProfile.logo",
      ...(filters || null),
      sort: "updatedAt:DESC",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const items = response?.data?.data
      const applications = items.map((item) =>
        convertWorkerApplicationModel({
          ...item?.attributes,
          id: item?.id,
        }),
      )
      return {kind: "ok", applications}
    } catch {
      return {kind: "bad-data"}
    }
  }

  async getBusinessJobAdApplication(applicantId: number): Promise<Types.GetWorkerApplicationResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/applications/${applicantId}`, {
      populate:
        "workerProfile,workerProfile.preferredLocations,workerProfile.user,workerProfile.user.avatar,workerProfile.photo," +
        "experiences,skills,qualifications," +
        "jobRole,jobRole.jobAd,jobRole.jobAd.owner," +
        "jobRole.locations,jobRole.industries,jobRole.role,jobRole.experiences,jobRole.skills,jobRole.qualifications",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response?.data?.data
      return {
        kind: "ok",
        application: convertWorkerApplicationModel({...item?.attributes, id: item?.id}),
      }
    } catch (e) {
      console.warn(e)
      return {kind: "bad-data"}
    }
  }

  async getBusinessJobAdApplications(filters: any): Promise<Types.GetWorkerApplicationsResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/applications`, {
      ...filters,
      populate:
        "workerProfile,workerProfile.preferredLocations,workerProfile.user,workerProfile.user.avatar,workerProfile.photo," +
        "experiences,skills,qualifications," +
        "jobRole,jobRole.jobAd," +
        "jobRole.locations,jobRole.industries,jobRole.role,jobRole.experiences," +
        "jobRole.skills,jobRole.skills.skillCategory," +
        "jobRole.qualifications,jobRole.qualifications.qualificationEducationLevel",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const items = response?.data?.data
      return {
        kind: "ok", applications: items?.map(item => convertWorkerApplicationModel({
          ...item?.attributes, id: item?.id
        }))
      }
    } catch (e) {
      console.warn(e)
      return {kind: "bad-data"}
    }
  }

  async editWorkerApplicationCv(data: any): Promise<Types.GetWorkerApplicationResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/applications`, {data})

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    try {
      const item = response?.data?.data
      return {
        kind: "ok", application: convertWorkerApplicationModel({
          ...item?.attributes,
          id: item?.id,
        })
      }
    } catch (e) {
      return {kind: "bad-data", message: e.toString()}
    }
  }

  async updateWorkerApplicationCv(id: number, data: any): Promise<Types.GeneralApiResult> {
    const response: ApiResponse<any> = await this.apisauce.put(`/applications/${id}`, {
      data: data,
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    return {kind: "ok"}
  }

  async getJobRoleBookmark(filters?: any): Promise<Types.GetJobRoleBookmarksResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/job-role-bookmarks`, {
      ...(filters || null),
      populate: "user,jobRole",
      sort: "updatedAt:DESC",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    try {
      const data = response.data?.data
      return {
        kind: "ok",
        items: data?.map((item) => convertJobRoleBookmarkModel({...item?.attributes, id: item?.id}),
        ),
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async createJobRoleBookmark(data: any): Promise<Types.GetJobRoleBookmarkResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/job-role-bookmarks`, {data})

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    const item = response.data?.data
    return {
      kind: "ok",
      item: convertJobRoleBookmarkModel({...item?.attributes, id: item?.id}),
    }
  }

  async deleteJobRoleBookmark(id: number): Promise<Types.GeneralApiResult> {
    const response: ApiResponse<any> = await this.apisauce.delete(`/job-role-bookmarks/${id}`)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    return {kind: "ok"}
  }

  async updateWorkerApplicationRate(id: number, rate: number): Promise<Types.GeneralApiResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/applications/${id}/rate`, {
      data: {rate},
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    return {kind: "ok"}
  }

  async getWorkerResponseMessage(filters?: any): Promise<Types.GetKvItemsResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/worker-responses`, {
      ...(filters || null),
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const data = response.data?.data
      return {
        kind: "ok",
        items: data?.map((item) =>
          convertKvItemModel({...item?.attributes, id: item?.id}),
        ),
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async createWorkerResponseMessage(
    data: any
  ): Promise<Types.GetKvItemResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/worker-responses`, {
      data
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response.data?.data
      return {
        kind: "ok", item: convertKvItemModel({...item?.attributes, id: item?.id}),
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async getWorkerNotes(filters?: any): Promise<Types.GetWorkerNoteModelsResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/worker-notes`, {
      ...(filters || null),
      populate: "user,jobRole",
      sort: "updatedAt:DESC",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    try {
      const data = response.data?.data
      return {
        kind: "ok",
        items: data?.map((item) => convertWorkerNoteModel({...item?.attributes, id: item?.id}),
        ),
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async createWorkerNote(data: any): Promise<Types.GetWorkerNoteModelResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/worker-notes`, {data})

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    const item = response.data?.data
    return {
      kind: "ok",
      item: convertWorkerNoteModel({...item?.attributes, id: item?.id}),
    }
  }

  async updateWorkerNote(id: number, data: any): Promise<Types.GetWorkerNoteModelResult> {
    const response: ApiResponse<any> = await this.apisauce.put(`/worker-notes/${id}`, {data})

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    const item = response.data?.data
    return {
      kind: "ok",
      item: convertWorkerNoteModel({...item?.attributes, id: item?.id}),
    }
  }

  async createNewMessage(data: any): Promise<Types.GetUserMessageResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/messages`, {data: data})

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response.data?.data
      return {kind: "ok", item: convertUserMessageModel({...item.attributes, id: item?.id})}
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async editNewMessage(
    id: number, data: any
  ): Promise<Types.GetUserMessageResult> {
    const response: ApiResponse<any> = await this.apisauce.put(`/messages/${id}`, {
      data
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response.data?.data
      return {kind: "ok", item: convertUserMessageModel({...item.attributes, id: item?.id})}
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async getMessageDetail(id: number, filters?: any): Promise<Types.GetUserMessageResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/messages/${id}`, {
      populate: "workerResponses,createdUser," +
        "group,group.contacts.user,group.contacts.user.avatar," +
        "group.contacts.businessProfile.logo,hideContactIds",
      ...filters
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response.data?.data
      return {kind: "ok", item: convertUserMessageModel({...item.attributes, id: item?.id})}
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async getNewMessage(
    filters?: any
  ): Promise<Types.GetUserMessagesResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/messages`, {
      // "filters[createdUser][$eq]": createdUser,
      ...(filters || null),
      populate: "group,businessProfile,businessProfile.logo,createdUser",
      sort: "updatedAt:DESC",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const items = response.data?.data?.map((item) => convertUserMessageModel({
        ...item?.attributes, id: item?.id
      }))
      return {kind: "ok", items}
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async getNewWorkerMessage(
    filters: any
  ): Promise<Types.GetUserMessagesResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/messages`, {
      // "filters[createdUser][$eq]": createdUser,
      ...(filters || null),
      populate: "group,group.contacts,group.contacts.user," +
        "createdUser,createdUser.businessProfile,createdUser.businessProfile.logo",
      sort: "updatedAt:DESC",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const items = response.data?.data?.map((item) =>
        convertUserMessageModel({
          ...item?.attributes,
          id: item?.id,
        }),
      )
      return {kind: "ok", items}
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async createNewChatMessage(messageId: number, fromUserId: number, toUserId: number, content: string): Promise<Types.GeneralApiResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/chats`, {
      data: {
        from: fromUserId,
        to: toUserId,
        content: content,
        message: messageId
      }
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    return {kind: "ok",}
  }

  async getNewChatMessage(filters: any): Promise<Types.GetUserChatsResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/chats`, {
      ...(filters || null),
      populate: "from,to,message,file",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const data = response.data?.data
      const items = data?.map((item) =>
        convertUserChatModel({
          ...item?.attributes,
          id: item?.id,
        }),
      )
      return {kind: "ok", items}
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async getChatInGroups(filters: any): Promise<Types.GetUserChatsResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/chat-in-groups`, {
      ...(filters || null),
      populate: "from,from.avatar,from.businessProfile,group,group.businessProfile,file",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const data = response.data?.data
      const items = data?.map((item) =>
        convertUserChatModel({
          ...item?.attributes,
          id: item?.id,
        }),
      )
      return {kind: "ok", items}
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async deleteChatInGroups(data: any): Promise<Types.GeneralApiResult> {
    const response: ApiResponse<any> = await this.apisauce.delete(`/chat-in-groups`, data)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    return {kind: "ok"}
  }

  async getUserQualification(filters?: any): Promise<Types.GetUserQualificationsResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/qualifications`, {
      populate: "qualificationEducationLevel,businessProfile,user",
      ...(filters || null),
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const items = response.data?.data
      return {
        kind: "ok", items: items?.map(item => convertUserQualificationModel({
          ...item?.attributes, id: item?.id
        }))
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async getUserQualificationDetail(id: number): Promise<Types.GetUserQualificationResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/qualifications/${id}`, {populate: "qualificationEducationLevel",})

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response.data?.data
      return {
        kind: "ok", item: convertUserQualificationModel({
          ...item?.attributes, id: item?.id
        })
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async getWorkerQualification(filters?: any): Promise<Types.GetWorkerQualificationsResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/worker-qualifications`, {
      populate: "document,businessProfile,user,qualification,qualification.qualificationEducationLevel",
      ...(filters || null),
      sort: "updatedAt:DESC",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const items = response.data?.data
      return {
        kind: "ok", items: items?.map(item => convertWorkerQualificationModel({
          ...item?.attributes, id: item?.id
        }))
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async getUserSkillDetail(id: number): Promise<Types.GetUserSkillResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/skills/${id}`, {
      populate: "businessProfile,user,skillCategory",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response.data?.data
      return {
        kind: "ok", item: convertUserSkillModel({
          ...item?.attributes, id: item?.id
        })
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async getUserSkill(filters?: any): Promise<Types.GetUserSkillsResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/skills`, {
      ...(filters || null),
      populate: "businessProfile,user,skillCategory",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const items = response.data?.data
      return {
        kind: "ok", items: items?.map(item => convertUserSkillModel({
          ...item?.attributes, id: item?.id
        }))
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async getWorkerSkill(filters?: any): Promise<Types.GetWorkerSkillsResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/worker-skills`, {
      populate: "document,user,skill,skill.skillCategory,frontImage,backImage",
      ...(filters || null),
      sort: "updatedAt:DESC",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const items = response.data?.data
      return {
        kind: "ok", items: items?.map(item => convertWorkerSkillModel({
          ...item?.attributes, id: item?.id
        }))
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async editUserQualification(data: any): Promise<Types.GetUserQualificationResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/qualifications`, {data})

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response.data?.data
      return {
        kind: "ok", item: convertUserQualificationModel({...item?.attributes, id: item?.id})
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async editUserSkill(data: any): Promise<Types.GetUserSkillResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/skills`, {
      data: data
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response.data?.data
      return {
        kind: "ok", item: convertUserSkillModel({...item?.attributes, id: item?.id})
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async emailConfirmation(code: string): Promise<Types.GeneralApiResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/auth/email-confirmation`, {
      confirmation: code
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    return {kind: "ok",}
  }

  async getWorkerWalletItemList(type: string, filters?: any): Promise<Types.GetWorkerWalletItemResults> {
    const response: ApiResponse<any> = await this.apisauce.get(`/${type}`, {
      sort: "id:DESC",
      populate: "workerProfile",
      ...(filters || null),
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const items = response.data?.data?.map((item) => convertWorkerWalletItemModel({...item?.attributes, ...item}),)
      return {kind: "ok", items}
    } catch {
      return {kind: "bad-data"}
    }
  }

  async editWorkerWalletItem(type: string, data: any): Promise<Types.GetWorkerWalletItemResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/${type}`, {data})

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response.data?.data
      return {kind: "ok", item: convertWorkerWalletItemModel({...item?.attributes, ...item})}
    } catch {
      return {kind: "bad-data"}
    }
  }

  async generalApiCreate(path: string, data: any): Promise<Types.GeneralApiResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/${path}`, {data})

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    return {kind: "ok",}
  }

  async generalApiDelete(path: string, id: number): Promise<Types.GeneralApiResult> {
    const response: ApiResponse<any> = await this.apisauce.delete(`/${path}/${id}`)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    return {kind: "ok",}
  }

  async setDeviceToken(path: string, data: any): Promise<Types.GeneralApiResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/${path}`, data)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    return {kind: "ok",}
  }

  async getJobLocation(filters?: any): Promise<Types.GetJobLocationsResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/locations`, {
      ...(filters || null),
      sort: "postcode"
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const items = response.data?.data
      return {
        kind: "ok", items: items?.map(item => convertJobLocationModel({
          ...item?.attributes, id: item?.id
        }))
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async getNotification(filters?: any): Promise<Types.GetUserNotificationResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/notifications/group`, {
      ...(filters || null),
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      return {kind: "ok", item: response.data}
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async getChatSeenMessages(filters?: any): Promise<Types.GetChatSeenMessagesResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/chat-seen-messages`, {
      ...(filters || null),
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    return {
      kind: "ok",
      items: response.data?.data?.map(it => convertChatSeenMessagesModel({...it?.attributes, id: it?.id}))
    }
  }

  async getListWorkerJobAds(filters?: any): Promise<Types.GetListWorkerResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/workers`, {
      populate: "workerProfile,avatar,workerProfile.industry",
      ...(filters || null),
      sort: "updatedAt:DESC",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      return {kind: "ok", workers: response?.data?.data}
    } catch {
      return {kind: "bad-data"}
    }
  }

  async createJobInvite(
    data: any
  ): Promise<Types.GetJobRoleResult> {
    const response: ApiResponse<any> = await this.apisauce.post(
      `/job-invitations`, {data},
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response.data?.data
      return {
        kind: "ok",
        jobRole: item,
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async getListInvites(filters?: any): Promise<Types.GetListInvitesResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/job-invitations`, {
      populate: "worker,jobAd,jobAd.jobRoles,jobAd.jobRoles.role,jobAd.jobRoles.locations," +
        "jobAd.paymentTypes,jobAd.businessProfile,jobAd.businessProfile.logo",
      ...(filters || null),
      sort: "updatedAt:DESC",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      return {kind: "ok", invites: response.data?.results}
    } catch {
      return {kind: "bad-data"}
    }
  }

  async getJobInvitesAdDetail(id: number): Promise<Types.GetListInvitesDetailResult> {
    const response: ApiResponse<any> = await this.apisauce.get(`/job-invitations/${id}`, {
      populate:
        "worker,jobAd,jobAd.jobRoles,jobAd.jobRoles.role,jobAd.jobRoles.locations,jobAd.owner," +
        "jobAd.paymentTypes,jobAd.businessProfile,jobAd.businessProfile.logo",
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const item = response?.data?.data
      return {
        kind: "ok", detailInvite: {
          ...convertJobRoleAdModel(item?.attributes),
          id: item?.id,
        }
      }
    } catch (e) {
      console.log(e)
      return {kind: "bad-data"}
    }
  }

  async acceptJobInvite(id: number): Promise<Types.AcceptInvite> {
    const response: ApiResponse<any> = await this.apisauce.put(`/job-invitations/${id}/accept`, {})

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    return {kind: "ok"}
  }

  async rejectJobInvite(id: number): Promise<Types.AcceptInvite> {
    const response: ApiResponse<any> = await this.apisauce.put(`/job-invitations/${id}/reject`, {})

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    return {kind: "ok"}
  }

  async deleteBulks(ids: string): Promise<Types.GeneralApiResult> {
    const response: ApiResponse<any> = await this.apisauce.delete(`/messages?ids=${ids}`)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    return {kind: "ok",}
  }

  async deleteGroupContacts(id: number, data: any): Promise<Types.GeneralApiResult> {
    const response: ApiResponse<any> = await this.apisauce.delete(`/groups/${id}/contacts`, data)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    return {kind: "ok",}
  }

  async sendMailItems(data: any): Promise<Types.GeneralApiResult> {
    const response: ApiResponse<any> = await this.apisauce.post(`/worker-skills/send-mail-items`, data)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    return {kind: "ok",}
  }
}
