import React, { FC, useEffect, useState } from "react"
import {
  View,
  SafeAreaView, ScrollView,
} from "react-native"
import { observer } from "mobx-react-lite"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { Header, GradientBackground, Text, Button } from "../../../../components"
import { color } from "../../../../theme"
import { SelectTextField } from "../../../../components/text-field/select-text-field";
import { useStores } from "../../../../models";
import { PureBottomModal } from "../../../../components/backdrop-modal/pure-bottom-modal";
import { isAndroid } from "../../../../utils/platform";
import { NavigatorParamList } from "../../../../navigators";
import { MainAppStore } from "../../../../models/app/main-app-store";
import { IKnItemModel, IKnParentItemModel } from "../../../../models/app/kv-item";

export interface UploadSelectTypeScreenProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
}

export interface ISelectTypeSection {
  id: number
  key: string
  name: string
  readOnly?: boolean
  data?: Array<IKnItemModel>
}

export interface ISelectTypeSectionDict {
  [key: string]: ISelectTypeSection
}

export const SelectTypeSections = {
  qualification: {key: 'qualification', name: 'Qualification', readOnly: true, data: [],} as ISelectTypeSection,
  skill: {key: 'skill', name: 'Skill', readOnly: true, data: [],} as ISelectTypeSection,
  cv: {key: 'cv', name: 'CV / Resume', readOnly: false, data: []} as ISelectTypeSection,
  rightToWork: {key: 'rightToWork', name: 'Right to Work', readOnly: false, data: []} as ISelectTypeSection,
  other: {key: 'other', name: 'Other', readOnly: false, data: []} as ISelectTypeSection,
}

export const UploadSelectTypeScreen: FC<UploadSelectTypeScreenProps> = observer(
  ({navigation}) => {
    // @ts-ignore
    const {app} = useStores();
    const [loading, setLoading] = useState(false)
    const [selectTypeSections, setSelectTypeSections] = useState<ISelectTypeSectionDict>(SelectTypeSections);
    const [selectedItem, setSelectedItem] = useState<IKnParentItemModel>();

    const loadData = async (text?: string) => {
      setLoading(true)
      try {
        const categoryRes = await app.environment.api.getKvItemList('skill-categories')
        const categoryItems: IKnItemModel[] = (categoryRes.kind !== 'ok') ? [] : categoryRes.items.map(item => ({
          key: String(item.id), id: item.id, name: item?.name
        } as IKnItemModel))
        const qualificationRes = await app.environment.api.getKvItemList('qualification-education-levels')
        const qualificationItems: IKnItemModel[] = (qualificationRes.kind !== 'ok') ? [] : qualificationRes.items.map(item => ({
          key: String(item.id), id: item.id, name: item?.name
        } as IKnItemModel))
        setSelectTypeSections({
          ...selectTypeSections,
          skill: {
            ...selectTypeSections?.skill,
            data: categoryItems
          },
          qualification: {
            ...selectTypeSections?.qualification,
            data: qualificationItems
          },
        })
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }
    useEffect(() => {
      loadData()
    }, [])

    const onDone = () => {
      MainAppStore.setSelectTypeSection(selectedItem)
      navigation.goBack()
    }

    const renderHeaderSection = (item: ISelectTypeSection) => {
      const checked = selectedItem?.key === item?.key && selectedItem?.parentId === item?.key
      return (
        <View key={item?.key}>
          {item?.readOnly ? (
            <View style={{marginHorizontal: 20, marginTop: 10, marginBottom: 10}}>
              <Text preset="bold">{item?.name}</Text>
            </View>
          ) : (
            <SelectTextField
              key={`${item.key}`} style={{marginLeft: 10, marginRight: 20}}
              value={item.name}
              onPress={() => setSelectedItem(selectedItem?.key === item.key ? null : {
                id: 0, key: item?.key, name: item?.name, parentId: item?.key
              })}
              rightIcon={checked ? 'check-circle' : 'circle'}
              rightIconTheme={checked ? 'solid' : 'regular'}
              rightIconPreset={checked ? 'primary' : 'default'}
              preset={checked ? 'itemSelected' : 'transparent'}
            />
          )}
          <View style={{marginHorizontal: 20}}>
            {item?.data?.map((_item) => renderSection(_item, item?.key))}
          </View>
        </View>
      )
    }
    const renderSection = (item: ISelectTypeSection, parentId: string) => {
      const checked = selectedItem?.key === item?.key && selectedItem?.parentId === parentId
      return (
        <SelectTextField
          key={`${item.key}`} style={{marginTop: 2}}
          value={item.name}
          onPress={() => setSelectedItem(selectedItem?.key === item.key ? null : {
            id: item?.id, key: item?.key, name: item?.name, parentId: parentId
          })}
          rightIcon={checked ? 'check-circle' : 'circle'}
          rightIconTheme={checked ? 'solid' : 'regular'}
          rightIconPreset={checked ? 'primary' : 'default'}
          preset={checked ? 'itemSelected' : 'transparentSm'}
        />
      )
    }

    const showBtnDone = !!selectedItem?.key
    return (
      <PureBottomModal onClose={() => navigation.goBack()}>
        <SafeAreaView style={{flex: 1}}>
          <GradientBackground
            colors={[color.palette.gradientStart, color.palette.gradientStop]}
          />
          <Header
            preset={"modalHeader"}
            titlePreset={"headerTitle"}
            headerText={'Select Type'}
            rightIcon={"times"}
            rightIconPreset={"smallBlue"}
            onRightPress={() => navigation.goBack()}
          />
          <View style={{flex: 1}}>
            <ScrollView showsVerticalScrollIndicator={false} style={{flex: 1}}>
              <View style={{marginTop: 4, flex: 1}}>
                {Object.values(selectTypeSections)?.map(renderHeaderSection)}
              </View>
            </ScrollView>
            <View style={{
              display: 'flex', paddingTop: 10, marginBottom: isAndroid ? 30 : 5,
              marginHorizontal: 20
            }}>
              <Button
                disabled={loading || !showBtnDone}
                preset={!showBtnDone ? 'disabled' : 'brand'}
                onPress={onDone} text={'Save & Next'}/>
            </View>
          </View>
        </SafeAreaView>
      </PureBottomModal>
    )
  },
)

