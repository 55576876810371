import React, {FC} from 'react';
import {View, ViewStyle} from 'react-native';
import {Text} from '..';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "./rich-editor.css";
import {isAndroid} from "../../utils/platform";
import {color} from "../../theme";

export interface CustomRichEditorProps {
  viewOnly?: boolean
  label?: string
  containerStyle?: ViewStyle
  style?: ViewStyle
  value: string
  onChange?: (value: string) => void
  richTextRef?: React.LegacyRef<ReactQuill>
}

export const CustomRichEditor: FC<CustomRichEditorProps> = (props) => {
  const {label, value, onChange} = props;

  return (
    <View>
      {label && <Text preset='primary'>{label}</Text>}
      <View style={{
        borderWidth: 0, borderRadius: 8, marginTop: 5,
        marginHorizontal: isAndroid ? 4 : 0,
        backgroundColor: color.palette.white,
        shadowColor: isAndroid ? color.palette.black : color.palette.black,
        shadowOffset: {width: 0, height: isAndroid ? 8 : 6},
        shadowOpacity: isAndroid ? 0.01 : 0.1,
        shadowRadius: 3,
        elevation: 8,
      }}>
        <ReactQuill
          value={value}
          onChange={onChange}
        />
      </View>
    </View>
  );
}
