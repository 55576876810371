import React, {FC, useState} from "react"
import {
  View,
  StyleSheet,
  SafeAreaView,
  ScrollView,
} from "react-native"
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {observer} from "mobx-react-lite";
import {color} from "../../../theme";
import {Button, GradientBackground, Header, Switch, Text, TextField} from "../../../components"
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import {AwSelectButton} from "../../../components/button/aw-select-button"
import {useStores} from "../../../models"
import UserCard from "../../../components/card/user-card"
import {TrackEvents, trackWithUserAccount} from "../../../services/track"
import {constants} from "../../../utils/constants"
import {TypeUserModel} from "../../../models/app/kv-item";
import {ParamListBase} from "@react-navigation/native";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";
import {isIos} from "../../../utils/platform";
import {AlertModalStore} from "../../../models/app/alert-modal-store";

export interface IUserContact {
  firstName: string
  email: string
}

export interface BulkScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
}

export const BulkMessageScreen: FC<BulkScreenProps> = observer(
  ({navigation}) => {
    const {app} = useStores();
    const isManager = app?.user?.role === constants.roles.MANAGER
    const listSelectedContact = app?.getListSelectedContact();
    const [isSave, setSave] = useState(false);
    const [contactName, setContactName] = useState('');

    const toggleSelectItem = (id: number) => {
      app.setListSelectedContact(listSelectedContact.map((item) => ({
        ...item, checked: item.id === id ? !item.checked : item.checked
      } as TypeUserModel)))
    }

    const onRightPress = () => {
      app.setListSelectedContact([])
      navigation.goBack()
    }

    const onCreateGroupName = async () => {
      const res = await app.environment.api.createNewGroup({
        save: isSave,
        name: contactName, contacts: listSelectedContact.filter(it => it.checked).map(it => it.id),
        businessProfile: isManager ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
        businessProfileId: isManager ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
      })
      if (res.kind === 'ok') {
        app.setListSelectedContact([])
        navigation.navigate('groupNewMessageScreen', {
          groupId: res?.item?.id
        })
        trackWithUserAccount(TrackEvents.CreatedGroup, app?.userAccount)
      } else {
        AlertModalStore.alert('Error', res.message)
      }
    }

    const isActive = listSelectedContact.findIndex(i => i.checked) !== -1 && contactName.trim().length > 0;
    return (
      <>
        <PureBottomModal onClose={() => navigation.goBack()}>
          <SafeAreaView style={styles.container}>
            <GradientBackground colors={[color.palette.gradientStart, color.palette.gradientStop]}/>
            <Header
              preset={'modalHeader'}
              headerText={'Create Bulk Message'}
              titlePreset={'headerTitle'}
              rightIcon={'times'} rightIconPreset={'smallBlue'} onRightPress={onRightPress}
            />
            <View style={{flex: 1}}>
              <ScrollView style={{flex: 1}} showsVerticalScrollIndicator={false}>
                <View style={{paddingHorizontal: 20, paddingVertical: 20, flex: 1}}>
                  <View style={{marginBottom: 20}}>
                    <AwSelectButton
                      onPress={() => navigation.navigate('useExistingContactScreen')}
                      text={'Use Existing Contact List'}
                    />
                  </View>
                  <View style={{marginBottom: 20}}>
                    <AwSelectButton
                      onPress={() => navigation.navigate('addExistingContactScreen', {isNeedSaveContact: true})}
                      text={'Add Existing Contact(s)'}
                    />
                  </View>
                  <View style={{marginBottom: 20}}>
                    <AwSelectButton
                      text={'Invite New Contact(s)'}
                      onPress={() => navigation.navigate('addContactScreen')}
                    />
                  </View>
                  <View style={{justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center'}}>
                    <Text preset="textName" style={{fontWeight: '700'}}>
                      Contact List
                    </Text>
                    {listSelectedContact.length > 0 &&
                    <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                      <Text preset={'textName'} style={{color: '#AAAAAA', marginRight: 5}}>Save</Text>
                      <Switch value={isSave} onToggle={setSave} style={{height: 20}}/>
                    </View>
                    }
                  </View>
                  {listSelectedContact.length > 0 ?
                    <>
                      <TextField
                        autoCapitalize='none' preset={'brand'}
                        value={contactName}
                        onChangeText={setContactName}
                        labelPreset={'label'}
                        placeholder={'Enter Contact List Name *'}
                        style={{marginTop: 15}}
                      />
                      <View style={{marginTop: 20}}>
                        {listSelectedContact.map((item, index) => (
                          <UserCard
                            key={`${index}-${item.id}`}
                            onClick={() => toggleSelectItem(item.id)}
                            isCheck={item.checked}
                            name={item.name}
                            avatar={item.avatar}
                          />
                        ))}
                      </View>
                    </>
                    : (
                      <View style={{marginTop: 25}}>
                        <Text preset="body" style={{color: color.palette.primary, fontStyle: 'italic'}}>
                          No Contacts have been added
                        </Text>
                      </View>
                    )}
                </View>
              </ScrollView>
            </View>
            <View style={{paddingTop: 12, paddingHorizontal: 20, marginBottom: isIos ? 0 : 30}}>
              <Button preset={isActive ? "brand" : "disabled"} onPress={onCreateGroupName} disabled={!isActive}>
                <Text preset={'whiteBold'}>Create Bulk Message</Text>
              </Button>
            </View>
          </SafeAreaView>
        </PureBottomModal>
        <AlertModal/>
      </>
    )
  },
)

const styles = StyleSheet.create({
  body: {
    flex: 1,
  },
  container: {
    flex: 1,
  },
  contentContainerStyle: {
    marginVertical: 20,
    paddingHorizontal: 10,
  },
  mainContainer: {},
  modalBtn: {
    marginVertical: 8
  },
  lineItem: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: 40
  }
});
