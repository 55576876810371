import {ViewStyle, TouchableOpacityProps} from "react-native";
import {color} from "../../theme";

export interface SvgStyle {
  width?: string | number
  height?: string | number
  fill?: string
  stroke?: string
}

export const themes = {
  brands: 'brands',
  light: 'light',
  regular: 'regular',
  solid: 'solid',
}

export type ThemeTypes = keyof typeof themes

const BASE: SvgStyle = {
  width: 20,
  height: 20,
  fill: color.palette.black30,
}

const BASE_TAG: SvgStyle = {
  width: 7,
  height: 12,
  fill: color.palette.dark,
}

export const presets = {
  default: BASE,
  tabIcon: {...BASE, fill: color.palette.primary},
  secondary: {...BASE, fill: color.palette.secondary},
  primary: {...BASE, fill: color.palette.blue},
  primaryMedium: {width: 40, height: 40, fill: color.palette.blue},
  medium: {width: 40, height: 40, fill: color.palette.blue},
  normal: {width: 24, height: 24, fill: color.palette.blue},
  normalDisabled: {width: 24, height: 24, fill: color.palette.black30},
  light: {...BASE, fill: color.palette.white},
  lightSm: {width: 15, height: 15, fill: color.palette.white},
  lightMd: {width: 30, height: 30, fill: color.palette.white},
  dark: {...BASE, fill: color.palette.black},
  grey: {...BASE, fill: color.palette.grey7},
  smallDark: {width: 30, height: 30, fill: color.palette.black},
  smallBlue: {width: 30, height: 30, fill: color.palette.blue},
  smallBrand: {width: 30, height: 30, fill: color.palette.orange},
  blueMed: {...BASE, fill: color.palette.blueMed},
  caption: {width: 14, height: 14, fill: color.palette.blue},
  captionBlack: {width: 14, height: 14, fill: color.palette.black},
  danger: {...BASE, fill: color.palette.red},
  warning: {...BASE, fill: color.palette.orange},
  orange: {width: 24, height: 24, fill: color.palette.orange},
  orangeSmall: {width: 20, height: 20, fill: color.palette.orange},
  alertIcon: {width: 80, height: 80, fill: color.palette.orange},
  orangeMid: {...BASE, fill: color.palette.orangeMid},
  success: {...BASE, fill: color.palette.greenMed},
  bgBlue: {...BASE, fill: color.palette.bgBlue},
  bgBlueMedium: {width: 40, height: 40, fill: color.palette.bgBlue},
  extraSmall: {...BASE_TAG},
  iconSmall: {...BASE_TAG, width: 16, height: 15},
  smaller: {...BASE_TAG, width: 11},
  smallerLight: {...BASE_TAG, width: 11, fill: color.palette.white},
  innerTextField: {width: 18, height: 18, fill: color.palette.black30},
  small: {width: 18, height: 18, fill: color.palette.blue},
  smallPrimary: {width: 18, height: 18, fill: color.palette.orange},
  smallNoMargin: {width: 20, height: 20, fill: color.palette.orange},
  smallNoMarginBlack: {width: 20, height: 20, fill: color.palette.black},
  smallerLink: { width : 16, height: 16, fill: color.palette.blue},
  socialIcon : {width : 24, height : 24, fill : color.palette.black},
  orangeBig : {width : 48, height: 48, fill : color.palette.orangeMid},
  tiny: {width: 12, height: 12, fill: color.palette.blue},
  tinyLight: {width: 12, height: 12, fill: color.palette.white},
  placeholderImage: {width: 50, height: 50, fill: color.palette.orangeLow},
  smallTag: {...BASE_TAG, width: 12},
  pingBubble: {width: 14, height: 14, fill: color.palette.red},
  triangleIcon: {width: 12, height: 12, fill: color.palette.greyHigh},
  starRegular: {width: 40, height: 40, fill: color.palette.bgBlue},
  starBold: {width: 40, height: 40, fill: color.palette.orangeMid},
  intro: {width: 106, height: 106},
  imgMsg: {width: 100, height: 100, fill: color.palette.black30},
  square: {width: 20, height: 20, fill: color.palette.greyInputBorder},
  smallBlue20: {width: 20, height: 20, fill: color.palette.blue},
  disable: {width: 24, height: 24, fill: color.palette.greyLink},
  cameraCard: {width: 40, height: 40},
  fileCard: {width: 31, height: 31},
}

export type SvgIconPresets = keyof typeof presets

export interface SvgIconProps extends TouchableOpacityProps {
  onPress?: (ev: any) => void
  style?: ViewStyle
  iconStyle?: SvgStyle
  icon?: string
  theme?: ThemeTypes
  preset?: SvgIconPresets
}
