import * as React from "react"
import {presets, SvgIconProps, themes} from "./svg-icon.props"
import {SvgIconSet} from "./svg-icon.set"
import {TouchableOpacity, View} from "react-native";

export const SvgIcon = (props: SvgIconProps) => {
  const {theme, preset, icon, style, onPress, iconStyle, ...rest} = props
  const activeTheme = theme || themes.regular
  const activeStyle = presets[preset] || presets.default
  const Icon = SvgIconSet[activeTheme][icon || 'exclamation-circle']

  return (
    onPress ? (
      <TouchableOpacity style={style} onPress={onPress} {...rest}>
        <Icon {...activeStyle} {...iconStyle}/>
      </TouchableOpacity>
    ) : (
      <View style={style} {...rest}>
        <Icon {...activeStyle}/>
      </View>
    )
  )
}
