import * as React from "react"
import {View, ViewStyle, ImageStyle} from "react-native"
import {Text} from "../text/text"
import {TextProps} from "../text/text.props";
import {color} from "../../theme";
import {ReactNode} from "react";

const BULLET_ITEM: ViewStyle = {
  flexDirection: "row",
  alignItems: 'center',
  marginBottom: 6
}
const BULLET: ImageStyle = {
  width: 4,
  height: 4,
  borderRadius: 4,
  backgroundColor: color.palette.black,
  marginLeft: 10,
  marginRight: 10,
}

export interface BulletItemProps extends TextProps {
  icon?: ReactNode
  children?: ReactNode
}

export function BulletItem(props: BulletItemProps) {
  const {icon, children, ...rest} = props;
  return (
    <View style={BULLET_ITEM}>
      {icon ? icon : <View style={BULLET}/>}
      {children ? children : <Text {...rest} />}
    </View>
  )
}
