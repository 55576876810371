import * as React from "react"
import {View, SafeAreaView} from "react-native"
import {FC, useCallback, useState} from "react"
import {ParamListBase, RouteProp, CommonActions, useFocusEffect} from "@react-navigation/native"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Button, GradientBackground, Text} from "../../components"
import {color} from "../../theme";
import {Header} from "../../components";
import {NavigatorParamList} from "../../navigators";
import {LoadingModal} from "../../components/backdrop-modal/loading-modal";
import {useStores} from "../../models";

const VerifyText = {
  success: {
    text: 'Verify email success!'
  },
  confirmed: {
    text: 'Email already confirmed!'
  },
  fail: {
    text: 'Verify email error with message: '
  },
}

export interface VerifyEmailScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'verifyEmailScreen'>
}

export const VerifyEmailScreen: FC<VerifyEmailScreenProps> = ({navigation, route}) => {
  const email = route.params?.email
  const code = route.params?.code
  const {app} = useStores();
  const [error, setError] = useState(false)
  const [verifyText, setVerifyText] = useState('')
  const [loading, setLoading] = useState(true)

  const loadData = async () => {
    setLoading(true)
    try {
      await app.onSignOut()
      const res = await app.environment.api.emailConfirmation(code)
      if (res.kind === 'ok') {
        setVerifyText(VerifyText.success.text)
        setError(false)
      } else if (!email) {
        setVerifyText(`${VerifyText.fail.text} ${res.message || 'Token invalid or Email verified'}`)
        setError(true)
      } else {
        const getMeRes = await app.environment.api.findMe(email)
        if (getMeRes.kind === 'ok' && getMeRes.userAccount.confirmed) {
          setVerifyText(VerifyText.confirmed.text)
          setError(false)
        } else {
          setVerifyText(`${VerifyText.fail.text} ${res.message || 'Token invalid or Email verified'}`)
          setError(true)
        }
      }
      window.location.href = `${process.env.REACT_APP_SCHEMES}sign-in`
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useFocusEffect(useCallback(() => {
    loadData()
  }, []))

  const goHome = () => {
    navigation.dispatch(
      CommonActions.reset({index: 0, routes: [{name: 'main'}],})
    );
  }

  const continueApp = () => {
    window.location.href = `${process.env.REACT_APP_SCHEMES}sign-in`
    goHome()
  }

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: color.palette.white,}}>
      <GradientBackground colors={[color.palette.gradientStart, color.palette.gradientStop]}/>
      <Header
        preset={"transparent"}
        rightIcon={'times'} rightIconPreset="smallBlue" onRightPress={goHome}
      />
      <View style={{flex: 1, paddingHorizontal: 16, justifyContent: 'space-between'}}>
        <View>
          <Text preset={"headerTitle"}>Verify email</Text>
          <View style={{height: 12}}/>
          <Text preset={"body1"} style={error ? {color: color.palette.red} : null}>{verifyText}</Text>
        </View>

        <View style={{flex: 1, justifyContent: 'center'}}>
          <Button
            onPress={continueApp}
            text="Continue to App" preset="primary"/>
        </View>
      </View>
      <LoadingModal visible={loading}/>
    </SafeAreaView>
  )
}

