import React, {FC, useEffect, useState} from "react"
import {View, SafeAreaView, ViewStyle} from "react-native"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Screen, Text, TextField} from "../../../components"
import {Header, Button} from "../../../components";
import {observer} from "mobx-react-lite";
import {CommonActions, ParamListBase, RouteProp} from "@react-navigation/native";
import {CustomRichEditor} from "../../../components/text-field/rich-editor"
import {useStores} from "../../../models";
import {LoadingModal} from "../../../components/backdrop-modal/loading-modal";
import {NavigatorParamList} from "../../../navigators";
import {constants} from "../../../utils/constants";
import {useFilePicker} from "use-file-picker";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";
import {isAndroid} from "../../../utils/platform";
import {AvatarImage} from "../../../components/avatar-image/avatar-image";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import {DefaultKvItemFile, IKvItemFileModel} from "../../../models/app/kv-item";
import {SvgIcon} from "../../../components/icon/svg-icon";
import AvatarDefault from "../../../components/avatar-image/AvatarDefault";
const facebookLogo = require("../../../../assets/images/icons/facebook.png")
const instagramLogo = require("../../../../assets/images/icons/instagram.png")
const twitterLogo = require("../../../../assets/images/icons/twitter-square.png")

export interface BusinessAccountEditScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'businessAccountProfileEditScreen'>
}

export const BusinessAccountProfileEditScreen: FC<BusinessAccountEditScreenProps> = observer(({navigation, route}) => {
  const skip = route.params?.skip;
  const {app} = useStores();
  const [loading, setLoading] = useState(true)
  const [logo, setLogo] = useState<IKvItemFileModel>(DefaultKvItemFile)
  const [website, setWebsite] = useState('')
  const [email, setEmail] = useState('')
  const [outline, setOutline] = useState('<div><br/></div>')
  const [fbLink, setFbLink] = useState('')
  const [instaLink, setInstaLink] = useState('')
  const [twLink, setTwLink] = useState('')
  const [openFileSelector, {plainFiles}] = useFilePicker({
    accept: 'image/*',
    maxFileSize: 2,
  });

  useEffect(() => {
    (async () => {
      setLoading(true)
      try {
        const res = await app.getMe()
        if (res.kind === 'ok') {
          const businessProfile = res.userAccount.businessProfile
          setLogo({
            id: businessProfile?.logo.id || 0,
            name: businessProfile?.logo?.name || '',
            url: businessProfile?.logo?.url || ''
          })
          // console.log(businessProfile, 8989)
          setWebsite(businessProfile?.website || '')
          setEmail(businessProfile?.email || '')
          setOutline(businessProfile?.outline || '')
          setFbLink(businessProfile?.fbLink || '')
          setInstaLink(businessProfile?.instaLink || '')
          setTwLink(businessProfile?.twLink || '')
        } else {
          AlertModalStore.alert('Error', res.message)
        }
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const goHome = () => {
    navigation.dispatch(
      CommonActions.reset({
        index: 0, routes: [{name: 'main'}],
      })
    );
  }

  const handleSave = async () => {
    const res = await app.environment.api.updateBusinessProfile(app.userAccount.businessProfile.id, {
      website: website,
      outline: outline,
      fbLink: fbLink,
      instaLink: instaLink,
      twLink: twLink,
      email: email
    })
    if (res.kind === 'ok') {
      app.user.setRole(constants.roles.BUSINESS)
      AlertModalStore.alert('Info', 'Saved', {
        onOk: {text: "OK", onPress: goHome}
      })
    } else {
      AlertModalStore.alert('Error', res.message || 'Can not save')
    }
  }

  const onPickerResult = async (response: File[]) => {
    if (Array.isArray(response) && response.length > 0) {
      setLoading(true);
      try {
        let firstAsset = response[0];
        if (logo.id) await app.environment.api.deleteFile(logo.id)
        const res = await app.environment.api.uploadFile(firstAsset)
        if (res.kind === 'ok') {
          app.environment.api.updateBusinessProfile(app.userAccount.businessProfile.id, {
            ...(res.file.id ? {logo: res.file.id} : null),
          })
          setLogo({id: res.file.id, name: res.file.name, url: res.file.url})
        } else {
          AlertModalStore.alert('Error', 'Can not upload file')
        }
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    if (!plainFiles || plainFiles.length <= 0) return
    onPickerResult(plainFiles)
  }, [plainFiles])

  const handleChangeLogo = async () => {
    await openFileSelector()
  }

  const handleLogoPress = async () => {
    if (logo?.id) {
      try {
        setLoading(true)
        await app.environment.api.deleteFile(logo.id)
        setLogo(DefaultKvItemFile)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    } else handleChangeLogo()
  }

  return (
    <>
      <PureBottomModal onClose={() => navigation.goBack()}>
        <SafeAreaView style={{flex: 1}}>
          <Header
            preset={"pureTransparent"}
            headerText="Business Profile" titlePreset="headerTitle"
            rightIcon={'times'} rightIconPreset={'smallBlue'} rightIconTheme={'regular'}
            onRightPress={() => navigation.goBack()}
          />
          <Screen preset="scroll" keyboardOffset={'primary'} unsafe={true}>
            <View style={BODY}>
              <View style={{alignItems: 'center', marginBottom: 20}}>
                <View>
                  {logo.url ?
                      <AvatarImage
                      onPress={handleLogoPress}
                      preset={'lgRound'}
                      source={{uri: logo.url}}/> :
                      <AvatarDefault onPress={handleLogoPress} name={app.userAccount.businessProfile?.name} preset={"lgRound"}/>}

                  <View style={{position: 'absolute', right: 4, top: 4, display: logo?.id ? 'flex' : 'none'}}>
                    <SvgIcon onPress={handleLogoPress} preset={'dark'} icon={'times'} theme={'regular'}/>
                  </View>
                  <View style={{position: 'absolute', right: 4, bottom: 4}}>
                    <SvgIcon onPress={handleChangeLogo} preset={'dark'} icon={'camera'} theme={'solid'}/>
                  </View>
                </View>
              </View>
              <TextField
                value={website}
                onChangeText={setWebsite}
                preset={'light'}
                style={{marginBottom: 20}}
                placeholder="Business website"
                label="Business Website"
                labelPreset="primary"
              />

              <TextField
                  value={email}
                  onChangeText={setEmail}
                  preset={'light'}
                  style={{marginBottom: 20}}
                  placeholder="Business email"
                  label="Business email"
                  labelPreset="primary"
              />

              <CustomRichEditor
                value={outline}
                label="Business Outline"
                containerStyle={{minHeight: 100}}
                onChange={setOutline}
              />
              <View style={{height: 24}}/>

              <View style={{marginBottom: 50}}>
                <Text preset="primary" style={{marginBottom: 4}}>
                  Social Media Accounts
                </Text>
                <View style={SOCIAL_CONTAINER}>
                  <View style={{marginTop: 6}}>
                    <AvatarImage source={facebookLogo} preset={'socialIcon'} px={0} py={0}/>
                  </View>
                  <TextField
                    value={fbLink}
                    onChangeText={setFbLink}
                    placeholder="facebook.com/sitename"
                    preset="grow"
                  />
                </View>
                <View style={SOCIAL_CONTAINER}>
                  <View style={{marginTop: 6}}>
                    <AvatarImage source={instagramLogo} preset={'socialIcon'} px={0} py={0}/>
                  </View>
                  <TextField
                    value={instaLink}
                    onChangeText={setInstaLink}
                    placeholder="instagram.com/sitename"
                    preset="grow"
                  />
                </View>
                <View style={SOCIAL_CONTAINER}>
                  <View style={{marginTop: 6}}>
                    <AvatarImage source={twitterLogo} preset={'socialIcon'} px={0} py={0}/>
                  </View>
                  <TextField
                    value={twLink}
                    onChangeText={setTwLink}
                    placeholder="twitter.com/sitename"
                    preset="grow"
                  />
                </View>
              </View>

              <View style={BUTTON_CONTAINER}>
                <View style={{flex: 1}}>
                  {skip && (
                    <Button disabled={loading} onPress={goHome} text="Skip" preset="brandOutLine"/>
                  )}
                </View>
                <View style={{width: 20}}/>
                <View style={{flex: 1}}>
                  <Button disabled={loading} onPress={handleSave} text="Save" preset="brand"/>
                </View>
              </View>
            </View>
          </Screen>
        </SafeAreaView>
      </PureBottomModal>
      <LoadingModal visible={loading}/>
      <AlertModal/>
    </>
  )
});

export const BODY: ViewStyle = {
  paddingHorizontal: isAndroid ? 20 : 26,
  marginBottom: 20,
}

export const BUTTON_CONTAINER: ViewStyle = {
  marginVertical: 8,
  justifyContent: 'space-between',
  flexDirection: 'row',
}

export const SOCIAL_CONTAINER: ViewStyle = {
  flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginVertical: 4
}
