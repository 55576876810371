import React, {FC, useCallback, useEffect, useState} from "react"
import {
  View,
  SafeAreaView, ScrollView, RefreshControl
} from "react-native"
import {observer} from "mobx-react-lite"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Header, GradientBackground, Text, TextField, Button} from "../../../../components"
import {color} from "../../../../theme"
import {SelectTextField} from "../../../../components/text-field/select-text-field";
import {IKvItemCheckModel, IKvItemModel, KvItemPath} from "../../../../models/app/kv-item";
import {useStores} from "../../../../models";
import {JobTags} from "../../../../models/app/job-advert";
import {searchCompare, searchIgnoreCase} from "../../../../utils/utils";
import {AlertModalStore} from "../../../../models/app/alert-modal-store";
import {PureBottomModal} from "../../../../components/backdrop-modal/pure-bottom-modal";
import {isAndroid} from "../../../../utils/platform";
import {debounce} from "../../../../utils/debounce";
import {BackdropModal} from "../../../../components/backdrop-modal/backdrop-modal";
import {SvgIcon} from "../../../../components/icon/svg-icon";
import {constants} from "../../../../utils/constants";
import {NavigatorParamList} from "../../../../navigators";
import {PickerSelectField} from "../../../../components/picker-select/picker-select";
import {getUserQualificationStr} from "../../../../models/app/advanced-skill";

export interface JobTagQualificationsViewProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
}

export const JobTagQualificationsView: FC<JobTagQualificationsViewProps> = observer(
  ({navigation}) => {
    const {app} = useStores();
    const {filterJob} = app
    const isManager = app?.user?.role === constants.roles.MANAGER
    const businessProfileId = isManager ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState("")
    const [data, setData] = useState<Array<IKvItemCheckModel>>([]);
    const [customIds, setCustomIds] = useState<Array<number>>([]);
    const [selectedItems, setSelectedItems] = useState<Array<IKvItemCheckModel>>([]);
    const [visibleModal, setVisibleModal] = useState(false)
    const [courseName, setCourseName] = useState('')
    const [qualificationEducationLevel, setEducationLevel] = useState(0)
    const [educationOpen, setEducationOpen] = useState(false)
    const [listEducations, setListEducations] = useState<Array<IKvItemModel>>([]);

    const initData = async () => {
      const categoryRes = await app.environment.api.getKvItemList('qualification-education-levels')
      if (categoryRes.kind !== 'ok') {
        AlertModalStore.alert('Error', categoryRes.message)
        return
      }
      setListEducations(categoryRes.items)
    }
    const loadData = async (text?: string) => {
      if (!text || text?.length < 3) return
      try {
        text = text?.trim();
        const userRes = await app.environment.api.getUserQualification({
          ...(text ? {'filters[courseName][$containsi]': text} : null),
          'filters[businessProfile][id]': businessProfileId,
          businessProfileId: businessProfileId,
        })
        if (userRes.kind !== 'ok') return
        setCustomIds(userRes?.items?.map(it => it.id))
        const sysRes = await app.environment.api.getUserQualification({
          ...(text ? {'filters[courseName][$containsi]': text} : null),
        })
        if (sysRes.kind === 'ok') {
          setData(
            [...userRes?.items, ...sysRes?.items].map(item => ({
              id: item.id, name: getUserQualificationStr(item),
              checked: false
            }))
          )
        }

      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }
    useEffect(() => {
      initData()
    }, [])

    const toggleSelectItem = (id) => {
      let _item = null
      const newData = data.map((item) => {
        if (item.id === id) _item = item
        return ({
          ...item, checked: item.id === id ? !item.checked : item.checked
        })
      })
      setData(newData)
      if (selectedItems.find(it => it?.id === id)) setSelectedItems(selectedItems.filter(it => it?.id !== id))
      else setSelectedItems([...selectedItems, _item])
    }

    const onDone = () => {
      filterJob.setSelectedQualifications(selectedItems)
      navigation.goBack()
    }

    const handleAddItem = async () => {
      if (!courseName || loading) return
      setLoading(true)
      try {
        const res = await app.environment.api.editKvItemBusiness(KvItemPath.qualification, {
          courseName,
          qualificationEducationLevel,
          businessProfile: isManager ? app?.user?.businessProfile : app?.userAccount?.businessProfile,
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }

        setVisibleModal(false)
        setSearch('')
        await loadData(courseName)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    const delayLoadData = useCallback(debounce(loadData, 600), []);
    const handleChangeSearch = (text: string) => {
        setSearch(text)
        setLoading(true);
        // @ts-ignore
      delayLoadData(text)
    }
    const loadDataFromState = () => {
      loadData(search)
    }

    const handleAddOther = () => {
      setCourseName(search)
      setVisibleModal(true)
    }

    const _systemData = data.filter(item => !customIds.includes(item.id) && searchIgnoreCase(item?.name, search))
    const _customData = data.filter(item => customIds.includes(item.id) && searchIgnoreCase(item?.name, search))
    const showBtnDone = selectedItems?.length > 0
    const showBtnAdd = !loading && !!search && data.find( item => searchCompare(item?.name, search)) === undefined;

    return (
      <PureBottomModal onClose={() => navigation.goBack()}>
        <SafeAreaView style={{flex: 1}}>
          <GradientBackground
            colors={[color.palette.gradientStart, color.palette.gradientStop]}
          />
          <Header
            preset={"modalHeader"}
            titlePreset={"headerTitle"}
            headerText={JobTags.qualifications.text}
            rightIcon={"times"}
            rightIconPreset={"smallBlue"}
            onRightPress={() => navigation.goBack()}
          />
          <View style={{paddingHorizontal: 20, flex: 1}}>
            <View style={{flex: 1}}>
              <View style={{}}>
                <Text preset={"label"}>{JobTags.qualifications.label}</Text>
                <View style={{marginTop: 24, marginBottom: 8}}>
                  <TextField
                    value={search} onChangeText={handleChangeSearch}
                    labelPreset={"default"}
                    rightIcon={"times-circle-fill"}
                    rightIconPreset={"default"}
                    rightIconPress={() => handleChangeSearch('')}
                  />
                </View>
                {showBtnAdd && (
                  <Button preset={"brandDisabled"} onPress={handleAddOther}>
                    <Text preset={"mediumBoldBlue"}>Add other</Text>
                  </Button>
                )}
              </View>
              {data.length > 0 ? (
                <View style={{marginTop: 24, flex: 1}}>
                  <ScrollView
                    refreshControl={<RefreshControl refreshing={loading} onRefresh={loadDataFromState}/>}
                    showsVerticalScrollIndicator={false} style={{flex: 1}}>
                    <View>
                      {_customData && _customData.length > 0 ? (
                        <View>
                          <View style={{marginHorizontal: 16}}>
                            <Text preset={"mediumBlue"}>Other</Text>
                          </View>
                          <View style={{height: 10}}/>
                          {_customData.map((item, index) => (
                            <SelectTextField
                              key={`${index}-${item.id}`}
                              value={item.name} onPress={() => toggleSelectItem(item.id)}
                              style={{marginBottom: 10}}
                              preset={item.checked ? 'customItemSelected' : 'transparent'}
                            />
                          ))}
                        </View>
                      ) : null}
                      {data ? (
                        <View>
                          <View style={{marginHorizontal: 16, marginBottom: 10}}>
                            <Text preset={"mediumBlue"}>Results</Text>
                          </View>
                          {_systemData.map((item, index) => (
                            <SelectTextField
                              key={`${index}-${item.id}`}
                              value={item.name} onPress={() => toggleSelectItem(item.id)}
                              style={{marginBottom: 10}}
                              preset={item.checked ? 'itemSelected' : 'transparent'}
                            />
                          ))}
                        </View>
                      ) : null}
                    </View>
                  </ScrollView>
                </View>
              ) : null}
            </View>
            <View style={{display: showBtnDone ? 'flex' : 'none', paddingTop: 10, marginBottom: isAndroid ? 10 : 5}}>
              <Button preset={"brand"} onPress={onDone} text={'Done'}/>
            </View>
          </View>
          <BackdropModal preset={'zeroPad'} visible={visibleModal} onClose={() => setVisibleModal(false)}>
            <View style={{paddingTop: 20, paddingBottom: 40}}>
              <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <Text preset={"h3"} text={'Add Other Qualification'} color={color.palette.orange}/>
                <SvgIcon onPress={() => setVisibleModal(false)} icon={'times'} preset={'orange'}/>
              </View>
              <View style={{paddingVertical: 30, zIndex: 9}}>
                <View style={{marginBottom: 20, zIndex: 9}}>
                  <PickerSelectField
                    onOpen={() => setEducationOpen(true)}
                    onClose={() => setEducationOpen(false)}
                    onValueChange={setEducationLevel}
                    items={listEducations?.map(item => ({
                      key: item.id, value: item.id,
                      label: item?.name
                    }))}
                    preset={"default"}
                    label={"Education Level"} labelPreset={"h4"}
                    placeholder="Select"
                    rightIcon={educationOpen ? 'chevron-down' : 'chevron-right'}
                  />
                </View>
                <TextField
                  value={courseName} onChangeText={setCourseName}
                  preset={'primary'} labelPreset={"h4"} label={'Course Name'}/>
              </View>
              <Button text="Submit" onPress={handleAddItem}/>
            </View>
          </BackdropModal>
        </SafeAreaView>
      </PureBottomModal>
    )
  },
)
