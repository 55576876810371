import {Instance, types} from "mobx-state-tree"
import {listToStr} from "../../utils/utils";

export const JobLocationModel = types.model({
  id: types.optional(types.number, 0),
  suburb: types.optional(types.string, ''),
  postcode: types.optional(types.string, ''),
  state: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
})
export type IJobLocationModel = Instance<typeof JobLocationModel>
export const convertJobLocationModel = (raw): IJobLocationModel => ({
  id: raw?.id || 0,
  suburb: raw?.suburb || '',
  postcode: raw?.postcode || '',
  state: raw?.state || '',
  name: parseLocation(raw) || '',
})

export function parseLocation(item: IJobLocationModel) {
  if (!item) return ''
  return listToStr([item?.suburb, item?.state, item?.postcode]?.filter(it => !!it?.trim()), ' ')
}
