import React, {FC, useEffect, useState} from "react"
import {SafeAreaView, ScrollView, View, ViewStyle} from "react-native"
import {observer} from "mobx-react-lite"
import {ParamListBase} from "@react-navigation/native"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Button, Header, Text} from "../../../components"
import {useStores} from "../../../models";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import {SelectTextField} from "../../../components/text-field/select-text-field";
import {BackdropModal} from "../../../components/backdrop-modal/backdrop-modal";
import {SvgIcon} from "../../../components/icon/svg-icon";
import {CreateJobTags} from "../../../models/app/job-advert";
import {JobTagRow} from "../../../components/tag/job-tag-row";
import {AdvertRoleStore} from "../../../models/app/advert-role";

export interface PreferencesProps {
  navigation: NativeStackNavigationProp<ParamListBase>
}

export const PreferencesScreen: FC<PreferencesProps> = observer(({navigation}) => {
  const {app} = useStores();
  const [loading, setLoading] = useState(true)
  const [showDoneModal, setShowDoneModal] = useState(false)

  const preferredLocations = app.userAccount.workerProfile?.preferredLocations;

  useEffect(() => {
    (async () => {
      setLoading(true)
      try {
        const getMeRes = await app.environment.api.getMe()
        if (getMeRes.kind !== 'ok') {
          AlertModalStore.alert('Error', getMeRes.message)
          return
        }

      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const handleSave = async (): Promise<boolean> => {
    try {
      setLoading(true)
      const workerProfile = app?.userAccount?.workerProfile
      const updateRes = await app.environment.api.updateWorkerProfileData(workerProfile.id, {
        preferredLocations: preferredLocations?.map(it => it.id),
      })
      if (updateRes.kind !== 'ok') {
        AlertModalStore.alert('Error', updateRes.message)
        return false
      }
      setShowDoneModal(true)
      return true
    } catch (e) {
      console.warn(e)
    } finally {
      setLoading(false)
    }
    return false
  }

  return (
    <>
      <PureBottomModal onClose={() => navigation.goBack()}>
        <SafeAreaView style={CONTAINER}>
          <Header
            headerText="Preferences" titlePreset="headerTitle"
            preset="modalHeader"
            rightIcon={"times"} rightIconPreset={"smallBlue"}
            onRightPress={() => navigation.goBack()}
          />
          <ScrollView style={{flex: 1,}} showsVerticalScrollIndicator={false}>
            <View style={{marginBottom: 100, marginHorizontal: 20}}>
              <View style={{marginBottom: 20}}>
                <SelectTextField
                  preset={'primary'} numberOfLines={1}
                  placeholder="Location"
                  label={'Preferred Location(s)'} labelPreset={'primary'}
                  onPress={() => navigation.navigate('workerPreferredLocationScreen')}
                />
                <View style={{marginTop: 4}}>
                  {app.userAccount?.workerProfile?.preferredLocations?.map((item) => (
                    <JobTagRow
                      key={String(item?.id)}
                      text={item.name} tagStyle={{marginBottom: 0}}
                      onPress={() => AdvertRoleStore.toggleSelectRoleIndex(CreateJobTags.qualifications.id, item.id)}/>
                  ))}
                </View>
              </View>
              <Button onPress={handleSave} text={"Save"} preset={'brand'} disabled={loading}/>
            </View>
          </ScrollView>
          <BackdropModal visible={showDoneModal} onClose={() => setShowDoneModal(false)}>
            <View>
              <View style={{alignItems: "center", justifyContent: 'center', padding: 10}}>
                <SvgIcon icon={'check-circle'} preset={'alertIcon'}/>
                <View style={{paddingTop: 18, paddingBottom: 8}}>
                  <Text preset={'alertTitle'}>Updated</Text>
                </View>
              </View>
            </View>
          </BackdropModal>
        </SafeAreaView>
      </PureBottomModal>
      <AlertModal/>
    </>
  )
})

export const CONTAINER: ViewStyle = {
  flex: 1,
}

export const INPUT: ViewStyle = {
  marginVertical: 20,
}
