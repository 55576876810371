import * as React from "react"
import {
  View,
  SafeAreaView,
  FlatList,
  TouchableOpacity,
  ScrollView,
  RefreshControl,
  StyleSheet,
  ViewStyle
} from "react-native"
import {FC, useCallback, useEffect, useState} from "react"
import {ParamListBase} from "@react-navigation/native"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Text, GradientBackground, IconButton} from "../../../components"
import {SvgIcon} from "../../../components/icon/svg-icon"
import {color} from "../../../theme"
import {SearchBar} from "../../../components/search-bar/search-bar"
import {useStores} from "../../../models";
import {Observer} from "mobx-react-lite";
import {IJobRoleAdModel} from "../../../models/app/job-role-ad";
import {AwBannerCard} from "../../../components/card/aw-banner-card";
import {JobRoleCard} from "../../../components/card/job-card";
import {SearchBarSuggest} from "../../../components/search-bar/search-bar-suggest";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {convertKvItemModel, IKvItemModel, KvItemPath} from "../../../models/app/kv-item";
import {debounce} from "../../../utils/debounce";
import {FilterJobType} from "../../../models/app/filter-job";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {isAndroid} from "../../../utils/platform";
import {BREAKPOINT_MOBILE, height, width} from "../../../utils/global";
import {convertJobAdvertModel} from "../../../models/app/job-advert";
import {AdvertRoleStore} from "../../../models/app/advert-role";

const bannerImage = require("../../../../assets/images/banner-avatar-worker.png")

export interface HomeScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
}

export const PublicHomeScreen: FC<HomeScreenProps> = ({navigation}) => {
  const {app} = useStores()
  const insets = useSafeAreaInsets()
  const [jobRoles, setJobRoles] = useState<Array<IJobRoleAdModel>>([])
  const [refreshing, setRefreshing] = useState(false)
  const [focusSearch, setFocusSearch] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [jobRoleSuggest, setJobRoleSuggest] = useState<Array<IKvItemModel>>([])
  const [filterData, setFilterData] = useState<FilterJobType>()
  const isDesktop =  width > BREAKPOINT_MOBILE;


  let _mount: boolean = true

  const loadData = async () => {
    if (!_mount) return
    if (app?.hasFilterJob) {
      setFilterData({
        locations: app?.filterJob?.locations?.map(convertKvItemModel),
        industries: app?.filterJob?.industries?.map(convertKvItemModel),
        roles: app?.filterJob?.roles?.map(convertKvItemModel),
        experience: app?.filterJob?.experience?.map(convertKvItemModel),
        skills: app?.filterJob?.skills?.map(convertKvItemModel),
        qualifications: app?.filterJob?.qualifications?.map(convertKvItemModel),
      })
      await app.setHasFilterJob(false)
    } else {
      setFilterData({
        locations: [],
        industries: [],
        roles: [],
        experience: [],
        skills: [],
        qualifications: [],
      })
    }
    await app.filterJob.reset()
    if (refreshing) return
    setRefreshing(true)
    try {
      const res = await app.environment.api.getJobRoleAds({
        "filters[jobAd][isDraft][$eq]": false,
        "filters[jobAd][closed][$eq]": false,
      })
      if (res.kind === 'ok') {
        setJobRoles(res.jobRoles)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setRefreshing(false)
    }
  }

  useEffect(() => {
    loadData()
    _mount = true
    return () => {
      _mount = false
    }
  }, [])

  useEffect(() => {
    window.addEventListener(
        "requestSignIn",
        function () {
          navigation.navigate('signInEmailCheckScreen')
        },
        false
    );
  }, []);

  const renderJobList = () => {
    return (
      <FlatList
        horizontal={!isDesktop}
        data={jobRoles}
        keyExtractor={(item, index) => String(item.id + '' + index)}
        renderItem={renderItem}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={isDesktop && styles.listDk}
        style={isDesktop && {height: height - 100}}
      />
    )
  }

  const renderItem = ({item}: { item: IJobRoleAdModel }) => {
    return (
      <JobRoleCard
        onLogoPress={(id) => navigation.navigate('businessProfileScreen', {businessProfileId: id})}
        onPress={() => navigation.navigate('jobsAdDetailScreen', {id: item.id})} item={item}/>
    )
  }

  const handleSearchSuggest = async (text: string) => {
    try {
      const res = await app.environment.api.getKvItemList(KvItemPath.role, {
        customFilter: true,
        ...(text ? {'filters[name][$containsi]': text} : null)
      })
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message)
        return
      }
      const _jobRoleSuggest: Array<IKvItemModel> = []
      res?.items?.forEach(it => {
        if (!it?.name?.toLowerCase().includes(text?.toLowerCase())) return
        if (_jobRoleSuggest.find(jit => jit.name?.trim() === it.name?.trim())) return
        _jobRoleSuggest.push({...it, name: it.name?.trim()})
      })
      setJobRoleSuggest(_jobRoleSuggest)
    } catch (e) {
      console.log(e)
    }
  }
  const delaySearchSuggest = useCallback(debounce(handleSearchSuggest, 800), []);

  const handleSearchText = (text) => {
    setFocusSearch(!!text)
    setSearchText(text)
    // @ts-ignore
    if (text) delaySearchSuggest(text)
  }

  const handleFilterPress = async () => {
    await app.filterJob.setFilterJobData(filterData)
    await app.setHasFilterJob(true)
    navigation.navigate('jobFilterScreen', {nextScreen: 'jobListScreen', replace: true})
  }

  const handleCreateJobAd = () => {
    app.setJobAdvertCreate(convertJobAdvertModel({}))
    AdvertRoleStore.reset()
    app.setSelectedContact(null)
    navigation.navigate("publicJobAdvertEditScreen")
  }

  const renderDesktop = () => {
    return(
        <View style={styles.wrapperDk}>
          <View style={styles.contentDk}>
            <View style={{paddingRight: 20}}>
              <View style={styles.topContentDk}>
                <Text
                  text="Job Adverts"
                  preset={'h2'}
                  style={{color: color.palette.dark}}/>
                <View style={{alignItems: "flex-end", flex: 1,}}>
                  <View style={{flexDirection: 'row'}}>
                    <IconButton
                      onPress={() => navigation.navigate('jobListScreen')}
                      text="See All" textPreset={'bodyGrey'}
                      iconProps={{icon: 'triangle-right', preset: 'triangleIcon'}}
                    />
                  </View>
                </View>
              </View>
              <Observer>{renderJobList}</Observer>
            </View>
          </View>
          <AwBannerCard
            isDesktop={isDesktop}
            title={'Post a free job ad today!'}
            buttonText={'Post Job'} buttonPreset={'brand'}
            onPress={handleCreateJobAd}
            image={bannerImage}
          />
        </View>
    )
  }

  const renderMobile = () => {
    return(
        <ScrollView
            showsHorizontalScrollIndicator={false}
            style={{flex: 1}}
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={loadData}/>}
        >
          <View style={{marginTop: 25, marginHorizontal: 20, flexDirection: "row",}}>
            <Text
                text="Job Adverts"
                preset={'h2'}
                style={{color: color.palette.dark}}/>
            <View style={{alignItems: "flex-end", flex: 1,}}>
              <View style={{flexDirection: 'row'}}>
                <IconButton
                    onPress={() => navigation.navigate('jobListScreen')}
                    text="See All" textPreset={'bodyGrey'}
                    iconProps={{icon: 'triangle-right', preset: 'triangleIcon'}}
                />
              </View>
            </View>
          </View>
          <View style={{marginLeft: 10, marginTop: 12}}>
            <Observer>{renderJobList}</Observer>
          </View>
          <View style={{marginBottom: 20, marginTop: 18}}>
            <View style={{paddingHorizontal: 20}}>
              <Text preset={'h2'} style={{fontWeight: '600'}}>Looking for Workers?</Text>
            </View>
            <View style={{marginHorizontal: 20, marginTop: 16}}>
              <AwBannerCard
                  title={'Post a free job ad today!'}
                  buttonText={'Post Job'} buttonPreset={'brand'}
                  onPress={() => navigation.navigate('signInEmailCheckScreen')}
                  image={bannerImage}
              />
            </View>
          </View>
        </ScrollView>
    )
  }

  return (
    <SafeAreaView style={[styles.container, isDesktop && styles.containerDK]}>
      <GradientBackground colors={[color.palette.gradientStart, color.palette.gradientStop]}/>
      <View style={focusSearch ? styles.focusHeaderArea : styles.headerArea}>
        <View style={focusSearch ? {flex: 1, marginTop: insets.top} : {}}>
          <View style={isDesktop && styles.fillerStyle}>
            <div id="mobile-header">
              <View style={HEADER_STYLE}>
                <TouchableOpacity style={{flex: 1, flexDirection: "row", alignItems: "center",}}>
                  <Text text={"Tap4Work"} preset={'h1'} style={{color: color.palette.blue, fontWeight: '600'}}/>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{alignItems: "flex-end"}}
                  onPress={() => navigation.navigate('signInEmailCheckScreen')}>
                  <View style={{backgroundColor: color.palette.greyLight, borderRadius: 8,}}>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        marginVertical: 10,
                        marginHorizontal: 10,
                      }}
                    >
                      <Text text="Sign in"/>
                      <View style={{width: 10}}/>
                      <SvgIcon icon="sign-in" preset={"small"}/>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>
            </div>
            <SearchBar
              value={searchText}
              onChangeText={handleSearchText}
              // onBlur={() => setFocusSearch(false)}
              placeholder={'Search job adverts...'}
              // onTouchStart={() => navigation.navigate("searchScreen")}
              onFilterPress={handleFilterPress}
            />
          </View>
          {focusSearch && <SearchBarSuggest
            onItemPress={(item) => {
              setSearchText('')
              app.setHasSearchJobRole(true)
              app.setFilterJobRoleText(item?.name)
              navigation.navigate('jobListScreen')
            }}
            data={jobRoleSuggest}
            searchText={searchText}/>}
        </View>
      </View>
      {isDesktop ? renderDesktop() : renderMobile()}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  focusHeaderArea: {
    backgroundColor: color.palette.white,
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 99,
  },
  headerArea: {
  },
  wrapperDk:{
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  contentDk:{
    flexDirection: 'column',
    flex: 1,
    paddingLeft: 20,
    paddingRight: 0,
  },
  topContentDk:{
    marginBottom: 20,
    flexDirection: 'row'
  },
  listDk:{
    paddingBottom: 300,
  },
  fillerStyle:{
    borderBottomColor: '#F6F6FC',
    borderBottomWidth: 1,
    marginBottom: 20,
  },
  container:{
    flex: 1,
  },
  containerDK:{
    borderRightWidth: 1,
    borderRightColor: '#F6F6FC',
  },
})
const HEADER_STYLE: ViewStyle = {
  marginHorizontal: 20,
  flexDirection: "row",
  alignItems: "center",
  marginTop: 20,
  marginBottom: isAndroid ? 15 : 30,
}
