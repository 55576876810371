import { Instance, types } from "mobx-state-tree"

export const FileUploadModel = types.model({
  id: types.optional(types.number, 0),
  name: types.optional(types.string, ''),
  alternativeText: types.optional(types.string, ''),
  caption: types.optional(types.string, ''),
  width: types.optional(types.number, 0),
  height: types.optional(types.number, 0),
  url: types.optional(types.string, ''),
  previewUrl: types.optional(types.string, ''),
  provider: types.optional(types.string, ''),
})
export type IFileUploadModel = Instance<typeof FileUploadModel>
export const convertFileUploadModel = (raw): IFileUploadModel => ({
  id: raw?.id || 0,
  name: raw?.name || '',
  alternativeText: raw?.alternativeText || '',
  caption: raw?.caption || '',
  width: raw?.width || 0,
  height: raw?.height || 0,
  url: raw?.url || '',
  previewUrl: raw?.previewUrl || '',
  provider: raw?.provider || '',
})

export const FileLocalModel = types.model({
  id: types.optional(types.number, 0),
  name: types.optional(types.string, ''),
  uri: types.optional(types.string, ''),
  type: types.optional(types.string, ''),
})
export type IFileLocalModel = Instance<typeof FileLocalModel>
export const convertFileLocalModel = (raw): IFileLocalModel => ({
  id: raw?.id || 0,
  name: raw?.name || '',
  uri: raw?.uri || '',
  type: raw?.type || '',
})

export const PICKER_TYPE = {
  filePicker: 1,
  imagePicker: 2,
  camera: 3,
}