import {Instance, types} from "mobx-state-tree"
import {convertFileUploadModel, FileUploadModel} from "./file-upload";
import {convertUserAccountModel, UserAccountModel} from "./user-account";

export const BusinessProfileUserModel = types.model({
  city: types.optional(types.string, ''),
  countryCode: types.optional(types.string, ''),
  createdAt: types.optional(types.string, ''),
  // createdBy: types.optional(types.string, ''),
  fbLink: types.optional(types.string, ''),
  id: types.optional(types.number, 0),
  instaLink: types.optional(types.string, ''),
  // jobs: types.optional(types.string, ''),
  logo: types.optional(FileUploadModel, {}),
  name: types.optional(types.string, ''),
  outline: types.optional(types.string, ''),
  postcode: types.optional(types.string, ''),
  publishedAt: types.optional(types.string, ''),
  telephone: types.optional(types.string, ''),
  twLink: types.optional(types.string, ''),
  updatedAt: types.optional(types.string, ''),
  // updatedBy: types.optional(types.string, ''),
  website: types.optional(types.string, ''),
  email: types.optional(types.string, ''),
  user: types.optional(UserAccountModel, {}),
  showWorkerTab: types.optional(types.boolean, false),
})
export type IBusinessProfileUserModel = Instance<typeof BusinessProfileUserModel>
export const convertBusinessProfileUserModel = (raw): IBusinessProfileUserModel => ({
  city: raw?.city || '',
  countryCode: raw?.countryCode || '',
  createdAt: raw?.createdAt || '',
  // createdBy:  '',
  fbLink: raw?.fbLink || '',
  id: raw?.id || 0,
  instaLink: raw?.instaLink || '',
  // jobs:  '',
  logo: convertFileUploadModel(raw?.logo?.data?.attributes ? {
    ...raw?.logo?.data?.attributes, id: raw?.logo?.data?.id
  } : raw?.logo?.attributes ? {...raw?.logo?.attributes, id: raw?.logo?.id} : raw?.logo),
  name: raw?.name || '',
  outline: raw?.outline || '',
  postcode: raw?.postcode || '',
  publishedAt: raw?.publishedAt || '',
  telephone: raw?.telephone || '',
  twLink: raw?.twLink || '',
  updatedAt: raw?.updatedAt || '',
  // updatedBy:  '',
  website: raw?.website || '',
  email: raw?.email || '',
  user: convertUserAccountModel(raw?.user?.data?.attributes ? {
    ...raw?.user?.data?.attributes, id: raw?.user?.data?.id
  } : raw?.user?.attributes ? {...raw?.user?.attributes, id: raw?.user?.id} : raw?.user),
  showWorkerTab: raw?.showWorkerTab || false,
})
