import * as React from "react"
import {StyleSheet, TouchableOpacity, View} from "react-native";
import {color} from "../../theme";
import {SvgIconPresets, ThemeTypes} from "../icon/svg-icon.props";
import {SelectTextField, SelectTextFieldPresets} from "../text-field/select-text-field";
import {ReactNode} from "react";

const BASE = {
  borderRadius: 8, backgroundColor: color.palette.greyLight
}
export const ListDivPresets = {
  default: {...BASE},
  lightLink: {
  },
  itemHeader: {
    ...BASE,
    paddingVertical: 4
  },
  chatSuggest: {
    borderRadius: 8, backgroundColor: color.palette.bgBlue
  }
}

export type ListDivPresetsType = keyof typeof ListDivPresets

export const ListDivItemPresets = {
  default: {},
  lightLink: {
  },
  itemHeader: {
    paddingLeft: 16
  },
  chatSuggest: {
  }
}

export type ListDivItemPresetsType = keyof typeof ListDivItemPresets

export interface DivItemProps {
  icon: string
  theme: ThemeTypes
  preset: SvgIconPresets
}

export interface ListDivProps {
  header?: ReactNode;
  preset?: ListDivPresetsType;
  selectPreset?: SelectTextFieldPresets
  onPress?: (index) => void
  itemValue: (index) => string
  iconPress?: (index) => void
  renderIcon?: (index) => DivItemProps
  data: Array<any>;
}

export const ListDiv = (props: ListDivProps) => {
  const {header, preset, selectPreset, onPress, data, itemValue, iconPress, renderIcon} = props
  const renderIconItem = (index) => {
    let rightIconProps = {}
    if (renderIcon) {
      const iconProps = renderIcon(index);
      rightIconProps = {
        rightIcon: iconProps.icon,
        rightIconPreset: iconProps.preset,
        rightIconTheme: iconProps.theme,
        rightIconPress: iconPress ? (e) => iconPress(index) : null,
      }
    }
    return (
      <TouchableOpacity
        key={index}
        style={ListDivItemPresets[preset] || ListDivItemPresets.default}
      >
        <SelectTextField
          numberOfLines={1}
          preset={selectPreset}
          value={itemValue(index)}
          {...(onPress ? {onPress: () => onPress(index)} : {})}
          {...rightIconProps}
        />
        {index < data.length - 1 && (
          <View style={styles.div}/>
        )}
      </TouchableOpacity>
    )
  }
  return (
    <View style={ListDivPresets[preset] || ListDivPresets.default}>
      {header && (
        <View>
          {header}
          {data.length > 0 && (
            <View style={styles.div}/>
          )}
        </View>
      )}
      {data.map((item, index) => renderIconItem(index))}
    </View>
  )
}

const styles = StyleSheet.create({
  div: {height: 1, marginHorizontal: 16, backgroundColor: color.palette.white}
})
