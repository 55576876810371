import React, {FC, ReactNode, useEffect} from "react";
import './styles.css'
import {color} from "../../theme";
import {Text} from "../text/text";
import {SvgIcon} from "../icon/svg-icon";
import {TouchableOpacity, View, ViewStyle} from "react-native";
import {useStores} from "../../models";
import {constants} from "../../utils/constants";
import {observer} from "mobx-react-lite";
import {navigate} from "../../navigators";
import {listToStr} from "../../utils/utils";
import {AvatarImage} from "../avatar-image/avatar-image";
import AvatarDefault from "../avatar-image/AvatarDefault";

const Logo = require("../../../assets/images/icons/logo-emails.png");
const userAvatar = require("../../../assets/images/default-photo.png");

export interface HeaderDkProps {
}

export const HeaderDk: FC<HeaderDkProps> = observer(() => {
  const {app} = useStores()
  const userAccount = app?.userAccount
  const isManager = app?.user?.role === constants.roles.MANAGER
  const businessProfile = isManager ? app?.user?.businessProfile : userAccount?.businessProfile

    useEffect(() => {
        let child = document.getElementById('headerDK')
        if(child){
            let parent = child.parentNode as HTMLElement
            parent.classList.add('flexDirection')
        }
    }, []);

  const handleSignIn = () => {
    navigate('signInEmailCheckScreen')
  }
  const onProfilePress = () => {
    navigate("chooseAccessScreen")
  }

  const renderUserSection = () => {
    return (
      <View style={HEADER_STYLE}>
        <TouchableOpacity style={{flexDirection: "row", alignItems: "center"}} onPress={onProfilePress}>
          <Text text={`Hi, ${userAccount?.firstName}`}
                preset={'h1'} style={{color: color.palette.primary}}/>
        </TouchableOpacity>
        <View style={{flex: 1, alignItems: "flex-end",}}>
          <AvatarImage
            onPress={() => navigate('settingScreen')}
            source={userAvatar} preset={"round"}/>
        </View>
      </View>
    )
  }
  const renderWorkerSection = () => {
    return (
      <View style={HEADER_STYLE}>
        <TouchableOpacity
          style={{flexDirection: "row", alignItems: "center",}}
          onPress={onProfilePress}>
          <View>
            <Text
              text={listToStr([userAccount?.firstName, userAccount?.lastName])}
              preset={'h1'} style={{color: color.palette.primary}}/>
            <View style={{flexDirection: 'row', marginTop: 2, alignItems: 'center'}}>
              <Text text={'Worker Profile'} preset={'body'} style={{color: color.palette.primary}}/>
              <SvgIcon icon={"chevron-down"} preset={"tiny"} style={{marginLeft: 3}}/>
            </View>
          </View>
        </TouchableOpacity>
        <View style={{flex: 1, alignItems: "flex-end",}}>
          <AvatarImage
            onPress={() => navigate("settingScreen")}
            source={userAccount?.avatar?.url ? {uri: userAccount?.avatar?.url} : userAvatar}
            preset={"round"}
          />
        </View>
      </View>

    )
  }
  const renderBusinessUserSection = () => {
    return (
      <View style={HEADER_STYLE}>
        <TouchableOpacity
          style={{flexDirection: "row", alignItems: "center",}}
          onPress={onProfilePress}>
          <View>
            <Text
              text={listToStr([userAccount?.firstName, userAccount?.lastName])}
              preset={'h1'} style={{color: color.palette.primary, fontWeight: '600'}}/>
            <View style={{flexDirection: 'row', marginTop: 2, alignItems: 'center'}}>
              <Text text={businessProfile?.name} preset={'body'} style={{color: color.palette.primary}}/>
              <SvgIcon icon={"chevron-down"} preset={"tiny"} style={{marginLeft: 3}}/>
            </View>
          </View>
        </TouchableOpacity>
        <View style={{flex: 1, alignItems: "flex-end",}}>
            {businessProfile?.logo?.url ?
                <AvatarImage
                onPress={() => navigate('settingScreen')}
                source={{uri: businessProfile?.logo?.url}}
                preset={"round"}/>:
                <AvatarDefault onPress={() => navigate('settingScreen')} name={businessProfile?.name} preset={"round"}/>}
        </View>
      </View>
    )
  }
  const renderPublicSection = () => {
    return (
      <TouchableOpacity
        style={{alignItems: "flex-end"}}
        onPress={handleSignIn}>
        <View style={{backgroundColor: color.palette.greyLight, borderRadius: 8,}}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginVertical: 10,
              marginHorizontal: 10,
            }}
          >
            <Text text="Sign in"/>
            <View style={{width: 10}}/>
            <SvgIcon icon="sign-in" preset={"small"}/>
          </View>
        </View>
      </TouchableOpacity>
    )
  }

  const renderRightSection = (): ReactNode => {
    if (!app?.auth) return renderPublicSection()
    switch (app?.user?.role) {
      case constants.roles.USER:
        return renderUserSection();
      case constants.roles.WORKER:
        return renderWorkerSection();
      case constants.roles.BUSINESS:
        return renderBusinessUserSection();
      case constants.roles.MANAGER:
        return renderBusinessUserSection();
    }
    return renderPublicSection();
  }

  return (
    <div id='headerDK'>
      <div className='logoLeft'>
        <img alt="T4W" src={Logo}/>
      </div>
      <div className='infoRight'>
        {renderRightSection()}
      </div>
    </div>
  )
});


const HEADER_STYLE: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  marginTop: 10,
  marginBottom: 10,
}
