import React, { FC, useEffect, useState } from "react"
import {SafeAreaView, TouchableOpacity, View} from "react-native"
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {ParamListBase} from "@react-navigation/native";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../models";
import {AlertModalStore} from "../../../../models/app/alert-modal-store";
import {PureBottomModal} from "../../../../components/backdrop-modal/pure-bottom-modal";
import {Button, Header, TextField} from "../../../../components";
import {SelectTextField} from "../../../../components/text-field/select-text-field";
import {LoadingModal} from "../../../../components/backdrop-modal/loading-modal";
import {AlertModal} from "../../../../components/backdrop-modal/alert-modal";
import {JobTagRow} from "../../../../components/tag/job-tag-row";
import {MainAppStore} from "../../../../models/app/main-app-store";
import {color} from "../../../../theme";
import {SvgIcon} from "../../../../components/icon/svg-icon";
import {SelectTypeSections} from "./upload-select-type-screen";
import {KvQualificationPath, KvSkillPath, WorkerWalletItemPath} from "../../../../models/app/advanced-skill";
import {useFilePicker} from "use-file-picker";
import { TrackEvents, trackWithUserAccount } from "../../../../services/track";

export interface IWorkerUploadDocumentScreenScreen {
  navigation: NativeStackNavigationProp<ParamListBase>
}

export const WorkerUploadDocumentScreen: FC<IWorkerUploadDocumentScreenScreen> = observer(({navigation}) => {
  const {app} = useStores()
  const [progressing, setProgressing] = useState(false)
  const [description, setDescription] = useState('')
  const [openFileSelector, fileResult] = useFilePicker({
    accept: ['.pdf', '.doc', '.docx', 'image/*'],
    maxFileSize: 2,
  });
  const docAsset = !fileResult?.plainFiles || fileResult?.plainFiles.length <= 0 ? null : fileResult?.plainFiles[0]

  useEffect(() => {
    trackWithUserAccount(TrackEvents.WalletSetup, app?.userAccount,)
  }, [])

  const handlePickFile = async () => {
    await openFileSelector()
  }

  const onClose = () => {
    MainAppStore.setSelectTypeSection({} as any)
    MainAppStore.setUploadSelectedDescription({} as any)
    navigation.goBack()
  }

  const goHome = () => {
    MainAppStore.setSelectTypeSection({} as any)
    MainAppStore.setUploadSelectedDescription({} as any)
    navigation.popToTop()
  }

  const handleSubmitQualification = async () => {
    try {
      setProgressing(true)
      const qualificationEducationLevel = MainAppStore.selectTypeSection.id
      const courseName = MainAppStore.uploadSelectedDescription.name
      if (!docAsset || !qualificationEducationLevel || !courseName) {
        AlertModalStore.alert('Error', 'Please enter require fields.')
        return
      }
      let qualificationId = MainAppStore.uploadSelectedDescription.id
      if (!qualificationId) {
        const res = await app.environment.api.editUserQualification({
          courseName: courseName,
          qualificationEducationLevel: qualificationEducationLevel,
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        qualificationId = res.item.id
      }
      const upRes = await app.environment.api.uploadFile(docAsset)
      if (upRes.kind !== 'ok') {
        AlertModalStore.alert('Error', upRes.message)
        return
      }
      const document = upRes?.file
      const workerQualificationRes = await app.environment.api.generalApiCreate(KvQualificationPath.workerQualifications, {
        qualification: qualificationId,
        ...(document.id ? {document: document.id} : null),
      })
      if (workerQualificationRes.kind !== 'ok') {
        AlertModalStore.alert('Error', workerQualificationRes.message)
        return
      }
      await app.environment.api.updateWorkerProfileData(app?.userAccount?.workerProfile?.id, {
        qualifications: [...app?.userAccount?.workerProfile?.qualifications?.map(it => it.id), qualificationId],
      })

      goHome()
    } catch (e) {
      console.log(e)
    } finally {
      setProgressing(false)
    }
  }

  const handleSubmitSkill = async () => {
    try {
      setProgressing(true)
      const skillCategory = MainAppStore.selectTypeSection.id
      const description = MainAppStore.uploadSelectedDescription.name
      if (!docAsset || !skillCategory || !description) {
        AlertModalStore.alert('Error', 'Please enter require fields.')
        return
      }
      let skillId = MainAppStore.uploadSelectedDescription.id
      if (!skillId) {
        const res = await app.environment.api.editUserSkill({
          ...(skillCategory ? {skillCategory} : null),
          ...(description ? {description} : null),
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        skillId = res.item.id
      }
      const upRes = await app.environment.api.uploadFile(docAsset)
      if (upRes.kind !== 'ok') {
        AlertModalStore.alert('Error', upRes.message)
        return
      }
      const document = upRes?.file
      const workerSkillsRes = await app.environment.api.generalApiCreate(KvSkillPath.workerSkills, {
        skill: skillId,
        ...(document.id ? {document: document.id} : null),
      })
      if (workerSkillsRes.kind !== 'ok') {
        AlertModalStore.alert('Error', workerSkillsRes.message)
        return
      }
      await app.environment.api.updateWorkerProfileData(app?.userAccount?.workerProfile?.id, {
        skills: [...app?.userAccount?.workerProfile?.skills?.map(it => it.id), skillId],
      })

      goHome()
    } catch (e) {
      console.log(e)
    } finally {
      setProgressing(false)
    }
  }

  const handleSubmitCv = async () => {
    if (!docAsset) return
    try {
      setProgressing(true);
      const res = await app.environment.api.uploadFile(docAsset)
      if (res.kind === 'ok') {
        await app.environment.api.editWorkerWalletItem(WorkerWalletItemPath.workerCvs, {
          url: res.file.id,
          workerProfile: app?.userAccount?.workerProfile?.id,
        })
      } else {
        AlertModalStore.alert('Error', 'Can not upload file')
      }

      goHome()
    } catch (e) {
      console.log(e)
    } finally {
      setProgressing(false);
    }
  }

  const handleSubmitRTW = async () => {
    if (!docAsset) return
    try {
      setProgressing(true);
      const res = await app.environment.api.uploadFile(docAsset)
      if (res.kind === 'ok') {
        await app.environment.api.editWorkerWalletItem(WorkerWalletItemPath.workerRightToWorks, {
          url: res.file.id,
          workerProfile: app?.userAccount?.workerProfile?.id,
        })
      } else {
        AlertModalStore.alert('Error', 'Can not upload file')
      }

      goHome()
    } catch (e) {
      console.log(e)
    } finally {
      setProgressing(false);
    }
  }

  const handleSubmitOther = async () => {
    if (!docAsset) return
    try {
      setProgressing(true);
      const res = await app.environment.api.uploadFile(docAsset)
      if (res.kind === 'ok') {
        await app.environment.api.editWorkerWalletItem(WorkerWalletItemPath.workerOthers, {
          url: res.file.id, description,
          workerProfile: app?.userAccount?.workerProfile?.id,
        })
      } else {
        AlertModalStore.alert('Error', 'Can not upload file')
      }

      goHome()
    } catch (e) {
      console.log(e)
    } finally {
      setProgressing(false);
    }
  }

  const onSubmit = () => {
    if (MainAppStore.selectTypeSection.parentId === SelectTypeSections.qualification.key) {
      handleSubmitQualification()
    } else if (MainAppStore.selectTypeSection.parentId === SelectTypeSections.skill.key) {
      handleSubmitSkill()
    } else if (MainAppStore.selectTypeSection.parentId === SelectTypeSections.cv.key) {
      handleSubmitCv()
    } else if (MainAppStore.selectTypeSection.parentId === SelectTypeSections.rightToWork.key) {
      handleSubmitRTW()
    } else if (MainAppStore.selectTypeSection.parentId === SelectTypeSections.other.key) {
      handleSubmitOther()
    }
  }

  const handleSelectDescription = () => {
    if (MainAppStore.selectTypeSection.parentId === SelectTypeSections.qualification.key) {
      navigation.navigate("workerDescriptionQualificationScreen", {qualificationEducationLevel: MainAppStore.selectTypeSection.id})
    } else if (MainAppStore.selectTypeSection.parentId === SelectTypeSections.skill.key) {
      navigation.navigate("workerDescriptionSkillScreen", {skillCategory: MainAppStore.selectTypeSection.id})
    } else if (MainAppStore.selectTypeSection.parentId === SelectTypeSections.other.key) {
      navigation.navigate("workerDescriptionSkillScreen", {skillCategory: 0})
    }
    // navigation.navigate("uploadEditDescriptionScreen")
  }

  const hasDescription = (
    MainAppStore.selectTypeSection.parentId !== SelectTypeSections.cv.key
    && MainAppStore.selectTypeSection.parentId !== SelectTypeSections.rightToWork.key
    && MainAppStore.selectTypeSection.parentId !== SelectTypeSections.other.key
  )
  const isOther = MainAppStore.selectTypeSection.parentId === SelectTypeSections.other.key
  const disableBtnDone = [SelectTypeSections.qualification.key, SelectTypeSections.skill.key].includes(MainAppStore.selectTypeSection.parentId)
    ? (!docAsset || !MainAppStore.selectTypeSection.id || !MainAppStore.uploadSelectedDescription?.id)
    : [SelectTypeSections.cv.key, SelectTypeSections.rightToWork.key].includes(MainAppStore.selectTypeSection.parentId)
      ? (!docAsset)
      : (!docAsset || !description)
  return (
    <>
      <PureBottomModal onClose={onClose}>
        <SafeAreaView style={{flex: 1}}>
          <Header
            preset={"modalHeader"}
            titlePreset={"headerTitle"}
            headerText={'Details'}
            rightIcon={"times"}
            rightIconPreset={"smallBlue"}
            onRightPress={onClose}
          />
          <View style={{paddingBottom: 40, marginTop: 30}}>
            <View style={{paddingLeft: 20, marginBottom: 24, paddingRight: 20, flexDirection: 'row'}}>
              <View style={{flex: 1}}>
                <SelectTextField
                  value={docAsset?.name || null}
                  numberOfLines={1}
                  preset={'brand'}
                  label={"Upload Document *"} labelPreset={"formLabel"}
                  placeholder={'Click to Upload Document'}
                  onPress={handlePickFile}
                />
              </View>
              <View style={{justifyContent: 'center', marginTop: 18}}>
                <TouchableOpacity
                  onPress={() => fileResult.clear()}
                  style={{
                    justifyContent: 'center', alignItems: 'center',
                    marginLeft: 10, borderRadius: 36, width: 36, height: 36,
                    borderWidth: 1, borderColor: color.palette.greyMedH
                  }}>
                  <SvgIcon icon={'minus'} preset={'primary'} iconStyle={{fill: color.palette.greyMedH}}/>
                </TouchableOpacity>
              </View>
            </View>
            <View style={{paddingHorizontal: 20, marginBottom: 24}}>
              <SelectTextField
                onPress={() => navigation.navigate("uploadSelectTypeScreen")}
                preset={"brand"}
                value={null} placeholder={'Select'}
                rightIcon={"chevron-right"}
                label={'Type *'} labelPreset={"formLabel"}
              >
                {!!MainAppStore.selectTypeSection?.parentId && (
                  <View style={{minHeight: 48, flex: 1, paddingHorizontal: 10}}>
                    <JobTagRow
                      text={MainAppStore.selectTypeSection?.name}
                      onPress={() => MainAppStore.setSelectTypeSection({} as any)}/>
                  </View>
                )}
              </SelectTextField>
            </View>
            <View style={{
              paddingHorizontal: 20, marginBottom: 24, flexDirection: 'row',
              display: hasDescription ? 'flex' : 'none'
            }}>
              <View style={{flex: 1}}>
                <SelectTextField
                  onPress={handleSelectDescription}
                  preset={"brand"}
                  value={MainAppStore.uploadSelectedDescription?.id ? MainAppStore.uploadSelectedDescription?.name : null}
                  placeholder={'Enter Description'}
                  label={'Description *'} labelPreset={"formLabel"}
                />
              </View>
              <View style={{justifyContent: 'center', marginTop: 16}}>
                <TouchableOpacity
                  onPress={() => MainAppStore.setUploadSelectedDescription({} as any)}
                  style={{
                    justifyContent: 'center', alignItems: 'center',
                    marginLeft: 10, borderRadius: 36, width: 36, height: 36,
                    borderWidth: 1, borderColor: color.palette.greyMedH
                  }}>
                  <SvgIcon icon={'minus'} preset={'primary'} iconStyle={{fill: color.palette.greyMedH}}/>
                </TouchableOpacity>
              </View>
            </View>
            <View style={{
              paddingHorizontal: 20, marginBottom: 24, flexDirection: 'row',
              display: isOther ? 'flex' : 'none'
            }}>
              <View style={{flex: 1}}>
                <TextField
                  preset={"brand"}
                  value={description} onChangeText={setDescription}
                  placeholder={'Enter Description'}
                  label={'Description *'} labelPreset={"formLabel"}
                />
              </View>
            </View>
            <View style={{paddingHorizontal: 20}}>
              <Button
                text="Save Details"
                disabled={progressing || disableBtnDone}
                preset={disableBtnDone ? 'disabled' : 'brand'}
                onPress={onSubmit}/>
            </View>
          </View>
        </SafeAreaView>
      </PureBottomModal>
      <LoadingModal visible={progressing}/>
      <AlertModal/>
    </>
  )
})
