import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {observer} from "mobx-react-lite"
import React, {FC, useCallback, useState} from "react"
import {SafeAreaView, View, ViewStyle} from "react-native"
import {Header, GradientBackground, TextField} from "../../../components"
import {color} from "../../../theme"
import {SearchBarSuggest} from "../../../components/search-bar/search-bar-suggest";
import {IKvItemModel, KvItemPath} from "../../../models/app/kv-item";
import {useStores} from "../../../models";
import {debounce} from "../../../utils/debounce";
import {ParamListBase, useFocusEffect} from "@react-navigation/native";
import {AlertModalStore} from "../../../models/app/alert-modal-store";

export interface SearchProps {
  navigation: NativeStackNavigationProp<ParamListBase>
}

export const SearchScreen: FC<SearchProps> = observer(({navigation}) => {
  const {app} = useStores()
  // const [focusSearch, setFocusSearch] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [jobRoleSuggest, setJobRoleSuggest] = useState<Array<IKvItemModel>>([])

  useFocusEffect(useCallback(() => {
    const searchJobRoleText = app?.searchJobRoleText
    setSearchText(searchJobRoleText)
  }, []))

  const handleSearchSuggest = async (text: string) => {
    try {
      const res = await app.environment.api.getKvItemList(KvItemPath.role, {
        customFilter: true,
        ...(text ? {'filters[name][$containsi]': text} : null)
      })
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message)
        return
      }
      const _jobRoleSuggest: Array<IKvItemModel> = []
      res?.items?.forEach(it => {
        if (!it?.name?.toLowerCase().includes(text?.toLowerCase())) return
        if (_jobRoleSuggest.find(jit => jit.name?.trim() === it.name?.trim())) return
        _jobRoleSuggest.push({...it, name: it.name?.trim()})
      })
      setJobRoleSuggest(_jobRoleSuggest)
    } catch (e) {
      console.log(e)
    }
  }
  const delaySearchSuggest = useCallback(debounce(handleSearchSuggest, 800), []);

  const handleSearchText = (text) => {
    setSearchText(text)
    // @ts-ignore
    if (text) delaySearchSuggest(text)
  }

  const handleSearchBack = async (text = '') => {
    await app.setHasSearchJobRole(true)
    await app.setFilterJobRoleText(text)
    navigation.goBack()
  }

  return (
    <SafeAreaView style={CONTAINER}>
      <GradientBackground colors={[color.palette.gradientStart, color.palette.gradientStop]}/>
      <View style={{flex: 1}}>
        <Header
          preset={"transparent"} titlePreset={"headerTitle"}
          leftIcon={"times"} leftIconPreset={'smallBlue'}
          onLeftPress={() => handleSearchBack(searchText)}
        />
        <View style={{marginHorizontal: 16, paddingTop: 28, paddingBottom: 8}}>
          <TextField
            value={searchText} autoFocus={true} preset={'primary'}
            onChangeText={handleSearchText}
            // onBlur={() => setFocusSearch(false)}
            rightIcon={"times-circle-fill"}
            rightIconPreset={"default"}
            rightIconPress={() => setSearchText("")}
          />
        </View>
        <SearchBarSuggest
          onItemPress={(item) => handleSearchBack(item?.name)}
          data={jobRoleSuggest}
          searchText={searchText}/>
      </View>
    </SafeAreaView>
  )
})

export const CONTAINER: ViewStyle = {
  flex: 1,
  backgroundColor: color.palette.white,
}
