import * as React from "react"
import {View, SafeAreaView, ViewStyle} from "react-native"
import {FC} from "react"
import {CommonActions, ParamListBase} from "@react-navigation/native"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Text} from "../../../components"
import {color} from "../../../theme";
import {Button} from "../../../components"

export interface ResetPassword2Props {
  navigation: NativeStackNavigationProp<ParamListBase>
}

export const ResetPasswordResultScreen: FC<ResetPassword2Props> = ({navigation}) => {

  const goHome = () => {
    navigation.dispatch(
      CommonActions.reset({index: 0, routes: [{name: 'main'}],})
    );
  }

  const continueApp = () => {
    window.location.href = `${process.env.REACT_APP_SCHEMES}main`
    goHome()
  }

  return (
    <SafeAreaView style={CONTAINER}>
      <View style={BODY}>
        <View>
          <Text preset={"headerTitle"}>Password Updated</Text>
          <Text preset={"body1"} style={{
            marginTop: 24
          }}>You have successfully updated your password.</Text>
        </View>

        <View style={BUTTON_CONTAINER}>
          <Button
            onPress={continueApp}
            text="Continue to App" preset="primary"/>
        </View>
      </View>
    </SafeAreaView>
  )
}

const CONTAINER: ViewStyle = {
  flex: 1,
  backgroundColor: color.palette.white,
}

const BODY: ViewStyle = {
  paddingHorizontal: 16,
  marginTop: 40,
  flex: 1,
  justifyContent: 'space-between'
}

const BUTTON_CONTAINER: ViewStyle = {
  marginHorizontal: 12,
  marginVertical: 8,
}
