import React, {FC} from "react"
import {View, FlatList} from "react-native"
import {searchIgnoreCase} from "../../../utils/utils";
import {IJobRoleAdModel} from "../../../models/app/job-role-ad";
import {BusinessJobRoleCard} from "../../../components/card/job-card";
import {IJobRoleBookmarkModel} from "../../../models/app/job-role";

export interface AvailiableJobsProps {
  onPress: (item: IJobRoleAdModel) => void
  onLogoPress?: (id: number) => void
  search: string
  jobRoles: Array<IJobRoleAdModel>
  jobRoleBookmarks: Array<IJobRoleBookmarkModel>
  refreshing: boolean
  loadData?: () => void
  toggleBookmark?: (jobRoleId: number) => void,
}

export const AvailableJobTab: FC<AvailiableJobsProps> = (
  {onPress, onLogoPress, search, jobRoles, refreshing, loadData, jobRoleBookmarks, toggleBookmark}
) => {

  const renderItem = ({item}: { item: IJobRoleAdModel }) => {
    return <BusinessJobRoleCard
      onLogoPress={onLogoPress}
      bookmark={!!jobRoleBookmarks?.find(it => it?.jobRole?.id === item?.id)}
      toggleBookmark={() => toggleBookmark(item?.id)}
      messageCount={true} item={item} onPress={() => onPress(item)}/>
  }

  const filteredData = search ? jobRoles.filter(item => (
    searchIgnoreCase(item?.role?.name, search)
  )) : jobRoles
  return (
    <View style={{flex: 1}}>
      {/*<View style={{flexDirection: "row", marginHorizontal: 20}}>*/}
      {/*  <Text preset="total" text={String("Total " + filteredData?.length)}/>*/}
      {/*</View>*/}
      <View style={{flex: 1, marginHorizontal: 5, marginTop: 16}}>
        <FlatList
          onRefresh={loadData} refreshing={refreshing}
          data={filteredData}
          renderItem={renderItem}
          ListFooterComponent={<View style={{height: 100}}/>}
          keyExtractor={(item) => String(item.id)}
        />
      </View>
    </View>
  )
}
