import React from 'react'
import { AvatarImage } from "../avatar-image/avatar-image"
import AvatarDefault from "../avatar-image/AvatarDefault"
import { StyleSheet, TouchableOpacity, View } from "react-native"
import { Text } from "../text/text"
import { SvgIcon } from "../icon/svg-icon"
import { isIos } from "../../utils/platform";

interface Props {
  onClick?: () => void
  avatar?: string
  name: string
  isCheck?: boolean
}

const UserCard = ({avatar, name, isCheck, onClick}: Props) => {
  return (
    <TouchableOpacity
      style={styles.wrapper}
      onPress={onClick}>
      {!!avatar ?
        <AvatarImage
          onPress={() => {
          }}
          source={{uri: avatar}} preset={"medium"} py={0} px={0}/>
        :
        <AvatarDefault py={0} px={0} name={name} preset={"medium"}/>
      }
      <View style={styles.textWrapper}>
        <Text
          style={{fontSize: 16, fontWeight: 'normal'}}
          numberOfLines={1}
          text={name}
        />
      </View>
      <View style={{justifyContent: 'center', display: onClick ? 'flex' : 'none'}}>
        <SvgIcon
          preset={isCheck ? 'smallBlue20' : 'square'}
          icon={isCheck ? 'check-square' : 'square-regular'}
          theme={isCheck ? 'solid' : 'regular'}
        />
      </View>
    </TouchableOpacity>
  )
};

export default UserCard;

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row', alignItems: 'center',
    marginBottom: isIos ? 18 : 8,
  },
  textWrapper: {
    flex: 1, paddingHorizontal: 10, paddingTop: 4
  },
})
