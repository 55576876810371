import React, { FC, useCallback, useEffect, useState } from 'react';
import { View, FlatList, StyleSheet, SafeAreaView, TouchableOpacity } from 'react-native';

import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { observer } from "mobx-react-lite";
import { UserMessage } from "../../../components/user-message/user-message";
import { color } from "../../../theme";
import { Button, GradientBackground, Header, Text } from "../../../components";
import { useStores } from "../../../models";
import { ParamListBase, useFocusEffect } from "@react-navigation/native";
import { IUserMessageModel } from "../../../models/app/message";
import { AlertModalStore } from "../../../models/app/alert-modal-store";
import { IWorkerApplicationModel } from "../../../models/app/application";
import { SvgIcon } from "../../../components/icon/svg-icon";
import { BackdropModal } from "../../../components/backdrop-modal/backdrop-modal";
import { IGroupNotificationModel, IUserNotificationModel } from "../../../models/app/user-account";
import { DEFAULT_LIMIT } from "../../../utils/constants";
import TabButton from "../../../components/tab-bar/tab-button"
import { IBusinessGroupModel } from "../../../models/app/contact";
import { MainAppStore } from "../../../models/app/main-app-store";
import { BottomModal } from "../../../components/backdrop-modal/bottom-modal";
import BulkMessageDelete from "../../business/messages/bulk-message-delete";
import { useIsMount } from "../../../utils/use-is-mount";

export interface WorkerMessageScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
}

export const WorkerMessageScreenTabs = {
  groupChat: {key: 1, label: "Chat"},
  applicationMessages: {key: 2, label: "Job Advert"},
  bulkMessages: {key: 3, label: "Bulk"},
}

export const WorkerMessageScreenTags = {
  newApplicants: {key: 1, label: "New Applicants", selectLabel: 'New Applicants'},
  responses: {key: 2, label: "New Responses", selectLabel: 'Responses'},
  unreadMessage: {key: 3, label: "Unread Messages", selectLabel: 'Unread Messages'},
}

export const WorkerMessageScreen: FC<WorkerMessageScreenProps> = observer(
  ({navigation}) => {
    const [refreshing, setRefreshing] = useState(false)
    const {app} = useStores()
    const activeTab = app.tabStore.workerMessageScreenTab || WorkerMessageScreenTabs.applicationMessages.key
    const [dataList, setDataList] = useState<Array<IUserMessageModel>>([])
    const [jobApplications, setJobApplications] = useState<Array<IWorkerApplicationModel>>([])
    const [groupChatMessages, setGroupChatMessages] = useState<Array<IBusinessGroupModel>>([])
    const [visibleModal, setVisibleModal] = useState(false)
    const [notification, setNotification] = useState<IUserNotificationModel>()
    const [groupNotification, setGroupNotification] = useState<IGroupNotificationModel>(null)

    const [visibleGroupChatModal, setVisibleGroupChatModal] = useState(false)
    const [isShowBulkDelete, setShowBulkDelete] = useState(false)

    const loadBulkData = async () => {
      if (refreshing) return
      setRefreshing(true)
      try {
        let _notification: IUserNotificationModel = null
        let showUnread = WorkerMessageScreenTags.unreadMessage.key === app.filterStore.workerMessageFilterId
        const notificationRes = await app.environment.api.getNotification()
        if (notificationRes.kind === 'ok') _notification = notificationRes.item
        if (showUnread) {
          if (_notification?.messages?.messages?.length <= 0) {
            setDataList([])
            return
          }
        }
        setNotification(_notification)

        const res = await app.environment.api.getNewWorkerMessage({
          'filters[group][contacts][user][id][$eq]': app.userAccount.id,
          'filters[$or][0][hideUserIds][$notContains]': app?.userAccount?.id,
          'filters[$or][1][hideUserIds][$null]': true,
          ...(showUnread ? {'filters[id][$eq]': _notification?.messages?.messages} : null),
          "pagination[pageSize]": DEFAULT_LIMIT
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        setDataList(res.items)
      } catch (e) {
        console.log(e)
      } finally {
        setRefreshing(false)
      }
    }

    const nextData = async () => {
      if (refreshing) return
      if (dataList?.length % DEFAULT_LIMIT !== 0) return
      const _currentPage = Math.floor(dataList?.length / DEFAULT_LIMIT) + 1
      setRefreshing(true)
      try {
        let showUnread = WorkerMessageScreenTags.unreadMessage.key === app.filterStore.workerMessageFilterId
        if (showUnread) {
          if (notification?.messages?.messages?.length <= 0) {
            setDataList([])
            return
          }
        }

        const res = await app.environment.api.getNewWorkerMessage({
          'filters[group][contacts][user][id][$eq]': app.userAccount.id,
          'filters[$or][0][hideUserIds][$notContains]': app?.userAccount?.id,
          'filters[$or][1][hideUserIds][$null]': true,
          ...(showUnread ? {'filters[id][$eq]': notification?.messages?.messages} : null),
          "pagination[page]": _currentPage,
          "pagination[pageSize]": DEFAULT_LIMIT
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        setDataList([...dataList, ...res.items])
      } catch (e) {
        console.log(e)
      } finally {
        setRefreshing(false)
      }
    }

    const loadMyApplicationData = async () => {
      try {
        const res = await app.environment.api.getWorkerApplications({
          "filters[workerProfile][id][$eq]": app?.userAccount?.workerProfile?.id,
          "pagination[pageSize]": DEFAULT_LIMIT
        })
        if (res.kind === 'ok') {
          setJobApplications(res.applications)
        }
      } catch (e) {
        console.log(e)
      } finally {
      }
    }

    const nextMyApplicationData = async () => {
      if (refreshing) return
      if (jobApplications?.length % DEFAULT_LIMIT !== 0) return
      const _currentPage = Math.floor(jobApplications?.length / DEFAULT_LIMIT) + 1
      setRefreshing(true)
      try {
        const res = await app.environment.api.getWorkerApplications({
          "filters[workerProfile][id][$eq]": app?.userAccount?.workerProfile?.id,
          "pagination[page]": _currentPage,
          "pagination[pageSize]": DEFAULT_LIMIT
        })
        if (res.kind === 'ok') {
          setJobApplications([...jobApplications, ...res.applications])
        }
      } catch (e) {
        console.log(e)
      } finally {
        setRefreshing(false)
      }
    }

    const loadGroupChatData = async () => {
      if (refreshing) return
      setRefreshing(true)
      try {
        let notification: IGroupNotificationModel = null
        const showUnread = WorkerMessageScreenTags.unreadMessage.key === app.filterStore.workerMessageFilterId
        const notificationRes = await app.environment.api.getGroupUnread()
        if (notificationRes.kind === 'ok') {
          notification = notificationRes.item
          setGroupNotification(notification)
        }
        if (showUnread) {
          if (notification?.count <= 0) {
            setGroupChatMessages([])
            return
          }
        }

        const res = await app.environment.api.getGroupForChat({
          ...(showUnread ? {'filters[id][$eq]': notification?.groups} : null),
          'filters[isChatGroup][$null]': false,
          'filters[isChatGroup][$eq]': true,
          'filters[contacts][user][id][$eq]': app.userAccount.id,
          'filters[$or][0][hideUserIds][$null]': true,
          'filters[$or][1][hideUserIds][$notContains]': app?.userAccount?.id,
          "pagination[pageSize]": DEFAULT_LIMIT
        })
        if (res.kind === 'ok') {
          setGroupChatMessages(res?.items)
        }
      } catch (e) {
        console.log(e)
      } finally {
        setRefreshing(false)
      }
    }

    const nextGroupChatData = async () => {
      if (refreshing) return
      if (groupChatMessages?.length % DEFAULT_LIMIT !== 0) return
      const _currentPage = Math.floor(groupChatMessages?.length / DEFAULT_LIMIT)
      try {
        setRefreshing(true)
        const showUnread = WorkerMessageScreenTags.unreadMessage.key === app.filterStore.workerMessageFilterId
        const res = await app.environment.api.getGroupForChat({
          ...(showUnread ? {'filters[id][$eq]': groupNotification?.groups} : null),
          'filters[isChatGroup][$null]': false,
          'filters[isChatGroup][$eq]': true,
          'filters[contacts][user][id][$eq]': app.userAccount.id,
          'filters[$or][0][hideUserIds][$null]': true,
          'filters[$or][1][hideUserIds][$notContains]': app?.userAccount?.id,
          "pagination[pageSize]": DEFAULT_LIMIT,
          "pagination[page]": _currentPage,
        })
        if (res.kind === 'ok') {
          setGroupChatMessages([...groupChatMessages, ...res?.items])
        }
      } catch (e) {
        console.log(e)
      } finally {
        setRefreshing(false)
      }
    }

    useFocusEffect(useCallback(() => {
      const _activeTab = app.tabStore.workerMessageScreenTab || WorkerMessageScreenTabs.applicationMessages.key
      if (_activeTab === WorkerMessageScreenTabs.applicationMessages.key) loadMyApplicationData()
      else if (_activeTab === WorkerMessageScreenTabs.bulkMessages.key) loadBulkData()
      else if (_activeTab === WorkerMessageScreenTabs.groupChat.key) loadGroupChatData()
    }, [activeTab]))

    const handleTabPress = (key: number) => {
      if (key === WorkerMessageScreenTabs.applicationMessages.key) loadMyApplicationData()
      else if (key === WorkerMessageScreenTabs.bulkMessages.key) loadBulkData()
      else if (key === WorkerMessageScreenTabs.groupChat.key) loadGroupChatData()
      app.tabStore.setWorkerMessageScreenTab(key)
    }

    const handleGroupPress = async (item: IUserMessageModel) => {
      navigation.navigate('businessNewMessageScreen', {
        messageId: item?.id, toUserId: item.createdUser.id
      })
    }

    const handleJobAdPress = async (item: IWorkerApplicationModel) => {
      navigation.navigate("userApplicantMessageScreen", {
        toUserId: item?.jobRole?.jobAd?.owner?.id, applicationId: item?.id,
      })
    }

    const handleFilterPress = async () => {
      setVisibleGroupChatModal(false)
      setVisibleModal(true)
    }

    const renderBulkMessageItem = ({item, index}: { item: IUserMessageModel, index: number }) => {
      return (
        <UserMessage
          onLogoPress={() => navigation.navigate('businessProfileScreen', {
            businessProfileId: item?.createdUser?.businessProfile?.id
          })}
          avatar={item?.createdUser?.businessProfile?.logo?.url} dot={!!item?.newChatMessages}
          key={index.toString()}
          title={item.title}
          subTitle={item?.createdUser?.businessProfile?.name}
          time={''}
          isWorker={false}
          onPress={() => handleGroupPress(item)}/>
      );
    }

    const renderJobApplicationItem = ({item, index}: { item: IWorkerApplicationModel, index: number }) => {
      return (
        <UserMessage
          onLogoPress={() => navigation.navigate('businessProfileScreen', {
            businessProfileId: item?.jobRole?.jobAd?.businessProfile?.id
          })}
          avatar={item?.jobRole?.jobAd?.businessProfile?.logo?.url}
          key={index.toString()}
          title={item?.jobRole?.jobAd?.businessProfile?.name}
          subTitle={item?.jobRole?.jobAd?.title}
          time={''}
          isWorker={false} dot={!!item?.newChatMessages}
          onPress={() => handleJobAdPress(item)}/>
      );
    }

    const handleGroupChatItem = (item: IBusinessGroupModel) => {
      MainAppStore.setGroup(item)
      navigation.navigate('workerGroupChatScreen', {
        groupId: item?.id, groupName: item?.name
      })
    }

    const renderGroupChatItem = ({item}: { item: IBusinessGroupModel }) => {
      const dot = groupNotification ? groupNotification?.groups?.includes(item?.id) : false
      return (
        <UserMessage
          onLogoPress={() => navigation.navigate('businessProfileScreen', {
            businessProfileId: item?.businessProfile?.id
          })}
          avatar={item?.businessProfile?.logo?.url} dot={dot}
          key={String(item?.id)}
          title={item.name}
          subTitle={item?.businessProfile?.name}
          time={''}
          isWorker={false}
          onPress={() => handleGroupChatItem(item)}/>
      );
    }

    const renderTabContent = () => {
      if (activeTab === WorkerMessageScreenTabs.applicationMessages.key) {
        return (
          <FlatList
            onRefresh={loadMyApplicationData} refreshing={refreshing}
            style={{flex: 1}}
            contentContainerStyle={styles.contentContainerStyle}
            data={jobApplications}
            renderItem={renderJobApplicationItem}
            keyExtractor={(item) => String(item.id)}
            onEndReached={nextMyApplicationData}
            ListFooterComponent={<View style={{height: 50}}/>}
            ListEmptyComponent={(
              <View style={{paddingHorizontal: 10, marginTop: 25}}>
                <Text preset="body" style={{color: color.palette.primary, fontStyle: 'italic'}}>
                  No Job Advert Messages to Display
                </Text>
              </View>
            )}
          />
        )
      }
      if (activeTab === WorkerMessageScreenTabs.groupChat.key) {
        return (
          <FlatList
            onRefresh={loadGroupChatData} refreshing={refreshing}
            style={{flex: 1}}
            contentContainerStyle={styles.contentContainerStyle}
            data={groupChatMessages}
            renderItem={renderGroupChatItem}
            keyExtractor={(item) => String(item.id)}
            onEndReached={nextGroupChatData}
            ListFooterComponent={<View style={{height: 50}}/>}
            ListEmptyComponent={(
              <View style={{paddingHorizontal: 10, marginTop: 25}}>
                <Text preset="body" style={{color: color.palette.primary, fontStyle: 'italic'}}>
                  No Chat Groups to Display
                </Text>
              </View>
            )}
          />
        )
      }
      return (
        <FlatList
          onRefresh={loadBulkData} refreshing={refreshing}
          style={{flex: 1}}
          contentContainerStyle={styles.contentContainerStyle}
          data={dataList}
          renderItem={renderBulkMessageItem}
          keyExtractor={(item) => String(item.id)}
          onEndReached={nextData}
          ListFooterComponent={<View style={{height: 50}}/>}
          ListEmptyComponent={(
            <View style={{paddingHorizontal: 10, marginTop: 25}}>
              <Text preset="body" style={{color: color.palette.primary, fontStyle: 'italic'}}>
                No Bulk Messages to Display
              </Text>
            </View>
          )}
        />
      )
    }

    const renderTabItem = (it) => {
      const isActive = it.key === activeTab
      return (
        <TabButton
          isActive={isActive}
          value={it.label}
          key={it.key}
          onPress={() => handleTabPress(it.key)}/>
      )
    }

    const handleSelectKey = (key: number) => {
      app.filterStore.setWorkerMessageFilterId(app.filterStore.workerMessageFilterId === key ? null : key)
      if (activeTab === WorkerMessageScreenTabs.applicationMessages.key) loadMyApplicationData()
      else if (activeTab === WorkerMessageScreenTabs.bulkMessages.key) loadBulkData()
      else if (activeTab === WorkerMessageScreenTabs.groupChat.key) loadGroupChatData()
    }

    const renderFilterItem = (it) => {
      return <View key={it.key} style={{marginRight: 8}}>
        <TouchableOpacity onPress={() => handleSelectKey(it.key)}>
          <View style={{
            flexDirection: 'row', alignItems: 'center',
            backgroundColor: color.palette.greyLight,
            paddingHorizontal: 12,
            paddingVertical: 11,
            borderRadius: 100
          }}>
            <Text preset={'body'} style={{color: color.palette.blue, fontWeight: '400'}}>
              {it.label}
            </Text>
            <View style={{paddingLeft: 8}}>
              <SvgIcon preset={'primary'} icon={'times-circle-dark'}/>
            </View>
          </View>
        </TouchableOpacity>
      </View>

    }

    const renderRightIcon = (): any => {
      const result = {rightIcon: 'ellipsis-h', rightIconTheme: 'solid', rightIconPreset: 'smallBrand'}
      if (activeTab === WorkerMessageScreenTabs.bulkMessages.key && dataList?.length > 0) {
        return result
      } else if (activeTab === WorkerMessageScreenTabs.groupChat.key && groupChatMessages?.length > 0) {
        return result
      } else {
        return result
      }
    }

    const handleShowModalDeleteBulk = () => {
      setVisibleGroupChatModal(false)
      setShowBulkDelete(true)
    };

    const onRightPress = () => {
      if (activeTab === WorkerMessageScreenTabs.groupChat.key) {
        setVisibleGroupChatModal(true)
      } else if (activeTab === WorkerMessageScreenTabs.bulkMessages.key) {
        setVisibleGroupChatModal(true)
      } else if (activeTab === WorkerMessageScreenTabs.applicationMessages.key) {
        setVisibleGroupChatModal(true)
      }
    }

    const isMount = useIsMount()
    useEffect(() => {
      if (!isMount && (!isShowBulkDelete)) loadBulkData()
    }, [isShowBulkDelete])

    const handleCloseBulkDelete = async () => {
      setShowBulkDelete(false)
    }

    const deleteGroupChat = () => {
      setVisibleGroupChatModal(false)
      MainAppStore.setChatGroupDeleteList(groupChatMessages)
      navigation.navigate('deleteGroupChatScreen')
    }

    const workerMessageScreenTags = activeTab === WorkerMessageScreenTabs.bulkMessages.key
      ? [WorkerMessageScreenTags.unreadMessage]
      : activeTab === WorkerMessageScreenTabs.groupChat.key ? [WorkerMessageScreenTags.unreadMessage]
        : [WorkerMessageScreenTags.newApplicants, WorkerMessageScreenTags.responses]
    const workerMessageScreenTagsFiltered = workerMessageScreenTags.filter(item => app.filterStore.workerMessageFilterId === item.key)
    return (
      <>
        <GradientBackground colors={[color.palette.gradientStart, color.palette.gradientStop]}/>
        <SafeAreaView style={styles.container}>
          <View style={styles.mainContainer}>
            <Header
              titlePreset={"headerTitle"} headerText={"Messages"} preset={"primary"}
              {...renderRightIcon()} onRightPress={onRightPress}
            />
            <View style={{
              flexDirection: "row", marginHorizontal: 20,
              alignItems: 'flex-end', justifyContent: 'flex-start'
            }}>
              {Object.values(WorkerMessageScreenTabs).map(renderTabItem)}
            </View>
            <View style={{
              flexDirection: "row", marginHorizontal: 20,
              marginTop: 8, marginBottom: 20,
              alignItems: 'flex-end', justifyContent: 'flex-start',
            }}>
              {workerMessageScreenTagsFiltered.map(renderFilterItem)}
            </View>
            {renderTabContent()}
          </View>
          <BottomModal visible={visibleGroupChatModal} onClose={() => setVisibleGroupChatModal(false)}>
            <View>
              {WorkerMessageScreenTabs.groupChat.key === activeTab && (
                <View>
                  <Button
                    onPress={deleteGroupChat}
                    style={{marginBottom: 10}}
                    disabled={false}
                    preset={'brand'}
                    text={'Delete Chat Group(s)'}
                  />
                </View>
              )}
              {WorkerMessageScreenTabs.bulkMessages.key === activeTab && (
                <Button
                  preset={'brand'}
                  text={'Delete Bulk Message(s)'}
                  style={{marginBottom: 10}}
                  onPress={handleShowModalDeleteBulk}
                />
              )}
              <Button
                onPress={handleFilterPress}
                style={{marginBottom: 10}}
                disabled={false}
                preset={'brandOutLine'}
                text={'Filter Messages'}
              />
              <Button
                onPress={() => setVisibleGroupChatModal(false)}
                style={{marginBottom: 0}}
                disabled={false}
                preset={'primaryOutLine'}
                text={'Cancel'}
              />
            </View>
          </BottomModal>
          <BackdropModal preset={'zeroPad'} visible={visibleModal} onClose={() => setVisibleModal(!visibleModal)}>
            <View style={{paddingTop: 20, paddingBottom: 40}}>
              <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <Text preset={"h1"} text={'Filters'} color={color.palette.blue}/>
                <SvgIcon onPress={() => setVisibleModal(!visibleModal)} icon={'times'} preset={'normal'}/>
              </View>
              <View style={{marginTop: 65}}>
                {Object.values(workerMessageScreenTags).map((item) => (
                  <TouchableOpacity
                    key={item?.key}
                    onPress={() => handleSelectKey(item.key)}
                    style={{marginBottom: 26, alignItems: 'center'}}>
                    <Text preset={"h1"} text={item.selectLabel} color={color.palette.blue}/>
                  </TouchableOpacity>
                ))}
              </View>
            </View>
          </BackdropModal>
          {isShowBulkDelete &&
          <BulkMessageDelete
            isWorker={true}
            isOpen={isShowBulkDelete}
            data={dataList}
            onClose={handleCloseBulkDelete}
          />
          }
        </SafeAreaView>
      </>
    )
  },
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainerStyle: {
    marginBottom: 20,
    paddingHorizontal: 10,
  },
  mainContainer: {
    flex: 1,
  },
  statusBar: {},
});
