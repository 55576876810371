import React, {FC, useCallback, useState} from "react"
import {
  View,
  FlatList,
  SafeAreaView,
  TouchableOpacity,
  ImageStyle,
  ViewStyle,
  ScrollView,
  StyleSheet
} from "react-native"

import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {observer} from "mobx-react-lite"
import {color, spacing} from "../../../theme"
import {Button, Header, Text, GradientBackground, Tag} from "../../../components"
import {BackdropModal} from "../../../components/backdrop-modal/backdrop-modal"
import {SvgIcon} from "../../../components/icon/svg-icon"
import {AvatarImage} from "../../../components/avatar-image/avatar-image"
import {useStores} from "../../../models";
import {ParamListBase, RouteProp, useFocusEffect} from "@react-navigation/native";
import {convertJobAdvertModel, IBusinessJobAdvertModel} from "../../../models/app/job-advert";
import {convertBusinessJobRoleModel, IBusinessJobRoleModel} from "../../../models/app/job-role";
import {IBusinessApplicationModel} from "../../../models/app/business-application";
import {searchIgnoreCase, stringToDateStr} from "../../../utils/utils";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {trackWithUserAccount, TrackEvents} from "../../../services/track";
import {constants, DEFAULT_PAGE_LIMIT} from "../../../utils/constants";
import {LINE, SEE_ALL} from "../../shared/job-ad/styles";
import {parseLocation} from "../../../models/app/location";
import {ApplicationStatus} from "../../../models/app/application";
import {BottomModal} from "../../../components/backdrop-modal/bottom-modal";
import {AdvertRoleStore} from "../../../models/app/advert-role";
import {getUserQualificationStr, getUserSkillStr} from "../../../models/app/advanced-skill";
import {NavigatorParamList} from "../../../navigators";
import TabButton from "../../../components/tab-bar/tab-button";
import WorkerCard from "../../../components/card/worker-card";
import {IUserAccountModel} from "../../../models/app/user-account";

export const JobStatusListScreenTabs = {
  worker: {key: 3, label: "Worker"},
  applications: {key: 1, label: "Applications"},
  jobAd: {key: 2, label: "Adverts"},
}

export const JobStatusListScreenNoWorkerTabs = {
  applications: {key: 1, label: "Applications"},
  jobAd: {key: 2, label: "Adverts"},
}


const CountBgStatusList = ["#61C759", "#FF6700", "#AEB0B8"]
const TitleStatusList = ["#61C759", "#FF6700", "#1C1C1C"]

export interface JobItemProps {
  statusItem: IBusinessJobRoleModel
  navigation: NativeStackNavigationProp<ParamListBase>
}

const avatar = require("../../../../assets/images/default-photo.png")
const JobStatusListItem: FC<JobItemProps> = ({statusItem, navigation}) => {
  const [show, setShow] = useState(false)

  const renderItem = ({item, index}: { item: IBusinessApplicationModel, index: number }) => {
    return (
      <TouchableOpacity
        key={index.toString()}
        onPress={() => navigation.navigate("applicantProfile", {
          applicantIdList: statusItem?.applications?.map(_item => _item?.id),
          index: index
        })}
      >
        <View style={index < statusItem?.applications?.length - 1 ? ITEM_SHOW : ITEM_SHOW_LAST}>
          <View style={{}}>
            <AvatarImage
              px={0}
              preset="round"
              onPress={() => navigation.navigate("workerProfileScreen", {id: item?.workerProfile?.user?.id})}
              source={
                item?.workerProfile?.user?.avatar?.url
                  ? {uri: item?.workerProfile?.user?.avatar?.url}
                  : avatar
              }/>
          </View>
          <View style={{flex: 1, paddingHorizontal: 10}}>
            <Text preset={"h2"}>{
              ([item?.workerProfile?.user?.firstName, item?.workerProfile?.user?.lastName].join(' '))
            }</Text>
            <View style={{marginTop: 5, flexDirection: "row"}}>
              {Array.from(Array(5).keys()).map((it) => (
                <View key={it}>
                  {it < item?.rate ? (
                    <SvgIcon style={ICON} icon={"star-regular"} preset={"orange"}/>
                  ) : (
                    <SvgIcon style={ICON} icon={"star-regular"} preset={"bgBlue"}/>
                  )}
                </View>
              ))}
            </View>
          </View>
        </View>
      </TouchableOpacity>
    )
  }

  const isApproved = statusItem?.id === -2
  const isShortlisted = statusItem?.id === -1
  const _statusItemIndex = statusItem?.role ? 2 : isApproved ? 0 : isShortlisted ? 1 : 2
  const roleName = (statusItem?.role?.name || '') + ' Applications'
  return (
    <View style={ITEM_CONTAINER}>
      <TouchableOpacity style={VIEW_ITEM} onPress={() => setShow(!show)}>
        <View style={{flexDirection: "row"}}>
          <View style={{justifyContent: 'center'}}>
            <Text preset="h3" style={{color: CountBgStatusList[(_statusItemIndex || 0) % CountBgStatusList.length]}}>
              {statusItem?.applications?.length}/
              {statusItem?.role ? statusItem?.numberOfPositions : statusItem?.applications?.length}
            </Text>
          </View>
          <View style={VIEW_CENTER}>
            <Text preset="h2" style={{color: TitleStatusList[(_statusItemIndex || 0) % TitleStatusList.length]}}>
              {statusItem?.role ? roleName
                : isShortlisted ? "Shortlisted"
                  : isApproved ? "Approved"
                    : roleName}
            </Text>
          </View>
          <View style={ITEM_RIGHT}>
            <SvgIcon style={ICON} icon={show ? "chevron-down" : "chevron-right"}/>
          </View>
        </View>

        {show && (
          <FlatList
            keyExtractor={(item, index) => index.toString()}
            data={statusItem?.applications as Array<IBusinessApplicationModel>}
            renderItem={renderItem}
          />
        )}
      </TouchableOpacity>
    </View>
  )
}

export interface JobStatusListScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'jobStatusListScreen'>
}

export const JobStatusListScreen: FC<JobStatusListScreenProps> = observer(({navigation, route}) => {
  const jobAdId = route.params?.jobAdId
  const showWorkerTab = route.params?.showWorkerTab
  const TAB_RENDER =( showWorkerTab) ? JobStatusListScreenTabs : JobStatusListScreenNoWorkerTabs;

  const {app} = useStores()
  const isManager = app?.user?.role === constants.roles.MANAGER
  const [jobAdvert, setJobAdvert] = useState<IBusinessJobAdvertModel>(null)
  const [refreshing, setRefreshing] = useState(false)
  const [search, setSearch] = useState("")
  const [seeMore, setSeeMore] = useState(false)

  const [activeTab, setActiveTab] = useState(JobStatusListScreenTabs.applications.key)
  const [menuVisible, setMenuVisible] = useState(false)
  const [confirmVisible, setConfirmVisible] = useState(false)
  const [listWorker, setListWorker] = useState([])

  const filteredData = search ? jobAdvert?.jobRoles.filter(item => (
    searchIgnoreCase(item?.role?.name, search)
  )) : jobAdvert?.jobRoles
  const filterJobStatusList = (): Array<IBusinessJobRoleModel> => {
    const _jobRolesDict: any = {}
    filteredData?.forEach((_role) => {
      _role?.applications?.filter(it => it?.status !== ApplicationStatus.viewing)?.forEach((_app) => {
        if (_app.approved) {
          if (!_jobRolesDict.hasOwnProperty('approved')) {
            _jobRolesDict.approved = {
              ...convertBusinessJobRoleModel({id: -2}), role: null
            } as IBusinessJobRoleModel
          }
          _jobRolesDict.approved?.applications?.push(_app)
        } else if (_app.shortlisted) {
          if (!_jobRolesDict.hasOwnProperty('shortlisted')) {
            _jobRolesDict.shortlisted = {
              ...convertBusinessJobRoleModel({id: -1}), role: null
            } as IBusinessJobRoleModel
          }
          _jobRolesDict.shortlisted?.applications?.push(_app)
        }
        if (!_app.approved && !_jobRolesDict.hasOwnProperty(_role.id)) {
          _jobRolesDict[_role.id] = {..._role, applications: []} as IBusinessJobRoleModel
        }
        _jobRolesDict[_role.id]?.applications?.push(_app)
      })
    })
    return Object.values(_jobRolesDict)
  }
  const filteredDataMapped = filterJobStatusList()?.filter(item => item?.applications?.length > 0)
    .sort((a, b) => (b.id < 0 ? a.id < 0 ? (a.id - b.id) : 1 : -1))
  // const filteredDataMapped = filteredData

  const loadData = async () => {
    if (refreshing) return
    setRefreshing(true)
    try {
      if(showWorkerTab){
        getListWorker();
      }
      const res = await app.environment.api.getBusinessJobAdvertDetail(jobAdId)
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message)
        return
      }
      setJobAdvert(res.jobAdvert)
    } catch (e) {
      console.log(e)
    } finally {
      setRefreshing(false)
    }
  }

  useFocusEffect(useCallback(() => {
    if (jobAdId) loadData()
    if (app?.hasSearchJobRole) setSearch(app?.searchJobRoleText)
    app?.setHasSearchJobRole(false)
    // setListItem(mockUsers)
  }, []))

  const renderItem = ({item}: { item: IBusinessJobRoleModel }) => (
    <JobStatusListItem
      navigation={navigation} statusItem={item}
    />
  )

  const getListWorker = async () => {
    try {
      const res = await app.environment.api.getListWorkerJobAds({
        "jobAd": jobAdId,
        "pagination[page]": 1,
        "pagination[pageSize]": DEFAULT_PAGE_LIMIT
      })
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message)
        return
      }
      setListWorker(res.workers);
    } catch (e) {
      console.log(e)
    }
  }

  const handleCloseJobAdPress = async () => {
    setMenuVisible(false)
    setConfirmVisible(true)
  }

  const handleCloseJobAd = async () => {
    if (refreshing) return
    setRefreshing(true)
    try {
      const res = await app.environment.api.editJobAdvertDetail(jobAdId, {
        closed: !jobAdvert.closed,
        businessProfileId: isManager
          ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
      })
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message)
        return
      }
      trackWithUserAccount(TrackEvents.DeleteJobAd, app?.userAccount)
      loadData()
    } catch (e) {
      console.log(e)
    } finally {
      setRefreshing(false)
      navigation.goBack()
    }
  }

  const handleRepostJobAd = async () => {
    if (refreshing) return
    setRefreshing(true)
    try {
      const jobRolesCreated = await Promise.all(jobAdvert.jobRoles.map(async (item) => {
        const createRes = await app.environment.api.createJobAdvertRoleByData(
          {
            // role: item?.role,
            amount: item?.amount,
            numberOfPositions: item?.numberOfPositions,
            startDate: item?.startDate,
            workType: item?.workType,
            ...(item?.role?.id ? {role: [item?.role?.id]} : null),
            ...(item?.contact?.id ? {contact: [item?.contact?.id]} : null),
            locations: item.locations.map(item => item.id),
            industries: item.industries.map(item => item.id),
            experiences: item.experiences.map(item => item.id),
            skills: item.skills.map(item => item.id),
            qualifications: item.qualifications.map(item => item.id)
          }
        )
        return createRes.kind !== 'ok' ? 0 : createRes.jobRole.id
      }))

      const res = await app.environment.api.createJobAdvert({
        title: jobAdvert?.title?.trim(), description: jobAdvert?.description,
        responsibilities: jobAdvert?.responsibilities, isDraft: false, closed: false,
        jobRoles: jobRolesCreated?.filter(it => !!it),
        paymentTypes: jobAdvert.paymentTypes.map(item => item.id),
        businessProfileId: isManager ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
        businessProfile: isManager ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
      })
      if (res.kind === 'ok') {
        app.setJobAdvertCreate(convertJobAdvertModel({}))
      } else {
        AlertModalStore.alert('Error', res.message)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setRefreshing(false)
      navigation.goBack()
    }
  }

  const handleEditJobAd = () => {
    setMenuVisible(false)
    AdvertRoleStore.reset()
    navigation.navigate('jobAdvertEditScreen', {editId: jobAdId})
  }

  const renderTabItem = (it) => {
    const isActive = it.key === activeTab
    return (
        <TabButton
            isActive={isActive}
            value={it.label}
            key={it.key}
            onPress={() => setActiveTab(it.key)}/>
    )
  }

  const renderEmpty = () => {
    if(!refreshing){
      return(
          <View style={styles.emptyStyle}>
            <Text preset={'body2'}
                  style={{color: color.palette.dark}}>
              Looks like there are no applications yet. Please check back later.
            </Text>
          </View>
      )
    }
  }

  const renderListWorker = ({item}: { item: IUserAccountModel }) => {
    return(
        <WorkerCard
            name={item.firstName + item.lastName}
            industry={item.workerProfile.industry?.name}
            radius={item.workerProfile.travelDistance}
            onClick={() => navigation.navigate("jobWorkerProfile", {id: item.id, jobAdId: jobAdId})}
            logo={item?.avatar?.url}
        />
    )
  }

  const renderTabContent = () => {
    if (activeTab === JobStatusListScreenTabs.applications.key) {
      return (
        <View style={MAIN_CONTAINER}>
          <FlatList
            onRefresh={loadData} refreshing={refreshing}
            data={filteredDataMapped as Array<IBusinessJobRoleModel>}
            renderItem={renderItem}
            keyExtractor={(item, index) => index.toString()}
            ListEmptyComponent={renderEmpty}
          />
        </View>
      )
    }

    if(activeTab === JobStatusListScreenTabs.worker.key){
      return (
          <View style={MAIN_CONTAINER}>
            <FlatList
                data={listWorker as Array<IUserAccountModel>}
                renderItem={renderListWorker}
                keyExtractor={(item, index) => item.id.toString()}
            />
          </View>
      )
    }

    const jobAdTitle = jobAdvert?.title || ''
    const jobRole: IBusinessJobRoleModel = jobAdvert?.jobRoles?.length > 0 ? jobAdvert?.jobRoles[0] : convertBusinessJobRoleModel({})
    const jobRoleName = jobRole?.role.name || ''
    const paymentType = jobAdvert?.paymentTypes?.length > 0 ? jobAdvert?.paymentTypes[0]?.name : ''
    const location = jobRole?.locations?.length > 0 ? parseLocation(jobRole?.locations[0]) : ''
    const contactFullName = [jobRole?.contact?.user?.firstName, jobRole?.contact?.user?.lastName]?.join(' ')?.trim()
    return (
      <View style={MAIN_CONTAINER}>
        <ScrollView style={{flex: 1}} showsVerticalScrollIndicator={false}>
          <View style={{paddingHorizontal: 20}}>
            <TouchableOpacity style={{marginTop: 12}}>
              <Text text={jobAdTitle} preset="h1"/>
              <View style={{marginTop: 9}}/>
              <Text text={jobRoleName} preset="h2" style={{color: color.palette.blue, fontSize: 20}}/>
            </TouchableOpacity>
            <View style={{marginTop: 24}}>
              <View style={LINE}>
                <Text preset="h4Dark">Job Ad Description </Text>
              </View>
              <Text preset="body1" style={{marginTop: 8}} numberOfLines={seeMore ? undefined : 3}>
                {jobAdvert?.description}
              </Text>
              <TouchableOpacity style={SEE_ALL} onPress={() => setSeeMore(!seeMore)}>
                <Text
                  text={seeMore ? "See less" : "See more"} preset="body2"
                  style={{fontWeight: 'bold', color: color.palette.blue}}/>
                <SvgIcon icon={seeMore ? "chevron-up" : "chevron-down"} preset={"tiny"} style={{marginLeft: 8}}/>
              </TouchableOpacity>
            </View>
            <View style={{marginTop: 24}}>
              <View style={LINE}>
                <Text preset="h4Dark">Responsibilites</Text>
              </View>
              <Text preset="body1" style={{marginTop: 8}}>
                {jobAdvert?.responsibilities}
              </Text>
            </View>

            <View style={{marginTop: 24}}>
              <View style={LINE}>
                <Text preset="h4Dark">Job Details</Text>
              </View>
              <View style={{marginTop: 8, flexDirection: "row", flexWrap: 'wrap'}}>
                <Tag
                  icon="user"
                  children={String(jobRole?.numberOfPositions)}
                  textPreset="body" textStyle={{color: color.palette.dark}}
                  preset="smaller"
                  containerStyle={{marginBottom: 10, marginRight: 4}}
                />
                <Tag
                  preset="smaller"
                  icon="calendar-day"
                  children={stringToDateStr(jobRole?.startDate)}
                  textPreset={'body'} textStyle={{color: color.palette.dark}}
                  containerStyle={{marginBottom: 10, marginRight: 4}}
                />
                <Tag
                  preset="iconSmall"
                  icon="pen-field"
                  children={jobRole?.workType}
                  textPreset={'body'} textStyle={{color: color.palette.dark}}
                  containerStyle={{marginBottom: 10, marginRight: 4}}
                />
                <Tag
                  icon="money-check-dollar"
                  preset={"iconSmall"}
                  children={`${paymentType} ${jobRole?.amount}`}
                  textPreset="body" textStyle={{color: color.palette.dark}}
                  containerStyle={{marginBottom: 10, marginRight: 4}}
                />
                <Tag
                  icon="map-marker-alt"
                  children={location}
                  textPreset="body" textStyle={{color: color.palette.dark}}
                  preset="smaller"
                  containerStyle={{marginBottom: 10, marginRight: 4}}
                />
              </View>
            </View>

            <View style={{marginTop: 24}}>
              <View style={LINE}>
                <Text preset="h4Dark">Experience</Text>
              </View>
              <Text
                preset="body2Grey"
                style={{color: color.palette.greyHigh, marginTop: 8}}
              >
                Tick all the boxes that apply to you. This will appear in your application.
              </Text>
              <View style={{marginTop: spacing[4]}}>
                <View style={{flexDirection: "row", flexWrap: 'wrap'}}>
                  {jobRole?.experiences?.map((item, index) => (
                    <View key={index.toString()} style={styles.checkboxContainer}>
                      <Text text={item?.name}/>
                    </View>
                  ))}
                </View>
              </View>
            </View>

            <View style={{marginTop: 24}}>
              <View style={LINE}>
                <Text preset="h4Dark">Skills</Text>
              </View>
              <Text
                preset="body2Grey"
                style={{color: color.palette.greyHigh, marginTop: 8}}
              >
                Tick all the boxes that apply to you. This will appear in your application.
              </Text>
              <View style={{marginTop: 16}}>
                <View style={{flexDirection: "row", flexWrap: 'wrap'}}>
                  {jobRole?.skills?.map((item, index) => (
                    <View key={index.toString()} style={styles.checkboxContainer}>
                      <Text text={getUserSkillStr(item)}/>
                    </View>
                  ))}
                </View>
              </View>
            </View>

            <View style={{marginTop: 24}}>
              <View style={LINE}>
                <Text preset="h4Dark">Qualification</Text>
              </View>
              <Text
                preset="body2Grey"
                style={{color: color.palette.greyHigh, marginTop: 8}}
              >
                Add all the qualifications that apply to you. This will appear in your application.
              </Text>
              <View style={{marginTop: 16}}>
                <View style={{flexDirection: "row", flexWrap: 'wrap'}}>
                  {jobRole?.qualifications?.map((item, index) => (
                    <View key={index.toString()} style={styles.checkboxContainer}>
                      <Text text={getUserQualificationStr(item)}/>
                    </View>
                  ))}
                </View>
              </View>
            </View>
            <View style={{marginTop: 24}}>
              <View style={LINE}>
                <Text preset="h4Dark">Contact</Text>
              </View>
              <View style={{marginTop: 16}}>
                <Text text={contactFullName}/>
              </View>
            </View>
            <View style={{height: 100}}/>
          </View>
        </ScrollView>
      </View>
    )
  }

  return (
    <SafeAreaView style={CONTAINER}>
      <GradientBackground colors={[color.palette.gradientStart, color.palette.gradientStop]}/>
      <Header
        preset={"bgWhite"}
        headerText={jobAdvert?.title}
        titlePreset={"headerTitle"}
        leftIcon={"arrow-left"} leftIconPreset={"normal"} onLeftPress={() => navigation.goBack()}
        rightIcon={ activeTab === JobStatusListScreenTabs.jobAd.key ? "ellipsis-h" : ""}
        rightIconTheme={"solid"}
        rightIconPreset={"smallNoMargin"}
        onRightPress={() => activeTab === JobStatusListScreenTabs.jobAd.key ? setMenuVisible(true) : () =>{}}
      />
      <View style={{
        flexDirection: "row", marginHorizontal: 20, marginVertical: 20,
        alignItems: 'flex-end', justifyContent: 'flex-start'
      }}>
        <ScrollView
            showsHorizontalScrollIndicator={false}
            horizontal>
          {Object.values(TAB_RENDER).map(renderTabItem)}
        </ScrollView>
      </View>
      {renderTabContent()}
      <BottomModal visible={menuVisible} onClose={() => setMenuVisible(false)}>
        <View>
          <Button
            preset={'brand'}
            style={MODAL_BTN}
            disabled={false}
            onPress={handleEditJobAd}
            text={"Edit"}/>
          <Button
            preset={'brandBlue'}
            style={MODAL_BTN}
            disabled={false}
            onPress={handleCloseJobAdPress}
            text={jobAdvert?.closed ? "Open Job Ad" : "Close Job Ad"}
          />
          <Button
            preset={'brandOutLine'}
            style={MODAL_BTN}
            disabled={false}
            onPress={handleRepostJobAd}
            text={"Repost Job Ad"}/>
          <Button
            style={MODAL_BTN}
            disabled={false}
            onPress={() => setMenuVisible(false)}
            preset={"brandOutLineBlue"}
            textPreset={"secondary"}
            text={"Cancel"}
          />
        </View>
      </BottomModal>
      <BackdropModal visible={confirmVisible} onClose={() => setConfirmVisible(false)}>
        <View>
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              padding: 10,
            }}
          >
            <SvgIcon iconStyle={{width: 80, height: 80}} preset={"alertIcon"}/>
            <View style={{paddingTop: 18, paddingBottom: 8}}>
              <Text preset={"alertTitle"}>Are you sure?</Text>
            </View>
          </View>
          <Button
            preset={'brand'}
            style={MODAL_BTN} disabled={false}
            onPress={handleCloseJobAd}
            text={"Yes"}/>
          <Button
            onPress={() => setConfirmVisible(false)}
            style={MODAL_BTN}
            disabled={false}
            preset={"brandOutLine"}
            text={"Cancel"}
          />
        </View>
      </BackdropModal>
    </SafeAreaView>
  )
})

export const CONTAINER: ViewStyle = {
  flex: 1,
}

export const MAIN_CONTAINER: ViewStyle = {
  flex: 1,
}

export const ICON: ImageStyle = {
  paddingHorizontal: 3,
}

export const MODAL_BTN: ViewStyle = {
  marginVertical: 8,
}

export const ITEM_CONTAINER: ViewStyle = {
  marginVertical: 10,
  marginHorizontal: 20,
}

export const VIEW_ITEM: ViewStyle = {
  borderRadius: 8,
  shadowColor: "rgba(2, 41, 100, 0.05)",
  shadowOffset: {width: 5, height: 5},
  shadowOpacity: 1.5,
  shadowRadius: 3,
  paddingHorizontal: 15,
  paddingVertical: 20,
}

export const ITEM_RIGHT: ViewStyle = {
  alignItems: "flex-end",
  justifyContent: "center",
}

export const VIEW_CENTER: ViewStyle = {
  flex: 1,
  marginLeft: 20,
}

export const ITEM_SHOW: ViewStyle = {
  flexDirection: "row",
  paddingVertical: 15,
  justifyContent: "space-between",
  alignItems: "center",
  borderBottomWidth: 1,
  borderBottomColor: color.palette.grey,
}

export const ITEM_SHOW_LAST: ViewStyle = {
  flexDirection: "row",
  paddingVertical: 15,
  justifyContent: "space-between",
  alignItems: "center",
}
const styles = StyleSheet.create({
  checkboxContainer: {
    flex: 1,
    flexBasis: '50%',
    marginBottom: 10,
    overflow: 'hidden',
    paddingRight: 20,
    paddingLeft: 4,
  },
  emptyStyle:{
    paddingHorizontal: 20,
    marginTop: 20,
  }
})
