import * as React from "react"
import {StyleSheet, TouchableOpacity, View} from "react-native"
import {color} from "../../theme"
import {SvgIconPresets, ThemeTypes} from "../icon/svg-icon.props"
import {SelectTextFieldPresets} from "../text-field/select-text-field"
import {ReactNode} from "react"
import {SvgIcon} from "../icon/svg-icon"
import {Text} from "../index"
import {IKvItemModel} from "../../models/app/kv-item";

const BASE = {
  borderRadius: 10,
  backgroundColor: color.palette.white,
  shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 3,
  },
  shadowOpacity: 0.07,
  shadowRadius: 4.65,
  elevation: 6,
  boxShadow: '0px 0px 16px rgba(2, 41, 100, 0.05)',
}
export const ListJobPresets = {
  default: {...BASE},
  lightLink: {},
  itemHeader: {
    ...BASE,
    paddingVertical: 4,
  },
}

export type ListJobPresetsType = keyof typeof ListJobPresets

export interface DivItemProps {
  icon: string
  theme: ThemeTypes
  preset: SvgIconPresets
}

export interface ListJobProps {
  header?: ReactNode
  preset?: ListJobPresetsType
  selectPreset?: SelectTextFieldPresets
  onPress: (index: any) => void
  onItemPress?: (id: any) => void
  itemValue: (item: IKvItemModel) => string
  renderIcon?: (index) => DivItemProps
  data: Array<IKvItemModel>
}

export const ListJobTag = (props: ListJobProps) => {
  const {header, preset, onPress, onItemPress, data, itemValue} = props

  const renderIconItem = (item: IKvItemModel, index) => {
    return (
      <View
        key={`${item.id}-${index}`}
        style={{
          margin: 10,
          backgroundColor: color.palette.greyLight,
          padding: 3, borderRadius: 6,
          flexDirection: "row", alignItems: "center",
        }}
      >
        <TouchableOpacity
          onPress={() => onPress(item.id)}
          style={{
            backgroundColor: color.palette.white,
            padding: 5, marginRight: 8, borderRadius: 5,
          }}
        >
          <SvgIcon icon="times" preset={"dark"}/>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => onItemPress(item.id)} style={{marginRight: 4, maxWidth: '90%'}}>
          <Text>{itemValue(item)}</Text>
        </TouchableOpacity>
      </View>
    )
  }
  return (
    <View style={ListJobPresets[preset] || ListJobPresets.default}>
      {header && (
        <View>
          {header}
          {data.length > 0 && <View style={styles.div}/>}
        </View>
      )}
      {data.length > 0 && (
        <View style={{
          borderTopColor: color.palette.grey3,
          borderTopWidth: 1,
          flex: 1, flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap',
          paddingVertical: 10
        }}>
          {data.length > 0 ? data.map(renderIconItem) : <View style={{height: 24}}/>}
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  div: {backgroundColor: color.palette.white, height: 1, marginHorizontal: 16},
})
