import React, {FC, useEffect, useState} from "react"
import {View, StyleSheet, SafeAreaView, ScrollView, TouchableOpacity} from "react-native"

import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {observer} from "mobx-react-lite"
import {color, spacing} from "../../../theme"
import {Button, GradientBackground, Header, Text} from "../../../components"
import {SvgIcon} from "../../../components/icon/svg-icon"
import {ListJobTag} from "../../../components/list-div/list-job-tag"
import {useStores} from "../../../models"
import {IKvItemModel} from "../../../models/app/kv-item";
import {IWorkerSkillGroup, WorkerSkillList, WorkerSkillType} from "../../../models/app/worker-profile";
import {NavigatorParamList} from "../../../navigators";
import {BackdropModal} from "../../../components/backdrop-modal/backdrop-modal";
import {CommonActions, RouteProp} from "@react-navigation/native";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import {CreateJobTags} from "../../../models/app/job-advert";
import {JobTagRow} from "../../../components/tag/job-tag-row";
import {SelectTextField} from "../../../components/text-field/select-text-field";

export interface SkillContainerProps {
  items: IKvItemModel[]
  onRemovePress: (item: IKvItemModel) => void
  onEditPress: (item: IKvItemModel) => void
  onAddPress: () => void
  label: string
  description: string
  hint: string
}

const SkillContainer: FC<SkillContainerProps> = (props) => {
  const {items, onRemovePress, onEditPress, onAddPress, label, description, hint} = props
  return (
    <View style={{marginBottom: 20}}>
      <View style={{paddingBottom: 8}}>
        <Text preset="h4Dark">{label}</Text>
      </View>
      <Text preset="body2" style={{color: color.palette.dark, marginTop: 0}}>{description}</Text>
      <View style={{marginTop: 4}}>
        <Text preset="body2" style={{color: color.palette.lightGrey, fontStyle: 'italic'}}>{hint}</Text>
      </View>
      <View style={{marginTop: 0, backgroundColor: color.palette.white}}>
        <View style={{flexDirection: "row", flexWrap: 'wrap', alignItems: 'flex-start'}}>
          {items?.map((item) => (
            <JobTagRow
              key={String(item?.id)} text={item.name} tagStyle={{marginBottom: 0}}
              onPress={() => onRemovePress(item)}
              onItemPress={() => onEditPress(item)}
            />
          ))}
        </View>
        <View style={{marginTop: 6, flexDirection: 'row', alignItems: 'flex-start'}}>
          <TouchableOpacity
            onPress={onAddPress}
            style={{
              flexDirection: 'row', alignItems: 'center', borderRadius: 6,
              paddingVertical: 6, paddingRight: 12, borderWidth: 1, borderColor: color.palette.primary
            }}>
            <View style={{paddingLeft: 6, paddingRight: 8}}>
              <SvgIcon icon={'plus'} preset={'primary'}/>
            </View>
            <Text style={{fontWeight: '500', color: color.palette.primary}}>Add</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
}

export interface RoleGroupCardProps {
  item: IWorkerSkillGroup
  headerPress: (item: IWorkerSkillGroup) => void
  onEditItem?: (id: any) => void
}

export const RoleGroupCard: FC<RoleGroupCardProps> = observer(({item, headerPress, onEditItem}) => {
  const {app} = useStores()
  const workerProfile = app?.userAccount?.workerProfile
  const items: Array<IKvItemModel> = workerProfile[item.id]?.toJSON() || []
  const _headerPress = () => headerPress(item)

  return (
    <View style={{marginBottom: 18}}>
      <ListJobTag
        header={
          <View style={styles.require}>
            <View style={{marginHorizontal: 20, marginVertical: 20}}>
              <Text preset={"mediumBoldBlue"}>{item.text}</Text>
            </View>
            <TouchableOpacity
              onPress={_headerPress}
              style={{flex: 1, alignItems: "flex-end", marginRight: 15}}
            >
              <View style={{backgroundColor: color.palette.orange, borderRadius: 30, padding: 1}}>
                <SvgIcon preset={'tinyLight'} icon="plus" style={{padding: spacing[1]}}/>
              </View>
            </TouchableOpacity>
          </View>
        }
        data={items}
        itemValue={(_item) => _item.name}
        renderIcon={() => ({icon: "times-circle", theme: "regular", preset: "default"})}
        onPress={(id) => app.toggleSelectWorkerSkillIndex(item.id, id)}
        onItemPress={onEditItem}
      />
    </View>
  )
})

export interface WorkerSkillDetailScreenProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
  route: RouteProp<NavigatorParamList, "workerSkillEditScreen">
}

let timeoutVal: NodeJS.Timeout = null

export const WorkerSkillEditScreen: FC<WorkerSkillDetailScreenProps> = observer(
  ({navigation, route}) => {
    const {app} = useStores()
    const workerProfile = app?.userAccount?.workerProfile
    const [loading, setLoading] = useState(false)
    const [resultVisible, setResultVisible] = useState(false);

    const loadData = async () => {
      if (loading) return
      setLoading(true)
      try {
        const res = await app.getMe()
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    useEffect(() => {
      loadData()
    }, [])

    const handleSaveSkill = async () => {
      setLoading(true)
      try {
        const workerProfile = app?.userAccount?.workerProfile
        const updateRes = await app.environment.api.updateWorkerProfileData(workerProfile?.id, {
          industries: workerProfile?.industries?.map((item) => item?.id) || [],
          workerRoles: workerProfile?.workerRoles?.map((item) => item?.id) || [],
          experiences: workerProfile?.experiences?.map((item) => item?.id) || [],
          skills: workerProfile?.skills?.map((item) => item?.id) || [],
          qualifications: workerProfile?.qualifications?.map((item) => item?.id) || [],
        })
        if (updateRes.kind !== 'ok') {
          AlertModalStore.alert('Error', updateRes.message)
          return
        }
        await app.getMe()
        showResult()
      } catch (e) {
        console.warn(e)
      } finally {
        setLoading(false)
      }
    }

    const onDone = () => {
      setResultVisible(false)
      if (route?.params?.isCreate) {
        navigation.dispatch(
          CommonActions.reset({
            index: 0, routes: [{name: 'main'}],
          })
        )
      } else navigation.goBack()
    }

    const handleCloseResult = async () => {
      if (timeoutVal) clearTimeout(timeoutVal)
      onDone()
    }

    const showResult = () => {
      if (timeoutVal) clearTimeout(timeoutVal)
      setResultVisible(true)
      timeoutVal = setTimeout(handleCloseResult, 1000)
    }

    const onEditItem = (id: any, key: string) => {
      switch (key) {
        case WorkerSkillList.skills.id:
          navigation.navigate('workerAddSkillScreen', {originId: id})
          break
        case WorkerSkillList.qualifications.id:
          navigation.navigate('workerAddQualificationScreen', {originId: id})
          break
      }
    }

    const onAddItem = (key: string) => {
      switch (key) {
        case WorkerSkillList.skills.id:
          navigation.navigate('workerUploadDocumentScreen')
          break
        case WorkerSkillList.qualifications.id:
          navigation.navigate('workerUploadDocumentScreen')
          break
        default:
          navigation.navigate("workerSkillDetailScreen", {tag: key as WorkerSkillType})
      }
    }

    return (
      <>
        <PureBottomModal onClose={() => navigation.goBack()}>
          <SafeAreaView style={styles.container}>
            <View style={{}}>
              <Header
                preset={"modalHeader"}
                titlePreset={"headerTitle"}
                headerText={"Skills"}
                rightIcon={"times"}
                rightIconPreset={"smallBlue"}
                onRightPress={() => navigation.goBack()}
              />
            </View>
            <View style={{flex: 1}}>
              <GradientBackground
                colors={[color.palette.gradientStart, color.palette.gradientStop]}
              />
              <ScrollView style={styles.body} showsVerticalScrollIndicator={false}>
                <View style={{marginBottom: 100, marginHorizontal: 10}}>
                  <View style={{marginBottom: 20}}>
                    <SelectTextField
                      onPress={() => onAddItem(WorkerSkillList.industries.id)}
                      preset={"brand"} value={null}
                      rightIcon={"chevron-right"}
                      placeholder={WorkerSkillList.industries.hint} label={'Industry(s)'} labelPreset={"formLabel"}
                    />
                    <View style={{minHeight: 48, flex: 1}}>
                      {workerProfile?.industries?.map((item) => (
                        <JobTagRow
                          key={String(item?.id)}
                          text={item.name} tagStyle={{marginBottom: 0}}
                          onPress={() => app.toggleSelectWorkerSkillIndex(WorkerSkillList.industries.id, item.id)}/>
                      ))}
                    </View>
                  </View>
                  <View style={{marginBottom: 20}}>
                    <SelectTextField
                      onPress={() => onAddItem(WorkerSkillList.workerRoles.id)}
                      preset={"brand"} value={null}
                      placeholder={WorkerSkillList.workerRoles.hint} label={'Role(s)'}
                      labelPreset={"formLabel"}
                    />
                    <View style={{minHeight: 48, flex: 1}}>
                      {workerProfile?.workerRoles?.map((item) => (
                        <JobTagRow
                          key={String(item?.id)}
                          text={item.name} tagStyle={{marginBottom: 0}}
                          onPress={() => app.toggleSelectWorkerSkillIndex(WorkerSkillList.workerRoles.id, item.id)}/>
                      ))}
                    </View>
                  </View>
                  <SkillContainer
                    label={CreateJobTags.qualifications.text}
                    description={WorkerSkillList.qualifications.label}
                    hint={CreateJobTags.qualifications.hint}
                    items={workerProfile.qualifications?.toJSON()}
                    onAddPress={() => onAddItem(WorkerSkillList.qualifications.id)}
                    onEditPress={(item) => onEditItem(item.id, WorkerSkillList.qualifications.id)}
                    onRemovePress={(item) => app.toggleSelectWorkerSkillIndex(WorkerSkillList.qualifications.id, item.id)}
                  />
                  <SkillContainer
                    label={WorkerSkillList.skills.text}
                    description={WorkerSkillList.skills.label}
                    hint={WorkerSkillList.skills.hint}
                    items={workerProfile.skills?.toJSON()}
                    onAddPress={() => onAddItem(WorkerSkillList.skills.id)}
                    onEditPress={(item) => onEditItem(item.id, WorkerSkillList.skills.id)}
                    onRemovePress={(item) => app.toggleSelectWorkerSkillIndex(WorkerSkillList.skills.id, item.id)}
                  />
                  <SkillContainer
                    label={WorkerSkillList.experiences.text}
                    description={WorkerSkillList.experiences.label}
                    hint={WorkerSkillList.experiences.hint}
                    items={workerProfile.experiences?.toJSON()}
                    onAddPress={() => onAddItem(WorkerSkillList.experiences.id)}
                    onEditPress={(item) => onEditItem(item.id, WorkerSkillList.experiences.id)}
                    onRemovePress={(item) => app.toggleSelectWorkerSkillIndex(WorkerSkillList.experiences.id, item.id)}
                  />
                </View>
              </ScrollView>
            </View>
            <View style={styles.bottomContainer}>
              <Button onPress={handleSaveSkill} preset={"brand"} disabled={loading}>
                <Text preset={"whiteBold"}>Done</Text>
              </Button>
            </View>
            <BackdropModal visible={resultVisible} onClose={onDone}>
              <View>
                <View style={{alignItems: "center", justifyContent: 'center', padding: 10,}}>
                  <SvgIcon icon={'check-circle'} preset={'alertIcon'}/>
                  <View style={{paddingTop: 18, paddingBottom: 8}}>
                    <Text preset={'alertTitle'}>Saved</Text>
                  </View>
                </View>
              </View>
            </BackdropModal>
          </SafeAreaView>
        </PureBottomModal>
        <AlertModal/>
      </>
    )
  },
)

const styles = StyleSheet.create({
  body: {
    backgroundColor: color.palette.white,
    flex: 1,
    paddingHorizontal: 10,
  },
  bottomContainer: {
    backgroundColor: color.palette.white,
    borderTopColor: color.palette.blueLine,
    borderTopWidth: 2,
    bottom: 0,
    paddingBottom: 16,
    paddingHorizontal: 20,
    paddingTop: 16,
    position: "absolute",
    width: "100%",
  },
  container: {
    flex: 1,
  },
  require: {
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 6,
    flexDirection: "row"
  },
})
