import React from 'react';
import {
  View,
  StyleProp,
  ViewStyle, Dimensions, Animated, Pressable, Easing
} from 'react-native';
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {isAndroid} from "../../utils/platform";
import {color} from "../../theme";
import {isDesktop} from "../../utils/global";

const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

export interface PureBottomModalProps {
  style?: StyleProp<ViewStyle>
  onClose: () => void
  marginTop?: number
  children: React.ReactNode
  isStatic?: boolean
}

export function PureBottomModal(props: PureBottomModalProps) {
  const {style, onClose, marginTop, children, isStatic} = props;
  const insets = useSafeAreaInsets()

  const anim = React.useRef(new Animated.Value(0))
  const popupHeight = React.useRef(Dimensions.get("window").height)
  const slideY = anim.current.interpolate({
    inputRange: [0, 1],
    outputRange: [popupHeight.current, 0],
  })

  React.useEffect(() => {
    if (isStatic) return
    Animated.timing(anim.current, {
      toValue: 1,
      duration: 400,
      easing: Easing.elastic(0.8),
      useNativeDriver: true,
    }).start()
  }, [])

  return (
    <View style={{position: "fixed", left: 0, right: 0, top: 0, bottom: 0}}>
      <View
        style={{
          position: "absolute", left: 0, right: 0, top: 0, bottom: 0, backgroundColor: "black",
          opacity: 0.16
        }}>
        <Pressable style={{flex: 1}} onPress={onClose}/>
      </View>

      <Animated.View
        style={[{
          flex: 1, marginTop: insets.top + (marginTop ?? isAndroid ? 50 : 10),
          height: windowHeight - insets.top - (marginTop ?? isAndroid ? 50 : 10),
          backgroundColor: '#FFFFFF', borderTopRightRadius: 20, borderTopLeftRadius: 20, paddingTop: 20,
          ...(isDesktop ? {width: windowWidth > 768 ? 768 : '100%', margin: 'auto'} : null),
          ...(!isStatic ? {transform: [{translateY: slideY}]} : null)
        }, style]}>
        {children}
      </Animated.View>
    </View>
  );
}

export interface PureBottomAutoModalProps {
  style?: StyleProp<ViewStyle>
  onClose: () => void
  marginTop?: number
  children: React.ReactNode
  isStatic?: boolean
}

export function PureBottomAutoModal(props: PureBottomAutoModalProps) {
  const {style, onClose, children, isStatic} = props;

  const anim = React.useRef(new Animated.Value(0))
  const popupHeight = React.useRef(Dimensions.get("window").height)
  const slideY = anim.current.interpolate({
    inputRange: [0, 1],
    outputRange: [popupHeight.current, 0],
  })

  React.useEffect(() => {
    if (isStatic) return
    Animated.timing(anim.current, {
      toValue: 1,
      duration: 400,
      easing: Easing.elastic(0.8),
      useNativeDriver: true,
    }).start()
  }, [])

  return (
    <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
      <View
        style={{
          position: "absolute", left: 0, right: 0, top: 0, bottom: 0, backgroundColor: "black",
          opacity: 0.16
        }}>
        <Pressable style={{flex: 1}} onPress={onClose}/>
      </View>

      <Animated.View
        style={{width: '82%', ...(!isStatic ? {transform: [{translateY: slideY}]} : null)}}>
        <View style={[
          {backgroundColor: color.palette.white, borderRadius: 10, paddingHorizontal: 20, paddingVertical: 40},
          style
        ]}>
          {children}
        </View>
      </Animated.View>
    </View>
  );
}
