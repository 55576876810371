import React, { FC, useEffect, useState } from "react"
import { View, SafeAreaView, FlatList } from "react-native"
import { observer } from "mobx-react-lite"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { Header, GradientBackground, Button } from "../../../components"
import { color } from "../../../theme"
import { useStores } from "../../../models";
import { NavigatorParamList } from "../../../navigators";
import { AlertModalStore } from "../../../models/app/alert-modal-store";
import { PureBottomModal } from "../../../components/backdrop-modal/pure-bottom-modal";
import { isAndroid } from "../../../utils/platform";
import { constants } from "../../../utils/constants";
import { IBusinessGroupModel } from "../../../models/app/contact";
import { GroupItem } from "../contacts/groups-item";

export interface UseExistingContactScreenProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
}

export const UseExistingContactScreen: FC<UseExistingContactScreenProps> = observer(
  ({navigation}) => {
    const {app} = useStores();
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<Array<IBusinessGroupModel>>([]);
    const [selectedItem, setSelectedItem] = useState<IBusinessGroupModel>();

    const loadData = async () => {
      setLoading(true)
      try {
        const isManager = app?.user?.role === constants.roles.MANAGER
        const res = await app.environment.api.getGroups({
          'filters[businessProfile][id][$eq]': isManager
            ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          'businessProfileId': isManager
            ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          'filters[save][$null]': false,
          'filters[save][$eq]': true,
          populate: "businessProfile,contacts,contacts.user,contacts.user.avatar",
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        setData(res?.items)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }
    useEffect(() => {
      loadData()
    }, [])

    const toggleSelectItem = (id) => {
      setSelectedItem(selectedItem?.id === id ? null : data?.find(item => item?.id === id))
      const _selectedItem = data?.find(item => item?.id === id)
      app.setListSelectedContact(_selectedItem?.contacts?.map((item) => {
        const fullName = [item?.user?.firstName, item?.user?.lastName]?.join(' ')?.trim()
        return ({
          id: item?.id, name: fullName || item?.user?.email,
          checked: true,
          avatar: item?.user?.avatar?.url || ''
        })
      }))
      navigation.goBack()
    }

    const onDone = () => {
      navigation.goBack()
    }

    const renderItem = ({item}: { item: IBusinessGroupModel }) => (
      <GroupItem
        item={item}
        onPress={() => toggleSelectItem(item.id)}/>
    )

    const showBtnDone = !!selectedItem?.id
    return (
      <PureBottomModal onClose={() => navigation.goBack()}>
        <SafeAreaView style={{flex: 1, backgroundColor: color.palette.white,}}>
          <GradientBackground
            colors={[color.palette.gradientStart, color.palette.gradientStop]}
          />
          <Header
            preset={"modalHeader"}
            titlePreset={"headerTitle"}
            headerText={"Use Existing Contact(s)"}
            rightIcon={"times"}
            rightIconPreset={"smallBlue"}
            onRightPress={() => navigation.goBack()}
          />
          <FlatList
            refreshing={loading} onRefresh={loadData}
            data={data} renderItem={renderItem} keyExtractor={item => String(item?.id)}
            style={{flex: 1}}
            contentContainerStyle={{paddingHorizontal: 20, marginTop: 16, paddingBottom: 16}}
          />
          <View style={{paddingHorizontal: 20}}>
            <View style={{display: showBtnDone ? 'flex' : 'none', paddingTop: 10, marginBottom: isAndroid ? 30 : 5}}>
              <Button preset={"brand"} onPress={onDone} text={'Done'}/>
            </View>
          </View>
        </SafeAreaView>
      </PureBottomModal>
    )
  },
)

