import {Instance, types} from "mobx-state-tree"
import {convertFileUploadModel, FileUploadModel} from "./file-upload";
import {convertKvItemModel, KvItemModel} from "./kv-item";
import {convertJobAdvertModel, JobAdvertModel} from "./job-advert";
import {
  convertUserQualificationModel,
  convertUserSkillModel,
  UserQualificationModel,
  UserSkillModel
} from "./advanced-skill";
import {BusinessContactModel, convertBusinessContactModel} from "./contact";
import {convertJobLocationModel, JobLocationModel} from "./location";

export const JobRoleAdModel = types.model({
  id: types.optional(types.number, 0),
  title: types.optional(types.string, ''),
  description: types.optional(types.string, ''),
  payType: types.optional(types.string, ''),
  amount: types.optional(types.string, ''),
  rate: types.optional(types.number, 0),
  numberOfPositions: types.optional(types.number, 0),
  startDate: types.optional(types.string, ''),
  postedDate: types.optional(types.string, ''),
  workType: types.optional(types.string, ''),
  locations: types.optional(types.array(JobLocationModel), []),
  industries: types.optional(types.array(KvItemModel), []),
  role: types.optional(KvItemModel, {}),
  experiences: types.optional(types.array(KvItemModel), []),
  skills: types.optional(types.array(UserSkillModel), []),
  qualifications: types.optional(types.array(UserQualificationModel), []),
  myCv: types.optional(FileUploadModel, {}),
  jobAd: types.optional(JobAdvertModel, {}),
  publishedAt: types.optional(types.string, ''),
  createdAt: types.optional(types.string, ''),
  contact: types.optional(BusinessContactModel, {}),
  newChatMessages: types.optional(types.number, 0),
  newApplicants: types.optional(types.number, 0),
  showWorkerTab: types.maybeNull(types.boolean),
})
export type IJobRoleAdModel = Instance<typeof JobRoleAdModel>
export const convertJobRoleAdModel = (raw): IJobRoleAdModel => ({
  id: raw?.id || 0,
  title: raw?.title || '',
  description: raw?.description || '',
  payType: raw?.payType || '',
  amount: raw?.amount || '',
  rate: raw?.rate || 0,
  numberOfPositions: raw?.numberOfPositions || 0,
  startDate: raw?.startDate || '',
  postedDate: raw?.postedDate || '',
  workType: raw?.workType || '',
  locations: raw?.locations?.data?.map((item) => convertJobLocationModel({...item?.attributes, id: item?.id})) || [],
  industries: raw?.industries?.data?.map((item) => convertKvItemModel({...item?.attributes, id: item?.id})) || [],
  role: convertKvItemModel({...raw?.role?.data?.attributes, id: raw?.role?.data?.id}),
  experiences: raw?.experiences?.data?.map((item) => convertKvItemModel({...item?.attributes, id: item?.id})) || [],
  skills: raw?.skills?.data?.map((item) => convertUserSkillModel({...item?.attributes, id: item?.id})) || [],
  qualifications: raw?.qualifications?.data?.map((item) => convertUserQualificationModel({
    ...item?.attributes, id: item?.id
  })) || [],
  myCv: convertFileUploadModel(raw?.myCv),
  jobAd: convertJobAdvertModel({...raw?.jobAd?.data?.attributes, id: raw?.jobAd?.data?.id}),
  publishedAt: raw?.publishedAt || '',
  createdAt: raw?.createdAt || '',
  contact: convertBusinessContactModel({...raw?.contact?.data?.attributes, id: raw?.contact?.data?.id}),
  newChatMessages: raw?.newChatMessages || 0,
  newApplicants: raw?.newApplicants || 0,
  showWorkerTab: raw?.showWorkerTab || false,
})
