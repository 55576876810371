import {ViewStyle} from "react-native"
import {color} from "../../../theme"

export const STATUS_BAR: ViewStyle = {
  backgroundColor: color.palette.white,
}

export const CONTAINER: ViewStyle = {
  flex: 1,
}

export const VIEW_CONTAINER: ViewStyle = {
  marginHorizontal: 20,

}

export const HEADER: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
}

export const RIGTH_HEADER: ViewStyle = {
  flex: 1,
  alignItems: "flex-end",
  marginTop: 20,
}

export const VIEW_CONTAIN_BOTTOM: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
}

export const MODAL: ViewStyle = {
  alignItems: "center",
  justifyContent: "center",
  padding: 10,
}

export const VIEW_MODAL: ViewStyle = {
  paddingTop: 18, paddingBottom: 8
}

export const SEE_ALL: ViewStyle = {
  flexDirection: "row",
  alignItems: 'center',
  marginTop: 10
}

export const LINE: ViewStyle = {
  borderBottomWidth: 2,
  borderColor: color.palette.bgBlue,
  paddingBottom: 10
}
