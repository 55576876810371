import {ViewStyle, TextStyle} from "react-native"
import {color} from "../../theme"
import fonts from "../../theme/base_fonts";

/**
 * All text will start off looking like this.
 */
const BASE_VIEW: ViewStyle = {
  paddingVertical: 18,
  paddingHorizontal: 8,
  borderRadius: 10,
  justifyContent: "center",
  alignItems: "center",
}

const BASE_TEXT: TextStyle = {
  ...fonts.style.button,
  paddingHorizontal: 12,
  color: color.palette.white,
}

/**
 * All the variations of text styling within the app.
 *
 * You want to customize these to whatever you need in your app.
 */
export const viewPresetsObject = {
  primary: {...BASE_VIEW, backgroundColor: color.palette.blue} as ViewStyle,
  success: {...BASE_VIEW, backgroundColor: color.palette.green} as ViewStyle,
  successOutline: {
    ...BASE_VIEW,
    borderWidth: 2, borderRadius: 8, borderColor: color.palette.green
  } as ViewStyle,
  successFill: {
    ...BASE_VIEW,
    borderWidth: 2, borderRadius: 8, borderColor: color.palette.green, backgroundColor: color.palette.green
  } as ViewStyle,
  infoOutline: {
    ...BASE_VIEW,
    borderWidth: 2, borderRadius: 8, borderColor: color.palette.blue
  } as ViewStyle,
  infoFill: {
    ...BASE_VIEW,
    borderWidth: 2, borderRadius: 8, borderColor: color.palette.blue, backgroundColor: color.palette.blue
  } as ViewStyle,
  primaryRound: {
    ...BASE_VIEW, borderRadius: 30, paddingHorizontal: 20,
    backgroundColor: color.palette.blue
  } as ViewStyle,
  primaryOpacity: {...BASE_VIEW, backgroundColor: color.palette.primary15} as ViewStyle,
  primaryOutLine: {
    ...BASE_VIEW,
    backgroundColor: color.palette.white,
    borderColor: color.palette.primary,
    borderWidth: 2,
  } as ViewStyle,
  brand: {...BASE_VIEW, backgroundColor: color.palette.orange} as ViewStyle,
  brandDanger: {...BASE_VIEW, backgroundColor: color.palette.red} as ViewStyle,
  brandDangerOutLine: {
    ...BASE_VIEW, backgroundColor: color.palette.white,
    borderColor: color.palette.red,
    borderWidth: 2,
  } as ViewStyle,
  brandSm: {...BASE_VIEW, paddingHorizontal: 0, backgroundColor: color.palette.orange} as ViewStyle,
  brandPink: {...BASE_VIEW, backgroundColor: color.palette.pink} as ViewStyle,
  brandRound: {
    ...BASE_VIEW, borderRadius: 30, paddingHorizontal: 20,
    backgroundColor: color.palette.orange
  } as ViewStyle,
  brandOutLine: {
    ...BASE_VIEW,
    backgroundColor: color.palette.white,
    borderColor: color.palette.orange,
    borderWidth: 1,
  } as ViewStyle,
  brandOutLineBlue: {
      ...BASE_VIEW,
      backgroundColor: color.palette.white,
      borderColor: color.palette.blue,
      borderWidth: 1,
  } as ViewStyle,
  brandOutLineOpacity: {
    ...BASE_VIEW,
    backgroundColor: color.palette.secondary15,
    borderColor: color.palette.orange,
    borderWidth: 1,
  } as ViewStyle,
  brandDisabled: {
    ...BASE_VIEW,
    backgroundColor: color.palette.primary15,
  } as ViewStyle,
  secondary: {...BASE_VIEW, backgroundColor: color.palette.bgBlue} as ViewStyle,
  destructive: {
    ...BASE_VIEW,
    backgroundColor: color.palette.white,
    borderColor: '#222ab399',
    borderWidth: 1
  } as ViewStyle,
  disabled: {...BASE_VIEW, backgroundColor: color.palette.black30} as ViewStyle,

  link: {
    ...BASE_VIEW,
    paddingHorizontal: 0,
    paddingVertical: 0,
    alignItems: "flex-start",
  } as ViewStyle,
  primaryPill: {...BASE_VIEW, borderRadius: 20, backgroundColor: color.palette.blue} as ViewStyle,
  brandBlue: {...BASE_VIEW, backgroundColor: color.palette.blue} as ViewStyle,
  iconButton: {
    ...BASE_VIEW,
    paddingHorizontal: 10,
    paddingVertical: 0,
  } as ViewStyle,
  contactBtn: {...BASE_VIEW, backgroundColor: color.palette.primary15} as ViewStyle,
}
export const viewPresets: Record<string, ViewStyle> = viewPresetsObject

export const textPresetsObject = {
  brand: {...BASE_TEXT} as TextStyle,
  primary: {...BASE_TEXT} as TextStyle,
  brandDisabled: {...BASE_TEXT, color: color.palette.primary, fontWeight: '500'} as TextStyle,
  primaryOpacity: {...BASE_TEXT, color: color.palette.primary} as TextStyle,
  primaryOutLine: {...BASE_TEXT, color: color.palette.primary} as TextStyle,
  brandDangerOutLine: {...BASE_TEXT, color: color.palette.red} as TextStyle,
  brandOutLine: {...BASE_TEXT, color: color.palette.orange} as TextStyle,
  brandOutLineOpacity: {...BASE_TEXT, color: color.palette.orange} as TextStyle,
  secondary: {...BASE_TEXT, color: color.palette.blue} as TextStyle,
  destructive: {...BASE_TEXT, color: color.palette.blue} as TextStyle,
  disabled: {...BASE_TEXT} as TextStyle,
  contactBtn: {
    ...fonts.style.h3,
    paddingHorizontal: 12,
    color: color.palette.primary,
  } as TextStyle,

  link: {
    ...BASE_TEXT,
    color: color.text,
    paddingHorizontal: 0,
    paddingVertical: 0,
  } as TextStyle,
}
export const textPresets: Record<ButtonPresetNames, TextStyle> = textPresetsObject as Record<ButtonPresetNames, TextStyle>

/**
 * A list of preset names.
 */
export type ButtonTextPresetNames = keyof typeof textPresetsObject
export type ButtonPresetNames = keyof typeof viewPresetsObject
