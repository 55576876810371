import React from "react"
import { StyleSheet, TouchableOpacity } from "react-native"
import { Text } from "../text/text"
import { SvgIcon } from "../icon/svg-icon"

interface Props{
  name: string
  isCheck: boolean
  onPress: () => void;
}

const LineCard = ({name, isCheck, onPress}: Props) => {
  return(
    <TouchableOpacity style={styles.wrapper} onPress={onPress}>
      <Text
        numberOfLines={1}
        text={name}
        preset={'default'} />
      <SvgIcon
        preset={isCheck ? 'smallBlue20' : 'square'}
        icon={isCheck ? 'check-square' : 'square-regular'}
        theme={isCheck ? 'solid' : 'regular'}
      />
    </TouchableOpacity>
  )
};

export default LineCard;

const styles = StyleSheet.create({
  icBookmark:{
    position: 'absolute',
    right: 0,
    top: 10
  },
  textWrapper:{
    marginLeft: 23,
    marginTop: 10
  },
  wrapper:{
    alignItems: 'center',
    borderColor: '#D9D9D9',
    borderRadius: 13,
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    paddingHorizontal: 25, paddingVertical: 15,
    width: '100%'
  }
})
