import {types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"

export const FilterStoreKey = {
  response: 1,
  message: 2,
  application: 3,
}

export const FilterStore = types
  .model("FilterStore")
  .props({
    businessJobListFilter: types.optional(types.boolean, false),
    businessJobListFilterId: types.maybeNull(types.number),
    businessMessageFilterId: types.maybeNull(types.number),
    workerJobListFilterId: types.maybeNull(types.number),
    workerMessageFilter: types.optional(types.boolean, false),
    workerMessageFilterId: types.maybeNull(types.number),
    userMessageFilter: types.optional(types.boolean, false),
    userMessageFilterId: types.maybeNull(types.number),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setBusinessJobListFilter: (businessJobListFilter: boolean) => {
      self.businessJobListFilter = businessJobListFilter;
    },
    setBusinessJobListFilterId: (businessJobListFilterId: number) => {
      self.businessJobListFilterId = businessJobListFilterId;
    },
    setBusinessMessageFilterId: (businessMessageFilterId: number) => {
      self.businessMessageFilterId = businessMessageFilterId;
    },
    setWorkerJobListFilterId: (workerJobListFilterId: number) => {
      self.workerJobListFilterId = workerJobListFilterId;
    },
    setWorkerMessageFilter: (workerMessageFilter: boolean) => {
      self.workerMessageFilter = workerMessageFilter;
    },
    setWorkerMessageFilterId: (workerMessageFilterId: number) => {
      self.workerMessageFilterId = workerMessageFilterId;
    },
    setUserMessageFilter: (userMessageFilter: boolean) => {
      self.userMessageFilter = userMessageFilter;
    },
    setUserMessageFilterId: (userMessageFilterId: number) => {
      self.userMessageFilterId = userMessageFilterId;
    },
  }))
  .views((self) => ({}))
