// This is the first file that ReactNative will run when it starts up.
//
// We jump out of here immediately and into our main entry point instead.
//
// It is possible to have React Native load our main module first, but we'd have to
// change that in both AppDelegate.m and MainApplication.java.  This would have the
// side effect of breaking other tooling like mobile-center and react-native-rename.
//
// It's easier just to leave it here.
import App from "./app/app"
import app from "./app.json"
import {AppRegistry} from "react-native"
import './assets/fonts/ProximaNova-Regular.otf';
import './assets/fonts/ProximaNova-Semibold.otf';
import './assets/fonts/ProximaNova-Bold.otf';
import './index.css'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

AppRegistry.registerComponent(app.name, () => App)
AppRegistry.runApplication(app.name, {
  rootTag: document.getElementById("root")
});
export default App
