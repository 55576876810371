import {Instance, types} from "mobx-state-tree"
import {BusinessProfileModel, convertBusinessProfileModel} from "./business-profile";
import {convertWorkerProfileModel, WorkerProfileModel} from "./worker-profile";
import {convertKnItemModel, KnItemModel} from "./kv-item";
import {convertFileUploadModel, FileUploadModel} from "./file-upload";

export const USER_TOKEN_PATH = 'users/set-device-token'
export const USER_TOKEN_ACTION = {
  destroy: 'destroy',
  add: 'add',
}

export const USER_STATUS = {
  unregistered: {key: 'unregistered'},
  pinVerified: {key: 'pinVerified'},
  active: {key: 'active'},
}

export const UserRoleModel = types.model({
  id: types.optional(types.number, 0),
  key: types.optional(types.string, ""),
  name: types.optional(types.string, ""),
  publishedAt: types.optional(types.string, ""),
  updatedAt: types.optional(types.string, ""),
})
export type IUserRoleModel = Instance<typeof UserRoleModel>
export const convertUserRoleModel = (raw): IUserRoleModel => ({
  id: raw?.id || 0,
  key: raw?.key || '',
  name: raw?.name || '',
  publishedAt: raw?.publishedAt || '',
  updatedAt: raw?.updatedAt || '',
})

export const UserAccountModel = types.model({
  id: types.optional(types.number, 0),
  status: types.optional(types.string, ""),
  firstName: types.optional(types.string, ""),
  lastName: types.optional(types.string, ""),
  username: types.optional(types.string, ""),
  email: types.optional(types.string, ""),
  mobile: types.optional(types.string, ""),
  countryCode: types.optional(types.string, ""),
  provider: types.maybeNull(types.string),
  confirmed: types.maybeNull(types.boolean),
  blocked: types.maybeNull(types.boolean),
  userRoles: types.optional(types.array(UserRoleModel), []),
  businessProfile: types.maybeNull(BusinessProfileModel),
  workerProfile: types.maybeNull(WorkerProfileModel),
  lastBusinessProfile: types.optional(BusinessProfileModel, {}),
  lastUserRole: types.optional(KnItemModel, {}),
  avatar: types.optional(FileUploadModel, {}),
})
export type IUserAccountModel = Instance<typeof UserAccountModel>
export const convertUserAccountModel = (raw): IUserAccountModel => ({
  id: raw?.id || 0,
  status: raw?.status || '',
  firstName: raw?.firstName || '',
  lastName: raw?.lastName || '',
  username: raw?.username || '',
  email: raw?.email || '',
  mobile: raw?.mobile || '',
  countryCode: raw?.countryCode || '',
  provider: raw?.provider || '',
  confirmed: raw?.confirmed || false,
  blocked: raw?.blocked || false,
  userRoles: raw?.userRoles ? raw?.userRoles?.map(convertUserRoleModel) : [],
  businessProfile: convertBusinessProfileModel(raw?.businessProfile?.data?.attributes ? {
    ...raw?.businessProfile?.data?.attributes, id: raw?.businessProfile?.data?.id
  } : raw?.businessProfile?.attributes
    ? {...raw?.businessProfile?.attributes, id: raw?.businessProfile?.id} : raw?.businessProfile),
  workerProfile: convertWorkerProfileModel(raw?.workerProfile?.data?.attributes ? {
    ...raw?.workerProfile?.data?.attributes, id: raw?.workerProfile?.data?.id
  } : raw?.workerProfile?.attributes
    ? {...raw?.workerProfile?.attributes, id: raw?.workerProfile?.id} : raw?.workerProfile),
  lastBusinessProfile: convertBusinessProfileModel(raw?.lastBusinessProfile?.data?.attributes ? {
    ...raw?.lastBusinessProfile?.data?.attributes, id: raw?.lastBusinessProfile?.data?.id
  } : raw?.lastBusinessProfile?.attributes
    ? {...raw?.lastBusinessProfile?.attributes, id: raw?.lastBusinessProfile?.id} : raw?.lastBusinessProfile),
  lastUserRole: convertKnItemModel(raw?.lastUserRole?.data?.attributes ? {
    ...raw?.lastUserRole?.data?.attributes, id: raw?.lastUserRole?.data?.id
  } : raw?.lastUserRole?.attributes
    ? {...raw?.lastUserRole?.attributes, id: raw?.lastUserRole?.id} : raw?.lastUserRole),
  avatar: convertFileUploadModel(raw?.avatar?.data?.attributes ? {
    ...raw?.avatar?.data?.attributes, id: raw?.avatar?.data?.id
  } : raw?.avatar?.attributes ? {...raw?.avatar?.attributes, id: raw?.avatar?.id} : raw?.avatar),
})

export interface IUserNotificationModel {
  responses: {
    count: number
    jobRoles: number[]
  }
  messages: {
    count: number
    messages: number[]
  }
  applicants: {
    count: number
    applications: number[]
    jobAds: number[]
  }
}

export interface IGroupNotificationModel {
  count: number
  groups: number[]
}

export interface IChatSeenMessagesModel {
  workerSeenAt: string
  businessSeenAt: string
}
export const convertChatSeenMessagesModel = (raw): IChatSeenMessagesModel => ({
  workerSeenAt: raw?.workerSeenAt || '',
  businessSeenAt: raw?.businessSeenAt || '',
})


export const getUserFullName = (user: any) => [user?.firstName, user?.lastName].join(' ').trim()
