import {Instance, types} from "mobx-state-tree";
import {convertShortUserModel, ShortUserModel} from "./worker-profile";
import {BusinessProfileModel, convertBusinessProfileModel} from "./business-profile";

export const WorkerNoteModel = types.model({
  id: types.optional(types.number, 0),
  note: types.optional(types.string, ''),
  rate: types.optional(types.number, 0),
  user: types.optional(ShortUserModel, {}),
  businessProfile: types.maybeNull(BusinessProfileModel),
})
export type IWorkerNoteModel = Instance<typeof WorkerNoteModel>
export const convertWorkerNoteModel = (raw): IWorkerNoteModel => ({
  id: raw?.id || 0,
  note: raw?.note || '',
  rate: raw?.rate || 0,
  user: convertShortUserModel(raw?.user?.data?.attributes ? {
    ...raw?.user?.data?.attributes, id: raw?.user?.data?.id
  } : raw?.user?.attributes ? {
    ...raw?.user?.attributes, id: raw?.user?.id
  } : raw?.user),
  businessProfile: convertBusinessProfileModel(raw?.businessProfile?.data?.attributes ? {
    ...raw?.businessProfile?.data?.attributes, id: raw?.businessProfile?.data?.id
  } : raw?.businessProfile?.attributes
    ? {...raw?.businessProfile?.attributes, id: raw?.businessProfile?.id} : raw?.businessProfile),
})
