import React, {FC, useCallback, useEffect, useRef, useState} from "react"
import {
  View, ViewStyle, SafeAreaView, ScrollView,
  TouchableOpacity, StyleSheet
} from "react-native"

import Slider from "react-slick";
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {observer} from "mobx-react-lite"
import {color} from "../../../theme"
import {BulletItem, Button, Header, Text, GradientBackground, Tag} from "../../../components"
import {BackdropModal} from "../../../components/backdrop-modal/backdrop-modal"
import {SvgIcon} from "../../../components/icon/svg-icon"
import {FlatCard} from "../../../components/card/flat-card"
import {AvatarImage} from "../../../components/avatar-image/avatar-image"
import {BottomContainer} from "../../../components/bottom-container/bottom-container"
import theme from "../../../theme/base_fonts"
import {listToStr} from "../../../utils/utils";
import {useStores} from "../../../models";
import {ParamListBase, RouteProp, useFocusEffect} from "@react-navigation/native";
import {IJobRoleAdModel} from "../../../models/app/job-role-ad";
import {convertWorkerApplicationModel, IWorkerApplicationModel} from "../../../models/app/application";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import {TrackEvents, trackWithUserAccount} from "../../../services/track";
import {LoadingModal} from "../../../components/backdrop-modal/loading-modal";
import {getUserQualificationStr, getUserSkillStr} from "../../../models/app/advanced-skill";
import {constants, DEFAULT_LIMIT} from "../../../utils/constants";
import {NavigatorParamList} from "../../../navigators";

const windowWidth = document.body.offsetWidth

export interface ApplicantProfileProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'applicantProfile'>
}

const avatar = require("../../../../assets/images/default-photo.png")
export const ApplicantProfile: FC<ApplicantProfileProps> = observer(({navigation, route}) => {
  const index = route?.params?.index;
  const applicantIdList = route?.params?.applicantIdList || [];
  const {app} = useStores()
  const [menuVisible, setMenuVisible] = useState(false)
  const [confirmVisible, setConfirmVisible] = useState(false)
  const [refreshing, setRefreshing] = useState(false)
  const [rate, setRate] = useState(0)

  const sliderRef = useRef<Slider>()
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [jobAdApplications, setJobAdApplications] = useState<IWorkerApplicationModel[]>(
    [convertWorkerApplicationModel({})]
  )
  const jobAdApplication: IWorkerApplicationModel = jobAdApplications[selectedIndex || 0]
  const jobRole: IJobRoleAdModel = jobAdApplication?.jobRole

  const loadData = async (_index) => {
    if (refreshing) return
    setRefreshing(true)
    try {
      const isManager = app?.user?.role === constants.roles.MANAGER
      const jobAppsRes = await app.environment.api.getBusinessJobAdApplications({
        'filters[id][$eq]': applicantIdList,
        businessProfileId: isManager ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
        "pagination[pageSize]": DEFAULT_LIMIT
      })
      if (jobAppsRes.kind !== 'ok') {
        AlertModalStore.alert('Error', jobAppsRes.message)
        return
      }
      setJobAdApplications(applicantIdList.map(it => jobAppsRes.applications?.find(_it => _it.id === it)))
      setSelectedIndex(_index)
    } catch (e) {
      console.log(e)
    } finally {
      setRefreshing(false)
    }
  }

  useEffect(() => {
    setRate(jobAdApplication?.rate)
  }, [selectedIndex])
  useFocusEffect(useCallback(() => {
    loadData(index)
  }, []))

  const scrollToItem = (index) => {
    if (index < 0 || index >= applicantIdList.length) return
    try {
      // pageScrollRef.current?.scrollToIndex({animated: true, index: index})
      sliderRef.current?.slickGoTo(index)
      setSelectedIndex(index)
    } catch (e) {
      console.log('ERR', e)
    }
  }

  const handleUpdateApplicationAction = async (data: any) => {
    if (refreshing) return
    setRefreshing(true)
    try {
      const res = await app.environment.api.updateWorkerApplicationCv(jobAdApplication?.id, data)
      if (res.kind !== 'ok') {
        // Alert.alert('Error', res.message)
        return
      }
      await loadData(selectedIndex)
    } catch (e) {
      console.log(e)
    } finally {
      setRefreshing(false)
    }
  }

  const handleApprovedAction = () => {
    trackWithUserAccount(TrackEvents.HiredWorker, app?.userAccount)
    trackWithUserAccount(TrackEvents.GotHired, app?.userAccount)
    handleUpdateApplicationAction({approved: !jobAdApplication?.approved})
  }

  const handleShortlistedAction = () => {
    handleUpdateApplicationAction({shortlisted: !jobAdApplication?.shortlisted})
  }

  const onChangePage = (index: number) => {
    if (isNaN(index) || index < 0 || index >= applicantIdList.length || index === selectedIndex) return;
    console.log('onChangePage', index)
    if (index !== selectedIndex) setSelectedIndex(index < 0 ? 0 : index)
  }

  const handleViewProfile = () => {
    navigation.goBack()
    navigation.navigate("workerProfileScreen", {id: jobAdApplication?.workerProfile?.user?.id})
  }

  const handleRateApplication = async (_rate) => {
    if (!jobAdApplication?.workerProfile?.id) {
      AlertModalStore.alert('Error', 'Invalid worker profile')
      return
    }
    setRate(_rate)
    await app.environment.api.updateWorkerApplicationRate(jobAdApplication?.id, _rate)
    await loadData(selectedIndex)
  }

  const renderProfileView = ({item, index}) => {
    const jobAdApplication: IWorkerApplicationModel = item
    const userAccount = jobAdApplication?.workerProfile?.user
    const workerProfile = jobAdApplication?.workerProfile

    const jobRole: IJobRoleAdModel = jobAdApplication?.jobRole
    const experiences = (jobRole?.experiences?.map((item) => ({
      ...item, checked: (
        !!jobAdApplication?.experiences.find(_it => _it?.id === item?.id)
      )
    }))) || []
    const skills = (jobRole?.skills?.map((item) => ({
      ...item, checked: (
        !!jobAdApplication?.skills.find(_it => _it?.id === item?.id)
      )
    }))) || []
    const qualifications = (jobRole?.qualifications?.map((item) => ({
      ...item, checked: (
        !!jobAdApplication?.qualifications.find(_it => _it?.id === item?.id)
      )
    }))) || []

    return (
      <View style={{width: windowWidth}} key={String(item?.id)}>
        <ScrollView
          style={{flex: 1}} showsVerticalScrollIndicator={false} key={index}>
          <View style={{paddingHorizontal: 20}}>
            <View style={{flexDirection: "row",}}>
              <View style={{}}>
                <AvatarImage
                  px={0}
                  onPress={handleViewProfile}
                  preset={"large"}
                  source={
                    userAccount?.avatar?.url
                      ? {uri: userAccount?.avatar?.url}
                      : avatar
                  }/>
              </View>
              <View style={{marginLeft: 10, marginTop: 5, flex: 1}}>
                <Text preset={"h2"}>{listToStr([userAccount?.firstName, userAccount?.lastName])}</Text>
                <View style={NAME}>
                  <TouchableOpacity onPress={handleViewProfile}>
                    <Text preset={"mediumLink"} style={{marginRight: 8}}>View Full profile</Text>
                  </TouchableOpacity>
                  <SvgIcon icon={"chevron-right"} preset={"tiny"}/>
                </View>
              </View>
              <View style={{justifyContent: "center"}}>
                <TouchableOpacity
                  style={MESSAGE}
                  onPress={() => {
                    navigation.goBack()
                    setTimeout(() => navigation.navigate("userApplicantMessageScreen", {
                      toUserId: workerProfile?.user?.id, applicationId: applicantIdList[selectedIndex],
                    }), 100)
                  }}
                >
                  <SvgIcon preset={'light'} icon="chatbubble-message"/>
                </TouchableOpacity>
              </View>
            </View>
            <View>
              <FlatCard title={"Preferences"}>
                <View style={ROW}>
                  <View style={{maxWidth: '50%'}}>
                    <Text preset={"body2"} style={{fontWeight: 'bold', marginLeft: 4, marginBottom: 8}}>
                      Pref. location
                    </Text>
                    {
                      workerProfile?.preferredLocations?.map(item => (
                        <View style={{marginBottom: 4}} key={String(item.id)}>
                          <Tag
                            icon="map-pin"
                            children={item.name}
                            textStyle={theme.type.light(theme.size.h3, color.palette.dark)}
                            preset="smaller"
                          />
                        </View>
                      ))
                    }
                  </View>
                  <View style={{marginLeft: 12}}>
                    <Text preset={"body2"} style={{fontWeight: 'bold', marginLeft: 4, marginBottom: 8}}>
                      Max Travel
                    </Text>
                    <Tag
                      icon="map"
                      children={workerProfile?.travelDistance}
                      textStyle={theme.type.light(theme.size.medium, color.palette.dark)}
                      preset="smaller"
                    />
                  </View>
                </View>
              </FlatCard>
              <FlatCard title={"Experience"}>
                <View style={{flexDirection: "row", flexWrap: "wrap"}}>
                  {experiences.map((item, index) => (
                    <View key={String(item?.id + '' + index)} style={styles.item}>
                      <BulletItem
                        key={index} text={item.name} preset={"body1"}
                        icon={
                          <SvgIcon
                            style={{marginRight: 10}}
                            icon={item?.checked ? "check-regular" : "times"}
                            preset={item?.checked ? "success" : "danger"}
                          />}
                      />
                    </View>
                  ))}
                </View>
              </FlatCard>
              <FlatCard title={"Skills"}>
                <View style={{flexDirection: "row", flexWrap: "wrap"}}>
                  {skills.map((item, index) => (
                    <View key={String(item?.id + '' + index)} style={styles.item}>
                      <BulletItem
                        key={index} text={getUserSkillStr(item)} preset={"body1"}
                        icon={
                          <SvgIcon
                            style={{marginRight: 10}}
                            icon={item?.checked ? "check-regular" : "times"}
                            preset={item?.checked ? "success" : "danger"}
                          />}
                      />
                    </View>
                  ))}
                </View>
              </FlatCard>
              <FlatCard title={"Qualifications"}>
                <View style={{flexDirection: "row", flexWrap: "wrap"}}>
                  {qualifications.map((item, index) => (
                    <View key={String(item?.id + '' + index)} style={styles.item}>
                      <BulletItem
                        key={index} text={getUserQualificationStr(item)} preset={"body1"}
                        icon={
                          <SvgIcon
                            style={{marginRight: 10}}
                            icon={item?.checked ? "check-regular" : "times"}
                            preset={item?.checked ? "success" : "danger"}
                          />}
                      />
                    </View>
                  ))}
                </View>
              </FlatCard>
              <View style={{flexDirection: "row", justifyContent: "space-around", marginTop: 40}}>
                {Array.from(Array(5).keys()).map((it, index) => (
                  <SvgIcon
                    key={index.toString()}
                    onPress={() => handleRateApplication(it + 1)}
                    style={{paddingHorizontal: 3}} icon={'star-solid'} theme={'solid'}
                    preset={it < rate ? 'orangeBig' : 'bgBlueMedium'}/>
                ))}
              </View>
            </View>
            <View style={{height: 160}}/>
          </View>
        </ScrollView>
      </View>
    )
  }

  return (
    <>
      <PureBottomModal onClose={() => navigation.goBack()}>
        <SafeAreaView style={CONTAINER}>
          <View style={{flex: 1}}>
            <GradientBackground colors={[color.palette.gradientStart, color.palette.gradientStop]}/>
            <Header
              preset={"modalHeader"}
              titlePreset={"headerTitle"}
              // headerText={`${jobRole?.role?.name} Profiles`}
              header={() => (
                <View style={{paddingRight: 10, flexDirection: 'row'}}>
                  <Text
                    numberOfLines={1} preset={'headerTitle'} text={`${jobRole?.role?.name} Profiles`}
                    style={{width: windowWidth - 100}}/>
                </View>
              )}
              rightIcon={"times"}
              rightIconPreset={"smallBlue"}
              onRightPress={() => navigation.goBack()}
            />
            <div style={{overflowY: 'scroll'}}>
              <Slider
                ref={sliderRef}
                {...({
                  dots: false, infinite: false, arrows: false,
                  speed: 500, slidesToShow: 1, slidesToScroll: 1,
                  initialSlide: index,
                })}
                afterChange={onChangePage}
              >
                {jobAdApplications?.map((item, index) => renderProfileView({item, index}))}
              </Slider>
            </div>
          </View>
          <BottomContainer backgroundColor={color.palette.white}>
            <View style={{flexDirection: "row", alignItems: "center"}}>
              <View style={{opacity: (selectedIndex - 1 < 0) ? 0 : 1}}>
                <SvgIcon
                  onPress={() => scrollToItem(selectedIndex - 1)}
                  style={{}}
                  icon={"arrow-left"} preset={"dark"}/>
              </View>
              <View style={{flex: 1, flexDirection: "row", paddingHorizontal: 20}}>
                <Button
                  onPress={handleApprovedAction}
                  preset={jobAdApplication?.approved ? "successFill" : "successOutline"} style={{flex: 1}}>
                  <Text preset="groupMessageSubtitle"
                        style={{color: jobAdApplication?.approved ? color.palette.white : color.palette.green}}
                  >Approved</Text>
                </Button>
                <View style={{width: 10}}/>
                <Button
                  onPress={handleShortlistedAction}
                  preset={jobAdApplication?.shortlisted ? "infoFill" : "infoOutline"} style={{flex: 1}}>
                  <Text preset="groupMessageSubtitle"
                        style={{color: jobAdApplication?.shortlisted ? color.palette.white : color.palette.blue}}
                  >Shortlisted</Text>
                </Button>
              </View>
              <View style={{opacity: selectedIndex + 1 < applicantIdList.length ? 1 : 0}}>
                <SvgIcon
                  onPress={() => scrollToItem(selectedIndex + 1)}
                  style={{}}
                  icon={"arrow-right"} preset={"dark"}/>
              </View>
            </View>
          </BottomContainer>
          <BackdropModal visible={menuVisible} onClose={() => setMenuVisible(false)}>
            <View>
              <View style={{alignItems: "center", justifyContent: "center", padding: 10,}}>
                <SvgIcon preset={"alertIcon"} icon={"exclamation-circle"}/>
                <View style={{paddingTop: 18, paddingBottom: 8}}>
                  <Text style={{textAlign: "center"}} preset={"alertTitle"}>
                    This will notify the applicant. Are you sure you want to proceed?
                  </Text>
                </View>
              </View>
              <Button
                onPress={() => {
                  setMenuVisible(false)
                  setConfirmVisible(true)
                }}
                style={ROW}
                disabled={false}
                text={"Notify them"}
              />
              <Button
                onPress={() => setConfirmVisible(false)}
                style={ROW}
                disabled={false}
                preset={"destructive"}
                text={"Cancel"}
              />
            </View>
          </BackdropModal>
          <BackdropModal visible={confirmVisible} onClose={() => setConfirmVisible(false)}>
            <View>
              <View style={{alignItems: "center", justifyContent: "center", padding: 10,}}>
                <SvgIcon icon={"bookmark"} preset={"alertIcon"}/>
                <View style={{paddingTop: 18, paddingBottom: 8}}>
                  <Text preset={"alertTitle"} style={{textAlign: "center"}}>
                    Applicant has been shortlisted.
                  </Text>
                </View>
              </View>
            </View>
          </BackdropModal>
        </SafeAreaView>
      </PureBottomModal>
      <AlertModal/>
      <LoadingModal visible={refreshing}/>
    </>
  )
})

const CONTAINER: ViewStyle = {
  flex: 1,
}

const NAME: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  marginTop: 5,
}

const MESSAGE: ViewStyle = {
  backgroundColor: color.palette.orange,
  padding: 15,
  borderRadius: 100,
}

const ROW: ViewStyle = {
  flexDirection: "row",
}

const styles = StyleSheet.create({
  item: {paddingRight: 24, maxWidth: '100%'}
})
