import React from "react"
import { TouchableOpacity, View } from "react-native"
import styles from "../styles"
import { SvgIcon } from "../../../../components/icon/svg-icon"
import { Text } from "../../../../components"
import { AvatarImage } from "../../../../components/avatar-image/avatar-image"

interface Props {
  onClick?: () => void;
  imgFile: string;
  title: string;
  subTile?: string;
}

const pdfIcon = require("../../../../../assets/images/icon-pdf.png")

const OtherCard = ({onClick, imgFile, title, subTile = ''}: Props) => {
  return (
    <TouchableOpacity style={[styles.ShadowWrapper, styles.otherCard]} onPress={onClick} activeOpacity={0.8}>
      <View style={{paddingLeft: 10}}>
        {imgFile === 'pdf-file'
          ? (
            <SvgIcon icon="pdf-file" preset="fileCard"/>
          ) : (
            <AvatarImage px={0} py={0} preset={'walletFile'} source={imgFile ? {uri: imgFile} : pdfIcon}/>
          )
        }
      </View>
      <View style={{flex: 1, justifyContent: 'center', paddingHorizontal: 10}}>
        <Text preset="titleFile">{title}</Text>
        {!!subTile &&
        <Text numberOfLines={1} preset="titleFile" style={{fontWeight: '400'}}>
          {subTile}
        </Text>}
      </View>
    </TouchableOpacity>
  )
};

export default OtherCard;
