import React, { FC, useCallback, useState } from "react"
import { View, SafeAreaView, ScrollView, RefreshControl, TouchableOpacity, StyleSheet } from "react-native"
import { observer } from "mobx-react-lite"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { Header, GradientBackground, Button, Text } from "../../../components"
import { color } from "../../../theme"
import { TypeUserModel } from "../../../models/app/kv-item"
import { useStores } from "../../../models";
import { AlertModalStore } from "../../../models/app/alert-modal-store";
import { PureBottomModal } from "../../../components/backdrop-modal/pure-bottom-modal";
import { isAndroid } from "../../../utils/platform";
import { constants } from "../../../utils/constants";
import { ParamListBase, RouteProp, useFocusEffect } from "@react-navigation/native"
import UserCard from "../../../components/card/user-card"
import { SvgIcon } from "../../../components/icon/svg-icon";
import { AlertModal } from "../../../components/backdrop-modal/alert-modal";
import { BackdropModal } from "../../../components/backdrop-modal/backdrop-modal";
import { NavigatorParamList } from "../../../navigators";

export interface GroupRemoveContactScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>,
  route: RouteProp<NavigatorParamList, 'groupRemoveContactScreen'>
}

export const GroupRemoveContactScreen: FC<GroupRemoveContactScreenProps> = observer(
  ({navigation, route}) => {
    const {app} = useStores();
    const isManager = app?.user?.role === constants.roles.MANAGER
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<Array<TypeUserModel>>([]);
    const [checked, setChecked] = useState(false)
    const [confirmVisible, setConfirmVisible] = useState(false);

    const loadData = async () => {
      setLoading(true)
      try {
        const res = await app.environment.api.getGroupDetail(route?.params?.groupId, {
          'filters[businessProfile][id][$eq]': isManager
            ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          'businessProfileId': isManager
            ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        const _contacts: TypeUserModel[] = []
        res?.item?.contacts?.forEach((item) => {
          if (!res?.item?.hideContacts?.find(ct => ct.id === item?.id)) {
            const fullName = [item?.user?.firstName, item?.user?.lastName]?.join(' ')?.trim()
            _contacts.push({
              id: item?.id, name: fullName || item?.user?.email,
              checked: false,
              avatar: item?.user?.avatar?.url || ''
            })
          }
        })
        setData(_contacts)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    useFocusEffect(useCallback(() => {
      loadData()
    }, []))

    const toggleSelectItem = (id) => {
      const newData = data.map((item) => {
        return ({
          ...item,
          checked: item.id === id ? !item.checked : item.checked
        })
      })
      setData(newData)
    }

    const onDone = async () => {
      setLoading(true)
      try {
        const updateRes = await app.environment.api.deleteGroupContacts(
          route?.params?.groupId, {
            // contacts: data.map(it => it.id),
            contactIds: data.filter(it => it.checked).map(it => it.id).join(','),
            businessProfileId: isManager
              ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          }
        )
        if (updateRes.kind !== 'ok') {
          AlertModalStore.alert('Error', updateRes.message)
          return
        }
        navigation.goBack()
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    const onCheckAll = () => {
      setData(data.map((it) => ({...it, checked: !checked})))
      setChecked(!checked)
    }

    const disableBtnDone = !data?.find(it => it?.checked) || loading
    return (
      <>
        <PureBottomModal onClose={() => navigation.goBack()}>
          <SafeAreaView style={{flex: 1}}>
            <GradientBackground
              colors={[color.palette.gradientStart, color.palette.gradientStop]}
            />
            <Header
              preset={"modalHeader"}
              titlePreset={"headerTitle"}
              headerText={route?.params?.groupName}
              leftIcon={'arrow-left'} leftIconPreset={'normal'} onLeftPress={() => navigation.goBack()}
              rightIcon={() => (
                <TouchableOpacity onPress={onCheckAll}>
                  <View style={{alignItems: 'center', marginRight: 10, paddingTop: 8}}>
                    <SvgIcon
                      icon={checked ? 'check-square' : 'square-regular'}
                      theme={checked ? 'solid' : 'regular'} preset={'primary'}
                    />
                    <View style={{marginTop: 4}}>
                      <Text preset="small" style={{color: color.palette.primary}}>All</Text>
                    </View>
                  </View>
                </TouchableOpacity>
              )}
            />
            <View style={{paddingHorizontal: 20, flex: 1}}>
              <View style={{marginBottom: 20}}>
                <Text preset={'body'} style={{fontWeight: 'bold'}}>Remove Chat Member(s)</Text>
              </View>
              <View style={{flex: 1}}>
                {data ? (
                  <View style={{flex: 1}}>
                    <ScrollView
                      refreshControl={<RefreshControl refreshing={loading} onRefresh={loadData}/>}
                      showsVerticalScrollIndicator={false} style={{flex: 1}}>
                      <View>
                        {data ? (
                          <View>
                            {data.map((item, index) => (
                              <UserCard
                                key={`${index}-${item.id}`}
                                onClick={() => toggleSelectItem(item.id)}
                                isCheck={item.checked}
                                name={item.name}
                                avatar={item.avatar}
                              />
                            ))}
                          </View>
                        ) : null}
                      </View>
                    </ScrollView>
                  </View>
                ) : null}
              </View>
              <View style={{paddingTop: 10, marginBottom: isAndroid ? 30 : 0}}>
                <Button
                  disabled={disableBtnDone}
                  preset={disableBtnDone ? 'disabled' : 'brand'}
                  onPress={() => setConfirmVisible(true)}
                  text={'Remove Chat Member(s)'}/>
              </View>
            </View>
          </SafeAreaView>
        </PureBottomModal>
        <BackdropModal visible={confirmVisible} onClose={() => setConfirmVisible(false)}>
          <View>
            <View style={{
              alignItems: "center",
              justifyContent: 'center',
              padding: 10,
            }}>
              <SvgIcon preset={'alertIcon'}/>
              <View style={{paddingTop: 18, paddingBottom: 8}}>
                <Text preset={'alertTitle'}>Are you sure?</Text>
              </View>
            </View>
            <Button
              onPress={onDone}
              style={styles.modalBtn}
              disabled={false}
              preset={'brand'}
              text={'Yes'}
            />
            <Button
              onPress={() => setConfirmVisible(false)}
              style={styles.modalBtn}
              disabled={false}
              preset={'destructive'}
              text={'Cancel'}
            />
          </View>
        </BackdropModal>
        <AlertModal/>
      </>
    )
  },
)

const styles = StyleSheet.create({
  modalBtn: {
    marginVertical: 8
  },
});
