import React, {FC, useEffect, useState} from "react"
import {View, StyleSheet, ScrollView, TouchableOpacity, SafeAreaView} from "react-native"

import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {observer} from "mobx-react-lite"
import {color} from "../../../theme"
import {Button, Header, Text, TextField} from "../../../components"
import {SvgIcon} from "../../../components/icon/svg-icon"
import {BackdropModal} from "../../../components/backdrop-modal/backdrop-modal"
import {useStores} from "../../../models";
import {IJobRoleModel} from "../../../models/app/job-role";
import {dateToShortDateStr} from "../../../utils/utils";
import {convertJobAdvertModel, CreateJobTags} from "../../../models/app/job-advert";
import {ParamListBase, RouteProp} from "@react-navigation/native";
import {IKvItemCheckModel} from "../../../models/app/kv-item";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";
import {constants} from "../../../utils/constants";
import {identifyWithUserAccount, TrackEvents, trackWithUserAccount} from "../../../services/track";
import {AdvertRoleStore} from "../../../models/app/advert-role";
import {
  getUserQualificationStr,
  getUserSkillStr,
  IUserQualificationModel,
  IUserSkillModel
} from "../../../models/app/advanced-skill";
import {SelectTextField} from "../../../components/text-field/select-text-field";
import {isAndroid} from "../../../utils/platform";
import {PickerSelectField} from "../../../components/picker-select/picker-select";
import {convertJobRoleAdModel} from "../../../models/app/job-role-ad";
import {JobTagRow} from "../../../components/tag/job-tag-row";
import {cast} from "mobx-state-tree";
import {DatePickerField} from "../../../components/picker-select/date-picker";
import {NavigatorParamList} from "../../../navigators";
import {LoadingModal} from "../../../components/backdrop-modal/loading-modal";
import {AvatarImage} from "../../../components/avatar-image/avatar-image";
import {useFilePicker} from "use-file-picker";
import {convertFileUploadModel} from "../../../models/app/file-upload";
import AvatarDefault from "../../../components/avatar-image/AvatarDefault";


const WORK_TYPES = [
  'Full time',
  'Part time',
  'Casual',
  'Contractor',
  'Any',
]
const RESULT_TEXT = {
  saveAd: "Job ad saved",
  postAd: "Job ad posted",
  done: "Done",
}

export interface JobAdvertEditScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'jobAdvertEditScreen'>
}

export const JobAdvertEditScreen: FC<JobAdvertEditScreenProps> = observer(({navigation, route}) => {
  const editId = route?.params?.editId;
  const {app} = useStores();
  const emptyBusinessProfile = !app?.userAccount?.businessProfile?.id

  const [jobRoleEdit, setJobRoleEdit] = useState<IJobRoleModel>()

  const [isDraft, setIsDraft] = useState(false)
  const [resultText, setResultText] = useState("")
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [responsibilities, setResponsibilities] = useState("")

  const [businessName, setBusinessName] = useState('')
  const [openFileSelector, {plainFiles, filesContent, clear}] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    maxFileSize: 2,
  });
  const firstAsset = !plainFiles || plainFiles.length <= 0 ? null : plainFiles[0]
  const firstContent = !filesContent || filesContent.length <= 0 ? null : filesContent[0]
  const [loading, setLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false);

  const [confirmVisible, setConfirmVisible] = useState(false)
  const [resultVisible, setResultVisible] = useState(false)

  const selectedContact = app?.selectedContact
  const [paymentTypes, setPaymentTypes] = useState<Array<IKvItemCheckModel>>([])
  const [payType, setPayType] = useState(0)
  const [payTypeOpen, setPayTypeOpen] = useState(false)
  const [amount, setAmount] = useState('')
  const [numberOfPositions, setNumberOfPositions] = useState('')
  const [startDate, setStartDate] = useState(new Date())
  const [workType, setWorkType] = useState(null)
  const [workTypeOpen, setWorkTypeOpen] = useState(false)

  const [datePickerVisible, setDatePickerVisible] = useState(false)

  const loadData = async () => {
    try {
      const res = await app.environment.api.getPaymentTypes()
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message)
        return
      }
      setPaymentTypes(res.items.map<IKvItemCheckModel>((item) => ({
        ...item, checked: false
      })))

      if (!editId) return
      const jobAdRes = await app.environment.api.getJobAdvertDetail(editId)
      if (jobAdRes.kind !== 'ok') {
        AlertModalStore.alert('Error', jobAdRes.message)
        return
      }
      const _jobAdvert = jobAdRes?.jobAdvert
      const _jobRole: IJobRoleModel = jobAdRes?.jobAdvert?.jobRoles?.length > 0
        ? jobAdRes?.jobAdvert?.jobRoles[0] : {} as IJobRoleModel
      setIsDraft(_jobAdvert?.isDraft)
      setJobRoleEdit(_jobRole)
      setTitle(_jobAdvert?.title)
      AdvertRoleStore.setSelectedIndustries(_jobRole?.industries)
      AdvertRoleStore.setSelectedLocations(_jobRole?.locations)
      AdvertRoleStore.setSelectedRoles(_jobRole?.role?.id ? [_jobRole?.role] : [])
      setDescription(_jobAdvert?.description)
      setResponsibilities(_jobAdvert?.responsibilities)
      setNumberOfPositions(String(_jobRole?.numberOfPositions || 0))
      const _paymentTypeIds = _jobAdvert.paymentTypes.map(it => it.id)
      setPaymentTypes(res.items.map<IKvItemCheckModel>((item) => ({
        ...item, checked: _paymentTypeIds.includes(item.id)
      })))
      setPayType(_jobAdvert?.paymentTypes?.length > 0 ? _jobAdvert?.paymentTypes[0]?.id : 0)
      setAmount(String(_jobRole?.amount))
      setWorkType(_jobRole?.workType)
      AdvertRoleStore.setSelectedQualifications(_jobRole?.qualifications?.map(it => ({
        id: it.id,
        name: getUserQualificationStr(it as IUserQualificationModel),
      })))
      AdvertRoleStore.setSelectedSkills(_jobRole?.skills?.map(it => ({
        id: it.id, name: getUserSkillStr(it as IUserSkillModel),
      })))
      AdvertRoleStore.setSelectedExperience(_jobRole?.experiences)
      const fullName = [_jobRole?.contact?.user?.firstName, _jobRole?.contact?.user?.lastName]?.join(' ')?.trim()
      app.setSelectedContact({id: _jobRole?.contact?.id, name: fullName || _jobRole?.contact?.user?.email,})

    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    trackWithUserAccount(TrackEvents.SetupJobAd, app?.userAccount)
    loadData()
  }, [])

  // const toggleSelectContact = (index) => {
  //   setPaymentTypes(paymentTypes.map((it, id) => (id === index ? {...it, checked: !it.checked} : it)))
  // }

  const handlePostJobAd = async (isDraft = false) => {
    if (!title) {
      AlertModalStore.alert('Alert', 'Please enter Job ad title')
      return
    }
    try {
      const isManager = app?.user?.role === constants.roles.MANAGER
      const roles = AdvertRoleStore.roles.filter(item => !!item.id).map(item => item.id)
      const jobRole = {
        ...(roles?.length > 0 ? {role: roles[0]} : null),
        amount: amount,
        numberOfPositions: parseInt(numberOfPositions) || 0,
        startDate,
        workType,
        locations: AdvertRoleStore.locations.toJSON().map(item => item.id),
        industries: AdvertRoleStore.industries.toJSON().map(item => item.id),
        ...(!!selectedContact?.id ? {contact: selectedContact?.id} : null),
        experiences: AdvertRoleStore.experience.toJSON().map(item => item.id),
        skills: AdvertRoleStore.skills.toJSON().map(item => item.id),
        qualifications: AdvertRoleStore.qualifications.toJSON().map(item => item.id)
      }

      if (editId) {
        const jobRoleRes = await app.environment.api.editJobAdvertRoleByData(jobRoleEdit.id, jobRole)
        if (jobRoleRes.kind !== 'ok') {
          AlertModalStore.alert('Error', jobRoleRes.message || '')
          return
        }
        const res = await app.environment.api.editJobAdvertDetail(editId, {
          title: title?.trim(), description, responsibilities, isDraft, closed: false,
          // jobRoles: [jobRole],
          paymentTypes: [payType],
          // paymentTypes: paymentTypes.filter(item => item.checked),
          businessProfileId: isManager ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          businessProfile: isManager ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
        })
        if (res.kind === 'ok') {
          setResultText(RESULT_TEXT.saveAd)
          setResultVisible(true)
        } else {
          AlertModalStore.alert('Error', res.message)
        }
      } else {
        if (emptyBusinessProfile) {
          if (!businessName || !firstAsset) {
            AlertModalStore.alert('Alert', 'Please enter Business info')
            return
          }
          setLoading(true);
          try {
            const res = await app.environment.api.uploadFile(firstAsset)
            if (res.kind === 'ok') {
              await identifyWithUserAccount(TrackEvents.CreateBusinessProfile, app?.userAccount)
              await trackWithUserAccount(TrackEvents.CreateBusinessProfile, app?.userAccount)
              const createRes = await app.environment.api.createBusinessProfile({
                logo: res.file.id, name: businessName
              })
              if (createRes.kind !== 'ok') {
                AlertModalStore.alert('Error', createRes.message)
                return
              }
              const updateRes = await app.updateUserProfile({businessProfile: createRes.businessProfile.id})
              if (updateRes.kind !== 'ok') {
                AlertModalStore.alert('Error', updateRes.message)
                return
              }
            } else {
              AlertModalStore.alert('Error', 'Can not upload file')
            }
          } catch (e) {
            console.log(e)
          } finally {
            setLoading(false);
          }
        }
        const jobRoleRes = await app.environment.api.createJobAdvertRoleByData(jobRole)
        if (jobRoleRes.kind !== 'ok') {
          AlertModalStore.alert('Error', jobRoleRes.message || '')
          return
        }
        const res = await app.environment.api.createJobAdvert({
          title: title?.trim(),
          description,
          responsibilities,
          isDraft,
          closed: false,
          jobRoles: [jobRoleRes?.jobRole?.id],
          paymentTypes: [payType],
          // paymentTypes: paymentTypes.filter(item => item.checked).map(item => item.id),
          // qualifications: jobAdvertCreate.qualifications.map(item => item.id),
          businessProfileId: isManager ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          businessProfile: isManager ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
        })
        if (res.kind === 'ok') {
          setResultText(RESULT_TEXT.postAd)
          setResultVisible(true)
        } else {
          AlertModalStore.alert('Error', res.message)
        }
      }
      const trackOptions = {
        email: app?.userAccount?.email,
        role: jobRole?.role,
        locations: JSON.stringify(jobRole?.locations),
        industries: JSON.stringify(jobRole?.industries),
      }
      trackWithUserAccount(TrackEvents.PostedJobAd, app?.userAccount, trackOptions)
      trackWithUserAccount(TrackEvents.NewJobs, app?.userAccount, trackOptions)
    } catch (e) {
      console.log(e)
    }
  }

  const handleSaveJobAdDraft = async () => {
    await handlePostJobAd(true)
  }

  const handlePreview = async () => {
    const roles = AdvertRoleStore.roles.filter(item => !!item.id)
    const _paymentType = paymentTypes?.find(it => it.id === payType)
    AdvertRoleStore.setPreviewJobRoleAd({
      ...convertJobRoleAdModel({}),
      ...(roles?.length > 0 ? {role: {...roles[0]}} : null),
      amount: amount,
      numberOfPositions: parseInt(numberOfPositions) || 0,
      startDate: startDate.toISOString(),
      workType: workType || '',
      jobAd: {
        ...convertJobAdvertModel({}),
        title,
        description,
        responsibilities,
        isDraft: true,
        closed: false,
        paymentTypes: cast(_paymentType ? [{
          id: _paymentType?.id, name: _paymentType?.name
        }] : []),
      }
    })
    AdvertRoleStore.setPreviewLogo(convertFileUploadModel({
      url: firstContent?.content
    }))
    navigation.navigate('jobsAdPreviewScreen')
  }

  const handlePostJobAdPublic = async () => {
    await handlePostJobAd(false)
  }

  const handleSaveJobAd = async () => {
    setResultVisible(false)
    navigation.goBack()
  }

  const toggleWorkTypeOpen = () => {
    setWorkTypeOpen(!workTypeOpen)
  }

  const handleSetDate = (date: Date) => {
    setStartDate(date)
  }

  const showUploadLogo = () => {
    setModalVisible(true)
  }
  const handleLogoPress = async () => {
    if (firstAsset) {
      clear()
    } else showUploadLogo()
  }

  const handleUploadImage = async (isUpload?: boolean) => {
    await openFileSelector()
    setModalVisible(false)
  }

  return (
    <>
      <SafeAreaView style={styles.container}>
        <Header
          preset={"primary"}
          titlePreset={"headerTitle"}
          headerText={(!editId || isDraft) ? "Create New Job Advert" : "Update Job Advert"}
        />
        <View style={{flex: 1, backgroundColor: color.palette.white}}>
          <ScrollView style={{flex: 1, paddingHorizontal: 24,}} showsVerticalScrollIndicator={false}>
            <View style={styles.jobAdDetails}>
              {emptyBusinessProfile && (
                <View>
                  <View style={{marginBottom: 24}}>
                    <TextField
                      preset={"brand"}
                      value={businessName} onChangeText={setBusinessName}
                      label="Business Name*" placeholder="Business Name"
                      labelPreset={"formLabel"}
                    />
                  </View>
                  <View style={{marginBottom: 24}}>
                    <Text preset="formLabel">Business Logo*</Text>
                    <View style={{alignItems: 'center'}}>
                      <View>
                        {firstContent?.content ?  <AvatarImage
                            onPress={handleLogoPress}
                            preset={'lgRound'}
                            source={{uri: firstContent?.content}}/> :
                            <AvatarDefault onPress={handleLogoPress} name={businessName} preset={"lgRound"}/>}
                        <View
                          style={{position: 'absolute', right: 4, top: 4, display: firstContent?.content ? 'flex' : 'none'}}>
                          <SvgIcon onPress={handleLogoPress} preset={'dark'} icon={'times'} theme={'regular'}/>
                        </View>
                        <View style={{position: 'absolute', right: 4, bottom: 4}}>
                          <SvgIcon onPress={showUploadLogo} preset={'dark'} icon={'camera'} theme={'solid'}/>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              )}
              <View style={{marginBottom: 24}}>
                <TextField
                  preset={"brand"}
                  value={title} onChangeText={setTitle}
                  label={"Advert Title*"} placeholder={'Eg Chef Needed, Experienced Painters Wanted'}
                  labelPreset={"formLabel"}
                />
              </View>
              <View style={{marginBottom: 24, marginHorizontal: isAndroid ? 6 : 0}}>
                <SelectTextField
                  onPress={() => navigation.navigate("jobRoleInfoDetailScreen", {tag: CreateJobTags.industries.id})}
                  preset={"brand"}
                  value={AdvertRoleStore.industries?.length > 0 ? '' : null} placeholder={'Select Industry'}
                  rightIcon={"chevron-right"}
                  label={CreateJobTags.industries.text} labelPreset={"formLabel"}
                >
                  {
                    AdvertRoleStore.industries?.length > 0 ? (
                      <View style={{minHeight: 48, flex: 1, paddingHorizontal: 10}}>
                        {AdvertRoleStore.industries.map((item) => (
                          <JobTagRow
                            key={String(item?.id)}
                            text={item.name}
                            onPress={() => AdvertRoleStore.toggleSelectRoleIndex(CreateJobTags.industries.id, item.id)}/>
                        ))}
                      </View>
                    ) : null
                  }
                </SelectTextField>
              </View>
              <View style={{marginBottom: 24, marginHorizontal: isAndroid ? 6 : 0}}>
                <SelectTextField
                  onPress={() => navigation.navigate("jobRoleInfoDetailScreen", {tag: CreateJobTags.locations.id})}
                  preset={"brand"}
                  value={AdvertRoleStore.locations?.length > 0 ? '' : null}
                  placeholder={'Enter Location Eg Alice Springs'}
                  rightIcon={"chevron-right"}
                  label={CreateJobTags.locations.text} labelPreset={"formLabel"}
                >
                  {AdvertRoleStore.locations?.length > 0 ? (
                    <View style={{minHeight: 48, flex: 1, paddingHorizontal: 10}}>
                      {AdvertRoleStore.locations.map((item) => (
                        <JobTagRow
                          key={String(item?.id)}
                          text={item.name}
                          onPress={() => AdvertRoleStore.toggleSelectRoleIndex(CreateJobTags.locations.id, item.id)}/>
                      ))}
                    </View>
                  ) : null}
                </SelectTextField>
              </View>
              <View style={{flexDirection: "row", marginBottom: 20, zIndex: 100}}>
                <View style={{marginRight: 20, flex: 1}}>
                  <TextField
                    value={numberOfPositions} onChangeText={setNumberOfPositions}
                    placeholder={'Enter Number'}
                    preset={"brand"}
                    label={"No. Of Positions"}
                    labelPreset={"formLabel"}
                  />
                </View>
                <View style={{flex: 1}}>
                  <DatePickerField
                    visible={datePickerVisible}
                    onChange={handleSetDate}
                    value={startDate} maxDate={null}
                    customInput={
                      <SelectTextField
                        value={startDate ? dateToShortDateStr(startDate) : null}
                        onPress={() => setDatePickerVisible(!datePickerVisible)}
                        preset={'brand'}
                        placeholder="DD/MM/YY" label="Start date" labelPreset="formLabel"/>
                    }
                  />
                </View>
              </View>
              <View style={{marginBottom: 20, zIndex: 99}}>
                <PickerSelectField
                  onOpen={() => setPayTypeOpen(true)}
                  onClose={() => setPayTypeOpen(false)}
                  value={payType}
                  onValueChange={setPayType}
                  items={paymentTypes?.map(item => ({
                    key: item.id, value: item.id,
                    label: item?.name
                  }))}
                  preset={"brand"}
                  label={"Pay Type"} labelPreset={"formLabel"}
                  placeholder="Select from list"
                  rightIcon={payTypeOpen ? 'chevron-down' : 'chevron-right'}
                />
              </View>
              <View style={{marginBottom: 20}}>
                <TextField
                  value={amount} onChangeText={setAmount} keyboardType={'number-pad'}
                  preset={"brand"}
                  style={{flex: 1}} placeholder={'Enter Pay Amount or Pay Range'}
                  label={"Amount"}
                  labelPreset={"formLabel"}
                />
              </View>
              <View style={{marginBottom: 20, zIndex: 9}}>
                <PickerSelectField
                  onOpen={toggleWorkTypeOpen}
                  onClose={toggleWorkTypeOpen}
                  value={workType}
                  onValueChange={setWorkType} items={WORK_TYPES.map(it => ({key: it, value: it, label: it}))}
                  preset={"brand"}
                  label={"Work Type"} labelPreset={"formLabel"}
                  placeholder="Select from list"
                  rightIcon={workTypeOpen ? 'chevron-down' : 'chevron-right'}
                />
              </View>
              <View style={{marginBottom: 6, marginHorizontal: isAndroid ? 6 : 0}}>
                <SelectTextField
                  onPress={() => navigation.navigate("jobRoleInfoDetailScreen", {tag: CreateJobTags.roles.id})}
                  preset={"brand"}
                  rightIcon={"chevron-right"}
                  value={AdvertRoleStore.roles?.length > 0 ? '' : null}
                  placeholder={'Enter Role Eg Retail Assistant, Chef, Plasterer'}
                  label={CreateJobTags.roles.text} labelPreset={"formLabel"}
                >{
                  AdvertRoleStore.roles?.length > 0 ? (
                    <View style={{minHeight: 48, flex: 1, paddingHorizontal: 10}}>
                      {AdvertRoleStore.roles.map((item) => (
                        <JobTagRow
                          key={String(item?.id)}
                          text={item.name}
                          onPress={() => AdvertRoleStore.toggleSelectRoleIndex(CreateJobTags.roles.id, item.id)}/>
                      ))}
                    </View>
                  ) : null
                }</SelectTextField>
              </View>
              <View style={{marginBottom: 20}}>
                <TextField
                  preset={"brand"}
                  value={description} onChangeText={setDescription}
                  multiline={true}
                  numberOfLines={6}
                  inputStyle={{height: 120}} placeholder={'Enter Description'}
                  label={"Description"}
                  labelPreset={"formLabel"}
                />
              </View>
              <View style={{marginBottom: 20}}>
                <TextField
                  preset={"brand"}
                  value={responsibilities} onChangeText={setResponsibilities}
                  multiline={true}
                  numberOfLines={6}
                  inputStyle={{height: 120}} placeholder={'Enter Responsibilities'}
                  label={"Responsibilities"}
                  labelPreset={"formLabel"}
                />
              </View>
              <View style={{marginTop: 34}}>
                <View style={{paddingBottom: 8}}>
                  <Text preset="h4Dark">{CreateJobTags.qualifications.text}</Text>
                </View>
                <Text preset="body2" style={{color: color.palette.dark, marginTop: 0}}>
                  {CreateJobTags.qualifications.label}
                </Text>
                <View style={{marginTop: 4}}>
                  <Text preset="body2" style={{color: color.palette.lightGrey, fontStyle: 'italic'}}>
                    {CreateJobTags.qualifications.hint}
                  </Text>
                </View>
                <View style={{marginTop: 16, backgroundColor: color.palette.white}}>
                  <View style={{flexDirection: "row", flexWrap: 'wrap', alignItems: 'flex-start'}}>
                    {AdvertRoleStore.qualifications?.map((item) => (
                      <JobTagRow
                        key={String(item?.id)}
                        text={item.name}
                        onPress={() => AdvertRoleStore.toggleSelectRoleIndex(CreateJobTags.qualifications.id, item.id)}/>
                    ))}
                  </View>
                  <View style={{flexDirection: 'row', alignItems: 'flex-start'}}>
                    <TouchableOpacity
                      onPress={() => navigation.navigate("jobRoleInfoDetailScreen", {tag: CreateJobTags.qualifications.id})}
                      style={{
                        flexDirection: 'row', alignItems: 'center',
                        borderRadius: 6,
                        paddingVertical: 6, paddingRight: 12, borderWidth: 1, borderColor: color.palette.primary
                      }}>
                      <View style={{paddingLeft: 6, paddingRight: 8}}>
                        <SvgIcon icon={'plus'} preset={'primary'}/>
                      </View>
                      <Text style={{fontWeight: '500', color: color.palette.primary}}>Add</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
              <View style={{marginTop: 34}}>
                <View style={{paddingBottom: 8}}>
                  <Text preset="h4Dark">{CreateJobTags.skills.text}</Text>
                </View>
                <Text preset="body2" style={{color: color.palette.dark, marginTop: 0}}>
                  {CreateJobTags.skills.label}
                </Text>
                <View style={{marginTop: 4}}>
                  <Text preset="body2" style={{color: color.palette.lightGrey, fontStyle: 'italic'}}>
                    {CreateJobTags.skills.hint}
                  </Text>
                </View>
                <View style={{marginTop: 16, backgroundColor: color.palette.white}}>
                  <View style={{flexDirection: "row", flexWrap: 'wrap'}}>
                    {AdvertRoleStore.skills?.map((item) => (
                      <JobTagRow
                        key={String(item?.id)}
                        text={item.name}
                        onPress={() => AdvertRoleStore.toggleSelectRoleIndex(CreateJobTags.skills.id, item.id)}/>
                    ))}
                  </View>
                  <View style={{flexDirection: 'row', alignItems: 'flex-start'}}>
                    <TouchableOpacity
                      onPress={() => navigation.navigate("jobRoleInfoDetailScreen", {tag: CreateJobTags.skills.id})}
                      style={{
                        flexDirection: 'row', alignItems: 'center',
                        borderRadius: 6,
                        paddingVertical: 6, paddingRight: 12, borderWidth: 1, borderColor: color.palette.primary
                      }}>
                      <View style={{paddingLeft: 6, paddingRight: 8}}>
                        <SvgIcon icon={'plus'} preset={'primary'}/>
                      </View>
                      <Text style={{fontWeight: '500', color: color.palette.primary}}>Add</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
              <View style={{marginTop: 34}}>
                <View style={{paddingBottom: 8}}>
                  <Text preset="h4Dark">{CreateJobTags.experience.text}</Text>
                </View>
                <Text preset="body2" style={{color: color.palette.dark, marginTop: 0}}>
                  {CreateJobTags.experience.label}
                </Text>
                <View style={{marginTop: 4}}>
                  <Text preset="body2" style={{color: color.palette.lightGrey, fontStyle: 'italic'}}>
                    {CreateJobTags.experience.hint}
                  </Text>
                </View>
                <View style={{marginTop: 16, backgroundColor: color.palette.white}}>
                  <View style={{flexDirection: "row", flexWrap: 'wrap'}}>
                    {AdvertRoleStore.experience?.map((item) => (
                      <JobTagRow
                        key={String(item?.id)}
                        text={item.name}
                        onPress={() => AdvertRoleStore.toggleSelectRoleIndex(CreateJobTags.experience.id, item.id)}/>
                    ))}
                  </View>
                  <View style={{flexDirection: 'row', alignItems: 'flex-start'}}>
                    <TouchableOpacity
                      onPress={() => navigation.navigate("jobRoleInfoDetailScreen", {tag: CreateJobTags.experience.id})}
                      style={{
                        flexDirection: 'row', alignItems: 'center',
                        borderRadius: 6,
                        paddingVertical: 6, paddingRight: 12, borderWidth: 1, borderColor: color.palette.primary
                      }}>
                      <View style={{paddingLeft: 6, paddingRight: 8}}>
                        <SvgIcon icon={'plus'} preset={'primary'}/>
                      </View>
                      <Text style={{fontWeight: '500', color: color.palette.primary}}>Add</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
              {app?.user?.role === constants.roles.BUSINESS && (
                <View>
                  <View style={{borderBottomWidth: 2, borderColor: color.palette.bgBlue, marginVertical: 34}}/>
                  <View style={{marginBottom: 20, marginHorizontal: isAndroid ? 6 : 0}}>
                    <View style={{paddingBottom: 22}}>
                      <Text preset="h2">Internal Use</Text>
                    </View>
                    <View style={{paddingBottom: 10}}>
                      <Text preset="h4Dark">Business Manager</Text>
                    </View>
                    <Text preset="body2" style={{color: color.palette.dark, marginTop: 8, marginBottom: 8}}>
                      Select Person Responsible for managing this job advert
                    </Text>
                    <SelectTextField
                      onPress={() => navigation.navigate('jobRoleAddContactScreen')}
                      preset={"brand"}
                      label={""} labelPreset={"primary"}
                      placeholder="Select from list"
                      rightIcon={'chevron-right'}
                      value={selectedContact?.name || ''}
                    />
                  </View>
                </View>
              )}
            </View>
            <View style={{marginTop: 46}}>
              <Button
                preset={"primary"}
                text={"Preview"}
                onPress={handlePreview}
              />
              <View style={{height: 10}}/>
              <Button
                preset={"brand"}
                text={(!editId || isDraft) ? "Post Job Advert" : "Update"}
                onPress={handlePostJobAdPublic}
              />
              <View style={{height: 10}}/>
              {(!editId || isDraft) && (
                <View style={{marginBottom: 10}}>
                  <Button
                    text={"Save as Draft"}
                    preset={"brandOutLine"}
                    onPress={handleSaveJobAdDraft}
                  />
                </View>
              )}
              <Button
                text={"Cancel"}
                preset={"destructive"}
                onPress={() => setConfirmVisible(true)}
              />
            </View>
            <View style={{height: 80}}/>
          </ScrollView>
        </View>
      </SafeAreaView>
      <BackdropModal contentStyle={{maxWidth: 576}} visible={confirmVisible} onClose={() => setConfirmVisible(false)}>
        <View>
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              padding: 10,
            }}
          >
            <SvgIcon preset={"alertIcon"}/>
            <View style={{paddingTop: 18, paddingBottom: 8}}>
              <Text preset={"alertTitle"} style={{textAlign: 'center'}} text={'Are you sure?'}/>
              <View style={{height: 10}}/>
              <Text preset={"alertTitle"} style={{textAlign: 'center'}} text={'Your data will be lost.'}/>
            </View>
          </View>
          <View style={{flexDirection: 'row'}}>
            <Button
              onPress={() => setConfirmVisible(false)}
              style={styles.modalBtn}
              disabled={false}
              preset={"destructive"}
              text={"No"}
            />
            <View style={{width: 30}}/>
            <Button
              onPress={() => {
                setConfirmVisible(false)
                navigation.goBack()
              }}
              style={styles.modalBtn}
              disabled={false}
              text={"Yes"}
            />
          </View>
        </View>
      </BackdropModal>
      <BackdropModal visible={resultVisible} onClose={handleSaveJobAd}>
        <View>
          <View style={{alignItems: "center", justifyContent: "center", padding: 10}}>
            <SvgIcon icon={"check-circle"} preset={"alertIcon"}/>
            <View style={{paddingTop: 18, paddingBottom: 8}}>
              <Text preset={"alertTitle"}>{resultText}</Text>
            </View>
          </View>
        </View>
      </BackdropModal>
      <AlertModal/>
      <LoadingModal visible={loading}/>
      <BackdropModal visible={modalVisible} onClose={() => setModalVisible(false)}>
        <View style={{paddingHorizontal: 10}}>
          <View style={{marginBottom: 0}}>
            <Button text="Upload from library" preset={'brandOutLine'} onPress={() => handleUploadImage(true)}/>
          </View>
        </View>
      </BackdropModal>
    </>
  )
})

const styles = StyleSheet.create({
  body: {paddingHorizontal: 10,},
  container: {flex: 1, backgroundColor: color.palette.white},
  modalBtn: {flex: 1, marginVertical: 8,},
  jobAdDetails: {marginTop: 24, paddingBottom: 10, backgroundColor: color.palette.white,},
})
