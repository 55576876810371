import {types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {ButtonPresetNames} from "../../components/button/button.presets";

export const AlertModalAction: IAlertModalAction = {
  onOk: null,
  onCancel: null,
}

export type IAlertModalActionItem = {
  text?: string
  preset?: ButtonPresetNames
  onPress?: () => void
}
export type IAlertModalAction = {
  onOk?: IAlertModalActionItem
  onCancel?: IAlertModalActionItem
}

export const AlertModalStore = types
  .model("AlertModalStore")
  .props({
    visible: types.optional(types.boolean, false),
    title: types.optional(types.string, ''),
    message: types.optional(types.string, ''),
    okText: types.optional(types.string, ''),
    cancelText: types.optional(types.string, ''),
    okPreset: types.optional(types.string, ''),
    cancelPreset: types.optional(types.string, ''),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setVisible: (visible: boolean) => {
      self.visible = visible
    },
    setTitle: (title: string) => {
      self.title = title
    },
    setMessage: (message: string) => {
      self.message = message
    },
    setOkText: (okText: string) => {
      self.okText = okText
    },
    setCancelText: (cancelText: string) => {
      self.cancelText = cancelText
    },
    alert: (title: string, message: string, actions?: IAlertModalAction) => {
      self.visible = true
      self.title = title
      self.message = message
      self.okText = actions?.onOk?.text || 'Ok'
      self.okPreset = actions?.onOk?.preset || 'brand'
      if (actions?.onOk) AlertModalAction.onOk = actions.onOk
      if (actions?.onCancel) {
        AlertModalAction.onCancel = actions.onCancel
        self.cancelText = actions?.onCancel?.text || 'Cancel'
        self.cancelPreset = actions?.onCancel?.preset || 'brandDisabled'
      }
    },
    cancel: () => {
      AlertModalAction.onOk = null
      AlertModalAction.onCancel = null
      self.okText = ''
      self.cancelText = ''
    }
  }))
  .views((self) => ({}))
  .create({})
