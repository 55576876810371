import React from 'react';
import {View, Modal, StyleSheet, TouchableOpacity, SafeAreaView, StyleProp, ViewStyle} from 'react-native';
import {color} from "../../theme";
import {BREAKPOINT_MOBILE, isDesktop} from "../../utils/global";

export const tagPresets = {
  default: {},
  pvModal: {
    paddingTop: 20, paddingBottom: 20, paddingHorizontal: 0
  },
  zeroPad: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  zeroPadDesktop: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  spin: {
    paddingVertical: 20,
    paddingHorizontal: 20,
    alignSelf: 'center'
  },
}
export type TagPresets = keyof typeof tagPresets

export const modalContentPresets = {
  default: {},
  zeroPadDesktop: {
    maxWidth: 576,
  },
}

export interface BackdropModalProps {
  visible: boolean
  onClose: () => void
  children: React.ReactNode
  preset?: TagPresets,
  contentStyle?: StyleProp<ViewStyle>
}

export function BackdropModal(props: BackdropModalProps) {
  const {visible, onClose, children, contentStyle, preset} = props;
  const modalViewStyle = [
    styles.modalView,
    tagPresets[preset] || tagPresets.default
  ]
  const _contentStyle = [contentStyle, modalContentPresets[preset] || modalContentPresets.default]

  if (!visible) return null
  return (
    <Modal
      animationType='fade'
      transparent={true}
      visible={visible}
      onRequestClose={onClose}
    >
      <View style={styles.modalContainer}>
        <TouchableOpacity style={styles.modalBackdrop} onPress={onClose}/>
        <SafeAreaView style={[styles.modalFullWidth, _contentStyle, isDesktop && {width: BREAKPOINT_MOBILE - 210}]}>
          <View style={modalViewStyle}>
            {children}
          </View>
        </SafeAreaView>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modalBackdrop: {
    backgroundColor: '#00000055',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9,
  },
  modalContainer: {flex: 1, justifyContent: 'center', alignItems: 'center'},
  modalFullWidth: {
    width: '82%',
    zIndex: 99,
  },
  modalView: {
    paddingVertical: 40,
    paddingHorizontal: 20,
    backgroundColor: color.palette.white,
    borderRadius: 10,
  },
});
