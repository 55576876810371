export const constants = {
    theme : {
        icon : {
            REGULAR : 'regular',
            BRANDS : 'brands',
            DUOTONE : 'duotone',
            LIGHT : 'light',
            SOLID : 'solid',
        },
        PRIMARY : 'primary',
        WARNING : 'warning',

    },
    AVAILABLE : 'available',
    UNAVAILABLE : 'unavailable',
    roles : {
        GUEST : 'guest',
        USER : 'user',
        WORKER : 'worker',
        BUSINESS : 'business',
        MANAGER : 'business_manager',
    },
    rolesName : {
        GUEST : 'Guest',
        USER : 'User',
        WORKER : 'Worker',
        BUSINESS : 'Business',
        MANAGER : 'Business Manager',
    },
    messageType : {
        TEXT : 'text',
    }
};

export const MAX_FILE_SIZE = 2 * 1024 * 1024
export const DEFAULT_LIMIT = 100
export const DEFAULT_MD_LIMIT = 500
export const DEFAULT_PAGE_LIMIT = 50
export const MIN_PASS_LENGTH = 8

export const NotificationType = {
  business_access: 'business_access',
  contact_invitation: 'contact_invitation',
  application_submitted: 'application_submitted',
  job_ad_closed: 'job_ad_closed',
  new_message: 'new_message',
}