import React, {FC, useRef} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {selectTextFieldPresets} from "../text-field/select-text-field";

export type SelectTextFieldPresets = keyof typeof selectTextFieldPresets

export interface DatePickerFieldProps {
  visible: boolean
  value: any
  onChange: any
  customInput?: any
  maxDate?: any
}

export const DatePickerField: FC<DatePickerFieldProps> = (props) => {
  const {visible, value, maxDate, ...rest} = props;
  const dateRef = useRef<DatePicker>()

  return (
    <DatePicker
      ref={dateRef}
      selected={value}
      dateFormat="dd/MM/yyyy"
      maxDate={maxDate}
      {...rest}
    />
  );
}
