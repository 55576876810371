import React, {FC} from "react"
import {FlatList, View} from "react-native"
import {IconButton, Text} from "../../../components"
import theme from "../../../theme/base_fonts"
import {searchIgnoreCase} from "../../../utils/utils";
import {BusinessJobRoleCard} from "../../../components/card/job-card";
import {IWorkerApplicationModel} from "../../../models/app/application";

export interface MyApplicationTabProps {
  onPress: (item: IWorkerApplicationModel) => void
  onLogoPress?: (id: number) => void
  search: string
  jobApplications: Array<IWorkerApplicationModel>
  refreshing: boolean
  loadData?: () => void
}

export const MyApplicationTab: FC<MyApplicationTabProps> = (
  {onPress, onLogoPress, search, jobApplications, refreshing, loadData}
) => {
  const renderItem = ({item}: { item: IWorkerApplicationModel }) => {
    return <BusinessJobRoleCard
      onLogoPress={onLogoPress}
      messageCount={true} applicantCount={false}
      workerApplication={item} item={item?.jobRole} onPress={() => onPress(item)}/>
  }

  const filteredData = search ? jobApplications
    ?.filter(item => (
      searchIgnoreCase(item?.jobRole?.jobAd?.title, search)
    )) : jobApplications
  return (
    <View style={{flex: 1}}>
      <View style={{flexDirection: "row", marginHorizontal: 20}}>
        <Text preset="total" text={"Total " + filteredData.length}/>
        <View style={{flex: 1, alignItems: "flex-end"}}>
          <IconButton
            textPreset={"total"}
            text="Date"
            iconProps={{
              icon: "caret-down",
              iconStyle: {
                width: theme.size.button,
                height: theme.size.button,
              },
            }}
          />
        </View>
      </View>
      <View style={{marginHorizontal: 5, marginTop: 16, flex: 1}}>
        <FlatList
          onRefresh={loadData} refreshing={refreshing}
          data={filteredData}
          renderItem={renderItem}
          ListFooterComponent={<View style={{height: 100}}/>}
          keyExtractor={(item, index) => String(item.id)}
        />
      </View>
    </View>
  )
}
