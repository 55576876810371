import React, {ReactNode} from "react"
import {
  StyleProp,
  TextProps as TextProperties,
  TextStyle,
  Pressable,
  View,
  ViewStyle
} from "react-native"
import {color} from "../../theme"
import {translate, TxKeyPath} from "../../i18n"
import {Text} from "../text/text"
import fonts from "../../theme/base_fonts";
import {SvgIcon} from "../icon/svg-icon";
import {TextPresets} from "../text/text.presets"
import {SvgIconPresets, ThemeTypes} from "../icon/svg-icon.props"
import {isAndroid} from "../../utils/platform";

// the base styling for the container
const CONTAINER: ViewStyle = {
  paddingVertical: 0,
}

// the base styling for the TextInput
const LABEL: TextStyle = {
  marginBottom: 5,
}

const INPUT: TextStyle = {
  ...fonts.style.body1,
  color: color.palette.dark,
  paddingVertical: isAndroid ? 14 : 16,
  paddingHorizontal: 12,
  fontSize: 15,
}
export const INPUT_PLACEHOLDER: TextStyle = {
  ...INPUT,
  color: color.palette.black30,
}

export const selectTextFieldPresets = {
  default: {},
  brand: {},
  primary: {},
  primaryDisabled: {},
  light: {},
  transparent: {},
  transparentSm: {},
  itemSelected: {},
  customItemSelected: {},
  link: {
    backgroundColor: color.palette.bgBlue,
    borderRadius: 8
  },
  lightLink: {
    backgroundColor: color.palette.white,
  },
  tinyPill: {},
  chatSuggest: {},
}

export type SelectTextFieldPresets = keyof typeof selectTextFieldPresets

const inputPresets = {
  default: {},
  brand: {},
  primary: {},
  primaryDisabled: {},
  light: {},
  transparent: {},
  transparentSm: {paddingVertical: 10},
  itemSelected: {},
  customItemSelected: {},
  lightLink: {
    backgroundColor: color.palette.white,
    color: color.palette.blue,
    fontWeight: 'bold',
  },
  textBold: {fontWeight: 'bold', color: color.palette.black80},
  link: {
    borderRadius: 8,
    color: color.palette.blue,
    fontWeight: 'bold',
  },
  chatSuggest: {}
}

const wrapInputPresets = {
  default: {
    borderRadius: 8, backgroundColor: color.palette.greyLight
  },
  brand: {
    borderRadius: 8,
    borderWidth: 1, borderColor: color.palette.greyMedH
  },
  primary: {
    borderWidth: 1.5, borderColor: '#BBC2DC1A', borderRadius: 8,

    backgroundColor: "#FFFFFF",
    shadowColor: isAndroid ? color.palette.black : color.palette.black,
    shadowOffset: {width: 0, height: isAndroid ? 8 : 5},
    shadowOpacity: isAndroid ? 0.01 : 0.1,
    shadowRadius: 3,
    elevation: 8,
  },
  primaryDisabled: {
    borderWidth: 1.5, borderColor: '#BBC2DC1A', borderRadius: 8,

    backgroundColor: color.palette.greyLight,
    shadowColor: isAndroid ? color.palette.black : color.palette.black,
    shadowOffset: {width: 0, height: isAndroid ? 8 : 5},
    shadowOpacity: isAndroid ? 0.01 : 0.1,
    shadowRadius: 3,
    elevation: 8,
  },
  light: {
    borderWidth: 2, borderColor: color.palette.bgBlue, borderRadius: 8
  },
  transparent: {
    borderWidth: 1, borderColor: color.palette.transparent, borderRadius: 8
  },
  transparentSm: {
    borderWidth: 1, borderColor: color.palette.transparent, borderRadius: 8
  },
  itemSelected: {
    borderWidth: 1, borderColor: color.palette.secondary, borderRadius: 8
  },
  customItemSelected: {
    borderWidth: 1, borderColor: color.palette.secondary, borderRadius: 8
  },
  lightLink: {
    backgroundColor: color.palette.white,
  },
  textBold: {},
  link: {
    backgroundColor: color.palette.bgBlue,
    borderRadius: 8
  },
  chatSuggest: {
    paddingVertical: 2,
    borderRadius: 8, backgroundColor: color.palette.bgBlue
  },
}

export interface SelectTextFieldProps extends TextProperties {
  value?: string
  onPress?: (e) => void
  /**
   * The placeholder i18n key.
   */
  placeholderTx?: TxKeyPath

  /**
   * The Placeholder text if no placeholderTx is provided.
   */
  placeholder?: string

  /**
   * The label i18n key.
   */
  labelTx?: TxKeyPath

  /**
   * The label text if no labelTx is provided.
   */
  label?: string

  /**
   * Optional container style overrides useful for margins & padding.
   */
  style?: StyleProp<ViewStyle>

  /**
   * Optional style overrides for the input.
   */
  inputStyle?: StyleProp<TextStyle>

  /**
   * Various look & feels.
   */
  preset?: SelectTextFieldPresets
  inputPreset?: keyof typeof inputPresets

  forwardedRef?: any

  leftIcon?: string
  leftIconTheme?: ThemeTypes
  leftIconPreset?: SvgIconPresets
  leftIconPress?: (e) => void
  rightIcon?: string
  rightIconTheme?: ThemeTypes
  rightIconPreset?: SvgIconPresets
  rightIconPress?: (e) => void
  leftOuterIcon?: string
  rightOuterIcon?: string
  rightOuterIconPreset?: SvgIconPresets
  rightOuterIconPress?: (e) => void

  labelPreset?: TextPresets
  numberOfLines?: number
  children?: ReactNode
}

/**
 * A component which has a label and an input together.
 */
export function SelectTextField(props: SelectTextFieldProps) {
  const {
    value,
    onPress,
    placeholderTx,
    placeholder,
    labelTx,
    label,
    preset = "default",
    inputPreset,
    style: styleOverride,
    inputStyle: inputStyleOverride,
    forwardedRef,
    leftIcon,
    leftIconTheme,
    leftIconPress,
    leftIconPreset,
    rightIcon,
    rightIconTheme,
    rightIconPress,
    rightIconPreset = "innerTextField",
    rightOuterIcon,
    rightOuterIconPreset,
    rightOuterIconPress,
    labelPreset,
    numberOfLines,
    ...rest
  } = props

  const containerStyles = [CONTAINER, selectTextFieldPresets[preset], styleOverride]
  const wrapInputStyles = [
    {flex: 1, flexDirection: 'row', minHeight: 48} as ViewStyle,
    wrapInputPresets[inputPreset] || wrapInputPresets[preset] || wrapInputPresets.default,
  ]
  const inputStyles = [
    {flex: 1},
    value ? INPUT : INPUT_PLACEHOLDER,
    leftIcon ? {paddingLeft: 16} : {},
    inputPresets[inputPreset] || inputPresets[preset] || inputPresets.default,
    inputStyleOverride
  ]
  const actualPlaceholder = placeholderTx ? translate(placeholderTx) : placeholder || ''

  return (
    <View style={containerStyles}>
      {label || labelTx ? (
        <Text preset={labelPreset} tx={labelTx} text={label} style={LABEL}/>
      ) : null}
      <Pressable onPress={onPress} style={{flexDirection: 'row'}}>
        <View style={wrapInputStyles}>
          {leftIcon && (
            <View style={{marginLeft: 16, justifyContent: 'center'}}>
              <SvgIcon
                icon={leftIcon} preset={leftIconPreset}
                theme={leftIconTheme || 'regular'}
                onPress={leftIconPress}/>
            </View>
          )}
          {props?.children ? props?.children : (
            <Text
              text={value !== null ? value || ' ' : actualPlaceholder}
              {...rest}
              style={inputStyles}
              {...(numberOfLines ? {numberOfLines} : {})}
            />
          )}
          {rightIcon && (
            <View style={{marginRight: 16, justifyContent: 'center'}}>
              <SvgIcon
                icon={rightIcon} preset={rightIconPreset}
                theme={rightIconTheme || 'regular'}
                onPress={rightIconPress}/>
            </View>
          )}
        </View>
        {rightOuterIcon && (
          <View style={{justifyContent: 'center', paddingHorizontal: 10}}>
            <SvgIcon icon={rightOuterIcon} preset={rightOuterIconPreset} onPress={rightOuterIconPress}/>
          </View>
        )}
      </Pressable>
    </View>
  )
}
