import * as React from "react"
import {View, SafeAreaView, ScrollView, ViewStyle, TouchableOpacity} from "react-native"
import {FC, useEffect, useState} from "react"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {CommonActions, RouteProp} from '@react-navigation/native';
import {Text, TextField} from "../../../components"
import {color} from "../../../theme";
import {Header} from "../../../components";
import {Button} from "../../../components"
import {SvgIcon} from "../../../components/icon/svg-icon";
import {observer} from "mobx-react-lite";
import {ParamListBase} from "@react-navigation/native";
import {BackdropModal} from "../../../components/backdrop-modal/backdrop-modal";
import {SelectTextField} from "../../../components/text-field/select-text-field";
import {PickerItemOption, PickerSelectField} from "../../../components/picker-select/picker-select";
import {useStores} from "../../../models";
import {LoadingModal} from "../../../components/backdrop-modal/loading-modal";
import {DefaultKvItemFile, IKvItemFileModel, KvItemPath} from "../../../models/app/kv-item";
// import {ImagePickerResponse} from "react-native-image-picker/src/types";
// import {ImageLibraryOptions, launchImageLibrary} from "react-native-image-picker";
import {useFilePicker} from "use-file-picker";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";
import {NavigatorParamList} from "../../../navigators";
import {identifyWithUserAccount, TrackEvents} from "../../../services/track";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import {constants} from "../../../utils/constants";
import {AvatarImage} from "../../../components/avatar-image/avatar-image";
import {JobTagRow} from "../../../components/tag/job-tag-row";
import {AdvertRoleStore} from "../../../models/app/advert-role";
import {CreateJobTags} from "../../../models/app/job-advert";
import {WorkerWalletItemPath} from "../../../models/app/advanced-skill";

let timeoutVal: NodeJS.Timeout = null

export interface CreateWorkerProfileScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'createWorkerProfileScreen'>
}

const defaultPhoto = require("../../../../assets/images/default-photo.png")

export const CreateWorkerProfileScreen: FC<CreateWorkerProfileScreenProps> = observer(({navigation, route}) => {
  const {app} = useStores();
  const preferredLocations = app.userAccount.workerProfile?.preferredLocations;
  const [loading, setLoading] = useState(true)

  const [industryList, setIndustryList] = useState<Array<PickerItemOption>>([]);
  const [industry, setIndustry] = useState(null);
  const [avatar, setAvatar] = useState<IKvItemFileModel>(DefaultKvItemFile)
  const [bio, setBio] = useState('');
  const [openFileSelector, {plainFiles}] = useFilePicker({
    accept: 'image/*',
    maxFileSize: 2,
  });
  const [openCvFileSelector, fileCvResult] = useFilePicker({
    accept: ['.pdf', '.doc', '.docx', 'image/*'],
    maxFileSize: 2,
  });
  const cvPlainFiles = fileCvResult.plainFiles
  let myCvDoc = !Array.isArray(cvPlainFiles) || cvPlainFiles.length <= 0 ? null : cvPlainFiles[0];

  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true)
      try {
        const industryRes = await app.environment.api.getKvItemList(KvItemPath.industry)
        if (industryRes.kind === 'ok') {
          setIndustryList(industryRes?.items?.map(it => ({
            key: it.id, value: it.id, label: it.name,
          })))
        }
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const goHome = () => {
    if (route?.params?.jobRoleId) {
      navigation.goBack()
      return
    }
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [
          {name: 'main'},
        ],
      })
    );
  }

  const handlePickFile = async () => {
    await openCvFileSelector()
  }

  const onPickerResult = async (response: File[]) => {
    if (Array.isArray(response) && response.length > 0) {
      setLoading(true);
      try {
        let firstAsset = response[0];
        if (avatar.id) await app.environment.api.deleteFile(avatar.id)
        const res = await app.environment.api.uploadFile(firstAsset)
        if (res.kind === 'ok') {
          setAvatar({id: res.file.id, name: res.file.name, url: res.file.url})
        } else {
          AlertModalStore.alert('Error', 'Can not upload file')
        }
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false);
      }
    }
  }
  const handleUploadImage = async () => {
    await openFileSelector()
  }
  useEffect(() => {
    onPickerResult(plainFiles)
  }, [plainFiles])

  const handleAvatarPress = async () => {
    if (avatar?.id) {
      try {
        setLoading(true)
        await app.environment.api.deleteFile(avatar.id)
        setAvatar(DefaultKvItemFile)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    } else handleUploadImage()
  }


  const handleSave = async (): Promise<boolean> => {
    if (!industry) {
      AlertModalStore.alert('Error', 'Please enter the require fields.')
      return false
    }
    try {
      setLoading(true)
      const getMeRes = avatar.id
        ? await app.environment.api.onUpdateUser({avatar: avatar.id}) : await app.environment.api.getMe()
      if (getMeRes.kind !== 'ok') {
        AlertModalStore.alert('Error', getMeRes.message)
        return false
      }
      const workerProfile = getMeRes?.userAccount?.workerProfile
      let workerProfileId = 0

      let myCv = null
      if (myCvDoc) {
        const res = await app.environment.api.uploadFile(myCvDoc)
        if (res.kind === 'ok') myCv = ({id: res.file.id, name: res.file.name})
      }

      if (workerProfile?.id) {
        const updateRes = await app.environment.api.updateWorkerProfileData(workerProfile.id, {
          bio: bio,
          ...(industry ? {industry: parseInt(industry)} : null),
          preferredLocations: preferredLocations?.map(it => it.id),
        })
        if (updateRes.kind !== 'ok') {
          AlertModalStore.alert('Error', updateRes.message)
          return false
        }
        workerProfileId = workerProfile?.id
      } else {
        const res = await app.environment.api.createWorkerProfileData({
            bio: bio,
            ...(industry ? {industry: parseInt(industry)} : null),
            preferredLocations: preferredLocations?.map(it => it.id),
          }
        )
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return false
        }
        workerProfileId = res.workerProfile.id
      }
      if (myCv?.id) {
        await app.environment.api.editWorkerWalletItem(WorkerWalletItemPath.workerCvs, {
          url: {id: myCv?.id},
          workerProfile: workerProfileId,
        })
      }
      await app.user.setRole(constants.roles.WORKER)
      app.environment.api.onUpdateUser({lastUserRole: {key: constants.roles.WORKER}})
      const updateRes = await app.updateUserProfile({
        workerProfile: workerProfileId,
      })
      if (updateRes.kind !== 'ok') {
        AlertModalStore.alert('Error', updateRes.message)
        return false
      }
      await identifyWithUserAccount(TrackEvents.SetupWorkerProfile, app?.userAccount)
      return true
    } catch (e) {
      console.warn(e)
    } finally {
      setLoading(false)
    }
    return false
  }

  const handleSaveDone = async () => {
    setModalVisible(false)
    navigation.goBack()
  }
  const handleCloseResult = async () => {
    if (timeoutVal) clearTimeout(timeoutVal)
    handleSaveDone()
  }
  const showResult = () => {
    if (timeoutVal) clearTimeout(timeoutVal)
    setModalVisible(true)
    timeoutVal = setTimeout(handleCloseResult, 1000)
  }

  const handleSaveOnly = async () => {
    const saved = await handleSave()
    if (saved) showResult()
  }

  const handleSaveAndCont = async () => {
    const saved = await handleSave()
    if (saved) navigation.navigate('workerSkillEditScreen', {isCreate: true})
  }

  return (
    <>
      <PureBottomModal onClose={() => navigation.goBack()}>
        <SafeAreaView style={CONTAINER}>
          <Header
            preset={"modalHeader"}
            headerText="Create your worker profile" titlePreset={'headerTitle'}
            style={{marginLeft: 10, marginRight: 20, marginTop: 20}}
            rightIcon="times" rightIconPreset="smallBlue" onRightPress={() => goHome()}
          />
          <ScrollView style={{paddingHorizontal: 20, marginTop: 10}} showsVerticalScrollIndicator={false}>
            <View style={{alignItems: 'center', marginBottom: 20}}>
              <View>
                <AvatarImage
                  onPress={handleAvatarPress}
                  preset={'lgRound'}
                  source={avatar.url ? {uri: avatar.url} : defaultPhoto}/>
                <View style={{position: 'absolute', right: 4, top: 4, display: avatar?.id ? 'flex' : 'none'}}>
                  <SvgIcon onPress={handleAvatarPress} preset={'dark'} icon={'times'} theme={'regular'}/>
                </View>
                <View style={{position: 'absolute', right: 4, bottom: 4}}>
                  <SvgIcon onPress={handleUploadImage} preset={'dark'} icon={'camera'} theme={'solid'}/>
                </View>
              </View>
            </View>
            <View style={{marginBottom: 20}}>
              <View style={{marginBottom: 18, zIndex: 99}}>
                <PickerSelectField
                  onValueChange={setIndustry}
                  items={industryList}
                  label={'Industry*'} labelPreset={'primary'}
                  placeholder="Select from list"
                  rightIcon={'chevron-down'}
                />
              </View>
              <View style={{flex: 1, marginBottom: 20}}>
                <SelectTextField
                  preset={'primary'} numberOfLines={1}
                  placeholder="Location"
                  label={'Preferred Location(s)'} labelPreset={'primary'}
                  onPress={() => navigation.navigate('workerPreferredLocationScreen')}
                />
                <View style={{marginTop: 4, flex: 1}}>
                  {app.userAccount?.workerProfile?.preferredLocations?.map((item) => (
                    <JobTagRow
                      key={String(item?.id)}
                      text={item.name} tagStyle={{marginBottom: 0}}
                      onPress={() => AdvertRoleStore.toggleSelectRoleIndex(CreateJobTags.qualifications.id, item.id)}/>
                  ))}
                </View>
              </View>
              <View style={{marginBottom: 24}}>
                <TextField
                  value={bio}
                  onChangeText={setBio}
                  preset={'primary'}
                  placeholder="Few lines about yourself"
                  label="Biography" labelPreset="primary"
                  multiline
                  inputStyle={{height: 120}}
                />
              </View>
              <View style={{marginBottom: 18}}>
                <SelectTextField
                  preset={'primary'} value={myCvDoc?.name || ''}
                  onPress={handlePickFile}
                  placeholder={'Upload CV'}
                  label={'My CV'} labelPreset={'primary'}
                  rightIcon={'file-upload'} rightIconPreset={'small'}
                />
              </View>
              <View style={BUTTON_CONTAINER}>
                <Button
                  onPress={handleSaveOnly}
                  style={{flex: 1}}
                  text="Done" preset="primary"/>
                <View style={{width: 20}}/>
                <TouchableOpacity
                  onPress={handleSaveAndCont}
                  style={{flex: 1, alignItems: 'flex-end', justifyContent: 'center'}}>
                  <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <Text
                      text="Skills"
                      preset="underTextFieldButton"
                    />
                    <View style={{width: 6}}/>
                    <SvgIcon icon={'arrow-right'} preset={'small'}/>
                  </View>
                  <Text
                    text="Save & Continue"
                    preset="iconButton"
                  />
                </TouchableOpacity>
              </View>
            </View>
          </ScrollView>
          <BackdropModal visible={modalVisible} onClose={handleSaveDone}>
            <View style={{
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <SvgIcon preset={'alertIcon'} icon={'check-circle'}/>
              <Text style={{marginTop: 16}} preset="alertTitle">Done</Text>
            </View>
          </BackdropModal>
          <LoadingModal visible={loading}/>
        </SafeAreaView>
      </PureBottomModal>
      <AlertModal/>
    </>
  )
});

const CONTAINER: ViewStyle = {
  flex: 1,
  backgroundColor: color.palette.white,
}

const BUTTON_CONTAINER: ViewStyle = {
  marginVertical: 8,
  flexDirection: 'row',
  justifyContent: 'space-between',
}
