import React from 'react';
import {TextProps, TextStyle, View, ViewStyle} from 'react-native';
import {SvgIcon} from '../icon/svg-icon';
import {Text} from "../text/text"
import {color} from '../../theme';
import {SvgIconPresets, SvgIconProps, SvgStyle} from "../icon/svg-icon.props";
import {TextPresets} from "../text/text.presets";

const CONTAINER_STYLE: ViewStyle = {
  flexDirection: 'row',
  backgroundColor: color.palette.greyLight,
  paddingVertical: 6, paddingHorizontal: 12, marginRight: 4,
  borderRadius: 4,
  alignItems: 'center'
}
export const tagSpacePresets = {
  default: {width: 6},
}
export const tagPresets = {
  default: {},
  ping: {
    backgroundColor: color.palette.ping
  },
}

export interface TagProps {
  children: string,
  icon?: string,
  iconProps?: SvgIconProps,
  style?: SvgStyle,
  containerStyle?: ViewStyle,
  textStyle?: TextStyle,
  textProps?: TextProps,
  preset?: SvgIconPresets,
  textPreset?: TextPresets,
}

export function Tag(props: TagProps) {
  const {icon, children, containerStyle, textPreset, textStyle, textProps, iconProps, preset = "extraSmall"} = props;
  const _containerStyle = [
    CONTAINER_STYLE, tagPresets[preset] || tagPresets.default, containerStyle
  ]
  const _tagSpaceStyle = tagSpacePresets[preset] || tagSpacePresets.default

  return (
    <View style={_containerStyle}>
      {icon && <SvgIcon icon={icon} preset={preset} {...iconProps}/>}
      <View style={children ? _tagSpaceStyle : {width: 0}}/>
      <Text preset={textPreset} style={textStyle} {...textProps}>{children}</Text>
    </View>
  );
}
