import React, {FC} from "react"
import {View, ViewStyle, TextStyle, ScrollView, Pressable} from "react-native"
import {Text} from ".."
import {color} from "../../theme"
import {isAndroid} from "../../utils/platform";

export interface QuickFilterTagItem {
  id: string
  name: string
  dot: boolean
}

export interface QuickFilterTagProps {
  items: Array<QuickFilterTagItem>,
  onItemPress: (item: QuickFilterTagItem) => void,
  styleCustom?: any;
}

export const QuickFilterTag: FC<QuickFilterTagProps> = ({items, onItemPress, styleCustom}) => {
  return (
    <ScrollView horizontal={true} showsHorizontalScrollIndicator={false} style={[CONTAINER, styleCustom]}>
      {items.map(item => (
        <Pressable key={item.id} onPress={() => onItemPress(item)}>
          <View style={VIEW_CONTAINER}>
            {!!item.dot && (
              <View style={DOT}/>
            )}
            <Text preset={'body'} numberOfLines={1} text={item.name} style={TEXT}/>
          </View>
        </Pressable>
      ))}
    </ScrollView>
  )
}

export const CONTAINER: ViewStyle = {
  flex: 1,
  marginHorizontal: 20,
  flexDirection: "row",
}

export const VIEW_CONTAINER: ViewStyle = {
  flex: 1,
  width: 128,
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row",
  backgroundColor: color.palette.white,
  marginLeft: 4, marginRight: 8,
  paddingVertical: isAndroid ? 6 : 12,
  marginVertical: 10,
  paddingHorizontal: isAndroid ? 4 : 10,
  borderRadius: 100,

  borderWidth: 1, borderColor: '#D3D8ED',
  shadowColor: '#022964', shadowOpacity: 0.3,
  shadowOffset: {width: 1, height: 0}, shadowRadius: 4,
  elevation: 4
  // shadowColor: color.palette.shadow,
  // shadowOffset: {width: 0, height: 0},
  // shadowOpacity: 0.1,
  // shadowRadius: 4,
  // elevation: 5,
}

export const DOT: ViewStyle = {
  width: 8, height: 8, backgroundColor: color.palette.green, borderRadius: 30,
  marginLeft: 8,
  position: 'absolute',
  top: 8,
  left: 2
}

export const TEXT: TextStyle = {
  color: color.palette.greyHigh,
}
