import {TextStyle} from "react-native"
import {color} from "./color"

const size = {
  h1: 22,
  h2: 20,
  h3: 14,
  h4: 16,
  h5: 11,
  h6: 10,
  body: 16,
  body1: 15,
  body2: 12,
  button: 16,
  caption: 12,
  label: 14,

  input: 18,
  header: 24,
  regular: 17,
  medium: 16,
  small: 12,
  tiny: 8.5,
}

const type = {
  base: (size = 15, ncolor?) => {
    return {
      fontFamily: "ProximaNova-Regular",
      fontSize: size,
      color: ncolor || color.palette.black,
    } as TextStyle
  },
  bold: (size = 15, ncolor?) => {
    return {
      fontFamily: "ProximaNova-Bold",
      fontSize: size,
      color: ncolor || color.palette.black,
    } as TextStyle
  },
  medium: (size = 15, ncolor?) => {
    return {
      fontFamily: "ProximaNova-Semibold",
      fontSize: size,
      color: ncolor || color.palette.black,
    } as TextStyle
  },
  semibold: (size = 15, ncolor?) => {
    return {
      fontFamily: "ProximaNova-Semibold",
      fontSize: size,
      color: ncolor || color.palette.black,
    } as TextStyle
  },
  light: (size = 15, ncolor?) => {
    return {
      fontFamily: "ProximaNova-Regular",
      fontSize: size,
      color: ncolor || color.palette.black,
    } as TextStyle
  },
}


const style = {
  header: {
    ...type.base(size.header, color.palette.white)
  },
  h1: {
    ...type.semibold(size.h1),
  },
  h2: {
    ...type.semibold(size.h2), color: color.palette.dark
  },
  h3: {
    ...type.base(size.h3), fontWeight: '500',
  },
  h4: {
    ...type.bold(size.h4),
  },
  h5: {
    ...type.semibold(size.h5),
  },
  h6: {
    ...type.semibold(size.h6),
  },
  body: {
    ...type.base(size.body),
  } as TextStyle,
  body1: {
    ...type.base(size.body1),
  },
  body2: {
    ...type.base(size.body2),
  },
  button: {
    ...type.bold(size.button),
  },
  iconButton: {
    ...type.medium(size.button, color.palette.blue),
  },
  caption: {
    ...type.base(size.caption),
  },
  normal: {
    ...type.base(size.regular),
  },
  medium: {
    ...type.base(size.medium),
  },
  mediumBlue: {
    ...type.base(size.medium, color.palette.blue),
  },
  mediumLink: {
    ...type.bold(size.medium, color.palette.blue),
  },
  mediumBold: {
    ...type.base(size.medium),
    fontWeight: 'bold'
  },
  mediumBoldBlue: {
    ...type.base(size.medium, color.palette.blue),
    fontWeight: 'bold'
  },
  description: {
    ...type.base(size.medium),
  },
  label: {
    ...type.base(size.label),
  },

  input: {
    ...type.base(size.input),
  },
  groupMessageTitle: {
    ...type.bold(size.body, color.palette.black00),
  },
  groupMessageSubtitle: {
    ...type.base(size.label, color.palette.black00),
  },
  userMessageTitle: {
    ...type.base(size.body2, color.palette.orange),
  },
  userMessageSubtitle: {
    ...type.base(size.body1, color.palette.black80),
  },
  smallPrimary: {
    ...type.base(size.small, color.palette.blue),
  },
  smallPrimaryBold: {
    ...type.base(size.small, color.palette.blue),
    fontWeight: 'bold'
  },
  smallSemiBold: {
    ...type.semibold(size.small, color.palette.blue),
  },
  smallBold: {
    ...type.bold(size.small),
  },
  alertTitle: {
    ...type.semibold(20),
  },
  tabBarLabel: {
    ...type.semibold(10, color.palette.blue),
  },
  boldLabel: {
    ...type.bold(size.label),
  },
  underTextFieldButton: {
    ...type.bold(size.button, color.palette.blue)
  },
  tabActive: {
    ...type.bold(size.h3, color.palette.blue)
  },
  tabInActive: {
    ...type.bold(size.h3, color.palette.blueMed)
  },
  bigIconButton: {
    ...type.medium(size.button, color.palette.greyDark),
    flexGrow: 1,
  },
  settingText: {
    ...type.base(size.button, color.palette.black80),
    // fontWeight: 'bold',
    flexGrow: 1,
  },
  settingTitle: {
    ...type.base(18, color.palette.primary),
    fontWeight: '600',
    flexGrow: 1,
  },
}
const base_fonts = {
  type,
  size,
  style,
}
export default base_fonts
