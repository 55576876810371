/**
 * Welcome to the main entry point of the app. In this file, we'll
 * be kicking off our app.
 *
 * Most of this file is boilerplate and you shouldn't need to modify
 * it very often. But take some time to look through and understand
 * what is going on here.
 *
 * The app navigation resides in ./app/navigators, so head over there
 * if you're interested in adding screens and navigators.
 */
import "./i18n"
import { useState, useEffect } from "react";
import {SafeAreaProvider, initialWindowMetrics} from "react-native-safe-area-context"
import * as storage from "./utils/storage"
import {ErrorBoundary} from "./screens/shared/error/error-boundary"
import {
  useBackButtonHandler,
  AppNavigator,
  canExit,
  useNavigationPersistence,
} from "./navigators"
import {RootStore, RootStoreProvider, setRootStoreVal, setupRootStore} from "./models"
import {setupSegmentClient} from "./services/track";
import { AlertModal } from "./components/backdrop-modal/alert-modal";

// This puts screens in a native ViewController or Activity. If you want fully native
// stack navigation, use `createNativeStackNavigator` in place of `createStackNavigator`:
// https://github.com/kmagiera/react-native-screens#using-native-stack-navigator

export const NAVIGATION_PERSISTENCE_KEY = "NAVIGATION_STATE"

/**
 * This is the root component of our app.
 */
function App() {
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined)

  useBackButtonHandler(canExit)
  const {
    // initialNavigationState,
    onNavigationStateChange,
    isRestored: isNavigationStateRestored,
  } = useNavigationPersistence(storage, NAVIGATION_PERSISTENCE_KEY)

  // Kick off initial async loading actions, like loading fonts and RootStore
  useEffect(() => {
    ;(async () => {
      setupSegmentClient()
      const _rootStore = await setupRootStore()
      setRootStore(_rootStore)
      setRootStoreVal(_rootStore)
    })()
  }, [])

  // Before we show the app, we have to wait for our state to be ready.
  // In the meantime, don't render anything. This will be the background
  // color set in native by rootView's background color.
  // In iOS: application:didFinishLaunchingWithOptions:
  // In Android: https://stackoverflow.com/a/45838109/204044
  // You can replace with your own loading component if you wish.
  if (!rootStore || !isNavigationStateRestored) return null

  // otherwise, we're ready to render the app
  return (
    <RootStoreProvider value={rootStore}>
      <SafeAreaProvider initialMetrics={initialWindowMetrics}>
        <ErrorBoundary catchErrors={"always"}>
          <AppNavigator
            onStateChange={onNavigationStateChange}
          />
        </ErrorBoundary>
        <AlertModal/>
      </SafeAreaProvider>
    </RootStoreProvider>
  )
}

export default App
