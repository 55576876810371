import * as React from "react"
import {View, SafeAreaView, ViewStyle, ImageBackground, Dimensions, TouchableOpacity, Keyboard} from "react-native"
import {FC, useCallback, useState} from "react"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Text, TextField, Header, Button} from "../../components"
import {color} from "../../theme";


import {NavigatorParamList} from "../../navigators";
import {useStores} from "../../models";
import {observer} from "mobx-react-lite";
import {AlertModalStore} from "../../models/app/alert-modal-store";
import {AlertModal} from "../../components/backdrop-modal/alert-modal";
import {RouteProp, useFocusEffect, CommonActions} from "@react-navigation/native";
import {isAndroid} from "../../utils/platform";
import {PureBottomModal} from "../../components/backdrop-modal/pure-bottom-modal";
import {identifyWithUserAccount, TrackEvents} from "../../services/track";
import {IUserAccountModel} from "../../models/app/user-account";
import {constants} from "../../utils/constants";
import {BREAKPOINT_MOBILE, width} from "../../utils/global";
// import {createSavedJob} from "../../utils/job-ad";
import {LoadingModal} from "../../components/backdrop-modal/loading-modal";

const backgroundImage = require("../../../assets/images/icons/cloud_user.png")

export interface PasswordProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
  route: RouteProp<NavigatorParamList, 'signInPasswordScreen'>
}

const minPassLength = 8

export const PasswordScreen: FC<PasswordProps> = observer(({navigation, route}) => {
  const email = route.params?.email
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false)
  const {app} = useStores()
  const isDesktop = width > BREAKPOINT_MOBILE;
  useFocusEffect(useCallback(() => {
    Keyboard.dismiss()
  }, []))

  const onResendMail = async () => {
    const res = await app.environment.api.userResendMail(email);
    if (res.kind !== 'ok') {
      AlertModalStore.alert('Error', res.message)
    } else {
      AlertModalStore.alert('Success', 'Resend email success')
    }
  }

  const onNext = async () => {
    if (!email?.trim()) {
      AlertModalStore.alert('Error', `Email empty`)
      return
    }
    if (!password || password.length < minPassLength) {
      AlertModalStore.alert('Error', `Password must at least ${minPassLength} characters length`)
      return
    }
    setLoading(true)
    try {
      await app.setAuth(null)
      const res = await app.onLogin(email, password);
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message || 'Invalid password or email is not verify',
          res?.message === 'Your account email is not confirmed' ?
            {
              onOk: {text: 'Resend email', onPress: onResendMail},
              onCancel: {text: 'Cancel'}
            } : null)
        return
      }

      const getMe = await app.getMe();
      if (getMe.kind !== 'ok') {
        AlertModalStore.alert('Error', getMe.message || 'Invalid user info')
        return
      } else {
        await identifyWithUserAccount(TrackEvents.SignedIn, app?.userAccount)
        const userAccount: IUserAccountModel = getMe.userAccount
        if (constants.roles.MANAGER === userAccount?.lastUserRole?.key) {
          const res = await app.environment.api.getBusinessProfileDetail(userAccount?.lastBusinessProfile?.id)
          if (res.kind === 'ok') {
            await app.user.setRole(userAccount?.lastUserRole?.key)
            await app.user.setBusinessProfile(res.businessProfile)
          } else await app.user.setRole(constants.roles.USER)
        } else if (
          !!userAccount.userRoles.find(it => it.key === userAccount?.lastUserRole?.key)
          && [constants.roles.BUSINESS, constants.roles.WORKER].includes(userAccount?.lastUserRole?.key)
        ) {
          await app.user.setRole(userAccount?.lastUserRole?.key)
        } else {
          if (userAccount.userRoles.find(it => it.key === constants.roles.BUSINESS)) {
            await app.user.setRole(constants.roles.BUSINESS)
          } else if (userAccount.userRoles.find(it => it.key === constants.roles.WORKER)) {
            await app.user.setRole(constants.roles.WORKER)
          } else {
            await app.user.setRole(constants.roles.USER)
          }
        }

        // let fcmToken = await messaging().getToken();
        // app.environment.api.setDeviceToken(USER_TOKEN_PATH, {
        //   deviceToken: fcmToken,
        //   action: USER_TOKEN_ACTION.add
        // })

        // await createSavedJob(app, userAccount?.id)
        goHome()
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const goHome = () => {
    if (route?.params?.jobRoleId) {
      navigation.navigate('jobsAdDetailScreen', {id: route?.params?.jobRoleId})
    } else {
      // navigation.goBack()
      // navigation.navigate('main');
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [
            {name: 'main'},
          ],
        })
      );
      navigation.navigate('main');
      // setTimeout(() => window.location.reload(), 500)
    }
  }

  const heightImage = Dimensions.get('window').height

  return (
    <>
      <PureBottomModal onClose={() => navigation.goBack()} isStatic={true}>
        <SafeAreaView style={{flex: 1,}}>
          <ImageBackground source={backgroundImage} style={{
            width: isDesktop ? '100%' : document.body.clientWidth,
            height: heightImage,
            position: "absolute", top: isAndroid ? 0 : 20, left: 0, right: 0, bottom: 0, zIndex: -9,
            backgroundColor: color.palette.white
          }} imageStyle={{resizeMode: 'stretch'}}/>
          <View>
            <Header
              preset={"modalHeader"} style={{marginHorizontal: 10}}
              leftIcon={"arrow-left"} leftIconPreset={"normal"} onLeftPress={() => navigation.goBack()}
              rightIcon={"times"} rightIconPreset={"smallBlue"} onRightPress={() => navigation.goBack()}
            />
            <View style={{paddingHorizontal: 16, marginTop: 100}}>
              <View style={{marginHorizontal: isAndroid ? 4 : 0}}>
                <Text preset={"h1"}>Welcome back!</Text>
                <View style={{height: 10}}/>
                <Text preset={"body"} style={{
                  color: color.palette.greyLink, fontSize: 18, fontWeight: isAndroid ? 'bold' : '500', opacity: 0.84
                }}>Let’s get you signed in.</Text>
                <View style={{height: 19}}/>
              </View>
              <TextField
                value={password} onChangeText={setPassword}
                secureTextEntry={true}
                style={{marginBottom: 20}}
                preset={'primary'}
                placeholder={`Enter your password`}
                rightIcon="lock"

              />
              <View style={{marginHorizontal: isAndroid ? 4 : 0}}>
                <TouchableOpacity
                  onPress={() => {
                    navigation.goBack()
                    navigation.navigate('forgotPasswordScreen')
                  }}
                >
                  <Text preset={"body"} style={{
                    color: color.palette.greyLink, fontSize: 18, fontWeight: isAndroid ? 'bold' : '500', opacity: 0.84
                  }}>Forgot your password?</Text>
                </TouchableOpacity>
              </View>

              <View style={{marginHorizontal: isAndroid ? 4 : 0, marginTop: 20}}>
                <View style={BUTTON_CONTAINER}>
                  <Button
                    disabled={loading}
                    onPress={onNext}
                    text="Next" preset="brand"/>
                </View>
              </View>

            </View>
          </View>
        </SafeAreaView>
      </PureBottomModal>
      <AlertModal/>
      <LoadingModal visible={loading}/>
    </>
  )
});

const BUTTON_CONTAINER: ViewStyle = {
  marginVertical: 8,
}
