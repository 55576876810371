import React from "react"
import { StyleSheet, TouchableOpacity, View } from "react-native"
import { Text } from "../text/text"

interface Props{
  onPress: () => void
  isActive: boolean
  value: string
  styleCustom?: any
}

const TabButton = ({onPress, value, isActive, styleCustom}: Props) => {
  return(
    <TouchableOpacity onPress={onPress} style={[styles.wrapper, styleCustom]}>
      <View style={[styles.tab,isActive && styles.activeTab]}>
        <Text preset={'input'}
              style={[styles.text, isActive && styles.textActive]}>
          {value}
        </Text>
      </View>
    </TouchableOpacity>
  )
};

export default TabButton;

const styles = StyleSheet.create({
  activeTab:{
    borderColor: '#FF6700',
    borderWidth: 2,
  },
  tab:{
    borderColor: '#202AB4',
    borderWidth: 1,
    borderRadius: 22,
    paddingHorizontal: 8,
    paddingVertical: 5
  },
  textActive:{
    color: '#FF6700',
    fontWeight: '600'
  },
  text:{
    color: '#202AB4',
    fontWeight: '400'
  },
  wrapper:{
    marginRight: 20,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center'
  }
})