/**
 * The app navigator (formerly "AppNavigator" and "MainNavigator") is used for the primary
 * navigation flows of your app.
 * Generally speaking, it will contain an auth flow (registration, login, forgot password)
 * and a "main" flow which the user will use once logged in.
 */
import React, {useEffect, useRef} from "react"
import {ActivityIndicator, useColorScheme} from "react-native"
import {NavigationContainer, DefaultTheme, DarkTheme, useNavigationState} from "@react-navigation/native"
import {createNativeStackNavigator} from "@react-navigation/native-stack"
import {navigate, navigationRef} from "./navigation-utilities"
import {SplashScreen} from "../screens/shared/splash-screen";
import {useStores} from "../models";
import {constants} from "../utils/constants";
import {UserNavigator} from "./app/user-navigator";
import {WorkerNavigator} from "./app/worker-navigator";
import {BusinessUserNavigator} from "./app/business-user-navigator";
import {PublicNavigator} from "./app/public-navigator";
import {
  EmailCheckScreen,
  ForgotPasswordScreen,
  ForgotPasswordResultScreen,
  PasswordScreen
} from "../screens/public";
import {ResetPasswordScreen} from "../screens/public/reset-password-1";
import {ResetPasswordResultScreen} from "../screens/public/reset-password-2";
import {observer} from "mobx-react-lite";
import {
  BusinessProfileScreen,
  CreateWorkerProfileScreen,
  JobsAdDetailScreen,
  UserAccountEditScreen
} from "../screens/shared";
import {ChooseAccessScreen} from "../screens/shared/choose-access/choose-access";
import {ChooseAccessInviteScreen} from "../screens/shared/choose-access/choose-access-invite";
import {BusinessAccountProfileEditScreen} from "../screens/business/user-account/business-account-profile-edit";
import {BusinessAccountEditScreen} from "../screens/business/user-account/business-account-edit";
import {JobListScreen} from "../screens/shared/jobs-list";
import {LinkingOptions} from "@react-navigation/native/lib/typescript/src/types";
import {VerifyEmailScreen} from "../screens/public/verify-email-screen";
import {WorkerSkillEditScreen} from "../screens/worker/worker-skills/worker-skill-edit";
import {WorkerSkillDetailScreen} from "../screens/worker/worker-skills/worker-skill-detail";
import {WorkerSkillType} from "../models/app/worker-profile";
import {SearchScreen} from "../screens/shared/search";
import {JobFilterDetailScreen} from "../screens/shared/filter-screen/job-filter-detail";
import {JobTagType} from "../models/app/job-advert";
import {JobFilterScreen} from "../screens/shared/filter-screen/job-filter-screen";
import {BusinessNewMessageScreen} from "../screens/business/messages/business-new-message-screen";
import {WorkerApplicantMessageScreen} from "../screens/business/messages/worker-applicant-message-screen";
import {ContactPreviewScreen} from "../screens/public/contact-preview-screen";
import {ChooseAccessContactScreen} from "../screens/shared/choose-access/choose-access-contact";
import {SignInPasscodeScreen} from "../screens/public/sign-in-passcode-screen";
import {InAppBrowser} from "../screens/shared/in-app-browser";
import {WorkerPreferredLocationScreen} from "../screens/worker/preferences/locations";
import {UserNewMessageScreen} from "../screens/user/messages/user-new-message-screen";
import {BusinessLocationsScreen} from "../screens/business/user-account/business-locations";
import {WorkerAddSkillScreen} from "../screens/shared/skill-modal/worker-add-skill-screen";
import {WorkerSkillCategoryScreen} from "../screens/shared/skill-modal/worker-skill-category-screen";
import {WorkerAddQualificationScreen} from "../screens/shared/skill-modal/worker-add-qualification-screen";
import {WorkerQualificationEduScreen} from "../screens/shared/skill-modal/worker-qualification-edu-screen";
import {BusinessApplicantMessageScreen} from "../screens/business/messages/business-applicant-message-screen";
import {WorkerProfileScreen} from "../screens/business/worker-profile/worker-profile";
import {BusinessMessageScreen} from "../screens/business/messages/business-message-screen";
import {WorkerSelectQualificationScreen} from "../screens/shared/skill-modal/worker-select-qualification-screen";
import {WorkerSelectSkillScreen} from "../screens/shared/skill-modal/worker-select-skill-screen";
import {JobsAdPreviewScreen} from "../screens/shared/job-ad/jobs-ad-preview-screen";
import {JobStatusListScreen} from "../screens/business/job-adverts-manage/job-status-list-screen";
import {ApplicantProfile} from "../screens/business/applicant-profile/applicant-profile";
import {PreferencesScreen} from "../screens/worker/preferences/preferences-screen";
import {HeaderDk} from "../components/header/headerDk";
import {JobAdvertEditScreen} from "../screens/business/job-adverts-manage/job-edit-screen";
import {JobRoleInfoDetailScreen} from "../screens/business/job-adverts-manage/job-role-info-detail";
import {JobRoleAddContactScreen} from "../screens/business/job-adverts-manage/job-role-add-contact";
import {PublicJobAdvertEditScreen} from "../screens/public/public-job-edit-screen";
import {JobsAdInvitesDetailScreen} from "../screens/shared/job-ad/job-add-invites-detail";
import {JobWorkerProfile} from "../screens/business/job-adverts-manage/job-worker-profile";
import {WorkerGroupChatScreen} from "../screens/worker/chat/worker-group-chat-screen";
import {WorkerUploadDocumentScreen} from "../screens/worker/wallet/document/upload-document-screen";
import {UploadSelectTypeScreen} from "../screens/worker/wallet/document/upload-select-type-screen";
import {UploadEditDescriptionScreen} from "../screens/worker/wallet/document/upload-edit-description-screen";
import {WorkerDescriptionQualificationScreen} from "../screens/worker/wallet/document-description/worker-description-qualification-screen";
import {WorkerDescriptionSkillScreen} from "../screens/worker/wallet/document-description/worker-description-skill-screen";
import DetailCardScreen from "../screens/worker/wallet/detail-card-screen";
import {WorkerDetailsScreen} from "../screens/worker/worker-details";
import {WalletItemShareScreen} from "../screens/worker/wallet/wallet-item-share-screen";
import {WalletItemShareFinalScreen} from "../screens/worker/wallet/wallet-item-share-final-screen";
import CreateCardDetailWalletScreen from "../screens/worker/wallet/components/CreateCardDetail";
import {IPureWorkerWalletItem} from "../models/app/advanced-skill";
import {BulkMessageScreen} from "../screens/business/messages/bulk-message-screen";
import {AddExistingContactScreen} from "../screens/business/chat/add-existing-contact-screen";
import {CreateGroupChatScreen} from "../screens/business/chat/create-group-chat-screen";
import {EditGroupChatScreen} from "../screens/business/chat/edit-group-chat-screen";
import {DeleteGroupChatScreen} from "../screens/business/chat/delete-group-chat-screen";
import {BusinessGroupChatScreen} from "../screens/business/chat/business-group-chat-screen";
import {GroupRemoveContactScreen} from "../screens/business/chat/group-remove-contact-screen";
import {AddContactScreen} from "../screens/business/contacts/contacts-add";
import {DeleteContactScreen} from "../screens/business/contacts/contacts-delete";
import {GroupNewMessageScreen} from "../screens/business/messages/group-new-message-screen";
import {UseExistingContactScreen} from "../screens/business/chat/use-existing-contact-screen";
import {GroupNewMessageResponseScreen} from "../screens/business/messages/group-new-message-response-screen";
import TakePhotoModalScreen from "../screens/worker/wallet/components/TakePhotoModalScreen";

const MainStack = observer(() => {
  const {app} = useStores();
  const refFirstRender = useRef(true);
  const routes = useNavigationState(state => state?.routes);
  useEffect(() => {
    const params = routes[0].state?.routes[0]?.state?.routes[0]?.params;
    // @ts-ignore
    if (params && params.next && params.next === 'signInEmailCheckScreen' && refFirstRender.current) {
      let timeOut = setTimeout(() => {
        navigate('signInEmailCheckScreen')
        clearTimeout(timeOut);
      }, 1000);
      refFirstRender.current = false;
    }
  }, [routes]);
  app?.environment?.api?.setAuth(app?.auth)
  useEffect(() => {
    if (app?.auth) {
      (async () => {
        const response = await app.getMe()
        if (response.kind === 'unauthorized') {
          app.onSignOut()
        }
      })()
    }
  }, [app?.auth])
  useEffect(() => {
    app.getUserRoles()
    if (app?.auth) {
      // (async () => {
      //   let fcmToken = await messaging().getToken();
      //   app.environment.api.setDeviceToken(USER_TOKEN_PATH, {
      //     deviceToken: fcmToken,
      //     action: USER_TOKEN_ACTION.add
      //   })
      // })()
    }
  }, [])

  // app.setFirstLaunch(true)
  // app.user.setRole(constants.roles.GUEST)
  // const role = app.user.role || constants.roles.GUEST;

  // if (app?.user?.firstLaunch) return <IntroScreen/>
  if (!app?.auth) return <PublicNavigator/>
  switch (app?.user?.role) {
    case constants.roles.USER:
      return <UserNavigator/>;
    case constants.roles.WORKER:
      return <WorkerNavigator/>;
    case constants.roles.BUSINESS:
      return <BusinessUserNavigator/>;
    case constants.roles.MANAGER:
      return <BusinessUserNavigator/>;
    default:
      return <PublicNavigator/>;
  }
})

export type NavigatorParamList = {
  main: undefined
  splashScreen: undefined
  home: undefined
  signInPasscodeScreen: { email: string }
  signInEmailCheckScreen: { loadEmail?: boolean, jobRoleId?: number }
  verifyEmailScreen: { code?: string, email?: string }
  signInPasswordScreen: { jobRoleId?: number, email?: string }
  forgotPasswordScreen: undefined
  forgotPasswordResultScreen: undefined
  resetPasswordScreen: { code?: string }
  resetPasswordResultScreen: undefined
  chooseAccessScreen: undefined
  chooseAccessInviteScreen: { businessId?: number, code?: string }
  chooseAccessContactScreen: { contactId?: number, code?: string }
  userAccountEditScreen: { type: 'edit' | 'create', requirePassword?: boolean }
  businessLocationsScreen: undefined
  businessAccountEditScreen: { isCreate?: boolean }
  businessAccountProfileEditScreen: { skip?: boolean }
  jobsAdDetailScreen: { id: number }
  jobsAdInvitesDetailScreen: { id: number }
  jobsAdPreviewScreen: undefined
  jobListScreen: { activeTabId?: number, fullScreen?: boolean }
  workerSkillEditScreen: { isCreate?: boolean }
  workerSkillDetailScreen: { tag: WorkerSkillType }
  businessProfileScreen: { businessProfileId?: number }
  createWorkerProfileScreen: { jobRoleId?: number }
  searchScreen: undefined
  jobFilterScreen: { nextScreen?: string, replace: boolean }
  jobFilterDetailScreen: { tag: JobTagType }
  businessNewMessageScreen: { toUserId: number, messageId: number }
  userNewMessageScreen: { messageId: number }
  userApplicantMessageScreen: { toUserId?: number, applicationId?: number }
  contactPreviewScreen: undefined
  inAppBrowser: { url: string }

  jobStatusListScreen: { jobAdId?: number, showWorkerTab?: boolean }
  applicantProfile: { applicantIdList?: number[], index?: number }
  jobRoleInfoDetailScreen: { tag: JobTagType }
  jobAdvertEditScreen: { editId?: number }
  publicJobAdvertEditScreen: undefined
  jobRoleAddContactScreen: undefined
  businessApplicantMessageScreen: { toUserId: number, applicationId: number }
  businessMessageScreen: { groupId?: number, messageId?: number }

  bulkMessageScreen: undefined
  addExistingContactScreen: { isNeedSaveContact?: boolean, hideSelected?: boolean }
  useExistingContactScreen: undefined
  createGroupChatScreen: undefined
  editGroupChatScreen: { groupId: number, groupName: string }
  deleteGroupChatScreen: undefined
  businessGroupChatScreen: { groupId: number, groupName: string }
  groupRemoveContactScreen: { groupId?: number, groupName?: string }
  addContactScreen: undefined
  deleteContactScreen: undefined

  workerProfileScreen: { id?: number }
  jobWorkerProfile: {
    jobAdId?: number,
    id?: number
  }
  preferencesScreen: undefined
  workerPreferredLocationScreen: undefined
  workerUploadDocumentScreen: undefined
  workerSelectSkillScreen: undefined
  workerSelectQualificationScreen: undefined
  workerAddSkillScreen: { originId?: number }
  workerSkillCategoryScreen: undefined
  workerAddQualificationScreen: { originId?: number }
  workerQualificationEduScreen: undefined
  workerGroupChatScreen: { groupId: number, groupName: string }
  uploadSelectTypeScreen: undefined
  uploadEditDescriptionScreen: undefined
  workerDescriptionQualificationScreen: { qualificationEducationLevel: number }
  workerDescriptionSkillScreen: { skillCategory: number }
  detailCardScreen: IPureWorkerWalletItem;
  createCardDetailWallet: {
    fontId: number,
    backId: number,
  }
  workerDetailsScreen: undefined
  walletItemShareScreen: undefined
  walletItemShareFinalScreen: undefined
  groupNewMessageScreen: { groupId?: number }
  groupNewMessageResponseScreen: undefined
  takePhotoModalScreen: undefined
}

const Stack = createNativeStackNavigator<NavigatorParamList>()

const AppStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName="main"
    >
      <Stack.Screen name="main" component={MainStack}/>
      <Stack.Screen name="signInPasscodeScreen" component={SignInPasscodeScreen}
                    options={{presentation: 'transparentModal', animation: 'fade'}}/>
      <Stack.Screen name="signInEmailCheckScreen" component={EmailCheckScreen}
                    options={{presentation: 'transparentModal', animation: 'fade'}}/>
      <Stack.Screen name="verifyEmailScreen" component={VerifyEmailScreen}/>
      <Stack.Screen name="signInPasswordScreen" component={PasswordScreen}
                    options={{presentation: 'transparentModal', animation: 'fade'}}/>
      <Stack.Screen name="forgotPasswordScreen" component={ForgotPasswordScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name="forgotPasswordResultScreen" component={ForgotPasswordResultScreen}/>
      <Stack.Screen name="resetPasswordScreen" component={ResetPasswordScreen}/>
      <Stack.Screen name="resetPasswordResultScreen" component={ResetPasswordResultScreen}/>
      <Stack.Screen name="chooseAccessScreen" component={ChooseAccessScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name="chooseAccessInviteScreen" component={ChooseAccessInviteScreen}/>
      <Stack.Screen name="chooseAccessContactScreen" component={ChooseAccessContactScreen}/>
      <Stack.Screen name="userAccountEditScreen" component={UserAccountEditScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name='businessAccountEditScreen' component={BusinessAccountEditScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name="businessLocationsScreen" component={BusinessLocationsScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name='businessAccountProfileEditScreen' component={BusinessAccountProfileEditScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name="jobsAdDetailScreen" component={JobsAdDetailScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name="jobsAdInvitesDetailScreen" component={JobsAdInvitesDetailScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name="jobsAdPreviewScreen" component={JobsAdPreviewScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name="jobListScreen" component={JobListScreen} initialParams={{fullScreen: true}}/>
      <Stack.Screen name="splashScreen" component={SplashScreen}/>
      <Stack.Screen name="workerSkillEditScreen" component={WorkerSkillEditScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name="workerSkillDetailScreen" component={WorkerSkillDetailScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name="businessProfileScreen" component={BusinessProfileScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name={'createWorkerProfileScreen'} component={CreateWorkerProfileScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name={'searchScreen'} component={SearchScreen} options={{animation: 'fade'}}/>
      <Stack.Screen name={'jobFilterScreen'} component={JobFilterScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name={'jobFilterDetailScreen'} component={JobFilterDetailScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name={"contactPreviewScreen"} component={ContactPreviewScreen}/>
      <Stack.Screen name={"inAppBrowser"} component={InAppBrowser}
                    options={{presentation: 'transparentModal'}}/>

      <Stack.Screen name={"businessNewMessageScreen"} component={BusinessNewMessageScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name={"userNewMessageScreen"} component={UserNewMessageScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name='userApplicantMessageScreen' component={WorkerApplicantMessageScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name='businessMessageScreen' component={BusinessMessageScreen}/>
      <Stack.Screen name='businessApplicantMessageScreen' component={BusinessApplicantMessageScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name='jobStatusListScreen' component={JobStatusListScreen}/>
      <Stack.Screen name='applicantProfile' component={ApplicantProfile}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name='jobAdvertEditScreen' component={JobAdvertEditScreen}/>
      <Stack.Screen name='publicJobAdvertEditScreen' component={PublicJobAdvertEditScreen}/>
      <Stack.Screen name="jobRoleInfoDetailScreen" component={JobRoleInfoDetailScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}
      />
      <Stack.Screen name="jobRoleAddContactScreen" component={JobRoleAddContactScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name='bulkMessageScreen' component={BulkMessageScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name="addExistingContactScreen" component={AddExistingContactScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name="useExistingContactScreen" component={UseExistingContactScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name='createGroupChatScreen' component={CreateGroupChatScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name='editGroupChatScreen' component={EditGroupChatScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name='deleteGroupChatScreen' component={DeleteGroupChatScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name='businessGroupChatScreen' component={BusinessGroupChatScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name="groupRemoveContactScreen" component={GroupRemoveContactScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name='addContactScreen' component={AddContactScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name='deleteContactScreen' component={DeleteContactScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>

      <Stack.Screen name='workerProfileScreen' component={WorkerProfileScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name='jobWorkerProfile' component={JobWorkerProfile}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name="preferencesScreen" component={PreferencesScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name='workerPreferredLocationScreen' component={WorkerPreferredLocationScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name="workerUploadDocumentScreen" component={WorkerUploadDocumentScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name="workerSelectSkillScreen" component={WorkerSelectSkillScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name="workerSelectQualificationScreen" component={WorkerSelectQualificationScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name="workerAddSkillScreen" component={WorkerAddSkillScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name="workerSkillCategoryScreen" component={WorkerSkillCategoryScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name="workerAddQualificationScreen" component={WorkerAddQualificationScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name="workerQualificationEduScreen" component={WorkerQualificationEduScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name='workerGroupChatScreen' component={WorkerGroupChatScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name="uploadSelectTypeScreen" component={UploadSelectTypeScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}
      />
      <Stack.Screen name="uploadEditDescriptionScreen" component={UploadEditDescriptionScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}
      />
      <Stack.Screen name="workerDescriptionQualificationScreen" component={WorkerDescriptionQualificationScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}
      />
      <Stack.Screen name="workerDescriptionSkillScreen" component={WorkerDescriptionSkillScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}
      />
      <Stack.Screen name="detailCardScreen" component={DetailCardScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}
      />
      <Stack.Screen name="createCardDetailWallet" component={CreateCardDetailWalletScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}
      />
      <Stack.Screen name="workerDetailsScreen" component={WorkerDetailsScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name="walletItemShareScreen" component={WalletItemShareScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name="walletItemShareFinalScreen" component={WalletItemShareFinalScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name='groupNewMessageScreen' component={GroupNewMessageScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name='groupNewMessageResponseScreen' component={GroupNewMessageResponseScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
      <Stack.Screen name='takePhotoModalScreen' component={TakePhotoModalScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}/>
    </Stack.Navigator>
  )
}

interface NavigationProps extends Partial<React.ComponentProps<typeof NavigationContainer>> {
}

const linking: LinkingOptions<any> = {
  prefixes: [process.env.REACT_APP_SCHEMES],
  config: {
    initialRouteName: 'main',
    screens: {
      signInEmailCheckScreen: {
        path: 'sign-in'
      },
      verifyEmailScreen: {
        path: 'verify-email/:code'
      },
      resetPasswordScreen: {
        path: 'reset-password/:code'
      },
      chooseAccessContactScreen: {
        path: 'contact-invitation/:contactId/:code'
      },
      chooseAccessInviteScreen: {
        path: 'business-invitation/:businessId/:code'
      },
      jobsAdDetailScreen: {
        path: 'job-detail/:id'
      },
      signInPasscodeScreen: {
        path: 'verify-by-passcode'
      },
      detailCardScreen: {
        path: 'wallet-detail'
      },
      jobListScreen: {
        path: 'jobs'
      },
      inboxScreen: {
        path: 'intro'
      },
      main: {
        path: '',
        screens: {
          tabNavigator: {
            path: '',
            screens: {
              homeScreen: {
                path: ''
              }
            }
          }
        }
      },
    }
  }
};
export const NavLinking = linking

export const AppNavigator = (props: NavigationProps) => {
  const colorScheme = useColorScheme();
  return (
    <>
      <HeaderDk/>
      <NavigationContainer
        documentTitle={{
          enabled: true,
          formatter: (options) => options?.title || 'Tap4work - Find Jobs & Workers',
        }}
        linking={linking}
        fallback={<ActivityIndicator color="blue" size="large"/>}
        ref={navigationRef}
        theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
        {...props}
      >
        <AppStack/>
      </NavigationContainer>
    </>
  )
}

AppNavigator.displayName = "AppNavigator"

/**
 * A list of routes from which we're allowed to leave the app when
 * the user presses the back button on Android.
 *
 * Anything not on this list will be a standard `back` action in
 * react-navigation.
 *
 * `canExit` is used in ./app/app.tsx in the `useBackButtonHandler` hook.
 */
const exitRoutes = ["demoList"]
export const canExit = (routeName: string) => exitRoutes.includes(routeName)
