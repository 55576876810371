import {Instance, types} from "mobx-state-tree"
import {BusinessGroupModel, convertBusinessGroupModel} from "./contact";
import {convertUserAccountModel, UserAccountModel} from "./user-account";
import {convertKvItemModel, KvItemModel} from "./kv-item";
import {BusinessProfileModel, convertBusinessProfileModel} from "./business-profile";
import {convertFileUploadModel, FileUploadModel} from "./file-upload";

export const UserMessageModel = types.model({
  id: types.optional(types.number, 0),
  content: types.optional(types.string, ''),
  title: types.optional(types.string, ''),
  group: types.optional(BusinessGroupModel, {}),
  createdUser: types.optional(UserAccountModel, {}),
  workerResponses: types.optional(types.array(KvItemModel), []),
  businessProfile: types.maybeNull(BusinessProfileModel),
  newChatMessages: types.optional(types.number, 0),
  hideUserIds: types.optional(types.string, ''),
})
export type IUserMessageModel = Instance<typeof UserMessageModel>
export const convertUserMessageModel = (raw): IUserMessageModel => ({
  id: raw?.id || 0,
  content: raw?.content || '',
  title: raw?.title || '',
  group: raw?.group?.data?.attributes ? convertBusinessGroupModel({
    ...raw?.group?.data?.attributes,
    id: raw?.group?.data?.id,
  }) : raw?.group,
  createdUser: convertUserAccountModel(raw?.createdUser?.data?.attributes ? {
    ...raw?.createdUser?.data?.attributes, id: raw?.createdUser?.data?.id
  } : raw?.createdUser?.attributes ? {...raw?.createdUser?.attributes, id: raw?.createdUser?.id} : raw?.createdUser),
  workerResponses: raw?.workerResponses?.data?.map((item) => convertKvItemModel(
    {...item?.attributes, id: item?.id}
  )) || [],
  businessProfile: convertBusinessProfileModel(
    raw?.businessProfile?.data?.attributes ? {
      ...raw?.businessProfile?.data?.attributes,
      id: raw?.businessProfile?.data?.id
    } : raw?.businessProfile?.data ? raw?.businessProfile?.data : {
      ...raw?.owner?.data?.attributes?.businessProfile?.data?.attributes,
      id: raw?.owner?.data?.attributes?.businessProfile?.data?.id
    }
  ),
  newChatMessages: raw?.newChatMessages || 0,
  hideUserIds: raw?.hideUserIds || '',
})
export const GroupChatModel = types.model({
  id: types.optional(types.number, 0),
  title: types.optional(types.string, ''),
  createdAt: types.optional(types.string, ''),
})
export type IGroupChatModel = Instance<typeof GroupChatModel>
export const convertGroupChatModel = (raw): IGroupChatModel => ({
  id: raw?.id || 0,
  title: raw?.title || '',
  createdAt: raw?.createdAt || '',
})

export const UserChatModel = types.model({
  id: types.optional(types.number, 0),
  content: types.optional(types.string, ''),
  createdAt: types.optional(types.string, ''),
  from: types.optional(UserAccountModel, {}),
  to: types.optional(UserAccountModel, {}),
  message: types.optional(UserMessageModel, {}),
  file: types.optional(FileUploadModel, {})
})
export type IUserChatModel = Instance<typeof UserChatModel>
export const convertUserChatModel = (raw): IUserChatModel => ({
  id: raw?.id || 0,
  content: raw?.content || '',
  createdAt: raw?.createdAt || '',
  from: convertUserAccountModel(raw?.from?.data?.attributes ? {
    ...raw?.from?.data?.attributes, id: raw?.from?.data?.id
  } : raw?.from?.attributes ? {...raw?.from?.attributes, id: raw?.from?.id} : raw?.from),
  to: convertUserAccountModel(raw?.to?.data?.attributes ? {
    ...raw?.to?.data?.attributes, id: raw?.to?.data?.id
  } : raw?.to?.attributes ? {...raw?.to?.attributes, id: raw?.to?.id} : raw?.to),
  message: convertUserMessageModel(raw?.message?.data?.attributes ? {
    ...raw?.message?.data?.attributes, id: raw?.message?.data?.id
  } : raw?.message?.attributes ? {...raw?.message?.attributes, id: raw?.message?.id} : raw?.message),
  file: convertFileUploadModel(raw?.file?.data?.attributes ? {
    ...raw?.file?.data?.attributes, id: raw?.file?.data?.id
  } : raw?.document?.attributes ? {...raw?.document?.attributes, id: raw?.document?.id} : raw?.document)
})
