import React, { FC, useCallback, useState, useMemo } from "react"
import { Dimensions, SafeAreaView, ScrollView, View, TouchableOpacity } from "react-native"
import { Button, Header, Text } from "../../../components"
import { PureBottomModal } from "../../../components/backdrop-modal/pure-bottom-modal"
import { RouteProp, useFocusEffect } from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { NavigatorParamList } from "../../../navigators"
import { observer } from "mobx-react-lite"
import styles from "./styles"
import { color } from "../../../theme"
import { BottomModal } from "../../../components/backdrop-modal/bottom-modal"
import { SelectTypeSections } from "./document/upload-select-type-screen"
import { SvgIcon } from "../../../components/icon/svg-icon"
import { BackdropModal } from "../../../components/backdrop-modal/backdrop-modal"
import { useStores } from "../../../models";
import { AlertModal } from "../../../components/backdrop-modal/alert-modal";
import { AlertModalStore } from "../../../models/app/alert-modal-store";
import { GeneralApiResult } from "../../../services/api/api.types";
import {
  IWorkerWalletItemModel,
  KvQualificationPath,
  KvSkillPath,
  WorkerWalletItemPath
} from "../../../models/app/advanced-skill";
import { LoadingModal } from "../../../components/backdrop-modal/loading-modal";
import moment from "moment";
import { urlIsDoc } from "../../../utils/utils";
import { constants } from "../../../utils/constants";
import { ScaleImage } from "../../../components/auto-image/scale-image";

const windowHeight = Dimensions.get('window').height

export interface CardWalletDetailScreen {
  navigation: NativeStackNavigationProp<NavigatorParamList>
  route: RouteProp<NavigatorParamList, 'detailCardScreen'>
}

const DetailCardScreen: FC<CardWalletDetailScreen> = observer(({route, navigation}) => {
  let isCard: any = route.params?.isCard;
  isCard = ['false', '0'].includes(isCard) ? false : !!isCard
  const paramsData = route.params;
  const {app} = useStores()
  const [downloadModal, setDownloadModal] = useState('');
  const [isShowOptionModal, setShowOptionModal] = useState(false);
  const [isShowConfirmDelete, setShowConfirmDelete] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IWorkerWalletItemModel>();
  let url = data?.url?.url || ''
  const isDoc = urlIsDoc(url)
  if (isDoc) {
    url = 'https://docs.google.com/gview?embedded=true&url=' + url
  }
  const [isWorkerView, setIsWorkerView] = useState(false)
  const isAuth = app?.userAccount?.id && (app.user.role || constants.roles.GUEST) !== constants.roles.GUEST

  const loadData = async () => {
    setLoading(true)
    try {
      if (paramsData.key === SelectTypeSections.qualification.key) {
        const qualificationRes = await app.environment.api.getWorkerQualification({
          'filters[id][$eq]': paramsData?.id,
        })
        if (qualificationRes.kind === "ok" && qualificationRes.items?.length > 0) {
          const item = qualificationRes.items[0]
          setIsWorkerView(app?.userAccount?.id && item?.user?.id === app?.userAccount?.id)
          setData({
            id: item?.id, key: SelectTypeSections.qualification.key,
            type: item?.qualification?.qualificationEducationLevel?.name,
            description: item?.qualification?.courseName,
            url: item?.document,
            imgFont: '',
            imgBack: '',
            expiryDate: '', workerProfile: null,
            parentId: item?.qualification?.qualificationEducationLevel?.id, isCard: false,
            createdAt: item?.createdAt,
          })
        }
      } else if (paramsData.key === SelectTypeSections.skill.key) {
        const skillRes = await app.environment.api.getWorkerSkill({
          'filters[id][$eq]': paramsData?.id,
        })
        if (skillRes.kind === "ok" && skillRes.items?.length > 0) {
          const item = skillRes.items[0]
          setIsWorkerView(app?.userAccount?.id && item?.user?.id === app?.userAccount?.id)
          setData(isCard ? {
            id: item?.id,
            key: SelectTypeSections.skill.key,
            type: item?.skill?.skillCategory?.name,
            url: item?.document,
            description: item?.skill?.description,
            imgFont: item?.frontImage?.url,
            imgBack: item?.backImage?.url,
            expiryDate: item?.expiryDate, workerProfile: null,
            parentId: item?.skill?.skillCategory?.id, isCard: true,
            createdAt: item?.createdAt,
          } : {
            id: item?.id, key: SelectTypeSections.skill.key,
            type: item?.skill?.skillCategory?.name,
            url: item?.document,
            description: item?.skill?.description,
            imgFont: '',
            imgBack: '',
            expiryDate: '', workerProfile: null,
            parentId: item?.skill?.skillCategory?.id, isCard: false,
            createdAt: item?.createdAt,
          })
        }
      } else {
        const otherData = paramsData.key === SelectTypeSections.cv.key ? {
            id: WorkerWalletItemPath.workerCvs, data: SelectTypeSections.cv
          }
          : paramsData.key === SelectTypeSections.rightToWork.key ? {
              id: WorkerWalletItemPath.workerRightToWorks, data: SelectTypeSections.rightToWork
            }
            : paramsData.key === SelectTypeSections.other.key ? {
              id: WorkerWalletItemPath.workerOthers, data: SelectTypeSections.other
            } : null
        if (otherData) {
          const itemRes = await app.environment.api.getWorkerWalletItemList(otherData.id, {
            'filters[id][$eq]': paramsData?.id,
            populate: "url,workerProfile",
          })
          if (itemRes.kind === "ok" && itemRes.items?.length > 0) {
            const item = itemRes.items[0]
            setIsWorkerView(app?.userAccount?.id && item?.workerProfile?.id === app?.userAccount?.workerProfile?.id)
            setData({...item, type: otherData.data.name})
          }
        }
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useFocusEffect(useCallback(() => {
    loadData()
  }, []))

  const onClose = () => navigation.goBack()

  const handleDownloadAll = () => {
    setShowOptionModal(false)
    if (data?.imgFont) setTimeout(() => window.open(data?.imgFont), 200)
    if (data?.imgBack) setTimeout(() => window.open(data?.imgBack), 800)
    if (data?.url?.url) setTimeout(() => window.open(data?.url?.url), 600)
  }

  const handleDownload = () => {
    setDownloadModal('')
    window.open(downloadModal)
  }

  const handleDelete = async () => {
    setShowConfirmDelete(false)
    let res: GeneralApiResult = null
    if (paramsData.key === SelectTypeSections.qualification.key) {
      res = await app.environment.api.generalApiDelete(KvQualificationPath.workerQualifications, paramsData.id)
    } else if (paramsData.key === SelectTypeSections.skill.key) {
      res = await app.environment.api.generalApiDelete(KvSkillPath.workerSkills, paramsData.id)
    } else if (paramsData.key === SelectTypeSections.cv.key) {
      res = await app.environment.api.generalApiDelete(WorkerWalletItemPath.workerCvs, paramsData.id)
    } else if (paramsData.key === SelectTypeSections.rightToWork.key) {
      res = await app.environment.api.generalApiDelete(WorkerWalletItemPath.workerRightToWorks, paramsData.id)
    } else if (paramsData.key === SelectTypeSections.other.key) {
      res = await app.environment.api.generalApiDelete(WorkerWalletItemPath.workerOthers, paramsData.id)
    }
    if (res?.kind !== 'ok') {
      AlertModalStore.alert('Error', res?.message)
      return
    } else {
      AlertModalStore.alert('Success', 'Deleted')
      navigation.goBack()
      setTimeout(() => {
        AlertModalStore.setVisible(false)
      }, 1000)
    }
  }

  const renderFileView = useMemo(() => {
    if (!url) return null
    return isDoc ? (
      <View style={{flex: 1}}>
        <iframe height={windowHeight * 0.8} src={url}/>
      </View>
    ) : (
      <TouchableOpacity onPress={() => setDownloadModal(url)} style={{width: '100%', marginBottom: 12, marginTop: 25}}>
        <ScaleImage source={{uri: url}} style={{width: '100%', height: 400, resizeMode: 'contain'}}/>
      </TouchableOpacity>
    )
  }, [url])

  const nameHeader = !isCard ? 'Item Details' : 'Card Details'
  return (
    <>
      <PureBottomModal onClose={onClose}>
        <SafeAreaView style={{flex: 1}}>
          <Header
            preset={"modalHeader"}
            titlePreset={"headerTitle"}
            headerText={nameHeader}
            onRightPress={() => !isAuth ? onClose() : setShowOptionModal(true)}
            rightIcon={!isAuth ? 'times' : "ellipsis-h"}
            rightIconTheme={!isAuth ? 'regular' : "solid"}
            rightIconPreset="orange"
            leftIcon={!isAuth ? null : 'arrow-left'}
            leftIconPreset={'normal'}
            onLeftPress={onClose}
          />
          <ScrollView
            style={{flex: 1}} contentContainerStyle={{paddingTop: 18, paddingHorizontal: 20}}
            showsVerticalScrollIndicator={false}>
            <View>
              <Text preset="dark">{data?.type}</Text>
              {!!data?.description && (
                <Text preset="total"
                      style={{maxWidth: 250, color: color.palette.dark, marginTop: 10, marginBottom: 19}}>
                  {data?.description}
                </Text>
              )}
              {!!isCard && (
                <Text>Expiry: {
                  data?.expiryDate ? moment(data?.expiryDate).format('DD/MM/YYYY') : 'No Expiry'
                }</Text>
              )}
            </View>
            {!isCard ? renderFileView : (
              <View>
                {!!data?.imgFont && (
                  <TouchableOpacity onPress={() => setDownloadModal(data?.imgFont)} style={styles.cardImage}>
                    <ScaleImage source={{uri: data?.imgFont}} style={{width: '100%', height: '100%'}}/>
                  </TouchableOpacity>
                )}
                {!!data?.imgBack && (
                  <TouchableOpacity onPress={() => setDownloadModal(data?.imgBack)} style={styles.cardImage}>
                    <ScaleImage source={{uri: data?.imgBack}} style={{width: '100%', height: '100%'}}/>
                  </TouchableOpacity>
                )}
              </View>
            )}
          </ScrollView>
          <BottomModal visible={isShowOptionModal} onClose={() => setShowOptionModal(false)}>
            <View>
              <Button
                preset={'primary'}
                text={'Download'}
                onPress={handleDownloadAll}
              />
              {!!isWorkerView && (
                <Button
                  preset={'brand'}
                  text={'Delete'}
                  style={{marginTop: 14}}
                  onPress={() => {
                    setShowConfirmDelete(true)
                    setShowOptionModal(false)
                  }}
                />
              )}
              <Button
                onPress={() => setShowOptionModal(false)}
                style={{marginTop: 14}}
                preset={'primaryOutLine'}
                text={'Cancel'}
              />
            </View>
          </BottomModal>
          <BottomModal visible={!!downloadModal} onClose={() => setDownloadModal('')}>
            <View>
              <Button
                preset={'primary'}
                text={'Download'}
                onPress={handleDownload}
              />
              <Button
                onPress={() => setDownloadModal('')}
                style={{marginTop: 14}}
                preset={'primaryOutLine'}
                text={'Cancel'}
              />
            </View>
          </BottomModal>
          <BackdropModal visible={isShowConfirmDelete} onClose={() => setShowConfirmDelete(false)}>
            <View>
              <View style={styles.modalContainer}>
                <SvgIcon preset={'alertIcon'}/>
                <View style={{paddingTop: 18, paddingBottom: 8}}>
                  <Text preset={'alertTitle'}>Are you sure?</Text>
                </View>
              </View>
              <Button
                onPress={handleDelete}
                style={styles.modalBtn}
                text={'Yes'}
                preset="brand"
              />
              <Button
                onPress={() => setShowConfirmDelete(false)}
                style={{marginTop: 8}}
                preset={'destructive'}
                text={'Cancel'}
              />
            </View>
          </BackdropModal>
        </SafeAreaView>
      </PureBottomModal>
      <AlertModal/>
      <LoadingModal visible={loading}/>
    </>
  )
});

export default DetailCardScreen;
