import React, {FC} from "react"
import {observer} from "mobx-react-lite"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {JobTagLocationsView} from "./tags/locations";
import {RouteProp} from "@react-navigation/native";
import {JobTagIndustriesView} from "./tags/industries";
import {JobTagRolesView} from "./tags/roles";
import {JobTagExperiencesView} from "./tags/experiences";
import {JobTagSkillsView} from "./tags/skills";
import {JobTagQualificationsView} from "./tags/qualifications";
import {NavigatorParamList} from "../../../navigators";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";

export interface JobFilterDetailScreenProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
  route: RouteProp<NavigatorParamList, "jobFilterDetailScreen">
}

export const JobFilterDetailScreen: FC<JobFilterDetailScreenProps> = observer(
  ({navigation, route}) => {
    const {tag} = route.params

    const renderContent = () => {
      switch (tag) {
        case "locations":
          return <JobTagLocationsView navigation={navigation}/>
        case "industries":
          return <JobTagIndustriesView navigation={navigation}/>
        case "roles":
          return <JobTagRolesView navigation={navigation}/>
        case "experience":
          return <JobTagExperiencesView navigation={navigation}/>
        case "skills":
          return <JobTagSkillsView navigation={navigation}/>
        case "qualifications":
          return <JobTagQualificationsView navigation={navigation}/>
        default:
          return <JobTagLocationsView navigation={navigation}/>
      }
    }

    return (
      <>
        {renderContent()}
        <AlertModal/>
      </>
    )
  },
)
