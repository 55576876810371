import {Instance, types} from "mobx-state-tree"
import {convertFileUploadModel, FileUploadModel} from "./file-upload";
import {convertKvItemModel, KvItemModel} from "./kv-item";
import {BusinessContactModel, convertBusinessContactModel} from "./contact";
import {BusinessApplicationModel, convertBusinessApplicationModel} from "./business-application";
import {convertShortUserModel, ShortUserModel} from "./worker-profile";
import {convertJobLocationModel, JobLocationModel} from "./location";
import {
  convertUserQualificationModel,
  convertUserSkillModel,
  UserQualificationModel,
  UserSkillModel
} from "./advanced-skill";
// import {convertJobAdvertModel, JobAdvertModel} from "./job-advert";

export const JobRoleModel = types.model({
  id: types.optional(types.number, 0),
  title: types.optional(types.string, ''),
  description: types.optional(types.string, ''),
  payType: types.optional(types.string, ''),
  amount: types.optional(types.string, ''),
  rate: types.optional(types.number, 0),
  numberOfPositions: types.optional(types.number, 0),
  startDate: types.optional(types.string, ''),
  postedDate: types.optional(types.string, ''),
  workType: types.optional(types.string, ''),
  contact: types.optional(BusinessContactModel, {}),
  locations: types.optional(types.array(JobLocationModel), []),
  industries: types.optional(types.array(KvItemModel), []),
  role: types.optional(KvItemModel, {}),
  experiences: types.optional(types.array(KvItemModel), []),
  skills: types.optional(types.array(UserSkillModel), []),
  qualifications: types.optional(types.array(UserQualificationModel), []),
  myCv: types.optional(FileUploadModel, {}),
  // jobAd: types.optional(JobAdvertModel, {}),
  publishedAt: types.optional(types.string, ''),
  createdAt: types.optional(types.string, ''),
})
export type IJobRoleModel = Instance<typeof JobRoleModel>
export const convertJobRoleModel = (raw): IJobRoleModel => ({
  id: raw?.id || 0,
  title: raw?.title || '',
  description: raw?.description || '',
  payType: raw?.payType || '',
  amount: raw?.amount || '',
  rate: raw?.rate || 0,
  numberOfPositions: raw?.numberOfPositions || 0,
  startDate: raw?.startDate || '',
  postedDate: raw?.postedDate || '',
  workType: raw?.workType || '',
  contact: convertBusinessContactModel({...raw?.contact?.data?.attributes, id: raw?.contact?.data?.id}),
  locations: raw?.locations?.data?.map((item) => convertJobLocationModel({...item?.attributes, id: item?.id})) || [],
  industries: raw?.industries?.data?.map((item) => convertKvItemModel({...item?.attributes, id: item?.id})) || [],
  role: convertKvItemModel({...raw?.role?.data?.attributes, id: raw?.role?.data?.id}),
  experiences: raw?.experiences?.data?.map((item) => convertKvItemModel({...item?.attributes, id: item?.id})) || [],
  skills: raw?.skills?.data?.map((item) => convertUserSkillModel({...item?.attributes, id: item?.id})) || [],
  qualifications: raw?.qualifications?.data?.map((item) => convertUserQualificationModel({
    ...item?.attributes, id: item?.id
  })) || [],
  myCv: convertFileUploadModel(raw?.myCv),
  // jobAd: convertJobAdvertModel(raw?.jobAd),
  publishedAt: raw?.publishedAt || '',
  createdAt: raw?.createdAt || '',
})

export const BusinessJobRoleModel = types.model({
  id: types.optional(types.number, 0),
  title: types.optional(types.string, ''),
  description: types.optional(types.string, ''),
  payType: types.optional(types.string, ''),
  amount: types.optional(types.string, ''),
  rate: types.optional(types.number, 0),
  numberOfPositions: types.optional(types.number, 0),
  startDate: types.optional(types.string, ''),
  postedDate: types.optional(types.string, ''),
  workType: types.optional(types.string, ''),
  contact: types.optional(BusinessContactModel, {}),
  locations: types.optional(types.array(JobLocationModel), []),
  industries: types.optional(types.array(KvItemModel), []),
  role: types.optional(KvItemModel, {}),
  experiences: types.optional(types.array(KvItemModel), []),
  skills: types.optional(types.array(UserSkillModel), []),
  qualifications: types.optional(types.array(UserQualificationModel), []),
  myCv: types.optional(FileUploadModel, {}),
  // jobAd: types.optional(JobAdvertModel, {}),
  publishedAt: types.optional(types.string, ''),
  createdAt: types.optional(types.string, ''),
  applications: types.optional(types.array(BusinessApplicationModel), []),
})
export type IBusinessJobRoleModel = Instance<typeof BusinessJobRoleModel>
export const convertBusinessJobRoleModel = (raw): IBusinessJobRoleModel => ({
  id: raw?.id || 0,
  title: raw?.title || '',
  description: raw?.description || '',
  payType: raw?.payType || '',
  amount: raw?.amount || '',
  rate: raw?.rate || 0,
  numberOfPositions: raw?.numberOfPositions || 0,
  startDate: raw?.startDate || '',
  postedDate: raw?.postedDate || '',
  workType: raw?.workType || '',
  contact: convertBusinessContactModel({...raw?.contact?.data?.attributes, id: raw?.contact?.data?.id}),
  locations: raw?.locations?.data?.map((item) => convertJobLocationModel({...item?.attributes, id: item?.id})) || [],
  industries: raw?.industries?.data?.map((item) => convertKvItemModel({...item?.attributes, id: item?.id})) || [],
  role: convertKvItemModel({...raw?.role?.data?.attributes, id: raw?.role?.data?.id}),
  experiences: raw?.experiences?.data?.map((item) => convertKvItemModel({...item?.attributes, id: item?.id})) || [],
  skills: raw?.skills?.data?.map((item) => convertUserSkillModel({...item?.attributes, id: item?.id})) || [],
  qualifications: raw?.qualifications?.data?.map((item) => convertUserQualificationModel({
    ...item?.attributes, id: item?.id
  })) || [],
  myCv: convertFileUploadModel(raw?.myCv),
  // jobAd: convertJobAdvertModel(raw?.jobAd),
  publishedAt: raw?.publishedAt || '',
  createdAt: raw?.createdAt || '',
  applications: raw?.applications?.data?.map((item) => convertBusinessApplicationModel({
    ...item?.attributes, id: item?.id
  })) || [],
})

export const JobRoleBookmarkModel = types.model({
  id: types.optional(types.number, 0),
  jobRole: types.optional(JobRoleModel, {}),
  user: types.optional(ShortUserModel, {}),
})
export type IJobRoleBookmarkModel = Instance<typeof JobRoleBookmarkModel>
export const convertJobRoleBookmarkModel = (raw): IJobRoleBookmarkModel => ({
  id: raw?.id || 0,
  jobRole: convertJobRoleModel(
    raw?.jobRole?.data?.attributes ? {
      ...raw?.jobRole?.data?.attributes,
      id: raw?.jobRole?.data?.id
    } : raw?.jobRole?.data ? raw?.jobRole?.data : {
      ...raw?.owner?.data?.attributes?.jobRole?.data?.attributes,
      id: raw?.owner?.data?.attributes?.jobRole?.data?.id
    }
  ),
  user: convertShortUserModel(
    raw?.user?.data?.attributes ? {
      ...raw?.user?.data?.attributes,
      id: raw?.user?.data?.id
    } : raw?.user?.data ? raw?.user?.data : {
      ...raw?.owner?.data?.attributes?.user?.data?.attributes,
      id: raw?.owner?.data?.attributes?.user?.data?.id
    }
  ),
})
