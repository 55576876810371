import {ParamListBase, RouteProp, useFocusEffect} from "@react-navigation/native"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import React, {FC, useCallback, useState} from "react"
import {
  FlatList, Linking,
  ScrollView,
  TouchableOpacityProps,
  View, TouchableOpacity, ViewStyle
} from "react-native"
import {GradientBackground, Header, Text} from "../../components"
import {SvgIcon} from "../../components/icon/svg-icon"
import {color} from "../../theme"
import {AvatarImage} from "../../components/avatar-image/avatar-image"
import {NavigatorParamList} from "../../navigators";
import {useStores} from "../../models";
import sanitizeHtml from 'sanitize-html';
import {IBusinessProfileUserModel} from "../../models/app/business-profile-user";
import {FlatJobRoleCard} from "../../components/card/job-card";
import {IJobRoleAdModel} from "../../models/app/job-role-ad";
import {AlertModalStore} from "../../models/app/alert-modal-store";
import {IKvItemCheckModel} from "../../models/app/kv-item";
import {AlertModal} from "../../components/backdrop-modal/alert-modal";
import {PureBottomModal} from "../../components/backdrop-modal/pure-bottom-modal";
import {getCountryCallingCode} from "react-phone-number-input";
import AvatarDefault from "../../components/avatar-image/AvatarDefault";
import TabButton from "../../components/tab-bar/tab-button";

const openLink = (url?: string) => {
  if (!url) return
  window.open(url?.includes('http') ? url : '//' + url)
}

interface TabComponentProps extends TouchableOpacityProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  businessProfile: IBusinessProfileUserModel
  jobRoles: Array<IJobRoleAdModel>,
  callingCode: string,
}

const Tabs = {
  fs: {key: 1},
  sc: {key: 2},
}

function TabComponent(props: TabComponentProps) {
  const {navigation, businessProfile, jobRoles, callingCode} = props
  const [tabItems, setTabItems] = useState<Array<IKvItemCheckModel>>([
    {id: Tabs.fs.key, name: 'Jobs', checked: true},
    {id: Tabs.sc.key, name: 'About', checked: false},
  ])
  const activeTab = tabItems.find(it => it.checked)

  const renderItem = ({item}) => {
    return <FlatJobRoleCard
      onLogoPress={(id) => navigation.navigate('businessProfileScreen', {businessProfileId: id})}
      messageCount={false} item={item} onPress={() => navigation.navigate("jobsAdDetailScreen", {id: item.id})}/>
  }

  const SanitizeHTML = ({html}) => (
    <div dangerouslySetInnerHTML={{
      __html: sanitizeHtml(html || '', {
        allowedAttributes: {
          'a': ['href']
        },
      })
    }}/>
  );

  const clickTab = (item) => {
    setTabItems(tabItems.map(it => ({...it, checked: it.id === item.id} as IKvItemCheckModel)))
  }


  return (
    <View style={{flex: 1}}>
      <View style={{flexDirection: 'row', marginLeft: 20}}>
        {tabItems.map((it) => {
          return(
              <TabButton
                  isActive={it.checked}
                  value={it.name}
                  key={it.id}
                  onPress={() => clickTab(it)}/>
          )
        })}
      </View>

      {activeTab.id === Tabs.fs.key ? (
        <View style={{flex: 1, paddingTop: 16, paddingHorizontal: 8}}>
          <FlatList
            data={jobRoles}
            renderItem={renderItem}
            ListFooterComponent={<View style={{height: 100}}/>}
            showsVerticalScrollIndicator={false}
            keyExtractor={(item, index) => index.toString()}
          />
        </View>
      ) : (
        <ScrollView style={{flex: 1}} showsVerticalScrollIndicator={false}>
          <View style={{paddingTop: 16, marginBottom: 100}}>
            <View style={{paddingHorizontal: 20,}}>
              {/*
              {!!businessProfile?.logo.url && (
                <Image
                  source={{uri: businessProfile?.logo.url}}
                  style={{height: 200, borderTopRightRadius: 8, borderBottomRightRadius: 8,}}/>
              )}
*/}
              <View style={{marginTop: 24}}>
                {!!businessProfile?.city && ( <View style={CONTACTS_CONTAINER}>
                  <Text preset="bold">Address: </Text>
                  <Text preset="body1">{
                    businessProfile?.city
                  }</Text>
                </View>)}
                {!!businessProfile?.telephone && ( <View style={CONTACTS_CONTAINER}>
                  <Text preset="bold">Phone: </Text>
                  <TouchableOpacity
                      onPress={() => Linking.openURL(`tel:+${callingCode}${businessProfile?.telephone || ''}`)}>
                    <Text preset="underTextFieldButton">
                      {`+${callingCode} ${businessProfile?.telephone || ''}`}
                    </Text>
                  </TouchableOpacity>
                </View>)}
                {!!businessProfile?.email && ( <View style={CONTACTS_CONTAINER}>
                  <Text preset="bold">Email: </Text>
                  <TouchableOpacity
                      onPress={() => Linking.openURL(`mailto:${businessProfile?.email || ''}`)}>
                    <Text preset="underTextFieldButton">
                      {`${businessProfile?.email || ''}`}
                    </Text>
                  </TouchableOpacity>
                </View>)}
              </View>
            </View>
            <View style={{paddingHorizontal: 18, marginVertical: 24}}>
              {!!businessProfile?.outline && (
                <SanitizeHTML html={businessProfile?.outline}/>
              )}
            </View>
            <View style={{paddingHorizontal: 20, flexDirection: "row", flexWrap: "wrap"}}>
              <View style={{marginRight: 12, display: businessProfile?.fbLink ? 'flex' : 'none'}}>
                <SvgIcon
                  onPress={() => openLink(businessProfile?.fbLink)}
                  icon="facebook" theme="brands" preset="socialIcon"/>
              </View>
              <View style={{marginRight: 12, display: businessProfile?.instaLink ? 'flex' : 'none'}}>
                <SvgIcon
                  onPress={() => openLink(businessProfile?.instaLink)}
                  icon="instagram" theme="brands" preset="socialIcon"/>
              </View>
              <View style={{marginRight: 12, display: businessProfile?.twLink ? 'flex' : 'none'}}>
                <SvgIcon
                  onPress={() => openLink(businessProfile?.twLink)}
                  icon="twitter" theme="brands" preset="socialIcon"/>
              </View>
            </View>
          </View>
        </ScrollView>
      )}
    </View>
  )
}

interface BusinessProfileProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'businessProfileScreen'>
}

export const BusinessProfileScreen: FC<BusinessProfileProps> = ({navigation, route}) => {
  const {businessProfileId} = route.params
  const {app} = useStores()

  const [loading, setLoading] = useState(false)
  const [businessProfile, setBusinessProfile] = useState<IBusinessProfileUserModel>()
  const [jobRoles, setJobRoles] = useState<Array<IJobRoleAdModel>>([])
  const [callingCode, setCallingCode] = useState('')


  const loadData = async () => {
    if (loading) return
    try {
      setLoading(true)
      const res = await app.environment.api.getBusinessProfileDetail(businessProfileId)
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message)
        return
      }
      const _businessProfile = res.businessProfile
      setBusinessProfile(_businessProfile)
      const countryCode = _businessProfile?.user?.countryCode
      if (countryCode) {
        const callingCode = countryCode ? getCountryCallingCode(countryCode as any) : ''
        // const callingCode = await getCallingCode(_businessProfile?.user?.countryCode as CountryCode)
        setCallingCode(callingCode.toString())
      }
      if (_businessProfile?.user?.id) {
        const jobRes = await app.environment.api.getJobRoleAds({
          'filters[jobAd][owner][id][$eq]': _businessProfile?.user?.id,
          "filters[jobAd][closed][$eq]": false,
          "filters[jobAd][isDraft][$eq]": false,
        })
        if (jobRes.kind === 'ok') {
          setJobRoles(jobRes.jobRoles)
        }
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useFocusEffect(useCallback(() => {
    if (businessProfileId) loadData()
  }, []))

  return (
    <>
      <PureBottomModal onClose={() => navigation.goBack()}>
        <View style={{flex: 1}}>
          <GradientBackground colors={[color.palette.gradientStart, color.palette.gradientStop]}/>
          <Header
            headerText="Business Profile" preset="modalHeader" titlePreset="headerTitle"
            rightIcon="times" rightIconPreset={"smallBlue"} onRightPress={() => navigation.goBack()}
          />
          <View style={{paddingHorizontal: 10, paddingBottom: 10, flexDirection: "row"}}>
            {businessProfile?.logo?.url ? <AvatarImage
                source={{uri: businessProfile?.logo?.url}}
                preset={"medium"}/>
                :
                <AvatarDefault name={businessProfile?.name} preset={"medium"}/>}
            <View style={{justifyContent: "center", marginLeft: 10}}>
              <Text preset={"mediumBoldBlue"}>{businessProfile?.name}</Text>
              <View style={{height: 4}}/>
              <TouchableOpacity onPress={() => openLink(businessProfile?.website)}>
                <Text preset={'label'} style={{color: color.palette.blue}}>{businessProfile?.website}</Text>
              </TouchableOpacity>
            </View>
          </View>
          <TabComponent
            businessProfile={businessProfile}
            jobRoles={jobRoles} callingCode={callingCode}
            navigation={navigation}/>
        </View>
      </PureBottomModal>
      <AlertModal/>
    </>
  )
}

const CONTACTS_CONTAINER: ViewStyle = {
  flexDirection: 'row',
  marginVertical: 4,
}
