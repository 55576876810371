import React from "react"
import { ScrollView, TouchableOpacity } from "react-native"
import styles from "../styles"
import { Text } from "../../../../components"
import { SvgIcon } from "../../../../components/icon/svg-icon"
import ImageCard from "./ImageCard"
import { IWorkerWalletItemModel } from "../../../../models/app/advanced-skill"

interface Props {
  addMore?: () => void;
  onClickCard: (item: IWorkerWalletItemModel) => void;
  data: IWorkerWalletItemModel[]
}

const CardList = ({addMore, onClickCard, data}: Props) => {
  return (
    <ScrollView horizontal contentContainerStyle={styles.scrollCard} showsHorizontalScrollIndicator={false}>
      {addMore && (
        <TouchableOpacity style={[styles.ShadowWrapper, styles.takePhoto]} onPress={addMore} activeOpacity={0.8}>
          <SvgIcon icon="camera-card" preset="cameraCard" style={{marginBottom: 8}}/>
          <Text preset="textTags">
            Add New
          </Text>
        </TouchableOpacity>
      )}
      {data.length > 0 ? data.map((item) => {
        if (!item?.imgFont) return null
        return (
          <ImageCard key={item?.id} item={item} onCLick={() => onClickCard(item)}/>
        )
      }) : <ImageCard isEmpty onCLick={addMore}/>}
    </ScrollView>
  )
};

export default CardList;
