import { TouchableOpacity, View } from "react-native";
import { Text } from "../../../components";
import { color } from "../../../theme";
import React, { FC } from "react";
import { IBusinessGroupModel } from "../../../models/app/contact";
import { SvgIcon } from "../../../components/icon/svg-icon";

export interface GroupItemProps {
  item: IBusinessGroupModel
  onPress: () => void
}

export const GroupItem: FC<GroupItemProps> = ({item, onPress}) => {
  return (
    <View style={{marginBottom: 30, paddingRight: 10, backgroundColor: color.palette.white}}>
      <TouchableOpacity onPress={onPress}>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <View style={{flex: 1,}}>
            <Text style={{fontSize: 16, fontWeight: '600', color: '#1C1C1C'}}>{item.name}</Text>
          </View>
          <View style={{marginRight: 20}}>
            <Text style={{fontSize: 16, fontWeight: '600', color: '#1C1C1C'}}>{item?.contacts?.length}</Text>
          </View>
          <SvgIcon icon='chevron-right'/>
        </View>
      </TouchableOpacity>
    </View>
  )
}
