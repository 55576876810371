import React from 'react';
import {View, TouchableOpacity} from 'react-native';
import {CardInfoProps, presets} from './card-info.props';
import {Text} from ".."
import {spacing} from '../../theme';

export function CardInfo(props: CardInfoProps) {
  const {
    leftIcon,
    preset,
    title,
    subTitle,
    titlePreset = 'medium',
    subTitlePreset = 'medium',
    rightIcon,
    rightText,
    rightTextPreset,
    ...rest
  } = props;
  const CONTAINER = presets[preset] || presets['default'];

  return (
    <TouchableOpacity style={CONTAINER} {...rest}>
      <View style={{}}>
        {
          !!leftIcon && leftIcon()
        }
      </View>
      <View style={{flex: 1, marginLeft: 8}}>
        <Text numberOfLines={1} preset={titlePreset}>{title}</Text>
        <Text numberOfLines={1} preset={subTitlePreset}>{subTitle}</Text>
      </View>
      <View style={{}}>
        {
          !!rightIcon && rightIcon()
        }
        {
          rightText ? <Text preset={rightTextPreset}>{rightText}</Text> :
            <View style={{height: spacing[3]}}/>
        }
      </View>
    </TouchableOpacity>
  );
}
