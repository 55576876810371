import React, {FC, useCallback, useState} from "react"
import {SafeAreaView, TouchableOpacity, View, ViewStyle} from "react-native"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {observer} from "mobx-react-lite"
import {Header, Text, GradientBackground} from "../../../components"
import {color} from "../../../theme"
import {CommonActions, ParamListBase, RouteProp, useFocusEffect} from "@react-navigation/native"
import {SearchBar} from "../../../components/search-bar/search-bar"
import {AvailableJobTab} from "./available-jobs"
import {NavigatorParamList} from "../../../navigators";
import {useStores} from "../../../models";
import {IJobRoleAdModel} from "../../../models/app/job-role-ad";
import {BackdropModal} from "../../../components/backdrop-modal/backdrop-modal";
import {SvgIcon} from "../../../components/icon/svg-icon";
import {IJobRoleBookmarkModel} from "../../../models/app/job-role";
import {DEFAULT_LIMIT, DEFAULT_PAGE_LIMIT} from "../../../utils/constants";

export interface JobListScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'jobListScreen'>
  fullScreen?: boolean
}

const JobListFilters = {
  saved: {key: 1, label: "Saved", selectLabel: "Saved"},
  new_jobs: {key: 2, label: "New Jobs", selectLabel: "New Jobs"},
  unread: {key: 3, label: "Unread", selectLabel: "Unread"},
}

export const JobListScreen: FC<JobListScreenProps> = observer(({navigation, fullScreen}) => {
  const {app} = useStores()
  const [search, setSearch] = useState("")
  const [isLoadAvailableJob, setIsLoadAvailableJob] = useState(false)
  const [jobRoleBookmarks, setJobRoleBookmarks] = useState<Array<IJobRoleBookmarkModel>>([])
  const [jobRoles, setJobRoles] = useState<Array<IJobRoleAdModel>>([])
  const [visibleModal, setVisibleModal] = useState(false)
  const [filterKeys, setFilterKeys] = useState<Array<number>>([])
  const [currentPage, setCurrentPage] = useState(0)

  useFocusEffect(useCallback(() => {
    if (app?.hasSearchJobRole) setSearch(app?.searchJobRoleText)
    app?.setHasSearchJobRole(false)
    loadAvailableJobData()
  }, []))

  const loadAvailableJobData = async (isNext: boolean = false) => {
    if (isLoadAvailableJob) return
    if (isNext && jobRoles?.length % DEFAULT_PAGE_LIMIT !== 0) return
    setIsLoadAvailableJob(true)
    const _currentPage = (isNext ? currentPage : 0) + 1
    try {
      if (!isNext) {
        const bookmarkRes = await app.environment.api.getJobRoleBookmark({
          "filters[user][id][$eq]": app?.userAccount?.id,
          "pagination[pageSize]": DEFAULT_LIMIT
        })
        if (bookmarkRes.kind === 'ok') setJobRoleBookmarks(bookmarkRes.items)
      }

      const res = await app.environment.api.getJobRoleAds({
        "filters[jobAd][isDraft][$eq]": false,
        "filters[jobAd][closed][$eq]": false,
        "pagination[page]": _currentPage,
        "pagination[pageSize]": DEFAULT_PAGE_LIMIT
      })
      if (res.kind === 'ok') {
        const _jobRoles = isNext ? [...jobRoles, ...res.jobRoles] : res.jobRoles
        setJobRoles(_jobRoles)
        setCurrentPage(_currentPage)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoadAvailableJob(false)
    }
  }

  const toggleBookmark = async (jobRoleId: number) => {
    const item = jobRoleBookmarks.find(it => it?.jobRole?.id === jobRoleId)
    if (!item || !item.id) {
      await app.environment.api.createJobRoleBookmark({
        user: app?.userAccount?.id,
        jobRole: jobRoleId,
      })
    } else {
      await app.environment.api.deleteJobRoleBookmark(item?.id)
    }
    loadAvailableJobData()
  }

  const _jobRoles = jobRoles
  const renderTab = () => {
    return <AvailableJobTab
      jobRoleBookmarks={jobRoleBookmarks} toggleBookmark={toggleBookmark}
      jobRoles={_jobRoles}
      search={search}
      refreshing={isLoadAvailableJob} loadData={loadAvailableJobData}
      onEndReached={() => loadAvailableJobData(true)}
      onLogoPress={(id) => navigation.navigate('businessProfileScreen', {businessProfileId: id})}
      onPress={(item) => navigation.navigate('jobsAdDetailScreen', {id: item.id})}/>
  }

  const handleFilterPress = async () => {
    // setVisibleModal(true)
  }

  const goHome = () => {
    navigation.dispatch(
      CommonActions.reset({
        index: 0, routes: [{name: 'main'}],
      })
    );
  }

  const renderFilterItem = (it) => {
    return <View key={it.key} style={{marginRight: 8}}>
      <TouchableOpacity onPress={() => handleSelectKey(it.key)}>
        <View style={{
          flexDirection: 'row', alignItems: 'center',
          backgroundColor: color.palette.greyLight,
          paddingHorizontal: 12,
          paddingVertical: 11,
          borderRadius: 100
        }}>
          <Text preset={'body'} style={{color: color.palette.blue, fontWeight: '400'}}>
            {it.label}
          </Text>
          <View style={{paddingLeft: 8}}>
            <SvgIcon preset={'primary'} icon={'times-circle-dark'}/>
          </View>
        </View>
      </TouchableOpacity>
    </View>

  }

  const handleSelectKey = (key: number) => {
    if (filterKeys.includes(key)) setFilterKeys(filterKeys.filter(it => it !== key))
    else setFilterKeys([...filterKeys, key])
  }

  return (
    <SafeAreaView style={CONTAINER}>
      <GradientBackground colors={[color.palette.gradientStart, color.palette.gradientStop]}/>
      <Header
        {...(fullScreen ? null : {leftIcon: 'arrow-left', leftIconPreset: 'primary', onLeftPress: goHome})}
        titlePreset={"headerTitle"} headerText={"Job Adverts"} preset={"primary"}/>
      <SearchBar
        value={search} onChangeText={setSearch}
        onTouchStart={() => {
          app?.setFilterJobRoleText(search)
          navigation.navigate('searchScreen')
        }}
        // onFilterPress={() => navigation.navigate('jobAdvertFilterScreen')}
        onFilterPress={handleFilterPress}
      />
      <View style={{
        flexDirection: "row", marginHorizontal: 20,
        marginTop: 8, marginBottom: filterKeys.length > 0 ? 20 : 0,
        alignItems: 'flex-end', justifyContent: 'flex-start'
      }}>
        {Object.values(JobListFilters).filter(item => filterKeys.includes(item.key)).map(renderFilterItem)}
      </View>
      {renderTab()}
      <BackdropModal preset={'zeroPadDesktop'} visible={visibleModal} onClose={() => setVisibleModal(!visibleModal)}>
        <View style={{paddingTop: 20, paddingBottom: 40}}>
          <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
            <Text preset={"h1"} text={'Filters'} color={color.palette.blue}/>
            <SvgIcon onPress={() => setVisibleModal(!visibleModal)} icon={'times'} preset={'normal'}/>
          </View>
          <View style={{marginTop: 65}}>
            {Object.values(JobListFilters).map((item) => (
              <TouchableOpacity
                key={item?.key}
                onPress={() => handleSelectKey(item.key)}
                style={{marginBottom: 26, alignItems: 'center'}}>
                <Text preset={"h1"} text={item.selectLabel} color={color.palette.blue}/>
              </TouchableOpacity>
            ))}
          </View>
        </View>
      </BackdropModal>
    </SafeAreaView>
  )
})

const CONTAINER: ViewStyle = {
  flex: 1,
}
