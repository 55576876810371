import React, {FC, useCallback, useEffect, useState} from "react"
import {
  View,
  SafeAreaView, ScrollView,
} from "react-native"
import {observer} from "mobx-react-lite"
import {Header, GradientBackground, Text, TextField, Button} from "../../../components"
import {color} from "../../../theme"
import {SelectTextField} from "../../../components/text-field/select-text-field";
import {IKvItemModel} from "../../../models/app/kv-item";
import {useStores} from "../../../models";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {isAndroid} from "../../../utils/platform";
import {debounce} from "../../../utils/debounce";
import {KvSkillPath} from "../../../models/app/advanced-skill";
import {WorkerSkillStore} from "../../../models/app/worker-skill-store";
import {ParamListBase, RouteProp} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import {NavigatorParamList} from "../../../navigators";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";

export interface WorkerQualificationEduScreenProps {
  route: RouteProp<NavigatorParamList, 'workerQualificationEduScreen'>
  navigation: NativeStackNavigationProp<ParamListBase>
}

export const WorkerQualificationEduScreen: FC<WorkerQualificationEduScreenProps> = observer(
  ({route, navigation}) => {
    const {app} = useStores();
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState("")
    const [data, setData] = useState<Array<IKvItemModel>>([]);
    const [selectedItems, setSelectedItems] = useState<Array<IKvItemModel>>([]);

    const loadData = async (text?: string, selectedIds?: number[]) => {
      setLoading(true)
      try {
        const userRes = await app.environment.api.getKvItemList(KvSkillPath.qualificationEdu, {
          ...(text ? {'filters[name][$containsi]': text} : null),
        })
        if (userRes.kind !== 'ok') return
        setData(
          userRes?.items.map(it => ({
            ...it, checked: !!selectedIds?.includes(it?.id)
          }))
        )
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }
    useEffect(() => {
      setSelectedItems([])
      loadData()
    }, [])

    const toggleSelectItem = (id) => {
      let _item = data.find((item) => item.id === id)
      if (selectedItems.find(it => it?.id === id)) setSelectedItems(selectedItems.filter(it => it?.id !== id))
      else setSelectedItems([_item])
    }

    const handleAddItem = async (newItemText: string) => {
      if (!newItemText || loading) return
      setLoading(true)
      try {
        const res = await app.environment.api.editKvItemBusiness(KvSkillPath.qualificationEdu, {
          name: newItemText,
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }

        setSearch('')
        setSelectedItems([res.item])
        await loadData('', [res.item?.id])
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    const delayLoadData = useCallback(debounce(loadData, 600), []);
    const handleChangeSearch = (text: string) => {
      // @ts-ignore
      delayLoadData(text)
      setSearch(text)
    }

    const handleAddOther = () => {
      handleAddItem(search)
    }

    const onDone = () => {
      const item = selectedItems?.length > 0 ? selectedItems[0] : null
      if (item) WorkerSkillStore.setQualificationEducationLevel({id: item?.id, name: item?.name,})
      onClose()
    }

    const onClose = () => navigation.goBack()

    // const showBtnAdd = !loading && !!search && data?.length <= 0
    const showBtnAdd = false
    const showBtnDone = selectedItems?.length > 0
    return (
      <>
        <PureBottomModal onClose={onClose}>
          <SafeAreaView style={{flex: 1}}>
            <GradientBackground
              colors={[color.palette.gradientStart, color.palette.gradientStop]}
            />
            <Header
              preset={"modalHeader"}
              titlePreset={"headerTitle"}
              headerText={''}
              rightIcon={"times"}
              rightIconPreset={"smallBlue"}
              onRightPress={onClose}
            />
            <View style={{paddingHorizontal: 20, flex: 1}}>
              <View style={{flex: 1}}>
                <View style={{}}>
                  <View style={{marginTop: 24, marginBottom: 8}}>
                    <TextField
                      value={search} onChangeText={handleChangeSearch} autoFocus={true}
                      labelPreset={"default"}
                      rightIcon={"times-circle-fill"}
                      rightIconPreset={"default"}
                      rightIconPress={() => handleChangeSearch('')}
                    />
                  </View>
                  {showBtnAdd && (
                    <Button preset={"brandDisabled"} onPress={handleAddOther}>
                      <Text preset={"mediumBoldBlue"}>Add other</Text>
                    </Button>
                  )}
                </View>
                <View style={{marginTop: 24, flex: 1}}>
                  <ScrollView showsVerticalScrollIndicator={false} style={{flex: 1}}>
                    <View>
                      {data.length > 0 ? (
                        <View>
                          <View style={{marginHorizontal: 16, marginBottom: 10}}>
                            <Text preset={"mediumBlue"}>Results</Text>
                          </View>
                          {data.map((item, index) => (
                            <SelectTextField
                              key={`${index}-${item.id}`}
                              value={item.name} onPress={() => toggleSelectItem(item.id)}
                              style={{marginBottom: 10}}
                              preset={selectedItems?.find(_it => item.id === _it.id) ? 'itemSelected' : 'transparent'}
                            />
                          ))}
                        </View>
                      ) : null}
                    </View>
                  </ScrollView>
                </View>
              </View>
              <View style={{display: showBtnDone ? 'flex' : 'none', paddingTop: 10, marginBottom: isAndroid ? 10 : 5}}>
                <Button preset={"brand"} onPress={onDone} text={'Done'}/>
              </View>
            </View>
          </SafeAreaView>
        </PureBottomModal>
        <AlertModal/>
      </>
    )
  },
)
