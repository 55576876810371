import React from 'react';
import {View, TouchableOpacity, StyleSheet, ViewStyle} from 'react-native';
import {color} from '../../theme';
import {Text} from "../text/text";
import {Tag,} from "../tag/tag";
import {AvatarImage} from "../avatar-image/avatar-image";
import {stringToDateStr} from "../../utils/utils";
import {IJobRoleAdModel} from "../../models/app/job-role-ad";
import {ApplicationStatus, IWorkerApplicationModel} from "../../models/app/application";
import {isAndroid} from "../../utils/platform";
import {SvgIcon} from "../icon/svg-icon";
import {parseLocation} from "../../models/app/location";
import {BREAKPOINT_MOBILE, width} from "../../utils/global";
import AvatarDefault from "../avatar-image/AvatarDefault";

interface JobRoleCardProps {
  onPress: () => void,
  onLogoPress?: (id: number) => void,
  item: IJobRoleAdModel,
}

export function JobRoleCard(props: JobRoleCardProps) {
  const {onPress, onLogoPress, item} = props;
  const roleTitle = item?.jobAd?.title || ''
  const roleDescription = item?.role?.name || ''
  const location = item?.locations?.length > 0 ? parseLocation(item?.locations[0]) : ''
  const logo = item?.jobAd?.businessProfile?.logo?.url
  const paymentType = item?.jobAd?.paymentTypes?.length > 0 ? item?.jobAd?.paymentTypes[0]?.name : ''
  const isDesktop =  width > BREAKPOINT_MOBILE;

  return (
    <TouchableOpacity
      style={[styles.wrapperJobRole, isDesktop && styles.wrapperJobRoleDK]}
      onPress={onPress}>
      <View style={{paddingHorizontal: 16, paddingTop: 15, paddingBottom: isAndroid ? 34 : 16}}>
        <View style={{flexDirection: 'row', marginTop: 4}}>
          <View style={{paddingRight: 8}}>
            {!!logo ?
                <AvatarImage
                onPress={() => onLogoPress && onLogoPress(item.jobAd.businessProfile.id)}
                source={{uri: logo}} preset={"medium"} py={0} px={0}/>
                :
                <AvatarDefault py={0} px={0} name={item.jobAd.businessProfile.name} preset={"medium"} onPress={() => onLogoPress && onLogoPress(item.jobAd.businessProfile.id)}/>
            }
          </View>
          <View style={{flex: 1, justifyContent: 'space-between'}}>
            <Text
              numberOfLines={1}
              text={roleDescription} preset={'h1'}
              style={{color: color.palette.black}}/>
            <View style={{height: 4}}/>
            <Text
              numberOfLines={1}
              text={item.jobAd.businessProfile.name} preset={'body'}
              style={{fontSize: 16, fontWeight: "600", color: color.palette.blue}}/>
            <View style={{height: 4}}/>
          </View>
        </View>
        <View style={{marginTop: 20, marginBottom: 18, paddingRight: 8}}>
          <Text
            numberOfLines={5}
            preset={'body'}
            style={{color: color.palette.dark, fontWeight: "400"}}
            text={roleTitle}/>
        </View>
        <View>
          <Text
            text={`Posted: ${stringToDateStr(item?.createdAt)}`} preset={'body'}
            style={{color: color.palette.greyHigh, fontWeight: "400"}}/>
        </View>
        <View style={{flexDirection: "row", flexWrap: "wrap", marginTop: isAndroid ? 12 : 16,}}>
          <View style={{marginBottom: 6, maxWidth: '100%'}}>
            <Tag
              preset={"iconSmall"}
              icon="money-check-dollar"
              children={`${paymentType} ${item?.amount}`}
              textPreset={'body'}
            />
          </View>
          <View style={{marginBottom: 6, maxWidth: '100%'}}>
            <Tag
              preset="smaller"
              icon="user"
              children={String(item?.numberOfPositions)}
              textPreset={'body'}
            />
          </View>
          <View style={{marginBottom: 6, maxWidth: '100%', paddingRight: 20}}>
            <Tag
              textProps={{numberOfLines: 1}}
              preset="smaller"
              icon="map-marker-alt"
              children={location}
              textPreset={'body'}
            />
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
}

interface FlatJobRoleCardProps {
  onPress: () => void,
  onLogoPress?: (id: number) => void,
  item: IJobRoleAdModel,
  messageCount?: boolean,
  workerApplication?: IWorkerApplicationModel,
}

export function FlatJobRoleCard(props: FlatJobRoleCardProps) {
  const {
    onPress, item, workerApplication, messageCount
  } = props;
  const roleTitle = item?.jobAd?.title || ''
  const roleDescription = item?.role?.name || ''
  const location = item?.locations?.length > 0 ? parseLocation(item?.locations[0]) : ''
  const logo = item?.contact?.businessProfile?.logo?.url
  const fullContactName = [item?.contact?.user?.firstName, item?.contact?.user?.lastName].join(' ')
  const paymentType = item?.jobAd?.paymentTypes?.length > 0 ? item?.jobAd?.paymentTypes[0]?.name : ''
  const hasContact = !!item?.contact?.id

  return (
    <TouchableOpacity
      style={{
        marginVertical: 10, marginHorizontal: 10,
        backgroundColor: color.palette.white,
        borderRadius: 6,
        borderWidth: 1, borderColor: '#D3D8ED',
        shadowColor: '#022964', shadowOpacity: 0.3,
        shadowOffset: {width: 1, height: 0}, shadowRadius: 4,
        elevation: 4
      }}
      onPress={onPress}>
      <View>
        <View style={{marginHorizontal: 15, marginVertical: 15}}>
          <View style={{flexDirection: 'row'}}>
            <View style={{flex: 1}}>
              <View style={{flexDirection: 'row'}}>
                <View style={{justifyContent: 'center', flex: 1}}>
                  <Text
                    numberOfLines={1}
                    text={roleDescription} preset={'h1'}
                    style={{color: color.palette.black}}/>
                </View>
                <View style={{flexDirection: 'row', paddingLeft: 2}}>
                  {(messageCount && item?.newApplicants > 0) && (
                    <View style={{justifyContent: 'center'}}>
                      <SvgIcon icon={'user'} preset={'smallPrimary'}/>
                    </View>
                  )}
                  {/*
                  {(messageCount && item?.newChatMessages > 0) && (
                    <Tag
                      icon="chatbubble-message" preset="smallerLight"
                      children={String(item?.newChatMessages)}
                      textPreset={'body'} textStyle={{color: color.palette.white}}
                      containerStyle={{backgroundColor: color.palette.red}}
                    />
                  )}
*/}
                  {/*
                  <Tag
                    preset="smallerLight" icon="chatbubble-message"
                    children={String(item?.amount)}
                    textPreset={'body'}
                    textStyle={{fontSize: 15, fontWeight: '400', color: color.palette.white}}
                    containerStyle={{backgroundColor: color.palette.red}}
                  />
*/}
                  {item?.jobAd?.closed && (
                    <View style={styles.pillRed}>
                      <Text preset={'body'} style={{fontWeight: '400', color: color.palette.white}}>
                        Closed
                      </Text>
                    </View>
                  )}
                  {item?.jobAd?.isDraft && (
                    <View style={styles.pill}>
                      <Text preset={'body'} style={{fontWeight: '400', color: color.palette.white}}>
                        Draft
                      </Text>
                    </View>
                  )}
                </View>
              </View>
              <View style={{height: 12}}/>
              <Text
                text={roleTitle} preset={'body'}
                style={{fontWeight: "400", color: color.palette.dark}}/>
              <View style={{height: 12}}/>
              <Text
                text={`Posted: ${stringToDateStr(item?.createdAt)}`} preset={'body'}
                style={{color: color.palette.textMuted, fontWeight: "400"}}/>
            </View>
          </View>
          <View style={{flexDirection: "row", flexWrap: "wrap", marginTop: isAndroid ? 12 : 16,}}>
            <View style={{marginBottom: 6, maxWidth: '100%'}}>
              <Tag
                preset="smaller"
                icon="user"
                children={String(item?.numberOfPositions)}
                textPreset={'body'}
              />
            </View>
            <View style={{marginBottom: 6, maxWidth: '100%'}}>
              <Tag
                preset="iconSmall"
                icon="pen-field"
                children={item?.workType}
                textPreset={'body'} textStyle={{color: color.palette.dark}}
                containerStyle={{marginBottom: 10, marginRight: 4}}
              />
            </View>
            <View style={{marginBottom: 6, maxWidth: '100%'}}>
              <Tag
                preset={"iconSmall"}
                icon="money-check-dollar"
                children={`${paymentType} ${item?.amount}`}
                textPreset={'body'}
              />
            </View>
            <View style={{marginBottom: 6, maxWidth: '100%', paddingRight: 20}}>
              <Tag
                textProps={{numberOfLines: 1}}
                preset="smaller"
                icon="map-marker-alt"
                children={location}
                textPreset={'body'}
              />
            </View>
          </View>
          <View
            style={{flexDirection: 'row', alignItems: 'center', marginTop: 10, display: hasContact ? 'flex' : 'none'}}>
            {!!logo ?
                <AvatarImage
                source={{uri: logo}} preset={"tinyRound"} py={0} px={0}/>
                :
                <AvatarDefault py={0} px={0} name={item?.contact?.businessProfile?.name} preset={"tinyRound"}/>}

            <View style={{marginLeft: 8}}>
              <Text preset={'body'} text={fullContactName}/>
            </View>
          </View>
        </View>
        {workerApplication?.id && (
          <View style={{flexDirection: "row", marginHorizontal: 20, marginBottom: 20}}>
            <View style={{
              height: 10, flex: 2, borderTopLeftRadius: 20, borderBottomLeftRadius: 20,
              backgroundColor: workerApplication?.status === ApplicationStatus.submitted || workerApplication?.approved
                ? color.palette.orange : color.palette.grey,
            }}/>
            <View style={{
              height: 10, flex: 1, marginHorizontal: 3,
              backgroundColor: workerApplication?.approved ? color.palette.green : color.palette.grey,
            }}/>
            <View style={{
              height: 10, flex: 1, borderTopRightRadius: 20, borderBottomRightRadius: 20,
              backgroundColor: color.palette.grey,
            }}/>
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
}

interface BusinessJobRoleCardProps {
  onPress: () => void,
  onLogoPress?: (id: number) => void,
  item: IJobRoleAdModel,
  messageCount?: boolean,
  applicantCount?: boolean,
  workerApplication?: IWorkerApplicationModel,
  bookmark?: boolean,
  toggleBookmark?: () => void,
}

export function BusinessJobRoleCard(props: BusinessJobRoleCardProps) {
  const {
    onPress, onLogoPress, item, workerApplication, messageCount, applicantCount, bookmark, toggleBookmark
  } = props;
  const roleTitle = item?.jobAd?.title || ''
  const roleDescription = item?.role?.name || ''
  const location = item?.locations?.length > 0 ? parseLocation(item?.locations[0]) : ''
  const companyLogo = item?.jobAd?.businessProfile?.logo?.url
  const contactLogo = item?.contact?.businessProfile?.logo?.url
  const fullContactName = [item?.contact?.user?.firstName, item?.contact?.user?.lastName].join(' ')
  const paymentType = item?.jobAd?.paymentTypes?.length > 0 ? item?.jobAd?.paymentTypes[0]?.name : ''
  const hasContact = !!item?.contact?.id

  return (
    <TouchableOpacity
      style={{
        marginVertical: 10, marginHorizontal: 10,
        backgroundColor: color.palette.white,
        borderRadius: 6,
        borderWidth: 1, borderColor: '#D3D8ED',
        shadowColor: '#022964', shadowOpacity: 0.3,
        shadowOffset: {width: 1, height: 0}, shadowRadius: 4,
        elevation: 4
      }}
      onPress={onPress}>
      <View>
        <View style={{marginHorizontal: 15, marginVertical: 15}}>
          <View style={{flexDirection: 'row'}}>
            <View style={{flex: 1}}>
              <View style={{flexDirection: 'row', marginTop: 4}}>
                <View style={{paddingRight: 8}}>
                  {!!companyLogo ?  <AvatarImage
                      onPress={() => onLogoPress && onLogoPress(item.jobAd.businessProfile.id)}
                      source={{uri: companyLogo}} preset={"medium"} py={0} px={0}/>
                      :
                      <AvatarDefault
                          py={0} px={0}
                          onPress={() => onLogoPress && onLogoPress(item.jobAd.businessProfile.id)}
                          name={item?.jobAd?.businessProfile?.name}
                          preset={"medium"}/>}

                </View>
                <View style={{flex: 1, justifyContent: 'space-between'}}>
                  <View style={{flexDirection: 'row'}}>
                    <View style={{justifyContent: 'center', flex: 1}}>
                      <Text
                        numberOfLines={1}
                        text={roleDescription} preset={'h1'}
                        style={{color: color.palette.black}}/>
                    </View>
                    <View style={{flexDirection: 'row', paddingLeft: 2, alignItems: 'center'}}>
                      {(messageCount && item?.newChatMessages > 0) && (
                        <Tag
                          icon="chatbubble-message" preset="smallerLight"
                          children={''}
                          textPreset={'body'} textStyle={{color: color.palette.white}}
                          containerStyle={{backgroundColor: color.palette.red, paddingHorizontal: 8}}
                        />
                      )}
                      {(applicantCount && item?.newApplicants > 0) && (
                        <View style={{justifyContent: 'center', paddingRight: 2}}>
                          <SvgIcon icon={'user'} preset={'smallPrimary'}/>
                        </View>
                      )}
                      {!!toggleBookmark && <TouchableOpacity onPress={toggleBookmark}>
                        <SvgIcon
                            icon="bookmark" theme={bookmark ? "solid" : "regular"}
                            preset={"small"}/>
                      </TouchableOpacity>}
                    </View>
                  </View>
                  <View style={{height: 4}}/>
                  <TouchableOpacity
                    onPress={() => onLogoPress && onLogoPress(item.jobAd.businessProfile.id)}
                    style={{alignSelf: 'baseline'}}>
                    <Text
                      numberOfLines={1}
                      text={item.jobAd?.businessProfile?.name} preset={'body'}
                      style={{fontSize: 16, fontWeight: "600", color: color.palette.blue}}/>
                  </TouchableOpacity>
                  <View style={{height: 4}}/>
                </View>
              </View>
              <View style={{height: 12}}/>
              <Text
                text={roleTitle} preset={'body'}
                style={{fontWeight: "400", color: color.palette.dark}}/>
              <View style={{height: 12}}/>
              <Text
                text={`Posted: ${stringToDateStr(item?.createdAt)}`} preset={'body'}
                style={{color: color.palette.textMuted, fontWeight: "400"}}/>
            </View>
          </View>
          <View style={{flexDirection: "row", flexWrap: "wrap", marginTop: isAndroid ? 12 : 16,}}>
            <View style={{marginBottom: 6, maxWidth: '100%'}}>
              <Tag
                preset="smaller"
                icon="user"
                children={String(item?.numberOfPositions)}
                textPreset={'body'}
              />
            </View>
            <View style={{marginBottom: 6, maxWidth: '100%'}}>
              <Tag
                preset="iconSmall"
                icon="pen-field"
                children={item?.workType}
                textPreset={'body'} textStyle={{color: color.palette.dark}}
                containerStyle={{marginBottom: 10, marginRight: 4}}
              />
            </View>
            <View style={{marginBottom: 6, maxWidth: '100%'}}>
              <Tag
                preset={"iconSmall"}
                icon="money-check-dollar"
                children={`${paymentType} ${item?.amount}`}
                textPreset={'body'}
              />
            </View>
            <View style={{marginBottom: 6, maxWidth: '100%', overflow: 'hidden', paddingRight: 20}}>
              <Tag
                textProps={{numberOfLines: 1}}
                preset="smaller"
                icon="map-marker-alt"
                children={location}
                textPreset={'body'}
              />
            </View>
          </View>
          <View
            style={{flexDirection: 'row', alignItems: 'center', marginTop: 10, display: hasContact ? 'flex' : 'none'}}>
            {!!contactLogo ? <AvatarImage
                source={{uri: contactLogo}} preset={"tinyRound"} py={0} px={0}/>
                :
                <AvatarDefault py={0} px={0} name={item?.jobAd?.businessProfile?.name} preset={"tinyRound"}/>}

            <View style={{marginLeft: 8}}>
              <Text preset={'body'} text={fullContactName}/>
            </View>
          </View>
        </View>
        {workerApplication?.id && (
          <View style={{flexDirection: "row", marginHorizontal: 20, marginBottom: 20}}>
            <View style={{
              height: 3, flex: 2, borderTopLeftRadius: 20, borderBottomLeftRadius: 20,
              backgroundColor: workerApplication?.status === ApplicationStatus.submitted || workerApplication?.approved
                ? color.palette.orange : color.palette.grey,
            }}/>
            <View style={{
              height: 3, flex: 1, marginHorizontal: 3,
              backgroundColor: workerApplication?.approved ? color.palette.green : color.palette.grey,
            }}/>
            <View style={{
              height: 3, flex: 1, borderTopRightRadius: 20, borderBottomRightRadius: 20,
              backgroundColor: color.palette.grey,
            }}/>
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
}

const BASE_PILL: ViewStyle = {
  marginLeft: 8,
  paddingHorizontal: 8,
  paddingVertical: 6,
  backgroundColor: color.palette.greyHigh,
  borderRadius: 4
}

const styles = StyleSheet.create({
  pill: {...BASE_PILL},
  pillRed: {...BASE_PILL, backgroundColor: color.palette.red},
  pillGreen: {...BASE_PILL, backgroundColor: color.palette.green},
  wrapperJobRole:{
    width: 260,
    backgroundColor: "#fff",
    marginLeft: 10, marginRight: 10, marginVertical: 20,
    borderRadius: 6,
    borderWidth: 1, borderColor: '#D3D8ED',
    shadowColor: '#022964', shadowOpacity: 0.3,
    shadowOffset: {width: 1, height: 0}, shadowRadius: 4,
    elevation: 4
  },
  wrapperJobRoleDK:{
    width: 'unset',
    marginLeft: 5,
    marginRight: 4,
    marginBottom: 28,
    marginTop: 2,
  }
})
