import moment from "moment";
import {Dimensions} from "react-native";

export const windowHeight = Dimensions.get("window").height

export interface SearchItemI {
  highlight: boolean
  text: string
}

export const generateSearchItem = (text, searchText): Array<SearchItemI> => {
  const _searchText = searchText.toLowerCase();
  const _searchTextLength = _searchText.length;
  let itemTmp = text;
  let itemLower = itemTmp.toLowerCase();
  const wordList = Array<SearchItemI>();
  if (!_searchText) {
    wordList.push({highlight: false, text: itemTmp});
  } else {
    while (itemTmp) {
      // console.log(itemTmp, _searchText);
      const firstPos = itemLower.indexOf(_searchText);
      if (firstPos < 0) {
        wordList.push({highlight: false, text: itemTmp});
        break;
      }
      const firstWord = itemTmp.substr(0, firstPos);
      if (firstWord) wordList.push({highlight: false, text: firstWord});
      wordList.push({highlight: true, text: itemTmp.substr(firstPos, _searchTextLength)});
      itemTmp = itemTmp.substr(firstPos + _searchTextLength);
      itemLower = itemLower.substr(firstPos + _searchTextLength);
    }
  }
  return wordList;
}

export const stringToDateStr = (txt) => {
  return moment(txt).format('DD/MM/YYYY')
}

export const dateToShortDateStr = (date: Date, full = false) => {
  return moment(date).format(full ? 'DD/MM/YYYY' : 'DD/MM/YY')
}

export const validateEmail = (email) => {
  const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  return reg.test(email)
}

export const searchIgnoreCase = (src, search) => {
  return src?.trim()?.toLowerCase()?.includes(search?.trim()?.toLowerCase())
}

export const searchCompare = (src, search) => {
  return src?.trim().toLowerCase() === search?.trim().toLowerCase()
}

export const listToStr = (ls: any[], sep = ' '): string => {
  return ls?.filter(it => !!it)?.join(sep)
}
export const safeArrayConvert = (arr: any): any => {
  if (Array.isArray(arr?.data)) return arr.data
  else if (Array.isArray(arr)) return arr
  return []
}

export const getFistName = (name: string) => {
  return !!name ? name.slice(0,1).toUpperCase() : 'I'
}

export const urlIsDoc = (url: string) => {
  return url?.endsWith('pdf') || url?.endsWith('doc') || url?.endsWith('docx')
}
