export const palette = {
  primary: '#222AB3',
  primary15: '#222AB326',
  secondary: '#FF6700',
  secondary15: '#FF670026',
  green: '#49CA1C',
  red: "#FF0000",
  ping: '#FFE8EB',
  dark: '#131635',
  greyHigh: '#9299BE',
  grey: "#F2F3F8",
  grey7: "#545A77",
  grey3: "#E7EBFB",
  greyInputBorder: '#BBC2DC',
  textMuted: '#BBC2DC',
  greyLight: '#F6F6FC',
  greyLightMed: '#DEDFF4',
  gradientStart: '#FFFFFF',
  gradientStop: '#F5F7F9',

  black: "#1C1C1C",
  black00: "#000000",
  black51: "#00000082",
  black30: "#1C1C1C4D",
  black80: "#1C1C1CCC",
  shadow: "#022964",
  white: "#FFFFFF",
  greyMed: "#E9EAF0",
  greyMedH: "#D3D8ED",
  greyDark: "#AEB0B8",
  greyLink: "#7A7A7AD6",
  bgBlue: "#EDF2FF",
  bgBlue30: "#EDF2FF4D",
  blue: "#222AB3",
  blueMed: "#698EEF",
  blueLine: "#222AB30F",
  orange: "#FF6700",
  orangeLow: "#FFE1CC",
  orangeMid: "#FFAE77",
  bgGreen: "#E9F9E4",
  greenMed: "#AEEBA8",
  pink: "#FF00E5DE",
  transparent: "#00000000",

  offWhite: "#e6e6e6",
  orangeDarker: "#EB9918",
  lightGrey: "#939AA4",
  lighterGrey: "#CDD4DA",
  angry: "#dd3333",
  deepPurple: "#5D2555",
  greyAA: "#AAAAAA",
  greyC4: '#C4C4C4',
  red03: 'rgba(222,0,0,0.3)'
}
