import React, {FC, useCallback, useState} from "react"
import {
  View,
  SafeAreaView, ScrollView, RefreshControl
} from "react-native"
import {observer} from "mobx-react-lite"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Header, GradientBackground, Text, TextField, Button} from "../../../../components"
import {color} from "../../../../theme"
import {SelectTextField} from "../../../../components/text-field/select-text-field";
import {IKvItemModel, KvItemPath} from "../../../../models/app/kv-item";
import {useStores} from "../../../../models";
import {JobTags} from "../../../../models/app/job-advert";
import {searchCompare, searchIgnoreCase} from "../../../../utils/utils";
import {NavigatorParamList} from "../../../../navigators";
import {useFocusEffect} from "@react-navigation/native";
import {AlertModalStore} from "../../../../models/app/alert-modal-store";
import {PureBottomModal} from "../../../../components/backdrop-modal/pure-bottom-modal";
import {isAndroid} from "../../../../utils/platform";
import {debounce} from "../../../../utils/debounce";
import {PickerSelectField} from "../../../../components/picker-select/picker-select";
import {getUserSkillStr} from "../../../../models/app/advanced-skill";

export interface JobTagSkillsViewProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
}

//DEPRECATED
export const JobTagSkillsView: FC<JobTagSkillsViewProps> = observer(
  ({navigation}) => {
    const {app} = useStores();
    const [loading, setLoading] = useState(false)
    const [description, setDescription] = useState("")
    const [data, setData] = useState<Array<IKvItemModel>>([]);
    const [customIds, setCustomIds] = useState<Array<number>>([]);
    const [categoriesOpen, setCategoriesOpen] = useState(false)
    const [listCategories, setListCategories] = useState<Array<IKvItemModel>>([]);
    const [skillCategory, setSkillCategory] = useState(0)

    const loadData = async (text?: string, skillCategory?: number) => {
      if (!text || text?.length < 3) return
      try {
        text = text?.trim();
        const userRes = await app.environment.api.getUserSkill({
          ...(skillCategory ? {'filters[skillCategory][id]': skillCategory} : null),
          ...(text ? {'filters[skillCategory][name][$containsi]': text} : null),
          'filters[user][id]': app?.userAccount?.id,
        })
        if (userRes.kind !== 'ok') return
        setCustomIds(userRes?.items?.map(it => it.id))
        const sysRes = await app.environment.api.getUserSkill({
          ...(skillCategory ? {'filters[skillCategory][id]': skillCategory} : null),
          ...(text ? {'filters[skillCategory][name][$containsi]': text} : null),
        })
        if (sysRes.kind === 'ok') {
          setData(
            [...userRes?.items, ...sysRes?.items].map(it => ({id: it.id, name: getUserSkillStr(it)}))
          )
        }

      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    const initData = async () => {
      const categoryRes = await app.environment.api.getKvItemList('skill-categories')
      if (categoryRes.kind !== 'ok') {
        AlertModalStore.alert('Error', categoryRes.message)
        return
      }
      setListCategories(categoryRes.items)
      app.getMe()
    }

    useFocusEffect(useCallback(() => {
      initData()
    }, []))

    const toggleSelectItem = (id: number) => {
      const _item = app?.userAccount?.workerProfile?.skills?.find(it => it?.id === id)
      if (_item) {
        app.toggleWorkerSkillSkillsTag(data?.find(it => it.id === id))
      } else navigation.navigate('workerAddSkillScreen', {originId: _item?.id})
    }

    const onDone = async () => {
      await app.environment.api.updateWorkerProfileData(app?.userAccount?.workerProfile?.id, {
        skills: app?.userAccount?.workerProfile?.skills?.map(it => it.id),
      })
      navigation.goBack()
    }

    const handleAddItem = async () => {
      if (!skillCategory || loading) return
      setLoading(true)
      try {
        const _skillCategory = listCategories?.find(it => it.id === skillCategory)
        if (!_skillCategory) return
        const res = await app.environment.api.editKvItemBusiness(KvItemPath.skill, {
          skillCategory, description,
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }

        setDescription('')
        await loadData(description, skillCategory)
        app.toggleWorkerSkillSkillsTag({
          id: res?.item?.id, name: _skillCategory?.name
            ? [_skillCategory?.name, description].join(' - ') : description,
        } as IKvItemModel)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    const delayLoadData = useCallback(debounce(loadData, 600), []);
    const handleChangeSearch = (text: string) => {
        setDescription(text)
        setLoading(true)
        // @ts-ignore
      delayLoadData(text, skillCategory)
    }

    const loadDataFromState = () => {
      loadData(description, skillCategory)
    }

    const renderItem = (item: IKvItemModel, index: number) => {
      const checked = app?.userAccount?.workerProfile?.skills?.find(it => it.id === item.id)
      return (
        <SelectTextField
          key={`${index}-${item.id}`}
          value={item.name} onPress={() => toggleSelectItem(item.id)}
          style={{marginBottom: 10}}
          rightIcon={checked ? 'check-square' : 'square-plus'}
          rightIconTheme={checked ? 'solid' : 'solid'}
          rightIconPreset={checked ? 'primary' : 'primary'}
          preset={checked ? 'itemSelected' : 'transparent'}
        />
      )
    }

    const _systemData = data.filter(item => !customIds.includes(item.id) && searchIgnoreCase(item?.name, description))
    const _customData = data.filter(item => customIds.includes(item.id) && searchIgnoreCase(item?.name, description))
    const showBtnAdd = !loading && description?.length >= 3 && data.find( item => searchCompare(item?.name, description)) === undefined;
    const showBtnDone = app?.userAccount?.workerProfile?.skills?.length > 0
    return (
      <PureBottomModal onClose={() => navigation.goBack()}>
        <SafeAreaView style={{flex: 1}}>
          <GradientBackground
            colors={[color.palette.gradientStart, color.palette.gradientStop]}
          />
          <Header
            preset={"modalHeader"}
            titlePreset={"headerTitle"}
            headerText={JobTags.skills.text}
            rightIcon={"times"}
            rightIconPreset={"smallBlue"}
            onRightPress={() => navigation.goBack()}
          />
          <View style={{paddingHorizontal: 20, flex: 1}}>
            <View style={{flex: 1}}>
              <View style={{}}>
                <View style={{marginTop: 0, marginBottom: 8}}>
                  <View style={{display: 'none'}}>
                    <TextField
                      value={description} onChangeText={handleChangeSearch}
                      labelPreset={"default"}
                      rightIcon={"times-circle-fill"}
                      rightIconPreset={"default"}
                      rightIconPress={() => handleChangeSearch('')}
                    />
                  </View>
                  <PickerSelectField
                    onOpen={() => setCategoriesOpen(true)}
                    onClose={() => setCategoriesOpen(false)}
                    onValueChange={setSkillCategory}
                    items={listCategories?.map(item => ({
                      key: item.id, value: item.id,
                      label: item?.name
                    }))}
                    preset={"default"}
                    label={"Category"} labelPreset={"h4"}
                    placeholder="Select"
                    rightIcon={categoriesOpen ? 'chevron-down' : 'chevron-right'}
                  />
                  <View style={{height: 20}}/>
                  <TextField
                    value={description} onChangeText={handleChangeSearch}
                    preset={'primary'}
                    labelPreset={"h4"} label={'Description'}/>
                </View>
                {showBtnAdd && (
                  <Button preset={"brandDisabled"} onPress={handleAddItem} disabled={loading}>
                    <Text preset={"mediumBoldBlue"}>Add other r</Text>
                  </Button>
                )}
              </View>
              {data.length > 0 ? (
                <View style={{marginTop: 24, flex: 1}}>
                  <ScrollView
                    refreshControl={<RefreshControl refreshing={loading} onRefresh={loadDataFromState}/>}
                    showsVerticalScrollIndicator={false} style={{flex: 1}}>
                    <View>
                      {_customData && _customData.length > 0 ? (
                        <View>
                          <View style={{marginHorizontal: 16}}>
                            <Text preset={"mediumBlue"}>Other</Text>
                          </View>
                          <View style={{height: 10}}/>
                          {_customData.map(renderItem)}
                        </View>
                      ) : null}
                      {data ? (
                        <View>
                          <View style={{marginHorizontal: 16, marginBottom: 10}}>
                            <Text preset={"mediumBlue"}>Results</Text>
                          </View>
                          {_systemData.map(renderItem)}
                        </View>
                      ) : null}
                    </View>
                  </ScrollView>
                </View>
              ) : null}
            </View>
            <View style={{display: showBtnDone ? 'flex' : 'none', paddingTop: 10, marginBottom: isAndroid ? 10 : 5}}>
              <Button preset={"brand"} onPress={onDone} text={'Done'} disabled={loading}/>
            </View>
          </View>
        </SafeAreaView>
      </PureBottomModal>
    )
  },
)
