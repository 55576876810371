import React, { FC, useCallback, useState } from 'react';
import { View, SafeAreaView, ScrollView, RefreshControl } from 'react-native';

import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../models";
import { ParamListBase, useFocusEffect } from "@react-navigation/native";
import { DEFAULT_LIMIT } from "../../../utils/constants";
import {
  IPureWorkerWalletItem,
  IWorkerWalletItemModel, KvSkillPath, WorkerWalletItemPath
} from "../../../models/app/advanced-skill";
import { IKvItemModel } from "../../../models/app/kv-item";
import { SelectTypeSections } from "../../worker/wallet/document/upload-select-type-screen";
import LineTitle from "../../worker/wallet/components/LineTitle";
import CardList from "../../worker/wallet/components/CardList";
import OtherCard from "../../worker/wallet/components/OtherCard";
import { IUserAccountModel } from "../../../models/app/user-account";
import { urlIsDoc } from "../../../utils/utils";
import Tags from "../../worker/wallet/components/Tags";

export interface WorkerProfileWalletProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  userAccount: IUserAccountModel
}

export const WorkerProfileWallet: FC<WorkerProfileWalletProps> = observer(
  ({navigation, userAccount}) => {
    const {app} = useStores()
    const [loading, setLoading] = useState(false)

    const [otherList, setOtherList] = useState<Array<IWorkerWalletItemModel>>([])

    const [selectedItem, setSelectedItem] = useState<IKvItemModel>()
    const [listCategories, setListCategories] = useState<IKvItemModel[]>([])

    const loadData = async () => {
      setLoading(true)
      try {
        const categoryRes = await app.environment.api.getKvItemList(KvSkillPath.skillCategories)
        if (categoryRes.kind === 'ok') setListCategories(categoryRes.items)

        const _otherList: IWorkerWalletItemModel[] = []
        const qualificationRes = await app.environment.api.getWorkerQualification({
          'filters[user][id][$eq]': userAccount?.id,
          "pagination[pageSize]": DEFAULT_LIMIT, sort: "id:DESC",
        })
        if (qualificationRes.kind === "ok") {
          qualificationRes.items
            ?.filter(item => !!item.user.id && !!item?.document?.id)
            ?.forEach(item => _otherList.push({
              id: item?.id,
              key: SelectTypeSections.qualification.key,
              parentId: item?.qualification?.qualificationEducationLevel?.id,
              type: item?.qualification?.qualificationEducationLevel?.name,
              description: item?.qualification?.courseName,
              url: item?.document,
              expiryDate: '', imgBack: null, imgFont: null, workerProfile: null, isCard: false,
              createdAt: item?.createdAt,
            }))
        }
        const skillRes = await app.environment.api.getWorkerSkill({
          'filters[user][id][$eq]': userAccount?.id,
          "pagination[pageSize]": DEFAULT_LIMIT, sort: "id:DESC",
        })
        if (skillRes.kind === "ok") {
          skillRes.items
            ?.filter(item => item?.user?.id)
            ?.forEach(item => _otherList.push({
              id: item?.id,
              key: SelectTypeSections.skill.key,
              parentId: item?.skill?.skillCategory?.id,
              type: item?.skill?.skillCategory?.name,
              description: item?.skill?.description,
              url: item?.document,
              expiryDate: item?.expiryDate,
              createdAt: item?.createdAt,
              imgBack: item?.backImage?.url,
              imgFont: item?.frontImage?.url,
              workerProfile: null, isCard: item?.isCard,
            }))
        }

        const allRes = await Promise.all([
          {id: WorkerWalletItemPath.workerCvs, data: SelectTypeSections.cv},
          {id: WorkerWalletItemPath.workerRightToWorks, data: SelectTypeSections.rightToWork},
          {id: WorkerWalletItemPath.workerOthers, data: SelectTypeSections.other},
        ].map(async (wwItem) => {
          const itemRes = await app.environment.api.getWorkerWalletItemList(wwItem.id, {
            'filters[workerProfile][id][$eq]': userAccount?.workerProfile?.id,
            "pagination[pageSize]": DEFAULT_LIMIT, sort: "id:DESC",
            populate: "url",
          })
          return itemRes.kind === "ok"
            ? itemRes.items?.filter(item => item?.url?.id)?.map((item) => {
              return ({...item, type: wwItem?.data?.name, key: wwItem?.data?.key})
            })
            : []
        }))
        allRes?.forEach(item => _otherList.push(...item))
        setOtherList(_otherList?.sort((a, b) => (
          (new Date(b.createdAt)).getTime() - (new Date(a.createdAt)).getTime()
        )))

      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    useFocusEffect(useCallback(() => {
      loadData()
    }, []))

    const otherPress = (item: IWorkerWalletItemModel) => {
      navigation.navigate('detailCardScreen', {
        id: item?.id, key: item?.key, isCard: false
      } as IPureWorkerWalletItem)
    }
    const handleClickCard = (item: IWorkerWalletItemModel) => {
      navigation.navigate('detailCardScreen', {
        id: item?.id, key: item?.key, isCard: true
      } as IPureWorkerWalletItem)
    }

    return (
      <SafeAreaView style={{flex: 1}}>
        <ScrollView
          style={{marginHorizontal: 20, marginTop: 20}}
          showsVerticalScrollIndicator={false}
          refreshControl={<RefreshControl refreshing={loading} onRefresh={loadData}/>}
        >
          <LineTitle disableAdd={true} title="Cards"/>
          <Tags data={listCategories} selectedItem={selectedItem} setSelectedItem={setSelectedItem}/>
          <CardList
            data={otherList?.filter(item => !!item?.isCard && (!selectedItem?.id || selectedItem?.id == item?.parentId))}
            onClickCard={handleClickCard}/>

          <LineTitle disableAdd={true} title="Other"/>

          <View style={{width: '100%', paddingHorizontal: 2, flexDirection: 'column'}}>
            {otherList?.filter(item => !item?.isCard && item?.url?.id)?.map((item, index) => (
              <OtherCard
                key={`${item?.key}-${item?.id}`}
                onClick={() => otherPress(item)}
                title={item?.type}
                subTile={item?.description || item?.url?.name}
                imgFile={urlIsDoc(item?.url?.url || '') ? "pdf-file" : item?.url?.url}/>
            ))}

          </View>
        </ScrollView>
      </SafeAreaView>
    )
  },
)
