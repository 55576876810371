import { BottomModal } from "../../../components/backdrop-modal/bottom-modal"
import { isAndroid } from "../../../utils/platform"
import { Dimensions, SafeAreaView, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native"
import { Button, Header, Text } from "../../../components"
import React, { useState } from "react"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { IUserMessageModel } from "../../../models/app/message"
import { GroupMessage } from "../../../components/group-message/group-message"
import { SvgIcon } from "../../../components/icon/svg-icon"
import { BackdropModal } from "../../../components/backdrop-modal/backdrop-modal"
import { AlertModalStore } from "../../../models/app/alert-modal-store"
import { useStores } from "../../../models"
import { LoadingModal } from "../../../components/backdrop-modal/loading-modal"

const windowHeight = Dimensions.get("window").height

interface Props {
  isWorker?: boolean;
  isOpen: boolean;
  onClose: () => void;
  data: IUserMessageModel[];
}


const BulkMessageDelete = ({isWorker, isOpen, onClose, data}: Props) => {
  const {app} = useStores();
  const insets = useSafeAreaInsets()
  const [isCheckAll, setCheckAll] = useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false)
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [loading, setLoading] = useState(false)

  const toggleSelectItem = (id) => {
    setSelectedIds(selectedIds?.includes(id) ? selectedIds?.filter(it => it != id) : [...selectedIds, id])
  }

  const handleCheckAll = () => {
    setSelectedIds(isCheckAll ? [] : data?.map(item => item?.id))
    setCheckAll(!isCheckAll)
  }

  const handleDeleteBulk = async () => {
    try {
      setShowModalConfirm(false)
      setLoading(true)
      const ids = selectedIds
      const res = await app.environment.api.deleteBulks(ids.join())
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message)
        return
      } else {
        onClose();
      }

    } catch (e) {
      AlertModalStore.alert('Error', e?.toString())
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const isValid = selectedIds?.length > 0

  return (
    <BottomModal
      style={{
        paddingTop: 20, paddingHorizontal: 10,
        height: windowHeight - insets.top - (isAndroid ? 10 : 10),
      }}
      visible={isOpen} onClose={onClose}
    >
      <SafeAreaView style={{flex: 1}}>
        <Header
          leftIcon="arrow-left"
          leftIconPreset='normal'
          onLeftPress={onClose}
          preset={"transparent"}
          titlePreset={"headerTitle"}
          headerText={"Select Bulk Message(s)"}
        />
        <TouchableOpacity style={styles.bgCheckAll} onPress={handleCheckAll}>
          <SvgIcon
            preset={isCheckAll ? 'smallBlue20' : 'square'}
            icon={isCheckAll ? 'check-square' : 'square-regular'}
            theme={isCheckAll ? 'solid' : 'regular'}
          />
          <Text preset="label" style={{color: '#aaaaaa'}}>
            All
          </Text>
        </TouchableOpacity>
        <View style={{flex: 1, justifyContent: "space-between", paddingHorizontal: 10}}>
          <View style={{flex: 1, paddingTop: 20}}>
            <ScrollView
              showsVerticalScrollIndicator={false}>
              {data.map((bulkItem, idx) => {
                return (
                  <GroupMessage
                    key={bulkItem.id}
                    isWorker={isWorker}
                    item={bulkItem}
                    typeCheck
                    isCheck={selectedIds?.includes(bulkItem?.id)}
                    onPress={() => toggleSelectItem(bulkItem.id)}
                  />
                )
              })}
            </ScrollView>
          </View>
          <View style={{flexDirection: "row", justifyContent: "space-between", paddingTop: 20}}>
            <Button
              disabled={!isValid}
              preset={isValid ? 'brand' : 'disabled'}
              onPress={() => setShowModalConfirm(true)}
              style={{flex: 1}}
              text={"Delete Bulk Message(s)"}
            />
          </View>
        </View>
      </SafeAreaView>
      <BackdropModal visible={showModalConfirm} onClose={() => setShowModalConfirm(false)}>
        <View>
          <View style={{
            alignItems: "center",
            justifyContent: 'center',
            padding: 10,
          }}>
            <Text preset="textNotify" style={{textAlign: 'center', maxWidth: 200, marginBottom: 20}}>
              You have selected Bulk Message(s) to Delete
            </Text>
            <Text preset="textNotify" style={{textAlign: 'center', maxWidth: 200, marginBottom: 20, color: '#000000'}}>
              This will result in all underlying Messages also being deleted
            </Text>
            <SvgIcon iconStyle={{width: 80, height: 80}} preset={'alertIcon'}/>
            <View style={{paddingTop: 18, paddingBottom: 8}}>
              <Text preset={'alertTitle'}>Are you sure?</Text>
            </View>
          </View>
          <Button
            style={styles.modalBtn}
            preset='brand'
            text={'Delete Bulk Message(s)'}
            onPress={handleDeleteBulk}
          />
          <Button
            onPress={() => setShowModalConfirm(false)}
            style={styles.modalBtn}
            preset={'destructive'}
            text={'Cancel'}
          />
        </View>
      </BackdropModal>
      <LoadingModal visible={loading}/>
    </BottomModal>
  )
};

export default BulkMessageDelete;

const styles = StyleSheet.create({
  bgCheckAll: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: 25
  },
  modalBtn: {
    marginVertical: 8
  },
});
