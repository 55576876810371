import { cast, flow, Instance, types } from "mobx-state-tree"

import { withEnvironment } from "../extensions/with-environment"
import { UserModel } from "./user"
import {
  GeneralApiResult,
  GetMeResult,
  GetUserRolesResult,
  LoginResult
} from "../../services/api/api.types"
import {
  convertUserRoleModel,
  IUserAccountModel,
  IUserRoleModel,
  UserAccountModel,
  UserRoleModel
} from "./user-account";
import { IJobAdvertModel, JobAdvertModel } from "./job-advert";
import { IJobRoleModel } from "./job-role";
import { constants } from "../../utils/constants";
import {IKvItemCheckModel, IKvItemModel, KvItemModel, TypeUserModel, UserItemModel} from "./kv-item";
import { FilterJobStore } from "./filter-job";
import { IBusinessProfileModel, ICreateLaterBusinessProfileModel, CreateLaterBusinessProfileModel } from "./business-profile";
import { IJobRoleAdModel, JobRoleAdModel } from "./job-role-ad";
import { TabStore } from "./tab-store";
import { FilterStore } from "./filter-store";

export const AppModel = types
  .model("AppModel")
  .props({
    user: types.optional(UserModel, {}),
    userNotes: types.map(KvItemModel),
    userAccount: types.optional(UserAccountModel, {}),
    userRoles: types.optional(types.array(UserRoleModel), []),
    loading: types.optional(types.boolean, false),
    auth: types.maybeNull(types.string),
    jobAdverts: types.optional(types.array(JobAdvertModel), []),
    jobAdvertCreate: types.optional(JobAdvertModel, {}),
    customJobRoleTags: types.optional(types.array(KvItemModel), []),
    customJobExperienceTags: types.optional(types.array(KvItemModel), []),
    customJobSkillTags: types.optional(types.array(KvItemModel), []),
    customJobQualificationTags: types.optional(types.array(KvItemModel), []),
    tabStore: types.optional(TabStore, {}),
    filterStore: types.optional(FilterStore, {}),
    filterJob: types.optional(FilterJobStore, {}),
    hasFilterJob: types.optional(types.boolean, false),
    searchJobRoleText: types.optional(types.string, ''),
    hasSearchJobRole: types.optional(types.boolean, false),
    selectedContact: types.maybeNull(KvItemModel),
    listSelectedContact: types.optional(types.array(UserItemModel), []),
    listExistedContact: types.optional(types.array(types.number), []),
    manageJobScreenFilter: types.optional(types.boolean, false),
    createLaterBusinessProfile: types.optional(CreateLaterBusinessProfileModel, {}),
    createLaterJobRoleAd: types.optional(JobRoleAdModel, {}),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setLoading: (loading: boolean) => {
      self.loading = loading
    },
    setAuth: (auth: string) => {
      self.auth = auth
      self.environment.api.setAuth(auth)
    },
    setUserAccount: (userAccount: IUserAccountModel) => {
      if (userAccount?.userRoles?.length < 1) {
        userAccount.userRoles.push(convertUserRoleModel({
          id: 0, key: constants.roles.USER, name: constants.rolesName.USER
        }))
      }
      self.userAccount = userAccount
    },
    setUserBusinessProfile: (businessProfile: IBusinessProfileModel) => {
      self.userAccount.businessProfile = businessProfile
    },
    setUserRoles: (userRoles: Array<IUserRoleModel>) => {
      self.userRoles = cast(userRoles);
    },
  }))
  .actions((self) => ({
    onLogin: flow(function* (email: string, password: string) {
      self.loading = true
      // const auth = yield self.setAuth(null)
      const res: LoginResult = yield self.environment.api.userLogin(email, password)
      if (res.kind === 'ok') {
        self.setAuth(res.jwt)
      }
      self.loading = false
      return res
    }),
    onSignUp: flow(function* (data: any) {
      self.loading = true
      self.setAuth(null)
      const res: LoginResult = yield self.environment.api.userSignUp(data)
      // if (res.kind === 'ok') {
      //   self.setUserAccount(res.userAccount)
      //   self.setAuth(res.jwt)
      // }
      self.loading = false
      return res
    }),
    onUpdateUser: flow(function* (data: any) {
      self.loading = true
      const res: GetMeResult = yield self.environment.api.onUpdateUser(data)
      if (res.kind === 'ok') {
        self.setUserAccount(res.userAccount)
      }
      self.loading = false
      return res
    }),
    updateUserProfile: flow(function* (
      {workerProfile, businessProfile}: { workerProfile?: number, businessProfile?: number }
    ) {
      self.loading = true
      const roles = self.userAccount.userRoles.filter(it => !!it.id).map(it => it.id)
      const newRole = self.userRoles.find(it => (
        it.key === (workerProfile ? constants.roles.WORKER : constants.roles.BUSINESS)
      ))
      if (newRole && !roles.includes(newRole.id)) roles.push(newRole.id)
      const res: GetMeResult = yield self.environment.api.updateUserProfile({workerProfile, businessProfile}, roles)
      if (res.kind === 'ok') {
        self.setUserAccount(res.userAccount)
      }
      self.loading = false
      return res
    }),
    getMe: flow(function* () {
      // self.loading = true
      const res: GetMeResult = yield self.environment.api.getMe()
      if (res.kind === 'ok') {
        self.setUserAccount(res.userAccount)
      }
      // self.loading = false
      return res
    }),
    getUserRoles: flow(function* () {
      self.loading = true
      const res: GetUserRolesResult = yield self.environment.api.getUserRoles()
      if (res.kind === 'ok') {
        self.setUserRoles(res.userRoles)
      }
      self.loading = false
      return res
    }),
    onForgotPassword: flow(function* () {
      self.loading = true
      const res: GeneralApiResult = yield self.environment.api.onForgotPassword(self.userAccount.email)
      self.loading = false
      return res
    }),
    onSignOut: function () {
      // let fcmToken = yield messaging().getToken();
      // self.environment.api.setDeviceToken(USER_TOKEN_PATH, {
      //   deviceToken: fcmToken,
      //   action: USER_TOKEN_ACTION.destroy
      // })
      self.user.setRole(constants.roles.GUEST)
      self.setAuth(null)
      self.setUserAccount({} as IUserAccountModel)
    },
    setJobAdvertCreate: function (jobAdvertCreate: IJobAdvertModel) {
      self.jobAdvertCreate = jobAdvertCreate
    },
    editJobAdvertCreateQualifications: function (item: IKvItemCheckModel) {
      const oldItem = self.jobAdvertCreate.qualifications.find((it) => it.id === item.id)
      if (item.checked && oldItem) return
      if (item.checked) self.jobAdvertCreate.qualifications.push(item)
      else {
        self.jobAdvertCreate.qualifications = cast(
          self.jobAdvertCreate.qualifications.filter(_item => _item.id !== item.id)
        )
      }
    },
    addCustomJobRoleTags: (item: IKvItemModel) => {
      const oldItem = self.customJobRoleTags.find((it) => it.id === item.id)
      if (oldItem) return
      self.customJobRoleTags.push(item);
    },
    addCustomJobExperienceTags: (item: IKvItemModel) => {
      const oldItem = self.customJobExperienceTags.find((it) => it.id === item.id)
      if (oldItem) return
      self.customJobExperienceTags.push(item);
    },
    addCustomJobSkillTags: (item: IKvItemModel) => {
      const oldItem = self.customJobSkillTags.find((it) => it.id === item.id)
      if (oldItem) return
      self.customJobSkillTags.push(item);
    },
    addCustomJobQualificationTags: (item: IKvItemModel) => {
      const oldItem = self.customJobQualificationTags.find((it) => it.id === item.id)
      if (oldItem) return
      self.customJobQualificationTags.push(item);
    },
    addJobAdvertCreateRoles: function (role: IJobRoleModel) {
      self.jobAdvertCreate.jobRoles.push(role)
    },
    toggleSelectWorkerSkillIndex: (role: string, id) => {
      self.userAccount.workerProfile[role] = self.userAccount?.workerProfile[role].filter((it) => it.id !== id);
    },
    setWorkerSkillIndustriesTag: (items: IKvItemModel[]) => {
      self.userAccount.workerProfile.industries = cast(items);
    },
    setWorkerSkillRolesTag: (items: IKvItemModel[]) => {
      self.userAccount.workerProfile.workerRoles = cast(items);
    },
    setWorkerSkillExperienceTag: (items: IKvItemModel[]) => {
      self.userAccount.workerProfile.experiences = cast(items);
    },
    setWorkerSkillSkillsTag: (items: IKvItemModel[]) => {
      self.userAccount.workerProfile.skills = cast(items);
    },
    setWorkerSkillQualificationsTag: (items: IKvItemModel[]) => {
      self.userAccount.workerProfile.qualifications = cast(items);
    },
    toggleWorkerSkillIndustriesTag: (item: IKvItemModel) => {
      const oldItem = self.userAccount?.workerProfile?.industries?.findIndex((it) => it.id === item.id)
      if (oldItem >= 0) self.userAccount.workerProfile.industries.splice(oldItem, 1);
      else self.userAccount.workerProfile.industries.push(item);
    },
    toggleWorkerSkillRolesTag: (item: IKvItemModel) => {
      const oldItem = self.userAccount?.workerProfile?.workerRoles?.findIndex((it) => it.id === item.id)
      if (oldItem >= 0) self.userAccount.workerProfile.workerRoles.splice(oldItem, 1);
      else self.userAccount.workerProfile.workerRoles.push(item);
    },
    toggleWorkerSkillExperienceTag: (item: IKvItemModel) => {
      const oldItem = self.userAccount?.workerProfile?.experiences?.findIndex((it) => it.id === item.id)
      if (oldItem >= 0) self.userAccount.workerProfile.experiences.splice(oldItem, 1);
      else self.userAccount.workerProfile.experiences.push(item);
    },
    toggleWorkerSkillSkillsTag: (item: IKvItemModel) => {
      const oldItem = self.userAccount?.workerProfile?.skills?.findIndex((it) => it.id === item.id)
      if (oldItem >= 0) self.userAccount.workerProfile.skills.splice(oldItem, 1);
      else self.userAccount.workerProfile.skills.push(item);
    },
    toggleWorkerSkillQualificationsTag: (item: IKvItemModel) => {
      const oldItem = self.userAccount?.workerProfile?.qualifications?.findIndex((it) => it.id === item.id)
      if (oldItem >= 0) self.userAccount.workerProfile.qualifications.splice(oldItem, 1);
      else self.userAccount.workerProfile.qualifications.push(item);
    },
    removeJobAdvertCreateRoles: function (role: IJobRoleModel) {
      const index = self.jobAdvertCreate.jobRoles.findIndex(item => item.id === role.id)
      self.jobAdvertCreate.jobRoles.splice(index, 1)
    },
    setUserNote: (note: IKvItemModel) => {
      self.userNotes.set(String(note.id), note)
    },
    setFilterJobRoleText: (val: string) => {
      self.searchJobRoleText = val
    },
    setHasSearchJobRole: (val: boolean) => {
      self.hasSearchJobRole = val
    },
    setHasFilterJob: (val: boolean) => {
      self.hasFilterJob = val
    },
    setPreferredLocations: (preferredLocations: IKvItemModel[]) => {
      self.userAccount.workerProfile = {...self.userAccount.workerProfile, preferredLocations: cast(preferredLocations)}
    },
    setSelectedContact: (selectedContact?: IKvItemModel) => {
      self.selectedContact = selectedContact
    },
    setListSelectedContact: (selectedContact?: TypeUserModel[]) => {
      self.listSelectedContact = cast(selectedContact);
    },
    pushListSelectedContact: (selectedContact?: TypeUserModel[]) => {
      self.listSelectedContact.push(...selectedContact)
    },
    setListExistedContact: (items?: number[]) => {
      self.listExistedContact = cast(items)
    },
    setManageJobScreenFilter: (manageJobScreenFilter: boolean) => {
      self.manageJobScreenFilter = manageJobScreenFilter
    },
    setCreateLaterBusinessProfile: (createLaterBusinessProfile: ICreateLaterBusinessProfileModel) => {
      self.createLaterBusinessProfile = createLaterBusinessProfile
    },
    setCreateLaterJobRoleAd: (createLaterJobRoleAd: IJobRoleAdModel) => {
      self.createLaterJobRoleAd = createLaterJobRoleAd
    },
    resetCreateLater: () => {
      self.createLaterBusinessProfile = {} as ICreateLaterBusinessProfileModel
      self.createLaterJobRoleAd={} as IJobRoleAdModel
    },
  }))
  .views(self => ({
    userFullName: (): string => {
      const userAccount = self.userAccount || {} as IUserAccountModel
      return (`${userAccount?.firstName} ${userAccount?.lastName}`)
    },
    getCustomJobRoleTagIds: (): Array<number> => {
      return self.customJobRoleTags.map(it => it.id)
    },
    getCustomJobExperienceTags: (): Array<number> => {
      return self.customJobExperienceTags.map(it => it.id)
    },
    getCustomJobSkillTags: (): Array<number> => {
      return self.customJobSkillTags.map(it => it.id)
    },
    getCustomJobQualificationTags: (): Array<number> => {
      return self.customJobQualificationTags.map(it => it.id)
    },
    getUserNote: (id: number): IKvItemModel => {
      return self.userNotes.get(String(id))
    },
    getListSelectedContact: () : Array<TypeUserModel> => {
      return self.listSelectedContact?.toJSON()
    }
  }))
export type AppType = Instance<typeof AppModel>
