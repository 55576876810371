import React, { FC, useCallback, useState } from 'react';
import { View, StyleSheet, SafeAreaView, ScrollView } from 'react-native';

import { observer } from "mobx-react-lite";
import { Button, Text, TextField } from "../../../components";
import { SvgIcon } from "../../../components/icon/svg-icon";
import { FlatCard } from "../../../components/card/flat-card";
import { useStores } from "../../../models";
import { useFocusEffect } from "@react-navigation/native";
import { IWorkerNoteModel } from "../../../models/app/worker-note";
import { AlertModalStore } from "../../../models/app/alert-modal-store";
import { IUserAccountModel } from "../../../models/app/user-account";
import { constants } from "../../../utils/constants";

const WorkerNoteType = {
  rate: 1,
  note: 2,
}

export interface WorkerProfilePreviewProps {
  userAccount: IUserAccountModel
  loading: boolean
  setLoading: (loading: boolean) => void
}

export const WorkerProfilePreview: FC<WorkerProfilePreviewProps> = observer(
  ({userAccount, loading, setLoading}) => {
    const workerProfile = userAccount?.workerProfile
    const {app} = useStores()
    const isManager = app?.user?.role === constants.roles.MANAGER
    const [workerNote, setWorkerNote] = useState<IWorkerNoteModel>(null)
    const [note, setNote] = useState('')

    const loadData = async () => {
      if (loading) return
      try {
        setLoading(true)
        const noteRes = await app.environment.api.getWorkerNotes({
          "filters[businessProfile][id][$eq]": app?.userAccount?.businessProfile?.id,
          "filters[user][id][$eq]": userAccount?.id,
        })
        if (noteRes.kind === 'ok' && noteRes.items.length > 0) {
          const _workerNote = noteRes.items[0]
          setNote(_workerNote?.note || '')
          setWorkerNote(_workerNote)
        }
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }
    useFocusEffect(useCallback(() => {
      loadData()
    }, []))

    const handleSaveWorkerNote = async (data: any, noteType: number) => {
      if (loading) return
      try {
        setLoading(true)
        if (!workerNote?.id) {
          const res = await app.environment.api.createWorkerNote({
            note: data?.note || '',
            rate: data?.rate || 0,
            businessProfile: {id: app?.userAccount?.businessProfile?.id},
            user: userAccount?.id,
          })
          if (res.kind !== 'ok') {
            AlertModalStore.alert('Error', res.message)
            return
          } else {
            AlertModalStore.alert('Info', noteType === WorkerNoteType.rate ? 'Rated' : 'Saved')
          }
        } else {
          const res = await app.environment.api.updateWorkerNote(workerNote?.id, {
            ...data,
            businessProfile: {id: app?.userAccount?.businessProfile?.id},
            businessProfileId: isManager
              ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          })
          if (res.kind !== 'ok') {
            AlertModalStore.alert('Error', res.message)
            return
          } else {
            AlertModalStore.alert('Info', noteType === WorkerNoteType.rate ? 'Rated' : 'Saved')
            setWorkerNote({...workerNote, ...data})
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    const handleSaveNote = async () => {
      handleSaveWorkerNote({note}, WorkerNoteType.note)
    }

    const handleUpdateWorkerProfileRate = async (_rate) => {
      handleSaveWorkerNote({rate: _rate}, WorkerNoteType.rate)
      setWorkerNote({...workerNote, rate: _rate})
    }

    return (
      <SafeAreaView style={styles.container}>
        <ScrollView style={styles.mainContainer}>
          <FlatCard noBorder={true} title={'Internal Notes'} titlePreset={'mediumBold'}>
            <TextField
              inputPreset={'primary'}
              value={note} onChangeText={setNote}
              numberOfLines={4} multiline={true} editable={!!workerProfile?.id}
              inputStyle={{height: 120}}
              placeholder={'Note here'}
            />
            <View style={{marginTop: 20, alignItems: 'flex-end', display: (note && note != workerNote?.note) ? 'flex' : 'none'}}>
              <Button
                preset={'brand'}
                disabled={!workerProfile?.id || loading}
                style={{width: 100}} onPress={handleSaveNote}>
                <Text preset={'white'}>Save</Text>
              </Button>
            </View>
          </FlatCard>
          <FlatCard noBorder={true} title={'Internal Rating'} titlePreset={'mediumBold'}>
            <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
              {Array.from(Array(5).keys()).map((it, index) => (
                <SvgIcon
                  key={index.toString()}
                  onPress={() => handleUpdateWorkerProfileRate(it + 1)}
                  style={{paddingHorizontal: 3}} icon={it < workerNote?.rate ? 'star-regular' : 'star-light'}
                  theme={'regular'}
                  preset={'starBold'}/>
              ))}
            </View>
          </FlatCard>
          <View style={{height: 100}}/>
        </ScrollView>
      </SafeAreaView>
    )
  },
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  mainContainer: {
    paddingHorizontal: 20,
    paddingTop: 10
  },
});
