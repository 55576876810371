import React, {FC} from 'react';
import {View, Pressable} from 'react-native';
import {useStores} from "../../../models";
import {AutoImage as Image} from "../../../components";

export interface IntroScreenProps {
  disablePress?: boolean
}

export const IntroScreen: FC<IntroScreenProps> = (props) => {
  const {app} = useStores();
  const {disablePress} = props;

  const handlePress = () => {
    if (disablePress) return;
    app.user.setFirstLaunch(false)
  }

  return (
    <View style={{flex: 1, backgroundColor: '#2131AC'}}>
      <Pressable
        style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}
        onPress={handlePress}>
        <View>
          <Image source={require('../../../../assets/images/default-icon.png')}/>
          <View style={{height: 80}}/>
        </View>
      </Pressable>
    </View>
  );
}
