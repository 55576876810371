import * as React from "react";
import {FC} from "react";
import {IntroScreen} from "../public";
import {useStores} from "../../models";
import {observer} from "mobx-react-lite";

export interface SplashScreenProps {
  setLoading: (loading) => void
}

export const SplashScreen: FC<SplashScreenProps> = observer((props) => {
  // const {setLoading} = props;
  const {app} = useStores();

  React.useEffect(() => {
    const initApp = async function () {
      if (app?.auth) app?.getMe()
      // try {
      //   if (app.auth) {
      //     app.setAuth()
      //   }
      // } catch (e) {
      //   console.log(e)
      // } finally {
      //   setLoading(false);
      // }
    }
    initApp().then(r => null)
  }, [])

  return <IntroScreen disablePress={true}/>
})
