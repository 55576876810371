import {types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {IKvItemModel, KvItemModel} from "./kv-item";

export const WorkerSkillStore = types
  .model("WorkerSkillStore")
  .props({
    skillCategory: types.maybeNull(KvItemModel),
    qualificationEducationLevel: types.maybeNull(KvItemModel),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setSkillCategory: (skillCategory?: IKvItemModel) => {
      self.skillCategory = skillCategory;
    },
    setQualificationEducationLevel: (qualificationEducationLevel?: IKvItemModel) => {
      self.qualificationEducationLevel = qualificationEducationLevel;
    },
  }))
  .views((self) => ({}))
  .create({})
