import {Instance, types} from "mobx-state-tree"
import {BusinessProfileModel, convertBusinessProfileModel} from "./business-profile";
import {convertUserAccountModel, UserAccountModel} from "./user-account";

export const BUSINESS_ACCESS_STATUS = {
  'pending': {key: 'pending', text: 'Pending Invitation'},
  'accepted': {key: 'accepted', text: 'Accepted Invitation'},
  'rejected': {key: 'rejected', text: 'Declined Invitation'},
  'blocked': {key: 'blocked', text: 'Blocked'},
}
export const BusinessAccessModel = types.model({
  id: types.optional(types.number, 0),
  status: types.optional(types.string, ''),
  code: types.optional(types.string, ''),
  invitationKey: types.optional(types.string, ''),
  businessProfile: types.maybeNull(BusinessProfileModel),
  user: types.maybeNull(UserAccountModel),
  createdAt: types.optional(types.string, ''),
})
export type IBusinessAccessModel = Instance<typeof BusinessAccessModel>
export const convertBusinessAccessModel = (raw): IBusinessAccessModel => ({
  id: raw?.id || 0,
  status: raw?.status || '',
  code: raw?.code || '',
  invitationKey: raw?.invitationKey || '',
  businessProfile: convertBusinessProfileModel(
    raw?.businessProfile?.data?.attributes ? {
      ...raw?.businessProfile?.data?.attributes,
      id: raw?.businessProfile?.data?.id
    } : raw?.businessProfile?.data
  ),
  user: convertUserAccountModel(
    raw?.user?.data?.attributes ? {
      ...raw?.user?.data?.attributes,
      id: raw?.user?.data?.id
    } : raw?.user?.data
  ),
  createdAt: raw?.createdAt || '',
})
