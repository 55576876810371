import React, {FC} from "react"
import {View, FlatList} from "react-native"
import {IJobRoleAdModel} from "../../../models/app/job-role-ad";
import {BusinessJobRoleCard} from "../../../components/card/job-card";

export interface JobInvitesProps {
  onPress: (item: IJobRoleAdModel) => void
  onLogoPress?: (id: number) => void
  jobInvites: Array<IJobRoleAdModel>
  refreshing: boolean
  loadData?: () => void
}

export const JobInvites: FC<JobInvitesProps> = (
  {onPress, onLogoPress, jobInvites, refreshing, loadData}
) => {


  const renderItem = ({item}: { item: IJobRoleAdModel }) => {
    const data = {
      ...item,
      role: item.jobAd?.jobRoles[0]?.role,
      locations: item.jobAd?.jobRoles[0]?.locations,
      workType: item.jobAd?.jobRoles[0].workType,
      numberOfPositions: item.jobAd?.jobRoles[0]?.numberOfPositions,
      amount: item.jobAd?.jobRoles[0]?.amount
    }
    return (
      <BusinessJobRoleCard
        onLogoPress={onLogoPress}
        item={data}
        onPress={() => onPress(item)}/>
    )
  }

  return (
    <View style={{flex: 1}}>
      <View style={{flex: 1, marginHorizontal: 5, marginTop: 5}}>
        <FlatList
          onRefresh={loadData} refreshing={refreshing}
          data={jobInvites}
          renderItem={renderItem}
          ListFooterComponent={<View style={{height: 100}}/>}
          keyExtractor={(item, index) => String(item.id)}
        />
      </View>
    </View>
  )
}
