import React, {FC, useCallback, useState} from "react"
import {
  View,
  SafeAreaView, ScrollView, RefreshControl
} from "react-native"
import {observer} from "mobx-react-lite"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Header, GradientBackground, Text, TextField, Button} from "../../../../components"
import {color} from "../../../../theme"
import {SelectTextField} from "../../../../components/text-field/select-text-field";
import {IKvItemModel, KvItemPath} from "../../../../models/app/kv-item";
import {useStores} from "../../../../models";
import {JobTags} from "../../../../models/app/job-advert";
import {searchIgnoreCase} from "../../../../utils/utils";
import {NavigatorParamList} from "../../../../navigators";
import {useFocusEffect} from "@react-navigation/native";
import {AlertModalStore} from "../../../../models/app/alert-modal-store";
import {PureBottomModal} from "../../../../components/backdrop-modal/pure-bottom-modal";
import {isAndroid} from "../../../../utils/platform";
import {debounce} from "../../../../utils/debounce";

export interface JobTagIndustriesViewProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
}

export const JobTagIndustriesView: FC<JobTagIndustriesViewProps> = observer(
  ({navigation}) => {
    const {app} = useStores();
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState("")
    const [data, setData] = useState<Array<IKvItemModel>>([]);

    const loadData = async (text?: string) => {
      setLoading(true)
      try {
        text = text?.trim();
        const res = await app.environment.api.getKvItemList(KvItemPath.industry, {
          ...(text ? {'filters[name][$containsi]': text} : null)
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        setData(res?.items)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }
    useFocusEffect(useCallback(() => {
      loadData()
    }, []))

    const toggleSelectItem = (id) => {
      app.toggleWorkerSkillIndustriesTag(data?.find(it => it.id === id))
    }

    const onDone = async () => {
      await app.environment.api.updateWorkerProfileData(app?.userAccount?.workerProfile?.id, {
        industries: app?.userAccount?.workerProfile?.industries?.map(it => it.id),
      })
      navigation.goBack()
    }

    const delayLoadData = useCallback(debounce(loadData, 600), []);
    const handleChangeSearch = (text: string) => {
      // @ts-ignore
      delayLoadData(text)
      setSearch(text)
    }
    const loadDataFromState = () => {
      loadData(search)
    }

    const renderItem = (item: IKvItemModel) => {
      const checked = app.userAccount.workerProfile?.industries?.find(it => it.id === item.id)
      return (
        <SelectTextField
          key={String(item.id)}
          value={item.name} onPress={() => toggleSelectItem(item.id)}
          style={{marginBottom: 10}}
          rightIcon={checked ? 'check-square' : 'square-regular'}
          rightIconTheme={checked ? 'solid' : 'regular'}
          rightIconPreset={checked ? 'primary' : 'default'}
          preset={checked ? 'itemSelected' : 'transparent'}
        />
      )
    }

    const filteredData = search ? data.filter(item => searchIgnoreCase(item?.name, search)) : data
    const showBtnDone = app.userAccount.workerProfile?.industries?.length > 0
    return (
      <PureBottomModal onClose={() => navigation.goBack()}>
        <SafeAreaView style={{flex: 1}}>
          <GradientBackground
            colors={[color.palette.gradientStart, color.palette.gradientStop]}
          />
          <Header
            preset={"modalHeader"}
            titlePreset={"headerTitle"}
            headerText={JobTags.industries.text}
            rightIcon={"times"}
            rightIconPreset={"smallBlue"}
            onRightPress={() => navigation.goBack()}
          />
          <View style={{paddingHorizontal: 20, flex: 1}}>
            <View style={{flex: 1}}>
              <View style={{display: 'none'}}>
                <Text preset={"label"}>{JobTags.industries.label}</Text>
                <View style={{marginTop: 24, marginBottom: 8}}>
                  <TextField
                    value={search} onChangeText={handleChangeSearch}
                    labelPreset={"default"}
                    rightIcon={"times-circle-fill"}
                    rightIconPreset={"default"}
                    rightIconPress={() => handleChangeSearch('')}
                  />
                </View>
              </View>
              {data.length > 0 ? (
                <View style={{marginTop: 24, flex: 1}}>
                  <View style={{marginHorizontal: 16, marginBottom: 10}}>
                    <Text preset={"mediumBlue"}>Results</Text>
                  </View>
                  <ScrollView
                    refreshControl={<RefreshControl refreshing={loading} onRefresh={loadDataFromState}/>}
                    showsVerticalScrollIndicator={false} style={{flex: 1}}>
                    <View>
                      {filteredData.map(renderItem)}
                    </View>
                  </ScrollView>
                </View>
              ) : null}
            </View>
            <View style={{display: showBtnDone ? 'flex' : 'none', paddingTop: 10, marginBottom: isAndroid ? 10 : 5}}>
              <Button preset={"brand"} onPress={onDone} text={'Done'} disabled={loading}/>
            </View>
          </View>
        </SafeAreaView>
      </PureBottomModal>
    )
  },
)

