import { ViewStyle } from "react-native"
import { color, spacing } from "../../../theme"

export const CONTAINER: ViewStyle = {
  flex: 1,
  backgroundColor: color.palette.white,
}

export const BODY: ViewStyle = {
    paddingHorizontal : spacing[5],
}

export const BUTTON_CONTAINER: ViewStyle = {
  marginHorizontal : spacing[3],
  marginVertical : spacing[2],
}