import React, {FC, useCallback, useState} from 'react';
import {View, FlatList, StyleSheet, SafeAreaView, TouchableOpacity} from 'react-native';

import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {observer} from "mobx-react-lite";
import {UserMessage} from "../../../components/user-message/user-message";
import {color} from "../../../theme";
import {Button, GradientBackground, Header, Text} from "../../../components";
import {useStores} from "../../../models";
import {ParamListBase, useFocusEffect} from "@react-navigation/native";
import {IUserMessageModel} from "../../../models/app/message";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {SvgIcon} from "../../../components/icon/svg-icon";
import {BackdropModal} from "../../../components/backdrop-modal/backdrop-modal";
import {DEFAULT_PAGE_LIMIT} from "../../../utils/constants";
import {BottomModal} from "../../../components/backdrop-modal/bottom-modal";

export interface UserMessageScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
}

export const UserMessageScreenTags = {
  responses: {key: 2, label: "New Responses", selectLabel: 'Responses'},
}

export const UserMessageScreen: FC<UserMessageScreenProps> = observer(
  ({navigation}) => {
    const [refreshing, setRefreshing] = useState(false)
    const {app} = useStores()
    const [dataList, setDataList] = useState<Array<IUserMessageModel>>([])
    const [visibleMainModal, setVisibleMainModal] = useState(false)
    const [visibleModal, setVisibleModal] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)

    const loadData = async (isNext: boolean = false) => {
      if (refreshing) return
      if (isNext && dataList?.length % DEFAULT_PAGE_LIMIT !== 0) return
      const _currentPage = (isNext ? currentPage : 0) + 1
      setRefreshing(true)
      try {
        const res = await app.environment.api.getNewWorkerMessage({
          'filters[group][contacts][user][id][$eq]': app.userAccount.id,
          "pagination[page]": _currentPage,
          "pagination[pageSize]": DEFAULT_PAGE_LIMIT
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        const _dataList = isNext ? [...dataList, ...res.items] : res.items
        setDataList(_dataList)
        setCurrentPage(_currentPage)
      } catch (e) {
        console.log(e)
      } finally {
        setRefreshing(false)
      }
    }

    useFocusEffect(useCallback(() => {
      loadData()
    }, []))

    const handleGroupPress = async (item: IUserMessageModel) => {
      navigation.navigate('userNewMessageScreen', {messageId: item?.id})
    }

    const handleFilterPress = async () => {
      setVisibleMainModal(false)
      setVisibleModal(true)
    }

    const renderItem = ({item, index}: { item: IUserMessageModel, index: number }) => {
      return (
        <UserMessage
          onLogoPress={() => navigation.navigate('businessProfileScreen', {
            businessProfileId: item?.createdUser?.businessProfile?.id
          })}
          avatar={item?.createdUser?.businessProfile?.logo?.url}
          key={index.toString()}
          title={item.title}
          subTitle={item?.createdUser?.businessProfile?.name}
          time={''}
          isWorker={false}
          onPress={() => handleGroupPress(item)}/>
      );
    }

    const renderTabContent = () => {
      return (
        <FlatList
          onRefresh={loadData} refreshing={refreshing}
          contentContainerStyle={styles.contentContainerStyle}
          data={dataList}
          renderItem={renderItem}
          keyExtractor={(item, index) => String(item.id + '' + index)}
          onEndReached={() => loadData(true)}
          ListFooterComponent={<View style={{height: 50}}/>}
        />
      )
    }

    const handleSelectKey = (key: number) => {
      app.filterStore.setUserMessageFilterId(app.filterStore.userMessageFilterId === key ? null : key)
    }
    const renderFilterItem = (it) => {
      return <View key={it.key} style={{marginRight: 8}}>
        <TouchableOpacity onPress={() => handleSelectKey(it.key)}>
          <View style={{
            flexDirection: 'row', alignItems: 'center',
            backgroundColor: color.palette.greyLight,
            paddingHorizontal: 12,
            paddingVertical: 11,
            borderRadius: 100
          }}>
            <Text preset={'body'} style={{color: color.palette.blue, fontWeight: '400'}}>
              {it.label}
            </Text>
            <View style={{paddingLeft: 8}}>
              <SvgIcon preset={'primary'} icon={'times-circle-dark'}/>
            </View>
          </View>
        </TouchableOpacity>
      </View>

    }

    const renderRightIcon = (): any => {
      const result = {rightIcon: 'ellipsis-h', rightIconTheme: 'solid', rightIconPreset: 'smallBrand'}
      return result
    }

    const onRightPress = () => {
      setVisibleMainModal(true)
    }

    return (
      <>
        <GradientBackground colors={[color.palette.gradientStart, color.palette.gradientStop]}/>
        <SafeAreaView style={styles.container}>
          <View style={styles.mainContainer}>
            <Header
              titlePreset={"headerTitle"} headerText={"Messages"} preset={"primary"}
              {...renderRightIcon()} onRightPress={onRightPress}
            />
            <View style={{
              flexDirection: "row", marginHorizontal: 20,
              marginTop: 8, marginBottom: !!app.filterStore.userMessageFilterId ? 20 : 0,
              alignItems: 'flex-end', justifyContent: 'flex-start',
            }}>
              {Object.values(UserMessageScreenTags).filter(item => app.filterStore.userMessageFilterId === item.key)
                .map(renderFilterItem)}
            </View>
            {renderTabContent()}
          </View>
          <BackdropModal preset={'zeroPadDesktop'} visible={visibleModal}
                         onClose={() => setVisibleModal(!visibleModal)}>
            <View style={{paddingTop: 20, paddingBottom: 40}}>
              <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <Text preset={"h1"} text={'Filters'} color={color.palette.blue}/>
                <SvgIcon onPress={() => setVisibleModal(!visibleModal)} icon={'times'} preset={'normal'}/>
              </View>
              <View style={{marginTop: 65}}>
                {Object.values(UserMessageScreenTags).map((item) => (
                  <TouchableOpacity
                    key={item?.key}
                    onPress={() => handleSelectKey(item.key)}
                    style={{marginBottom: 26, alignItems: 'center'}}>
                    <Text preset={"h1"} text={item.selectLabel} color={color.palette.blue}/>
                  </TouchableOpacity>
                ))}
              </View>
            </View>
          </BackdropModal>
          <BottomModal visible={visibleMainModal} onClose={() => setVisibleMainModal(false)}>
            <View>
              <Button
                onPress={handleFilterPress}
                style={{marginBottom: 10}}
                disabled={false}
                preset={'brandOutLine'}
                text={'Filter Messages'}
              />
              <Button
                onPress={() => setVisibleMainModal(false)}
                style={{marginBottom: 0}}
                disabled={false}
                preset={'primaryOutLine'}
                text={'Cancel'}
              />
            </View>
          </BottomModal>
        </SafeAreaView>
      </>
    )
  },
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainerStyle: {
    marginVertical: 20,
    paddingHorizontal: 10,
  },
  mainContainer: {
    flex: 1,
  },
  statusBar: {},
});
