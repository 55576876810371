import React, {FC} from 'react';
import {View} from 'react-native';
import {getCountryCallingCode} from 'react-phone-number-input'
import PhoneInput, {CountryData, PhoneInputProps} from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './mobile-input.css'
// import PhoneInput, {PhoneInputProps} from 'react-native-phone-number-input';
import {color} from "../../theme";
import {isAndroid} from "../../utils/platform";

const presets = {
  default: {
    backgroundColor: color.palette.white,
  },
  brand: {
  },
}

const BASE_WRAP_INPUT = {
  zIndex: 99,
}

const wrapInputPresets = {
  default: {
    ...BASE_WRAP_INPUT,
    borderWidth: 0, borderRadius: 8, marginTop: 5,
    marginHorizontal: isAndroid ? 4 : 0,
    backgroundColor: color.palette.white,
    shadowColor: isAndroid ? color.palette.black : color.palette.black,
    shadowOffset: {width: 0, height: isAndroid ? 8 : 6},
    shadowOpacity: isAndroid ? 0.01 : 0.1,
    shadowRadius: 3,
    elevation: 8,
  },
  brand: {
    ...BASE_WRAP_INPUT,
    borderRadius: 8,
    borderWidth: 1, borderColor: color.palette.greyMedH,

    shadowColor: color.palette.transparent,
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0,
    shadowRadius: 0,
    elevation: 0,
  },
  contact:{
    height: 55
  }
}

interface MobileInputProps extends PhoneInputProps {
  setPhoneNumber?: (data: any) => void
  defaultValue?: any
  phoneRef?: any
  placeholder?: any
  codeTextStyle?: any
  defaultCode?: any
  textInputProps?: any
  preset?: keyof typeof presets
}

const MobileInput: FC<MobileInputProps> = (props) => {
  const {
    setPhoneNumber,
    defaultCode,
    defaultValue,
    phoneRef,
    preset,
    ...rest
  } = props;
  const _containerStyle = {
    width: '100%',
    borderWidth: 1.5, borderColor: '#BBC2DC1A', borderRadius: 8,
    ...(presets[preset] || presets.default),
  }
  const _wrapInputStyle = wrapInputPresets[preset] || wrapInputPresets.default

  return (
    <View style={_wrapInputStyle}>
      <PhoneInput
        // ref={phoneRef}
        // layout='second'
        onChange={(value, country: CountryData, e, formattedValue) => {
          console.log(value, country, e, formattedValue)
          setPhoneNumber({
            country: String(country?.countryCode || '').toUpperCase(),
            value: value?.substring(country?.dialCode?.length)
          });
        }}
        {...(!defaultValue ? {country: defaultCode?.toLowerCase()} : null)}
        value={'+' + (defaultCode ? getCountryCallingCode(defaultCode) : '') + defaultValue}
        containerStyle={_containerStyle}
        inputStyle={{
          ..._containerStyle,
          paddingTop: isAndroid ? 10 : 16, paddingBottom: isAndroid ? 10 : 16, height: 'auto', lineHeight: 'normal'
        }}
        buttonStyle={{
          borderLeftWidth: 1.5, borderTopWidth: 1.5, borderBottomWidth: 1.5, borderColor: '#BBC2DC1A',
          borderBottomLeftRadius: 8, borderTopLeftRadius: 8, backgroundColor: color.palette.white
        }}
        {...rest}
      />
    </View>
  );
}

export default MobileInput
