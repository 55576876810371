import {ParamListBase} from "@react-navigation/native"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {ViewStyle, TouchableOpacityProps} from "react-native"
import {color} from "../../theme"
import {TextPresets} from "../text/text.presets"

const BASE: ViewStyle = {
  backgroundColor: color.palette.white,
  padding: 16,
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  borderColor: color.palette.greyMed,
  borderRadius: 8,
  marginBottom: 6,

  marginHorizontal: 20,
  shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 1,
  },
  shadowOpacity: 0.22,
  shadowRadius: 2.22,
  elevation: 3,
}

export const presets = {
  default: BASE,
  noneBorder: {...BASE, borderWidth: 0,}
}

export type CardInfoPresets = keyof typeof presets

export interface CardInfoProps extends TouchableOpacityProps {
  onPress?: () => void,
  leftIcon?: () => React.ReactNode
  preset?: CardInfoPresets,
  title?: string,
  subTitle?: string,
  titlePreset?: TextPresets
  subTitlePreset?: TextPresets
  rightTextPreset?: TextPresets
  rightIcon?: () => React.ReactNode
  rightText?: string
  navigation?: NativeStackNavigationProp<ParamListBase>
}
