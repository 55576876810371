import React, { FC, useEffect, useState } from "react"
import {
  SafeAreaView,
  ScrollView,
  TouchableOpacity,
  View,
} from "react-native"
import { Button, GradientBackground, Header, Text } from "../../../../components"
import styles from "../styles"
import { SelectTextField } from "../../../../components/text-field/select-text-field"
import { color } from "../../../../theme"
import { SvgIcon } from "../../../../components/icon/svg-icon"
import moment from "moment"
import { MainAppStore } from "../../../../models/app/main-app-store"
import { ParamListBase, RouteProp } from "@react-navigation/native"
import { useStores } from "../../../../models"
import { IKvItemModel } from "../../../../models/app/kv-item"
import { LoadingModal } from "../../../../components/backdrop-modal/loading-modal"
import { PureBottomModal } from "../../../../components/backdrop-modal/pure-bottom-modal"
import { observer } from "mobx-react-lite"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { NavigatorParamList } from "../../../../navigators"
import { KvSkillPath } from "../../../../models/app/advanced-skill"
import { AlertModalStore } from "../../../../models/app/alert-modal-store"
import { DatePickerField } from "../../../../components/picker-select/date-picker";
import { TrackEvents, trackWithUserAccount } from "../../../../services/track";

export interface CreateCardDetailScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'createCardDetailWallet'>
}


const CreateCardDetailWalletScreen: FC<CreateCardDetailScreenProps> = observer(
  ({navigation, route}) => {
    const {app} = useStores();
    const {fontId, backId} = route.params;
    const [selectedItem, setSelectedItem] = useState<number>();
    const [isCheckExpiry, setCheckExpire] = useState(false);
    const [dob, setDob] = useState(null);
    const [datePickerVisible, setDatePickerVisible] = useState(false)
    const [stateList, setStateList] = useState<Array<IKvItemModel>>([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
      setLoading(true);
      setSelectedItem(null)
      setCheckExpire(false)
      getListCategories();
      MainAppStore.setUploadSelectedDescription({} as any)
      trackWithUserAccount(TrackEvents.WalletSetup, app?.userAccount,)
    }, []);

    const getListCategories = async () => {
      const stateRes = await app.environment.api.getKvItemList('skill-categories')
      if (stateRes.kind === 'ok') {
        setStateList(stateRes.items)
      }
      setLoading(false);
    }

    const handleSetDate = (date: Date) => {
      setDob(date)
      setDatePickerVisible(false)
      setCheckExpire(false)
    }

    const noExpired = () => {
      if (!isCheckExpiry) {
        setDob(null)
      }
      setCheckExpire(e => !e);
    }


    const handleSaveCardDetail = async () => {
      const skillCategory = MainAppStore.selectTypeSection.id
      const description = MainAppStore.uploadSelectedDescription.name
      if (!skillCategory || !description) {
        AlertModalStore.alert('Error', 'Please enter require fields.')
        return
      }
      let skillId = MainAppStore.uploadSelectedDescription.id
      setLoading(true);
      if (!skillId) {
        const res = await app.environment.api.editUserSkill({
          ...(skillCategory ? {skillCategory} : null),
          ...(description ? {description} : null),
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        skillId = res.item.id
      }
      const expiryDate = isCheckExpiry ? null : dob
      try {
        const workerSkillsRes = await app.environment.api.generalApiCreate(KvSkillPath.workerSkills, {
          skill: skillId,
          isCard: true,
          ...(fontId ? {frontImage: fontId} : null),
          ...(backId ? {backImage: backId} : null),
          ...(expiryDate ? {expiryDate: new Date(expiryDate).toISOString()} : null),
        })
        if (workerSkillsRes.kind !== 'ok') {
          AlertModalStore.alert('Error', workerSkillsRes.message)
          return
        }
        // await app.environment.api.updateWorkerProfileData(app?.userAccount?.workerProfile?.id, {
        //   skills: [...app?.userAccount?.workerProfile?.skills?.map(it => it.id)],
        // })
      } catch (e) {
        console.log('Error create card wallet', e)
      } finally {
        goHome();
        setLoading(false);
      }
    }

    const handleSelectDescription = () => {
      if (selectedItem) {
        navigation.navigate("workerDescriptionSkillScreen", {skillCategory: MainAppStore.selectTypeSection.id})
      }
    }

    const handleSelectedType = (item) => {
      MainAppStore.setSelectTypeSection(item)
      setSelectedItem(selectedItem === item.id ? null : item.id)
      MainAppStore.setUploadSelectedDescription({} as any)
    }

    const renderSelectCardType = () => {
      return (
        <View style={{marginTop: 10}}>
          {stateList.length > 0 && stateList.map((item) => {
            const checked = selectedItem === item?.id
            return (
              <SelectTextField
                key={`${item.id}`} style={{marginLeft: 10, marginRight: 20}}
                value={item.name} onPress={() => handleSelectedType(item)}
                rightIcon={checked ? 'check-circle' : 'circle'}
                rightIconTheme={checked ? 'solid' : 'regular'}
                rightIconPreset={checked ? 'primary' : 'default'}
                preset={checked ? 'itemSelected' : 'transparent'}
              />
            )
          })}
        </View>
      )
    }

    const renderExpireDate = () => {
      const dateExpired = dob ? moment(dob).format('DD/MM/YYYY') : 'DD/MM/YYYY'
      return (
        <View style={styles.expireDate}>
          <View style={{zIndex: 100}}>
            <DatePickerField
              visible={datePickerVisible}
              onChange={handleSetDate}
              value={dob} maxDate={new Date()}
              customInput={
                <TouchableOpacity style={[styles.borderLine, {width: 165}]} onPress={() => setDatePickerVisible(true)}>
                  <Text preset="placeholder" style={dob && {color: color.palette.black00}}>
                    {dateExpired}
                  </Text>
                </TouchableOpacity>
              }
            />
          </View>
          <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Text>
              No Expiry
            </Text>
            <View style={{marginLeft: 16, justifyContent: 'center'}}>
              <SvgIcon
                icon={isCheckExpiry ? 'check-circle' : 'circle'}
                preset={isCheckExpiry ? 'primary' : 'default'}
                theme={isCheckExpiry ? 'solid' : 'regular'}
                onPress={noExpired}/>
            </View>
          </View>
        </View>
      )
    }

    const onClose = () => {
      MainAppStore.setSelectTypeSection({} as any)
      MainAppStore.setUploadSelectedDescription({} as any)
      navigation.goBack()
    }

    const goHome = () => {
      MainAppStore.setSelectTypeSection({} as any)
      MainAppStore.setUploadSelectedDescription({} as any)
      navigation.popToTop()
    }

    const disableBtnDone = ((!isCheckExpiry && !dob) || !MainAppStore.selectTypeSection.id || !MainAppStore.uploadSelectedDescription?.id)
    return (
      <PureBottomModal onClose={onClose}>
        <SafeAreaView style={{flex: 1}}>
          <GradientBackground
            colors={[color.palette.gradientStart, color.palette.gradientStop]}
          />
          <Header
            preset={'modalHeader'}
            headerText={`Card Details`}
            titlePreset={'headerTitle'}
            rightIcon={'times'} rightIconPreset={'smallBlue'} onRightPress={onClose}
          />
          <View style={{flex: 1, paddingHorizontal: 20}}>
            <ScrollView style={{flex: 1}} showsVerticalScrollIndicator={false}>
              <Text preset="textName" style={styles.titleModal}>
                Select the Card Type *
              </Text>
              {renderSelectCardType()}
              <View style={{
                marginTop: 24, marginBottom: 24, flexDirection: 'row',
              }}>
                <View style={{flex: 1}}>
                  <SelectTextField
                    onPress={handleSelectDescription}
                    preset={"brand"}
                    value={MainAppStore.uploadSelectedDescription?.id ? MainAppStore.uploadSelectedDescription?.name : null}
                    placeholder={'Enter Description'}
                    label={'Description *'} labelPreset={"formLabel"}
                  />
                </View>
                <View style={{justifyContent: 'center', marginTop: 16}}>
                  <TouchableOpacity
                    onPress={handleSelectDescription}
                    style={{
                      justifyContent: 'center', alignItems: 'center',
                      marginLeft: 10, borderRadius: 36, width: 36, height: 36,
                      borderWidth: 1, borderColor: !selectedItem ? color.palette.greyMedH : color.palette.greyLink
                    }}>
                    <SvgIcon icon={'plus'} preset={'disable'}
                             iconStyle={{fill: !selectedItem ? color.palette.greyMedH : color.palette.greyLink}}/>
                  </TouchableOpacity>
                </View>
              </View>
              <Text preset="textName" style={styles.titleModal}>
                Expiry Date *
              </Text>
              {renderExpireDate()}
              <View style={{marginTop: 20, marginBottom: 100}}>
                <Button
                  disabled={loading || disableBtnDone}
                  preset={disableBtnDone ? 'disabled' : 'brand'}
                  text="Save Details"
                  onPress={handleSaveCardDetail}/>
              </View>
            </ScrollView>
          </View>
          <LoadingModal visible={loading}/>
        </SafeAreaView>
      </PureBottomModal>
    )
  });

export default CreateCardDetailWalletScreen;
