import React, {FC, useCallback, useState} from "react"
import {View, SafeAreaView, ScrollView, RefreshControl} from "react-native"
import {observer} from "mobx-react-lite"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Header, GradientBackground, TextField, Button, Text} from "../../../components"
import {color} from "../../../theme"
import {IKvItemModel, TypeUserModel} from "../../../models/app/kv-item"
import {useStores} from "../../../models";
import {searchIgnoreCase} from "../../../utils/utils";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import {isAndroid} from "../../../utils/platform";
import {debounce} from "../../../utils/debounce";
import {constants} from "../../../utils/constants";
import {ParamListBase, useFocusEffect} from "@react-navigation/native";
import {CONTACT_STATUS} from "../../../models/app/contact";
import UserCard from "../../../components/card/user-card"
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";

export interface JobRoleAddContactScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
}

// DEPRECATED
export const JobRoleAddContactScreen: FC<JobRoleAddContactScreenProps> = observer(
  ({navigation}) => {
    const {app} = useStores();
    const isManager = app?.user?.role === constants.roles.MANAGER
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState("")
    const [data, setData] = useState<Array<TypeUserModel>>([]);
    const [customIds, setCustomIds] = useState<Array<number>>([]);
    const [selectedItem, setSelectedItem] = useState<IKvItemModel>();

    const loadData = async () => {
      setLoading(true)
      try {
        const res = await app.environment.api.getContacts({
          'filters[businessProfile][id][$eq]': isManager
            ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          'businessProfileId': isManager
            ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        setData(res?.items?.map((item) => {
          const fullName = [item?.user?.firstName, item?.user?.lastName]?.join(' ')?.trim()
          return ({
            id: item?.id, name: fullName || item?.user?.email,
            checked: app?.selectedContact?.id === item?.id,
            avatar: item?.user?.avatar?.url || ''
          })
        }))
        setCustomIds(
          res?.items?.filter(it => CONTACT_STATUS.pending.key === it.status)?.map(it => it.id)
        )
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    useFocusEffect(useCallback(() => {
      setSelectedItem(app?.selectedContact)
      loadData()
    }, []))

    const toggleSelectItem = (id) => {
      let _item = null
      const newData = data.map((item) => {
        if (item.id === id) _item = item
        return ({
          ...item, checked: item.id === id ? !item.checked : false
        })
      })
      setData(newData)
      if (selectedItem?.id !== id) setSelectedItem(_item)
      else setSelectedItem(null)
    }

    const onDone = () => {
      app.setSelectedContact(selectedItem)
      navigation.goBack()
    }

    const delayLoadData = useCallback(debounce(loadData, 600), []);
    const handleChangeSearch = (text: string) => {
      // @ts-ignore
      delayLoadData(text)
      setSearch(text)
    }

    const _systemData = data.filter(item => !customIds.includes(item.id) && searchIgnoreCase(item?.name, search))

    return (
      <>
        <PureBottomModal onClose={() => navigation.goBack()}>
          <SafeAreaView style={{flex: 1}}>
            <GradientBackground
              colors={[color.palette.gradientStart, color.palette.gradientStop]}
            />
            <Header
              preset={"modalHeader"}
              titlePreset={"headerTitle"}
              headerText={"Add Business Manager"}
              rightIcon={"times"}
              rightIconPreset={"smallBlue"}
              onRightPress={() => navigation.goBack()}
            />
            <View style={{paddingHorizontal: 20, flex: 1}}>
              <View style={{flex: 1}}>
                <View style={{}}>
                  <View style={{marginTop: 0, marginBottom: 18}}>
                    <TextField
                      placeholder="Search contacts"
                      value={search} onChangeText={handleChangeSearch}
                      labelPreset={"default"}
                      rightIcon={"times-circle-fill"}
                      rightIconPreset={"default"}
                      rightIconPress={() => handleChangeSearch('')}
                    />
                  </View>
                </View>
                {data ? (
                  <View style={{marginTop: 24, flex: 1}}>
                    <ScrollView
                      refreshControl={<RefreshControl refreshing={loading} onRefresh={loadData}/>}
                      showsVerticalScrollIndicator={false} style={{flex: 1}}>
                      <View>
                        {_systemData.length > 0 ? (
                          <View>
                            {_systemData.map((item, index) => (
                              <UserCard
                                key={`${index}-${item.id}`}
                                onClick={() => toggleSelectItem(item.id)}
                                isCheck={item.checked}
                                name={item.name}
                                avatar={item.avatar}
                              />
                            ))}
                          </View>
                        ) : (
                          <View>
                            {!loading &&
                            <Text preset={"body"} style={{color: color.palette.primary, fontStyle: 'italic'}}
                                  text="No Search Results Found"/>}
                          </View>)}
                      </View>
                    </ScrollView>
                  </View>
                ) : null}
              </View>
              <View style={{paddingTop: 10, marginBottom: isAndroid ? 30 : 15}}>
                <Button
                  preset={'brand'}
                  onPress={onDone}
                  text={'Save'}/>
              </View>
            </View>
          </SafeAreaView>
        </PureBottomModal>
        <AlertModal/>
      </>
    )
  },
)
