import React, {FC, useCallback, useState} from "react"
import {
  View,
  SafeAreaView, ScrollView, RefreshControl,
} from "react-native"
import {observer} from "mobx-react-lite"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Header, GradientBackground, Text, TextField, Button} from "../../../components"
import {color} from "../../../theme"
import {SelectTextField} from "../../../components/text-field/select-text-field";
import {IKvItemModel, KvItemPath} from "../../../models/app/kv-item";
import {useStores} from "../../../models";
import {JobTags} from "../../../models/app/job-advert";
import {searchCompare, searchIgnoreCase} from "../../../utils/utils";
import {NavigatorParamList} from "../../../navigators";
import {IWorkerProfileModel} from "../../../models/app/worker-profile";
import {useFocusEffect} from "@react-navigation/native";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import {isAndroid} from "../../../utils/platform";
import {debounce} from "../../../utils/debounce";
import {getUserQualificationStr} from "../../../models/app/advanced-skill";
import {PickerSelectField} from "../../../components/picker-select/picker-select";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";

export interface WorkerSelectQualificationScreenProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
}

export const WorkerSelectQualificationScreen: FC<WorkerSelectQualificationScreenProps> = observer(
  ({navigation}) => {
    const {app} = useStores();
    const workerProfile: IWorkerProfileModel = app?.userAccount?.workerProfile
    const workerProfileQualifications = workerProfile?.qualifications?.map(it => it?.id) || []
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState("")
    const [data, setData] = useState<Array<IKvItemModel>>([]);
    const [customIds, setCustomIds] = useState<Array<number>>([]);
    const [qualificationEducationLevel, setEducationLevel] = useState(0)
    const [educationOpen, setEducationOpen] = useState(false)
    const [listEducations, setListEducations] = useState<Array<IKvItemModel>>([]);

    const loadData = async (text?: string, qualificationEducationLevel?: number) => {
      if (!text || text?.length < 3) return
      try {
        text = text?.trim();
        const userRes = await app.environment.api.getUserQualification({
          // ...(qualificationEducationLevel ? {'filters[qualificationEducationLevel][id]': qualificationEducationLevel} : null),
          ...(qualificationEducationLevel ? {'filters[qualificationEducationLevel][id]': qualificationEducationLevel} : null),
          ...(text ? {'filters[courseName][$containsi]': text} : null),
          'filters[user][id]': app?.userAccount?.id,
        })
        if (userRes.kind !== 'ok') return
        setCustomIds(userRes?.items?.map(it => it.id))
        const sysRes = await app.environment.api.getUserQualification({
          ...(qualificationEducationLevel ? {'filters[qualificationEducationLevel][id]': qualificationEducationLevel} : null),
          ...(text ? {'filters[courseName][$containsi]': text} : null),
        })
        if (sysRes.kind === 'ok') {
          setData([...userRes?.items, ...sysRes?.items].map(it => ({id: it.id, name: getUserQualificationStr(it)})))
        }
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    const initData = async () => {
      await app.getMe()
      const categoryRes = await app.environment.api.getKvItemList('qualification-education-levels')
      if (categoryRes.kind !== 'ok') {
        AlertModalStore.alert('Error', categoryRes.message)
        return
      }
      setListEducations(categoryRes.items)
    }

    useFocusEffect(useCallback(() => {
      initData()
    }, []))

    const toggleSelectItem = (id: number) => {
      const _item = app.userAccount.workerProfile.qualifications?.find(it => it?.id === id)
      if (_item) {
        app.toggleWorkerSkillQualificationsTag(data?.find(it => it.id === id))
      } else navigation.navigate('workerAddQualificationScreen', {originId: id})
    }

    const onDone = () => {
      navigation.goBack()
    }

    const handleAddItem = async (courseName?: string) => {
      if (!courseName || loading) return
      setLoading(true)
      try {
        const _qualificationEducationLevel = listEducations?.find(it => it.id === qualificationEducationLevel)
        if (!_qualificationEducationLevel) return
        const res = await app.environment.api.editKvItemBusiness(KvItemPath.qualification, {
          courseName,
          qualificationEducationLevel,
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        navigation.navigate('workerAddQualificationScreen', {originId: res?.item?.id})
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    const delayLoadData = useCallback(debounce(loadData, 600), []);
    const handleChangeSearch = (text: string) => {
      setSearch(text)
      setLoading(true)
      // @ts-ignore
      delayLoadData(text, qualificationEducationLevel)
    }
    const loadDataFromState = () => {
      loadData(search, qualificationEducationLevel)
    }

    const handleAddOther = () => {
      handleAddItem(search)
    }

    const renderItem = (item: IKvItemModel, index: number) => {
      const checked = app.userAccount.workerProfile.qualifications?.find(it => it.id === item.id)
      const isExists = !!workerProfileQualifications?.includes(item.id)
      return (
        <SelectTextField
          key={`${index}-${item.id}`}
          value={item.name} onPress={() => toggleSelectItem(item.id)}
          style={{marginBottom: 10}}
          rightIcon={checked ? 'check-square' : isExists ? 'square-regular' : 'square-plus'}
          rightIconTheme={checked ? 'solid' : isExists ? 'regular' : 'solid'}
          rightIconPreset={checked ? 'primary' : isExists ? 'default' : 'primary'}
          preset={checked ? 'itemSelected' : 'transparent'}
        />
      )
    }

    const _systemData = data.filter(item => !customIds.includes(item.id) && searchIgnoreCase(item?.name, search))
    const _customData = data.filter(item => customIds.includes(item.id) && searchIgnoreCase(item?.name, search))
    const showBtnAdd = !loading && search?.length >= 3 && data.find(item => searchCompare(item?.name, search)) === undefined;
    const showBtnDone = app.userAccount.workerProfile.qualifications?.length > 0
    return (
      <>
        <PureBottomModal onClose={() => navigation.goBack()}>
          <SafeAreaView style={{flex: 1}}>
            <GradientBackground
              colors={[color.palette.gradientStart, color.palette.gradientStop]}
            />
            <Header
              preset={"modalHeader"}
              titlePreset={"headerTitle"}
              headerText={JobTags.qualifications.text}
              rightIcon={"times"}
              rightIconPreset={"smallBlue"}
              onRightPress={() => navigation.goBack()}
            />
            <View style={{paddingHorizontal: 20, flex: 1}}>
              <View style={{flex: 1}}>
                <View style={{zIndex: 9}}>
                  <View style={{marginTop: 0, marginBottom: 8}}>
                    <View style={{display: 'none'}}>
                      <TextField
                        value={search} onChangeText={handleChangeSearch}
                        labelPreset={"default"}
                        rightIcon={"times-circle-fill"}
                        rightIconPreset={"default"}
                        rightIconPress={() => handleChangeSearch('')}
                      />
                    </View>
                    <View style={{zIndex: 9, marginBottom: 20}}>
                      <PickerSelectField
                        onOpen={() => setEducationOpen(true)}
                        onClose={() => setEducationOpen(false)}
                        onValueChange={setEducationLevel}
                        items={listEducations?.map(item => ({
                          key: item.id, value: item.id,
                          label: item?.name
                        }))}
                        preset={"default"}
                        label={"Education Level"} labelPreset={"h4"}
                        placeholder="Select"
                        rightIcon={educationOpen ? 'chevron-down' : 'chevron-right'}
                      />
                    </View>
                    <TextField
                      value={search} onChangeText={handleChangeSearch}
                      preset={qualificationEducationLevel ? 'primary' : 'primaryDisabled'}
                      editable={!!qualificationEducationLevel}
                      labelPreset={"h4"} label={'Course Name'}/>
                  </View>
                  {showBtnAdd && (
                    <Button preset={"brandDisabled"} onPress={handleAddOther}>
                      <Text preset={"mediumBoldBlue"}>Add other</Text>
                    </Button>
                  )}
                </View>
                {data.length > 0 ? (
                  <View style={{marginTop: 24, flex: 1}}>
                    <ScrollView
                      refreshControl={<RefreshControl refreshing={loading} onRefresh={loadDataFromState}/>}
                      showsVerticalScrollIndicator={false} style={{flex: 1}}>
                      <View>
                        {_customData && _customData.length > 0 ? (
                          <View>
                            <View style={{marginHorizontal: 16}}>
                              <Text preset={"mediumBlue"}>Other</Text>
                            </View>
                            <View style={{height: 10}}/>
                            {_customData.map(renderItem)}
                          </View>
                        ) : null}
                        {data ? (
                          <View>
                            <View style={{marginHorizontal: 16, marginBottom: 10}}>
                              <Text preset={"mediumBlue"}>Results</Text>
                            </View>
                            {_systemData.map(renderItem)}
                          </View>
                        ) : null}
                      </View>
                    </ScrollView>
                  </View>
                ) : null}
              </View>
              <View style={{display: showBtnDone ? 'flex' : 'none', paddingTop: 10, marginBottom: isAndroid ? 10 : 5}}>
                <Button preset={"brand"} onPress={onDone} text={'Done'}/>
              </View>
            </View>
          </SafeAreaView>
        </PureBottomModal>
        <AlertModal/>
      </>
    )
  },
)
