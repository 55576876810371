import React, {FC, useEffect, useState} from "react"
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {ParamListBase, RouteProp} from "@react-navigation/native"
import {NavigatorParamList} from "../../../navigators"
import {ActivityIndicator, ScrollView, StyleSheet, TouchableOpacity, View} from "react-native"
import {color} from "../../../theme"
import {BulletItem, Button, Header, Text} from "../../../components"
import {observer} from "mobx-react-lite"
import {AlertModalStore} from "../../../models/app/alert-modal-store"
import {IUserAccountModel} from "../../../models/app/user-account"
import {useStores} from "../../../models"
import {AvatarImage} from "../../../components/avatar-image/avatar-image"
import AvatarDefault from "../../../components/avatar-image/AvatarDefault"
import {SvgIcon} from "../../../components/icon/svg-icon"
import {FlatCard} from "../../../components/card/flat-card"
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";

interface JobWorkerProfileProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'jobWorkerProfile'>
}

export const JobWorkerProfile: FC<JobWorkerProfileProps> = observer(
  ({navigation, route}) => {
    const id = route?.params?.id;
    const jobAdId = route?.params?.jobAdId;
    const {app} = useStores()
    const [refreshing, setRefreshing] = useState(false)
    const [userAccount, setUserAccount] = useState<IUserAccountModel>()
    const loadData = async () => {
      try {
        const res = await app.environment.api.getMe(id)
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        setUserAccount(res.userAccount)
        // if (userAccount?.countryCode) {
        //   const callingCode = await getCallingCode(userAccount?.countryCode as CountryCode)
        //   setCallingCode(callingCode.toString())
        // }
      } catch (e) {
        console.log(e)
      }
    }
    useEffect(() => {
      loadData()
    }, [])

    const sendInvite = async () => {
      if (refreshing) return
      setRefreshing(true)
      try {
        const res = await app.environment.api.createJobInvite({
          worker: id,
          jobAd: jobAdId
        })
        if (res && res.kind === 'ok') {
          navigation.goBack()
        }
      } catch (e) {
        console.log(e)
      } finally {
        setRefreshing(false)
      }
    }

    return (
      <>
        <PureBottomModal onClose={() => navigation.goBack()}>
          <Header
            preset={"modalHeader"}
            titlePreset={"headerTitle"}
            header={() => (
              <View style={{paddingTop: 4}}>
                <Text preset={'h2'}
                      color={color.palette.primary}>{userAccount?.workerProfile?.industry?.name || ''}</Text>
              </View>
            )}
            rightIcon={"times"}
            rightIconPreset={"smallBlue"}
            onRightPress={() => navigation.goBack()}
          />
          <View style={styles.content}>
            <View style={styles.avatar}>
              {userAccount?.avatar?.url ?
                <AvatarImage
                  preset={"medium"}
                  source={{uri: userAccount?.avatar?.url}}/>
                :
                <AvatarDefault py={0} px={0} name={userAccount?.firstName} preset={"medium"}/>
              }
              <Text preset={'suggestBold'} style={{
                marginTop: 5,
                marginLeft: 15
              }}>{`${userAccount?.firstName || ''} ${userAccount?.lastName || ''}`}</Text>
              <View style={{
                justifyContent: "center", position: 'absolute', top: 5,
                right: 0
              }}>
                <TouchableOpacity
                  activeOpacity={1}
                  style={styles.message}
                  // onPress={() => {
                  //   navigation.goBack()
                  //   setTimeout(() => navigation.navigate("userApplicantMessageScreen", {
                  //     toUserId: userAccount?.workerProfile?.user?.id,
                  //     applicationId: jobAdId,
                  //   }), 100)
                  // }}
                  onPress={() => {
                  }}
                >
                  <SvgIcon preset={'light'} icon="chatbubble-message"/>
                </TouchableOpacity>
              </View>
            </View>
            <ScrollView
              showsVerticalScrollIndicator={false}
              style={styles.mainContainer}
              contentContainerStyle={{paddingBottom: 250}}>
              <FlatCard noBorder={true} title={'Preferences'} titlePreset={'mediumBoldBlue'}>
                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                  <View style={{flexDirection: 'row', paddingHorizontal: 4, flex: 6}}>
                    <SvgIcon icon={'map-pin'} preset={'small'}/>
                    <View>
                      <Text preset={'body2'} style={{fontWeight: 'bold'}}>Pref. location</Text>
                      {userAccount?.workerProfile?.preferredLocations?.map(item => (
                        <View style={{marginTop: 4}}>
                          <Text preset={'body1'}>{item.name}</Text>
                        </View>
                      ))}
                    </View>
                  </View>
                  <View style={{flexDirection: 'row', paddingHorizontal: 4, flex: 4}}>
                    <SvgIcon icon={'map-pin'} preset={'small'}/>
                    <View>
                      <Text preset={'body2'} style={{fontWeight: 'bold'}}>Max Travel</Text>
                      <Text preset={'body1'}>{userAccount?.workerProfile?.travelDistance}</Text>
                    </View>
                  </View>
                </View>
              </FlatCard>
              <FlatCard noBorder={true} title={'Experience'} titlePreset={'mediumBoldBlue'}>
                {userAccount?.workerProfile?.experiences.map((item, index) => (
                  <BulletItem key={index + '' + item?.id} text={item.name} preset={'body1'}/>
                ))}
              </FlatCard>
              <FlatCard noBorder={true} title={'Skills'} titlePreset={'mediumBoldBlue'}>
                {userAccount?.workerProfile?.skills.map((item, index) => (
                  <BulletItem key={index + '' + item?.id} text={item.name} preset={'body1'}/>
                ))}
              </FlatCard>
              <FlatCard noBorder={true} title={'Qualifications'} titlePreset={'mediumBoldBlue'}>
                {userAccount?.workerProfile?.qualifications.map((item, index) => (
                  <BulletItem key={index + '' + item?.id} text={item.name} preset={'body1'}/>
                ))}
              </FlatCard>
              <FlatCard noBorder={true} title={'Biography'} titlePreset={'mediumBoldBlue'}>
                <BulletItem text={userAccount?.workerProfile?.bio} preset={'body1'}/>
              </FlatCard>
              <FlatCard noBorder={true} title={'Industries'} titlePreset={'mediumBoldBlue'}>
                {userAccount?.workerProfile?.industries.map((item, index) => (
                  <BulletItem key={index + '' + item?.id} text={item.name} preset={'body1'}/>
                ))}
              </FlatCard>
              <FlatCard noBorder={true} title={'Roles'} titlePreset={'mediumBoldBlue'}>
                {userAccount?.workerProfile?.workerRoles.map((item, index) => (
                  <BulletItem key={index + '' + item?.id} text={item.name} preset={'body1'}/>
                ))}
              </FlatCard>
            </ScrollView>
          </View>
          <View style={{
            position: 'absolute',
            bottom: 0,
            paddingBottom: 30,
            paddingTop: 10,
            width: '100%',
            backgroundColor: 'white'
          }}>
            <View style={{width: 768 - 100, marginHorizontal: 50}}>
              {!refreshing ?
                <Button
                  preset="brand"
                  text="Invite to Job"
                  onPress={sendInvite}
                />
                :
                <Button
                  preset="brand"
                >
                  <ActivityIndicator size="small" color="#fff"/>
                </Button>
              }
            </View>
          </View>

        </PureBottomModal>
        <AlertModal/>
      </>
    )
  });

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 18,
    position: 'relative'
  },
  avatar: {
    flexDirection: 'row',
    position: 'relative'
  },
  mainContainer: {
    paddingTop: 10
  },
  pill: {
    alignItems: 'center',
    backgroundColor: color.palette.blue,
    borderRadius: 20,
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingVertical: 10
  },
  message: {
    backgroundColor: color.palette.orange,
    padding: 15,
    borderRadius: 100,
  }
});
