import React from "react"
import { View, ViewStyle, TextStyle } from "react-native"
import { HeaderProps, presets } from "./header.props"
import { Button } from "../button/button"
import { Text } from "../text/text"
import { translate } from "../../i18n/"
import { SvgIcon } from "../icon/svg-icon"
import { color } from "../../theme"

// static styles
const ROOT: ViewStyle = {
  flexDirection: "row",
  paddingHorizontal: 18,
  alignItems: "center",
  justifyContent: "flex-start",
  backgroundColor: color.palette.blue,
}
const TITLE: TextStyle = { textAlign: "left" }
const TITLE_CONTAINER: ViewStyle = { flex: 1, justifyContent: "center", paddingHorizontal: 10 }

/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export function Header(props: HeaderProps) {
  const {
    onLeftPress,
    onRightPress,
    rightIcon,
    rightIconPreset,
    rightIconTheme,
    leftIcon,
    leftIconPreset,
    leftIconTheme,
    header,
    headerText,
    headerTx,
    style,
    titlePreset,
    titleStyle,
    preset,
  } = props
  const _headerText = headerText || (headerTx && translate(headerTx)) || ""
  const _preset = presets[preset] || presets.default

  const headerStyle = [_preset, style]

  return (
    <View style={[ROOT, headerStyle]}>
      <View style={{ flexDirection: "row", flex: 1 }}>
        {leftIcon ? (
          typeof leftIcon === "string" ? (
            <Button preset={"iconButton"} onPress={onLeftPress}>
              <SvgIcon icon={leftIcon} preset={leftIconPreset} theme={leftIconTheme} />
            </Button>
          ) : (
            leftIcon()
          )
        ) : null}
        <View style={TITLE_CONTAINER}>
          {header ? (
            header()
          ) : (
            <Text style={[TITLE, titleStyle]} preset={titlePreset || "h1"} text={_headerText} />
          )}
        </View>
        {rightIcon ? (
          typeof rightIcon === "string" ? (
            <Button preset={"iconButton"} onPress={onRightPress}>
              <SvgIcon icon={rightIcon} preset={rightIconPreset} theme={rightIconTheme} />
            </Button>
          ) : (
            rightIcon()
          )
        ) : null}
      </View>
    </View>
  )
}
