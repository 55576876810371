import React, {useState} from "react"
import {AutoImage as Image, ImageProps} from "./auto-image";

export function ScaleImage(props: ImageProps) {
  const [imgWidth, setImgWidth] = useState(0);
  const [imageSize, setImageSize] = useState({width: 0, height: 0})

  return (
    <Image
      onLayout={event => setImgWidth(event.nativeEvent.layout?.width)}
      handleImageSize={setImageSize}
      {...props}
      style={[props?.style, (imgWidth && imageSize?.width && imageSize?.height) ? {
        width: imgWidth,
        height: imgWidth / imageSize.width * imageSize.height
      } : null]}
    />
  )
}
