import React, { FC, useCallback, useRef, useState } from "react"
import { View, ScrollView, SafeAreaView, TouchableOpacity } from "react-native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { observer } from "mobx-react-lite"
import {
  VIEW_CONTAIN_BOTTOM,
  SEE_ALL,
  LINE,
} from "./styles"
import { Text, Tag, Button, Header } from "../../../components"
import { color } from "../../../theme"
import { SvgIcon } from "../../../components/icon/svg-icon"
import { BottomContainer } from "../../../components/bottom-container/bottom-container"
import { AvatarImage } from "../../../components/avatar-image/avatar-image"
import type { RouteProp } from "@react-navigation/native";
import { NavigatorParamList } from "../../../navigators";
import { ParamListBase, useFocusEffect } from "@react-navigation/native";
import { useStores } from "../../../models";
import { IJobRoleAdModel } from "../../../models/app/job-role-ad";
import { constants, DEFAULT_LIMIT } from "../../../utils/constants"
import { AlertModalStore } from "../../../models/app/alert-modal-store";
import { AlertModal } from "../../../components/backdrop-modal/alert-modal";
import { PureBottomModal } from "../../../components/backdrop-modal/pure-bottom-modal";
import { parseLocation } from "../../../models/app/location";
import { stringToDateStr } from "../../../utils/utils";
import { LoadingModal } from "../../../components/backdrop-modal/loading-modal";
import AvatarDefault from "../../../components/avatar-image/AvatarDefault"
import { IWorkerApplicationModel } from "../../../models/app/application"

export interface JobsAdProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'jobsAdInvitesDetailScreen'>
}

export const JobsAdInvitesDetailScreen: FC<JobsAdProps> = observer(({navigation, route}) => {
  const id = route.params?.id
  const {app} = useStores()
  const role = app.user.role || constants.roles.GUEST;
  const workerProfile = app?.userAccount?.workerProfile
  const [seeMore, setSeeMore] = useState(false)
  const [jobDetail, setJobDetail] = useState<IJobRoleAdModel>(null)
  const [loading, setLoading] = useState(false)

  const loadData = async () => {
    if (loading) return
    try {
      setLoading(true)
      await app.getMe()
      const res = await app.environment.api.getJobInvitesAdDetail(id)
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message)
        return
      }
      const dataUpdate = {
        ...res.detailInvite,
        locations: res.detailInvite.jobAd?.jobRoles[0]?.locations,
        role: res.detailInvite.jobAd?.jobRoles[0]?.role,
      }
      setJobDetail(dataUpdate)
      await getApplicationID(res.detailInvite.jobAd?.jobRoles[0]?.id)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const refApplicationId = useRef(0);
  const refJobRolesId = useRef(0);
  const getApplicationID = async (idJobRole: number) => {
    refJobRolesId.current = idJobRole;
    const applicationRes = await app.environment.api.getWorkerApplications({
      "filters[workerProfile][id][$eq]": workerProfile?.id,
      "filters[jobRole][id][$eq]": idJobRole,
      "pagination[pageSize]": DEFAULT_LIMIT
    })
    if (applicationRes.kind !== 'ok') {
      AlertModalStore.alert('Error', applicationRes.message)
      return
    }
    const _application: IWorkerApplicationModel = applicationRes?.applications?.length > 0 ? applicationRes?.applications[0] : null
    refApplicationId.current = _application?.id || 0;
  }

  useFocusEffect(useCallback(() => {
    if (id && app?.auth) {
      loadData()
      // trackWithUserAccount(TrackEvents.ViewedJobAd, app?.userAccount)
    }
  }, []))


  const handleMessagePress = async () => {
    navigation.goBack()
    navigation.navigate("userApplicantMessageScreen", {
      toUserId: jobDetail?.jobAd?.owner?.id, applicationId: refApplicationId.current,
    })
  }

  const handleViewBusinessProfile = () => navigation.navigate('businessProfileScreen', {
    businessProfileId: jobDetail?.jobAd?.businessProfile?.id
  })

  const handleSubmit = async (type: string) => {
    if (loading) return
    try {
      setLoading(true)
      if (type === 'accept') {
        const res = await app.environment.api.acceptJobInvite(id)
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        navigation.goBack()
      } else {
        const res = await app.environment.api.rejectJobInvite(id)
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        navigation.goBack()
      }
    } catch (e) {
      console.log('error submit invitation', e)
    } finally {
      setLoading(false)
    }
  }
  if (jobDetail === null) {
    return null;
  }

  const location = jobDetail?.locations?.length > 0 ? parseLocation(jobDetail?.locations[0]) : ''
  const roleTitle = jobDetail?.jobAd?.title || ''
  const roleDescription = jobDetail?.role?.name || ''
  const logo = jobDetail?.jobAd?.businessProfile?.logo?.url
  const paymentType = jobDetail?.jobAd?.paymentTypes?.length > 0 ? jobDetail?.jobAd?.paymentTypes[0]?.name : ''
  const numberOfPosition = jobDetail?.jobAd?.jobRoles[0]?.numberOfPositions
  const amount = jobDetail?.jobAd?.jobRoles[0]?.amount
  const workType = jobDetail?.jobAd?.jobRoles[0]?.workType
  return (
    <>
      <PureBottomModal onClose={() => navigation.goBack()}>
        <SafeAreaView style={{flex: 1}}>
          <Header
            preset={"transparent"}
            header={() => (
              <View style={{flexDirection: 'row', alignItems: "center"}}>
                <View style={{}}>
                  {logo ?
                    <AvatarImage
                      onPress={handleViewBusinessProfile}
                      source={{uri: logo}}
                      preset={"medium"}/> :
                    <AvatarDefault
                      onPress={handleViewBusinessProfile}
                      name={jobDetail?.jobAd?.businessProfile?.name}
                      preset={"medium"}/>}
                </View>
              </View>
            )}
            style={{marginRight: 10}}
            rightIcon={'times'} rightIconPreset={'smallBlue'} onRightPress={() => navigation.goBack()}
          />
          <ScrollView style={{flex: 1}} showsVerticalScrollIndicator={false}>
            <View style={{paddingHorizontal: 20}}>
              <TouchableOpacity style={{marginTop: 12}}>
                <Text text={roleDescription} preset="h1"/>
                <View style={{marginTop: 9}}/>
                <Text text={roleTitle} preset="h2" style={{color: color.palette.blue, fontSize: 20}}/>
              </TouchableOpacity>
              <View style={{marginTop: 24}}>
                <View style={LINE}>
                  <Text preset="h4Dark">Job Ad Description </Text>
                </View>
                <Text preset="body1" style={{marginTop: 8}} numberOfLines={seeMore ? undefined : 3}>
                  {jobDetail?.jobAd?.description}
                </Text>
                <TouchableOpacity style={SEE_ALL} onPress={() => setSeeMore(!seeMore)}>
                  <Text
                    text={seeMore ? "See less" : "See more"} preset="body2"
                    style={{fontWeight: 'bold', color: color.palette.blue}}/>
                  <SvgIcon icon={seeMore ? "chevron-up" : "chevron-down"} preset={"tiny"} style={{marginLeft: 8}}/>
                </TouchableOpacity>
              </View>
              <View style={{marginTop: 24}}>
                <View style={LINE}>
                  <Text preset="h4Dark">Responsibilites</Text>
                </View>
                <Text preset="body1" style={{marginTop: 8}}>
                  {jobDetail?.jobAd?.responsibilities}
                </Text>
              </View>

              <View style={{marginTop: 24}}>
                <View style={LINE}>
                  <Text preset="h4Dark">Job Details</Text>
                </View>
                <View style={{marginTop: 8, flexDirection: "row", flexWrap: 'wrap'}}>
                  <Tag
                    icon="user"
                    children={String(numberOfPosition)}
                    textPreset="body" textStyle={{color: color.palette.dark}}
                    preset="smaller"
                    containerStyle={{marginBottom: 10, marginRight: 4}}
                  />
                  <Tag
                    preset="smaller"
                    icon="calendar-day"
                    children={stringToDateStr(jobDetail?.startDate)}
                    textPreset={'body'} textStyle={{color: color.palette.dark}}
                    containerStyle={{marginBottom: 10, marginRight: 4}}
                  />
                  <Tag
                    preset="iconSmall"
                    icon="pen-field"
                    children={workType}
                    textPreset={'body'} textStyle={{color: color.palette.dark}}
                    containerStyle={{marginBottom: 10, marginRight: 4}}
                  />
                  <Tag
                    icon="money-check-dollar"
                    preset={"iconSmall"}
                    children={`${paymentType} ${amount}`}
                    textPreset="body" textStyle={{color: color.palette.dark}}
                    containerStyle={{marginBottom: 10, marginRight: 4}}
                  />
                  <Tag
                    icon="map-marker-alt"
                    children={location}
                    textPreset="body" textStyle={{color: color.palette.dark}}
                    preset="smaller"
                    containerStyle={{marginBottom: 10, marginRight: 4}}
                  />
                </View>
              </View>

              <View style={{marginTop: 24}}>
                <View style={LINE}>
                  <Text preset="h4Dark">Experience</Text>
                </View>
                <Text
                  preset="body2Grey"
                  style={{color: color.palette.greyHigh, marginTop: 8}}
                >
                  Tick all the boxes that apply to you. This will appear in your application.
                </Text>
              </View>

              <View style={{marginTop: 24}}>
                <View style={LINE}>
                  <Text preset="h4Dark">Skills</Text>
                </View>
                <Text
                  preset="body2Grey"
                  style={{color: color.palette.greyHigh, marginTop: 8}}
                >
                  Tick all the boxes that apply to you. This will appear in your application.
                </Text>
              </View>

              <View style={{marginTop: 24}}>
                <View style={LINE}>
                  <Text preset="h4Dark">Qualification</Text>
                </View>
                <Text
                  preset="body2Grey"
                  style={{color: color.palette.greyHigh, marginTop: 8}}
                >
                  Add all the qualifications that apply to you. This will appear in your application.
                </Text>
              </View>

              <View style={{height: 200}}/>
            </View>
          </ScrollView>

          <BottomContainer backgroundColor={color.palette.white}>
            <View style={VIEW_CONTAIN_BOTTOM}>
              <TouchableOpacity onPress={handleMessagePress} style={{
                marginRight: 20,
                display: role !== constants.roles.WORKER ? 'none' : 'flex',
              }}>
                <SvgIcon icon="chatbubble-message" preset={"smallBlue"}/>
              </TouchableOpacity>
              <View style={{flex: 1}}>
                <Button
                  disabled={loading}
                  onPress={() => handleSubmit('accept')}
                  preset={"brand"}
                  text={"Accept Invitation"}
                />
                <View style={{width: 1, height: 10}}/>
                <Button
                  disabled={loading}
                  onPress={() => handleSubmit('reject')}
                  preset={"brandOutLine"}
                  text={"Reject Invitation"}
                />
              </View>
            </View>
          </BottomContainer>
        </SafeAreaView>
      </PureBottomModal>
      <AlertModal/>
      <LoadingModal visible={loading}/>
    </>
  )
})
