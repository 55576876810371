import React, { FC, useState, useEffect } from 'react';
import { View, FlatList, StyleSheet, SafeAreaView, TouchableOpacity } from 'react-native';

import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../models";
import { constants } from "../../../utils/constants";
import { Button, Header, Text } from "../../../components";
import { PureBottomModal } from "../../../components/backdrop-modal/pure-bottom-modal";
import { SvgIcon } from "../../../components/icon/svg-icon";
import { BottomContainer } from "../../../components/bottom-container/bottom-container";
import { color } from "../../../theme";
import { BackdropModal } from "../../../components/backdrop-modal/backdrop-modal";
import { LoadingModal } from "../../../components/backdrop-modal/loading-modal";
import { AlertModalStore } from "../../../models/app/alert-modal-store";
import { AlertModal } from "../../../components/backdrop-modal/alert-modal";
import { IKvItemCheckModel } from "../../../models/app/kv-item";
import { NavigatorParamList } from "../../../navigators";
import { MainAppStore } from "../../../models/app/main-app-store";

export interface DeleteGroupChatScreenProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
}

export const DeleteGroupChatScreen: FC<DeleteGroupChatScreenProps> = observer(
  ({navigation}) => {
    const {app} = useStores();
    const [loading, setLoading] = useState(false)
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [listItem, setListItem] = useState<Array<IKvItemCheckModel>>([{id: 0, name: 'cc', checked: false}]);
    const [checked, setChecked] = useState(false)

    const handleInitData = () => {
      const newData = MainAppStore?.chatGroupDeleteList.map((item) => {
        return ({id: item.id, name: item.name, checked: false})
      })
      setListItem(newData)
    }

    useEffect(() => {
      handleInitData();
    }, [])

    const onCheckAll = () => {
      setListItem(listItem.map((it) => ({...it, checked: !checked})))
      setChecked(!checked)
    }

    const toggleSelectItem = (item: IKvItemCheckModel) => {
      setListItem(listItem.map((it) => it?.id === item?.id ? {...it, checked: !it.checked} : it))
    }

    const handleRemoveContact = () => {
      setConfirmVisible(true)
    }

    const onRemoveContact = async () => {
      try {
        setConfirmVisible(false)
        setLoading(true)

        const isWorker = app.user.role === constants.roles.WORKER
        const isManager = app?.user?.role === constants.roles.MANAGER
        let res
        if (isWorker) {
          res = await app.environment.api.deleteGroups({
            'businessProfileId': 0,
            ids: listItem?.filter(it => it.checked)?.map(it => it.id).join(',')
          })
        } else {
          res = await app.environment.api.deleteGroups({
            'businessProfileId': isManager ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
            ids: listItem?.filter(it => it.checked)?.map(it => it.id).join(',')
          })
        }
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }

        navigation.goBack()
      } catch (e) {
        AlertModalStore.alert('Error', e?.toString())
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    // const renderItem = ({item}) => <ContactItem item={item} navigation={navigation}/>
    const renderItem = ({item}: { item: IKvItemCheckModel }) => (
      <TouchableOpacity
        onPress={() => toggleSelectItem(item)}>
        <View style={{
          marginBottom: 20,
          flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'
        }}>
          <Text preset="textName" style={{fontWeight: '700'}}>{item?.name}</Text>
          <SvgIcon
            icon={item?.checked ? 'check-square' : 'square-regular'}
            theme={item?.checked ? 'solid' : 'regular'} preset={'primary'}
          />
        </View>
      </TouchableOpacity>
    )

    const isValid = listItem?.filter(it => it?.checked)?.length > 0
    return (
      <>
        <PureBottomModal onClose={() => navigation.goBack()}>
          <SafeAreaView style={styles.container}>
            <Header
              preset={'modalHeader'}
              headerText={'Select Chat Group(s)'}
              titlePreset={'headerTitle'}
              leftIcon={'arrow-left'} leftIconPreset={'normal'} onLeftPress={() => navigation.goBack()}
              rightIcon={() => (
                <TouchableOpacity onPress={onCheckAll}>
                  <View style={{alignItems: 'center', marginRight: 10, paddingTop: 8}}>
                    <SvgIcon
                      icon={checked ? 'check-square' : 'square-regular'}
                      theme={checked ? 'solid' : 'regular'} preset={'primary'}
                    />
                    <View style={{marginTop: 4}}>
                      <Text preset="small" style={{color: color.palette.primary}}>All</Text>
                    </View>
                  </View>
                </TouchableOpacity>
              )}
            />
            <View style={{paddingHorizontal: 10}}>
              <FlatList
                showsVerticalScrollIndicator={false}
                contentContainerStyle={styles.contentContainerStyle}
                data={listItem}
                renderItem={renderItem}
                keyExtractor={(item, index) => index.toString()}
                ListFooterComponent={<View style={{height: 200}}/>}
              />
            </View>
            <BottomContainer backgroundColor={color.palette.grey}>
              <Button onPress={handleRemoveContact} disabled={!isValid} preset={isValid ? 'brand' : 'disabled'}>
                <Text preset={'whiteBold'}>Delete Chat Group(s)</Text>
              </Button>
            </BottomContainer>
          </SafeAreaView>
        </PureBottomModal>
        <BackdropModal visible={confirmVisible} onClose={() => setConfirmVisible(false)}>
          <View>
            <View style={{alignItems: "center", justifyContent: 'center', padding: 10, paddingTop: 0}}>
              <View style={{paddingBottom: 8}}>
                <Text preset={'alertTitle'} style={{color: color.palette.red, textAlign: 'center'}}>
                  You have selected Chat Group(s) to Delete
                </Text>
                <View style={{marginTop: 20, marginBottom: 10}}>
                  <Text preset={'alertTitle'} style={{textAlign: 'center'}}>
                    This will result in all Messages to the Group also being deleted
                  </Text>
                </View>
              </View>
              <SvgIcon preset={'alertIcon'}/>
              <View style={{paddingTop: 18, paddingBottom: 8}}>
                <Text preset={'alertTitle'}>Are you sure?</Text>
              </View>
            </View>
            <Button
              onPress={onRemoveContact}
              style={styles.modalBtn}
              disabled={false}
              preset={'brand'}
              text={'Delete Group'}
            />
            <Button
              onPress={() => setConfirmVisible(false)}
              style={{marginTop: 8}}
              disabled={false}
              preset={'destructive'}
              text={'Cancel'}
            />
          </View>
        </BackdropModal>
        <LoadingModal visible={loading}/>
        <AlertModal/>
      </>
    )
  },
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainerStyle: {
    paddingHorizontal: 10,
  },
  modalBtn: {
    marginVertical: 8
  },
});
