import React from 'react';
import {View, Modal, StyleSheet, TouchableOpacity, StyleProp, ViewStyle} from 'react-native';
import {color} from "../../theme";
import {BREAKPOINT_MOBILE, isDesktop} from "../../utils/global";

export interface BottomModalProps {
  visible: boolean
  onClose: () => void
  children: React.ReactNode
  style?: StyleProp<ViewStyle>
  contentStyle?: StyleProp<ViewStyle>
}

export function BottomModal(props: BottomModalProps) {
  const {visible, onClose, children, style, contentStyle} = props;

  return (
    <Modal
      animationType='fade'
      transparent={true}
      visible={visible}
      onRequestClose={onClose}
    >
      <View style={styles.modalContainer}>
        <TouchableOpacity style={styles.modalBackdrop} onPress={onClose}/>
        <View style={[styles.modalFullWidth, contentStyle, isDesktop && {width: BREAKPOINT_MOBILE - 210}]}>
          <View style={[styles.modalView, style]}>
            {children}
          </View>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modalBackdrop: {
    backgroundColor: '#00000055',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9,
  },
  modalContainer: {flex: 1, justifyContent: 'flex-end', alignItems: 'center'},
  modalFullWidth: {
    width: '100%',
    zIndex: 99,
  },
  modalView: {
    paddingVertical: 40,
    paddingHorizontal: 20,
    backgroundColor: color.palette.white,
    borderRadius: 10,
  },
});
