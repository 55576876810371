import {Instance, types} from "mobx-state-tree"
import {convertFileUploadModel, FileUploadModel} from "./file-upload";
import {convertKvItemModel, IKvItemModel, KvItemModel} from "./kv-item";
import {parseLocation} from "./location";
import {safeArrayConvert} from "../../utils/utils";

export const GENDER: { [key: string]: IKvItemModel } = {
  male: {id: 1, name: 'Male'},
  female: {id: 2, name: 'Female'},
  unspecified: {id: 3, name: 'Unspecified'},
}

export const VACCINE_STATUS: { [key: string]: IKvItemModel } = {
  male: {id: 1, name: 'Yes'},
  female: {id: 2, name: 'No'},
}
export const TravelDistances = [
  '25 km',
  '50 km',
  '100 km',
  '200 km',
  '500 km',
  'Any',
]

export interface IWorkerSkillGroup {
  id: string,
  icon: string,
  text: string,
}

export const WorkerSkillList = {
  industries: {
    id: "industries",
    icon: "industry-alt",
    text: "Industries",
    label: "Search and select industry",
    hint: "Select Industry(s)",
  },
  workerRoles: {
    id: "workerRoles",
    icon: "user-hard-hat",
    text: "Roles",
    label: "Search and select role",
    hint: "Enter Role(s) Eg Retail Assistant, Chef",
  },
  experiences: {
    id: "experiences",
    icon: "diploma",
    text: "Experience",
    label: "Search and select experience",
    hint: "Eg Residential Extensions, 2 year Customer Service",
  },
  skills: {
    id: "skills",
    icon: "certificate",
    text: "Skills",
    label: "Search and select skills",
    hint: "Eg Licence - Forklift",
  },
  qualifications: {
    id: "qualifications",
    icon: "id-badge",
    text: "Qualifications",
    label: "Search and select qualifications",
    hint: "Eg Diploma - Project Management",
  },
}
export type WorkerSkillType = keyof typeof WorkerSkillList

export const convertUserSkillStr = (it: any): string => {
  return (it?.description
    ? [it?.skillCategory?.name, it?.description].join(' - ') : it?.skillCategory?.name)
}
export const convertUserQualificationStr = (it: any): string => it?.qualificationEducationLevel?.name
  ? [it?.qualificationEducationLevel?.name, it?.courseName].join(' - ') : it?.courseName


export const ShortUserModel = types.model({
  id: types.optional(types.number, 0),
  firstName: types.optional(types.string, ""),
  lastName: types.optional(types.string, ""),
  username: types.optional(types.string, ""),
  email: types.optional(types.string, ""),
  mobile: types.optional(types.string, ""),
  countryCode: types.optional(types.string, ""),
  avatar: types.optional(FileUploadModel, {}),
})
export type IShortUserModel = Instance<typeof ShortUserModel>
export const convertShortUserModel = (raw): IShortUserModel => ({
  id: raw?.id || 0,
  firstName: raw?.firstName || '',
  lastName: raw?.lastName || '',
  username: raw?.username || '',
  email: raw?.email || '',
  mobile: raw?.mobile || '',
  countryCode: raw?.countryCode || '',
  avatar: convertFileUploadModel(raw?.avatar?.data?.attributes ? {
    ...raw?.avatar?.data?.attributes, id: raw?.avatar?.data?.id
  } : raw?.avatar?.attributes ? {...raw?.avatar?.attributes, id: raw?.avatar?.id} : raw?.avatar),
})

export const WorkerProfileModel = types.model({
  id: types.optional(types.number, 0),
  bio: types.optional(types.string, ''),
  preferredTravelDistance: types.optional(types.string, ''),
  availableWeeks: types.optional(types.string, ''),
  unavailableWeeks: types.optional(types.string, ''),
  availableTime: types.optional(types.string, ''),
  addressLine1: types.optional(types.string, ''),
  addressLine2: types.optional(types.string, ''),
  city: types.optional(types.string, ''),
  state: types.optional(types.string, ''),
  postcode: types.optional(types.string, ''),
  dob: types.optional(types.string, ''),
  gender: types.optional(types.number, 0),
  returnedWorker: types.optional(types.boolean, false),
  exServicePerson: types.optional(types.boolean, false),
  rate: types.optional(types.number, 0),

  myLocation: types.optional(KvItemModel, {}),
  industry: types.optional(KvItemModel, {}),
  preferredLocations: types.optional(types.array(KvItemModel), []),
  travelDistance: types.optional(types.string, ''),
  indigenousStatuses: types.optional(types.array(KvItemModel), []),
  rightToWork: types.optional(KvItemModel, {}),

  industries: types.optional(types.array(KvItemModel), []),
  experiences: types.optional(types.array(KvItemModel), []),
  skills: types.optional(types.array(KvItemModel), []),
  qualifications: types.optional(types.array(KvItemModel), []),
  workerRoles: types.optional(types.array(KvItemModel), []),

  rightToWorkDocs: types.optional(types.array(FileUploadModel), []),
  document: types.optional(FileUploadModel, {}),
  myCv: types.optional(FileUploadModel, {}),
  user: types.maybeNull(ShortUserModel),
})
export type IWorkerProfileModel = Instance<typeof WorkerProfileModel>
export const convertWorkerProfileModel = (raw): IWorkerProfileModel => ({
  id: raw?.id || 0,
  travelDistance: raw?.travelDistance || '',
  bio: raw?.bio || '',
  preferredTravelDistance: raw?.preferredTravelDistance || '',
  availableWeeks: raw?.availableWeeks || '',
  unavailableWeeks: raw?.unavailableWeeks || '',
  availableTime: raw?.availableTime || '',
  addressLine1: raw?.addressLine1 || '',
  addressLine2: raw?.addressLine2 || '',
  city: raw?.city || '',
  state: raw?.state || '',
  postcode: raw?.postcode || '',
  dob: raw?.dob || '',
  gender: raw?.gender || 0,
  returnedWorker: raw?.returnedWorker || false,
  exServicePerson: raw?.exServicePerson || false,
  rate: raw?.rate || 0,
  preferredLocations: safeArrayConvert(raw?.preferredLocations)
    ?.map(item => convertKvItemModel(
      item?.data?.attributes ? {name: parseLocation(item?.data?.attributes), id: item?.data?.id}
        : item?.attributes ? {name: parseLocation(item?.attributes), id: item?.id} : {
          name: parseLocation(item), id: item?.id
        })
    ),
  myLocation: convertKvItemModel(raw?.myLocation || {}),
  indigenousStatuses: safeArrayConvert(raw?.indigenousStatuses)
    ?.map(item => convertKvItemModel(
      item?.data?.attributes ? {...item?.data?.attributes, id: item?.data?.id}
        : item?.attributes ? {...item?.attributes, id: item?.id} : item)
    ),
  rightToWork: convertKvItemModel(raw?.rightToWork || {}),
  industry: convertKvItemModel(raw?.industry || {}),

  industries: safeArrayConvert(raw?.industries)
    ?.map(item => convertKvItemModel(
      item?.data?.attributes ? {...item?.data?.attributes, id: item?.data?.id}
        : item?.attributes ? {...item?.attributes, id: item?.id} : item)
    ),
  experiences: safeArrayConvert(raw?.experiences)
    ?.map(item => convertKvItemModel(
      item?.data?.attributes ? {...item?.data?.attributes, id: item?.data?.id}
        : item?.attributes ? {...item?.attributes, id: item?.id} : item)
    ),
  skills: safeArrayConvert(raw?.skills)
    ?.map((it) => convertKvItemModel({
      id: it.id, name: convertUserSkillStr(it)
    })),
  qualifications: safeArrayConvert(raw?.qualifications)
    ?.map((it) => convertKvItemModel({
      id: it?.id, name: convertUserQualificationStr(it)
    })),
  workerRoles: safeArrayConvert(raw?.workerRoles)
    ?.map(item => convertFileUploadModel(
      item?.data?.attributes ? {...item?.data?.attributes, id: item?.data?.id}
        : item?.attributes ? {...item?.attributes, id: item?.id} : item)
    ),

  rightToWorkDocs: safeArrayConvert(raw?.rightToWorkDocs)
    ?.map(item => convertFileUploadModel(
      item?.data?.attributes ? {...item?.data?.attributes, id: item?.data?.id}
        : item?.attributes ? {...item?.attributes, id: item?.id} : item)
    ),
  document: convertFileUploadModel(raw?.document?.data?.attributes ? {
    ...raw?.document?.data?.attributes, id: raw?.document?.data?.id
  } : raw?.document?.attributes ? {...raw?.document?.attributes, id: raw?.document?.id} : raw?.document),
  myCv: convertFileUploadModel(raw?.myCv?.data?.attributes ? {
    ...raw?.myCv?.data?.attributes, id: raw?.myCv?.data?.id
  } : raw?.myCv?.attributes ? {...raw?.myCv?.attributes, id: raw?.myCv?.id} : raw?.myCv),
  user: convertShortUserModel(raw?.user?.data?.attributes ? {
    ...raw?.user?.data?.attributes, id: raw?.user?.data?.id
  } : raw?.user?.attributes ? {...raw?.user?.attributes, id: raw?.user?.id} : raw?.user),
})
