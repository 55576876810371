import { Instance, types } from "mobx-state-tree"
import { convertFileLocalModel, convertFileUploadModel, FileLocalModel, FileUploadModel } from "./file-upload";
import { convertShortUserModel, ShortUserModel } from "./worker-profile";
import { convertJobLocationModel, JobLocationModel } from "./location";

export const BusinessProfileModel = types.model({
  city: types.optional(types.string, ''),
  countryCode: types.optional(types.string, ''),
  createdAt: types.optional(types.string, ''),
  // createdBy: types.optional(types.string, ''),
  fbLink: types.optional(types.string, ''),
  id: types.optional(types.number, 0),
  instaLink: types.optional(types.string, ''),
  // jobs: types.optional(types.string, ''),
  logo: types.optional(FileUploadModel, {}),
  name: types.optional(types.string, ''),
  outline: types.optional(types.string, ''),
  postcode: types.optional(types.string, ''),
  industry: types.optional(types.number, 0),
  publishedAt: types.optional(types.string, ''),
  telephone: types.optional(types.string, ''),
  twLink: types.optional(types.string, ''),
  updatedAt: types.optional(types.string, ''),
  // updatedBy: types.optional(types.string, ''),
  website: types.optional(types.string, ''),
  email: types.optional(types.string, ''),
  location: types.optional(JobLocationModel, {}),
  showWorkerTab: types.optional(types.boolean, false),
})
export type IBusinessProfileModel = Instance<typeof BusinessProfileModel>
export const convertBusinessProfileModel = (raw): IBusinessProfileModel => ({
  city: raw?.city || '',
  countryCode: raw?.countryCode || '',
  createdAt: raw?.createdAt || '',
  // createdBy:  '',
  fbLink: raw?.fbLink || '',
  id: raw?.id || 0,
  instaLink: raw?.instaLink || '',
  // jobs:  '',
  logo: convertFileUploadModel(raw?.logo?.data?.attributes ? {
    ...raw?.logo?.data?.attributes, id: raw?.logo?.data?.id
  } : raw?.logo?.attributes ? {...raw?.logo?.attributes, id: raw?.logo?.id} : raw?.logo),
  name: raw?.name || '',
  outline: raw?.outline || '',
  postcode: raw?.postcode || '',
  industry: raw?.industry?.id || raw?.industry || 0,
  publishedAt: raw?.publishedAt || '',
  telephone: raw?.telephone || '',
  twLink: raw?.twLink || '',
  updatedAt: raw?.updatedAt || '',
  // updatedBy:  '',
  website: raw?.website || '',
  email: raw?.email || '',
  location: convertJobLocationModel(raw?.location?.data?.attributes ? {
    ...raw?.location?.data?.attributes, id: raw?.location?.data?.id
  } : raw?.location?.attributes
    ? {...raw?.location?.attributes, id: raw?.location?.id} : raw?.location),
  showWorkerTab: raw?.showWorkerTab || false,
})

export const KvItemBusinessModel = types.model({
  id: types.optional(types.number, 0),
  name: types.optional(types.string, ''),
  user: types.optional(ShortUserModel, {}),
  businessProfile: types.maybeNull(BusinessProfileModel),
})
export type IKvItemBusinessModel = Instance<typeof KvItemBusinessModel>
export const convertKvItemBusinessModel = (raw): IKvItemBusinessModel => ({
  id: raw?.id || 0,
  name: raw?.name || '',
  user: convertShortUserModel(raw?.user?.data?.attributes ? {
    ...raw?.user?.data?.attributes, id: raw?.user?.data?.id
  } : raw?.user?.attributes ? {
    ...raw?.user?.attributes, id: raw?.user?.id
  } : raw?.user),
  businessProfile: convertBusinessProfileModel(raw?.businessProfile?.data?.attributes ? {
    ...raw?.businessProfile?.data?.attributes, id: raw?.businessProfile?.data?.id
  } : raw?.businessProfile?.attributes
    ? {...raw?.businessProfile?.attributes, id: raw?.businessProfile?.id} : raw?.businessProfile),
})

export const CreateLaterBusinessProfileModel = types.model({
  logo: types.optional(FileLocalModel, {}),
  name: types.optional(types.string, ''),
  userId: types.optional(types.number, 0),
})
export type ICreateLaterBusinessProfileModel = Instance<typeof CreateLaterBusinessProfileModel>
export const convertCreateLaterBusinessProfileModel = (raw): ICreateLaterBusinessProfileModel => ({
  logo: convertFileLocalModel(raw?.logo),
  name: raw?.name || '',
  userId: raw?.userId || '',
})
