import React, {FC, useEffect, useRef, useState} from 'react';
import {View, StyleSheet, TouchableOpacity, FlatList} from 'react-native';

import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {observer} from "mobx-react-lite";
import moment from "moment";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {color} from "../../../theme";
import {Header, Text, TextField} from "../../../components";
import {AvatarImage} from "../../../components/avatar-image/avatar-image";
import {ParamListBase, RouteProp} from "@react-navigation/native";
import {NavigatorParamList} from "../../../navigators";
import {useStores} from "../../../models";
import {IUserAccountModel} from "../../../models/app/user-account";
import {listToStr} from "../../../utils/utils";
import {IUserChatModel, IUserMessageModel} from "../../../models/app/message";
import { constants, DEFAULT_MD_LIMIT } from "../../../utils/constants";
import {AwChatManager} from "../../../services/api/socket";
import {ListDiv} from "../../../components/list-div/list-div";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import {TrackEvents, trackWithUserAccount} from "../../../services/track";
import {useFilePicker} from "use-file-picker";
import {convertFileUploadModel} from "../../../models/app/file-upload"
import {MessageImage} from "../../../components/avatar-image/message-image"
import {SvgIcon} from "../../../components/icon/svg-icon";
import {isIos} from "../../../utils/platform";

export interface BusinessNewMessageScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'businessNewMessageScreen'>
}

const avatar = require("../../../../assets/images/default-photo.png")

export const BusinessNewMessageScreen: FC<BusinessNewMessageScreenProps> = observer(
  ({navigation, route}) => {
    const insets = useSafeAreaInsets()
    const toUserId = route.params?.toUserId
    const messageId = route.params?.messageId
    const {app} = useStores()
    const isWorker = app.user.role === constants.roles.WORKER
    const isManager = app?.user?.role === constants.roles.MANAGER
    const fromUserAccount: IUserAccountModel = app?.userAccount
    const [toUserAccount, setUserAccount] = useState<IUserAccountModel>()
    const [chatData, setChatData] = useState<Array<IUserChatModel>>([])
    const [startDayIdList, setStartDayIdList] = useState<Array<number>>([])
    const [loading, setLoading] = useState(false)
    const [textMessage, setTextMessage] = useState('')
    const canSend = !!textMessage?.trim()
    const [chatManager, setChatManager] = useState<AwChatManager>(null)
    const [userMessageData, setUserMessageData] = useState<IUserMessageModel>()
    const responsesOptions = userMessageData?.workerResponses || []

    const currentContact = userMessageData?.group?.contacts?.find(it => it?.user?.id === fromUserAccount?.id)
    const disabledChat = userMessageData?.group?.hideContacts?.find(it => it.id === currentContact?.id)
    const disabledSend = userMessageData?.hideUserIds?.includes(`${fromUserAccount?.id},`)
      || userMessageData?.hideUserIds?.includes(`${toUserAccount?.id},`)

    const dataRef = useRef<FlatList>();
    const initData = async () => {
      const messRes = await app.environment.api.getMessageDetail(messageId)
      if (messRes.kind === 'ok') {
        setUserMessageData(messRes.item)
      }
      const res = await app.environment.api.getMe(toUserId)
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message)
        return
      }
      setUserAccount(res.userAccount)
      // dataRef && dataRef?.current && dataRef?.current?.scrollToEnd()
    }

    const loadData = async () => {
      // loadSocket()
      if (loading) return
      setLoading(true)
      try {
        const chatRes = await app.environment.api.getNewChatMessage({
          messageId, userId: isWorker ? fromUserAccount?.id : toUserId,
          ...(!isWorker ? {
            businessProfileId: isManager
              ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          } : null),
          ...(disabledChat ? {'filters[updatedAt][$lte]': disabledChat.name} : null),
          "pagination[pageSize]": DEFAULT_MD_LIMIT
        })
        if (chatRes.kind !== 'ok') {
          AlertModalStore.alert('Error', chatRes.message)
          return
        }
        if (!chatData || chatRes.items.length !== chatData?.length) {
          const startDayIdDict = {}
          chatRes.items?.forEach(item => {
            const createdDate = moment(item?.createdAt).format('DD/MM/YYYY')
            if (!startDayIdDict.hasOwnProperty(createdDate)) startDayIdDict[createdDate] = item?.id
          })
          setStartDayIdList(Object.values(startDayIdDict))
          setChatData(chatRes.items?.reverse())
        }

      } catch (e) {
        console.log(e)
      } finally {
        // setTimeout(() => {
        //   dataRef && dataRef?.current && dataRef?.current?.scrollToEnd()
        // }, 200)
        setLoading(false)
      }
    }

    useEffect(() => {
      if (toUserId) {
        loadData()
        initData()
      }
      if (!disabledChat) {
        const awChatManager = AwChatManager.getInstance(app.auth)
        awChatManager.onChat = () => {
          loadData()
        }
        setChatManager(awChatManager)
      }
      return () => {
        AwChatManager.currentInstance()?.disconnect()
      }
    }, [])

    const handleSend = async (_textMessage: string) => {
      if (!!urlImgBefore) {
        refTextImg.current = textMessage;
        setTextMessage('');
        prepareImg();
        return;
      }
      if (!_textMessage?.trim() || loading) return
      try {
        if (!chatManager?.socket) {
          console.log('Socket null')
          return
        }
        chatManager?.socket.emit('chat', {
          businessProfileId: isManager
              ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          messageId: messageId,
          toUserId: toUserAccount?.id,
          action: 'sendMessage',
          content: _textMessage,
        });
      } catch (e) {
        console.log(e)
      } finally {
        trackWithUserAccount(TrackEvents.SentMessage, app?.userAccount)
        setChatData([
          {
            id: new Date().getTime(), from: fromUserAccount, to: toUserAccount, content: _textMessage,
            createdAt: new Date().toISOString()
          } as IUserChatModel,
          ...chatData,
        ])
        setTimeout(() => {
          loadData()
        }, 400)
        setTextMessage('')
      }
    }

    const handleSendPress = () => {
      handleSend(textMessage)
    }

    // Start send img
    const [openFileSelector, {plainFiles, filesContent}] = useFilePicker({
      readAs: 'DataURL',
      accept: 'image/*',
      maxFileSize: 10,
    });
    const [urlImgBefore, setUrlImgBefore] = useState('');
    const refCurrentImage = useRef();
    const refTextImg = useRef('');

    useEffect(() => {
      if (!plainFiles || plainFiles.length <= 0) return
      onPickerResult(plainFiles)
    }, [plainFiles])

    const onPickerResult = async (response: File[]) => {
      if (Array.isArray(response) && response.length > 0) {
        try {
          setUrlImgBefore(filesContent[0].content)
          // @ts-ignore
          refCurrentImage.current = response[0];
        } catch (e) {
          console.log(e)
        }
      }
    }

    const handleUpLoadImg = async () => {
      await openFileSelector();
    }

    const handleSendImg = (mediaId: number) => {
      try {
        if (!chatManager?.socket) {
          console.log('Socket null')
          return
        }
        chatManager?.socket.emit('chat', {
          businessProfileId: isManager
              ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          messageId: messageId,
          toUserId: toUserAccount?.id,
          action: 'sendMessage',
          file: mediaId,
          content: refTextImg.current
        });
      } catch (e) {
        console.log(e)
      } finally {
        trackWithUserAccount(TrackEvents.SentMessage, app?.userAccount)
        setTimeout(() => {
          loadData()
        }, 400)
        refCurrentImage.current = null;
        refTextImg.current = '';
      }
    }

    const prepareImg = async () => {
      setUrlImgBefore('');
      setChatData([
        {
          id: new Date().getTime(),
          from: fromUserAccount,
          to: toUserAccount,
          file: convertFileUploadModel({url: 'local'}),
          createdAt: new Date().toISOString(),
          content: refTextImg.current
        } as IUserChatModel,
        ...chatData,
      ])
      const res = await app.environment.api.uploadFile(refCurrentImage.current)
      if (res.kind === 'ok') {
        handleSendImg(res?.file?.id)
      } else {
        AlertModalStore.alert('Error', 'Can not upload file')
      }
    }

    const onRemoveImg = () => {
      setUrlImgBefore('');
      refCurrentImage.current = null;
    }
    // End


    const renderItem = ({item}: { item: IUserChatModel }) => {
      const isFrom = item?.from?.id === toUserId
      return (
        <View>
          {startDayIdList.includes(item?.id) && (
            <View style={{alignItems: 'center', marginTop: 20, paddingBottom: 10}}>
              <Text
                preset={'body1'} style={{color: color.palette.black80}}
              >{moment(item?.createdAt).format('DD/MM/YYYY')}</Text>
            </View>
          )}
          <View style={{
            flexDirection: 'row',
            marginBottom: 20,
            marginRight: isFrom ? 50 : 0, marginLeft: isFrom ? 0 : 50
          }}>
            {isFrom ? (
              <AvatarImage
                onPress={showProfile}
                style={{}} preset={'medium'}
                source={toUserAvatar ? {uri: toUserAvatar} : avatar}/>
            ) : null}
            <View style={{flex: 1, paddingTop: 4, alignItems: isFrom ? 'flex-start' : 'flex-end'}}>
              <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                <Text preset={isFrom ? 'messageTitleLink' : 'messageTitle'}>
                  {listToStr([item?.from?.firstName, item?.from?.lastName])}
                </Text>
                <Text style={{marginLeft: 10}} preset={'caption'}>{moment(item?.createdAt).format('HH:mm')}</Text>
              </View>
              {!!item?.file?.url
                ?
                <View style={{
                  marginTop: 10,
                  flexDirection: 'column',
                  alignSelf: isFrom ? 'flex-start' : 'flex-end',
                  alignItems: isFrom ? 'flex-start' : 'flex-end'
                }}>
                  {!!item.content &&
                  <View style={{
                    marginBottom: 5,
                    paddingHorizontal: 18,
                    paddingVertical: 12,
                    borderTopRightRadius: 10,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    backgroundColor: isFrom ? color.palette.grey : color.palette.bgGreen,
                  }}>
                    <Text preset={'body1'} text={item.content}/>
                  </View>}
                  {item?.file?.url.includes('local') ? <SvgIcon
                    icon='image'
                    theme='light'
                    preset='imgMsg'
                  /> : <MessageImage name={item?.file?.name} url={item?.file?.url}/>}
                </View>
                :
                <View style={{
                  marginTop: 10, paddingHorizontal: 18, paddingVertical: 12,
                  backgroundColor: isFrom ? color.palette.grey : color.palette.bgGreen,
                  borderTopRightRadius: 10, borderBottomLeftRadius: 10, borderBottomRightRadius: 10,
                  flexDirection: 'row',
                  alignSelf: isFrom ? 'flex-start' : 'flex-end'
                }}>
                  <Text preset={'body1'} text={item.content}/>
                </View>
              }
            </View>
          </View>
        </View>
      )
    }

    const toUserAvatar = isWorker
      ? toUserAccount?.businessProfile?.logo?.url
      : toUserAccount?.avatar?.url
    const showSuggest = !chatData?.find(it => it.from.id === fromUserAccount.id)
    const showProfile = () => {
      if (isWorker) navigation.navigate('businessProfileScreen', {
        businessProfileId: toUserAccount?.businessProfile?.id
      })
      else navigation.navigate("workerProfileScreen", {id: toUserAccount?.id})
    }

    return (
      <>
        <PureBottomModal onClose={() => navigation.goBack()}>
          <View style={styles.container}>
            <View style={styles.mainContainer}>
              <Header
                preset={'modalHeader'}
                header={() => (
                  <View style={{paddingTop: 4}}>
                    <Text preset={'mediumTitle'}>{listToStr([toUserAccount?.firstName, toUserAccount?.lastName])}</Text>
                    <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 8}}>
                      <TouchableOpacity>
                        <Text preset={'mediumLink'} style={{marginRight: 4}}>{''}</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                )}
                leftIcon={() => (
                  <AvatarImage
                    onPress={showProfile}
                    preset={'default'} source={toUserAvatar ? {uri: toUserAvatar} : avatar}/>
                )}
                rightIcon={'times'} rightIconPreset={'smallBlue'} onRightPress={() => navigation.goBack()}
              />
            </View>
            <View style={styles.body}>
              <View style={{flex: 1, paddingLeft: 10, paddingRight: 20,}}>
                <FlatList
                  ref={dataRef}
                  showsVerticalScrollIndicator={false}
                  ListFooterComponent={<View style={{height: 20}}/>}
                  keyExtractor={((item) => String(item.id))}
                  inverted={true}
                  data={chatData} renderItem={renderItem}/>
              </View>
              <View style={{
                backgroundColor: color.palette.bgBlue30, paddingBottom: insets.bottom,
                display: disabledChat || disabledSend ? 'none' : 'flex'
              }}>
                {showSuggest && responsesOptions && responsesOptions.length > 0 && (
                  <View style={styles.suggestContainer}>
                    <ListDiv
                      data={responsesOptions}
                      itemValue={(index) => responsesOptions[index].name}
                      renderIcon={() => ({
                        icon: "arrow-circle-up",
                        theme: "regular",
                        preset: "primary",
                      })}
                      preset={'chatSuggest'} selectPreset={'chatSuggest'}
                      onPress={(index) => handleSend(responsesOptions[index].name)}
                    />
                  </View>
                )}
                <View style={styles.chatContainer}>
                  <View style={{flex: 1}}>
                    <TextField
                      numberOfLines={2} multiline={true}
                      value={textMessage} onChangeText={setTextMessage}
                      inputPreset={'roundMessage'}
                      placeholder={'Enter Message'}
                      rightIcon={'arrow-circle-up'}
                      rightIconTheme={canSend || !!urlImgBefore ? 'regular' : 'solid'}
                      rightIconPreset={canSend || !!urlImgBefore ? 'normal' : 'normalDisabled'}
                      rightIconPress={handleSendPress}
                      leftIconPress={handleUpLoadImg}
                      leftIconPreset="normalDisabled"
                      leftIcon={'image-sold'}
                      leftIconTheme="solid"
                      inputStyle={{
                        marginLeft: 5, maxHeight: 60, paddingTop: 0, paddingBottom: 0,
                        textAlignVertical: 'center'
                      }}
                      urlImgMsg={urlImgBefore}
                      onRemoveImg={onRemoveImg}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </PureBottomModal>
        <AlertModal/>
      </>
    )
  },
)

const styles = StyleSheet.create({
  body: {
    flex: 1,
  },
  chatContainer: {
    alignItems: 'center', borderTopColor: color.palette.grey,
    borderTopWidth: 1.5, flexDirection: 'row', paddingBottom: isIos ? 10 : 3, paddingHorizontal: 20, paddingTop: 11
  },
  container: {
    backgroundColor: color.palette.white,
    flex: 1,
  },
  contentContainerStyle: {
    marginVertical: 20,
    paddingHorizontal: 10,
  },
  mainContainer: {
    borderBottomColor: color.palette.grey,
    borderBottomWidth: 1.5,
  },
  modalBtn: {
    marginVertical: 8
  },
  statusBar: {
    backgroundColor: color.palette.bgBlue,
  },
  suggestContainer: {
    borderTopColor: color.palette.grey, borderTopWidth: 1.5,
    paddingHorizontal: 20, paddingVertical: 18
  },
});
