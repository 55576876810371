import React, {FC, useCallback, useState} from "react"
import {View, StyleSheet, SafeAreaView, ScrollView} from "react-native"

import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {observer} from "mobx-react-lite"
import {color} from "../../../theme"
import {Button, Header, Text, TextField} from "../../../components"
import {useStores} from "../../../models"
import {
  IBusinessGroupModel,
} from "../../../models/app/contact"
import {ParamListBase, RouteProp, useFocusEffect} from "@react-navigation/native"
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";
import {constants} from "../../../utils/constants";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import {TrackEvents, trackWithUserAccount} from "../../../services/track";
import {isAndroid, isIos} from "../../../utils/platform";
import {BusinessMessageScreenTabs} from "./group-message-screen";
import {AwSelectButton} from "../../../components/button/aw-select-button"
import LineCard from "../../../components/card/line-card"
import {NavigatorParamList} from "../../../navigators";
import {MainAppStore} from "../../../models/app/main-app-store";

export interface GroupNewMessageScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'groupNewMessageScreen'>
}

export const GroupNewMessageScreen: FC<GroupNewMessageScreenProps> = observer(({navigation, route}) => {
  const groupId = route.params?.groupId
  const {app} = useStores()
  const isManager = app?.user?.role === constants.roles.MANAGER
  const [refreshing, setRefreshing] = useState(false)
  const [listGroupItem, setListGroupItem] = useState<Array<IBusinessGroupModel>>([])
  const [groupIndex, setGroupIndex] = useState(null)
  const [currentHeight, setCurrentHeight] = useState(0)

  const responsesOptions = MainAppStore.responsesOptions
  const [messageTitle, setMessageTitle] = useState("")
  const [messageText, setMessageText] = useState("")

  const loadData = async (firstLoad: boolean = false) => {
    try {
      setRefreshing(true)
      const res = await app.environment.api.getGroups({
        'filters[businessProfile][id][$eq]': isManager
          ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
        'businessProfileId': isManager
          ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
      })

      if (res.kind !== "ok") {
        AlertModalStore.alert("Error", res.message)
        return
      }
      setListGroupItem(res.items)
      if (firstLoad && groupId) setGroupIndex(res?.items?.findIndex(it => it.id === groupId))
    } catch (e) {
      console.log(e)
    } finally {
      setRefreshing(false)
    }
  }

  useFocusEffect(
    useCallback(() => {
      loadData(true)
    }, []),
  )

  const toggleResponseOption = (item) => {
    MainAppStore.setResponsesOptions(responsesOptions?.map((it) => ({
      ...it, checked: it.id !== item?.id ? it.checked : false
    })))
  }

  const handleCreateMsg = async () => {
    try {
      if (refreshing) return
      if (!messageTitle) {
        AlertModalStore.alert('Error', 'Please enter message title.')
        return
      } else if (groupIndex !== 0 && !groupIndex) {
        AlertModalStore.alert('Error', 'Please select group.')
        return
      }
      setRefreshing(true)
      const res = await app.environment.api.createNewMessage(
        {
          title: messageTitle,
          content: messageText,
          group: listGroupItem[groupIndex]?.id,
          workerResponses: responsesOptions?.filter((it) => it.checked).map(it => it.id),
          businessProfile: isManager ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          businessProfileId: isManager ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
        }
      )
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res?.message || 'Can not create')
        return
      }
      trackWithUserAccount(TrackEvents.SentGroupMessage, app?.userAccount)

      app.tabStore.setBusinessGroupMessageTab(BusinessMessageScreenTabs.bulk.key)
      navigation.popToTop()
    } catch (e) {
      console.log(e)
    } finally {
      setRefreshing(false)
    }
  }

  const checkedResponsesOption = responsesOptions?.filter((it) => it.checked)
  const isActive = messageTitle?.trim().length > 0 && messageText?.trim().length > 0
  return (
    <>
      <PureBottomModal onClose={() => navigation.goBack()}>
        <SafeAreaView style={styles.container}>
          <Header
            preset={"modalHeader"}
            headerText={"New Bulk Message"}
            titlePreset={"headerTitle"}
            rightIcon={"times"} rightIconPreset={"smallBlue"}
            onRightPress={() => navigation.goBack()}
          />
          <View style={styles.body}>
            <View
              onLayout={event => setCurrentHeight(event.nativeEvent.layout?.height)}
              style={isAndroid && currentHeight > 0 ? {height: currentHeight} : {flex: 1}}>
              <ScrollView style={{paddingHorizontal: 20, paddingTop: 10, flex: 1}}>
                <View style={{paddingBottom: 10, paddingTop: 4, marginBottom: 15}}>
                  <TextField
                    preset={"brand"}
                    value={messageTitle}
                    onChangeText={(e) => setMessageTitle(e)}
                    label={"Message Title*"}
                    labelPreset={"bold"}
                    placeholder={"Enter title*"}
                  />
                </View>
                <View style={{paddingBottom: 10, paddingTop: 4}}>
                  <TextField
                    preset={"brand"}
                    value={messageText}
                    onChangeText={(e) => setMessageText(e)}
                    label={"Message*"}
                    labelPreset={"bold"}
                    multiline={true}
                    numberOfLines={5}
                    inputStyle={{height: 120}}
                    placeholder={"Enter message*"}
                  />
                </View>
                <View style={{paddingVertical: 20}}>
                  <AwSelectButton
                    text={'Add Response Option(s)'}
                    styles={{fontWeight: 'bold'}}
                    onPress={() => navigation.navigate('groupNewMessageResponseScreen')}
                  />
                </View>
                <View style={{flex: 1, paddingBottom: 90}}>
                  {checkedResponsesOption.map((i, idx) => {
                    return (
                      <LineCard
                        key={i.id}
                        name={i.name}
                        isCheck={i.checked}
                        onPress={() => toggleResponseOption(checkedResponsesOption[idx])}/>
                    )
                  })}
                </View>
              </ScrollView>
            </View>
            <View style={{paddingTop: 12, paddingHorizontal: 20, marginBottom: isIos ? 0 : 30}}>
              <Button onPress={handleCreateMsg} preset={isActive ? 'brand' : 'disabled'} disabled={!isActive}>
                <Text preset={"whiteBold"}>Send Message</Text>
              </Button>
            </View>
          </View>
        </SafeAreaView>
      </PureBottomModal>
      <AlertModal/>
    </>
  )
})

const styles = StyleSheet.create({
  body: {
    backgroundColor: color.palette.white,
    flex: 1,
  },
  container: {
    flex: 1,
    overflow: 'hidden'
  },
  contentContainerStyle: {
    marginVertical: 20,
    paddingHorizontal: 10,
  },
  modalBtn: {
    marginVertical: 8,
  },
})
