import React from "react";
import {TabBar} from "../../components/tab-bar/tab-bar";
import {createBottomTabNavigator} from "@react-navigation/bottom-tabs";
import {GroupMessageScreen} from "../../screens/business/messages/group-message-screen";
import {MainContactScreen} from "../../screens/business/contacts/main";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {BusinessHomeScreen} from "../../screens/business/home/business-home";
import {SettingsScreen} from "../../screens/shared";
import {JobManageListScreen} from "../../screens/business/job-adverts-manage/job-manage-list-screen";
import {BusinessManagerScreen} from "../../screens/business/business-manager/business-manager";

export type BusinessUserParamList = {
  // OUTER
  tabNavigator: { screen?: string }
  businessManagerScreen: undefined
  homeScreen: undefined
  manageJobScreen: undefined
  contactScreen: { isGroups?: boolean }
  settingScreen: undefined
  groupMessageScreen: { activeTab?: number }
}

const Tab = createBottomTabNavigator<BusinessUserParamList>()

const TabNavigator = () => (
  <Tab.Navigator
    screenOptions={{
      headerShown: false,
    }}
    tabBar={(props) => <TabBar {...props} />}
    initialRouteName={"homeScreen"}
  >
    <Tab.Screen name={"homeScreen"} component={BusinessHomeScreen} options={{tabBarLabel: 'Dashboard'}}/>
    <Tab.Screen name={'manageJobScreen'} component={JobManageListScreen} options={{tabBarLabel: 'Job Ads'}}/>
    <Tab.Screen name={'groupMessageScreen'} component={GroupMessageScreen} options={{tabBarLabel: 'Messages'}}/>
    <Tab.Screen name={'contactScreen'} component={MainContactScreen} options={{tabBarLabel: 'Contacts'}}/>
    <Tab.Screen name={'settingScreen'} component={SettingsScreen} options={{tabBarLabel: 'Settings'}}/>
  </Tab.Navigator>
)

const Stack = createNativeStackNavigator<BusinessUserParamList>()

export function BusinessUserNavigator() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName={'tabNavigator'}
    >
      <Stack.Screen name='tabNavigator' component={TabNavigator}/>
      <Stack.Screen name='businessManagerScreen' component={BusinessManagerScreen}
                    options={{presentation: 'transparentModal', animation: 'none'}}
      />

    </Stack.Navigator>
  );
}
