import React, { FC, useCallback, useState } from "react"
import {
  View, SafeAreaView, ScrollView, RefreshControl
} from "react-native"
import { observer } from "mobx-react-lite"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { Header, GradientBackground, Text, TextField, Button } from "../../../../components"
import { color } from "../../../../theme"
import { SelectTextField } from "../../../../components/text-field/select-text-field";
import { IKvItemModel } from "../../../../models/app/kv-item";
import { useStores } from "../../../../models";
import { searchCompare, searchIgnoreCase } from "../../../../utils/utils"
import { NavigatorParamList } from "../../../../navigators";
import { RouteProp, useFocusEffect } from "@react-navigation/native";
import { AlertModalStore } from "../../../../models/app/alert-modal-store";
import { PureBottomModal } from "../../../../components/backdrop-modal/pure-bottom-modal";
import { isAndroid } from "../../../../utils/platform";
import { debounce } from "../../../../utils/debounce";
import { getUserSkillStr } from "../../../../models/app/advanced-skill";
import { AlertModal } from "../../../../components/backdrop-modal/alert-modal";
import { SvgIcon } from "../../../../components/icon/svg-icon";
import { MainAppStore } from "../../../../models/app/main-app-store";

export interface WorkerDescriptionSkillScreenProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
  route: RouteProp<NavigatorParamList, 'workerDescriptionSkillScreen'>
}

export const WorkerDescriptionSkillScreen: FC<WorkerDescriptionSkillScreenProps> = observer(
  ({navigation, route}) => {
    const {app} = useStores();
    const skillCategory = route.params?.skillCategory
    const [loading, setLoading] = useState(false)
    const [description, setDescription] = useState("")
    const [data, setData] = useState<Array<IKvItemModel>>([]);
    const [selectedData, setSelectedData] = useState<IKvItemModel>();
    const [customIds, setCustomIds] = useState<Array<number>>([]);

    const loadData = async (text?: string, skillCategory?: number) => {
      if (!text || text?.length < 3) return
      setLoading(true)
      try {
        text = text?.trim();
        const userRes = await app.environment.api.getUserSkill({
          ...(skillCategory ? {'filters[skillCategory][id]': skillCategory} : null),
          ...(text ? {'filters[description][$containsi]': text} : null),
          'filters[user][id]': app?.userAccount?.id,
        })
        if (userRes.kind !== 'ok') return
        setCustomIds(userRes?.items?.map(it => it.id))
        const sysRes = await app.environment.api.getUserSkill({
          ...(skillCategory ? {'filters[skillCategory][id]': skillCategory} : null),
          ...(text ? {'filters[description][$containsi]': text} : null),
        })
        if (sysRes.kind === 'ok') {
          setData([...userRes?.items, ...sysRes?.items].map(it => ({id: it.id, name: getUserSkillStr(it)})))
        }

      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    const initData = async () => {
      await app.getMe()
    }

    useFocusEffect(useCallback(() => {
      initData()
    }, []))

    const toggleSelectItem = (id: number) => {
      const item = data?.find(it => it.id === id)
      setSelectedData(selectedData?.id === item?.id ? null : item)
    }

    const onDone = () => {
      MainAppStore.setUploadSelectedDescription({...selectedData})
      navigation.goBack()
    }

    const handleAddItem = async (description?: string) => {
      if (loading) return
      try {
        setLoading(true)
        const res = await app.environment.api.editUserSkill({
          ...(skillCategory ? {skillCategory} : null),
          ...(description ? {description} : null),
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        setDescription('')
        loadData(description)
        setSelectedData({id: res?.item?.id, name: getUserSkillStr(res?.item)})
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    const delayLoadData = useCallback(debounce(loadData, 600), []);
    const handleChangeSearch = (text: string) => {
      setDescription(text)
      // @ts-ignore
      delayLoadData(text, skillCategory)
    }
    const loadDataFromState = () => {
      loadData(description, skillCategory)
    }

    const handleAddOther = () => {
      handleAddItem(description)
    }

    const renderItem = (item: IKvItemModel, index: number) => {
      const checked = selectedData?.id === item.id
      return (
        <SelectTextField
          key={`${index}-${item.id}`}
          value={item.name} onPress={() => toggleSelectItem(item.id)}
          style={{marginBottom: 10}}
          rightIcon={checked ? 'check-circle' : 'circle'}
          rightIconTheme={checked ? 'solid' : 'regular'}
          rightIconPreset={checked ? 'primary' : 'default'}
          preset={checked ? 'itemSelected' : 'transparent'}
        />
      )
    }

    const _systemData = data.filter(item => !customIds.includes(item.id) && searchIgnoreCase(item?.name, description))
    const _customData = data.filter(item => customIds.includes(item.id) && searchIgnoreCase(item?.name, description))
    const showBtnAdd = !loading && description?.length >= 3 && data.find(item => searchCompare(item?.name, description)) === undefined;
    const showBtnDone = !!selectedData?.id
    return (
      <>
        <PureBottomModal onClose={() => navigation.goBack()}>
          <SafeAreaView style={{flex: 1}}>
            <GradientBackground
              colors={[color.palette.gradientStart, color.palette.gradientStop]}
            />
            <Header
              preset={"modalHeader"}
              titlePreset={"headerTitle"}
              headerText={'Description'}
              rightIcon={"times"}
              rightIconPreset={"smallBlue"}
              onRightPress={() => navigation.goBack()}
            />
            <View style={{paddingHorizontal: 20, flex: 1}}>
              <View style={{flex: 1}}>
                <View style={{}}>
                  <View style={{marginTop: 0, marginBottom: 8}}>
                    <TextField
                      preset={'brand'}
                      value={description} onChangeText={handleChangeSearch}
                      labelPreset={"default"} placeholder={'Enter Description'}
                      rightIcon={"times-circle-fill"} rightIconPreset={"default"}
                      rightIconPress={() => handleChangeSearch('')}
                      rightOuterIcon={
                        <View style={{justifyContent: 'center'}}>
                          <View style={{
                            justifyContent: 'center', alignItems: 'center',
                            marginLeft: 10, borderRadius: 36, width: 36, height: 36,
                            borderWidth: 1, borderColor: showBtnAdd ? color.palette.orange : color.palette.greyLink
                          }}>
                            <SvgIcon
                              icon={'plus'} preset={showBtnAdd ? 'orange' : 'disable'}
                              onPress={handleAddOther}
                            />
                          </View>
                        </View>
                      }
                    />
                  </View>
                </View>
                {data.length > 0 ? (
                  <View style={{marginTop: 24, flex: 1}}>
                    <ScrollView
                      refreshControl={<RefreshControl refreshing={loading} onRefresh={loadDataFromState}/>}
                      showsVerticalScrollIndicator={false} style={{flex: 1}}>
                      <View>
                        {_customData && _customData.length > 0 ? (
                          <View>
                            <View style={{marginHorizontal: 16}}>
                              <Text preset={"mediumBlue"}>Other</Text>
                            </View>
                            <View style={{height: 10}}/>
                            {_customData.map(renderItem)}
                          </View>
                        ) : null}
                        {data ? (
                          <View>
                            <View style={{marginHorizontal: 16, marginBottom: 10}}>
                              <Text preset={"mediumBlue"}>Results</Text>
                            </View>
                            {_systemData.map(renderItem)}
                          </View>
                        ) : null}
                      </View>
                    </ScrollView>
                  </View>
                ) : null}
              </View>
              <View
                style={{paddingTop: 10, marginBottom: isAndroid ? 30 : 5}}>
                <Button
                  disabled={!showBtnDone}
                  preset={!showBtnDone ? 'disabled' : 'brand'}
                  onPress={onDone} text={'Done'}/>
              </View>
            </View>
          </SafeAreaView>
        </PureBottomModal>
        <AlertModal/>
      </>
    )
  },
)
