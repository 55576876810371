import React, {FC, useCallback, useState} from 'react';
import {View, FlatList, StyleSheet, SafeAreaView, TouchableOpacity} from 'react-native';

import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {observer} from "mobx-react-lite";
import {color} from "../../../theme";
import {Button, Header, Text, TextField} from "../../../components";
import {BackdropModal} from "../../../components/backdrop-modal/backdrop-modal";
import {SvgIcon} from "../../../components/icon/svg-icon";
import {BusinessUserParamList} from "../../../navigators/app/business-user-navigator";
import {BottomContainer} from "../../../components/bottom-container/bottom-container";
import {useFocusEffect} from "@react-navigation/native";
import {useStores} from "../../../models";
import {BUSINESS_ACCESS_STATUS, IBusinessAccessModel} from "../../../models/app/business-access";
import {AvatarImage} from "../../../components/avatar-image/avatar-image";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import {BottomModal} from "../../../components/backdrop-modal/bottom-modal";
import AvatarDefault from "../../../components/avatar-image/AvatarDefault";

export interface ContactItemProps {
  item: IBusinessAccessModel
  onPress: () => void
}

export const ManagerItem: FC<ContactItemProps> = ({item, onPress}) => {
  const status = BUSINESS_ACCESS_STATUS[item.status]?.text || BUSINESS_ACCESS_STATUS.pending.text
  return (
    <View style={{flexDirection: 'row', paddingBottom: 18, justifyContent: 'space-between', alignItems: 'center'}}>
      <View style={{}}>
          {item?.businessProfile?.logo?.url ? <AvatarImage
              px={0}
              source={
                  item?.businessProfile?.logo?.url ? {uri: item?.businessProfile?.logo?.url}
                      : require('../../../../assets/images/default-company.png')
              }/> : <AvatarDefault name={item?.businessProfile?.name} />}

      </View>
      <View style={{flex: 1, paddingHorizontal: 10}}>
        <Text preset={'mediumTitle'}>{
          ([item?.user?.firstName, item?.user?.lastName].join(' ').trim() || '')
        }</Text>
        <View style={{marginTop: 5, flexDirection: 'row'}}>
          <Text preset={'bold'} style={{
            color: item.status === BUSINESS_ACCESS_STATUS.accepted.key
              ? color.palette.green : item.status === BUSINESS_ACCESS_STATUS.rejected.key
                ? color.palette.greyHigh : color.palette.orangeMid
          }}>{status}</Text>
        </View>
      </View>
      <TouchableOpacity style={{}} onPress={onPress}>
        <SvgIcon icon={'ellipsis-h'} theme={'solid'} preset={'primary'}/>
      </TouchableOpacity>
    </View>
  )
}

export interface BusinessManagerScreenProps {
  navigation: NativeStackNavigationProp<BusinessUserParamList>
}

let timeoutVal: NodeJS.Timeout = null

export const BusinessManagerScreen: FC<BusinessManagerScreenProps> = observer(
  ({navigation}) => {
    const {app} = useStores();
    const [listItem, setListItem] = useState<Array<IBusinessAccessModel>>([]);
    const [activeItem, setActiveItem] = useState<IBusinessAccessModel>();
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [resultVisible, setResultVisible] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');

    const loadData = async () => {
      try {
        const res = await app?.environment?.api?.getBusinessManagers()
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        setListItem(res?.items || [])
      } catch (e) {
        console.log(e)
      }
    }

    useFocusEffect(useCallback(() => {
      loadData()
    }, []))

    const onDone = async () => {
      setFirstName('')
      setEmail('')
      await loadData()
      setResultVisible(false)
    }

    const handleCloseResult = async () => {
      if (timeoutVal) clearTimeout(timeoutVal)
      onDone()
    }

    const showResult = () => {
      if (timeoutVal) clearTimeout(timeoutVal)
      setResultVisible(true)
      timeoutVal = setTimeout(handleCloseResult, 1000)
    }

    const handleRemovePress = () => {
      setMenuVisible(false)
      setConfirmVisible(true)
    }

    const toggleAddModalVisible = () => {
      setAddModalVisible(!addModalVisible)
    }
    const handleSendInvite = async () => {
      if (!email?.trim() || !firstName?.trim()) {
        AlertModalStore.alert('Error', 'Please enter require fields.')
        return
      }
      setAddModalVisible(false)
      try {
        const res = await app?.environment?.api?.createBusinessManager({email, firstName})
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        showResult()
      } catch (e) {
        console.log(e)
      }
    }

    const handleItemPress = (item: IBusinessAccessModel) => {
      setActiveItem(item)
      setMenuVisible(true)
    }
    const renderItem = ({item}: { item: IBusinessAccessModel }) => (
      <ManagerItem item={item} onPress={() => handleItemPress(item)}/>
    )
    const handleChangeStatus = async () => {
      if (!activeItem?.id) return
      try {
        setConfirmVisible(false)
        const status = activeItem?.status !== BUSINESS_ACCESS_STATUS.blocked.key
          ? BUSINESS_ACCESS_STATUS.blocked.key : BUSINESS_ACCESS_STATUS.accepted.key
        const res = await app?.environment?.api?.updateBusinessManager(activeItem?.id, {
          status, businessProfileId: app.userAccount?.businessProfile?.id
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        showResult()
      } catch (e) {
        console.log(e)
      }
    }

    return (
      <>
        <PureBottomModal onClose={() => navigation.goBack()}>
          <SafeAreaView style={styles.container}>
            <Header
              preset={'modalHeader'}
              header={() => (
                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                  <Text preset={'headerTitle'} text={'Business Managers'}/>
                  <SvgIcon
                    icon={'user-plus'} preset={'smallBlue'}
                    onPress={toggleAddModalVisible}/>
                </View>
              )}
              rightIcon={'times'} rightIconPreset={'smallBlue'} rightIconTheme={'regular'}
              onRightPress={() => navigation.goBack()}
            />
            <View style={styles.mainContainer}>
              <FlatList
                contentContainerStyle={styles.contentContainerStyle}
                data={listItem}
                renderItem={renderItem}
                keyExtractor={(item) => item.id.toString()}
                ListFooterComponent={<View style={{height: 200}}/>}
              />
            </View>
            <BottomContainer backgroundColor={'transparent'}>
              <View>
                <View style={{flexDirection: 'row', justifyContent: "space-between"}}>
                  <Button style={{flex: 1}} onPress={navigation.goBack}>
                    <Text preset={'whiteBold'}>Done</Text>
                  </Button>
                </View>
              </View>
            </BottomContainer>
            <BackdropModal contentStyle={{maxWidth: 576}} preset={'zeroPad'} visible={addModalVisible} onClose={toggleAddModalVisible}>
              <View style={{marginVertical: 20}}>
                <View style={{marginBottom: 20}}>
                  <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Text preset={"h3"} text={'Add Operator (Manager)'} color={color.palette.orange}/>
                    <SvgIcon icon={'times'} preset={'orange'} onPress={toggleAddModalVisible}/>
                  </View>
                </View>
                <View>
                  <View style={styles.input}>
                    <TextField
                      value={firstName} onChangeText={setFirstName}
                      preset={'primary'}
                      label={"First name"} labelPreset={"h4"}
                      placeholder={"First name"}
                    />
                  </View>
                  <View style={styles.input}>
                    <TextField
                      autoCapitalize='none'
                      value={email} onChangeText={setEmail}
                      preset={'primary'}
                      label={"Email"} labelPreset={"h4"}
                      placeholder={"Email"}
                    />
                  </View>
                  <Button text="Send Invitation" onPress={handleSendInvite}/>
                </View>
              </View>
            </BackdropModal>
            <BottomModal contentStyle={{maxWidth: 768}} visible={menuVisible} onClose={() => setMenuVisible(false)}>
              <View>
                <Button
                  style={styles.modalBtn}
                  disabled={false} preset={'brand'}
                  text={activeItem?.status !== BUSINESS_ACCESS_STATUS.blocked.key ? 'Block Access' : 'Unblock'}
                  onPress={handleRemovePress}
                />
                <Button
                  onPress={() => setMenuVisible(false)}
                  style={{marginTop: 8}}
                  disabled={false}
                  preset={'brandOutLine'}
                  text={'Cancel'}
                />
              </View>
            </BottomModal>
            <BackdropModal visible={confirmVisible} onClose={() => setConfirmVisible(false)}>
              <View>
                <View style={styles.modalContainer}>
                  <SvgIcon preset={'alertIcon'}/>
                  <View style={{paddingTop: 18, paddingBottom: 8}}>
                    <Text preset={'alertTitle'}>Are you sure?</Text>
                  </View>
                </View>
                <Button
                  onPress={handleChangeStatus}
                  style={styles.modalBtn}
                  disabled={false}
                  text={'Yes'}
                />
                <Button
                  onPress={() => setConfirmVisible(false)}
                  style={{marginTop: 8}}
                  disabled={false}
                  preset={'destructive'}
                  text={'Cancel'}
                />
              </View>
            </BackdropModal>
            <BackdropModal visible={resultVisible} onClose={onDone}>
              <View>
                <View style={styles.modalContainer}>
                  <SvgIcon icon={'check-circle'} preset={'alertIcon'}/>
                  <View style={{paddingTop: 18, paddingBottom: 8}}>
                    <Text preset={'alertTitle'}>Done</Text>
                  </View>
                </View>
              </View>
            </BackdropModal>
          </SafeAreaView>
        </PureBottomModal>
        <AlertModal/>
      </>
    )
  },
)

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.palette.white,
    flex: 1,
  },
  contentContainerStyle: {
    paddingHorizontal: 10,
  },
  input: {
    paddingBottom: 25,
  },
  mainContainer: {
    backgroundColor: color.palette.white,
    marginHorizontal: 10
  },
  modalBtn: {
    marginVertical: 8
  },
  modalContainer: {
    alignItems: "center", justifyContent: 'center', padding: 10,
  },
  statusBar: {
    backgroundColor: color.palette.white,
  }
});
