import React, { FC, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RouteProp } from "@react-navigation/native";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../models";
import { Button, Header, Text } from "../../../components";
import { PureBottomModal } from "../../../components/backdrop-modal/pure-bottom-modal";
import { AwSelectButton } from "../../../components/button/aw-select-button";
import { color } from "../../../theme";
import UserCard from "../../../components/card/user-card";
import { AlertModalStore } from "../../../models/app/alert-modal-store";
import { constants } from "../../../utils/constants";
import { AlertModal } from "../../../components/backdrop-modal/alert-modal";
import { NavigatorParamList } from "../../../navigators";
import { LoadingModal } from "../../../components/backdrop-modal/loading-modal";

export interface EditGroupChatScreenProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
  route: RouteProp<NavigatorParamList, 'editGroupChatScreen'>
}

export const EditGroupChatScreen: FC<EditGroupChatScreenProps> = observer(
  ({navigation, route}) => {
    // @ts-ignore
    const {app} = useStores();
    const [loading, setLoading] = useState(false)
    const listSelectedContact = app?.getListSelectedContact();
    const contacts = app?.listExistedContact?.toJSON();
    const listExistedContact = listSelectedContact?.filter(it => contacts?.includes(it?.id))
    const listNewContact = listSelectedContact?.filter(it => !contacts?.includes(it?.id))

    // const toggleSelectItem = (id: number) => {
    //   app.setListSelectedContact(listSelectedContact.map((item) => ({
    //     ...item, checked: item.id === id ? !item.checked : item.checked
    //   } as TypeUserModel)))
    // }

    const handleAddContact = async () => {
      if (loading) return
      try {
        setLoading(true)
        const isManager = app?.user?.role === constants.roles.MANAGER
        const _selectedContacts = app?.getListSelectedContact().filter(it => it.checked).map(it => it.id)
        const updateRes = await app.environment.api.updateGroupContact(
          route?.params?.groupId, {
            contactIds: _selectedContacts,
            businessProfileId: isManager
              ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          }
        )
        if (updateRes.kind !== 'ok') {
          AlertModalStore.alert('Error', updateRes.message)
          return
        }
        app.setListSelectedContact([])
        navigation.popToTop()
        navigation.navigate('businessGroupChatScreen', {
          groupId: route?.params?.groupId, groupName: route?.params?.groupName
        })
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    const onClose = () => {
      app.setListSelectedContact([])
      navigation.goBack()
    }

    const disableBtnDone = !(listNewContact?.length > 0)
    return (
      <>
        <PureBottomModal onClose={onClose}>
          <View style={{flex: 1}}>
            <Header
              preset={'modalHeader'}
              headerText={route?.params?.groupName} titlePreset={'headerTitle'}
              leftIcon={'arrow-left'} leftIconPreset={'normal'} onLeftPress={onClose}
            />
            <ScrollView showsVerticalScrollIndicator={false} style={{flex: 1}}>
              <View style={{paddingHorizontal: 20, marginTop: 0}}>
                <Text preset="body" style={{fontWeight: 'bold'}}>
                  Add Chat Member(s)
                </Text>
                <View style={{marginTop: 20}}>
                  <View style={{marginBottom: 20}}>
                    <AwSelectButton
                      onPress={() => {
                        navigation.navigate('addExistingContactScreen', {isNeedSaveContact: true, hideSelected: true})
                      }}
                      text={'Add Existing Contact(s)'}
                    />
                  </View>
                  <View style={{marginBottom: 20}}>
                    <AwSelectButton
                      onPress={() => navigation.navigate('addContactScreen')}
                      text={'Invite New Contact(s)'}
                    />
                  </View>
                </View>
                <View style={styles.memberTitle}>
                  <Text preset="bold">New Member(s)</Text>
                </View>
                {listNewContact.length > 0 ?
                  <View style={{marginTop: 20}}>
                    {listNewContact.map((item, index) => (
                      <UserCard
                        key={`${index}-${item.id}`}
                        isCheck={item.checked}
                        name={item.name}
                        avatar={item.avatar}
                      />
                    ))}
                  </View>
                  :
                  <Text preset="body" style={styles.noContact}>
                    No New Members have been added
                  </Text>}
                <View style={{height: 20}}/>
                <View style={styles.memberTitle}>
                  <Text preset="bold">Existing Member(s)</Text>
                </View>
                {listExistedContact?.length > 0 ?
                  <View style={{marginTop: 20}}>
                    {listExistedContact.map((item, index) => (
                      <UserCard
                        key={`${index}-${item.id}`}
                        isCheck={item.checked}
                        name={item.name}
                        avatar={item.avatar}
                      />
                    ))}
                  </View>
                  :
                  <Text preset="body" style={styles.noContact}>
                    No existing members
                  </Text>}
              </View>
            </ScrollView>
            <View style={{paddingTop: 12, paddingBottom: 20, paddingHorizontal: 20}}>
              <Button
                onPress={handleAddContact}
                disabled={disableBtnDone}
                preset={disableBtnDone ? 'disabled' : 'brand'}>
                <Text preset={'whiteBold'}>Save</Text>
              </Button>
            </View>
          </View>
        </PureBottomModal>
        <AlertModal/>
        <LoadingModal visible={loading}/>
      </>
    )
  },
)

const styles = StyleSheet.create({
  noContact: {color: color.palette.primary, fontStyle: 'italic', marginTop: 10},
  memberTitle: {justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center'},
})
