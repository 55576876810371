import {TouchableOpacity, View} from "react-native";
import {AvatarImage} from "../../../components/avatar-image/avatar-image";
import {Text} from "../../../components";
import React, {FC} from "react";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {CONTACT_STATUS, IBusinessContactModel} from "../../../models/app/contact";
import {ParamListBase} from "@react-navigation/native";
import {color} from "../../../theme";
import {SvgIcon} from "../../../components/icon/svg-icon";

const avatar = require('../../../../assets/images/default-photo.png')

export interface ContactItemProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  item: IBusinessContactModel
}

export const ContactItem: FC<ContactItemProps> = ({item, navigation}) => {
  const status = CONTACT_STATUS.pending.key === item?.status ? CONTACT_STATUS.pending.value : ''
  const contactColor = CONTACT_STATUS[item?.status]?.color || color.palette.orangeMid
  const fullName = [item?.user?.firstName, item?.user?.lastName].join(' ')
  return (
    <TouchableOpacity
      onPress={() => navigation.navigate('workerProfileScreen', {id: item?.user?.id})}
      style={{flexDirection: 'row', paddingBottom: 18, justifyContent: 'space-between', alignItems: 'flex-start'}}>
      <View style={{}}>
        <AvatarImage
          px={0}
          onPress={() => navigation.navigate("workerProfileScreen", {id: item?.user?.id})}
          source={
            item?.user?.avatar?.url
              ? {uri: item?.user?.avatar?.url}
              : avatar
          }/>
      </View>
      <View style={{flex: 1, paddingHorizontal: 10, paddingTop: 4}}>
        <Text style={{fontSize: 16, fontWeight: '600'}}>{
          !fullName?.trim() ? item?.user?.email : !!status ? fullName : item?.user?.firstName
        }</Text>
        <View style={{marginVertical: 4}}>
          {!!status ? (
            <Text preset={'bold'} style={{color: contactColor}}>{status}</Text>
          ) : (
            <Text style={{fontSize: 16, fontWeight: '600'}}>{item?.user?.lastName}</Text>
          )}
        </View>
      </View>
    </TouchableOpacity>
  )
}

export interface ContactSelectItemProps {
  item: IBusinessContactModel
  onItemPress: (item: IBusinessContactModel) => void
  checked: boolean
}

export const ContactSelectItem: FC<ContactSelectItemProps> = ({item, onItemPress, checked}) => {
  const pendingStatus = CONTACT_STATUS.pending.key === item?.status ? CONTACT_STATUS.pending.value : ''
  const contactColor = CONTACT_STATUS[item?.status]?.color || color.palette.orangeMid
  const fullName = [item?.user?.firstName, item?.user?.lastName].join(' ')
  return (
    <TouchableOpacity
      onPress={() => onItemPress(item)}
      style={{flexDirection: 'row', paddingBottom: 18, justifyContent: 'space-between', alignItems: 'stretch'}}>
      <View style={{}}>
        <AvatarImage
          px={0}
          source={
            item?.user?.avatar?.url
              ? {uri: item?.user?.avatar?.url}
              : avatar
          }/>
      </View>
      <View style={{flex: 1, paddingHorizontal: 10, paddingTop: 4}}>
        <Text style={{fontSize: 16, fontWeight: '600'}}>{
          !fullName?.trim() ? item?.user?.email : !!pendingStatus ? fullName : item?.user?.firstName
        }</Text>
        <View style={{marginVertical: 4}}>
          {!!pendingStatus ? (
            <Text preset={'bold'} style={{color: contactColor}}>{pendingStatus}</Text>
          ) : (
            <Text style={{fontSize: 16, fontWeight: '600'}}>{item?.user?.lastName}</Text>
          )}
        </View>
      </View>
      <View style={{justifyContent: 'center'}}>
        <SvgIcon
          icon={checked ? 'check-square' : 'square-regular'}
          theme={checked ? 'solid' : 'regular'} preset={'primary'}
        />
      </View>
    </TouchableOpacity>
  )
}
