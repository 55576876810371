import {Dimensions} from "react-native";

export type JsonValue =
  | boolean
  | number
  | string
  | null
  | JsonList
  | JsonMap
  | undefined;

export interface JsonMap {
  [key: string]: JsonValue;

  [index: number]: JsonValue;
}

export interface JsonList extends Array<JsonValue> {
}

export type UserTraits = JsonMap & {
  address?: {
    city?: string;
    country?: string;
    postalCode?: string;
    state?: string;
    street?: string;
  };
  age?: number;
  avatar?: string;
  birthday?: string;
  company?: {
    name?: string;
    id?: string | number;
    industry?: string;
    employee_count?: number;
    plan?: string;
  };
  createdAt?: string;
  description?: string;
  email?: string;
  firstName?: string;
  gender?: string;
  id?: string;
  lastName?: string;
  name?: string;
  phone?: string;
  title?: string;
  username?: string;
  website?: string;
};

export type GroupTraits = JsonMap & {
  address?: {
    city?: string;
    country?: string;
    postalCode?: string;
    state?: string;
    street?: string;
  };
  avatar?: string;
  createdAt?: string;
  description?: string;
  email?: string;
  employees?: string;
  id?: string;
  industry?: string;
  name?: string;
  phone?: string;
  website?: string;
  plan?: string;
};

declare global {
  const __DEV__ : boolean
  interface Window {
    analytics: {
      screen: (name: string, properties?: JsonMap) => void;
      track: (event: string, properties?: JsonMap) => void;
      identify: (userId?: string, userTraits?: UserTraits) => void;
      flush: () => Promise<void>;
      group: (groupId: string, groupTraits?: GroupTraits) => void;
      alias: (newUserId: string) => void;
      reset: (resetAnonymousId?: boolean) => void;
    }
  }
}

const analytics = window.analytics
export {
  analytics
}

export const  BREAKPOINT_MOBILE = 768;

export const MAX_WIDTH = 1440;

export const width = Dimensions.get("window").width
export const height = Dimensions.get("window").height

export const isDesktop =  width > BREAKPOINT_MOBILE;
