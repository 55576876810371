import { Dimensions, StyleSheet } from "react-native";
import { color } from "../../../theme";

const styles = StyleSheet.create({
  ShadowWrapper: {
    backgroundColor: color.palette.white,
    borderRadius: 8,
    flex: 1,

    marginHorizontal: 5,
    shadowColor: '#022964', shadowOpacity: 0.3,
    shadowOffset: {width: 1, height: 0}, shadowRadius: 4,
    elevation: 4
  },
  bgWrapperEmpty: {
    alignItems: 'center',
    borderRadius: 8,
    bottom: 0,
    display: "flex",
    justifyContent: 'center', textAlign: 'center',
    left: 0,
    paddingBottom: 11,
    paddingRight: 7,
    position: 'absolute',
    right: 0,
    top: 0
  },
  bgWrapperExpiry: {
    alignItems: 'flex-end',
    borderRadius: 8,
    bottom: 0,
    display: "flex",
    justifyContent: 'flex-end',
    left: 0,
    paddingBottom: 11,
    paddingRight: 7,
    position: 'absolute',
    right: 0,
    top: 0
  },
  bodyModal: {
    height: '100%',
    paddingHorizontal: 20
  },
  borderLine: {
    borderColor: color.palette.greyMedH,
    borderRadius: 8,
    borderWidth: 1,
    paddingHorizontal: 12,
    paddingVertical: 15,
  },
  cardImage: {
    borderColor: color.palette.greyAA, borderWidth: 3, borderRadius: 11,
    marginBottom: 12,
    marginTop: 25,
    overflow: 'hidden',
    width: '100%'
  },
  containerModal: {
    // flex: 1,
    height: 500
  },
  contentModal: {
    borderRadius: 0,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    paddingHorizontal: 10,
  },
  description: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 21,
    marginTop: 14,
  },
  expireDate: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 9
  },
  expiring: {
    backgroundColor: color.palette.red,
    borderRadius: 8,
    paddingHorizontal: 10,
    paddingVertical: 5
  },
  heightBtn: {
    marginTop: 17,
    paddingVertical: 15
  },
  imageCard: {
    alignItems: 'center',
    height: 130, marginRight: 15, width: 200,
  },
  infoCard: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 18
  },
  modalBtn: {
    marginVertical: 8
  },
  fabBtn: {
    position: "absolute",
    left: 10,
    bottom: 10,
    padding: 8,
    backgroundColor: color.palette.white,
    borderRadius: 10
  },
  modalContainer: {
    alignItems: "center", justifyContent: 'center', padding: 10,
  },
  otherCard: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 12,
    marginVertical: 10,
  },
  scrollCard: {
    marginBottom: 29,
    paddingHorizontal: 4,
    paddingVertical: 10
  },
  subModal: {
    fontSize: 14,
    fontWeight: '400',
    marginBottom: 15,
    textAlign: 'center',
  },
  takePhoto: {
    alignItems: 'center',
    height: 130,
    justifyContent: 'center',
    marginRight: 15,
    paddingHorizontal: 12
  },
  titleModal: {
    fontSize: 16,
    fontWeight: '600'
  },
  wrapperImage: {
    paddingHorizontal: 10,
    width: '100%',
  },
  viewUploadImageBorder: {
    borderColor: color.palette.greyAA, borderWidth: 3, borderRadius: 11,
    marginVertical: 20,
  },
  viewUploadImage: {
    width: Math.floor(Dimensions.get('window').width - 100),
    height: Math.floor(Dimensions.get('window').width / 2),
    flexDirection: 'column',
    backgroundColor: '#FFFFFF12',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8, overflow: 'hidden',
  },
  touchableOpacityResourcePath: {
    width: Math.floor(Dimensions.get('window').width - 100),
    height: Math.floor(Dimensions.get('window').width / 2),
  },
  cameraView: {
    width: Math.floor(Dimensions.get('window').width - 100),
    height: Math.floor(Dimensions.get('window').width / 2),
    flex: 1, justifyContent: 'center', alignItems: 'center',
  },
});

export default styles;
