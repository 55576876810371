import React, { FC, useCallback, useState } from "react"
import { View, SafeAreaView, ScrollView, RefreshControl } from "react-native"

import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { observer } from "mobx-react-lite"
import { Button, Header, Text, TextField } from "../../../components"
import { useStores } from "../../../models"
import { ParamListBase, RouteProp, useFocusEffect } from "@react-navigation/native"
import { AlertModalStore } from "../../../models/app/alert-modal-store";
import { AlertModal } from "../../../components/backdrop-modal/alert-modal";
import { constants } from "../../../utils/constants";
import { PureBottomModal } from "../../../components/backdrop-modal/pure-bottom-modal";
import { isAndroid, isIos } from "../../../utils/platform";
import LineCard from "../../../components/card/line-card"
import { NavigatorParamList } from "../../../navigators";
import { MainAppStore } from "../../../models/app/main-app-store";
import { IKvItemCheckModel } from "../../../models/app/kv-item";

export interface GroupNewMessageResponseScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'groupNewMessageScreen'>
}

export const GroupNewMessageResponseScreen: FC<GroupNewMessageResponseScreenProps> = observer(({navigation}) => {
  const {app} = useStores()
  const isManager = app?.user?.role === constants.roles.MANAGER
  const [refreshing, setRefreshing] = useState(false)
  const [currentHeight2, setCurrentHeight2] = useState(0)

  const [responseValue, setResponseValue] = useState("")
  const [responsesOptions, setResponsesOptions] = useState<Array<IKvItemCheckModel>>([])

  const loadPredefinedMessages = async () => {
    try {
      setRefreshing(true)
      const res = await app.environment.api.getWorkerResponseMessage({
        'filters[businessProfile][id]': isManager ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
      })

      if (res.kind !== "ok") {
        AlertModalStore.alert("Error", res.message)
        return
      }
      setResponsesOptions(res.items?.map(item => ({
        ...item,
        checked: MainAppStore.responsesOptions?.find(rs => rs?.id === item?.id)?.checked || false
      })))
    } catch (e) {
      console.log(e)
    } finally {
      setRefreshing(false)
    }
  }

  useFocusEffect(
    useCallback(() => {
      loadPredefinedMessages()
    }, []),
  )

  const onSelectResponse = (id) => {
    setResponsesOptions(responsesOptions?.map((it) => (
      {...it, checked: id === it?.id ? !it.checked : it.checked}
    )))
  }

  const addResponse = async () => {
    try {
      if (refreshing) return
      if (!responseValue) return
      setRefreshing(true)
      const res = await app.environment.api.createWorkerResponseMessage({
        name: responseValue,
        businessProfile: isManager ? app?.user?.businessProfile : app?.userAccount?.businessProfile,
      })
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res?.message || 'Can not Add')
        return
      }
      setResponsesOptions([{name: responseValue, id: res.item?.id, checked: true}, ...responsesOptions])
      setResponseValue("")
    } catch (e) {
      console.log(e)
    } finally {
      setRefreshing(false)
    }
  }

  const onDone = () => {
    MainAppStore.setResponsesOptions(responsesOptions?.filter(item => item?.checked))
    navigation.goBack()
  }

  const isActive2 = responsesOptions?.filter(item => item?.checked)?.length > 0
  return (
    <>
      <PureBottomModal onClose={() => navigation.goBack()}>
        <SafeAreaView style={{flex: 1}}>
          <Header
            leftIcon="arrow-left"
            leftIconPreset='normal'
            onLeftPress={() => navigation.goBack()}
            preset={"transparent"}
            titlePreset={"headerTitle"}
            headerText={"Response Options"}
          />
          <View style={{flex: 1, justifyContent: "space-between", paddingHorizontal: 10, paddingTop: 20}}>
            <View style={{paddingTop: 10, paddingBottom: 20}}>
              <TextField
                preset={"brand"}
                value={responseValue}
                onChangeText={(e) => setResponseValue(e)}
                label={"Create New Response Option"}
                labelPreset={"bold"}
                rightOuterIcon={"plus"}
                rightOuterIconPreset={responseValue.trim().length > 0 ? "orange" : "disable"}
                rightOuterIconPress={() => addResponse()}
                placeholder={"Enter Response Option"}
              />
            </View>
            <Text preset={"bold"} style={{paddingBottom: 16}}>
              Select Response
            </Text>
            <View
              onLayout={event => setCurrentHeight2(event.nativeEvent.layout?.height)}
              style={isAndroid && currentHeight2 > 0 ? {height: currentHeight2} : {flex: 1}}>
              <ScrollView
                refreshControl={<RefreshControl refreshing={refreshing} onRefresh={loadPredefinedMessages}/>}
                showsVerticalScrollIndicator={false}>
                {responsesOptions.map((i) => {
                  return (
                    <LineCard
                      key={i.id}
                      name={i.name}
                      isCheck={i.checked}
                      onPress={() => onSelectResponse(i?.id)}/>
                  )
                })}
              </ScrollView>
            </View>
            <View style={{paddingTop: 12, marginBottom: isIos ? 0 : 30}}>
              <Button
                preset={isActive2 ? 'brand' : 'disabled'} disabled={refreshing || !isActive2}
                onPress={onDone}
                text={"Done"}
              />
            </View>
          </View>
        </SafeAreaView>
      </PureBottomModal>
      <AlertModal/>
    </>
  )
})
