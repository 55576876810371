import React, { FC, useCallback, useEffect, useState } from "react"
import {
  View,
  SafeAreaView, ScrollView,
} from "react-native"
import { observer } from "mobx-react-lite"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { Header, GradientBackground, Text, TextField, Button } from "../../../../components"
import { color } from "../../../../theme"
import { SelectTextField } from "../../../../components/text-field/select-text-field";
import { IKvItemCheckModel, IKvItemModel, KvItemPath } from "../../../../models/app/kv-item";
import { useStores } from "../../../../models";
import { searchCompare, searchIgnoreCase } from "../../../../utils/utils"
import { AlertModalStore } from "../../../../models/app/alert-modal-store";
import { PureBottomModal } from "../../../../components/backdrop-modal/pure-bottom-modal";
import { isAndroid } from "../../../../utils/platform";
import { debounce } from "../../../../utils/debounce";
import { constants } from "../../../../utils/constants";
import { NavigatorParamList } from "../../../../navigators";
import { IKvItemBusinessModel } from "../../../../models/app/business-profile";
import { SvgIcon } from "../../../../components/icon/svg-icon";
import { MainAppStore } from "../../../../models/app/main-app-store";

export interface UploadEditDescriptionScreenProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
}

export const UploadEditDescriptionScreen: FC<UploadEditDescriptionScreenProps> = observer(
  ({navigation}) => {
    const {app} = useStores();
    const isManager = app?.user?.role === constants.roles.MANAGER
    const businessProfileId = isManager ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState("")
    const [data, setData] = useState<Array<IKvItemCheckModel>>([]);
    const [customIds, setCustomIds] = useState<Array<number>>([]);
    const [selectedItem, setSelectedItem] = useState<IKvItemModel>();

    const loadData = async (text?: string, selectedIds?: number[]) => {
      if (!text || text?.length < 3) return
      try {
        text = text?.trim();
        let userItems: IKvItemBusinessModel[] = []
        if (businessProfileId) {
          const userRes = await app.environment.api.getKvItemBusinessList(KvItemPath.role, {
            ...(text ? {'filters[name][$containsi]': text} : null),
            'filters[businessProfile][id]': businessProfileId,
            businessProfileId: businessProfileId,
          })
          if (userRes.kind !== 'ok') return
          userItems = userRes?.items
          setCustomIds(userRes?.items?.map(it => it.id))
        }
        const sysRes = await app.environment.api.getKvItemBusinessList(KvItemPath.role, {
          ...(text ? {'filters[name][$containsi]': text} : null),
        })
        if (sysRes.kind === 'ok') {
          setData(
            [...userItems, ...sysRes?.items].map(it => ({
              ...it, checked: !!selectedIds?.includes(it?.id)
            }))
          )
        }
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }
    useEffect(() => {
      loadData()
    }, [])

    const toggleSelectItem = (id) => {
      let _item = null
      const newData = data.map((item) => {
        if (item.id === id) _item = item
        return ({
          ...item, checked: item.id === id ? !item.checked : false
        })
      })
      setData(newData)
      setSelectedItem(selectedItem?.id === id ? null : _item)
    }

    const onDone = () => {
      MainAppStore.setUploadSelectedDescription(selectedItem)
      navigation.goBack()
    }

    const handleAddRole = async (newRole: string) => {
      if (!newRole || loading) return
      setLoading(true)
      try {
        const res = await app.environment.api.editKvItemBusiness(KvItemPath.role, {
          name: newRole,
          businessProfile: isManager ? app?.user?.businessProfile : app?.userAccount?.businessProfile,
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        setSearch('')
        setSelectedItem(res.item)
        await loadData(newRole, [res.item?.id])
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    const delayLoadData = useCallback(debounce(loadData, 600), []);
    const handleChangeSearch = (text: string) => {
      setSearch(text)
      setLoading(true)
      // @ts-ignore
      delayLoadData(text)
    }

    const handleAddOther = () => {
      handleAddRole(search)
    }

    const renderCustomItem = (item: IKvItemCheckModel) => {
      return (
        <SelectTextField
          key={String(item.id)}
          value={item.name} onPress={() => toggleSelectItem(item.id)}
          style={{marginBottom: 10}}
          rightIcon={item?.checked ? 'check-circle' : 'circle'}
          rightIconTheme={item?.checked ? 'solid' : 'regular'}
          rightIconPreset={item?.checked ? 'primary' : 'default'}
          preset={item?.checked ? 'customItemSelected' : 'transparent'}
        />
      )
    }
    const renderSystemItem = (item: IKvItemCheckModel) => {
      return (
        <SelectTextField
          key={String(item.id)}
          value={item.name} onPress={() => toggleSelectItem(item.id)}
          style={{marginBottom: 10}}
          rightIcon={item?.checked ? 'check-circle' : 'circle'}
          rightIconTheme={item?.checked ? 'solid' : 'regular'}
          rightIconPreset={item?.checked ? 'primary' : 'default'}
          preset={item?.checked ? 'itemSelected' : 'transparent'}
        />
      )
    }

    const _systemData = data.filter(item => !customIds.includes(item.id) && searchIgnoreCase(item?.name, search))
    const _customData = data.filter(item => customIds.includes(item.id) && searchIgnoreCase(item?.name, search))
    const showBtnAdd = !loading && search?.length >= 3 && data.find(item => searchCompare(item?.name, search)) === undefined;
    const showBtnDone = selectedItem?.id

    return (
      <PureBottomModal onClose={() => navigation.goBack()}>
        <SafeAreaView style={{flex: 1}}>
          <GradientBackground
            colors={[color.palette.gradientStart, color.palette.gradientStop]}
          />
          <Header
            preset={"modalHeader"}
            titlePreset={"headerTitle"}
            headerText={'Description'}
            rightIcon={"times"}
            rightIconPreset={"smallBlue"}
            onRightPress={() => navigation.goBack()}
          />
          <View style={{paddingHorizontal: 20, flex: 1}}>
            <View style={{flex: 1}}>
              <View style={{}}>
                <View style={{marginBottom: 8}}>
                  <TextField
                    value={search} onChangeText={handleChangeSearch}
                    labelPreset={"default"}
                    rightIcon={"times-circle-fill"} rightIconPreset={"default"}
                    rightIconPress={() => handleChangeSearch('')}
                    rightOuterIcon={
                      <View style={{justifyContent: 'center'}}>
                        <View style={{
                          justifyContent: 'center', alignItems: 'center',
                          marginLeft: 10, borderRadius: 36, width: 36, height: 36,
                          borderWidth: 1, borderColor: showBtnAdd ? color.palette.orange : color.palette.greyLink
                        }}>
                          <SvgIcon
                            icon={'plus'} preset={showBtnAdd ? 'orange' : 'disable'}
                            onPress={handleAddOther}
                          />
                        </View>
                      </View>
                    }
                  />
                </View>
              </View>
              {data.length > 0 ? (
                <View style={{marginTop: 24, flex: 1}}>
                  <ScrollView showsVerticalScrollIndicator={false} style={{flex: 1}}>
                    <View>
                      {_customData && _customData.length > 0 ? (
                        <View>
                          <View style={{marginHorizontal: 16}}>
                            <Text preset={"mediumBlue"}>Other</Text>
                          </View>
                          <View style={{height: 10}}/>
                          {_customData.map(renderCustomItem)}
                        </View>
                      ) : null}
                      {data ? (
                        <View>
                          <View style={{marginHorizontal: 16, marginBottom: 10}}>
                            <Text preset={"mediumBlue"}>Results</Text>
                          </View>
                          {_systemData.map(renderSystemItem)}
                        </View>
                      ) : null}
                    </View>
                  </ScrollView>
                </View>
              ) : null}
            </View>
            <View style={{display: showBtnDone ? 'flex' : 'none', paddingTop: 10, marginBottom: isAndroid ? 30 : 5}}>
              <Button preset={"brand"} onPress={onDone} text={'Done'}/>
            </View>
          </View>
        </SafeAreaView>
      </PureBottomModal>
    )
  },
)
