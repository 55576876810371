import * as React from "react"
import {ImageSourcePropType, TouchableOpacity, TouchableOpacityProps, View} from "react-native";
import {Text} from "../text/text";
import {SvgIcon} from "../icon/svg-icon";
import {AvatarImage} from "../avatar-image/avatar-image";
import AvatarDefault from "../avatar-image/AvatarDefault";


const defaultPhoto = require("../../../assets/images/default-photo.png")
const defaultLogo = require("../../../assets/images/default-company.png")

export interface UserMessageProps extends TouchableOpacityProps {
  onPress?: (e) => void
  avatar?: string
  title?: string
  subTitle?: string
  time?: string
  onLogoPress?: () => void,
  avatarSource?: ImageSourcePropType
  isWorker?: boolean
  dot?: boolean
}

export const UserMessage = (props: UserMessageProps) => {
  const {title, subTitle, time, onPress, onLogoPress, avatar, isWorker, dot, ...rest} = props
  return (
    <TouchableOpacity
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        height: 55,
        marginBottom: 10
      }}
      onPress={onPress}
      {...rest}
    >
      <View style={{}}>
        {!!avatar ?  <AvatarImage
            onPress={onLogoPress} dot={dot}
            source={avatar ? {uri: avatar} : isWorker ? defaultPhoto : defaultLogo}/>
          :
          <AvatarDefault
            onPress={onLogoPress} dot={dot}
            name={title}/>}
      </View>
      <View style={{flex: 1, flexDirection: 'column', marginLeft: 5}}>
        <View>
          <Text preset={'mediumBold'}>{title}</Text>
        </View>
        {!!subTitle &&  <View style={{marginTop: 4}}>
          <Text preset={'userMessageSubtitle'} numberOfLines={1}>{subTitle}</Text>
        </View>}
      </View>
      <View style={{height: '100%', justifyContent: 'center', alignItems: 'center'}}>
        <Text preset={'body2Grey'}>{time}</Text>
      </View>
      <SvgIcon icon='chevron-right'/>
    </TouchableOpacity>
  )
}
