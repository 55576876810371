import React, { FC, useState } from 'react';
import { View, FlatList, StyleSheet, SafeAreaView, TouchableOpacity } from 'react-native';

import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { observer } from "mobx-react-lite";
import { NavigatorParamList } from "../../../navigators";
import { Button, Header, Text } from "../../../components";
import { PureBottomModal } from "../../../components/backdrop-modal/pure-bottom-modal";
import { SvgIcon } from "../../../components/icon/svg-icon";
import { BottomContainer } from "../../../components/bottom-container/bottom-container";
import { color } from "../../../theme";
import { AlertModal } from "../../../components/backdrop-modal/alert-modal";
import { MainAppStore } from "../../../models/app/main-app-store";
import { IWorkerWalletItemShareModel } from "../../../models/app/advanced-skill";

export interface WalletItemShareScreenProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
}

export const WalletItemShareScreen: FC<WalletItemShareScreenProps> = observer(
  ({navigation}) => {
    const listItem = MainAppStore.workerWalletItemShare.toJSON()
    const [selectedItems, setSelectedItems] = useState<Array<IWorkerWalletItemShareModel>>([]);

    const toggleSelectItem = (item: IWorkerWalletItemShareModel) => {
      const _item = selectedItems?.find(it => it?.id === item?.id)
      setSelectedItems(_item
        ? selectedItems?.filter(it => it?.id !== item?.id)
        : [...selectedItems, ({...item, urls: [...item?.urls?.toJSON()]} as IWorkerWalletItemShareModel)]
      )
    }

    const handleNext = () => {
      MainAppStore.setWorkerWalletItemShareSelected([...selectedItems])
      navigation.navigate('walletItemShareFinalScreen')
    }

    // const renderItem = ({item}) => <ContactItem item={item} navigation={navigation}/>
    const renderItem = ({item}: { item: IWorkerWalletItemShareModel }) => {
      const checked = !!selectedItems?.find(_item => (_item?.id === item?.id && _item?.key === item?.key))
      return (
        <TouchableOpacity onPress={() => toggleSelectItem(item)}>
          <View style={{
            marginBottom: 20,
            flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'
          }}>
            <View style={{flex: 1}}>
              <Text preset="label">{[item?.type, item?.description].filter(it => !!it).join(' - ')}</Text>
            </View>
            <SvgIcon
              icon={checked ? 'check-square' : 'square-regular'}
              theme={checked ? 'solid' : 'regular'} preset={'primary'}
            />
          </View>
        </TouchableOpacity>
      )
    }

    const isValid = selectedItems?.length > 0
    return (
      <>
        <PureBottomModal onClose={() => navigation.goBack()}>
          <SafeAreaView style={styles.container}>
            <Header
              preset={'modalHeader'}
              headerText={'Select Items'}
              titlePreset={'headerTitle'}
              rightIcon={'times'} rightIconPreset={'smallBlue'} onRightPress={() => navigation.goBack()}
            />
            <View style={{paddingHorizontal: 10}}>
              <TouchableOpacity>
                <View style={{
                  paddingHorizontal: 10, marginBottom: 20,
                  flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'
                }}>
                  <Text preset="textName" style={{fontWeight: '700'}}>Wallet Items</Text>
                </View>
              </TouchableOpacity>
              <FlatList
                showsVerticalScrollIndicator={false}
                contentContainerStyle={styles.contentContainerStyle}
                data={listItem}
                renderItem={renderItem}
                keyExtractor={(item) => `${item?.key}-${item?.id}`}
                ListFooterComponent={<View style={{height: 200}}/>}
              />
            </View>
            <BottomContainer backgroundColor={color.palette.grey}>
              <Button onPress={handleNext} disabled={!isValid} preset={isValid ? 'brand' : 'disabled'}>
                <Text preset={'whiteBold'}>Next</Text>
              </Button>
            </BottomContainer>
          </SafeAreaView>
        </PureBottomModal>
        <AlertModal/>
      </>
    )
  },
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainerStyle: {
    paddingHorizontal: 10,
  },
  modalBtn: {
    marginVertical: 8
  },
});
