import {Instance, types} from "mobx-state-tree"

export const WorkerSkillCategory = {
  industries: 'industries',
  experiences: 'experiences',
  skills: 'skills',
  qualifications: 'qualifications',
  workerRoles: 'worker-roles',
}

export const KvItemPath = {
  industry: 'industries',
  location: 'locations',
  role: 'worker-roles',
  experience: 'experiences',
  skill: 'skills',
  qualification: 'qualifications',
}

export const KvItemModel = types.model({
  id: types.optional(types.number, 0),
  name: types.optional(types.string, ''),
})
export type IKvItemModel = Instance<typeof KvItemModel>
export const convertKvItemModel = (raw): IKvItemModel => ({
  id: raw?.id || 0,
  name: raw?.name || '',
})

export const KvItemFileModel = types.model({
  id: types.optional(types.number, 0),
  name: types.optional(types.string, ''),
  url: types.maybeNull(types.string),
})
export type IKvItemFileModel = Instance<typeof KvItemFileModel>
export const convertKvItemFileModel = (raw): IKvItemFileModel => ({
  id: raw?.id || 0,
  name: raw?.name || '',
  url: raw?.url || '',
})
export const DefaultKvItemFile = {id: 0, name: '', url: ''}

export const UvItemModel = types.model({
  id: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
})
export type IUvItemModel = Instance<typeof UvItemModel>
export const convertUvItemModel = (raw): IUvItemModel => ({
  id: raw?.id || '',
  name: raw?.name || '',
})

export const KnItemModel = types.model({
  id: types.optional(types.number, 0),
  key: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
})
export type IKnItemModel = Instance<typeof KnItemModel>
export const convertKnItemModel = (raw): IKnItemModel => ({
  id: raw?.id || 0,
  key: raw?.key || '',
  name: raw?.name || '',
})

export const KvItemCheckModel = types.model({
  id: types.optional(types.number, 0),
  name: types.optional(types.string, ''),
  checked: types.optional(types.boolean, false),
})
export type IKvItemCheckModel = Instance<typeof KvItemCheckModel>

export const UserItemModel = types.model({
  id: types.optional(types.number, 0),
  name: types.optional(types.string, ''),
  checked: types.optional(types.boolean, false),
  avatar: types.optional(types.string, ''),
})
export type TypeUserModel = Instance<typeof UserItemModel>

export const KnParentItemModel = types.model({
  id: types.optional(types.number, 0),
  key: types.optional(types.string, ''),
  parentId: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
})
export type IKnParentItemModel = Instance<typeof KnParentItemModel>
export const convertKnParentItemModel = (raw): IKnParentItemModel => ({
  id: raw?.id || 0,
  key: raw?.key || '',
  parentId: raw?.parentId || '',
  name: raw?.name || '',
})
