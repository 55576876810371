import {cast, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {BusinessGroupModel, IBusinessGroupModel} from "./contact";
import {
  IKnParentItemModel, IKvItemCheckModel, IKvItemModel, KnParentItemModel, KvItemCheckModel, KvItemModel
} from "./kv-item";
import {
  IWorkerWalletItemShareModel,
  IWorkerWalletItemModel,
  WorkerWalletItemShareModel,
  WorkerWalletItemModel
} from "./advanced-skill";

export const MainAppStore = types
  .model("MainAppStore")
  .props({
    group: types.optional(BusinessGroupModel, {}),
    selectTypeSection: types.optional(KnParentItemModel, {}),
    uploadSelectedDescription: types.optional(KvItemModel, {}),
    workerWalletItem: types.optional(WorkerWalletItemModel, {}),
    workerWalletItemShare: types.optional(types.array(WorkerWalletItemShareModel), []),
    workerWalletItemShareSelected: types.optional(types.array(WorkerWalletItemShareModel), []),
    chatGroupDeleteList: types.optional(types.array(BusinessGroupModel), []),
    responsesOptions: types.optional(types.array(KvItemCheckModel), []),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setGroup: (group?: IBusinessGroupModel) => {
      self.group = group;
    },
    setSelectTypeSection: (selectTypeSection?: IKnParentItemModel) => {
      self.selectTypeSection = selectTypeSection;
    },
    setUploadSelectedDescription: (uploadSelectedDescription?: IKvItemModel) => {
      self.uploadSelectedDescription = uploadSelectedDescription;
    },
    setWorkerWalletItem: (workerWalletItem?: IWorkerWalletItemModel) => {
      self.workerWalletItem = workerWalletItem;
    },
    setWorkerWalletItemShare: (workerWalletItemShare?: IWorkerWalletItemShareModel[]) => {
      self.workerWalletItemShare = cast(workerWalletItemShare);
    },
    setWorkerWalletItemShareSelected: (workerWalletItemShareSelected?: IWorkerWalletItemShareModel[]) => {
      self.workerWalletItemShareSelected = cast(workerWalletItemShareSelected);
    },
    setChatGroupDeleteList: (chatGroupDeleteList?: IBusinessGroupModel[]) => {
      self.chatGroupDeleteList = cast(chatGroupDeleteList);
    },
    setResponsesOptions: (responsesOptions?: IKvItemCheckModel[]) => {
      self.responsesOptions = cast(responsesOptions);
    },
  }))
  .views((self) => ({}))
  .create({})
