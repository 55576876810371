import React from "react"
import { StyleSheet, TouchableOpacity, View } from "react-native"
import { Text } from "../../../components"
import { color } from "../../../theme"
import { SvgIcon } from "../../../components/icon/svg-icon"
interface Props{
  title: string;
  nameIcon: string;
  des1: string;
  des2?: string;
  onClick?: () => void;
  txtBottom: string;
}

const Intro = ({title, nameIcon, des1, des2, onClick, txtBottom}: Props) => {
  return(
    <View style={styles.container}>
      <SvgIcon icon={nameIcon} preset='intro'/>
      <Text preset={'h1'} style={styles.title}>
        {title}
      </Text>
      <Text preset={'h1'} style={styles.divider}>
        -------
      </Text>
      <Text preset={'body'} style={styles.txtDes}>
        {des1}
      </Text>
      {!!des2 &&  <Text preset={'body'} style={[styles.txtDes, {marginTop: 15}]}>
        {des2}
      </Text>}
      <Text preset={'h2'} style={[styles.txtDes, {fontWeight: '600', marginTop: 40}]}>
        {txtBottom}
      </Text>
      <TouchableOpacity activeOpacity={0.9} onPress={onClick} style={styles.btn}>
        <Text preset={'body'} style={[styles.txtDes, {fontWeight: '600', color: '#fff'}]}>
          Sign up, Free
        </Text>
      </TouchableOpacity>
    </View>
  )
};

export default Intro;

const styles = StyleSheet.create({
  container:{
    paddingVertical: 40,
    paddingHorizontal: 20,
    borderRadius: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,
    elevation: 10,
    backgroundColor: '#fff',
    marginTop: 10,
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    color: color.palette.primary,
    fontWeight: '600',
    marginTop: 36,
    textAlign: 'center'
  },
  divider:{
    color: color.palette.secondary,
    fontWeight: '900',
    marginVertical: 15
  },
  txtDes:{
    color: color.palette.dark,
    fontWeight: '400',
    textAlign: 'center'
  },
  btn:{
    borderRadius: 8,
    backgroundColor: color.palette.orange,
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    width: '100%',
    marginTop: 15
  }
});
