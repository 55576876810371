import React, {FC, useEffect, useState} from "react"
import {Dimensions, SafeAreaView, ScrollView, View, ViewStyle} from "react-native"
import {Button, Header, TextField} from "../../../components"
import {color} from "../../../theme"
import {IKvItemModel} from "../../../models/app/kv-item";
import {useStores} from "../../../models";
import {SelectTextField} from "../../../components/text-field/select-text-field";
import {observer} from "mobx-react-lite";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {KvQualificationPath} from "../../../models/app/advanced-skill";
import {WorkerSkillStore} from "../../../models/app/worker-skill-store";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import {ParamListBase, RouteProp} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {NavigatorParamList} from "../../../navigators";
import {useFilePicker} from "use-file-picker";
import {PickerSelectField} from "../../../components/picker-select/picker-select";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";

const windowHeight = Dimensions.get('window').height;
const MONTHS: string[] = Array(12).fill(1)
  .map((value, idx) => String(value + idx).padStart(2, '0'))

const MAX_YEAR = new Date().getFullYear(), MIN_YEAR = 1960
const YEARS: string[] = Array(MAX_YEAR - MIN_YEAR + 1).fill(MAX_YEAR)
  .map((value, idx) => String(value - idx))

export interface WorkerAddQualificationScreenProps {
  route: RouteProp<NavigatorParamList, 'workerAddQualificationScreen'>
  navigation: NativeStackNavigationProp<ParamListBase>
}

export const WorkerAddQualificationScreen: FC<WorkerAddQualificationScreenProps> = observer((
  {route, navigation}
) => {
  const {app} = useStores();
  const originId = route.params?.originId;
  const qualificationEducationLevel: IKvItemModel = WorkerSkillStore.qualificationEducationLevel;
  const [loading, setLoading] = useState(false)

  const [courseName, setCourseName] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [organisation, setOrganisation] = useState('');
  const [document, setDocument] = useState<IKvItemModel>({id: 0, name: null})
  const [openDocFileSelector, docFileResult] = useFilePicker({
    accept: ['.pdf', '.doc', '.docx', 'image/*'],
    maxFileSize: 2,
  });
  const myDoc: File = docFileResult.plainFiles?.length > 0 ? docFileResult.plainFiles[0] : null

  const loadData = async () => {
    try {
      if (originId) {
        const res = await app.environment.api.getUserQualificationDetail(originId)
        if (res.kind === 'ok') {
          WorkerSkillStore.setQualificationEducationLevel(res.item.qualificationEducationLevel)
          setCourseName(res.item.courseName)
        } else {
          AlertModalStore.alert('Error', res.message)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    handleUploadFile()
  }, [myDoc])

  const handleUploadFile = async () => {
    setLoading(true);
    try {
      const res = await app.environment.api.uploadFile(myDoc)
      if (res.kind === 'ok') {
        setDocument({id: res.file.id, name: res.file.name})
      } else {
        AlertModalStore.alert('Error', 'Can not upload file')
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = async () => {
    if (!originId && (
      !qualificationEducationLevel?.id || !courseName || !organisation || (month && !parseInt(month)) || (year && !parseInt(year))
    )) {
      AlertModalStore.alert('Error', 'Please enter require fields.')
      return
    }
    let qualificationId = originId
    if (!originId) {
      const res = await app.environment.api.editUserQualification({
        courseName: courseName,
        qualificationEducationLevel: qualificationEducationLevel?.id,
      })
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message)
        return
      }
      qualificationId = res.item.id
    }
    const workerQualificationRes = await app.environment.api.generalApiCreate(KvQualificationPath.workerQualifications, {
      qualification: qualificationId,
      organisation: organisation,
      ...(document.id ? {document: document.id} : null),
      ...(month ? {month: parseInt(month)} : null),
      ...(year ? {year: parseInt(year)} : null),
    })
    if (workerQualificationRes.kind !== 'ok') {
      AlertModalStore.alert('Error', workerQualificationRes.message)
      return
    }
    await app.environment.api.updateWorkerProfileData(app?.userAccount?.workerProfile?.id, {
      qualifications: [...app?.userAccount?.workerProfile?.qualifications?.map(it => it.id), qualificationId],
    })
    onClose()
    WorkerSkillStore.setQualificationEducationLevel(null)
    setCourseName('')
    setMonth('')
    setYear('')
    setOrganisation('')
    setDocument({id: 0, name: null})
  }

  const onClose = () => navigation.goBack()
  const onSelectEducationLevel = () => navigation.navigate('workerQualificationEduScreen')
  return (
    <>
      <PureBottomModal onClose={onClose}>
        <SafeAreaView style={{flex: 1}}>
          <Header
            preset={"modalHeader"}
            titlePreset={"headerTitle"}
            headerText={'Qualifications'}
            rightIcon={"times"}
            rightIconPreset={"smallBlue"}
            onRightPress={onClose}
          />
          <ScrollView style={{flex: 1}} showsVerticalScrollIndicator={false}>
            <View style={{paddingHorizontal: 20, paddingBottom: 50}}>
              <View style={INPUT}>
                <SelectTextField
                  preset={originId ? 'primaryDisabled' : 'primary'}
                  label={"*Education Level"} labelPreset={"h4"}
                  value={qualificationEducationLevel?.name} onPress={originId ? null : onSelectEducationLevel}
                />
              </View>
              <View style={INPUT}>
                <TextField
                  preset={originId ? 'primaryDisabled' : 'primary'} editable={!originId}
                  label={"*Course Name"} labelPreset={"h4"}
                  value={courseName} onChangeText={setCourseName}
                />
              </View>
              <View style={{flexDirection: "row", paddingBottom: 15, zIndex: 9}}>
                <View style={{flex: 1, paddingRight: 15}}>
                  <PickerSelectField
                    onValueChange={setMonth}
                    items={MONTHS.map(item => ({key: item, value: item, label: item}))}
                    labelPreset={"h4"} label={"Month"}
                    placeholder="..."
                    rightIcon={'chevron-down'}
                  />
                </View>
                <View style={{flex: 1}}>
                  <PickerSelectField
                    onValueChange={setYear}
                    items={YEARS.map(item => ({key: item, value: item, label: item}))}
                    labelPreset={"h4"} label={"Year"}
                    placeholder="..."
                    rightIcon={'chevron-down'}
                  />
                </View>
              </View>
              <View style={INPUT}>
                <TextField
                  preset={'primary'}
                  labelPreset={"h4"} label={"Organisation"}
                  placeholder={"..."}
                  value={organisation}
                  onChangeText={setOrganisation}
                />
              </View>
              <View style={INPUT}>
                <SelectTextField
                  value={document?.name || ''} onPress={openDocFileSelector}
                  numberOfLines={1}
                  preset={'primary'}
                  rightIcon={'upload'} rightIconTheme={'solid'} rightIconPreset={'primary'}
                  labelPreset={"h4"} label={'Upload Document'}
                  placeholder={"Select"}/>
              </View>
              <Button text="Submit" disabled={loading} preset={loading ? 'disabled' : 'brand'} onPress={handleSubmit}/>
            </View>
          </ScrollView>
        </SafeAreaView>
      </PureBottomModal>
      <AlertModal/>
    </>
  )
})

export const CONTAINER: ViewStyle = {
  paddingBottom: 10,
  backgroundColor: color.palette.white,
  maxHeight: windowHeight * 0.92,
  minHeight: 600 < windowHeight * 0.80 ? 600 : windowHeight * 0.80,
}
export const INPUT: ViewStyle = {
  paddingBottom: 25,
}
