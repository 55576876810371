import React from 'react';
import { Modal, StyleSheet, TouchableOpacity, View } from "react-native"
import { AutoImage as Image } from "../auto-image/auto-image"
import { SvgIcon } from "../icon/svg-icon"

export interface Props {
  visible: boolean;
  onClose?: () => void;
  url?: string;
}

export const ImageModal = ({ visible, onClose, url }: Props )=> {

  if (!visible) return null
  return (
    <Modal
      animationType='fade'
      transparent={true}
      visible={visible}
      onRequestClose={onClose}
    >
      <View style={styles.modalContainer}>
        <View style={styles.modalFullWidth}>
          <TouchableOpacity style={styles.btnClose} onPress={onClose}>
            <SvgIcon icon="times" preset="lightMd"/>
          </TouchableOpacity>
          <Image style={styles.imgStyle} source={{uri: url}}/>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modalContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(0,0,0,0.83)',
  },
  modalFullWidth: {
    width: '100%',
    zIndex: 99,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  imgStyle:{
    width: 500,
    height: 500,
  },
  btnClose:{
    position: 'absolute',
    right: 100,
    top: 50,
  }
});
