import {cast, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {IKvItemModel, KvItemModel} from "./kv-item";
import {IJobRoleAdModel, JobRoleAdModel} from "./job-role-ad";
import { FileUploadModel, IFileUploadModel } from "./file-upload";

// const defaultLocations: Array<ISelectItem> = [
//   {id: '1', selected: true, text: 'Chippendale - 2008'},
//   {id: '2', selected: false, text: 'Sydney - 2000'},
//   {id: '3', selected: false, text: 'Epping - 3007'},
//   {id: '4', selected: false, text: 'Suburb - 4000'},
//   {id: '5', selected: false, text: 'Chippendale - 2008'},
//   {id: '6', selected: false, text: 'Sydney - 2000'},
//   {id: '7', selected: false, text: 'Chippendale - 2008'},
//   {id: '8', selected: false, text: 'Sydney - 2000'},
// ]
// const defaultIndustries: Array<ISelectItem> = [
//   {id: '1', selected: false, text: 'Construction'},
//   {id: '2', selected: false, text: 'IT'},
//   {id: '3', selected: false, text: 'Accounting'},
//   {id: '4', selected: true, text: 'Another Industry'},
//   {id: '5', selected: false, text: 'Legal'},
//   {id: '6', selected: false, text: 'Marketing'},
//   {id: '7', selected: false, text: 'Aviation'},
//   {id: '8', selected: false, text: 'Another'},
//   {id: '9', selected: false, text: 'Industry'},
// ]
// const defaultRoles: Array<ISelectItem> = [
//   {id: '1', selected: false, text: 'Role A'},
//   {id: '2', selected: false, text: 'Painter'},
//   {id: '3', selected: false, text: 'Bookkeeper'},
//   {id: '4', selected: true, text: 'Bricklayer'},
//   {id: '5', selected: false, text: 'Bookkeeper'},
//   {id: '6', selected: false, text: 'SEO Expert'},
//   {id: '7', selected: false, text: 'Carpenter'},
//   {id: '8', selected: false, text: 'Hairdresser'},
//   {id: '9', selected: false, text: 'Cashier'},
// ]
// const defaultExperience: Array<ISelectItem> = [
//   {id: '1', selected: false, text: 'Experience A'},
//   {id: '2', selected: false, text: 'Cashier'},
//   {id: '3', selected: false, text: 'Bookkeeping'},
//   {id: '4', selected: true, text: 'Paint Mixer'},
//   {id: '5', selected: false, text: 'Exp Another'},
//   {id: '6', selected: false, text: 'Another Experience'},
//   {id: '7', selected: false, text: 'Experience'},
//
// ]
// const defaultSkills: Array<ISelectItem> = [
//   {id: '1', selected: false, text: 'Advanced Diploma - Science'},
//   {id: '2', selected: false, text: 'Cert III - Agriculture'},
//   {id: '3', selected: false, text: 'Cert IV - Agriculture'},
//   {id: '4', selected: true, text: 'Cert V - Agriculture'},
//   {id: '5', selected: false, text: 'Bachelors Degree - Agriculture'},
//   {id: '6', selected: false, text: 'Cert IV - IT'},
//   {id: '7', selected: false, text: 'Cert IV - Software Development'},
// ]
// const defaultQualifications: Array<ISelectItem> = [
//   {id: '1', selected: false, text: 'Tony Lommi'},
//   {id: '2', selected: false, text: 'Steve Jobs'},
//   {id: '3', selected: false, text: 'Susan Boyle'},
// ]

export const AdvertRoleStore = types
  .model("AdvertRoleStore")
  .props({
    locations: types.optional(types.array(KvItemModel), []),
    industries: types.optional(types.array(KvItemModel), []),
    roles: types.optional(types.array(KvItemModel), []),
    experience: types.optional(types.array(KvItemModel), []),
    skills: types.optional(types.array(KvItemModel), []),
    qualifications: types.optional(types.array(KvItemModel), []),
    previewJobRoleAd: types.optional(JobRoleAdModel, {}),
    previewLogo: types.optional(FileUploadModel, {}),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setSelectedLocations: (locations: Array<IKvItemModel>) => {
      self.locations = cast(locations);
    },
    setSelectedIndustries: (industries: Array<IKvItemModel>) => {
      self.industries = cast(industries);
    },
    setSelectedRoles: (roles: Array<IKvItemModel>) => {
      self.roles = cast(roles);
    },
    setSelectedExperience: (experience: Array<IKvItemModel>) => {
      self.experience = cast(experience);
    },
    setSelectedSkills: (skills: Array<IKvItemModel>) => {
      self.skills = cast(skills);
    },
    setSelectedQualifications: (qualifications: Array<IKvItemModel>) => {
      self.qualifications = cast(qualifications);
    },
    addLocationsTag: (item: IKvItemModel) => {
      self.locations = cast([item]);
    },
    addIndustriesTag: (item: IKvItemModel) => {
      self.industries = cast([item]);
    },
    addRolesTag: (item: IKvItemModel) => {
      self.roles = cast([item]);
    },
    toggleExperienceTag: (item: IKvItemModel) => {
      const oldItem = self.experience.findIndex((it) => it.id === item.id)
      if (oldItem >= 0) self.experience.splice(oldItem, 1);
      else self.experience.push(item);
    },
    toggleSkillsTag: (item: IKvItemModel) => {
      const oldItem = self.skills.findIndex((it) => it.id === item.id)
      if (oldItem >= 0) self.skills.splice(oldItem, 1);
      else self.skills.push(item);
    },
    toggleQualificationsTag: (item: IKvItemModel) => {
      const oldItem = self.qualifications.findIndex((it) => it.id === item.id)
      if (oldItem >= 0) self.qualifications.splice(oldItem, 1);
      else self.qualifications.push(item);
    },
    addTagItem: (role: string, item: IKvItemModel) => {
      const oldItem = self[role].find((it) => it.id !== item.id)
      if (oldItem) return
      self[role].push(item)
    },
    toggleSelectRoleIndex: (role: string, id) => {
      self[role] = self[role].filter((it) => it.id !== id);
    },
    reset: () => {
      self.locations = cast([])
      self.industries = cast([])
      self.roles = cast([])
      self.experience = cast([])
      self.skills = cast([])
      self.qualifications = cast([])
    },
    setPreviewJobRoleAd: (previewJobRoleAd: IJobRoleAdModel) => {
      self.previewJobRoleAd = previewJobRoleAd
    },
    setPreviewLogo: (previewLogo: IFileUploadModel) => {
      self.previewLogo = previewLogo
    },
  }))
  .views((self) => ({
    getSelectedLocations: () => self.locations,
    getSelectedIndustries: () => self.industries,
    getSelectedRoles: () => self.roles,
    getSelectedExperience: () => self.experience,
    getSelectedSkills: () => self.skills,
    getSelectedQualifications: () => self.qualifications,
  }))
  .create({})
