import React, { FC, useCallback, useState } from "react"
import { View, SafeAreaView, ScrollView, RefreshControl, TouchableOpacity } from "react-native"
import { observer } from "mobx-react-lite"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { Header, GradientBackground, TextField, Button, Text } from "../../../components"
import { color } from "../../../theme"
import { TypeUserModel } from "../../../models/app/kv-item"
import { useStores } from "../../../models";
import { searchIgnoreCase } from "../../../utils/utils";
import { AlertModalStore } from "../../../models/app/alert-modal-store";
import { PureBottomModal } from "../../../components/backdrop-modal/pure-bottom-modal";
import { isAndroid, isIos } from "../../../utils/platform";
import { debounce } from "../../../utils/debounce";
import { constants, DEFAULT_LIMIT } from "../../../utils/constants";
import { ParamListBase, RouteProp, useFocusEffect } from "@react-navigation/native"
import UserCard from "../../../components/card/user-card"
import { SvgIcon } from "../../../components/icon/svg-icon";
import { AlertModal } from "../../../components/backdrop-modal/alert-modal";
import { NavigatorParamList } from "../../../navigators";

export interface AddExistingContactScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>,
  route: RouteProp<NavigatorParamList, 'addExistingContactScreen'>
}

export const AddExistingContactScreen: FC<AddExistingContactScreenProps> = observer(
  ({navigation, route}) => {
    const isNeedSaveContact = route?.params?.isNeedSaveContact;
    const hideSelected = route?.params?.hideSelected;
    const {app} = useStores();
    const isManager = app?.user?.role === constants.roles.MANAGER
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState("")
    const [data, setData] = useState<Array<TypeUserModel>>([]);
    const [checked, setChecked] = useState(false)

    const loadData = async () => {
      setLoading(true)
      try {
        const res = await app.environment.api.getContacts({
          'filters[businessProfile][id][$eq]': isManager
            ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          'businessProfileId': isManager
            ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          "pagination[pageSize]": DEFAULT_LIMIT
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        setData(
          res?.items
            ?.filter(item => hideSelected ? !app?.listSelectedContact?.find(cit => (cit?.id === item?.id && cit.checked)) : true)
            ?.map((item) => {
              const fullName = [item?.user?.firstName, item?.user?.lastName]?.join(' ')?.trim()
              return ({
                id: item?.id, name: fullName || item?.user?.email,
                checked: !!app?.listSelectedContact?.find(cit => (cit?.id === item?.id && cit.checked)),
                avatar: item?.user?.avatar?.url || ''
              })
            }))
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    useFocusEffect(useCallback(() => {
      loadData()
    }, []))

    const toggleSelectItem = (id) => {
      const newData = data.map((item) => {
        return ({
          ...item,
          checked: item.id === id ? !item.checked : item.checked
        })
      })
      setData(newData)
    }

    const onCheckAll = () => {
      setData(data.map((it) => ({...it, checked: !checked})))
      setChecked(!checked)
    }

    const onDone = () => {
      if (isNeedSaveContact) {
        const newData = data.filter(i => i.checked && !app.listSelectedContact?.find(c => c.id === i.id))
        app.pushListSelectedContact(newData)
      }
      navigation.goBack()
    }

    const delayLoadData = useCallback(debounce(loadData, 600), []);
    const handleChangeSearch = (text: string) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delayLoadData(text)
      setSearch(text)
    }

    const _systemData = data.filter(item => searchIgnoreCase(item?.name, search))
    const disableBtnDone = !data.find(it => it.checked)

    return (
      <>
        <PureBottomModal onClose={() => navigation.goBack()}>
          <SafeAreaView style={{flex: 1}}>
            <GradientBackground
              colors={[color.palette.gradientStart, color.palette.gradientStop]}
            />
            <Header
              preset={"modalHeader"}
              titlePreset={"headerTitle"}
              headerText={"Add Existing Contact(s)"}
              rightIcon={"times"}
              rightIconPreset={"smallBlue"}
              onRightPress={() => navigation.goBack()}
            />
            <View style={{paddingHorizontal: 20, flex: 1}}>
              <View style={{flex: 1}}>
                <View style={{marginTop: 0, marginBottom: 18, flexDirection: 'row', alignItems: 'center'}}>
                  <TextField
                    style={{flex: 1}}
                    placeholder="Search contacts" preset={'brand'}
                    value={search} onChangeText={handleChangeSearch}
                    labelPreset={"default"}
                    rightIcon={"times-circle-fill"}
                    rightIconPreset={"default"}
                    rightIconPress={() => handleChangeSearch('')}
                  />
                  <TouchableOpacity style={{alignItems: 'flex-end', marginLeft: 10}} onPress={onCheckAll}>
                    <View style={{alignItems: 'center'}}>
                      <SvgIcon
                        icon={checked ? 'check-square' : 'square-regular'}
                        theme={checked ? 'solid' : 'regular'} preset={'primary'}
                      />
                      <View style={{marginTop: 4}}>
                        <Text preset="small" style={{color: color.palette.primary}}>All</Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                </View>
                {data ? (
                  <View style={{marginTop: 4, flex: 1}}>
                    <ScrollView
                      refreshControl={<RefreshControl refreshing={loading} onRefresh={loadData}/>}
                      showsVerticalScrollIndicator={false} style={{flex: 1}}>
                      <View>
                        {_systemData.map((item, index) => (
                          <UserCard
                            key={`${index}-${item.id}`}
                            onClick={() => toggleSelectItem(item.id)}
                            isCheck={item.checked}
                            name={item.name}
                            avatar={item.avatar}
                          />
                        ))}
                      </View>
                    </ScrollView>
                  </View>
                ) : null}
              </View>
              <View style={{paddingTop: 10, marginBottom: isIos ? 0 : 30}}>
                <Button
                  disabled={disableBtnDone}
                  preset={disableBtnDone ? 'disabled' : 'brand'}
                  onPress={onDone}
                  text={'Add Contact(s)'}/>
              </View>
            </View>
          </SafeAreaView>
        </PureBottomModal>
        <AlertModal/>
      </>
    )
  },
)
