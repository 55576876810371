import * as React from "react"
import { View, SafeAreaView } from "react-native"
import { FC } from "react"
import { ParamListBase } from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { IconButton, Text, Button, Header } from "../../../components"
import { CONTAINER, BODY, BUTTON_CONTAINER } from "./style"
import { color, spacing } from "../../../theme"
import fonts from "../../../theme/base_fonts"

export interface ForgotPassword2Props {
  navigation: NativeStackNavigationProp<ParamListBase>
}

export const ForgotPasswordResultScreen: FC<ForgotPassword2Props> = ({ navigation }) => {
  return (
    <SafeAreaView style={CONTAINER}>
      <Header preset={"bgWhite"} />
      <View style={BODY}>
        <Text preset={"h1Blue"}>We emailed a reset link</Text>
        <Text
          preset={"body1"}
          style={{
            marginTop: spacing[3],
          }}
        >
          We emailed you a verification link. Please tap the link in that email to continue.
        </Text>

        <View style={{height: spacing[7]*5}}/>

        <View
          style={{
            alignItems: "center",
          }}
        >
          <IconButton
            onPress={() => navigation.navigate("resetPasswordScreen")}
            text="Click email link for demo ->"
            textStyle={fonts.type.base(22, color.palette.red)}
            style={{
              borderBottomWidth: 1,
              borderBottomColor: color.palette.red,
            }}
          />
        </View>

        <View style={{height: spacing[7]}}/>

        <View style={BUTTON_CONTAINER}>
          <Button text="Continue" preset="primary"  onPress={() => navigation.navigate('signInEmailCheckScreen')}/>
        </View>
      </View>
    </SafeAreaView>
  )
}
