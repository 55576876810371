import React from "react";
import { TouchableOpacity, StyleSheet, View, ViewStyle } from "react-native"
import {Text} from "../text/text";
import {color} from "../../theme";
import {getFistName} from "../../utils/utils";


const BASE = {
    width: 40,
    height: 40,
}

export const presets = {
    default: BASE,
    small: { width: 40, height: 40},
    medium: {width: 50, height: 50},
    mediumSquare: {borderRadius: 0,  width: 50, height: 50},
    avatar: {borderRadius: 10, width: 48, height: 48},
    socialIcon: {width: 48, height: 48},
    businessAvatar: {width: 68, height: 68},
    large: { width: 76, height: 76, borderRadius: 16,},
    round: {width: 50, height: 50, borderRadius: 50, },
    mediumRound: {width: 60, height: 60, borderRadius: 60, },
    lgRound: {width: 120, height: 120, borderRadius: 120,},
    smallRound: {width: 40, height: 40, borderRadius: 40},
    tinyRound: {width: 24, height: 24, borderRadius: 20},
    businessLogo: {width: 95, height: 95},
    walletImageIcon: {width: 40, height: 48, borderRadius: 0},
    walletPdfIcon: {width: 48, height: 48, borderRadius: 0},
    mailImage: {width: 129, height: 83, borderRadius: 0},
}
export type AvatarImagePresets = keyof typeof presets

interface Props {
    name: string;
    onPress?: () => void;
    preset?: AvatarImagePresets
    px?: number
    py?: number
    dot?: boolean
}

const DOT_STYLE: ViewStyle = {
  width: 10,
  height: 10,
  backgroundColor: color.palette.red,
  borderRadius: 10,
  borderWidth: 2,
  borderColor: color.palette.white,
  position: 'absolute',
  top: 8,
  right: 8,
}

const AvatarDefault = ({name, onPress, preset, px, py, dot}: Props) => {
    const styleOverride = presets[preset] || presets.default
    const txt = getFistName(name);
    return (
        <View style={{paddingHorizontal: px ?? 6, paddingVertical: py ?? 6}}>
            <TouchableOpacity style={[styles.wrapper, styleOverride]} onPress={onPress}>
                <Text
                    numberOfLines={1}
                    text={txt}
                    preset={'h1'}
                    style={{color: color.palette.white, paddingTop: 1}}/>
            </TouchableOpacity>
            {dot && <View style={DOT_STYLE}/>}
        </View>
    )
};

export default AvatarDefault;

const styles = StyleSheet.create({
    wrapper:{
        alignItems: 'center',
        backgroundColor: color.palette.greyLightMed,
        borderRadius: 10,
        justifyContent: 'center',
    }
})
