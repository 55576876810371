import React from "react"
import styles from "../styles"
import { TouchableOpacity, View } from "react-native"
import { AutoImage as Image, Text } from "../../../../components"
import { color } from "../../../../theme"
import { IWorkerWalletItemModel } from "../../../../models/app/advanced-skill";
import moment from "moment";

interface Props {
  onCLick: () => void;
  isEmpty?: boolean;
  item?: IWorkerWalletItemModel
}

const ImageCard = ({onCLick, isEmpty, item}: Props) => {
  const delta = moment(item?.expiryDate).unix() - moment().unix()
  const isExpired = !!item?.expiryDate && (delta <= 0)
  const isExpiringSoon = !!item?.expiryDate && (delta > 0 && delta <= 14 * 86400)
  const url = item?.imgFont
  return (
    <TouchableOpacity style={[styles.ShadowWrapper, styles.imageCard]} onPress={onCLick} activeOpacity={0.8}>
      {!!url && (
        <Image source={{uri: url}} style={{width: '100%', height: '100%', borderRadius: 8}}/>
      )}
      <View style={[styles.bgWrapperExpiry, isExpired && {backgroundColor: color.palette.red03}]}>
        {isExpired ? (
          <View style={styles.expiring}>
            <Text preset="textTags" style={{color: color.palette.white}}>Expired</Text>
          </View>
        ) : isExpiringSoon ? (
          <View style={styles.expiring}>
            <Text preset="textTags" style={{color: color.palette.white}}>Expiring Soon</Text>
          </View>
        ) : null}
      </View>
      {isEmpty && <View style={styles.bgWrapperEmpty}>
        <Text preset="textTags" style={{color: color.palette.primary}}>No Cards have been uploaded</Text>
      </View>}
    </TouchableOpacity>
  )
};

export default ImageCard
