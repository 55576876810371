import { Instance, types } from "mobx-state-tree"
import { convertFileUploadModel, FileUploadModel } from "./file-upload";
import { convertKvItemModel, KvItemModel } from "./kv-item";
import { convertShortUserModel, convertWorkerProfileModel, ShortUserModel, WorkerProfileModel } from "./worker-profile";
import { BusinessProfileModel, convertBusinessProfileModel } from "./business-profile";

export const KvQualificationPath = {
  courses: 'courses',
  educationLevels: 'education-levels',
  workerQualifications: 'worker-qualifications',
}

export const KvSkillPath = {
  skills: 'skills',
  workerSkills: 'worker-skills',
  licences: 'licences',
  skillCategories: 'skill-categories',
  states: 'states',
  qualificationEdu: 'qualification-education-levels',
}

export const getUserSkillStr = (it: IUserSkillModel): string => it?.description
  ? [it?.skillCategory?.name, it?.description].join(' - ') : it?.skillCategory?.name
export const getUserQualificationStr = (it: IUserQualificationModel): string => it?.qualificationEducationLevel?.name
  ? [it?.qualificationEducationLevel?.name, it?.courseName].join(' - ') : it?.courseName

export const UserQualificationModel = types
  .model("UserQualificationModel", {
    id: types.optional(types.number, 0),
    courseName: types.optional(types.string, ''),
    qualificationEducationLevel: types.optional(KvItemModel, {}),
    user: types.optional(ShortUserModel, {}),
    businessProfile: types.maybeNull(BusinessProfileModel),
  })
export type IUserQualificationModel = Instance<typeof UserQualificationModel>
export const convertUserQualificationModel = (raw): IUserQualificationModel => ({
  id: raw?.id || 0,
  courseName: raw?.courseName || '',
  qualificationEducationLevel: convertKvItemModel(raw?.qualificationEducationLevel?.data?.attributes ? {
    ...raw?.qualificationEducationLevel?.data?.attributes, id: raw?.qualificationEducationLevel?.data?.id
  } : raw?.qualificationEducationLevel?.attributes
    ? {...raw?.qualificationEducationLevel?.attributes, id: raw?.qualificationEducationLevel?.id}
    : raw?.qualificationEducationLevel),
  user: convertShortUserModel(raw?.user?.data?.attributes ? {
    ...raw?.user?.data?.attributes, id: raw?.user?.data?.id
  } : raw?.user?.attributes ? {
    ...raw?.user?.attributes, id: raw?.user?.id
  } : raw?.user),
  businessProfile: convertBusinessProfileModel(raw?.businessProfile?.data?.attributes ? {
    ...raw?.businessProfile?.data?.attributes, id: raw?.businessProfile?.data?.id
  } : raw?.businessProfile?.attributes
    ? {...raw?.businessProfile?.attributes, id: raw?.businessProfile?.id} : raw?.businessProfile),
})

export const WorkerQualificationModel = types
  .model("WorkerQualificationModel", {
    id: types.optional(types.number, 0),
    createdAt: types.optional(types.string, ''),
    month: types.optional(types.number, 0),
    year: types.optional(types.number, 0),
    organisation: types.optional(types.string, ''),
    document: types.optional(FileUploadModel, {}),
    user: types.optional(ShortUserModel, {}),
    qualification: types.optional(UserQualificationModel, {}),
  })
export type IWorkerQualificationModel = Instance<typeof WorkerQualificationModel>
export const convertWorkerQualificationModel = (raw): IWorkerQualificationModel => ({
  id: raw?.id || 0,
  createdAt: raw?.createdAt || '',
  month: raw?.month || 0,
  year: raw?.year || 0,
  organisation: raw?.organisation || '',
  document: convertFileUploadModel(raw?.document?.data?.attributes ? {
    ...raw?.document?.data?.attributes, id: raw?.document?.data?.id
  } : raw?.document?.attributes ? {
    ...raw?.document?.attributes, id: raw?.document?.id
  } : raw?.document),
  user: convertShortUserModel(raw?.user?.data?.attributes ? {
    ...raw?.user?.data?.attributes, id: raw?.user?.data?.id
  } : raw?.user?.attributes ? {
    ...raw?.user?.attributes, id: raw?.user?.id
  } : raw?.user),
  qualification: convertUserQualificationModel(raw?.qualification?.data?.attributes ? {
    ...raw?.qualification?.data?.attributes, id: raw?.qualification?.data?.id
  } : raw?.qualification?.attributes ? {
    ...raw?.qualification?.attributes, id: raw?.qualification?.id
  } : raw?.qualification),
})

export const UserSkillModel = types
  .model("UserSkillModel", {
    id: types.optional(types.number, 0),
    description: types.optional(types.string, ''),
    skillCategory: types.optional(KvItemModel, {}),

    user: types.optional(ShortUserModel, {}),
    businessProfile: types.maybeNull(BusinessProfileModel),
  })
export type IUserSkillModel = Instance<typeof UserSkillModel>
export const convertUserSkillModel = (raw): IUserSkillModel => ({
  id: raw?.id || 0,
  description: raw?.description || '',
  skillCategory: convertKvItemModel(raw?.skillCategory?.data?.attributes ? {
    ...raw?.skillCategory?.data?.attributes, id: raw?.skillCategory?.data?.id
  } : raw?.skillCategory?.attributes ? {
    ...raw?.skillCategory?.attributes, id: raw?.skillCategory?.id
  } : raw?.skillCategory),

  user: convertShortUserModel(raw?.user?.data?.attributes ? {
    ...raw?.user?.data?.attributes, id: raw?.user?.data?.id
  } : raw?.user?.attributes ? {
    ...raw?.user?.attributes, id: raw?.user?.id
  } : raw?.user),
  businessProfile: convertBusinessProfileModel(raw?.businessProfile?.data?.attributes ? {
    ...raw?.businessProfile?.data?.attributes, id: raw?.businessProfile?.data?.id
  } : raw?.businessProfile?.attributes
    ? {...raw?.businessProfile?.attributes, id: raw?.businessProfile?.id} : raw?.businessProfile),
})

export const WorkerSkillModel = types
  .model("WorkerSkillModel", {
    id: types.optional(types.number, 0),
    licenceRegistrationNo: types.optional(types.string, ''),
    skillClass: types.optional(types.string, ''),
    expiryDate: types.optional(types.string, ''),
    createdAt: types.optional(types.string, ''),

    skill: types.optional(UserSkillModel, {}),
    state: types.optional(KvItemModel, {}),
    document: types.optional(FileUploadModel, {}),
    frontImage: types.optional(FileUploadModel, {}),
    backImage: types.optional(FileUploadModel, {}),
    user: types.optional(ShortUserModel, {}),
    isCard: types.optional(types.boolean, false),
  })
export type IWorkerSkillModel = Instance<typeof WorkerSkillModel>
export const convertWorkerSkillModel = (raw): IWorkerSkillModel => ({
  id: raw?.id || 0,
  licenceRegistrationNo: raw?.licenceRegistrationNo || '',
  skillClass: raw?.skillClass || '',
  expiryDate: raw?.expiryDate || '',
  createdAt: raw?.createdAt || '',

  skill: convertUserSkillModel(raw?.skill?.data?.attributes ? {
    ...raw?.skill?.data?.attributes, id: raw?.skill?.data?.id
  } : raw?.skill?.attributes ? {
    ...raw?.skill?.attributes, id: raw?.skill?.id
  } : raw?.skill),
  state: convertKvItemModel(raw?.state?.data?.attributes ? {
    ...raw?.state?.data?.attributes, id: raw?.state?.data?.id
  } : raw?.state?.attributes ? {
    ...raw?.state?.attributes, id: raw?.state?.id
  } : raw?.state),
  document: convertFileUploadModel(raw?.document?.data?.attributes ? {
    ...raw?.document?.data?.attributes, id: raw?.document?.data?.id
  } : raw?.document?.attributes ? {
    ...raw?.document?.attributes, id: raw?.document?.id
  } : raw?.document),
  frontImage: convertFileUploadModel(raw?.frontImage?.data?.attributes ? {
    ...raw?.frontImage?.data?.attributes, id: raw?.frontImage?.data?.id
  } : raw?.frontImage?.attributes ? {
    ...raw?.frontImage?.attributes, id: raw?.frontImage?.id
  } : raw?.frontImage),
  backImage: convertFileUploadModel(raw?.backImage?.data?.attributes ? {
    ...raw?.backImage?.data?.attributes, id: raw?.backImage?.data?.id
  } : raw?.backImage?.attributes ? {
    ...raw?.backImage?.attributes, id: raw?.backImage?.id
  } : raw?.backImage),
  user: convertShortUserModel(raw?.user?.data?.attributes ? {
    ...raw?.user?.data?.attributes, id: raw?.user?.data?.id
  } : raw?.user?.attributes ? {
    ...raw?.user?.attributes, id: raw?.user?.id
  } : raw?.user),
  isCard: raw?.isCard || false,
})

export const WorkerWalletItemPath = {
  workerOthers: 'worker-others',
  workerRightToWorks: 'worker-right-to-works',
  workerCvs: 'worker-cvs',
}
export const WorkerWalletItemModel = types.model({
  id: types.optional(types.number, 0),
  parentId: types.optional(types.number, 0),
  createdAt: types.optional(types.string, ''),
  url: types.optional(FileUploadModel, {}),
  description: types.optional(types.string, ''),
  type: types.optional(types.string, ''),
  key: types.optional(types.string, ''),
  isCard: types.optional(types.boolean, false),
  imgFont: types.optional(types.string, ''),
  imgBack: types.optional(types.string, ''),
  expiryDate: types.optional(types.string, ''),
  workerProfile: types.maybeNull(WorkerProfileModel),
})
export type IWorkerWalletItemModel = Instance<typeof WorkerWalletItemModel>
export const convertWorkerWalletItemModel = (raw): IWorkerWalletItemModel => ({
  id: raw?.id || 0,
  parentId: raw?.parentId || 0,
  createdAt: raw?.createdAt || '',
  url: convertFileUploadModel(raw?.url?.data?.attributes ? {
    ...raw?.url?.data?.attributes, id: raw?.url?.data?.id
  } : raw?.url?.attributes ? {
    ...raw?.url?.attributes, id: raw?.url?.id
  } : raw?.url),
  description: raw?.description || '',
  type: raw?.type || '',
  isCard: raw?.isCard || false,
  key: raw?.key || '',
  imgBack: raw?.imgBack || '',
  imgFont: raw?.imgFont || '',
  expiryDate: raw?.expiryDate || '',
  workerProfile: convertWorkerProfileModel(
    raw?.workerProfile?.data?.attributes ? {
      ...raw?.workerProfile?.data?.attributes,
      id: raw?.workerProfile?.data?.id
    } : raw?.workerProfile?.data
  ),
})

export const WorkerWalletItemShareModel = types.model({
  id: types.optional(types.number, 0),
  description: types.optional(types.string, ''),
  type: types.optional(types.string, ''),
  key: types.optional(types.string, ''),
  isCard: types.optional(types.boolean, false),
  urls: types.optional(types.array(types.string), []),
})
export type IWorkerWalletItemShareModel = Instance<typeof WorkerWalletItemShareModel>
export const convertWorkerWalletItemShareModel = (raw): IWorkerWalletItemShareModel => ({
  id: raw?.id || 0,
  description: raw?.description || '',
  type: raw?.type || '',
  key: raw?.key || '',
  isCard: raw?.isCard || false,
  urls: raw?.urls || [],
})

export interface IPureWorkerWalletItem {
  id: number
  key: string
  isCard: boolean
}
