import * as React from "react"
import { TouchableOpacity, TouchableOpacityProps, View } from "react-native"
import { Text } from "../text/text"
import { SvgIcon } from "../icon/svg-icon";

export interface AwSelectButtonProps extends TouchableOpacityProps {
  text?: string
  styles?: any
  onPress?: () => void
}

export function AwSelectButton(props: AwSelectButtonProps) {
  const {text,styles, ...rest} = props
  return (
    <TouchableOpacity {...rest}>
      <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
        <Text preset={'body'} style={styles}>{text}</Text>
        <SvgIcon icon={'plus'} preset={'orange'}/>
      </View>
    </TouchableOpacity>
  )
}
