import {cast, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment"
import {IKvItemModel, KvItemModel} from "./kv-item";

export type FilterJobType = {
  locations: Array<IKvItemModel>;
  industries: Array<IKvItemModel>;
  roles: Array<IKvItemModel>;
  experience: Array<IKvItemModel>;
  skills: Array<IKvItemModel>;
  qualifications: Array<IKvItemModel>;
}

export const FilterJobStore = types
  .model("FilterJobStore")
  .props({
    locations: types.optional(types.array(KvItemModel), []),
    industries: types.optional(types.array(KvItemModel), []),
    roles: types.optional(types.array(KvItemModel), []),
    experience: types.optional(types.array(KvItemModel), []),
    skills: types.optional(types.array(KvItemModel), []),
    qualifications: types.optional(types.array(KvItemModel), []),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setSelectedLocations: (locations: Array<IKvItemModel>) => {
      self.locations = cast(locations);
    },
    setSelectedIndustries: (industries: Array<IKvItemModel>) => {
      self.industries = cast(industries);
    },
    setSelectedRoles: (roles: Array<IKvItemModel>) => {
      self.roles = cast(roles);
    },
    setSelectedExperience: (experience: Array<IKvItemModel>) => {
      self.experience = cast(experience);
    },
    setSelectedSkills: (skills: Array<IKvItemModel>) => {
      self.skills = cast(skills);
    },
    setSelectedQualifications: (qualifications: Array<IKvItemModel>) => {
      self.qualifications = cast(qualifications);
    },
    addLocationsTag: (item: IKvItemModel) => {
      self.locations = cast([item]);
    },
    addIndustriesTag: (item: IKvItemModel) => {
      self.industries = cast([item]);
    },
    addRolesTag: (item: IKvItemModel) => {
      self.roles = cast([item]);
    },
    addExperienceTag: (item: IKvItemModel) => {
      const oldItem = self.experience.find((it) => it.id === item.id)
      if (oldItem) return
      self.experience.push(item);
    },
    addSkillsTag: (item: IKvItemModel) => {
      const oldItem = self.skills.find((it) => it.id === item.id)
      if (oldItem) return
      self.skills.push(item);
    },
    addQualificationsTag: (item: IKvItemModel) => {
      const oldItem = self.qualifications.find((it) => it.id === item.id)
      if (oldItem) return
      self.qualifications.push(item);
    },
    addTagItem: (role: string, item: IKvItemModel) => {
      const oldItem = self[role].find((it) => it.id !== item.id)
      if (oldItem) return
      self[role].push(item)
    },
    toggleSelectRoleIndex: (role: string, id) => {
      self[role] = self[role].filter((it) => it.id !== id);
    },
    reset: () => {
      self.locations = cast([])
      self.industries = cast([])
      self.roles = cast([])
      self.experience = cast([])
      self.skills = cast([])
      self.qualifications = cast([])
    },
    setFilterJobData: (data: FilterJobType) => {
      self.locations = cast(data?.locations || [])
      self.industries = cast(data?.industries || [])
      self.roles = cast(data?.roles || [])
      self.experience = cast(data?.experience || [])
      self.skills = cast(data?.skills || [])
      self.qualifications = cast(data?.qualifications || [])
    },
  }))
  .views((self) => ({
    getSelectedLocations: () => self.locations,
    getSelectedIndustries: () => self.industries,
    getSelectedRoles: () => self.roles,
    getSelectedExperience: () => self.experience,
    getSelectedSkills: () => self.skills,
    getSelectedQualifications: () => self.qualifications,
  }))
