import React from "react"
import { StyleSheet, TouchableOpacity, View } from "react-native"
import { color } from "../../theme"
import { AvatarImage } from "../avatar-image/avatar-image"
import AvatarDefault from "../avatar-image/AvatarDefault"
import { Text } from "../text/text"

interface Props{
  onClick:() => void
  logo?: string
  name: string
  industry: string
  radius: string
  isPinned?: boolean
}

const WorkerCard = ({onClick, logo, name, industry, radius, isPinned}:Props) => {
  return(
    <TouchableOpacity style={styles.wrapper} activeOpacity={0.8} onPress={onClick}>
      {!!logo ?
        <AvatarImage
          onPress={() => {}}
          source={{uri: logo}} preset={"medium"} py={0} px={0}/>
        :
        <AvatarDefault py={0} px={0} name={name} preset={"medium"} />
      }
      <View style={styles.textWrapper}>
        <Text
          numberOfLines={1}
          text={name} 
          preset={'dark'}
          style={{color: color.palette.dark}}/>
        <Text
          numberOfLines={1}
          text={industry}
          preset={'total'}
          style={{color: color.palette.primary, marginVertical: 2}}/>
        <Text
          numberOfLines={1}
          text={`Within ${radius}`}
          preset={'posted'}
          style={{fontWeight: '700'}}/>
      </View>
      {/* <View style={styles.icBookmark}> */}
      {/*  <SvgIcon */}
      {/*    icon={"bookmark"} theme={isPinned ? "solid" : "regular"} preset="normal"/> */}
      {/* </View> */}
    </TouchableOpacity>
  )
};

export default WorkerCard;

const styles = StyleSheet.create({
  wrapper:{
    marginVertical: 10, 
    marginHorizontal: 10,
    backgroundColor: color.palette.white,
    borderRadius: 6,
    borderWidth: 1, borderColor: '#D3D8ED',
    shadowColor: '#022964', shadowOpacity: 0.3,
    shadowOffset: {width: 1, height: 0}, shadowRadius: 4,
    elevation: 4,
    paddingVertical: 16,
    paddingHorizontal: 16,
    flexDirection: 'row'
  },
  textWrapper:{
    marginLeft: 8,
    flexDirection: 'column'
  },
  icBookmark:{
    position: 'absolute',
    top: 16,
    right: 35
  }
})