import React, { FC, useState } from 'react';
import { StyleSheet, SafeAreaView, View } from 'react-native';

import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { observer } from "mobx-react-lite";
import { NavigatorParamList } from "../../../navigators";
import { Button, Header, Text, TextField } from "../../../components";
import { PureBottomModal } from "../../../components/backdrop-modal/pure-bottom-modal";
import { LoadingModal } from "../../../components/backdrop-modal/loading-modal";
import { AlertModal } from "../../../components/backdrop-modal/alert-modal";
import { AlertModalStore } from "../../../models/app/alert-modal-store";
import { useStores } from "../../../models";
import { MainAppStore } from "../../../models/app/main-app-store";
import { validateEmail } from "../../../utils/utils";

export interface WalletItemShareFinalScreenProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
}

export const WalletItemShareFinalScreen: FC<WalletItemShareFinalScreenProps> = observer(
  ({navigation}) => {
    const {app} = useStores()
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')

    const onSubmit = async () => {
      setLoading(true)
      try {
        const user = app.userAccount
        const fullName = ([user?.firstName, user?.lastName].join(' '))
        const res = await app.environment.api.sendMailItems({
          email,
          fromName: fullName,
          items: MainAppStore.workerWalletItemShareSelected.map(item => ({
            type: item?.type || ' ',
            description: item?.description || ' ',
            link: window.location.origin + '/' + `wallet-detail?id=${item?.id}&key=${item?.key}&isCard=${item?.isCard ? true : false}`,
            hasFiles: item?.urls?.length > 0,
            urls: item?.urls?.map(it => String(it))
          }))
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
        } else {
          AlertModalStore.alert('Success', 'Shared', {
            onOk: {onPress: () => navigation.popToTop()},
          })
          setTimeout(() => {
            AlertModalStore.setVisible(false)
            navigation.popToTop()
          }, 2000)
        }
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    const handleChangeEmail = (text?: string) => {
      setEmail(text?.trim()?.split(' ').join(''))
    }

    const isValidEmail = email && validateEmail(email)
    return (
      <>
        <PureBottomModal onClose={() => navigation.goBack()}>
          <SafeAreaView style={styles.container}>
            <Header
              preset={'modalHeader'}
              headerText={'Enter Email to Share'}
              titlePreset={'headerTitle'}
              rightIcon={'times'} rightIconPreset={'smallBlue'} onRightPress={() => navigation.goBack()}
            />
            <View style={styles.contentContainerStyle}>
              <View style={{marginBottom: 24}}>
                <TextField
                  preset={"brand"}
                  value={email} onChangeText={handleChangeEmail} autoCapitalize='none'
                  placeholder={'Email'}
                />
              </View>
              <Button
                disabled={loading || !isValidEmail}
                preset={!isValidEmail ? 'disabled' : 'brand'}
                onPress={onSubmit}>
                <Text preset={'whiteBold'}>Next</Text>
              </Button>
            </View>
          </SafeAreaView>
        </PureBottomModal>
        <LoadingModal visible={loading}/>
        <AlertModal/>
      </>
    )
  },
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainerStyle: {
    flex: 1, marginTop: 60, paddingHorizontal: 20
  },
});
