import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {View, StyleSheet, FlatList} from 'react-native';

import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {observer} from "mobx-react-lite";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {color, spacing} from "../../../theme";
import {Header, Text, TextField} from "../../../components";
import {AvatarImage} from "../../../components/avatar-image/avatar-image";
import {ParamListBase, RouteProp, useFocusEffect} from "@react-navigation/native";
import {useStores} from "../../../models";
import {IUserAccountModel} from "../../../models/app/user-account";
import {listToStr} from "../../../utils/utils";
import {IUserChatModel} from "../../../models/app/message";
import { constants, DEFAULT_MD_LIMIT } from "../../../utils/constants";
import {AwChatManager} from "../../../services/api/socket";
import {ApplicationStatus, IWorkerApplicationModel} from "../../../models/app/application";
import {NavigatorParamList} from "../../../navigators";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import moment from "moment";
import {trackWithUserAccount, TrackEvents} from "../../../services/track";
import AvatarDefault from "../../../components/avatar-image/AvatarDefault";
import {useFilePicker} from "use-file-picker";
import {convertFileUploadModel} from "../../../models/app/file-upload";
import {SvgIcon} from "../../../components/icon/svg-icon";
import {MessageImage} from "../../../components/avatar-image/message-image";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";
import {isIos} from "../../../utils/platform";

export interface UserApplicantMessageScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'userApplicantMessageScreen'>
}

const defaultIcon = require("../../../../assets/images/default-icon.png")
const defaultPhoto = require("../../../../assets/images/default-photo.png")
const defaultLogo = require("../../../../assets/images/default-company.png")

export const WorkerApplicantMessageScreen: FC<UserApplicantMessageScreenProps> = observer(
  ({navigation, route}) => {
    const insets = useSafeAreaInsets()
    const applicationId = route.params?.applicationId
    const toUserId = route.params?.toUserId
    const {app} = useStores()
    const isWorker = app.user.role === constants.roles.WORKER
    const isManager = app?.user?.role === constants.roles.MANAGER
    const fromUserAccount: IUserAccountModel = app?.userAccount
    const [toUserAccount, setUserAccount] = useState<IUserAccountModel>()
    const [chatData, setChatData] = useState<Array<IUserChatModel>>([])
    const [startDayIdList, setStartDayIdList] = useState<Array<number>>([])
    const [loading, setLoading] = useState(false)
    const [textMessage, setTextMessage] = useState('')
    const canSend = !!textMessage?.trim()
    const [chatManager, setChatManager] = useState<AwChatManager>(null)
    const dataRef = useRef<FlatList>()
    const [jobAdApplication, setJobAdApplication] = useState<IWorkerApplicationModel>(null)

    const initData = async () => {
      const jobAppRes = await app.environment.api.getBusinessJobAdApplication(applicationId)
      if (jobAppRes.kind === 'ok') {
        setJobAdApplication(jobAppRes.application)
      }
      const res = await app.environment.api.getMe(toUserId)
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message)
        return
      }
      setUserAccount(res.userAccount)
      // dataRef && dataRef?.current && dataRef?.current?.scrollToEnd()
    }

    const loadData = async () => {
      // loadSocket()
      if (loading) return
      setLoading(true)
      try {
        const chatRes = await app.environment.api.getNewChatMessage({
          applicationId, userId: app.user.role === constants.roles.WORKER ? fromUserAccount.id : toUserId,
          ...(!isWorker ? {
            businessProfileId: isManager
              ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          } : null),
          "pagination[pageSize]": DEFAULT_MD_LIMIT
        })
        if (chatRes.kind !== 'ok') {
          AlertModalStore.alert('Error', chatRes.message)
          return
        }
        if (!chatData || chatRes.items.length !== chatData?.length) {
          const startDayIdDict = {}
          chatRes.items?.forEach(item => {
            const createdDate = moment(item?.createdAt).format('DD/MM/YYYY')
            if (!startDayIdDict.hasOwnProperty(createdDate)) startDayIdDict[createdDate] = item?.id
          })
          setStartDayIdList(Object.values(startDayIdDict))
          setChatData(chatRes?.items?.reverse())
          // setTimeout(() => {
          //   dataRef && dataRef?.current && dataRef?.current?.scrollToEnd()
          // }, 200)
        }

      } catch (e) {
        console.log(e)
      } finally {
        // setTimeout(() => {
        //   dataRef && dataRef?.current && dataRef?.current?.scrollToEnd()
        // }, 200)
        setLoading(false)
      }
    }

    useFocusEffect(useCallback(() => {
      if (toUserId) {
        initData()
        loadData()
      }
      const awChatManager = AwChatManager.getInstance(app.auth)
      awChatManager.onChat = (message) => {
        loadData()
      }
      setChatManager(awChatManager)
      return () => {
        AwChatManager.currentInstance()?.disconnect()
      }
    }, []))

    const handleSend = async () => {
      if (!!urlImgBefore) {
        refTextImg.current = textMessage;
        setTextMessage('')
        prepareImg();
        return;
      }
      if (!textMessage?.trim() || loading) return
      try {
        if (!chatManager?.socket) {
          console.log('Socket null')
          return
        }
        chatManager?.socket.emit('chat', {
          applicationId: applicationId,
          toUserId: toUserId,
          action: 'sendMessage',
          content: textMessage,
        });
      } catch (e) {
        console.log(e)
      } finally {
        trackWithUserAccount(TrackEvents.SentMessage, app?.userAccount)
        setChatData([
          {
            id: 0, from: fromUserAccount, to: toUserAccount, content: textMessage,
            createdAt: new Date().toISOString()
          } as IUserChatModel,
          ...chatData,
        ])
        setTimeout(() => {
          loadData()
        }, 400)
        setTextMessage('')
      }
    }

    const showProfile = () => {
      navigation.navigate('businessProfileScreen', {
        businessProfileId: toUserAccount?.businessProfile?.id
      })
    }

    // Start send img
    const [openFileSelector, {plainFiles, filesContent}] = useFilePicker({
      readAs: 'DataURL',
      accept: 'image/*',
      maxFileSize: 10,
    });
    const [urlImgBefore, setUrlImgBefore] = useState('');
    const refCurrentImage = useRef();
    const refTextImg = useRef('');
    useEffect(() => {
      if (!plainFiles || plainFiles.length <= 0) return
      onPickerResult(plainFiles)
    }, [plainFiles])

    const onPickerResult = async (response: File[]) => {
      if (Array.isArray(response) && response.length > 0) {
        try {
          setUrlImgBefore(filesContent[0].content)
          // @ts-ignore
          refCurrentImage.current = response[0];
        } catch (e) {
          console.log(e)
        }
      }
    }

    const handleUpLoadImg = async () => {
      await openFileSelector();
    }

    const handleSendImg = (mediaId: number) => {
      try {
        if (!chatManager?.socket) {
          console.log('Socket null')
          return
        }
        chatManager?.socket.emit('chat', {
          applicationId: applicationId,
          toUserId: toUserId,
          action: 'sendMessage',
          file: mediaId,
          content: refTextImg.current
        });
      } catch (e) {
        console.log(e)
      } finally {
        trackWithUserAccount(TrackEvents.SentMessage, app?.userAccount)
        setTimeout(() => {
          loadData()
        }, 400)
      }
      refCurrentImage.current = null;
      refTextImg.current = '';
    }
    const prepareImg = async () => {
      setUrlImgBefore('');
      setChatData([
        {
          id: new Date().getTime(),
          from: fromUserAccount,
          to: toUserAccount,
          file: convertFileUploadModel({url: 'local'}),
          createdAt: new Date().toISOString(),
          content: refTextImg.current
        } as IUserChatModel,
        ...chatData,
      ])
      const res = await app.environment.api.uploadFile(refCurrentImage.current)
      if (res.kind === 'ok') {
        handleSendImg(res?.file?.id)
      } else {
        AlertModalStore.alert('Error', 'Can not upload file')
      }
    }

    const onRemoveImg = () => {
      setUrlImgBefore('');
      refCurrentImage.current = null;
    }
    // End send img

    const renderItem = ({item}: { item: IUserChatModel }) => {
      const isFrom = item?.to?.id === fromUserAccount.id
      const isSystem = !item?.from?.id
      return (
        <View>
          {startDayIdList.includes(item?.id) && (
            <View style={{alignItems: 'center', marginTop: 20, paddingBottom: 10}}>
              <Text
                preset={'body1'} style={{color: color.palette.black80}}
              >{moment(item?.createdAt).format('DD/MM/YYYY')}</Text>
            </View>
          )}
          <View style={{
            flexDirection: 'row',
            marginBottom: 20,
            marginRight: isFrom ? 50 : 0, marginLeft: isFrom ? 0 : 50
          }}>
            {isFrom ? (
              <AvatarImage
                onPress={showProfile}
                style={{}} preset={'medium'}
                source={isSystem ? defaultIcon : toUserAvatar
                  ? {uri: toUserAvatar}
                  : isWorker ? defaultPhoto : defaultLogo}/>
            ) : null}
            <View style={{flex: 1, paddingTop: 4, alignItems: isFrom ? 'flex-start' : 'flex-end'}}>
              <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                <Text preset={isFrom ? 'messageTitleLink' : 'messageTitle'}>
                  {isSystem ? 'Notification' :
                    isFrom ?
                      listToStr([toUserAccount?.firstName, toUserAccount?.lastName]) :
                      listToStr([fromUserAccount?.firstName, fromUserAccount?.lastName])
                  }
                </Text>
                <Text style={{marginLeft: 10}} preset={'caption'}>{moment(item?.createdAt).format('HH:mm')}</Text>
              </View>
              {!!item?.file?.url
                ?
                <View style={{
                  marginTop: 10,
                  flexDirection: 'column',
                  alignSelf: isFrom ? 'flex-start' : 'flex-end',
                  alignItems: isFrom ? 'flex-start' : 'flex-end'
                }}>
                  {!!item.content &&
                  <View style={{
                    marginBottom: 5,
                    paddingHorizontal: 18,
                    paddingVertical: 12,
                    borderTopRightRadius: 10,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    backgroundColor: isFrom ? isSystem ? color.palette.bgGreen : color.palette.grey : color.palette.bgGreen,
                  }}>
                    <Text preset={'body1'} text={item.content}/>
                  </View>}
                  {item?.file?.url.includes('local') ? <SvgIcon
                    icon='image'
                    theme='light'
                    preset='imgMsg'
                  /> : <MessageImage name={item?.file?.name} url={item?.file?.url}/>}
                </View>
                :
                <View style={{
                  marginTop: 10, paddingHorizontal: 18, paddingVertical: 12,
                  backgroundColor: isFrom ? isSystem ? color.palette.bgGreen : color.palette.grey : color.palette.bgGreen,
                  borderTopRightRadius: 10, borderBottomLeftRadius: 10, borderBottomRightRadius: 10,
                  flexDirection: 'row',
                  alignSelf: isFrom ? 'flex-start' : 'flex-end'
                }}>
                  <Text preset={'body1'} text={item.content}/>
                </View>
              }
            </View>
          </View>
        </View>
      )
    }

    const toUserAvatar = isWorker
      ? toUserAccount?.businessProfile?.logo?.url
      : toUserAccount?.avatar?.url
    const toUserName = isWorker
      ? toUserAccount?.businessProfile?.name
      : listToStr([toUserAccount?.firstName, toUserAccount?.lastName])
    return (
      <>
        <PureBottomModal onClose={() => navigation.goBack()}>
          <View style={styles.container}>
            <View style={styles.mainContainer}>
              <Header
                preset={'pureTransparent'}
                header={() => (
                  <View style={{paddingTop: 4}}>
                    <Text preset={'mediumBold'}>{toUserName}</Text>
                    <View style={{height: 4}}/>
                    <Text preset={'userMessageSubtitle'}>{jobAdApplication?.jobRole?.jobAd?.title}</Text>
                  </View>
                )}
                leftIcon={() =>
                  toUserAvatar ? <AvatarImage
                      onPress={showProfile}
                      preset={'default'}
                      source={toUserAvatar ? {uri: toUserAvatar} : isWorker ? defaultPhoto : defaultLogo}/> :
                    <AvatarDefault onPress={showProfile} name={toUserAccount?.businessProfile?.name}
                                   preset={"default"}/>
                }
                rightIcon={'times'} rightIconPreset={'smallBlue'} onRightPress={() => navigation.goBack()}
              />
            </View>
            <View style={{padding: 20, borderBottomWidth: 1.5, borderColor: color.palette.bgBlue}}>
              <Text preset={"mediumBold"} style={{marginBottom: spacing[2]}}>
                Application Status
              </Text>
              <View style={{flexDirection: "row"}}>
                <View
                  style={{
                    height: 10, flex: 2,
                    backgroundColor: jobAdApplication?.status === ApplicationStatus.submitted || jobAdApplication?.approved
                      ? color.palette.orange : color.palette.grey,
                    borderTopLeftRadius: 20, borderBottomLeftRadius: 20,
                  }}
                />
                <View
                  style={{
                    height: 10, flex: 1,
                    backgroundColor: jobAdApplication?.approved
                      ? color.palette.green : color.palette.grey,
                    marginHorizontal: 3,
                  }}
                />
                <View
                  style={{
                    height: 10, flex: 1,
                    backgroundColor: color.palette.grey,
                    borderTopRightRadius: 20, borderBottomRightRadius: 20,
                  }}
                />
              </View>
            </View>
            <View style={styles.body}>
              <View style={{flex: 1, paddingLeft: 10, paddingRight: 20,}}>
                <FlatList
                  ref={dataRef}
                  showsVerticalScrollIndicator={false}
                  ListFooterComponent={<View style={{height: 20}}/>}
                  keyExtractor={((item, index) => index.toString())}
                  inverted={true}
                  data={chatData} renderItem={renderItem}/>
              </View>
              <View style={{backgroundColor: color.palette.bgBlue30, paddingBottom: insets.bottom}}>
                <View style={styles.chatContainer}>
                  <View style={{flex: 1}}>
                    <TextField
                      numberOfLines={2} multiline={true}
                      value={textMessage} onChangeText={setTextMessage}
                      inputPreset={'roundMessage'}
                      placeholder={'Enter Message'}
                      rightIcon={'arrow-circle-up'} rightIconTheme={canSend || !!urlImgBefore ? 'regular' : 'solid'}
                      rightIconPreset={canSend || !!urlImgBefore ? 'normal' : 'normalDisabled'}
                      rightIconPress={handleSend}
                      leftIconPress={handleUpLoadImg}
                      leftIconPreset="normalDisabled"
                      leftIcon="image-sold"
                      leftIconTheme="solid"
                      inputStyle={{
                        marginLeft: 5, maxHeight: 60, paddingTop: 0, paddingBottom: 0,
                        textAlignVertical: 'center'
                      }}
                      urlImgMsg={urlImgBefore}
                      onRemoveImg={onRemoveImg}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </PureBottomModal>
        <AlertModal/>
      </>
    )
  },
)

const styles = StyleSheet.create({
  body: {
    backgroundColor: color.palette.white,
    flex: 1,
  },
  chatContainer: {
    alignItems: 'center', borderTopColor: color.palette.grey,
    borderTopWidth: 1.5, flexDirection: 'row', paddingBottom: isIos ? 10 : 3, paddingHorizontal: 20, paddingTop: 11
  },
  container: {
    backgroundColor: color.palette.white,
    flex: 1,
  },
  contentContainerStyle: {
    marginVertical: 20,
    paddingHorizontal: 10,
  },
  mainContainer: {
    backgroundColor: color.palette.white,
  },
  modalBtn: {
    marginVertical: 8
  },
  statusBar: {
    backgroundColor: color.palette.bgBlue,
  },
});
