import React from "react"
import { StyleProp, StyleSheet, TextInput, TextInputProps, TextStyle, View, ViewStyle } from "react-native"
import { color } from "../../theme"
import { translate, TxKeyPath } from "../../i18n"
import { Text } from "../text/text"
import fonts from "../../theme/base_fonts"
import { SvgIcon } from "../icon/svg-icon"
import { TextPresets } from "../text/text.presets"
import { SvgIconPresets, ThemeTypes } from "../icon/svg-icon.props"
import { isAndroid } from "../../utils/platform";
import { MessageImage } from "../avatar-image/message-image"

// the base styling for the container
const CONTAINER: ViewStyle = {
  paddingVertical: 0,
}

// the base styling for the TextInput
const LABEL: TextStyle = {
  marginBottom: 5,
  marginHorizontal: isAndroid ? 4 : 0,
  color: color.palette.black,
}

const INPUT: TextStyle = {
  flex: 1,
  marginHorizontal: isAndroid ? 4 : 0,
  paddingHorizontal: 12,
  // backgroundColor: color.palette.grey,
  borderWidth: 1.5, borderColor: '#BBC2DC1A', borderRadius: 8,

  backgroundColor: color.palette.white,
}

const textFieldPresets = {
  default: {},
  brand: {},
  primary: {},
  primaryDisabled: {},
  light: {},
  half: {
    flexBasis: isAndroid ? '48%' : '45%',
  },
  grow: {
    flexGrow: 1,
    marginLeft: 8,
  },
}

const inputPresets = {
  default: {
    shadowColor: color.palette.black,
    shadowOffset: {width: 0, height: isAndroid ? 8 : 5},
    shadowOpacity: isAndroid ? 0.01 : 0.1,
    shadowRadius: 3,
    elevation: 8,
  },
  brand: {
    borderRadius: 8,
    borderWidth: 1, borderColor: color.palette.greyMedH
  },
  primary: {
    backgroundColor: color.palette.white,
    shadowColor: color.palette.black,
    shadowOffset: {width: 0, height: isAndroid ? 8 : 5},
    shadowOpacity: isAndroid ? 0.01 : 0.1,
    shadowRadius: 3,
    elevation: 8,
  },
  primaryDisabled: {
    backgroundColor: color.palette.greyLight,
    shadowColor: color.palette.black,
    shadowOffset: {width: 0, height: isAndroid ? 8 : 5},
    shadowOpacity: isAndroid ? 0.01 : 0.1,
    shadowRadius: 3,
    elevation: 8,
  },
  light: {
    shadowColor: color.palette.black,
    shadowOffset: {width: 0, height: isAndroid ? 8 : 5},
    shadowOpacity: isAndroid ? 0.01 : 0.1,
    shadowRadius: 3,
    elevation: 8,
  },
  round: {
    borderRadius: 20,
    borderWidth: 1,
    borderColor: color.palette.blue,
    backgroundColor: color.palette.transparent,
    shadowColor: color.palette.white,
    marginBottom: isAndroid ? 10 : 0,
  },
  roundMessage: {
    borderRadius: 20,
    borderWidth: 1,
    borderColor: color.palette.blue,
    backgroundColor: color.palette.transparent,
    shadowColor: color.palette.white,
    marginBottom: isAndroid ? 10 : 0,
    paddingTop: isAndroid ? 10 : 16, paddingBottom: isAndroid ? 10 : 16,
  },
}

export interface TextFieldProps extends TextInputProps {
  keyboardType?: string
  value?: string
  autoCapitalize?: any
  editable?: any
  maxLength?: any
  autoFocus?: any
  secureTextEntry?: any
  numberOfLines?: any
  multiline?: boolean
  onChangeText?: (e) => void
  onPress?: (e) => void
  onFocus?: (e) => void
  /**
   * The placeholder i18n key.
   */
  placeholderTx?: TxKeyPath

  /**
   * The Placeholder text if no placeholderTx is provided.
   */
  placeholder?: string

  /**
   * The label i18n key.
   */
  labelTx?: TxKeyPath

  /**
   * The label text if no labelTx is provided.
   */
  label?: string

  /**
   * Optional container style overrides useful for margins & padding.
   */
  style?: StyleProp<ViewStyle>

  /**
   * Optional style overrides for the input.
   */
  inputStyle?: StyleProp<TextStyle>

  /**
   * Various look & feels.
   */
  preset?: keyof typeof textFieldPresets
  inputPreset?: keyof typeof inputPresets

  forwardedRef?: any

  leftIcon?: string
  leftIconTheme?: ThemeTypes
  leftIconPreset?: SvgIconPresets
  leftIconPress?: (e) => void
  rightIcon?: string | React.ReactNode
  rightIconTheme?: ThemeTypes
  rightIconPreset?: SvgIconPresets
  rightIconPress?: (e) => void
  leftOuterIcon?: string
  rightOuterIcon?: string | React.ReactNode
  rightOuterIconPreset?: SvgIconPresets
  rightOuterIconPress?: (e) => void
  labelPreset?: TextPresets
  urlImgMsg?: string;
  onRemoveImg?: () => void;
}

/**
 * A component which has a label and an input together.
 */
export function TextField(props: TextFieldProps) {
  const {
    onPress,
    placeholderTx,
    placeholder,
    labelTx,
    label,
    preset = "default",
    inputPreset,
    style: styleOverride,
    inputStyle: inputStyleOverride,
    forwardedRef,
    leftIcon,
    leftIconTheme,
    leftIconPress,
    leftIconPreset,
    rightIcon,
    rightIconTheme,
    rightIconPress,
    rightIconPreset = "innerTextField",
    rightOuterIcon,
    rightOuterIconPreset,
    rightOuterIconPress,
    labelPreset,
    value,
    urlImgMsg,
    onRemoveImg,
    ...rest
  } = props

  const containerStyles = [CONTAINER, textFieldPresets[preset], styleOverride]
  const inputStyles = [
    INPUT,
    leftIcon ? {paddingLeft: 42} : {}, rightIcon ? {paddingRight: 42} : {},
    inputPresets[inputPreset] || inputPresets[preset] || inputPresets.default,
  ]
  const actualPlaceholder = placeholderTx ? translate(placeholderTx) : placeholder || ""

  return (
    <View style={containerStyles}>
      {label || labelTx ? (
        <Text preset={labelPreset} tx={labelTx} text={label} style={LABEL}/>
      ) : null}
      <View style={{flexDirection: "row"}}>
        <View style={inputStyles}>
          {!!urlImgMsg &&
          <MessageImage isInput onClose={onRemoveImg} isClose styleCustom={{marginVertical: 10, marginLeft: 10}}
                        url={urlImgMsg}/>}
          <TextInput
            placeholder={actualPlaceholder}
            placeholderTextColor={color.palette.lighterGrey}
            underlineColorAndroid={color.transparent}
            value={value}
            style={[{
              ...fonts.type.base(16), color: color.palette.dark,
              paddingTop: isAndroid ? 10 : 16, paddingBottom: isAndroid ? 10 : 16,
              textAlignVertical: rest?.numberOfLines > 1 || rest?.multiline ? 'top' : 'center',
              outline: '0 solid transparent',
            }, inputStyleOverride]}
            {...rest}
            ref={forwardedRef}
          />
          {leftIcon && (
            <View style={!!urlImgMsg ? styles.leftIconImage : styles.leftIcon}>
              <SvgIcon
                icon={leftIcon} preset={leftIconPreset} theme={leftIconTheme || "regular"}
                onPress={leftIconPress}
              />
            </View>
          )}
          {rightIcon && (
            <View style={!!urlImgMsg ? styles.rightIconImage : styles.rightIcon}>
              {typeof rightIcon === 'string' ? (
                <SvgIcon
                  icon={rightIcon} preset={rightIconPreset} theme={rightIconTheme || "regular"}
                  onPress={rightIconPress}
                />
              ) : rightIcon}
            </View>
          )}

        </View>
        {typeof rightOuterIcon === 'string' ? (
          <View style={styles.rightOuterIcon}>
            <SvgIcon
              icon={rightOuterIcon} preset={rightOuterIconPreset}
              onPress={rightOuterIconPress}
            />
          </View>
        ) : rightOuterIcon}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  leftIcon: {
    bottom: 0, flex: 1, justifyContent: "center", left: 16,
    position: "absolute", top: 0,
  },
  leftIconImage: {
    bottom: 10, flex: 1, justifyContent: "center", left: 16,
    position: "absolute",
  },
  rightIcon: {
    bottom: 0, flex: 1, justifyContent: "center", position: "absolute",
    right: 16, top: 0,
  },
  rightIconImage: {
    bottom: 10, flex: 1, justifyContent: "center", position: "absolute",
    right: 16,
  },
  rightOuterIcon: {justifyContent: "center", paddingHorizontal: 10},

})
