import React, {FC, useState, useCallback} from "react"
import {View, SafeAreaView, ScrollView, ViewStyle, TouchableOpacity} from "react-native"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Text, TextField} from "../../../components"
import {Header, Button} from "../../../components";
import {SvgIcon} from "../../../components/icon/svg-icon";
import {observer} from "mobx-react-lite";
import {ParamListBase, RouteProp, useFocusEffect} from "@react-navigation/native";
import MobileInput from "../../../components/text-field/mobile-input";
import {color} from "../../../theme";
import {NavigatorParamList} from "../../../navigators";
import {BackdropModal} from "../../../components/backdrop-modal/backdrop-modal";
import {useStores} from "../../../models";
// import {CountryCode} from "react-native-country-picker-modal";
import {LoadingModal} from "../../../components/backdrop-modal/loading-modal";
import {constants} from "../../../utils/constants";
import {IKvItemCheckModel, KvItemPath} from "../../../models/app/kv-item";
import {PickerSelectField} from "../../../components/picker-select/picker-select";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";
import {isAndroid} from "../../../utils/platform";
import {identifyWithUserAccount, TrackEvents, trackWithUserAccount} from "../../../services/track";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import {SelectTextField} from "../../../components/text-field/select-text-field";
import {parseLocation} from "../../../models/app/location";

let timeoutVal: NodeJS.Timeout = null

export interface UserAccountEditScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'businessAccountEditScreen'>
}

export const BusinessAccountEditScreen: FC<UserAccountEditScreenProps> = observer(({navigation, route}) => {
  const isCreate = route.params?.isCreate;
  const {app} = useStores();
  const {userAccount} = app;
  const businessProfile = userAccount?.businessProfile
  const selectedLocation = businessProfile?.location
  const [loading, setLoading] = useState(false)
  const [industryList, setIndustryList] = useState<Array<IKvItemCheckModel>>([]);

  const [modalVisible, setModalVisible] = useState(false);
  const [name, setName] = useState('');
  const [industry, setIndustry] = useState(0);
  const [industryOpen, setIndustryOpen] = useState(false)

  const [mobile, setMobile] = useState({
    value: userAccount?.mobile || '',
    country: userAccount?.countryCode || 'AU',
  });
  const onSetPhoneNumber = (data: any) => {
    setMobile(data)
  }

  const toggleIndustryOpen = () => {
    setIndustryOpen(!industryOpen)
  }

  useFocusEffect(useCallback(() => {
    (async () => {
      const industryRes = await app.environment.api.getKvItemList(KvItemPath.industry)
      if (industryRes.kind !== 'ok') {
        AlertModalStore.alert('Error', industryRes.message)
        return
      }
      setIndustryList(industryRes?.items?.map(it => ({
        ...it, checked: false
      })))
      if (isCreate) return
      setLoading(true)
      try {
        const res = await app.getMe()
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        setName(businessProfile?.name || '')
        setMobile({value: businessProfile?.telephone, country: businessProfile?.countryCode})
        setIndustry(businessProfile?.industry || 0)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    })()
  }, []))

  const onSaveData = async (): Promise<boolean> => {
    if (!name.trim() || !mobile?.value?.trim() || !selectedLocation?.id) {
      AlertModalStore.alert('Error', 'Please enter require fields')
      return false
    }
    setLoading(true)
    try {
      if (isCreate) {
        const res = await app.environment.api.createBusinessProfile({
          name: name,
          telephone: mobile.value,
          countryCode: mobile?.country,
          industry,
          ...(selectedLocation?.id ? {location: selectedLocation?.id} : null),
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return false
        }
        await identifyWithUserAccount(TrackEvents.CreateBusinessProfile, app?.userAccount)
        await trackWithUserAccount(TrackEvents.CreateBusinessProfile, app?.userAccount)
        const updateRes = await app.updateUserProfile({businessProfile: res.businessProfile.id})
        if (updateRes.kind !== 'ok') {
          AlertModalStore.alert('Error', updateRes.message)
          return false
        }
      } else {
        const res = await app.environment.api.updateBusinessProfile(userAccount.businessProfile.id, {
          name,
          telephone: mobile.value,
          countryCode: mobile?.country,
          ...(industry ? {industry: industry} : null),
          ...(businessProfile?.logo?.id ? {logo: businessProfile?.logo?.id} : null),
          website: businessProfile?.website,
          outline: businessProfile?.outline,
          fbLink: businessProfile?.fbLink,
          instaLink: businessProfile?.instaLink,
          twLink: businessProfile?.twLink,
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return false
        }
      }
      identifyWithUserAccount(TrackEvents.SetupBusinessProfile, app?.userAccount)
      return true
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
    return false
  }

  const handleCloseResult = async () => {
    if (timeoutVal) clearTimeout(timeoutVal)
    handleSaveDone()
  }
  const showResult = () => {
    if (timeoutVal) clearTimeout(timeoutVal)
    setModalVisible(true)
    timeoutVal = setTimeout(handleCloseResult, 1000)
  }

  const handleSaveOnly = async () => {
    const saved = await onSaveData()
    if (saved) showResult()
  }

  const handleSaveAndCont = async () => {
    const saved = await onSaveData()
    if (saved) navigation.navigate('businessAccountProfileEditScreen', {skip: isCreate})
    // navigation.goBack()
  }

  const handleSaveDone = () => {
    setModalVisible(false)
    app.user.setRole(constants.roles.BUSINESS)
    navigation.goBack()
  }

  return (
    <>
      <PureBottomModal onClose={() => navigation.goBack()}>
        <SafeAreaView style={{flex: 1}}>
          <Header
            preset={"modalHeader"}
            headerText="Business account" titlePreset="headerTitle"
            rightIcon={"times"} rightIconPreset={"smallBlue"}
            onRightPress={() => navigation.goBack()}
          />
          <ScrollView style={BODY}>
            <TextField
              value={name}
              onChangeText={setName}
              preset={'primary'}
              placeholder="Business Name"
              label="Business Name*"
              labelPreset="primary"
            />
            <View style={{height: 20}}/>
            <View style={{marginHorizontal: isAndroid ? 4 : 0,}}>
              <Text preset={'primary'} text={'Business Number*'}/>
            </View>
            <View style={{zIndex: 999}}>
              <MobileInput
                setPhoneNumber={onSetPhoneNumber}
                defaultValue={mobile?.value}
                placeholder={'Enter your number'}
                codeTextStyle={{color: color.palette.black80}}
                defaultCode={mobile?.country}
                textInputProps={{maxLength: 10}}
              />
            </View>
            <View style={{height: 20}}/>
            <View style={{marginBottom: 20}}>
              <SelectTextField
                onPress={() => navigation.navigate('businessLocationsScreen')}
                preset={"primary"}
                label={"Where you operate*"} labelPreset={"primary"}
                placeholder="Select from list"
                rightIcon={'chevron-right'}
                value={parseLocation(selectedLocation) || ''}
              />
            </View>

            <View style={{zIndex: 99}}>
              <PickerSelectField
                onOpen={toggleIndustryOpen}
                onClose={toggleIndustryOpen}
                value={industry}
                onValueChange={setIndustry} items={industryList.map(item => ({
                key: item?.id, value: item?.id, label: item?.name
              }))}
                preset={"default"}
                label={"What Industries do you operate in*"} labelPreset={"bold"}
                placeholder="..."
                rightIcon={industryOpen ? 'chevron-down' : 'chevron-right'}
              />
            </View>

            <View style={{height: 48}}/>
            <View style={BUTTON_CONTAINER}>
              <Button
                disabled={loading}
                onPress={handleSaveOnly}
                style={{flex: 1}}
                text="Save" preset="brand"/>
              <View style={{width: 20}}/>
              <TouchableOpacity
                disabled={loading}
                onPress={handleSaveAndCont}
                style={{flex: 1, alignItems: 'flex-end', justifyContent: 'center'}}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <Text
                    text="Business Profile"
                    preset="underTextFieldButton"
                  />
                  <View style={{width: 6}}/>
                  <SvgIcon icon={'arrow-right'} preset={'small'}/>
                </View>
                {isCreate && (
                  <Text
                    text="Save & Continue"
                    preset="iconButton"
                    // onPress={() => navigation.navigate('businessAccountProfileEditScreen')}
                  />
                )}
              </TouchableOpacity>
            </View>
          </ScrollView>
          <BackdropModal visible={modalVisible} onClose={handleSaveDone}>
            <View style={{
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <SvgIcon preset={'alertIcon'} icon={'check-circle'}/>
              <Text style={{marginTop: 16}} preset="alertTitle">Done</Text>
            </View>
          </BackdropModal>
        </SafeAreaView>
      </PureBottomModal>
      <LoadingModal visible={loading}/>
      <AlertModal/>
    </>
  )
});

export const BODY: ViewStyle = {
  paddingHorizontal: isAndroid ? 20 : 26,
  paddingBottom: 16
}

export const BUTTON_CONTAINER: ViewStyle = {
  marginVertical: 8,
  justifyContent: 'space-between',
  flexDirection: 'row'
}
