import React, { FC, useState } from 'react';
import {
  StyleSheet,
  SafeAreaView,
  View,
} from 'react-native';
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { observer } from "mobx-react-lite";
import { color } from "../../../theme";
import { Button, Header } from "../../../components";
import { ContactListScreen } from "./contacts";
import { IBusinessContactModel } from "../../../models/app/contact";
import { BottomModal } from "../../../components/backdrop-modal/bottom-modal";
import {ParamListBase} from "@react-navigation/native";

export interface MainContactScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
}

export const MainContactScreen: FC<MainContactScreenProps> = observer(
  ({navigation}) => {
    const [listItem, setListItem] = useState<Array<IBusinessContactModel>>([]);
    const [visibleModal, setVisibleModal] = useState(false)

    const renderActiveTab = () => {
      return (
        <ContactListScreen
          listItem={listItem}
          setListItem={setListItem} navigation={navigation}/>
      )
    }

    const onAdd = () => {
      if (listItem?.length > 0) setVisibleModal(true)
      else navigation.navigate('addContactScreen')
    }

    const renderRightIcon = (): any => {
      const result = {rightIcon: 'plus', rightIconTheme: 'regular', rightIconPreset: 'smallBrand'}
      result.rightIcon = (listItem?.length > 0) ? 'ellipsis-h' : 'plus'
      result.rightIconTheme = (listItem?.length > 0) ? 'solid' : 'regular'
      return result
    }

    return (
      <>
        <SafeAreaView style={styles.container}>
          <Header
            preset={'primary'}
            headerText={'Contacts'} titlePreset={'headerTitle'}
            {...renderRightIcon()} onRightPress={onAdd}
          />
          <View style={{paddingLeft: 10, flex: 1}}>
            {renderActiveTab()}
          </View>
        </SafeAreaView>
        <BottomModal visible={visibleModal} onClose={() => setVisibleModal(false)}>
          <View>
            <Button
              onPress={() => {
                setVisibleModal(false)
                navigation.navigate('addContactScreen')
              }}
              style={{marginTop: 0}}
              disabled={false}
              preset={'primary'}
              text={'Add Contact'}
            />
            <Button
              onPress={() => {
                setVisibleModal(false)
                navigation.navigate('deleteContactScreen')
              }}
              style={{marginTop: 10}}
              disabled={false}
              preset={'brand'}
              text={'Delete Contact'}
            />
            <Button
              onPress={() => setVisibleModal(false)}
              style={{marginTop: 10}}
              disabled={false}
              preset={'primaryOutLine'}
              text={'Cancel'}
            />
          </View>
        </BottomModal>
      </>
    )
  },
)

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.palette.white,
    flex: 1,
  },
});
