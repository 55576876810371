import {View, ViewStyle} from "react-native"

const BG_GRADIENT: ViewStyle = {
  position: "absolute", left: 0, right: 0, top: 0, bottom: 0,

}

export interface GradientBackgroundProps {
  colors: string[]
}

export function GradientBackground(props: GradientBackgroundProps) {
  const {colors} = props
  return (
    <View style={[BG_GRADIENT, {background: `linear-gradient(0deg, ${colors[1]} 0%, ${colors[0]} 40.2%)`}]}></View>)
}
