import {ParamListBase} from "@react-navigation/native"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import React, {FC} from "react"
import {SafeAreaView, SectionList, View, ViewStyle} from "react-native"
import {GradientBackground, Header, IconButton, Text} from "../../../components"
import {color} from "../../../theme"
import {useStores} from "../../../models"
import {constants} from "../../../utils/constants"
import {observer} from "mobx-react-lite"
import {isAndroid} from "../../../utils/platform";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";

export interface SettingsScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
}

const SETTING_SECTION = {
  title: "App Settings",
  data: [
    {
      id: "help_support",
      type: "Help and Support",
      leftIcon: "message-question",
    },
    {
      id: "privacy",
      type: "Privacy Policy",
      leftIcon: "shield",
    },
    {
      id: "terms",
      type: "Terms and Conditions",
      leftIcon: "clipboard-check",
    },
  ],
};

const workerSections = [
  {
    title: null,
    data: [
      {
        id: "user_account_detail",
        type: "User Account Details",
        leftIcon: "id-card",
      },
      {
        id: "switch_account",
        type: "Switch Account",
        leftIcon: "retweet-alt",
      },
    ],
  },
  {
    title: "Worker Account",
    data: [
      {
        id: "worker_profile",
        type: "Profile",
        leftIcon: "address-book",
      },
      {
        id: "skills",
        type: "Skills",
        leftIcon: "list-check",
      },
      {
        id: "preferences",
        type: "Preferences",
        leftIcon: "bars-progress",
      },
    ],
  },
  SETTING_SECTION,
]

const userSections = [
  {
    title: null,
    data: [
      {
        id: "user_account_detail",
        type: "User Account Details",
        leftIcon: "id-card",
      },
      {
        id: "switch_account",
        type: "Switch Account",
        leftIcon: "retweet-alt",
      },
    ],
  },
  SETTING_SECTION,
]

const businessSections = [
  {
    title: null,
    data: [
      {
        id: "user_account_detail",
        type: "User Account Details",
        leftIcon: "id-card",
      },
      {
        id: "switch_account",
        type: "Switch Account",
        leftIcon: "retweet-alt",
      },
    ],
  },
  {
    title: "Business Account",
    data: [
      {
        id: "business_account_detail",
        type: "Details",
        leftIcon: "address-book",
      },
      {
        id: "business_profile",
        type: "Profile",
        leftIcon: "file-user",
      },
      {
        id: "business_managers",
        type: "Managers",
        leftIcon: "user-plus",
      },
    ],
  },
  SETTING_SECTION,
]

const managerSections = [
  {
    title: null,
    data: [
      {
        id: "user_account_detail",
        type: "User Account Details",
        leftIcon: "id-card",
      },
      {
        id: "switch_account",
        type: "Switch Account",
        leftIcon: "retweet-alt",
      },
    ],
  },
  SETTING_SECTION,
]

export const SettingsScreen: FC<SettingsScreenProps> = observer(({navigation}) => {
  const {app} = useStores()
  let settingSections = []

  switch (app.user.role) {
    case constants.roles.USER:
      settingSections = userSections
      break
    case constants.roles.BUSINESS:
      settingSections = businessSections
      break
    case constants.roles.MANAGER:
      settingSections = managerSections
      break
    case constants.roles.WORKER:
      settingSections = workerSections
      break
    case constants.roles.GUEST:
      settingSections = workerSections
      break
  }

  const onHandleNavigate = async (item) => {
    switch (item.id) {
      case "user_account_detail":
        app.getMe()
        navigation.navigate('userAccountEditScreen', {type: 'edit'})
        break
      case "preferences":
        navigation.navigate("preferencesScreen")
        break
      case "switch_account":
        navigation.navigate("chooseAccessScreen")
        break
      case "skills":
        navigation.navigate("workerSkillEditScreen")
        break
      case "business_account_detail":
        navigation.navigate("businessAccountEditScreen")
        break
      case "business_profile":
        navigation.navigate("businessAccountProfileEditScreen")
        break
      case "business_managers":
        navigation.navigate("businessManagerScreen")
        break
      case "worker_profile":
        navigation.navigate("workerDetailsScreen")
        break
      case "help_support":
        window.location.href = 'mailto:support@tap4work.com.au'
        break
      case "privacy":
        navigation.navigate('inAppBrowser', {url: 'https://www.tap4work.com.au/privacy'})
        break
      case "terms":
        navigation.navigate('inAppBrowser', {url: 'https://www.tap4work.com.au/terms'})
        break
      default:
        break
    }
  }

  const renderSection = ({item}) => {
    return (
      <View style={{marginHorizontal: 24, marginTop: 12}}>
        <IconButton
          onPress={() => onHandleNavigate(item)}
          leftIconProps={{icon: item.leftIcon, preset: "smallNoMargin"}} leftPreset={'settingIcon'}
          text={item.type} textPreset="settingText" textStyle={{flex: 1}}
          iconProps={{icon: "chevron-right", preset: "small"}}
          preset="primary"
        />
      </View>
    )
  }

  const onSignOut = async () => {
    app.onSignOut()
  }

  return (
    <SafeAreaView style={CONTAINER}>
      <GradientBackground colors={[color.palette.gradientStart, color.palette.gradientStop]}/>
      <Header headerText="Settings" preset="primary" titlePreset="headerTitle"/>
      <View style={ITEM}>
        <SectionList
          showsVerticalScrollIndicator={false}
          sections={settingSections}
          scrollEnabled
          stickySectionHeadersEnabled={false}
          keyExtractor={(item, index) => item + index}
          renderItem={renderSection}
          renderSectionHeader={({section: {title}}) => (
            <View style={{marginHorizontal: 24}}>
              <Text preset="settingTitle">{title}</Text>
            </View>
          )}
          renderSectionFooter={() => (<View style={{height: 32}}/>)}
          ListFooterComponent={<View style={{height: isAndroid ? 30 : 0}}/>}
        />
        <View style={SIGN_OUT}>
          <IconButton
            text="Sign out"
            textPreset="labelPrimary"
            iconProps={{icon: "sign-out", preset: "smallPrimary"}}
            onPress={onSignOut}
          />
        </View>
      </View>
      <AlertModal/>
    </SafeAreaView>
  )
})

export const CONTAINER: ViewStyle = {
  flex: 1,
}

export const SIGN_OUT: ViewStyle = {
  alignItems: "center",
  marginVertical: 10,
}

export const ITEM: ViewStyle = {
  flex: 1,
}
