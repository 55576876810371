import React, {FC} from 'react';
import {StyleSheet, SafeAreaView, ScrollView, View} from 'react-native';

import {observer} from "mobx-react-lite";
import {color} from "../../../theme";
import {BulletItem, Text} from "../../../components";
import {FlatCard} from "../../../components/card/flat-card";
import {IWorkerProfileModel} from "../../../models/app/worker-profile";

export interface WorkerProfileAboutProps {
  workerProfile: IWorkerProfileModel
}

export const WorkerProfileAbout: FC<WorkerProfileAboutProps> = observer(
  ({workerProfile}) => {

    return (
      <SafeAreaView style={styles.container}>
        <ScrollView style={styles.mainContainer}>
          <FlatCard noBorder={true} title={'Biography'} titlePreset={'mediumBold'}>
            <Text preset={'medium'}>{workerProfile?.bio}</Text>
          </FlatCard>
          <FlatCard noBorder={true} title={'Preferred Location(s)'} titlePreset={'mediumBold'}>
            {workerProfile?.preferredLocations.map((item, index) => (
              <BulletItem key={index + '' + item?.id} text={item.name} preset={'body1'}/>
            ))}
          </FlatCard>
          <FlatCard noBorder={true} title={'Industries'} titlePreset={'mediumBold'}>
            {workerProfile?.industries.map((item, index) => (
              <BulletItem key={index + '' + item?.id} text={item.name} preset={'body1'}/>
            ))}
          </FlatCard>
          <FlatCard noBorder={true} title={'Roles'} titlePreset={'mediumBold'}>
            {workerProfile?.workerRoles.map((item, index) => (
              <BulletItem key={index + '' + item?.id} text={item.name} preset={'body1'}/>
            ))}
          </FlatCard>
          <FlatCard noBorder={true} title={'Experience'} titlePreset={'mediumBold'}>
            {workerProfile?.experiences.map((item, index) => (
              <BulletItem key={index + '' + item?.id} text={item.name} preset={'body1'}/>
            ))}
          </FlatCard>
          <FlatCard noBorder={true} title={'Skills'} titlePreset={'mediumBold'}>
            {workerProfile?.skills.map((item, index) => (
              <BulletItem key={index + '' + item?.id} text={item.name} preset={'body1'}/>
            ))}
          </FlatCard>
          <FlatCard noBorder={true} title={'Qualifications'} titlePreset={'mediumBold'}>
            {workerProfile?.qualifications.map((item, index) => (
              <BulletItem key={index + '' + item?.id} text={item.name} preset={'body1'}/>
            ))}
          </FlatCard>
          <FlatCard noBorder={true} title={'Other'} titlePreset={'mediumBold'}>
            {[
              // {title: 'Traineership', value: 'Name of Traineership'},
              // {title: 'Apprenticeship', value: 'Name of Apprenticeship'},
              {
                show: workerProfile?.indigenousStatuses?.length > 0, title: 'Indigenous Status',
                value: workerProfile?.indigenousStatuses?.map(item => item?.name).join(', ')
              },
              {
                show: !!workerProfile?.rightToWork?.name,
                title: 'Right to Work',
                value: workerProfile?.rightToWork?.name
              },
              {show: true, title: 'Returned Ex Worker', value: workerProfile?.returnedWorker ? 'Yes' : 'No'},
              {show: true, title: 'Ex Service Person', value: workerProfile?.exServicePerson ? 'Yes' : 'No'},
            ].filter(it => it.show).map((item, index) => (
              <BulletItem key={index.toString()} preset={'body1'}>
                <Text preset={'body1'} style={{fontWeight: 'bold'}}>{item.title}: </Text>
                <Text preset={'body1'} style={{flex: 1, flexWrap: 'wrap'}}>{item.value}</Text>
              </BulletItem>
            ))}
          </FlatCard>
          <View style={{height: 100}}/>
        </ScrollView>
      </SafeAreaView>
    )
  },
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  mainContainer: {
    paddingHorizontal: 20,
    paddingTop: 10
  },
  pill: {
    alignItems: 'center',
    backgroundColor: color.palette.blue,
    borderRadius: 20,
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingVertical: 10
  },
});
