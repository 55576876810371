import * as React from "react"
import {View, SafeAreaView, FlatList} from "react-native"
import {FC, useCallback, useState} from "react"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Button, IconButton, Tag, Text, Header} from "../../../components"
import {color, spacing} from "../../../theme";

import {CardInfo} from "../../../components/card-info/card-info"
import {constants} from "../../../utils/constants";
import {useStores} from "../../../models";
import {NavigatorParamList} from "../../../navigators";
import {observer} from "mobx-react-lite";
import {CommonActions, useFocusEffect} from "@react-navigation/native";
import {AvatarImage} from "../../../components/avatar-image/avatar-image";
import {CONTACT_STATUS} from "../../../models/app/contact";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";
import {BUSINESS_ACCESS_STATUS} from "../../../models/app/business-access";
import {IBusinessProfileModel} from "../../../models/app/business-profile";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import AvatarDefault from "../../../components/avatar-image/AvatarDefault";

export type CardInfoData = {
  id: string,
  title: string,
  subTitle: string,
  invite: boolean
  role: string
  contactId?: number
  code?: string
  businessAccessId?: number
  businessProfile?: IBusinessProfileModel
  logoUrl?: string
}

// const dummyCardInfoData: Array<CardInfoData> = [
//   {title: 'Abyss Construction', subTitle: 'Manager', invite: true, role: constants.roles.MANAGER},
//   {title: 'Idea Box', subTitle: 'Owner', invite: false, role: constants.roles.BUSINESS},
//   {title: 'John Citizen', subTitle: 'Worker Account', invite: false, role: constants.roles.WORKER},
//   {title: 'Bob Creen', subTitle: 'User Account', invite: false, role: constants.roles.USER},
// ]

const defaultLogo = require("../../../../assets/images/default-company.png")
const defaultPhoto = require("../../../../assets/images/default-photo.png")

export interface ChooseAccessScreenProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
}

export const ChooseAccessScreen: FC<ChooseAccessScreenProps> = observer(({navigation}) => {
  const {app} = useStores()
  const {userAccount} = app
  const [loading, setLoading] = useState(false)
  const [accountRoles, setAccountRoles] = useState<Array<CardInfoData>>([])
  const hasWorker = app?.userAccount?.userRoles?.find(it => it.key === constants.roles.WORKER)
  const hasBusiness = app?.userAccount?.userRoles?.find(it => it.key === constants.roles.BUSINESS)

  const goHome = () => {
    navigation.dispatch(
      CommonActions.reset({
        index: 0, routes: [{name: 'main'}],
      })
    );
  }

  const loadData = async () => {
    setLoading(true)
    try {
      const res = await app.getMe()
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message, {onOk: {text: "OK", onPress: goHome}})
        return
      }
      const _accountRoles: CardInfoData[] = userAccount?.userRoles?.map(it => ({
        id: `role_${it.key}_${it.id}`,
        title: it.key === constants.roles.BUSINESS ? userAccount?.businessProfile?.name : app.userFullName(),
        logoUrl: it.key === constants.roles.BUSINESS
          ? userAccount?.businessProfile?.logo?.url
          : userAccount?.avatar?.url,
        role: it.key, subTitle: it.name, invite: false,
      } as CardInfoData))

      let _accountBusiness: CardInfoData[] = []
      const businessRes = await app?.environment?.api?.getBusinessManagersByUser({
        'filters[status][$eq]': [BUSINESS_ACCESS_STATUS.pending.key, BUSINESS_ACCESS_STATUS.accepted.key],
      })
      if (businessRes.kind === 'ok') {
        _accountBusiness = businessRes?.items
          ?.map(it => ({
            id: `business_${constants.roles.MANAGER}_${it.id}`,
            title: it?.businessProfile?.name,
            role: constants.roles.MANAGER,
            subTitle: constants.rolesName.MANAGER,
            invite: it.status === BUSINESS_ACCESS_STATUS.pending.key,
            logoUrl: it?.businessProfile?.logo?.url,
            businessProfile: it?.businessProfile,
            businessAccessId: it.id, code: it.code,
          } as CardInfoData))
      }
      let _accountContacts: CardInfoData[] = []
      const contactRes = await app.environment.api.getWorkerContacts({
        "filters[status][$eq]": CONTACT_STATUS.pending.key
      })
      if (contactRes.kind === 'ok') {
        _accountContacts = contactRes.items.map(it => ({
          id: `contact_${it.id}`, code: it?.code,
          title: it?.businessProfile?.name, role: null, subTitle: 'Contact',
          invite: true, contactId: it.id, logoUrl: it?.businessProfile?.logo?.url
        } as CardInfoData))
      }
      setAccountRoles([..._accountRoles, ..._accountContacts, ..._accountBusiness])
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useFocusEffect(useCallback(() => {
    // getAppVersion()
    loadData()
  }, []))

  const onHandleNavigate = async (item: CardInfoData) => {
    if (loading) return
    if (item.invite) {
      navigation.goBack()
      if (item.contactId) {
        navigation.navigate('chooseAccessContactScreen', {contactId: item.contactId, code: item.code});
      } else if (item.businessAccessId) {
        navigation.navigate('chooseAccessInviteScreen', {businessId: item.businessAccessId, code: item.code});
      } else {
        navigation.navigate('chooseAccessInviteScreen');
      }
    } else if (item.businessAccessId) {
      if (!item.businessProfile.id) {
        AlertModalStore.alert('Error', 'Can not switch access.')
        return
      }
      const res = await app.environment.api.getBusinessProfileDetail(item.businessProfile.id)
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message)
        return
      }
      app.user.setBusinessProfile(res.businessProfile)
      app.user.setRole(item.role)
      app.environment.api.onUpdateUser({
        lastUserRole: {key: item.role},
        lastBusinessProfile: {id: res.businessProfile.id}
      })
      navigation.navigate('main')
    } else {
      app.user.setRole(item.role)
      app.environment.api.onUpdateUser({lastUserRole: {key: item.role}})
      navigation.navigate('main')
    }
  }

  function renderItem({item}: { item: CardInfoData }) {
    const _isBusiness = item?.role === constants.roles.BUSINESS
    return (
      <View style={{marginVertical: 4,}}>
        <CardInfo
          leftIcon={() => item.logoUrl
              ? <AvatarImage
                 source={item.logoUrl ? {uri: item.logoUrl} : _isBusiness ? defaultLogo : defaultPhoto} preset={"small"}/>
              :
              <AvatarDefault name={item?.businessProfile?.name} preset={"small"}/>}
          title={item.title}
          subTitle={item.subTitle}
          titlePreset="mediumBoldBlue"
          subTitlePreset="mediumBlue"
          rightIcon={
            item.invite
              ? () => <Tag children="Invitation" icon="envelope" preset="smallTag" textPreset="smallSemiBold"/>
              : null
          }
          onPress={() => onHandleNavigate(item)}
        />
      </View>
    );
  }

  const handleSignOut = async () => {
    navigation.navigate('main')
    app.onSignOut()
  }

  return (
    <>
      <PureBottomModal onClose={() => navigation.goBack()}>
        <SafeAreaView style={{flex: 1}}>
          <Header
            preset={"pureTransparent"}
            rightIcon="times" rightIconPreset="smallBlue" onRightPress={() => navigation.goBack()}
          />
          <View style={{flex: 1}}>
            <View style={{flex: 1}}>
              <View style={{marginHorizontal: 20, alignItems: 'center', flexDirection: 'row'}}>
                <View style={{}}>
                  <AvatarImage
                    source={userAccount?.avatar?.url ? {uri: userAccount?.avatar?.url} : defaultPhoto}
                    preset={"mediumRound"}/>
                </View>
                <View style={{flex: 1, marginLeft: 8}}>
                  <Text numberOfLines={1} preset={'h1'}
                        style={{color: color.palette.primary}}>{app?.userFullName()}</Text>
                  <View style={{height: 2}}/>
                  <Text numberOfLines={1} preset={'body1'}>{userAccount?.email}</Text>
                </View>
              </View>
              <View style={{marginHorizontal: 20, marginTop: 45}}>
                <Text preset={"h2"} style={{fontWeight: '600', color: color.palette.primary}}>Choose Access</Text>
                <Text preset={"body1"} style={{
                  marginTop: spacing[3],
                }}>Enter the email address associated with your account</Text>
                <View style={{height: 20}}/>
              </View>
              <FlatList
                refreshing={loading} onRefresh={loadData}
                data={accountRoles}
                renderItem={renderItem}
                keyExtractor={item => item.id}
              />
            </View>

            <View style={{marginHorizontal: 20, marginBottom: 10}}>
              <View style={{marginBottom: 24, justifyContent: 'space-between'}}>
                {!hasBusiness && (
                  <Button
                    onPress={() => navigation.navigate('businessAccountEditScreen', {isCreate: true})}
                    style={{width: '100%', marginTop: 16}}
                    text={"Create Business Profile"} preset="primary"/>
                )}
                {!hasWorker && (
                  <Button
                    onPress={() => navigation.navigate('createWorkerProfileScreen')}
                    style={{width: '100%', marginTop: 16}}
                    text={"Create Worker Profile"} preset="primary"/>
                )}
              </View>
              <View style={{alignItems: 'center'}}>
                <IconButton
                  text="Sign out"
                  iconProps={{icon: 'sign-out', preset: 'smallerLink'}}
                  textPreset={'iconButton'}
                  onPress={handleSignOut}
                />
              </View>
            </View>
          </View>
        </SafeAreaView>
      </PureBottomModal>
      <AlertModal/>
    </>
  )
})
