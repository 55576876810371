import React, {FC} from 'react';
import {SafeAreaView, View} from 'react-native';
import {observer} from "mobx-react-lite";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {ParamListBase, RouteProp} from "@react-navigation/native";
import {NavigatorParamList} from "../../navigators";
import {Header} from "../../components";

interface InAppBrowserProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, "inAppBrowser">
}

export const InAppBrowser: FC<InAppBrowserProps> = observer(({navigation, route}) => {
  const {url} = route.params

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: '#FFF'}}>
      <Header
        // headerText="Preferences" titlePreset="headerTitle"
        preset="modalHeader"
        rightIcon={"times"} rightIconPreset={"smallBlue"}
        onRightPress={() => navigation.goBack()}
      />
      <View style={{flex: 1,}}>
        <iframe title={'T4W'} style={{flex: 1}} src={url}/>
      </View>
    </SafeAreaView>
  );
})

