import * as React from "react"
import {TextStyle, TouchableOpacity, View, ViewStyle} from "react-native"
import {Text} from "../text/text"
import {color, spacing} from "../../theme"
import {CheckboxProps} from "./checkbox.props"
import {SvgIcon} from "../icon/svg-icon";

const ROOT: ViewStyle = {
  flexDirection: "row",
  paddingVertical: spacing[1],
  alignSelf: "flex-start",
}

const DIMENSIONS = {width: 16, height: 16}

const OUTLINE: ViewStyle = {
  ...DIMENSIONS,
  marginTop: 2, // finicky and will depend on font/line-height/baseline/weather
  justifyContent: "center",
  alignItems: "center",
  borderWidth: 2,
  borderColor: color.palette.greyInputBorder,
  borderRadius: 2,
}

// const FILL: ViewStyle = {
//   width: DIMENSIONS.width - 4,
//   height: DIMENSIONS.height - 4,
//   backgroundColor: color.primary,
// }

const LABEL: TextStyle = {paddingLeft: spacing[2], justifyContent: 'center', width: '100%'}

export function Checkbox(props: CheckboxProps) {
  const {checkIcon, multiline} = props
  const numberOfLines = multiline ? 0 : 1

  const rootStyle = [ROOT, props.style]
  const outlineStyle = [OUTLINE, props.outlineStyle]
  // const fillStyle = [FILL, props.fillStyle]

  const onPress = props.onToggle ? () => props.onToggle && props.onToggle(!props.value) : null

  return (
    <TouchableOpacity
      disabled={!props.onToggle}
      onPress={onPress}
      style={rootStyle}
    >
      <View style={outlineStyle}>
        {checkIcon ? checkIcon : (
          <SvgIcon
            preset={"primary"}
            icon={props.value ? "check-square" : "square-regular"}
            theme={props.value ? "solid" : "regular"}
          />
        )}
      </View>
      <View style={[LABEL, props.textStyle]}>
        <Text text={props.text} tx={props.tx} numberOfLines={numberOfLines}/>
      </View>
    </TouchableOpacity>
  )
}
