import React, {FC} from "react"
import {FlatList, TouchableOpacity, View} from "react-native";
import {Text} from "../text/text";
import {generateSearchItem} from "../../utils/utils";
import {IKvItemModel} from "../../models/app/kv-item";

export interface SearchBarSuggestProps {
  searchText: string
  data: Array<IKvItemModel>
  onItemPress?: (item: IKvItemModel) => void
}

export const SearchBarSuggest: FC<SearchBarSuggestProps> = (props) => {
  const {searchText, data, onItemPress} = props;
  const handleItemPress = (item: IKvItemModel) => {
    onItemPress && onItemPress(item)
  }
  return (
    <View style={{flex: 1, marginHorizontal: 20, marginVertical: 20}}>
      <Text preset={'primary'}>Suggestions</Text>
      <View style={{height: 20}}/>
      <FlatList
        data={data}
        keyExtractor={(item, index) => (index.toString())}
        renderItem={({item}) => (
          <TouchableOpacity
            onPress={() => handleItemPress(item)}
            style={{marginBottom: 8, paddingVertical: 10}}>
            <Text>{generateSearchItem(item.name, searchText).map((it, index) => (
              <Text
                key={index.toString()}
                preset={it.highlight ? 'suggestBold' : 'suggestDefault'}>{it.text}</Text>
            ))}</Text>
          </TouchableOpacity>
        )}/>
    </View>
  )
}

