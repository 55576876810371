import * as React from "react"
import { View, SafeAreaView, ScrollView, ViewStyle } from "react-native"
import { FC, useCallback, useState } from "react"
import { CommonActions, ParamListBase, RouteProp, useFocusEffect } from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { Text, Header, Button } from "../../../components"
import { color, spacing } from "../../../theme";


import { SvgIcon } from "../../../components/icon/svg-icon";
import { useStores } from "../../../models";
import { NavigatorParamList } from "../../../navigators";
import { CONTACT_STATUS, IBusinessContactModel } from "../../../models/app/contact";
import { BackdropModal } from "../../../components/backdrop-modal/backdrop-modal";
import { MODAL, VIEW_MODAL } from "../job-ad/styles";
import { AlertModalStore } from "../../../models/app/alert-modal-store";
import { AlertModal } from "../../../components/backdrop-modal/alert-modal";
import { LoadingModal } from "../../../components/backdrop-modal/loading-modal";

export interface ChooseAccessContactScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'chooseAccessContactScreen'>
}

let timeoutVal: NodeJS.Timeout = null

export const ChooseAccessContactScreen: FC<ChooseAccessContactScreenProps> = ({navigation, route}) => {
  const contactId = route.params?.contactId || 0
  const code = route.params?.code || ''
  const {app} = useStores()
  const [resultVisible, setResultVisible] = useState(false);
  const [loading, setLoading] = useState(false)
  const [contactDetail, setContactDetail] = useState<IBusinessContactModel>()

  const goHome = async () => {
    navigation.dispatch(
      CommonActions.reset({index: 0, routes: [{name: 'main'}],})
    );
  }

  const handleCloseResult = async () => {
    if (timeoutVal) clearTimeout(timeoutVal)
    setResultVisible(false)
    goHome()
  }

  const showResult = () => {
    if (timeoutVal) clearTimeout(timeoutVal)
    setResultVisible(true)
    timeoutVal = setTimeout(handleCloseResult, 1000)
  }

  const handleAction = async (accept: boolean) => {
    const res = await app?.environment?.api?.editContact(contactId, {
      code,
      status: accept ? CONTACT_STATUS.accepted.key : CONTACT_STATUS.rejected.key
    })
    if (res.kind !== 'ok') {
      AlertModalStore.alert('Error', res.message)
    } else {
      showResult()
    }
  }

  const loadData = async () => {
    try {
      setLoading(true)
      const res = await app.environment.api.getContactDetail(contactId)
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message, {onOk: {text: "OK", onPress: goHome}})
        return
      }
      setContactDetail(res.item)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }
  useFocusEffect(useCallback(() => {
    loadData()
  }, []))

  return (
    <SafeAreaView style={CONTAINER}>
      <Header
        preset={'modalHeader'}
        headerText={'Contact Invitation'}
        titlePreset={'headerTitle'}
        leftIcon={'arrow-left'} leftIconPreset={'normal'} onLeftPress={goHome}
      />
      <ScrollView style={BODY}>
        <View style={{marginHorizontal: spacing[3], marginBottom: 4}}>
          <Text preset={"body1"} style={{
            marginTop: spacing[3],
          }}>
            {contactDetail?.businessProfile?.name} has invited you to be a Contact of their Business Account in
            Tap4Work.
          </Text>
        </View>
        <View style={{marginHorizontal: spacing[3],}}>
          <Text preset={"body1"} style={{
            marginTop: spacing[3],
          }}>
            By Accepting this invitation, please note that {contactDetail?.businessProfile?.name} will have access to
            your Tap4Work 'My Wallet' details.
          </Text>
        </View>

        <View style={{height: spacing[7]}}/>

        <View style={BUTTON_CONTAINER}>
          <Button
            text="Accept" preset="brand"
            onPress={() => handleAction(true)}
          />
        </View>
        <View style={BUTTON_CONTAINER}>
          <Button
            text="Decline" preset="primary"
            onPress={() => handleAction(false)}
          />
        </View>
      </ScrollView>
      <BackdropModal visible={resultVisible} onClose={handleCloseResult}>
        <View style={MODAL}>
          <SvgIcon icon={"check-circle"} preset={"alertIcon"}/>
          <View style={VIEW_MODAL}>
            <Text preset={"alertTitle"}>Done</Text>
          </View>
        </View>
      </BackdropModal>
      <AlertModal/>
      <LoadingModal visible={loading}/>
    </SafeAreaView>
  )
}

export const CONTAINER: ViewStyle = {
  flex: 1,
  backgroundColor: color.palette.white,
}

export const BODY: ViewStyle = {
  paddingHorizontal: spacing[4],
}

export const BUTTON_CONTAINER: ViewStyle = {
  marginHorizontal: spacing[3],
  marginVertical: spacing[2],
}
