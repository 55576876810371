// const io = require('socket.io-client');
import {connect, Socket} from 'socket.io-client';
import {DEFAULT_API_CONFIG} from "./api-config";


export const AwSocket = (token: string): Socket => {
  const socket: Socket = connect(DEFAULT_API_CONFIG.socketUrl, {
    query: {token},
    timeout: 60000,
  });
  return socket
}

export class AwChatManager {
  private static instance: AwChatManager
  socket: Socket
  isAuthorized: boolean = false
  onChat?: (message: any) => void = null

  private constructor(token: string) {
    this.socket = connect(DEFAULT_API_CONFIG.socketUrl, {
      query: {token},
      timeout: 60000,
      transports: ["polling", "websocket"],
    });
    this.handleSocket()
  }

  handleSocket() {
    this.socket.on("connect", () => {
      console.log("SOCKET CONNECT")
    })

    this.socket.on("disconnect", (res, ack) => {
      console.log("SOCKET DISCONNECT", res, ack)
    })

    this.socket.on("success", (res, ack) => {
      console.log("SOCKET CONNECTED", res, ack)
      this.isAuthorized = true
      if (res) {
        const {action, data} = res
        console.log(action, data)
      }
    })

    this.socket.on("exception", (res, ack) => {
      console.log("ERROR", res, ack)
      this.isAuthorized = false
      this.disconnect()
    })

    this.socket.on('chat', (message) => {
      console.log(message, '54')
      this?.onChat && this?.onChat(message)
    });
  }

  static currentInstance(): AwChatManager {
    return AwChatManager.instance
  }

  static getInstance(token: string): AwChatManager {
    if (!AwChatManager.instance) {
      console.log("INIT AGAIN")
      AwChatManager.instance = new AwChatManager(token)
    } else {
      AwChatManager.instance.disconnect()
      AwChatManager.instance = new AwChatManager(token)
    }

    return AwChatManager.instance
  }

  disconnect() {
    if (!AwChatManager.instance.socket) return;
    try {
      AwChatManager.instance.socket.emit('forceDisconnect');
      AwChatManager.instance.socket.disconnect();
    } catch (e) {
      console.log(e, 73);
    }
  }
}
