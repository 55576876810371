import * as React from "react"
import {View, SafeAreaView, ScrollView, ViewStyle} from "react-native"
import {FC, useState} from "react"
import {ParamListBase} from "@react-navigation/native"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Header, Button, Text, TextField} from "../../../components"
import {useStores} from "../../../models";
import {observer} from "mobx-react-lite";
import {LoadingModal} from "../../../components/backdrop-modal/loading-modal";
import {validateEmail} from "../../../utils/utils";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";
import {isAndroid} from "../../../utils/platform";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";

export interface ForgotPasswordProps {
  navigation: NativeStackNavigationProp<ParamListBase>
}

export const ForgotPasswordScreen: FC<ForgotPasswordProps> = observer(({navigation}) => {
  const {app} = useStores();
  const {userAccount} = app;
  const [loading, setLoading] = useState(false)
  const email = userAccount?.email || '';
  const setEmail = (email) => {
    app.setUserAccount({...userAccount, email})
  }

  const onReset = async () => {
    setLoading(true)
    try {
      const res = await app.onForgotPassword()
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message || 'Your email address does not exist')
      } else {
        AlertModalStore.alert('Success', 'Please check your email')
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const isValidEmail = userAccount?.email && validateEmail(userAccount?.email)
  return (
    <>
      <PureBottomModal onClose={() => navigation.goBack()} isStatic={true}>
        <SafeAreaView style={{flex: 1,}}>
          <Header
            leftIcon={'arrow-left'} leftIconPreset={'normal'} onLeftPress={() => navigation.goBack()}
            preset={"modalHeader"}
            rightIcon={'times'} rightIconPreset={'smallBlue'} onRightPress={() => navigation.goBack()}
          />
          <ScrollView style={BODY}>
            <Text preset={"h1Blue"}>Forgot your password?</Text>
            <Text preset={"body1"} style={{
              marginTop: 20, marginBottom: 28
            }}>Enter the email address associated with your account</Text>
            <TextField
              autoCapitalize='none'
              value={email} onChangeText={setEmail}
              preset={'primary'}
              placeholder="Email"
              label="Email Address*"
              labelPreset="primary"
            />
            <View style={BUTTON_CONTAINER}>
              <Button
                disabled={!isValidEmail}
                preset={!isValidEmail ? "disabled" : "brand"}
                text="Reset password"
                onPress={onReset}/>
            </View>
          </ScrollView>
        </SafeAreaView>
      </PureBottomModal>
      <LoadingModal visible={loading}/>
      <AlertModal/>
    </>
  )
})

const BODY: ViewStyle = {
  paddingHorizontal: isAndroid ? 20 : 26,
}

const BUTTON_CONTAINER: ViewStyle = {
  marginTop: 40,
}
