import * as React from "react"
import { StyleProp, View, ViewStyle } from "react-native";
import {ReactNode} from "react";


export interface BottomContainerProps {
  backgroundColor?: string
  children: ReactNode
  style?: StyleProp<ViewStyle>
}

export const BottomContainer = (props: BottomContainerProps) => {
  const {backgroundColor, style} = props
  return (
    <View style={[{
      position: 'absolute',
      bottom: 0,
      paddingTop: 12,
      paddingBottom: 20,
      paddingHorizontal: 20,
      left: 0, right: 0,
      backgroundColor: backgroundColor || '#EDF2FF',
    }, style]}>
      {props.children}
    </View>
  )
}
