import React, {FC, useCallback, useEffect, useState} from 'react';
import {View, FlatList, StyleSheet, SafeAreaView, TouchableOpacity, ViewStyle} from 'react-native';

import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {observer} from "mobx-react-lite";
import {GroupMessage} from "../../../components/group-message/group-message";
import {color} from "../../../theme";
import {Button, GradientBackground, Header, Text} from "../../../components";
import {ParamListBase, useFocusEffect} from "@react-navigation/native";
import {useStores} from "../../../models";
import {IUserMessageModel} from "../../../models/app/message";
import {constants, DEFAULT_LIMIT} from "../../../utils/constants";
import {FilterStoreKey} from "../../../models/app/filter-store";
import {IWorkerApplicationModel} from "../../../models/app/application";
import {UserMessage} from "../../../components/user-message/user-message";
import {BackdropModal} from "../../../components/backdrop-modal/backdrop-modal";
import {SvgIcon} from "../../../components/icon/svg-icon";
import {IKvItemModel} from "../../../models/app/kv-item";
import { getUserFullName, IGroupNotificationModel, IUserNotificationModel } from "../../../models/app/user-account";
import TabButton from "../../../components/tab-bar/tab-button"
import {BottomModal} from "../../../components/backdrop-modal/bottom-modal";
import BulkMessageDelete from "./bulk-message-delete"
import {IBusinessGroupModel} from "../../../models/app/contact";
import {stringToDateStr} from "../../../utils/utils";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";
import {useIsMount} from "../../../utils/use-is-mount";
import {MainAppStore} from "../../../models/app/main-app-store";

export const BusinessMessageScreenTabs = {
  groupChat: {key: 1, label: "Chat"},
  jobAd: {key: 2, label: "Job Advert"},
  bulk: {key: 3, label: "Bulk"},
}
export const BusinessMessageScreenTags = [
  {id: FilterStoreKey.message, name: 'Unread Messages'},
  {id: FilterStoreKey.response, name: 'New Responses'},
]

export interface GroupMessageScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
}

export const GroupMessageScreen: FC<GroupMessageScreenProps> = observer(
  ({navigation}) => {
    const [refreshing, setRefreshing] = useState(false)
    const {app} = useStores()
    const activeTab = app.tabStore.businessGroupMessageTab || BusinessMessageScreenTabs.bulk.key
    const [userMess, setUserMess] = useState<Array<IUserMessageModel>>([])
    const [jobApplications, setJobApplications] = useState<Array<IWorkerApplicationModel>>([])
    const [groupChatMessages, setGroupChatMessages] = useState<Array<IBusinessGroupModel>>([])
    const [groupNotification, setGroupNotification] = useState<IGroupNotificationModel>(null)
    const [userNotification, setUserNotification] = useState<IUserNotificationModel>(null)

    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleGroupChatModal, setVisibleGroupChatModal] = useState(false)
    const [isShowBulkDelete, setShowBulkDelete] = useState(false)

    const loadBulkMessageData = async () => {
      if (refreshing) return
      setRefreshing(true)
      try {
        const isManager = app?.user?.role === constants.roles.MANAGER
        const businessProfileId = isManager
          ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id

        let notification: IUserNotificationModel = null
        const showUnread = app.filterStore.businessMessageFilterId === FilterStoreKey.message
        const notificationRes = await app.environment.api.getNotification({businessProfileId})
        if (notificationRes.kind === 'ok') {
          notification = notificationRes.item
          setUserNotification(notification)
        }
        if (showUnread) {
          if (notification?.messages?.messages?.length <= 0) {
            setUserMess([])
            return
          }
        }

        const res = await app.environment.api.getNewMessage({
          'filters[businessProfile][id][$eq]': businessProfileId,
          businessProfileId,
          // 'filters[hideUserIds][$notContains]': app?.userAccount?.id,
          'filters[$or][0][hideUserIds][$notContains]': app?.userAccount?.id,
          'filters[$or][1][hideUserIds][$null]': true,
          ...(showUnread ? {'filters[id][$eq]': notification?.messages?.messages} : null),
          "pagination[pageSize]": DEFAULT_LIMIT
        })
        if (res.kind === 'ok') {
          setUserMess(res?.items)
        }
      } catch (e) {
        console.log(e)
      } finally {
        setRefreshing(false)
      }
    }

    const loadMyApplicationData = async () => {
      try {
        const isManager = app?.user?.role === constants.roles.MANAGER
        const businessProfileId = isManager ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id

        let notification: IUserNotificationModel = null
        const showUnread = app.filterStore.businessMessageFilterId === FilterStoreKey.response
        if (showUnread) {
          const notificationRes = await app.environment.api.getNotification({businessProfileId})
          if (notificationRes.kind === 'ok') notification = notificationRes.item
          if (notification?.responses?.jobRoles?.length <= 0) {
            setJobApplications([])
            return
          }
        }

        const res = await app.environment.api.getWorkerApplications({
          "filters[jobRole][jobAd][businessProfile][id][$eq]": businessProfileId,
          ...(showUnread ? {'filters[jobRole][id][$eq]': notification?.responses?.jobRoles} : null),
          businessProfileId,
          "pagination[pageSize]": DEFAULT_LIMIT
        })
        if (res.kind === 'ok') {
          setJobApplications(!showUnread ?
            res.applications : res.applications.filter(it => it.newChatMessages > 0)
          )
        }
      } catch (e) {
        console.log(e)
      } finally {
      }
    }

    const loadGroupChatData = async () => {
      if (refreshing) return
      setRefreshing(true)
      try {
        const isManager = app?.user?.role === constants.roles.MANAGER
        const businessProfileId = isManager ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id

        let notification: IGroupNotificationModel = null
        const showUnread = app.filterStore.businessMessageFilterId === FilterStoreKey.message
        const notificationRes = await app.environment.api.getGroupUnread({businessProfileId})
        if (notificationRes.kind === 'ok') {
          notification = notificationRes.item
          setGroupNotification(notification)
        }
        if (showUnread) {
          if (notification?.count <= 0) {
            setGroupChatMessages([])
            return
          }
        }

        const res = await app.environment.api.getGroupForMessage({
          ...(showUnread ? {'filters[id][$eq]': notification?.groups} : null),
          'showLastSentDate': true,
          'filters[isChatGroup][$null]': false,
          'filters[isChatGroup][$eq]': true,
          'filters[businessProfile][id][$eq]': businessProfileId,
          businessProfileId,
          "pagination[pageSize]": DEFAULT_LIMIT
        })
        if (res.kind === 'ok') {
          setGroupChatMessages(res?.items)
        }
      } catch (e) {
        console.log(e)
      } finally {
        setRefreshing(false)
      }
    }

    useFocusEffect(useCallback(() => {
      const _activeTab = app.tabStore.businessGroupMessageTab || BusinessMessageScreenTabs.bulk.key
      if (_activeTab === BusinessMessageScreenTabs.jobAd.key) loadMyApplicationData()
      else if (_activeTab === BusinessMessageScreenTabs.bulk.key) loadBulkMessageData()
      else if (_activeTab === BusinessMessageScreenTabs.groupChat.key) loadGroupChatData()
    }, []))

    const isMount = useIsMount()
    useEffect(() => {
      if (!isMount && (!isShowBulkDelete)) loadBulkMessageData()
    }, [isShowBulkDelete])

    const renderBulkMessageItem = ({item}: { item: IUserMessageModel }) => {
      const dot = userNotification ? userNotification?.messages?.messages?.includes(item?.id) : false
      return (
        <GroupMessage
          dot={dot}
          item={item}
          onPress={() => navigation.navigate('businessMessageScreen', {
            groupId: item?.group?.id, messageId: item?.id
          })}/>
      );
    }

    const renderGroupChatItem = ({item}: { item: IBusinessGroupModel }) => {
      const dot = groupNotification ? groupNotification?.groups?.includes(item?.id) : false
      return (
        <TouchableOpacity
          onPress={() => navigation.navigate('businessGroupChatScreen', {groupId: item?.id, groupName: item?.name})}
          style={{flexDirection: 'row', paddingTop: 4, paddingBottom: 20, alignItems: 'center'}}>
          <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 6}}>
            <View style={{flex: 1, flexDirection: 'row'}}>
              <Text preset={'body'} style={{fontWeight: 'bold'}} numberOfLines={1}>{item?.name}</Text>
              {dot && <View style={DOT_STYLE}/>}
            </View>
            <View style={{marginRight: 10}}>
              <Text preset={'default'} numberOfLines={1}>{stringToDateStr(item?.lastSentDate || item?.createdAt)}</Text>
            </View>
          </View>
          <SvgIcon icon='chevron-right'/>
        </TouchableOpacity>
      );
    }

    const renderJobApplicationItem = ({item, index}: { item: IWorkerApplicationModel, index: number }) => {
      return (
        <UserMessage
          onLogoPress={() => navigation.navigate("workerProfileScreen", {id: item?.workerProfile?.user?.id})}
          avatar={item?.workerProfile?.user?.avatar?.url}
          key={index.toString()}
          title={item?.jobRole?.jobAd?.title}
          subTitle={getUserFullName(item?.workerProfile?.user)}
          time={''}
          isWorker={true} dot={!!item?.newChatMessages}
          onPress={() => handleJobAdPress(item)}/>
      );
    }

    const handleTabPress = (key: number) => {
      if (key === BusinessMessageScreenTabs.jobAd.key) loadMyApplicationData()
      else if (key === BusinessMessageScreenTabs.bulk.key) loadBulkMessageData()
      else if (key === BusinessMessageScreenTabs.groupChat.key) loadGroupChatData()
      app.tabStore.setBusinessGroupMessageTab(key)
    }

    const renderTabItem = (it) => {
      const isActive = it.key === activeTab
      return (
        <TabButton
          isActive={isActive}
          value={it.label}
          key={it.key}
          onPress={() => handleTabPress(it.key)}/>
      )
    }

    const handleJobAdPress = async (item: IWorkerApplicationModel) => {
      navigation.navigate("businessApplicantMessageScreen", {
        toUserId: item?.workerProfile?.user?.id, applicationId: item?.id,
      })
    }

    const renderTabContent = () => {
      if (activeTab === BusinessMessageScreenTabs.jobAd.key) {
        return (
          <FlatList
            onRefresh={loadMyApplicationData} refreshing={refreshing}
            style={{flex: 1}}
            contentContainerStyle={styles.contentContainerStyle}
            data={jobApplications}
            renderItem={renderJobApplicationItem}
            keyExtractor={(item) => String(item.id)}
            ListFooterComponent={<View style={{height: 50}}/>}
          />
        )
      }
      if (activeTab === BusinessMessageScreenTabs.groupChat.key) {
        return (
          <FlatList
            onRefresh={loadGroupChatData} refreshing={refreshing}
            style={{flex: 1}}
            contentContainerStyle={styles.contentContainerStyle}
            data={groupChatMessages}
            renderItem={renderGroupChatItem}
            keyExtractor={(item) => String(item.id)}
            ListEmptyComponent={(
              <View style={{paddingHorizontal: 10, marginTop: 25}}>
                <Text preset="body" style={{color: color.palette.primary, fontStyle: 'italic'}}>
                  Click the '
                  <Text preset="body" style={{color: color.palette.orange, fontWeight: 'bold'}}>+</Text>
                  ' symbol to start a Chat...
                </Text>
              </View>
            )}
            ListFooterComponent={<View style={{height: 50}}/>}
          />
        )
      }
      return (
        <FlatList
          onRefresh={loadBulkMessageData} refreshing={refreshing}
          style={{flex: 1}}
          contentContainerStyle={styles.contentContainerStyle}
          data={userMess}
          renderItem={renderBulkMessageItem}
          keyExtractor={(item) => String(item?.group?.id + '' + item.id)}
          ListEmptyComponent={(
            <View style={{paddingHorizontal: 10, marginTop: 25}}>
              <Text preset="body" style={{color: color.palette.primary, fontStyle: 'italic'}}>
                Click the '
                <Text preset="body" style={{color: color.palette.orange, fontWeight: 'bold'}}>+</Text>
                ' to create a Bulk Message
              </Text>
            </View>
          )}
          ListFooterComponent={<View style={{height: 50}}/>}
        />
      )
    }

    const handleFilterPress = async () => {
      setVisibleGroupChatModal(false)
      setVisibleModal(true)
    }

    const handleSelectKey = (key: number) => {
      app.filterStore.setBusinessMessageFilterId(app.filterStore.businessMessageFilterId === key ? null : key)
      if (activeTab === BusinessMessageScreenTabs.jobAd.key) loadMyApplicationData()
      else if (activeTab === BusinessMessageScreenTabs.bulk.key) loadBulkMessageData()
      else if (activeTab === BusinessMessageScreenTabs.groupChat.key) loadGroupChatData()
    }

    const renderFilterItem = (it: IKvItemModel) => {
      return <View key={it.id} style={{marginRight: 8}}>
        <TouchableOpacity onPress={() => handleSelectKey(it.id)}>
          <View style={{
            flexDirection: 'row', alignItems: 'center',
            backgroundColor: color.palette.greyLight,
            paddingHorizontal: 12,
            paddingVertical: 11,
            borderRadius: 100
          }}>
            <Text preset={'body'} style={{color: color.palette.blue, fontWeight: '400'}}>
              {it.name}
            </Text>
            <View style={{paddingLeft: 8}}>
              <SvgIcon preset={'primary'} icon={'times-circle-dark'}/>
            </View>
          </View>
        </TouchableOpacity>
      </View>

    }

    const onRightPress = () => {
      if (activeTab === BusinessMessageScreenTabs.groupChat.key) {
        if (groupChatMessages?.length > 0) setVisibleGroupChatModal(true)
        else navigation.navigate('createGroupChatScreen')
      } else if (activeTab === BusinessMessageScreenTabs.bulk.key) {
        if (userMess?.length > 0) setVisibleGroupChatModal(true)
        else navigation.navigate('bulkMessageScreen')
      } else if (activeTab === BusinessMessageScreenTabs.jobAd.key) {
        setVisibleGroupChatModal(true)
      }
    }

    const createBulkMsg = () => {
      setVisibleGroupChatModal(false)
      navigation.navigate('bulkMessageScreen')
    }

    const createGroupChat = () => {
      setVisibleGroupChatModal(false)
      navigation.navigate('createGroupChatScreen')
    }

    const deleteGroupChat = () => {
      setVisibleGroupChatModal(false)
      MainAppStore.setChatGroupDeleteList(groupChatMessages)
      navigation.navigate('deleteGroupChatScreen')
    }

    const renderRightIcon = (): any => {
      const result = {rightIcon: 'plus', rightIconTheme: 'regular', rightIconPreset: 'smallBrand'}
      if (activeTab === BusinessMessageScreenTabs.groupChat.key) {
        result.rightIcon = (groupChatMessages?.length > 0) ? 'ellipsis-h' : 'plus'
        result.rightIconTheme = (groupChatMessages?.length > 0) ? 'solid' : 'regular'
      } else if (activeTab === BusinessMessageScreenTabs.bulk.key) {
        result.rightIcon = (userMess?.length > 0) ? 'ellipsis-h' : 'plus'
        result.rightIconTheme = (userMess?.length > 0) ? 'solid' : 'regular'
      } else {
        result.rightIcon = 'ellipsis-h'
        result.rightIconTheme = 'solid'
      }
      return result
    }

    const handleShowModalDeleteBulk = () => {
      setVisibleGroupChatModal(false)
      setShowBulkDelete(true)
    };

    const handleCloseBulkDelete = async () => {
      setShowBulkDelete(false)
    }

    const businessMessageScreenTags = activeTab === BusinessMessageScreenTabs.jobAd.key
      ? BusinessMessageScreenTags.filter(it => it.id === FilterStoreKey.response)
      : BusinessMessageScreenTags.filter(it => it.id === FilterStoreKey.message)
    const businessMessageScreenTagsFiltered = businessMessageScreenTags
      .filter(it => it.id === app.filterStore.businessMessageFilterId)
    return (
      <SafeAreaView style={styles.container}>
        <GradientBackground colors={[color.palette.gradientStart, color.palette.gradientStop]}/>
        <Header
          preset={'primary'}
          headerText={'Messages'} titlePreset={'headerTitle'}
          {...renderRightIcon()} onRightPress={onRightPress}
        />
        <View style={{
          flexDirection: "row", marginHorizontal: 20,
          alignItems: 'flex-end', justifyContent: 'flex-start'
        }}>
          {Object.values(BusinessMessageScreenTabs).map(renderTabItem)}
        </View>
        <View style={{
          flexDirection: "row", marginHorizontal: 20,
          marginTop: 8, marginBottom: 20,
          alignItems: 'flex-end', justifyContent: 'flex-start',
        }}>
          {businessMessageScreenTagsFiltered.map(renderFilterItem)}
        </View>
        <View style={styles.mainContainer}>
          {renderTabContent()}
        </View>
        <BackdropModal preset={'zeroPad'} visible={visibleModal} onClose={() => setVisibleModal(!visibleModal)}>
          <View style={{paddingTop: 20, paddingBottom: 40}}>
            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
              <Text preset={"h1"} text={'Filters'} color={color.palette.blue}/>
              <SvgIcon onPress={() => setVisibleModal(!visibleModal)} icon={'times'} preset={'normal'}/>
            </View>
            <View style={{marginTop: 65}}>
              {businessMessageScreenTags.map((item) => (
                <TouchableOpacity
                  key={item?.id}
                  onPress={() => handleSelectKey(item.id)}
                  style={{marginBottom: 26, alignItems: 'center'}}>
                  <Text preset={"h1"} text={item.name} color={color.palette.blue}/>
                </TouchableOpacity>
              ))}
            </View>
          </View>
        </BackdropModal>
        <BottomModal visible={visibleGroupChatModal} onClose={() => setVisibleGroupChatModal(false)}>
          <View>
            {BusinessMessageScreenTabs.groupChat.key === activeTab && (
              <View>
                <Button
                  onPress={createGroupChat}
                  style={{marginBottom: 10}}
                  disabled={false}
                  preset={'primary'}
                  text={'Create New Chat Group'}
                />
                <Button
                  onPress={deleteGroupChat}
                  style={{marginBottom: 10}}
                  disabled={false}
                  preset={'brand'}
                  text={'Delete Chat Group(s)'}
                />
              </View>
            )}

            {BusinessMessageScreenTabs.bulk.key === activeTab && (
              <View>
                <Button
                  style={{marginBottom: 10}}
                  preset={'primary'}
                  text={'Create New Bulk Message'}
                  onPress={createBulkMsg}
                />
                <Button
                  preset={'brand'}
                  text={'Delete Bulk Message(s)'}
                  style={{marginBottom: 10}}
                  onPress={handleShowModalDeleteBulk}
                />
              </View>
            )}
            <Button
              onPress={handleFilterPress}
              style={{marginBottom: 10}}
              disabled={false}
              preset={'brandOutLine'}
              text={'Filter Messages'}
            />
            <Button
              onPress={() => setVisibleGroupChatModal(false)}
              style={{marginBottom: 0}}
              disabled={false}
              preset={'primaryOutLine'}
              text={'Cancel'}
            />
          </View>
        </BottomModal>
        <AlertModal/>
        {isShowBulkDelete &&
        <BulkMessageDelete
          isOpen={isShowBulkDelete}
          data={userMess}
          onClose={handleCloseBulkDelete}
        />
        }
      </SafeAreaView>
    )
  },
)

const DOT_STYLE: ViewStyle = {
  width: 10, height: 10,
  backgroundColor: color.palette.red,
  borderRadius: 10, borderWidth: 2, borderColor: color.palette.white,
  marginBottom: 0, marginLeft: 2,
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainerStyle: {
    marginBottom: 20,
    paddingHorizontal: 10,
  },
  mainContainer: {
    flex: 1,
  },
});

