import * as React from "react"
import {
  View,
  SafeAreaView,
  FlatList,
  TouchableOpacity,
  ScrollView,
  StyleSheet,
  RefreshControl, ViewStyle
} from "react-native"
import {FC, useCallback, useState} from "react"
import {ParamListBase, useFocusEffect} from "@react-navigation/native"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Text, GradientBackground, IconButton} from "../../../components"
import {SvgIcon} from "../../../components/icon/svg-icon"
import {color} from "../../../theme"
import {AvatarImage} from "../../../components/avatar-image/avatar-image"
import {SearchBar} from "../../../components/search-bar/search-bar"
import {useSafeAreaInsets} from "react-native-safe-area-context"
import {SearchBarSuggest} from "../../../components/search-bar/search-bar-suggest"
import {useStores} from "../../../models"
import {IJobRoleAdModel} from "../../../models/app/job-role-ad"
import {listToStr} from "../../../utils/utils"
import {IKvItemModel, KvItemPath} from "../../../models/app/kv-item";
import {debounce} from "../../../utils/debounce";
import {AwBannerCard} from "../../../components/card/aw-banner-card";
import {JobRoleCard} from "../../../components/card/job-card";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {Observer, observer} from "mobx-react-lite";
import {QuickFilterTag, QuickFilterTagItem} from "../../../components/tag/quick-filter-tag";
import {WorkerJobListScreenTabs, WorkerJobListScreenTags} from "../jobs-list";
import {WorkerMessageScreenTabs, WorkerMessageScreenTags} from "../messages/worker-message-screen";
import {IUserNotificationModel} from "../../../models/app/user-account";
import {BREAKPOINT_MOBILE, height, width} from "../../../utils/global";

const avatar = require("../../../../assets/images/default-photo.png")
const bannerImage = require("../../../../assets/images/t4w-primary-square.png")

const QuickFilterKey = {
  response: "response",
  message: "message",
  newJobs: "newJobs",
}
const WorkerQuickFilters: Array<QuickFilterTagItem> = [
  {id: QuickFilterKey.response, name: "Responses", dot: false},
  {id: QuickFilterKey.message, name: "Messages", dot: false},
  // {id: QuickFilterKey.newJobs, name: "New Jobs", dot: false},
]

export interface HomeProps {
  navigation: NativeStackNavigationProp<ParamListBase>
}

export const WorkerHomeScreen: FC<HomeProps> = observer(({navigation}) => {
  const insets = useSafeAreaInsets()
  const {app} = useStores()
  const {userAccount} = app
  const [focusSearch, setFocusSearch] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [jobRoles, setJobRoles] = useState<Array<IJobRoleAdModel>>([])
  const [jobRoleSuggest, setJobRoleSuggest] = useState<Array<IKvItemModel>>([])
  const [refreshing, setRefreshing] = useState(false)
  const [notification, setNotification] = useState<IUserNotificationModel>()
  const workerQuickFilters = WorkerQuickFilters.map(item => {
    const newItem = {...item}
    switch (item.id) {
      case QuickFilterKey.response:
        newItem.dot = !!notification?.responses?.count
        break;
      case QuickFilterKey.message:
        newItem.dot = !!notification?.messages?.count
        break;
    }
    return newItem
  })
  const isDesktop =  width > BREAKPOINT_MOBILE;
  const loadData = async () => {
    if (refreshing) return
    setRefreshing(true)
    try {
      const notificationRes = await app.environment.api.getNotification()
      if (notificationRes.kind === 'ok') setNotification(notificationRes.item)

      const res = await app.environment.api.getJobRoleAds({
        "filters[jobAd][isDraft][$eq]": false,
        "filters[jobAd][closed][$eq]": false,
      })
      if (res.kind === "ok") {
        setJobRoles(res.jobRoles)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setRefreshing(false)
    }
  }

  useFocusEffect(useCallback(() => {
    loadData()
  }, []))

  const onProfilePress = () => {
    navigation.navigate("chooseAccessScreen")
  }

  const handleSearchSuggest = async (text: string) => {
    try {
      const res = await app.environment.api.getKvItemList(KvItemPath.role, {
        customFilter: true,
        ...(text ? {'filters[name][$containsi]': text} : null)
      })
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message)
        return
      }
      const _jobRoleSuggest: Array<IKvItemModel> = []
      res?.items?.forEach(it => {
        if (!it?.name?.toLowerCase().includes(text?.toLowerCase())) return
        if (_jobRoleSuggest.find(jit => jit.name?.trim() === it.name?.trim())) return
        _jobRoleSuggest.push({...it, name: it.name?.trim()})
      })
      setJobRoleSuggest(_jobRoleSuggest)
    } catch (e) {
      console.log(e)
    }
    // setJobRoleSuggest([])
    // handleSuggest
  }
  const delaySearchSuggest = useCallback(debounce(handleSearchSuggest, 800), []);

  const handleSearchText = (text) => {
    setFocusSearch(!!text)
    setSearchText(text)
    // @ts-ignore
    if (text) delaySearchSuggest(text)
  }

  const renderItem = ({item}: { item: IJobRoleAdModel }) => {
    return (
      <JobRoleCard
        onLogoPress={(id) => navigation.navigate('businessProfileScreen', {businessProfileId: id})}
        onPress={() => navigation.navigate('jobsAdDetailScreen', {id: item.id})} item={item}/>
    )
  }

  const handleBannerClick = () => {
    // navigation.navigate('inAppBrowser', {url: 'https://tap4work.com.au/'})
    window.open('https://tap4work.com.au/')
    // if (app?.userAccount?.userRoles?.find((it) => it.key === constants.roles.BUSINESS)) {
    //   AlertModalStore.alert("Info", "Login as business role to post job.")
    // } else {
    //   navigation.navigate("businessAccountEditScreen", {isCreate: true})
    // }
  }

  const handleItemPress = (item: QuickFilterTagItem) => {
    switch (item.id) {
      case QuickFilterKey.response:
        app.filterStore.setWorkerJobListFilterId(WorkerJobListScreenTags.responses.key)
        app.tabStore.setWorkerJobListScreenTab(WorkerJobListScreenTabs.application.key)
        navigation.navigate('manageJobScreen', {activeTabId: WorkerJobListScreenTabs.application.key})
        break
      case QuickFilterKey.message:
        app.filterStore.setWorkerMessageFilterId(WorkerMessageScreenTags.unreadMessage.key)
        app.tabStore.setWorkerMessageScreenTab(WorkerMessageScreenTabs.bulkMessages.key)
        navigation.navigate('workerMessageScreen')
        break
      case QuickFilterKey.newJobs:
        app.filterStore.setWorkerJobListFilterId(WorkerJobListScreenTags.newJobs.key)
        app.tabStore.setWorkerJobListScreenTab(WorkerJobListScreenTabs.job.key)
        navigation.navigate('manageJobScreen', {activeTabId: WorkerJobListScreenTabs.job.key})
        break
    }
  }

  // const handleFocusInput = () => {
  //   app?.setManageJobScreenFilter(true)
  //   navigation.navigate('manageJobScreen')
  // }
  //
  const renderMobile = () => {
    return(
        <ScrollView
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={loadData}/>}
            showsHorizontalScrollIndicator={false}
        >
          <View style={{marginBottom: 20}}>
            <QuickFilterTag items={workerQuickFilters} onItemPress={handleItemPress}/>
            <View style={{marginTop: 20, marginHorizontal: 20, flexDirection: "row",}}>
              <Text text="Job Adverts" preset={'h1'} style={{color: color.palette.dark}}/>
              <View style={{alignItems: "flex-end", flex: 1}}>
                <IconButton
                    onPress={() => navigation.navigate('manageJobScreen')}
                    text="See All" textPreset={'bodyGrey'}
                    iconProps={{icon: 'triangle-right', preset: 'triangleIcon'}}
                />
              </View>
            </View>
            <View style={{marginLeft: 10, marginBottom: 18, marginTop: 8}}>
              <Observer>{renderJobList}</Observer>
            </View>
            <View style={{marginHorizontal: 20}}>
              <AwBannerCard
                  title={`Need Help? Head to our FAQs page`}
                  buttonText={'Click Here'} buttonPreset={'brandSm'}
                  onPress={handleBannerClick}
                  image={bannerImage}
              />
            </View>
          </View>
        </ScrollView>
    )
  }

  const renderJobList = () => {
    return (
        <FlatList
            horizontal={!isDesktop}
            data={jobRoles}
            keyExtractor={(item, index) => String(item.id + '' + index)}
            renderItem={renderItem}
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={isDesktop && styles.listDk}
            style={isDesktop && {height: height - 100}}
        />
    )
  }

  const renderDesktop = () => {
    return(
        <View style={styles.wrapperDk}>
          <View style={styles.contentDk}>
            <View style={{paddingRight: 20}}>
              <View style={styles.topContentDk}>
                <QuickFilterTag items={workerQuickFilters} styleCustom={{marginHorizontal: 0}}
                                onItemPress={handleItemPress}/>
                <View style={{marginTop: 20, flexDirection: "row",}}>
                  <Text text="Job Adverts" preset={'h1'} style={{color: color.palette.dark}}/>
                  <View style={{alignItems: "flex-end", flex: 1}}>
                    <IconButton
                      onPress={() => navigation.navigate('manageJobScreen')}
                      text="See All" textPreset={'bodyGrey'}
                      iconProps={{icon: 'triangle-right', preset: 'triangleIcon'}}
                    />
                  </View>
                </View>
              </View>
              <Observer>{renderJobList}</Observer>
            </View>
          </View>
          <AwBannerCard
              isDesktop={isDesktop}
              title={'Post a free job ad today!'}
              buttonText={'Post Job'} buttonPreset={'brand'}
              onPress={() => navigation.navigate('signInEmailCheckScreen')}
              image={bannerImage}
              imgCustom={{width: 220, height: 246}}
          />
        </View>
    )
  }

  return (
    <SafeAreaView style={{flex: 1}}>
      <GradientBackground colors={[color.palette.gradientStart, color.palette.gradientStop]}/>
      <View style={focusSearch ? styles.focusHeaderArea : styles.headerArea}>
        <View style={focusSearch ? {flex: 1, marginTop: insets.top} : {}}>
          <View style={{}}>
            <div id="mobile-header">
              <View style={HEADER_STYLE}>
                <TouchableOpacity
                  style={{flexDirection: "row", alignItems: "center",}}
                  onPress={onProfilePress}>
                  <View>
                    <Text
                      text={listToStr([userAccount?.firstName, userAccount?.lastName])}
                      preset={'h1'} style={{color: color.palette.primary}}/>
                    <View style={{flexDirection: 'row', marginTop: 2, alignItems: 'center'}}>
                      <Text text={'Worker Profile'} preset={'body'} style={{color: color.palette.primary}}/>
                      <SvgIcon icon={"chevron-down"} preset={"tiny"} style={{marginLeft: 3}}/>
                    </View>
                  </View>
                </TouchableOpacity>
                <View style={{flex: 1, alignItems: "flex-end",}}>
                  <AvatarImage
                    onPress={() => navigation.navigate("settingScreen")}
                    source={userAccount?.avatar?.url ? {uri: userAccount?.avatar?.url} : avatar}
                    preset={"round"}
                  />
                </View>
              </View>
            </div>
            <SearchBar
              value={searchText}
              editable={true}
              onChangeText={handleSearchText}
              // onBlur={() => setFocusSearch(false)}
              placeholder={'Search job adverts...'}
              onFilterPress={() => navigation.navigate("jobFilterScreen", {nextScreen: 'manageJobScreen'})}
            />
          </View>
          {focusSearch && <SearchBarSuggest
            onItemPress={(item) => {
              setSearchText('')
              setFocusSearch(false)
              app.setHasSearchJobRole(true)
              app.setFilterJobRoleText(item?.name)
              navigation.navigate('manageJobScreen')
            }}
            data={jobRoleSuggest}
            searchText={searchText}/>}
        </View>
      </View>
      {isDesktop ? renderDesktop() : renderMobile()}
    </SafeAreaView>
  )
})

const styles = StyleSheet.create({
  focusHeaderArea: {
    backgroundColor: color.palette.white,
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 99,
  },
  headerArea: {},
  wrapperDk:{
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  contentDk:{
    flexDirection: 'column',
    flex: 1,
    paddingLeft: 20,
    paddingRight: 0,
  },
  topContentDk:{
    marginBottom: 20,
  },
  listDk:{
    paddingBottom: 300,
  },
})

const HEADER_STYLE: ViewStyle = {
  flexDirection: "row", alignItems: "center",
  marginHorizontal: 20, marginTop: 10, marginBottom: 6,
}
