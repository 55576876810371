import React, {FC, useCallback, useEffect, useState} from 'react';
import {View, StyleSheet, FlatList, TouchableOpacity} from 'react-native';

import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {observer} from "mobx-react-lite";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {color} from "../../../theme";
import {Button, Header, Text, TextField} from "../../../components"
import {AvatarImage} from "../../../components/avatar-image/avatar-image";
import {ParamListBase, RouteProp, useFocusEffect} from "@react-navigation/native";
import {useStores} from "../../../models";
import {IUserAccountModel} from "../../../models/app/user-account";
import {listToStr} from "../../../utils/utils";
import {IUserChatModel} from "../../../models/app/message";
import { constants, DEFAULT_MD_LIMIT } from "../../../utils/constants";
import {AwChatManager} from "../../../services/api/socket";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import moment from "moment";
import {TrackEvents, trackWithUserAccount} from "../../../services/track";
import {BackdropModal} from "../../../components/backdrop-modal/backdrop-modal"
import {IFileUploadModel} from "../../../models/app/file-upload"
import {MessageImage} from "../../../components/avatar-image/message-image"
import {BottomModal} from "../../../components/backdrop-modal/bottom-modal";
import {SvgIcon} from "../../../components/icon/svg-icon";
import {useFilePicker} from "use-file-picker";
import {TypeUserModel} from "../../../models/app/kv-item";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";
import {NavigatorParamList} from "../../../navigators";
import {isIos} from "../../../utils/platform";

export interface BusinessGroupChatScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'businessGroupChatScreen'>
}

const defaultIcon = require("../../../../assets/images/default-icon.png")
const defaultPhoto = require("../../../../assets/images/default-photo.png")
const defaultLogo = require("../../../../assets/images/default-company.png")

const getStartDayIdList = (chatData: IUserChatModel[]): number[] => {
  if (chatData?.length > 0) {
    const startDayIdDict = {}
    chatData?.forEach(item => {
      const createdDate = moment(item?.createdAt).format('DD/MM/YYYY')
      if (!startDayIdDict.hasOwnProperty(createdDate)) startDayIdDict[createdDate] = item?.id
    })
    return Object.values(startDayIdDict)
  }
  return []
}

export const BusinessGroupChatScreen: FC<BusinessGroupChatScreenProps> = observer(
  ({navigation, route}) => {
    const insets = useSafeAreaInsets()
    const {app} = useStores()
    const isWorker = app.user.role === constants.roles.WORKER
    const fromUserAccount: IUserAccountModel = app?.userAccount
    const [chatData, setChatData] = useState<Array<IUserChatModel>>([])
    const startDayIdList: number[] = getStartDayIdList(chatData)
    const [loading, setLoading] = useState(false)
    const [textMessage, setTextMessage] = useState('')
    const [chatManager, setChatManager] = useState<AwChatManager>(null)
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleRenameModal, setVisibleRenameModal] = useState(false)
    const [headerTitle, setHeaderTitle] = useState('')
    const [groupName, setGroupName] = useState('')

    const [deleteMode, setDeleteMode] = useState(false);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState<Array<number>>([]);
    const [confirmVisible, setConfirmVisible] = useState(false);

    const [currentImage, setCurrentImage] = useState<File>(null)
    const [urlImgBefore, setUrlImgBefore] = useState('');
    const canSend = !!textMessage?.trim() || !!urlImgBefore

    const initData = async () => {
    }

    const loadData = async () => {
      // loadSocket()
      if (loading) return
      setLoading(true)
      try {
        const isManager = app?.user?.role === constants.roles.MANAGER
        const chatRes = await app.environment.api.getChatInGroups({
          'filters[$or][0][hideUserIds][$null]': true,
          'filters[$or][1][hideUserIds][$notContains]': `${app?.userAccount?.id},`,
          'filters[group][id]': route?.params?.groupId,
          ...(!isWorker ? {
            businessProfileId: isManager
              ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          } : null),
          "pagination[pageSize]": DEFAULT_MD_LIMIT
        })
        if (chatRes.kind !== 'ok') {
          AlertModalStore.alert('Error', chatRes.message)
          return
        }
        if (!chatData || chatRes.items.length !== chatData?.length) {
          setChatData(chatRes?.items?.reverse())
        }
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    useFocusEffect(useCallback(() => {
      if (route.params?.groupName) setHeaderTitle(route.params?.groupName)
      if (route.params?.groupId) {
        initData()
        loadData()
      }
      const awChatManager = AwChatManager.getInstance(app.auth)
      awChatManager.onChat = () => {
        loadData()
      }
      setChatManager(awChatManager)
      return () => {
        AwChatManager.currentInstance()?.disconnect()
      }
    }, []))

    const handleSend = async () => {
      if (!canSend || loading) return
      const _textMessage = textMessage;
      const _currentImage = currentImage;
      let uploadFile: IFileUploadModel;
      setTextMessage('')
      setCurrentImage(null)
      if (!!_currentImage) {
        const uploadRes = await app.environment.api.uploadFile(_currentImage)
        if (uploadRes.kind !== 'ok') {
          AlertModalStore.alert('Error', 'Can not upload file')
          return;
        } else {
          uploadFile = uploadRes?.file
        }
      }
      try {
        if (!chatManager?.socket) {
          console.log('Socket null')
          return
        }
        chatManager?.socket.emit('chat', {
          groupId: route?.params?.groupId,
          // toUserId: toUserId,
          action: 'sendMessage',
          content: textMessage,
          ...(uploadFile?.id ? {file: uploadFile?.id} : null)
        });
      } catch (e) {
        console.log(e)
      } finally {
        trackWithUserAccount(TrackEvents.SentMessage, app?.userAccount)
        const createdAt = new Date()
        setChatData([
          {
            id: createdAt.getTime(),
            from: fromUserAccount,
            // to: toUserAccount,
            file: uploadFile?.id ? uploadFile : null,
            createdAt: createdAt.toISOString(),
            content: _textMessage
          } as IUserChatModel,
          ...chatData,
        ])
        setTimeout(() => {
          loadData()
        }, 400)
      }
    }


    const [openFileSelector, {plainFiles, filesContent}] = useFilePicker({
      readAs: 'DataURL',
      accept: 'image/*',
      maxFileSize: 2,
    });

    const handleUploadImg = async () => {
      openFileSelector()
    }
    useEffect(() => {
      if (!plainFiles || plainFiles.length <= 0) return
      onPickerResult(plainFiles)
    }, [plainFiles])

    const onPickerResult = async (response: File[]) => {
      if (Array.isArray(response) && response.length > 0) {
        try {
          setUrlImgBefore(filesContent[0].content)
          setCurrentImage(response[0]);
        } catch (e) {
          console.log(e)
        }
      }
    }

    const onRemoveImg = () => {
      setCurrentImage(null)
    }

    const handleAvatarPress = (item: IUserAccountModel) => {
      navigation.goBack()
      navigation.navigate("workerProfileScreen", {id: item?.id})
    }

    const toggleSelectedMessage = (item: IUserChatModel) => {
      const index = selectedMessage?.findIndex(_item => item?.id === _item)
      if (index >= 0) setSelectedMessage(selectedMessage?.filter(_item => item?.id !== _item))
      else setSelectedMessage([...selectedMessage, item?.id])
    }

    const toggleSelectedAllMessage = () => {
      if (isCheckAll) setSelectedMessage([])
      else setSelectedMessage(chatData?.map(it => it?.id))
      setIsCheckAll(!isCheckAll)
    }

    const renderItem = ({item}: { item: IUserChatModel }) => {
      const isOther = item?.from?.id !== fromUserAccount.id
      const isSystem = !item?.from?.id
      const toUserAvatar = item?.from?.avatar?.url
      const isCheck = selectedMessage?.includes(item?.id)
      return (
        <View>
          {startDayIdList.includes(item?.id) && (
            <View style={{alignItems: 'center', marginTop: 20, paddingBottom: 10}}>
              <Text
                preset={'body1'} style={{color: color.palette.black80}}
              >{moment(item?.createdAt).format('DD/MM/YYYY')}</Text>
            </View>
          )}
          <View style={{flexDirection: 'row', marginBottom: 20,}}>
            <View style={{width: isOther ? 0 : 50}}/>
            {isOther ? (
              <AvatarImage
                onPress={() => handleAvatarPress(item?.from)}
                style={{}} preset={'medium'}
                source={isSystem ? defaultIcon : toUserAvatar
                  ? {uri: toUserAvatar}
                  : isWorker ? defaultPhoto : defaultLogo}/>
            ) : null}
            <View style={{flex: 1, paddingTop: 4, alignItems: isOther ? 'flex-start' : 'flex-end'}}>
              <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                <Text preset={isOther ? 'messageTitleLink' : 'messageTitle'}>
                  {isSystem ? 'Notification' : listToStr([item?.from?.firstName, item?.from?.lastName])}
                </Text>
                <Text style={{marginLeft: 10}} preset={'caption'}>{moment(item?.createdAt).format('HH:mm')}</Text>
              </View>
              {!!item?.file?.url
                ?
                <View style={{
                  marginTop: 10,
                  flexDirection: 'column',
                  alignSelf: isOther ? 'flex-start' : 'flex-end',
                  alignItems: isOther ? 'flex-start' : 'flex-end'
                }}>
                  {!!item.content &&
                  <View style={{
                    marginBottom: 5,
                    paddingHorizontal: 18,
                    paddingVertical: 12,
                    borderTopRightRadius: 10,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    backgroundColor: isOther ? isSystem ? color.palette.bgGreen : color.palette.grey : color.palette.bgGreen,
                  }}>
                    <Text preset={'body1'} text={item.content}/>
                  </View>}
                  <MessageImage url={item?.file?.url}/>
                </View>
                :
                <View style={{
                  marginTop: 10, paddingHorizontal: 18, paddingVertical: 12,
                  backgroundColor: isOther ? isSystem ? color.palette.bgGreen : color.palette.grey : color.palette.bgGreen,
                  borderTopRightRadius: 10, borderBottomLeftRadius: 10, borderBottomRightRadius: 10,
                  flexDirection: 'row',
                  alignSelf: isOther ? 'flex-start' : 'flex-end'
                }}>
                  <Text preset={'body1'} text={item.content}/>
                </View>
              }

            </View>
            {deleteMode ? (
              <TouchableOpacity
                onPress={() => toggleSelectedMessage(item)}
                style={{marginLeft: 6, justifyContent: 'center'}}>
                <SvgIcon
                  preset={isCheck ? 'smallBlue20' : 'square'}
                  icon={isCheck ? 'check-square' : 'square-regular'}
                  theme={isCheck ? 'solid' : 'regular'}
                />
              </TouchableOpacity>
            ) : (<View style={{width: !isOther ? 0 : 50}}/>)}
          </View>
        </View>
      )
    }

    const onRenameGroup = async () => {
      const isManager = app?.user?.role === constants.roles.MANAGER
      const updateRes = await app.environment.api.updateGroup(
        route?.params?.groupId, {
          name: groupName,
          businessProfileId: isManager
            ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
        }
      )
      if (updateRes.kind !== 'ok') {
        AlertModalStore.alert('Error', updateRes.message)
        return
      }
      setHeaderTitle(groupName)
    }

    const showRenameModal = () => {
      setVisibleRenameModal(true)
      setGroupName(headerTitle)
    }

    const loadGroupData = async () => {
      setLoading(true)
      try {
        const isManager = app?.user?.role === constants.roles.MANAGER
        const res = await app.environment.api.getGroupDetail(route?.params?.groupId, {
          'filters[businessProfile][id][$eq]': isManager
            ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          'businessProfileId': isManager
            ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        const _contacts: TypeUserModel[] = []
        res?.item?.contacts?.forEach((item) => {
          if (
            !res?.item?.hideContacts?.find(ct => ct.id === item?.id)
            && !res?.item?.hideUserIds?.includes(`${item?.user?.id},`)
          ) {
            const fullName = [item?.user?.firstName, item?.user?.lastName]?.join(' ')?.trim()
            _contacts.push({
              id: item?.id, name: fullName || item?.user?.email,
              checked: true,
              avatar: item?.user?.avatar?.url || ''
            })
          }
        })
        app.setListSelectedContact(_contacts)
        app.setListExistedContact(_contacts?.map(item => item?.id))
        navigation.navigate('editGroupChatScreen', {
          groupId: route?.params?.groupId,
          groupName: route?.params?.groupName
        })
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    const editGroupChat = () => {
      setVisibleModal(false)
      loadGroupData()
    }

    const handleOpenDeleteMessage = () => {
      setVisibleModal(false)
      setDeleteMode(true)
      setSelectedMessage([])
    }

    const handleDeleteMessage = () => {
      setConfirmVisible(true)
    }

    const onDeleteMessage = async () => {
      try {
        setConfirmVisible(false)
        setLoading(true)
        const res = await app.environment.api.deleteChatInGroups({
          ids: selectedMessage?.join(','),
          isHideOnlyMe: !!isWorker
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        setDeleteMode(false)
        loadData()
      } catch (e) {
        AlertModalStore.alert('Error', e?.toString())
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    const onBackPress = () => {
      if (deleteMode) setDeleteMode(false)
      else navigation.goBack()
    }

    const disableBtnDone = !(selectedMessage?.length > 0)
    return (
      <>
        <PureBottomModal onClose={() => navigation.goBack()}>
          <View style={styles.container}>
            <View style={styles.mainContainer}>
              <Header
                preset={'pureTransparent'}
                header={() => (
                  <TouchableOpacity
                    onPress={showRenameModal}
                    style={{paddingRight: 10, flexDirection: 'row'}}>
                    <Text numberOfLines={1} preset={'headerTitle'} text={headerTitle}/>
                  </TouchableOpacity>
                )}
                leftIcon={'arrow-left'} leftIconPreset={'normal'} onLeftPress={onBackPress}
                rightIcon={deleteMode ? (() => (
                  <TouchableOpacity
                    onPress={toggleSelectedAllMessage}
                    style={{marginRight: 6, alignItems: 'flex-end'}}>
                    <View style={{alignItems: 'center'}}>
                      <SvgIcon
                        preset={isCheckAll ? 'smallBlue20' : 'square'}
                        icon={isCheckAll ? 'check-square' : 'square-regular'}
                        theme={isCheckAll ? 'solid' : 'regular'}
                      />
                      <View style={{marginTop: 4}}>
                        <Text preset="small" style={{color: color.palette.primary}}>All</Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                )) : 'ellipsis-h'} rightIconPreset={'orange'} rightIconTheme={'solid'}
                onRightPress={() => setVisibleModal(true)}
              />
            </View>
            <View style={{height: 20, borderBottomWidth: 1.5, borderColor: color.palette.bgBlue}}/>
            <View style={styles.body}>
              <View style={{flex: 1, paddingLeft: 10, paddingRight: 20,}}>
                <FlatList
                  showsVerticalScrollIndicator={false}
                  ListFooterComponent={<View style={{height: 20}}/>}
                  keyExtractor={((item) => String(item.id))}
                  inverted={true}
                  data={chatData} renderItem={renderItem}/>
              </View>
              {deleteMode ? (
                <View
                  style={{
                    backgroundColor: color.palette.grey, paddingTop: 12, paddingBottom: 20, paddingHorizontal: 20,
                    flexDirection: 'row',
                  }}>
                  <Button
                    style={{flex: 1, marginLeft: 10}}
                    onPress={handleDeleteMessage}
                    disabled={disableBtnDone}
                    preset={disableBtnDone ? 'disabled' : 'brand'} text={'Delete Message(s)'}/>
                </View>
              ) : (
                <View style={{backgroundColor: color.palette.bgBlue30, paddingBottom: insets.bottom}}>
                  <View style={styles.chatContainer}>
                    <View style={{flex: 1}}>
                      <TextField
                        numberOfLines={2} multiline={true}
                        value={textMessage} onChangeText={setTextMessage}
                        inputPreset={'roundMessage'}
                        placeholder={'Enter Message'}
                        rightIcon={'arrow-circle-up'} rightIconTheme={canSend ? 'regular' : 'solid'}
                        rightIconPreset={canSend ? 'normal' : 'normalDisabled'}
                        rightIconPress={handleSend}
                        leftIconPress={handleUploadImg}
                        leftIconPreset="normalDisabled"
                        leftIcon={'image-sold'}
                        leftIconTheme="solid"
                        inputStyle={{
                          marginLeft: 5, maxHeight: 60, paddingTop: 0, paddingBottom: 0,
                          textAlignVertical: 'center'
                        }}
                        urlImgMsg={urlImgBefore}
                        onRemoveImg={onRemoveImg}
                      />
                    </View>
                  </View>
                </View>
              )}
            </View>
          </View>
        </PureBottomModal>
        <BottomModal style={{paddingTop: 20}} visible={visibleModal} onClose={() => setVisibleModal(false)}>
          <View>
            <Button
              style={{marginTop: 0}}
              disabled={false}
              preset={'brand'}
              onPress={editGroupChat}
              text={'Add Chat Member(s)'}
            />
            <Button
              style={{marginTop: 10}}
              disabled={false}
              preset={'primary'}
              onPress={() => {
                setVisibleModal(false)
                navigation.navigate('groupRemoveContactScreen', {
                  groupName: route.params?.groupName, groupId: route.params?.groupId
                })
              }}
              text={'Remove Chat Member(s)'}
            />
            <Button
              onPress={handleOpenDeleteMessage}
              style={{marginTop: 10}}
              disabled={false}
              preset={'brandOutLine'}
              text={'Delete Messages'}
            />
            <Button
              onPress={() => setVisibleModal(false)}
              style={{marginTop: 10}}
              disabled={false}
              preset={'primaryOutLine'}
              text={'Cancel'}
            />
          </View>
        </BottomModal>
        <BackdropModal
          preset={'pvModal'}
          visible={visibleRenameModal} onClose={() => setVisibleRenameModal(false)}>
          <View>
            <View style={{marginBottom: 20}}>
              <View style={{paddingHorizontal: 20,}}>
                <Text numberOfLines={1} preset={'headerTitle'} text={'Rename Chat Group'}/>
              </View>
              <View style={{height: 16, borderBottomWidth: 1.5, borderColor: color.palette.bgBlue}}/>
            </View>
            <View style={{paddingHorizontal: 20,}}>
              <View style={{marginBottom: 30}}>
                <TextField
                  autoCapitalize='none'
                  value={groupName}
                  onChangeText={setGroupName}
                  placeholder={'Enter New Group Name here'}
                />
              </View>
              <Button
                style={{marginTop: 0}}
                disabled={false}
                preset={'brand'}
                text={'Rename Group'}
                onPress={onRenameGroup}
              />
            </View>
          </View>
        </BackdropModal>
        <BackdropModal visible={confirmVisible} onClose={() => setConfirmVisible(false)}>
          <View>
            <View style={{alignItems: "center", justifyContent: 'center', padding: 10, paddingTop: 0}}>
              <View style={{paddingBottom: 8}}>
                <View style={{marginBottom: 10}}>
                  <Text preset={'alertTitle'} style={{textAlign: 'center'}}>
                    You have selected Messages to be Deleted
                  </Text>
                </View>
              </View>
              <SvgIcon preset={'alertIcon'}/>
              <View style={{paddingTop: 18, paddingBottom: 8}}>
                <Text preset={'alertTitle'}>Are you sure?</Text>
              </View>
            </View>
            <Button
              onPress={onDeleteMessage}
              style={{marginVertical: 8}}
              disabled={false}
              preset={'brand'}
              text={'Yes'}
            />
            <Button
              onPress={() => setConfirmVisible(false)}
              style={{marginTop: 8}}
              disabled={false}
              preset={'destructive'}
              text={'Cancel'}
            />
          </View>
        </BackdropModal>
        <AlertModal/>
      </>
    )
  },
)

const styles = StyleSheet.create({
  body: {
    backgroundColor: color.palette.white,
    flex: 1,
  },
  chatContainer: {
    alignItems: 'center', borderTopColor: color.palette.grey,
    borderTopWidth: 1.5, flexDirection: 'row', paddingBottom: isIos ? 10 : 3, paddingHorizontal: 20, paddingTop: 11
  },
  container: {
    backgroundColor: color.palette.white,
    flex: 1,
  },
  contentContainerStyle: {
    marginVertical: 20,
    paddingHorizontal: 10,
  },
  mainContainer: {
    backgroundColor: color.palette.white,
  },
  statusBar: {
    backgroundColor: color.palette.bgBlue,
  },
});
