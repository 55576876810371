import React, { FC } from "react"
import { SafeAreaView, ScrollView, StyleSheet } from "react-native"
import { Header } from "../../components"
import Intro from "./components/Intro"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { NavigatorParamList } from "../../navigators"
import { RouteProp } from "@react-navigation/native"
export interface WalletScreenProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
  route: RouteProp<NavigatorParamList, 'userAccountEditScreen'>
}

const WalletScreen: FC<WalletScreenProps> = ({navigation}) => {
  return(
    <SafeAreaView style={styles.container}>
      <Header
        titlePreset={"headerTitle"} headerText={"My Wallet"} preset={"primary"}
      />
      <ScrollView contentContainerStyle={styles.scrollView}>
        <Intro
          des1="Save space in your wallet by storing your certificates, licenses and tickets digitally within the app."
          title="Workers, Safely Store Your Credentials in the App Wallet"
          txtBottom='Get started saving your credentials'
          nameIcon='wallet-intro'
          onClick={() => navigation.navigate('signInEmailCheckScreen')}/>
      </ScrollView>
    </SafeAreaView>
  )
};

export default WalletScreen;

const styles = StyleSheet.create({
  container:{
    backgroundColor: '#FFFFFF',
    flex: 1,
  },
  scrollView:{
    paddingHorizontal: 16,
    marginTop: 20,
    flex: 1,
  }
});