import {Instance, types} from "mobx-state-tree"
import {convertFileUploadModel, FileUploadModel} from "./file-upload";
import {convertWorkerProfileModel, WorkerProfileModel} from "./worker-profile";
import {convertJobRoleAdModel, JobRoleAdModel} from "./job-role-ad";
import {convertKvItemModel, KvItemModel} from "./kv-item";

export const ApplicationStatus = {
  submitted: "submitted",
  rejected: "rejected",
  approved: "approved",
  viewing: "viewing",
  pending: 'pending'
}

export const WorkerApplicationModel = types.model({
  id: types.optional(types.number, 0),
  workerBookmark: types.optional(types.boolean, false),
  myCv: types.optional(FileUploadModel, {}),
  workerProfile: types.maybeNull(WorkerProfileModel),
  jobRole: types.optional(JobRoleAdModel, {}),
  publishedAt: types.optional(types.string, ''),
  createdAt: types.optional(types.string, ''),
  approved: types.optional(types.boolean, false),
  shortlisted: types.optional(types.boolean, false),
  status: types.optional(types.string, ''),
  rate: types.optional(types.number, 0),

  experiences: types.optional(types.array(KvItemModel), []),
  skills: types.optional(types.array(KvItemModel), []),
  qualifications: types.optional(types.array(KvItemModel), []),
  newChatMessages: types.optional(types.number, 0),
})
export type IWorkerApplicationModel = Instance<typeof WorkerApplicationModel>
export const convertWorkerApplicationModel = (raw): IWorkerApplicationModel => ({
  id: raw?.id || 0,
  workerBookmark: raw?.workerBookmark || false,
  myCv: convertFileUploadModel(raw?.myCv?.data?.attributes ? {
    ...raw?.myCv?.data?.attributes,
    id: raw?.myCv?.data?.id
  } : {
    ...raw?.myCv?.attributes,
    id: raw?.myCv?.id
  }),
  workerProfile: convertWorkerProfileModel(
    raw?.workerProfile?.data?.attributes ? {
      ...raw?.workerProfile?.data?.attributes,
      id: raw?.workerProfile?.data?.id
    } : raw?.workerProfile?.data
  ),
  jobRole: raw?.jobRole?.data ? convertJobRoleAdModel({
    ...raw?.jobRole?.data?.attributes, id: raw?.jobRole?.data?.id
  }) : convertJobRoleAdModel(raw?.jobRole),
  publishedAt: raw?.publishedAt || '',
  createdAt: raw?.createdAt || '',
  approved: raw?.approved || false,
  shortlisted: raw?.shortlisted || false,
  status: raw?.status || '',
  rate: raw?.rate || 0,

  experiences: raw?.experiences?.data?.map((item) => convertKvItemModel(
    {...item?.attributes, id: item?.id}
  )) || [],
  skills: raw?.skills?.data?.map((item) => convertKvItemModel({
      id: item.id,
      name: item.skillCategory?.data?.attributes?.name || item.skillCategory?.attributes?.name || item.skillCategory?.name || '?'
  })) || [],
  qualifications: raw?.qualifications?.data?.map((item) => convertKvItemModel(
    {...item?.attributes, id: item?.id}
  )) || [],
  newChatMessages: raw?.newChatMessages || 0,
})
