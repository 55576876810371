import { cast, Instance, types } from "mobx-state-tree"
import {BusinessProfileModel, convertBusinessProfileModel} from "./business-profile"
import {convertUserAccountModel, UserAccountModel} from "./user-account"
import {color} from "../../theme";
import { convertKvItemModel, KvItemModel } from "./kv-item";

export const CONTACT_STATUS = {
  accepted: {key: 'accepted', value: 'Accepted', color: color.palette.green},
  pending: {key: 'pending', value: 'Pending', color: color.palette.orangeMid},
  rejected: {key: 'rejected', value: 'Rejected', color: color.palette.red},
}

export const BusinessContactModel = types.model({
  id: types.optional(types.number, 0),
  rate: types.optional(types.number, 0),
  status: types.optional(types.string, ""),
  code: types.optional(types.string, ""),
  createdAt: types.optional(types.string, ''),
  businessProfile: types.maybeNull(BusinessProfileModel),
  user: types.maybeNull(UserAccountModel),
  newChatMessages: types.optional(types.number, 0),
  previewChatMessage: types.optional(types.string, ''),
  lastSentDate: types.optional(types.string, ''),
})
export type IBusinessContactModel = Instance<typeof BusinessContactModel>
export type IBusinessContactModelCheck = IBusinessContactModel & { checked: boolean }
export const convertBusinessContactModel = (raw): IBusinessContactModel => ({
  id: raw?.id || 0,
  rate: raw?.rate || 0,
  status: raw?.status || "",
  code: raw?.code || "",
  createdAt: raw?.createdAt || "",
  businessProfile: convertBusinessProfileModel(
    raw?.businessProfile?.data?.attributes
      ? {
        ...raw?.businessProfile?.data?.attributes,
        id: raw?.businessProfile?.data?.id,
      }
      : raw?.businessProfile?.data,
  ),
  user: convertUserAccountModel(
    raw?.user?.data?.attributes
      ? {
        ...raw?.user?.data?.attributes,
        id: raw?.user?.data?.id,
      }
      : raw?.user?.data
  ),
  newChatMessages: raw?.newChatMessages || 0,
  previewChatMessage: raw?.previewChatMessage?.content || '',
  lastSentDate: raw?.previewChatMessage?.createdAt || '',
})

export const BusinessGroupModel = types.model({
  id: types.optional(types.number, 0),
  name: types.optional(types.string, ""),
  hideContacts: types.optional(types.array(KvItemModel), []),
  businessProfile: types.maybeNull(BusinessProfileModel),
  contacts: types.optional(types.array(BusinessContactModel), []),
  createdAt: types.optional(types.string, ''),
  lastSentDate: types.optional(types.string, ''),
  hideUserIds: types.optional(types.string, ''),
})
export type IBusinessGroupModel = Instance<typeof BusinessGroupModel>
export const convertBusinessGroupModel = (raw): IBusinessGroupModel => ({
  id: raw?.id || 0,
  name: raw?.name || "",
  hideContacts: cast(Object.entries(raw?.hideContacts || {}).map((item) => convertKvItemModel({
    id: Number(item[0]), name: item[1]
  }))),
  createdAt: raw?.createdAt || "",
  lastSentDate: raw?.previewChatMessage?.createdAt || '',
  businessProfile: convertBusinessProfileModel(
    raw?.businessProfile?.data?.attributes
      ? {
        ...raw?.businessProfile?.data?.attributes,
        id: raw?.businessProfile?.data?.id,
      }
      : raw?.businessProfile?.data,
  ),
  contacts: raw?.contacts?.data?.map((item) =>
    convertBusinessContactModel(
      item?.attributes
        ? {
          ...item?.attributes,
          id: item?.id,
        }
        : item,
    ),
  ),
  hideUserIds: raw?.hideUserIds || '',
})

export const convertBusinessPredefinedMessagesModel = (raw) => ({
  id: raw?.id || 0,
  content: raw?.content,
  userType: raw?.userType,
})

export interface CreateUserInput {
  firstName: string
  email: string
}
