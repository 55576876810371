import React from 'react';
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {
  SettingsScreen,
} from "../../screens/shared";
import {UserHomeScreen as HomeScreen} from '../../screens/user/home/user-home-screen';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {TabBar} from '../../components/tab-bar/tab-bar';
import {UserMessageScreen} from "../../screens/user/messages/user-message-screen";

export type UserParamList = {
  tabNavigator: undefined
  settingsScreen: undefined

  homeScreen: undefined
  manageJobScreen: undefined
  inboxScreen: undefined
  contactScreen: undefined
  settingScreen: undefined
  walletScreen: undefined
}

const Tab = createBottomTabNavigator<UserParamList>()

const TabNavigator = () => (
  <Tab.Navigator
    screenOptions={{
      headerShown: false,
    }}
    tabBar={(props) => <TabBar {...props} />}
    initialRouteName={"homeScreen"}
  >
    <Tab.Screen name={"homeScreen"} component={HomeScreen} options={{tabBarLabel: 'Dashboard'}}/>
    <Tab.Screen name={'manageJobScreen'} component={HomeScreen} options={{tabBarLabel: 'Job Ads'}}/>
    <Tab.Screen name={'inboxScreen'} component={UserMessageScreen} options={{tabBarLabel: 'Messages'}}/>
    <Tab.Screen name={'walletScreen'} component={HomeScreen} options={{tabBarLabel: 'Wallet'}}/>
    <Tab.Screen name={'settingScreen'} component={SettingsScreen} options={{tabBarLabel: 'Settings'}}/>
  </Tab.Navigator>
)

const UserStack = createNativeStackNavigator<UserParamList>()

export function UserNavigator() {
  return (
    <UserStack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName={"tabNavigator"}
    >
      <UserStack.Screen name={'tabNavigator'} component={TabNavigator}/>
    </UserStack.Navigator>
  );
}
