import React, { FC } from 'react';
import { View } from 'react-native';
import { PureBackdropModal } from "./pure-backdrop-modal";
import { observer } from "mobx-react-lite";
import { Text } from "../text/text";
import { AlertModalAction, AlertModalStore } from "../../models/app/alert-modal-store";
import { Button } from "../button/button";
import { ButtonPresetNames } from "../button/button.presets";

export interface AlertModalProps {
}

export const AlertModal: FC<AlertModalProps> = observer(() => {
  const {visible, title, message, okText, cancelText} = AlertModalStore;

  const handleOk = () => {
    AlertModalStore.setVisible(!visible)
    const onOk = AlertModalAction?.onOk?.onPress
    onOk && onOk()
    AlertModalStore.cancel()
  }
  const handleCancel = () => {
    AlertModalStore.setVisible(!visible)
    const onCancel = AlertModalAction?.onCancel?.onPress
    onCancel && onCancel()
    AlertModalStore.cancel()
  }

  if (!visible) return null
  return (
    <PureBackdropModal visible={visible} onClose={handleCancel} preset={'alert'}>
      <View style={{minWidth: '100%'}}>
        <View style={{marginBottom: 30}}>
          <Text preset={'h1'} style={{textAlign: 'center'}}>{title}</Text>
          <View style={{height: 10}}/>
          <View>
            <Text preset={'default'} style={{textAlign: 'center'}}>{message}</Text>
          </View>
        </View>
        <View style={{flexDirection: 'row', justifyContent: 'space-between',}}>
          <Button
            onPress={handleOk}
            preset={AlertModalStore.okPreset as ButtonPresetNames || 'brand'}
            text={okText}
            style={{flex: 1}}
          />
          <View style={{width: 10, display: cancelText ? 'flex' : 'none'}}/>
          <Button
            onPress={handleCancel}
            preset={AlertModalStore.cancelPreset as ButtonPresetNames || 'brandDisabled'}
            text={cancelText}
            style={{...(okText?.length > cancelText?.length ? {} : {flex: 1}), display: cancelText ? 'flex' : 'none'}}
          />
        </View>
      </View>
    </PureBackdropModal>
  );
})
