import { ParamListBase, useFocusEffect } from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import React, { FC, useCallback, useState } from "react"
import { SafeAreaView, ScrollView, RefreshControl, View } from "react-native"
import { Button, GradientBackground, Header } from "../../../components"
import { useStores } from "../../../models"
import { color } from "../../../theme"
import { observer } from "mobx-react-lite"
import {
  IPureWorkerWalletItem, IWorkerWalletItemShareModel,
  IWorkerWalletItemModel, KvSkillPath, WorkerWalletItemPath
} from "../../../models/app/advanced-skill";
import { DEFAULT_LIMIT } from "../../../utils/constants";
import LineTitle from "./components/LineTitle"
import CardList from "./components/CardList"
import OtherCard from "./components/OtherCard"
import { SelectTypeSections } from "./document/upload-select-type-screen";
import { BottomModal } from "../../../components/backdrop-modal/bottom-modal";
import { MainAppStore } from "../../../models/app/main-app-store";
import { IKvItemModel } from "../../../models/app/kv-item";
import { urlIsDoc } from "../../../utils/utils";
import Tags from "./components/Tags";
import { TrackEvents, trackWithUserAccount } from "../../../services/track";

interface WalletScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
}

export const WalletScreen: FC<WalletScreenProps> = observer(({navigation}) => {
  const {app} = useStores()
  const [loading, setLoading] = useState(false)

  const [otherList, setOtherList] = useState<Array<IWorkerWalletItemModel>>([])

  const [visibleModal, setVisibleModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState<IKvItemModel>()
  const [listCategories, setListCategories] = useState<IKvItemModel[]>([])

  const loadData = async () => {
    setLoading(true)
    try {
      const categoryRes = await app.environment.api.getKvItemList(KvSkillPath.skillCategories)
      if (categoryRes.kind === 'ok') setListCategories(categoryRes.items)

      const _otherList: IWorkerWalletItemModel[] = []
      const qualificationRes = await app.environment.api.getWorkerQualification({
        'filters[user][id][$eq]': app?.userAccount?.id,
        "pagination[pageSize]": DEFAULT_LIMIT, sort: "id:DESC",
      })
      if (qualificationRes.kind === "ok") {
        qualificationRes.items
          ?.filter(item => !!item.user.id && !!item?.document?.id)
          ?.forEach(item => _otherList.push({
            id: item?.id,
            key: SelectTypeSections.qualification.key,
            parentId: item?.qualification?.qualificationEducationLevel?.id,
            type: item?.qualification?.qualificationEducationLevel?.name,
            description: item?.qualification?.courseName,
            url: item?.document,
            expiryDate: '', imgBack: null, imgFont: null, workerProfile: null, isCard: false,
            createdAt: item?.createdAt,
          }))
      }
      const skillRes = await app.environment.api.getWorkerSkill({
        'filters[user][id][$eq]': app?.userAccount?.id,
        "pagination[pageSize]": DEFAULT_LIMIT, sort: "id:DESC",
      })
      if (skillRes.kind === "ok") {
        skillRes.items
          ?.filter(item => item?.user?.id)
          ?.forEach(item => _otherList.push({
            id: item?.id,
            key: SelectTypeSections.skill.key,
            parentId: item?.skill?.skillCategory?.id,
            type: item?.skill?.skillCategory?.name,
            description: item?.skill?.description,
            url: item?.document,
            expiryDate: item?.expiryDate,
            createdAt: item?.createdAt,
            imgBack: item?.backImage?.url,
            imgFont: item?.frontImage?.url,
            workerProfile: null, isCard: item?.isCard,
          }))
      }

      const allRes = await Promise.all([
        {id: WorkerWalletItemPath.workerCvs, data: SelectTypeSections.cv},
        {id: WorkerWalletItemPath.workerRightToWorks, data: SelectTypeSections.rightToWork},
        {id: WorkerWalletItemPath.workerOthers, data: SelectTypeSections.other},
      ].map(async (wwItem) => {
        const itemRes = await app.environment.api.getWorkerWalletItemList(wwItem.id, {
          'filters[workerProfile][id][$eq]': app?.userAccount?.workerProfile?.id,
          "pagination[pageSize]": DEFAULT_LIMIT, sort: "id:DESC",
          populate: "url",
        })
        return itemRes.kind === "ok"
          ? itemRes.items?.filter(item => item?.url?.id)?.map((item) => {
            return ({...item, type: wwItem?.data?.name, key: wwItem?.data?.key})
          })
          : []
      }))
      allRes?.forEach(item => _otherList.push(...item))
      setOtherList(_otherList?.sort((a, b) => (
        (new Date(b.createdAt)).getTime() - (new Date(a.createdAt)).getTime()
      )))

    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useFocusEffect(useCallback(() => {
    loadData()
  }, []))

  const handleAddItem = () => {
    setVisibleModal(true)
  }

  const otherPress = (item: IWorkerWalletItemModel) => {
    navigation.navigate('detailCardScreen', {
      id: item?.id, key: item?.key, isCard: false
    } as IPureWorkerWalletItem)
  }
  const handleClickCard = (item: IWorkerWalletItemModel) => {
    navigation.navigate('detailCardScreen', {
      id: item?.id, key: item?.key, isCard: true
    } as IPureWorkerWalletItem)
  }

  const handleAddOtherItem = () => {
    setVisibleModal(false)
    navigation.navigate('workerUploadDocumentScreen')
  }

  const handleShare = () => {
    setVisibleModal(false)
    MainAppStore.setWorkerWalletItemShare([
      ...otherList
        ?.filter(item => (
          (!item?.isCard && item?.url?.id) ||
          (!!item?.isCard && (!selectedItem?.id || selectedItem?.id == item?.parentId))
        ))?.map(item => ({
          id: item?.id,
          key: item?.key,
          type: item?.type, description: item?.description, isCard: item?.isCard,
          urls: [item?.imgBack, item?.imgFont, item?.url?.url]?.filter(im => !!im)
        } as IWorkerWalletItemShareModel)),
    ])
    navigation.navigate('walletItemShareScreen')
  }

  const handleAddCard = () => {
    if (visibleModal) setVisibleModal(false)
    navigation.navigate('takePhotoModalScreen')
  }

  const handleAddDocs = () => {
    navigation.navigate('workerUploadDocumentScreen')
  }

  return (
    <SafeAreaView style={{flex: 1}}>
      <GradientBackground colors={[color.palette.gradientStart, color.palette.gradientStop]}/>
      <Header
        titlePreset={"headerTitle"} headerText={"My Wallet"} preset={"primary"}
        rightIcon="ellipsis-h"
        rightIconTheme="solid"
        rightIconPreset="smallBrand"
        onRightPress={handleAddItem}
      />
      <View style={{flex: 1}}>
        <ScrollView
          style={{flex: 1}} showsVerticalScrollIndicator={false}
          refreshControl={<RefreshControl refreshing={loading} onRefresh={loadData}/>}
        >
          <View style={{paddingHorizontal: 20}}>
            <LineTitle disableAdd title="Cards" onClick={handleAddCard}/>
            <Tags data={listCategories} selectedItem={selectedItem} setSelectedItem={setSelectedItem}/>
          </View>
          <View style={{paddingHorizontal: 10}}>
            <CardList
              data={otherList?.filter(item => !!item?.isCard && (!selectedItem?.id || selectedItem?.id == item?.parentId))}
              onClickCard={handleClickCard}/>
          </View>
          <View style={{paddingHorizontal: 20}}>
            <LineTitle disableAdd title="Other" onClick={() => navigation.navigate('workerUploadDocumentScreen')}/>
          </View>

          <View style={{paddingHorizontal: 12, width: '100%', marginTop: 4}}>
            {otherList?.filter(item => !item?.isCard && item?.url?.id)?.map((item) => (
              <OtherCard
                key={`${item?.key}-${item?.id}`}
                onClick={() => otherPress(item)}
                title={item?.type}
                subTile={item?.description || item?.url?.name}
                imgFile={urlIsDoc(item?.url?.url || '') ? "pdf-file" : item?.url?.url}/>
            ))}

            <OtherCard
              onClick={handleAddDocs}
              title="Click here to add file" imgFile="pdf-file"/>
          </View>
        </ScrollView>
      </View>
      <BottomModal style={{paddingTop: 20}} visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <View>
          <Button
            onPress={handleAddOtherItem}
            style={{marginTop: 10}}
            disabled={false}
            preset={'primary'}
            text={'Add Wallet Item'}
          />
          <Button
            onPress={handleShare}
            style={{marginTop: 10}}
            disabled={false}
            preset={'brand'}
            text={'Share Wallet Item(s)'}
          />
          <Button
            onPress={() => setVisibleModal(false)}
            style={{marginTop: 10}}
            disabled={false}
            preset={'primaryOutLine'}
            text={'Cancel'}
          />
        </View>
      </BottomModal>
    </SafeAreaView>
  )
})

