import React, {FC} from "react"
import {Image, ImageProps, ImageSourcePropType, ImageStyle, StyleProp, View, StyleSheet} from "react-native"
import {Button, Text} from "../index"
import {ButtonPresetNames} from "../button/button.presets";

export interface AwBannerCardProps {
  title: string,
  buttonText?: string,
  buttonPreset?: ButtonPresetNames,
  image?: ImageSourcePropType,
  imageView?: React.ReactNode,
  imageStyle?: StyleProp<ImageStyle>,
  imageProps?: ImageProps,
  onPress?: () => void,
  isDesktop?: boolean
  imgCustom?: any;
}

export const AwBannerCard: FC<AwBannerCardProps> = (props: AwBannerCardProps) => {
  const {
    title, buttonText, buttonPreset,
    image, imageProps, imageStyle, imageView, onPress, isDesktop, imgCustom
  } = props;
  return (
    <View style={[styles.wrapper, isDesktop && styles.wrapperDK]}>
      <View style={isDesktop ? styles.headerDK : styles.header}>
        <Text preset="h2" text={title} style={{color: '#FFF', maxWidth: 230}}/>
        <View style={{height: 16}}/>
        <Button
          style={!isDesktop && {marginRight: 16}}
          text={buttonText} preset={buttonPreset} onPress={onPress}/>
      </View>
      <View style={[isDesktop ? styles.imgBottomDK : styles.imgBottom, imgCustom]}>
        {imageView || (
          <Image
            source={image}
            style={[
              {
                width: '100%', height: "100%",
                ...(isDesktop ? null : {borderTopRightRadius: 8, borderBottomRightRadius: 8})
              }, imageStyle]}
            {...imageProps}
          />
        )}
      </View>
    </View>
  )
}
const styles = StyleSheet.create({
  wrapper: {
    overflow: 'hidden',
    flexDirection: "row", alignItems: "center",
    backgroundColor: "#222AB3", borderRadius: 8,
  },
  wrapperDK:{
    flexDirection: 'column',
    paddingVertical: 20, paddingHorizontal: 10, marginRight: 10,
    height: 'fit-content'
  },
  header:{
    flex: 6, paddingHorizontal: 20, paddingVertical: 20
  },
  headerDK:{
    marginBottom: 20,
  },
  imgBottom:{
    flex: 5, height: '100%', overflow: 'hidden'
  },
  imgBottomDK:{
    height: 466,
    width: 220
  }
})
