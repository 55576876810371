import React, {FC} from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import {Text} from '..';
import {color} from '../../theme';
import {SvgIcon} from '../icon/svg-icon';
import {SvgIconPresets, ThemeTypes} from "../icon/svg-icon.props";
import {TextPresets} from "../text/text.presets";
import Select, {StylesConfig} from 'react-select';
import {isAndroid} from "../../utils/platform";

export interface PickerSelectProps {
  label?: string
  onValueChange: any
}

export interface PickerItemOption {
  label: string;
  value: any;
  key?: string | number;
  color?: string;
  inputLabel?: string;
}

const BASE: ViewStyle = {}

const BASE_TEXT_INPUT = {
  backgroundColor: color.palette.bgBlue,
  marginTop: 4,
  borderWidth: 0,
  borderRadius: 10,
  paddingHorizontal: 16,
  placeholderTextColor: color.palette.greyDark,
  color: color.palette.greyDark,
}

export const presets = {
  default: BASE,
  light: BASE,
  brand: BASE,
  defaultTextInput: {...BASE_TEXT_INPUT},
}

const inputPresets = {
  default: {},
  brand: {},
  light: {},
}

const BASE_PICKER = {
}

const pickerPresets = {
  default: {
    ...BASE_PICKER,
    borderWidth: 0, borderRadius: 8, marginTop: 5,
    marginHorizontal: isAndroid ? 4 : 0,
    backgroundColor: color.palette.white,
    shadowColor: isAndroid ? color.palette.black : color.palette.black,
    shadowOffset: {width: 0, height: isAndroid ? 8 : 6},
    shadowOpacity: isAndroid ? 0.01 : 0.1,
    shadowRadius: 3,
    elevation: 8,
  },
  brand: {
    ...BASE_PICKER,
    borderRadius: 8,
    borderWidth: 1, borderColor: color.palette.greyMedH
  },
  light: {},
}

const wrapInputPresets = {
  default: {
    borderWidth: 1.5, borderColor: '#BBC2DC1A', borderRadius: 8,
    paddingTop: 6, paddingBottom: 6,
  },
  brand: {
    borderWidth: 1.5, borderColor: '#BBC2DC1A', borderRadius: 8,
    paddingTop: 6, paddingBottom: 6,
  },
  light: {
    borderWidth: 2, borderColor: color.palette.bgBlue, borderRadius: 8
  },
}

export type PickerSelectFieldPresets = keyof typeof presets

export interface PickerSelectFieldProps extends PickerSelectProps {
  value?: any
  onOpen?: any
  onClose?: any
  items?: PickerItemOption[]
  isDisabled?: boolean
  preset?: PickerSelectFieldPresets
  label?: string
  labelPreset?: TextPresets
  icon?: string
  placeholder?: string
  containerStyle?: StyleProp<ViewStyle>

  leftIcon?: string
  leftIconTheme?: ThemeTypes
  leftIconPreset?: SvgIconPresets

  rightIcon?: string
  rightIconTheme?: ThemeTypes
  rightIconPreset?: SvgIconPresets
  rightIconPress?: (e) => void
}

export const PickerSelectField: FC<PickerSelectFieldProps> = (props) => {
  const {
    value,
    items,
    isDisabled,
    preset,
    label,
    labelPreset,
    icon = 'chevron-down',
    placeholder,
    containerStyle: styleOverride,

    leftIcon,
    leftIconTheme,
    leftIconPreset = "innerTextField",
    rightIcon,
    rightIconTheme,
    rightIconPress,
    rightIconPreset = "innerTextField",
    onValueChange,
    onOpen,
    onClose,
    ...rest
  } = props;

  const containerStyles = [
    (presets[preset] || presets.default),
    styleOverride
  ];
  const inputStyles = {
    ...(inputPresets[preset] || inputPresets.default)
  }
  const pickerStyles = pickerPresets[preset] || pickerPresets.default
  const wrapInputStyles = {
    ...(wrapInputPresets[preset] || wrapInputPresets.default),
    ...(leftIcon ? {paddingLeft: 20} : null),
  }

  const handleValueChange = (item: PickerItemOption) => {
    const index = items.findIndex(_item => _item.key === item.key)
    onValueChange(item.key, index)
  }

  const colourStyles: StylesConfig<PickerItemOption> = {
    control: (styles) => ({...styles, ...wrapInputStyles}),
    input: (styles) => ({...styles, ...inputStyles}),
  };

  const itemValue = items.find(_item => _item.key === value)
  return (
    <View style={containerStyles}>
      {label ? (
        <Text preset={labelPreset} text={label} style={{marginBottom: 5}}/>
      ) : null}
      <View style={pickerStyles}>
        {leftIcon && (
          <View style={{
            position: 'absolute', top: 0, left: 0, zIndex: 1,
            paddingLeft: 10, height: '100%', justifyContent: 'center'
          }}>
            <SvgIcon
              icon={leftIcon} preset={leftIconPreset}
              theme={leftIconTheme || 'regular'}
            />
          </View>
        )}
        <Select
          options={items}
          styles={colourStyles}
          isDisabled={isDisabled ?? false}
          components={{
            IndicatorSeparator: () => null,
            IndicatorsContainer: () => (
              <View style={{paddingRight: 8}}>
                <SvgIcon
                  icon={rightIcon} preset={rightIconPreset}
                  theme={rightIconTheme || 'regular'}
                  onPress={rightIconPress}/>
              </View>
            ),
          }}
          placeholder={placeholder}
          onChange={handleValueChange}
          onMenuOpen={onOpen}
          onMenuClose={onClose}
          value={itemValue}
          {...rest}
        />
      </View>
    </View>
  );
}
