import React, {FC, useEffect, useState} from "react"
import {View, StyleSheet, ScrollView, TouchableOpacity, SafeAreaView} from "react-native"

import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {observer} from "mobx-react-lite"
import {color} from "../../theme"
import {Button, Header, Text, TextField} from "../../components"
import {SvgIcon} from "../../components/icon/svg-icon"
import {BackdropModal} from "../../components/backdrop-modal/backdrop-modal"
import {useStores} from "../../models";
import {dateToShortDateStr} from "../../utils/utils";
import {convertJobAdvertModel, CreateJobTags} from "../../models/app/job-advert";
import {ParamListBase, RouteProp} from "@react-navigation/native";
import {IKvItemCheckModel} from "../../models/app/kv-item";
import {AlertModalStore} from "../../models/app/alert-modal-store";
import {AlertModal} from "../../components/backdrop-modal/alert-modal";
import {constants, MIN_PASS_LENGTH} from "../../utils/constants";
import {identifyWithUserAccount, TrackEvents} from "../../services/track";
import {AdvertRoleStore} from "../../models/app/advert-role";
import {SelectTextField} from "../../components/text-field/select-text-field";
import {isAndroid} from "../../utils/platform";
import {PickerSelectField} from "../../components/picker-select/picker-select";
import {convertJobRoleAdModel} from "../../models/app/job-role-ad";
import {JobTagRow} from "../../components/tag/job-tag-row";
import {cast} from "mobx-state-tree";
import {DatePickerField} from "../../components/picker-select/date-picker";
import {NavigatorParamList} from "../../navigators";
import {LoadingModal} from "../../components/backdrop-modal/loading-modal";
import {AvatarImage} from "../../components/avatar-image/avatar-image";
import {useFilePicker} from "use-file-picker";
import {convertFileUploadModel} from "../../models/app/file-upload";
import AvatarDefault from "../../components/avatar-image/AvatarDefault";
import MobileInput from "../../components/text-field/mobile-input";


const WORK_TYPES = [
  'Full time',
  'Part time',
  'Casual',
  'Contractor',
  'Any',
]

export interface PublicJobAdvertEditScreenProps {
  navigation: NativeStackNavigationProp<ParamListBase>
  route: RouteProp<NavigatorParamList, 'jobAdvertEditScreen'>
}

export const PublicJobAdvertEditScreen: FC<PublicJobAdvertEditScreenProps> = observer(({navigation}) => {
  const {app} = useStores();

  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [responsibilities, setResponsibilities] = useState("")

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [mobile, setMobile] = useState({value: '', country: 'AU',});
  const [password, setPassword] = useState('');
  const [businessName, setBusinessName] = useState('')
  const [openFileSelector, {plainFiles, filesContent, clear}] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    maxFileSize: 2,
  });
  const firstAsset = !plainFiles || plainFiles.length <= 0 ? null : plainFiles[0]
  const firstContent = !filesContent || filesContent.length <= 0 ? null : filesContent[0]
  const [loading, setLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false);

  const selectedContact = app?.selectedContact
  const [paymentTypes, setPaymentTypes] = useState<Array<IKvItemCheckModel>>([])
  const [payType, setPayType] = useState(0)
  const [payTypeOpen, setPayTypeOpen] = useState(false)
  const [amount, setAmount] = useState('')
  const [numberOfPositions, setNumberOfPositions] = useState('')
  const [startDate, setStartDate] = useState(new Date())
  const [workType, setWorkType] = useState(null)
  const [workTypeOpen, setWorkTypeOpen] = useState(false)

  const [confirmVisible, setConfirmVisible] = useState(false)
  const [resultVisible, setResultVisible] = useState(false)
  const [datePickerVisible, setDatePickerVisible] = useState(false)

  const loadData = async () => {
    try {
      const res = await app.environment.api.getPaymentTypes()
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message)
        return
      }
      setPaymentTypes(res.items.map<IKvItemCheckModel>((item) => ({
        ...item, checked: false
      })))
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  // const toggleSelectContact = (index) => {
  //   setPaymentTypes(paymentTypes.map((it, id) => (id === index ? {...it, checked: !it.checked} : it)))
  // }

  const handlePostJobAd = async (isDraft = false) => {
    if (!password) {
      AlertModalStore.alert('Error', `Please set password`)
      return
    } else if (password?.length < MIN_PASS_LENGTH) {
      AlertModalStore.alert('Error', `Password must at least ${MIN_PASS_LENGTH} characters length`)
      return
    }
    const hasUnsavedRequires = !firstName?.trim() || !lastName?.trim() || !email?.trim();
    if (hasUnsavedRequires) {
      AlertModalStore.alert('Error', 'Please enter require fields')
      return
    }
    if (!businessName || !firstAsset) {
      AlertModalStore.alert('Alert', 'Please enter Business info')
      return
    }
    if (!title) {
      AlertModalStore.alert('Alert', 'Please enter Job ad title')
      return
    }

    try {
      setLoading(true)
      const roles = AdvertRoleStore.roles.toJSON()
      const _paymentType = paymentTypes?.find(it => it.id === payType)

      const blRes: Response = await fetch(firstContent?.content);
      const blob: Blob = await blRes.blob();
      const fileRes = await app.environment.api.uploadFile(
        new File([blob], firstAsset?.name, {type: firstAsset?.type})
      )
      if (fileRes.kind !== 'ok') {
        AlertModalStore.alert('Error', 'Can not upload file')
        return
      }

      const jobRoleRes = await app.environment.api.createJobAdvertRoleByData({
        ...(roles?.length > 0 ? {role: roles[0].id} : {}),
        amount: amount,
        numberOfPositions: parseInt(numberOfPositions) || 0,
        startDate: startDate.toISOString(),
        workType: workType || '',
        locations: AdvertRoleStore?.locations.map(item => item.id),
        industries: AdvertRoleStore?.industries.map(item => item.id),
        ...(selectedContact?.id ? {contact: selectedContact?.id} : null),
        experiences: AdvertRoleStore.experience.map(item => item.id),
        skills: AdvertRoleStore.skills.map(item => item.id),
        qualifications: AdvertRoleStore.qualifications.map(item => item.id)
      })
      if (jobRoleRes.kind !== 'ok') {
        AlertModalStore.alert('Error', jobRoleRes.message || '')
        return
      }

      const res = await app.onSignUp({
        firstName, lastName, username: email, email, mobile: mobile?.value, countryCode: mobile?.country,
        userRoles: app.userRoles.filter(it => (it.key === constants.roles.BUSINESS))?.map(it => it.id),
        password,
        businessProfile: {logo: fileRes.file.id, name: businessName},
        jobAdvert: {
          title,
          description,
          responsibilities,
          isDraft: true,
          closed: false,
          jobRoles: [jobRoleRes?.jobRole?.id],
          paymentTypes: _paymentType ? [_paymentType] : [],
        }
      })

      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message || '')
        return
      }

      await identifyWithUserAccount(TrackEvents.SignedUp, res?.userAccount)
      setResultVisible(true)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false);
    }
  }

  const handlePreview = async () => {
    const roles = AdvertRoleStore.roles.toJSON()
    const _paymentType = paymentTypes?.find(it => it.id === payType)
    AdvertRoleStore.setPreviewJobRoleAd({
      ...convertJobRoleAdModel({}),
      ...(roles?.length > 0 ? {role: {...roles[0]}} : null),
      amount,
      numberOfPositions: parseInt(numberOfPositions) || 0,
      startDate: startDate.toISOString(),
      workType,
      jobAd: {
        ...convertJobAdvertModel({}),
        title,
        description,
        responsibilities,
        isDraft: true,
        closed: false,
        paymentTypes: cast(_paymentType ? [{
          id: _paymentType?.id, name: _paymentType?.name
        }] : []),
      }
    })
    AdvertRoleStore.setPreviewLogo(convertFileUploadModel({
      url: firstContent?.content
    }))
    navigation.navigate('jobsAdPreviewScreen')
  }

  const handlePostJobAdPublic = async () => {
    await handlePostJobAd(false)
  }

  const handleSaveJobAd = async () => {
    setResultVisible(false)
    navigation.goBack()
  }

  const toggleWorkTypeOpen = () => {
    setWorkTypeOpen(!workTypeOpen)
  }

  const handleSetDate = (date: Date) => {
    setStartDate(date)
  }

  const showUploadLogo = () => {
    setModalVisible(true)
  }
  const handleLogoPress = async () => {
    if (firstAsset) {
      clear()
    } else showUploadLogo()
  }

  const handleUploadImage = async () => {
    await openFileSelector()
    setModalVisible(false)
  }

  const onSetPhoneNumber = ({value, country}: any) => {
    setMobile({value, country})
  }

  return (
    <>
      <SafeAreaView style={styles.container}>
        <Header
          preset={"primary"}
          titlePreset={"headerTitle"}
          headerText={"Create New Job Advert"}
        />
        <View style={{flex: 1, backgroundColor: color.palette.white}}>
          <ScrollView style={{flex: 1, paddingHorizontal: 24,}} showsVerticalScrollIndicator={false}>
            <View style={styles.jobAdDetails}>
              <View>
                <View style={{marginBottom: 20}}>
                  <TextField
                    preset={"brand"}
                    value={email} onChangeText={setEmail}
                    label="Email Address*" placeholder="Enter email address"
                    labelPreset={"formLabel"}
                  />
                </View>
                <View style={{marginBottom: 24}}>
                  <TextField
                    preset={"brand"}
                    value={businessName} onChangeText={setBusinessName}
                    label="Business Name*" placeholder="Business Name"
                    labelPreset={"formLabel"}
                  />
                </View>
                <View style={{marginBottom: 24}}>
                  <Text preset="formLabel">Business Logo*</Text>
                  <View style={{alignItems: 'center'}}>
                    <View>
                      {firstContent?.content ? <AvatarImage
                          onPress={handleLogoPress}
                          preset={'lgRound'}
                          source={{uri: firstContent?.content}}/> :
                        <AvatarDefault onPress={handleLogoPress} name={businessName} preset={"lgRound"}/>}
                      <View
                        style={{
                          position: 'absolute',
                          right: 4,
                          top: 4,
                          display: firstContent?.content ? 'flex' : 'none'
                        }}>
                        <SvgIcon onPress={handleLogoPress} preset={'dark'} icon={'times'} theme={'regular'}/>
                      </View>
                      <View style={{position: 'absolute', right: 4, bottom: 4}}>
                        <SvgIcon onPress={showUploadLogo} preset={'dark'} icon={'camera'} theme={'solid'}/>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View style={{marginBottom: 24}}>
                <TextField
                  preset={"brand"}
                  value={title} onChangeText={setTitle}
                  label={"Advert Title*"} placeholder={'Eg Chef Needed, Experienced Painters Wanted'}
                  labelPreset={"formLabel"}
                />
              </View>
              <View style={{marginBottom: 24, marginHorizontal: isAndroid ? 6 : 0}}>
                <SelectTextField
                  onPress={() => navigation.navigate("jobRoleInfoDetailScreen", {tag: CreateJobTags.industries.id})}
                  preset={"brand"}
                  value={AdvertRoleStore.industries?.length > 0 ? '' : null} placeholder={'Select Industry'}
                  rightIcon={"chevron-right"}
                  label={CreateJobTags.industries.text} labelPreset={"formLabel"}
                >
                  {
                    AdvertRoleStore.industries?.length > 0 ? (
                      <View style={{minHeight: 48, flex: 1, paddingHorizontal: 10}}>
                        {AdvertRoleStore.industries.map((item) => (
                          <JobTagRow
                            key={String(item?.id)}
                            text={item.name}
                            onPress={() => AdvertRoleStore.toggleSelectRoleIndex(CreateJobTags.industries.id, item.id)}/>
                        ))}
                      </View>
                    ) : null
                  }
                </SelectTextField>
              </View>
              <View style={{marginBottom: 24, marginHorizontal: isAndroid ? 6 : 0}}>
                <SelectTextField
                  onPress={() => navigation.navigate("jobRoleInfoDetailScreen", {tag: CreateJobTags.locations.id})}
                  preset={"brand"}
                  value={AdvertRoleStore.locations?.length > 0 ? '' : null}
                  placeholder={'Enter Location Eg Alice Springs'}
                  rightIcon={"chevron-right"}
                  label={CreateJobTags.locations.text} labelPreset={"formLabel"}
                >
                  {AdvertRoleStore.locations?.length > 0 ? (
                    <View style={{minHeight: 48, flex: 1, paddingHorizontal: 10}}>
                      {AdvertRoleStore.locations.map((item) => (
                        <JobTagRow
                          key={String(item?.id)}
                          text={item.name}
                          onPress={() => AdvertRoleStore.toggleSelectRoleIndex(CreateJobTags.locations.id, item.id)}/>
                      ))}
                    </View>
                  ) : null}
                </SelectTextField>
              </View>
              <View style={{flexDirection: "row", marginBottom: 20, zIndex: 100}}>
                <View style={{marginRight: 20, flex: 1}}>
                  <TextField
                    value={numberOfPositions} onChangeText={setNumberOfPositions}
                    placeholder={'Enter Number'}
                    preset={"brand"}
                    label={"No. Of Positions"}
                    labelPreset={"formLabel"}
                  />
                </View>
                <View style={{flex: 1}}>
                  <DatePickerField
                    visible={datePickerVisible}
                    onChange={handleSetDate}
                    value={startDate} maxDate={null}
                    customInput={
                      <SelectTextField
                        value={startDate ? dateToShortDateStr(startDate) : null}
                        onPress={() => setDatePickerVisible(!datePickerVisible)}
                        preset={'brand'}
                        placeholder="DD/MM/YY" label="Start date" labelPreset="formLabel"/>
                    }
                  />
                </View>
              </View>
              <View style={{marginBottom: 20, zIndex: 99}}>
                <PickerSelectField
                  onOpen={() => setPayTypeOpen(true)}
                  onClose={() => setPayTypeOpen(false)}
                  value={payType}
                  onValueChange={setPayType}
                  items={paymentTypes?.map(item => ({
                    key: item.id, value: item.id,
                    label: item?.name
                  }))}
                  preset={"brand"}
                  label={"Pay Type"} labelPreset={"formLabel"}
                  placeholder="Select from list"
                  rightIcon={payTypeOpen ? 'chevron-down' : 'chevron-right'}
                />
              </View>
              <View style={{marginBottom: 20}}>
                <TextField
                  value={amount} onChangeText={setAmount} keyboardType={'number-pad'}
                  preset={"brand"}
                  style={{flex: 1}} placeholder={'Enter Pay Amount or Pay Range'}
                  label={"Amount"}
                  labelPreset={"formLabel"}
                />
              </View>
              <View style={{marginBottom: 20, zIndex: 9}}>
                <PickerSelectField
                  onOpen={toggleWorkTypeOpen}
                  onClose={toggleWorkTypeOpen}
                  value={workType}
                  onValueChange={setWorkType} items={WORK_TYPES.map(it => ({key: it, value: it, label: it}))}
                  preset={"brand"}
                  label={"Work Type"} labelPreset={"formLabel"}
                  placeholder="Select from list"
                  rightIcon={workTypeOpen ? 'chevron-down' : 'chevron-right'}
                />
              </View>
              <View style={{marginBottom: 20, marginHorizontal: isAndroid ? 6 : 0}}>
                <SelectTextField
                  onPress={() => navigation.navigate("jobRoleInfoDetailScreen", {tag: CreateJobTags.roles.id})}
                  preset={"brand"}
                  rightIcon={"chevron-right"}
                  value={AdvertRoleStore.roles?.length > 0 ? '' : null}
                  placeholder={'Enter Role Eg Retail Assistant, Chef, Plasterer'}
                  label={CreateJobTags.roles.text} labelPreset={"formLabel"}
                >{
                  AdvertRoleStore.roles?.length > 0 ? (
                    <View style={{minHeight: 48, flex: 1, paddingHorizontal: 10}}>
                      {AdvertRoleStore.roles.map((item) => (
                        <JobTagRow
                          key={String(item?.id)}
                          text={item.name}
                          onPress={() => AdvertRoleStore.toggleSelectRoleIndex(CreateJobTags.roles.id, item.id)}/>
                      ))}
                    </View>
                  ) : null
                }</SelectTextField>
              </View>
              <View style={{marginBottom: 20}}>
                <TextField
                  preset={"brand"}
                  value={description} onChangeText={setDescription}
                  multiline={true}
                  numberOfLines={6}
                  inputStyle={{height: 120}} placeholder={'Enter Description'}
                  label={"Description"}
                  labelPreset={"formLabel"}
                />
              </View>
              <View style={{marginBottom: 20}}>
                <TextField
                  preset={"brand"}
                  value={responsibilities} onChangeText={setResponsibilities}
                  multiline={true}
                  numberOfLines={6}
                  inputStyle={{height: 120}} placeholder={'Enter Responsibilities'}
                  label={"Responsibilities"}
                  labelPreset={"formLabel"}
                />
              </View>
              <View style={{marginTop: 34}}>
                <View style={{paddingBottom: 8}}>
                  <Text preset="h4Dark">{CreateJobTags.qualifications.text}</Text>
                </View>
                <Text preset="body2" style={{color: color.palette.dark, marginTop: 0}}>
                  {CreateJobTags.qualifications.label}
                </Text>
                <View style={{marginTop: 4}}>
                  <Text preset="body2" style={{color: color.palette.lightGrey, fontStyle: 'italic'}}>
                    {CreateJobTags.qualifications.hint}
                  </Text>
                </View>
                <View style={{marginTop: 16, backgroundColor: color.palette.white}}>
                  <View style={{flexDirection: "row", flexWrap: 'wrap', alignItems: 'flex-start'}}>
                    {AdvertRoleStore.qualifications?.map((item) => (
                      <JobTagRow
                        key={String(item?.id)}
                        text={item.name}
                        onPress={() => AdvertRoleStore.toggleSelectRoleIndex(CreateJobTags.qualifications.id, item.id)}/>
                    ))}
                  </View>
                  <View style={{flexDirection: 'row', alignItems: 'flex-start'}}>
                    <TouchableOpacity
                      onPress={() => navigation.navigate("jobRoleInfoDetailScreen", {tag: CreateJobTags.qualifications.id})}
                      style={{
                        flexDirection: 'row', alignItems: 'center',
                        borderRadius: 6,
                        paddingVertical: 6, paddingRight: 12, borderWidth: 1, borderColor: color.palette.primary
                      }}>
                      <View style={{paddingLeft: 6, paddingRight: 8}}>
                        <SvgIcon icon={'plus'} preset={'primary'}/>
                      </View>
                      <Text style={{fontWeight: '500', color: color.palette.primary}}>Add</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
              <View style={{marginTop: 34}}>
                <View style={{paddingBottom: 8}}>
                  <Text preset="h4Dark">{CreateJobTags.skills.text}</Text>
                </View>
                <Text preset="body2" style={{color: color.palette.dark, marginTop: 0}}>
                  {CreateJobTags.skills.label}
                </Text>
                <View style={{marginTop: 4}}>
                  <Text preset="body2" style={{color: color.palette.lightGrey, fontStyle: 'italic'}}>
                    {CreateJobTags.skills.hint}
                  </Text>
                </View>
                <View style={{marginTop: 16, backgroundColor: color.palette.white}}>
                  <View style={{flexDirection: "row", flexWrap: 'wrap'}}>
                    {AdvertRoleStore.skills?.map((item) => (
                      <JobTagRow
                        key={String(item?.id)}
                        text={item.name}
                        onPress={() => AdvertRoleStore.toggleSelectRoleIndex(CreateJobTags.skills.id, item.id)}/>
                    ))}
                  </View>
                  <View style={{flexDirection: 'row', alignItems: 'flex-start'}}>
                    <TouchableOpacity
                      onPress={() => navigation.navigate("jobRoleInfoDetailScreen", {tag: CreateJobTags.skills.id})}
                      style={{
                        flexDirection: 'row', alignItems: 'center',
                        borderRadius: 6,
                        paddingVertical: 6, paddingRight: 12, borderWidth: 1, borderColor: color.palette.primary
                      }}>
                      <View style={{paddingLeft: 6, paddingRight: 8}}>
                        <SvgIcon icon={'plus'} preset={'primary'}/>
                      </View>
                      <Text style={{fontWeight: '500', color: color.palette.primary}}>Add</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
              <View style={{marginTop: 34}}>
                <View style={{paddingBottom: 8}}>
                  <Text preset="h4Dark">{CreateJobTags.experience.text}</Text>
                </View>
                <Text preset="body2" style={{color: color.palette.dark, marginTop: 0}}>
                  {CreateJobTags.experience.label}
                </Text>
                <View style={{marginTop: 4}}>
                  <Text preset="body2" style={{color: color.palette.lightGrey, fontStyle: 'italic'}}>
                    {CreateJobTags.experience.hint}
                  </Text>
                </View>
                <View style={{marginTop: 16, backgroundColor: color.palette.white}}>
                  <View style={{flexDirection: "row", flexWrap: 'wrap'}}>
                    {AdvertRoleStore.experience?.map((item) => (
                      <JobTagRow
                        key={String(item?.id)}
                        text={item.name}
                        onPress={() => AdvertRoleStore.toggleSelectRoleIndex(CreateJobTags.experience.id, item.id)}/>
                    ))}
                  </View>
                  <View style={{flexDirection: 'row', alignItems: 'flex-start'}}>
                    <TouchableOpacity
                      onPress={() => navigation.navigate("jobRoleInfoDetailScreen", {tag: CreateJobTags.experience.id})}
                      style={{
                        flexDirection: 'row', alignItems: 'center',
                        borderRadius: 6,
                        paddingVertical: 6, paddingRight: 12, borderWidth: 1, borderColor: color.palette.primary
                      }}>
                      <View style={{paddingLeft: 6, paddingRight: 8}}>
                        <SvgIcon icon={'plus'} preset={'primary'}/>
                      </View>
                      <Text style={{fontWeight: '500', color: color.palette.primary}}>Add</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
              <View style={{flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20, marginTop: 24}}>
                <TextField
                  value={firstName} onChangeText={setFirstName}
                  preset={"brand"}
                  style={{flex: 1}}
                  placeholder="First name"
                  label="First Name*"
                  labelPreset="formLabel"
                />
                <View style={{width: isAndroid ? 10 : 20}}/>
                <TextField
                  value={lastName} onChangeText={setLastName}
                  preset={"brand"}
                  style={{flex: 1}}
                  placeholder="Last name"
                  label="Last Name*"
                  labelPreset="formLabel"
                />
              </View>
              <View style={{marginBottom: 20}}>
                <View style={{marginHorizontal: isAndroid ? 4 : 0, marginBottom: 5}}>
                  <Text preset={'formLabel'} text={'Mobile Number'}/>
                </View>
                <View>
                  <MobileInput
                    // phoneRef={phoneRef}
                    preset={'brand'}
                    setPhoneNumber={onSetPhoneNumber}
                    defaultValue={mobile?.value}
                    placeholder={'Enter your number'}
                    codeTextStyle={{color: color.palette.black80}}
                    defaultCode={mobile?.country}
                    textInputProps={{maxLength: 10}}
                  />
                </View>
              </View>
              <View style={{marginBottom: 0}}>
                <TextField
                  value={password} onChangeText={setPassword}
                  preset={'brand'}
                  label="Password*" placeholder="Minimum 8 characters"
                  labelPreset="formLabel" secureTextEntry={true} rightIcon="lock"
                />
              </View>
            </View>
            <View style={{marginTop: 46}}>
              <View style={{marginBottom: 10}}>
                <Button
                  preset={"primary"}
                  text={"Preview"}
                  onPress={handlePreview}
                />
              </View>
              <View style={{marginBottom: 10}}>
                <Button
                  preset={"brand"}
                  text={"Post Job Advert"}
                  onPress={handlePostJobAdPublic}
                />
              </View>
              <Button
                text={"Cancel"}
                preset={"destructive"}
                onPress={() => setConfirmVisible(true)}
              />
            </View>
            <View style={{height: 80}}/>
          </ScrollView>
        </View>
      </SafeAreaView>
      <BackdropModal contentStyle={{maxWidth: 576}} visible={confirmVisible} onClose={() => setConfirmVisible(false)}>
        <View>
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              padding: 10,
            }}
          >
            <SvgIcon preset={"alertIcon"}/>
            <View style={{paddingTop: 18, paddingBottom: 8}}>
              <Text preset={"alertTitle"} style={{textAlign: 'center'}} text={'Are you sure?'}/>
              <View style={{height: 10}}/>
              <Text preset={"alertTitle"} style={{textAlign: 'center'}} text={'Your data will be lost.'}/>
            </View>
          </View>
          <View style={{flexDirection: 'row'}}>
            <Button
              onPress={() => setConfirmVisible(false)}
              style={styles.modalBtn}
              disabled={false}
              preset={"destructive"}
              text={"No"}
            />
            <View style={{width: 30}}/>
            <Button
              onPress={() => {
                setConfirmVisible(false)
                navigation.goBack()
              }}
              style={styles.modalBtn}
              disabled={false}
              text={"Yes"}
            />
          </View>
        </View>
      </BackdropModal>
      <BackdropModal visible={resultVisible} onClose={handleSaveJobAd}>
        <View style={{alignItems: "center", justifyContent: "center", padding: 10}}>
          <AvatarImage preset={'mailImage'} source={require('../../../assets/images/icons/Frame_3.png')}/>
          <View style={{paddingTop: 18, paddingBottom: 8}}>
            <Text preset={"alertTitle"} style={{textAlign: 'center'}} text={'Almost there...'}/>
            <View style={{marginTop: 10}}>
              <Text preset={"alertBody"} style={{textAlign: 'center'}}
                    text={'Verify your email to post your free job ad.'}/>
            </View>
            <View style={{marginTop: 20}}>
              <Button onPress={handleSaveJobAd} text={"Ok"} preset="brand"/>
            </View>
          </View>
        </View>
      </BackdropModal>
      <AlertModal/>
      <LoadingModal visible={loading}/>
      <BackdropModal visible={modalVisible} onClose={() => setModalVisible(false)}>
        <View style={{paddingHorizontal: 10}}>
          <View style={{marginBottom: 0}}>
            <Button text="Upload from library" preset={'brandOutLine'} onPress={handleUploadImage}/>
          </View>
        </View>
      </BackdropModal>
    </>
  )
})

const styles = StyleSheet.create({
  body: {paddingHorizontal: 10,},
  container: {flex: 1, backgroundColor: color.palette.white},
  modalBtn: {flex: 1, marginVertical: 8,},
  jobAdDetails: {marginTop: 24, paddingBottom: 10, backgroundColor: color.palette.white,},
})
