//Regular icons
import {ReactComponent as SignInRegular} from '../../../assets/images/svg/regular/sign-in.svg';
import {ReactComponent as ChevronRightRegular} from '../../../assets/images/svg/regular/chevron-right.svg';
import {ReactComponent as DollarSignRegular} from '../../../assets/images/svg/regular/dollar-sign.svg';
import {ReactComponent as UserRegular} from '../../../assets/images/svg/regular/user.svg';
import {ReactComponent as MapPinRegular} from '../../../assets/images/svg/regular/map-pin.svg';
import {ReactComponent as TimesRegular} from '../../../assets/images/svg/regular/times.svg';
import {ReactComponent as ArrowLeftRegular} from '../../../assets/images/svg/regular/arrow-left.svg';
import {ReactComponent as SearchRegular} from '../../../assets/images/svg/regular/search.svg';
import {ReactComponent as ArrowRightRegular} from '../../../assets/images/svg/regular/arrow-right.svg';
import {ReactComponent as MapRegular} from '../../../assets/images/svg/regular/map.svg';
import {ReactComponent as IndustryAltRegular} from '../../../assets/images/svg/regular/industry-alt.svg';
import {ReactComponent as UserHardHatRegular} from '../../../assets/images/svg/regular/user-hard-hat.svg';
import {ReactComponent as DiplomaRegular} from '../../../assets/images/svg/regular/diploma.svg';
import {ReactComponent as CertificateRegular} from '../../../assets/images/svg/regular/certificate.svg';
import {ReactComponent as IdBadgeRegular} from '../../../assets/images/svg/regular/id-badge.svg';
import {ReactComponent as TimesCircleRegular} from '../../../assets/images/svg/regular/times-circle.svg';
import {ReactComponent as ToolBoxRegular} from '../../../assets/images/svg/regular/toolbox.svg';
import {ReactComponent as CaretDownRegular} from '../../../assets/images/svg/regular/caret-down.svg';
import {ReactComponent as FilterRegular} from '../../../assets/images/svg/regular/filter.svg';
import {ReactComponent as CommentAltDotsRegular} from '../../../assets/images/svg/regular/comment-alt-dots.svg';
import {ReactComponent as TimesCircleFillRegular} from '../../../assets/images/svg/regular/times-circle-fill.svg';
import {ReactComponent as TimesCircleDarkRegular} from '../../../assets/images/svg/regular/times-circle-dark.svg';
import {ReactComponent as CalendarDayRegular} from '../../../assets/images/svg/regular/calendar-day.svg';
import {ReactComponent as ClockRegular} from '../../../assets/images/svg/regular/clock.svg';
import {ReactComponent as FileUploadRegular} from '../../../assets/images/svg/regular/file-upload.svg';
import {ReactComponent as FileUploadCloudRegular} from '../../../assets/images/svg/regular/file-upload-cloud.svg';
import {ReactComponent as ChevronDownRegular} from '../../../assets/images/svg/regular/chevron-down.svg';
import {ReactComponent as BuildingRegular} from '../../../assets/images/svg/regular/building.svg';
import {ReactComponent as LockRegular} from '../../../assets/images/svg/regular/lock.svg';
import {ReactComponent as SignOutRegular} from '../../../assets/images/svg/regular/sign-out.svg';
import {ReactComponent as EnvelopeRegular} from '../../../assets/images/svg/regular/envelope.svg';
import {ReactComponent as NoticeBoardRegular} from '../../../assets/images/svg/regular/home.svg';
import {ReactComponent as UserCogRegular} from '../../../assets/images/svg/regular/user-cog.svg';
import {ReactComponent as RetweetAltRegular} from '../../../assets/images/svg/regular/retweet-alt.svg';
import {ReactComponent as BellRegular} from '../../../assets/images/svg/regular/bell.svg';
import {ReactComponent as QuestionRegular} from '../../../assets/images/svg/regular/question.svg';
import {ReactComponent as PlusRegular} from '../../../assets/images/svg/regular/plus.svg';
import {ReactComponent as UploadRegular} from '../../../assets/images/svg/regular/upload.svg';
import {ReactComponent as ChevronUpRegular} from '../../../assets/images/svg/regular/chevron-up.svg';
import {ReactComponent as CommentAltLinesRegular} from '../../../assets/images/svg/regular/comment-alt-lines.svg';
import {ReactComponent as WalletRegular} from '../../../assets/images/svg/regular/wallet.svg';
import {ReactComponent as ExclamationCircleRegular} from '../../../assets/images/svg/regular/exclamation-circle.svg';
import {ReactComponent as CheckCircleRegular} from '../../../assets/images/svg/regular/check-circle.svg';
import {ReactComponent as CopyRegular} from '../../../assets/images/svg/regular/copy.svg';
import {ReactComponent as FileSearchRegular} from '../../../assets/images/svg/regular/file-search.svg';
import {ReactComponent as MegaphoneRegular} from '../../../assets/images/svg/regular/megaphone.svg';
import {ReactComponent as ClipBoardRegular} from '../../../assets/images/svg/regular/clipboard.svg';
import {ReactComponent as HeartRegular} from '../../../assets/images/svg/regular/heart.svg';
import {ReactComponent as CircleRegular} from '../../../assets/images/svg/regular/circle.svg';
import {ReactComponent as AddressBookRegular} from '../../../assets/images/svg/regular/address-book.svg';
import {ReactComponent as StarRegular} from '../../../assets/images/svg/regular/star.svg';
import {ReactComponent as BookmarkRegular} from '../../../assets/images/svg/regular/bookmark-regular.svg';
import {ReactComponent as UsersRegular} from '../../../assets/images/svg/regular/users.svg';
import {ReactComponent as UserPlusRegular} from '../../../assets/images/svg/regular/user-plus.svg';
import {ReactComponent as UsersMedicalRegular} from '../../../assets/images/svg/regular/users-medical.svg';
import {ReactComponent as UsersClassRegular} from '../../../assets/images/svg/regular/users-class.svg';
import {ReactComponent as MinusCircleRegular} from '../../../assets/images/svg/regular/minus-circle.svg';
import {ReactComponent as SquareRegularRegular} from '../../../assets/images/svg/regular/square-regular.svg';
import {ReactComponent as PlusSquareRegular} from '../../../assets/images/svg/regular/plus-square.svg';
import {ReactComponent as CommentAltCheckRegular} from '../../../assets/images/svg/regular/comment-alt-check.svg';
import {ReactComponent as OfficeCaseRegular} from '../../../assets/images/svg/regular/officecase.svg';
import {ReactComponent as ChatBubbleMessageRegular} from '../../../assets/images/svg/regular/chatbubble-message.svg';
import {ReactComponent as GearringRegular} from '../../../assets/images/svg/regular/gearring.svg';
import {ReactComponent as UserGroupRegular} from '../../../assets/images/svg/regular/usergroup.svg';
import {ReactComponent as ChevonDownUser} from '../../../assets/images/svg/regular/chevon-down-user.svg';
import {ReactComponent as FilterUser} from '../../../assets/images/svg/regular/filter-user.svg';
import {ReactComponent as RightRegular} from '../../../assets/images/svg/regular/rights.svg';
import {ReactComponent as TriangleRightRegular} from '../../../assets/images/svg/regular/triangle-right.svg';
import {ReactComponent as StarThinRegular} from '../../../assets/images/svg/regular/star-regular.svg';
import {ReactComponent as StarLightRegular} from '../../../assets/images/svg/regular/star-light.svg';
import {ReactComponent as CheckRegular} from '../../../assets/images/svg/regular/check-regular.svg';
import {ReactComponent as ShareRegular} from '../../../assets/images/svg/regular/share.svg';
import {ReactComponent as ShareAltRegular} from '../../../assets/images/svg/regular/share-alt.svg';
import {ReactComponent as LocationDotRegular} from '../../../assets/images/svg/regular/location-dot.svg';
import {ReactComponent as PlusCircleRegular} from '../../../assets/images/svg/regular/plus-circle.svg';
import {ReactComponent as CircleNotchRegular} from '../../../assets/images/svg/regular/circle-notch.svg';
import {ReactComponent as SubtractRegular} from '../../../assets/images/svg/regular/subtract.svg';
import {ReactComponent as ArrowCircleUpRegular} from '../../../assets/images/svg/regular/arrow-circle-up.svg';
import {ReactComponent as MapMarkerAltRegular} from '../../../assets/images/svg/regular/map-marker-alt.svg';
import {ReactComponent as SettingsRegular} from '../../../assets/images/svg/regular/settings.svg';
import {ReactComponent as FilePenRegular} from '../../../assets/images/svg/regular/file-pen.svg';
import {ReactComponent as MoneyCheckDollarRegular} from '../../../assets/images/svg/regular/money-check-dollar.svg';
import {ReactComponent as PenFieldRegular} from '../../../assets/images/svg/regular/pen-field.svg';
import {ReactComponent as IdCardRegular} from '../../../assets/images/svg/regular/id-card.svg';
import {ReactComponent as FileUserRegular} from '../../../assets/images/svg/regular/file-user.svg';
import {ReactComponent as ClipboardCheckRegular} from '../../../assets/images/svg/regular/clipboard-check.svg';
import {ReactComponent as MessageQuestionRegular} from '../../../assets/images/svg/regular/message-question.svg';
import {ReactComponent as ShieldRegular} from '../../../assets/images/svg/regular/shield.svg';
import {ReactComponent as ListCheckRegular} from '../../../assets/images/svg/regular/list-check.svg';
import {ReactComponent as BarsProgressRegular} from '../../../assets/images/svg/regular/bars-progress.svg';
import {ReactComponent as InboxRegular} from '../../../assets/images/svg/regular/Inbox.svg';
import {ReactComponent as WalletIntroRegular} from '../../../assets/images/svg/regular/wallet-intro.svg';
import {ReactComponent as MenuRegular} from '../../../assets/images/svg/regular/menu.svg';
import {ReactComponent as CameraCard} from '../../../assets/images/svg/regular/camera-card.svg';
import {ReactComponent as PdfFile} from '../../../assets/images/svg/regular/pdf-file.svg';
import {ReactComponent as MinusRegular} from '../../../assets/images/svg/regular/minus.svg';
// fontawesome brands
import {ReactComponent as LinkedinBrands} from '../../../assets/images/svg/brands/linkedin.svg';
import {ReactComponent as InstagramBrands} from '../../../assets/images/svg/brands/instagram.svg';
import {ReactComponent as FacebookBrands} from '../../../assets/images/svg/brands/facebook.svg';
import {ReactComponent as TwitterBrands} from '../../../assets/images/svg/brands/twitter.svg';

//fontawesome solid
import {ReactComponent as NoticeBoardSolid} from '../../../assets/images/svg/solid/home.svg';
import {ReactComponent as UserCogSolid} from '../../../assets/images/svg/solid/user-cog.svg';
import {ReactComponent as UploadSolid} from '../../../assets/images/svg/solid/upload.svg';
import {ReactComponent as ToolBoxSolid} from '../../../assets/images/svg/solid/toolbox.svg';
import {ReactComponent as CommentAltLinesSolid} from '../../../assets/images/svg/solid/comment-alt-lines.svg';
import {ReactComponent as WalletSolid} from '../../../assets/images/svg/solid/wallet.svg';
import {ReactComponent as ArrowCircleUpSolid} from '../../../assets/images/svg/solid/arrow-circle-up.svg';
import {ReactComponent as EllipsishSolid} from '../../../assets/images/svg/solid/ellipsis-h.svg';
import {ReactComponent as CheckCircleSolid} from '../../../assets/images/svg/solid/check-circle.svg';
import {ReactComponent as AddressBookSolid} from '../../../assets/images/svg/solid/address-book.svg';
import {ReactComponent as CheckSolid} from '../../../assets/images/svg/solid/check-solid.svg';
import {ReactComponent as StarSolid} from '../../../assets/images/svg/solid/star-solid.svg';
import {ReactComponent as CheckSquareSolid} from '../../../assets/images/svg/solid/check-square-solid.svg';
import {ReactComponent as MapMarkerAlt} from '../../../assets/images/svg/solid/map-marker-alt-solid.svg';
import {ReactComponent as MobileScreenButton} from '../../../assets/images/svg/solid/mobile-screen-button-solid.svg';
import {ReactComponent as OfficeCaseSolid} from '../../../assets/images/svg/solid/officecase.svg';
import {ReactComponent as ChatBubbleMessageSolid} from '../../../assets/images/svg/solid/chatbubble-message.svg';
import {ReactComponent as GearringSolid} from '../../../assets/images/svg/solid/gearring.svg';
import {ReactComponent as UserGroupSolid} from '../../../assets/images/svg/solid/usergroup.svg';
import {ReactComponent as Plus} from '../../../assets/images/svg/solid/plus.svg';
import {ReactComponent as BookmarkSolid} from '../../../assets/images/svg/solid/bookmark.svg';
import {ReactComponent as SquarePlusSolid} from '../../../assets/images/svg/solid/square-plus-solid.svg';
import {ReactComponent as FilePenSolid} from '../../../assets/images/svg/solid/file-pen.svg';
import {ReactComponent as CameraSolid} from '../../../assets/images/svg/solid/camera.svg';
import {ReactComponent as ImageSold} from '../../../assets/images/svg/solid/images-solid.svg';
import {ReactComponent as DownloadSolid} from '../../../assets/images/svg/solid/download.svg';

//fontawesome light
import {ReactComponent as ImageLight} from '../../../assets/images/svg/light/image.svg';
import {ReactComponent as IdCardLight} from '../../../assets/images/svg/light/id-card-v6.svg';
import {ReactComponent as DiplomaLight} from '../../../assets/images/svg/light/diploma.svg';
import {ReactComponent as AddressBookLight} from '../../../assets/images/svg/light/address-book.svg';
import {ReactComponent as UsersMedicalLight} from '../../../assets/images/svg/light/users-medical.svg';

export const SvgIconSet = ({
  regular: {
    'sign-in': SignInRegular,
    'chevron-right': ChevronRightRegular,
    'dollar-sign': DollarSignRegular,
    'user': UserRegular,
    'map-pin': MapPinRegular,
    'times': TimesRegular,
    'arrow-left': ArrowLeftRegular,
    'search': SearchRegular,
    'arrow-right': ArrowRightRegular,
    'map': MapRegular,
    'industry-alt': IndustryAltRegular,
    'user-hard-hat': UserHardHatRegular,
    'diploma': DiplomaRegular,
    'certificate': CertificateRegular,
    'id-badge': IdBadgeRegular,
    'times-circle': TimesCircleRegular,
    'toolbox': ToolBoxRegular,
    'caret-down': CaretDownRegular,
    'filter': FilterRegular,
    'comment-alt-dots': CommentAltDotsRegular,
    'times-circle-fill': TimesCircleFillRegular,
    'times-circle-dark': TimesCircleDarkRegular,
    'calendar-day': CalendarDayRegular,
    'clock': ClockRegular,
    'file-upload': FileUploadRegular,
    'file-upload-cloud': FileUploadCloudRegular,
    'chevron-down': ChevronDownRegular,
    'building': BuildingRegular,
    'lock': LockRegular,
    'sign-out': SignOutRegular,
    'envelope': EnvelopeRegular,
    'home': NoticeBoardRegular,
    'user-cog': UserCogRegular,
    'retweet-alt': RetweetAltRegular,
    'bell': BellRegular,
    'question': QuestionRegular,
    'plus': PlusRegular,
    'plus-square': PlusSquareRegular,
    'upload': UploadRegular,
    'chevron-up': ChevronUpRegular,
    'comment-alt-lines': CommentAltLinesRegular,
    'wallet': WalletRegular,
    'exclamation-circle': ExclamationCircleRegular,
    'check-circle': CheckCircleRegular,
    'copy': CopyRegular,
    'file-search': FileSearchRegular,
    'megaphone': MegaphoneRegular,
    'clipboard': ClipBoardRegular,
    'heart': HeartRegular,
    'circle': CircleRegular,
    'address-book': AddressBookRegular,
    'star': StarRegular,
    'bookmark': BookmarkRegular,
    'users': UsersRegular,
    'user-plus': UserPlusRegular,
    'users-medical': UsersMedicalRegular,
    'users-class': UsersClassRegular,
    'minus-circle': MinusCircleRegular,
    'square-regular': SquareRegularRegular,
    'comment-alt-check': CommentAltCheckRegular,
    'officecase': OfficeCaseRegular,
    'chatbubble-message': ChatBubbleMessageRegular,
    'gearring': GearringRegular,
    'usergroup': UserGroupRegular,
    'chevondownuser': ChevonDownUser,
    'filteruser': FilterUser,
    'right': RightRegular,
    'triangle-right': TriangleRightRegular,
    'star-regular': StarThinRegular,
    'star-light': StarLightRegular,
    'check-regular': CheckRegular,
    'share': ShareRegular,
    'share-alt': ShareAltRegular,
    'location-dot': LocationDotRegular,
    'plus-circle': PlusCircleRegular,
    'circle-notch': CircleNotchRegular,
    'subtract': SubtractRegular,
    'arrow-circle-up': ArrowCircleUpRegular,
    'map-marker-alt': MapMarkerAltRegular,
    'settings': SettingsRegular,
    'file-pen': FilePenRegular,
    'money-check-dollar': MoneyCheckDollarRegular,
    'pen-field': PenFieldRegular,
    'id-card': IdCardRegular,
    'file-user': FileUserRegular,
    'clipboard-check': ClipboardCheckRegular,
    'message-question': MessageQuestionRegular,
    'shield': ShieldRegular,
    'list-check': ListCheckRegular,
    'bars-progress': BarsProgressRegular,
    'inbox': InboxRegular,
    'wallet-intro': WalletIntroRegular,
    'menu': MenuRegular,
    'camera-card': CameraCard,
    'pdf-file': PdfFile,
    'minus': MinusRegular,
  },
  brands: {
    'linkedin': LinkedinBrands,
    'instagram': InstagramBrands,
    'facebook': FacebookBrands,
    'twitter': TwitterBrands,
  },
  solid: {
    'home': NoticeBoardSolid,
    'user-cog': UserCogSolid,
    'upload': UploadSolid,
    'toolbox': ToolBoxSolid,
    'comment-alt-lines': CommentAltLinesSolid,
    'wallet': WalletSolid,
    'arrow-circle-up': ArrowCircleUpSolid,
    'ellipsis-h': EllipsishSolid,
    'check-circle': CheckCircleSolid,
    'address-book': AddressBookSolid,
    'check-solid': CheckSolid,
    'star-solid': StarSolid,
    'check-square': CheckSquareSolid,
    'map-marker-alt-solid': MapMarkerAlt,
    'mobile-screen-button-solid': MobileScreenButton,
    'officecase': OfficeCaseSolid,
    'chatbubble-message': ChatBubbleMessageSolid,
    'gearring': GearringSolid,
    'usergroup': UserGroupSolid,
    'plusorange': Plus,
    'bookmark': BookmarkSolid,
    'square-plus': SquarePlusSolid,
    'file-pen': FilePenSolid,
    'camera': CameraSolid,
    'image-sold': ImageSold,
    'download': DownloadSolid,
  },
  light: {
    'image': ImageLight,
    'id-card': IdCardLight,
    'diploma': DiplomaLight,
    'address-book': AddressBookLight,
    'users-medical': UsersMedicalLight,
  },
})
