import React from "react"
import {View, ViewStyle} from "react-native"
import {Button, Text, TextField} from "../../../components"
import {color} from "../../../theme"
import {BackdropModal} from "../../../components/backdrop-modal/backdrop-modal";
import {SvgIcon} from "../../../components/icon/svg-icon";

export interface ModalEditProps {
  isVisible: boolean
  onSubmit?: () => void
  onClose?: () => void
  title: string
  label: string
  value: string
  onChange: (value: string) => void
}

export const ModalEdit = (props: ModalEditProps) => {
  const {isVisible, onSubmit, onClose, title, label, value, onChange} = props
  return (
    <BackdropModal preset={'zeroPad'} visible={isVisible} {...(onClose ? {onClose} : null)}>
      <View style={{paddingTop: 20, paddingBottom: 40}}>
        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
          <Text preset={"h3"} text={title} color={color.palette.orange}/>
          <SvgIcon onPress={onClose} icon={'times'} preset={'orange'}/>
        </View>
        <View style={INPUT}>
          <TextField
            value={value} onChangeText={onChange}
            preset={'primary'} labelPreset={"h4"} label={label} placeholder={label}/>
        </View>
        <Button text="Submit" {...(onSubmit ? {onPress: onSubmit} : null)}/>
      </View>
    </BackdropModal>
  )
}

export const INPUT: ViewStyle = {
  paddingVertical: 30,
}
