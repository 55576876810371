import React, {FC, useCallback, useEffect, useState} from "react"
import {
  View,
  SafeAreaView, ScrollView,
} from "react-native"
import {observer} from "mobx-react-lite"
import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {Header, GradientBackground, Text, TextField, Button} from "../../../components"
import {color, spacing} from "../../../theme"
import {INPUT_PLACEHOLDER, SelectTextField} from "../../../components/text-field/select-text-field";
import {useStores} from "../../../models";
import {JobTags} from "../../../models/app/job-advert";
import {generateSearchItem, searchIgnoreCase} from "../../../utils/utils";
import {NavigatorParamList} from "../../../navigators";
import {AlertModalStore} from "../../../models/app/alert-modal-store";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import {isAndroid} from "../../../utils/platform";
import {debounce} from "../../../utils/debounce";
import {IJobLocationModel} from "../../../models/app/location";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";

export interface BusinessLocationsScreenProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
}

export const BusinessLocationsScreen: FC<BusinessLocationsScreenProps> = observer(
  ({navigation}) => {
    const {app} = useStores();
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState("")
    const [data, setData] = useState<Array<IJobLocationModel>>([]);
    const [selectedItems, setSelectedItems] = useState<Array<IJobLocationModel>>([]);

    const loadData = async (text?: string) => {
      if (!text || text?.length < 3) return
      setLoading(true)
      try {
        const res = await app.environment.api.getJobLocation({
          ...(text ? {
            'filters[$or][0][suburb][$containsi]': text,
            'filters[$or][1][state][$containsi]': text,
            'filters[$or][2][postcode][$containsi]': text,
          } : null),
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }
        setData(res?.items)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }
    useEffect(() => {
      loadData()
    }, [])

    const toggleSelectItem = (id) => {
      let _item = data.find((item) => item.id === id)
      setSelectedItems([_item])
    }

    const onDone = async () => {
      const selectedItem: IJobLocationModel = selectedItems?.length > 0 ? selectedItems[0] : null
      if (selectedItem && selectedItem.id) {
        if (app.userAccount.businessProfile?.id) {
          const res = await app.environment.api.updateBusinessProfile(app.userAccount.businessProfile.id, {
            location: selectedItem?.id,
          })
          if (res.kind !== 'ok') {
            AlertModalStore.alert('Error', res.message)
            return
          }
        } else app.setUserBusinessProfile({
          ...app.userAccount.businessProfile, location: selectedItem
        })
      }
      navigation.goBack()
    }

    const delayLoadData = useCallback(debounce(loadData, 600), []);
    const handleChangeSearch = (text: string) => {
      // @ts-ignore
      delayLoadData(text)
      setSearch(text)
    }

    const filteredData = search ? data.filter(item => searchIgnoreCase(item?.name, search)) : data
    const showBtnDone = selectedItems?.length > 0
    return (
      <>
        <PureBottomModal onClose={() => navigation.goBack()}>
          <SafeAreaView style={{flex: 1, backgroundColor: color.palette.white,}}>
            <GradientBackground
              colors={[color.palette.gradientStart, color.palette.gradientStop]}
            />
            <Header
              preset={"modalHeader"}
              titlePreset={"headerTitle"}
              headerText={JobTags.locations.text}
              rightIcon={"times"}
              rightIconPreset={"smallBlue"}
              onRightPress={() => navigation.goBack()}
            />
            <View style={{paddingHorizontal: 20, flex: 1}}>
              <Text preset={"label"}>{JobTags.locations.label}</Text>
              <View style={{marginTop: spacing[5], marginBottom: spacing[2]}}>
                <TextField
                  value={search} onChangeText={handleChangeSearch}
                  labelPreset={"default"}
                  rightIcon={"times-circle-fill"}
                  rightIconPreset={"default"}
                  rightIconPress={() => handleChangeSearch('')}
                />
              </View>
              <ScrollView showsVerticalScrollIndicator={false} style={{flex: 1}}>
                <View style={{marginTop: 16, paddingBottom: 16}}>
                  {data.length > 0 ? (
                    <View>
                      <View style={{marginHorizontal: 16}}>
                        <Text preset={"mediumBlue"}>Results</Text>
                      </View>
                      <View style={{height: 10}}/>
                      <View>
                        {filteredData.map((item, index) => (
                          <SelectTextField
                            key={`${index}-${item.id}`}
                            value={item.name} onPress={() => toggleSelectItem(item.id)}
                            style={{marginBottom: 10}}
                            preset={selectedItems?.find(_it => _it.id === item.id) ? 'itemSelected' : 'transparent'}
                          >
                            <Text
                              style={INPUT_PLACEHOLDER}>{generateSearchItem(item.name, search).map((it, index) => (
                              <Text
                                key={index.toString()}
                                preset={it.highlight ? 'bold' : 'default'}>{it.text}</Text>
                            ))}</Text>
                          </SelectTextField>
                        ))}
                      </View>
                    </View>
                  ) : null}
                </View>
              </ScrollView>
            </View>
            <View style={{paddingHorizontal: 20}}>
              <View
                style={{display: showBtnDone ? 'flex' : 'none', paddingTop: 10, marginBottom: isAndroid ? 10 : 5}}>
                <Button preset={"brand"} onPress={onDone} text={'Done'} disabled={loading}/>
              </View>
            </View>
          </SafeAreaView>
        </PureBottomModal>
        <AlertModal/>
      </>
    )
  },
)

