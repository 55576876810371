import {TextStyle} from "react-native"
import {color} from "../../theme"
import fonts from "../../theme/base_fonts";

/**
 * All text will start off looking like this.
 */
const BASE: TextStyle = {
  ...fonts.type.base(),
}

/**
 * All the variations of text styling within the app.
 *
 * You want to customize these to whatever you need in your app.
 */
export const presets = {
  default: BASE,
  white: {...BASE, color: color.palette.white} as TextStyle,
  whiteBold: {...BASE, color: color.palette.white, fontWeight: 'bold'} as TextStyle,
  bold: {...BASE, fontWeight: "bold"} as TextStyle,
  greyHigh: {...BASE, color: color.palette.greyHigh} as TextStyle,
  header: {...fonts.style.header, fontWeight: "bold"} as TextStyle,
  fieldLabel: {...BASE, fontSize: 13, color: color.dim} as TextStyle,
  secondary: {...BASE, fontSize: 9, color: color.dim} as TextStyle,
  primary: {...BASE} as TextStyle,
  danger: {...BASE, fontSize: 14, fontStyle: 'italic', color: color.palette.red} as TextStyle,
  greySemi: {...fonts.type.semibold(), color: color.palette.grey7} as TextStyle,

  h1: fonts.style.h1,
  h2: fonts.style.h2,
  h3: fonts.style.h3 as TextStyle,
  h4: fonts.style.h4 as TextStyle,
  h4Dark: {
    ...fonts.type.base(fonts.size.h4, color.palette.dark),
    fontWeight: '600'
  } as TextStyle,
  h5: fonts.style.h5 as TextStyle,
  h6: fonts.style.h6 as TextStyle,
  bodyItem: {
    ...fonts.type.base(14),
    color: color.palette.greyHigh
  } as TextStyle,
  body1: fonts.style.body1 as TextStyle,
  body: fonts.style.body,
  body2: fonts.style.body2 as TextStyle,
  button: fonts.style.button as TextStyle,
  caption: fonts.style.caption as TextStyle,
  label: fonts.style.label as TextStyle,
  labelPrimary: {...fonts.style.label, color: color.palette.orange, fontWeight: '600'} as TextStyle,
  medium: fonts.style.medium as TextStyle,
  mediumBlue: fonts.style.mediumBlue as TextStyle,
  mediumLink: fonts.style.mediumLink as TextStyle,
  mediumBold: fonts.style.mediumBold as TextStyle,
  mediumBoldBlue: fonts.style.mediumBoldBlue as TextStyle,
  groupMessageTitle: fonts.style.groupMessageTitle as TextStyle,
  groupMessageSubtitle: fonts.style.groupMessageSubtitle as TextStyle,
  body2Grey: {...fonts.style.body2, color: color.palette.greyDark} as TextStyle,
  bodyGrey: {...fonts.style.body, color: color.palette.greyHigh} as TextStyle,
  userMessageSubtitle: fonts.style.userMessageSubtitle as TextStyle,
  smallPrimary: fonts.style.smallPrimary as TextStyle,
  small: {
    ...fonts.type.base(13, color.palette.blue),
  } as TextStyle,
  smallPrimaryBold: fonts.style.smallPrimaryBold as TextStyle,
  smallSemiBold: fonts.style.smallSemiBold as TextStyle,
  smallBold: fonts.style.smallBold as TextStyle,
  mediumTitle: fonts.style.body as TextStyle,
  alertTitle: fonts.style.alertTitle as TextStyle,
  alertBody: {...fonts.type.semibold(16), color: color.palette.greyLink} as TextStyle,
  tabBarLabel: fonts.style.tabBarLabel as TextStyle,
  iconButton: fonts.style.iconButton as TextStyle,
  bigIconButton: fonts.style.bigIconButton as TextStyle,
  settingText: fonts.style.settingText as TextStyle,
  settingTitle: fonts.style.settingTitle as TextStyle,
  headerTitle: {
    ...fonts.type.semibold(fonts.size.h1, color.palette.blue),
  } as TextStyle,
  headerTitleDark: {
    ...fonts.type.semibold(fonts.size.h1),
  } as TextStyle,
  boldLabel: fonts.style.boldLabel as TextStyle,
  underTextFieldButton: fonts.style.underTextFieldButton as TextStyle,
  tabActive: fonts.style.tabActive as TextStyle,
  tabInActive: fonts.style.tabInActive as TextStyle,
  messageTitle: {...fonts.type.base(15, color.palette.black), fontWeight: 'bold'} as TextStyle,
  messageTitleLink: {...fonts.type.base(15, color.palette.blue), fontWeight: 'bold'} as TextStyle,
  h1Blue: {
    ...fonts.type.bold(fonts.size.h1, color.palette.blue),
  } as TextStyle,
  input: fonts.style.input as TextStyle,
  total: {...fonts.type.base(14, color.palette.greyInputBorder),} as TextStyle,
  posted: {...fonts.type.base(12, color.palette.greyInputBorder),} as TextStyle,
  dark: {...fonts.type.base(16, color.palette.dark), fontWeight: "bold",} as TextStyle,
  cardTitleDate: {...fonts.style.body2, color: color.palette.greyDark} as TextStyle,
  chipCardTitle: {...fonts.type.semibold(), color: color.palette.blue} as TextStyle,
  suggestBold: {...BASE, fontSize: 18, fontWeight: "bold"} as TextStyle,
  suggestDefault: {...BASE, fontSize: 18, fontWeight: "600", color: color.palette.greyHigh} as TextStyle,
  formLabel: {...fonts.type.base(15, color.palette.dark), fontWeight: "600"} as TextStyle,
  textName: {...BASE, fontSize: 18, fontWeight: "400", color: color.palette.black00} as TextStyle,
  textNotify: {...BASE, fontSize: 14, fontWeight: "600", color: color.palette.red} as TextStyle,
  textTags: {fontSize: 11, color: color.palette.primary, fontWeight: '700'},
  titleFile: {fontSize: 14, color: color.palette.black00, fontWeight: '700'},
  placeholder: {fontSize: 14, color: color.palette.greyC4, fontWeight: '400'}
}

/**
 * A list of preset names.
 */
export type TextPresets = keyof typeof presets
