import {BottomModal} from "../../../components/backdrop-modal/bottom-modal"
import {isAndroid} from "../../../utils/platform"
import {Dimensions, SafeAreaView, ScrollView, StyleSheet, TouchableOpacity, View} from "react-native"
import {Button, Header, Text} from "../../../components"
import React, {useEffect, useState} from "react"
import {useSafeAreaInsets} from "react-native-safe-area-context"
import {SvgIcon} from "../../../components/icon/svg-icon"
import {BackdropModal} from "../../../components/backdrop-modal/backdrop-modal"
import {AlertModalStore} from "../../../models/app/alert-modal-store"
import {useStores} from "../../../models"
import {LoadingModal} from "../../../components/backdrop-modal/loading-modal"
import {IBusinessContactModel, IBusinessContactModelCheck} from "../../../models/app/contact"
import UserCard from "../../../components/card/user-card"
import {listToStr} from "../../../utils/utils";
import { constants } from "../../../utils/constants";

const windowHeight = Dimensions.get("window").height

interface Props {
  isOpen: boolean;
  onClose: (isRecall?: boolean, isGoBack?: boolean) => void;
  data: IBusinessContactModel[];
  nameGroup: string;
  idBulk: number;
}


const BulkMessageContactsDelete = ({isOpen, onClose, data, nameGroup, idBulk}: Props) => {
  const {app} = useStores();
  const insets = useSafeAreaInsets()
  const [isCheckAll, setCheckAll] = useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false)
  const [contacts, setContacts] = useState<IBusinessContactModelCheck[]>([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    handleInitData(false);
  }, [data])

  const handleInitData = (val: boolean) => {
    const newData = data.map((item) => {
      return ({
        ...item,
        checked: val
      })
    })
    setContacts(newData)
  }

  const toggleSelectItem = (val) => {
    const newData = contacts.map((item) => {
      return ({
        ...item,
        checked: item.id === val.id ? !item?.checked : item?.checked
      })
    })
    setContacts(newData)
  }

  const handleCheckAll = () => {
    handleInitData(!isCheckAll)
    setCheckAll(!isCheckAll)
  }

  const handleDeleteBulk = async () => {
    try {
      setShowModalConfirm(false)
      setLoading(true)
      const ids = contacts?.filter(it => it.checked)?.map(it => it.id)
      const noChecked = contacts?.filter(it => !it.checked)?.map(it => it.id)
      const isManager = app?.user?.role === constants.roles.MANAGER
      const res = await app.environment.api.deleteGroupContacts(
        idBulk, {
          contactIds: ids.join(','),
          businessProfileId: isManager
            ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
        }
      )
      if (res.kind !== 'ok') {
        AlertModalStore.alert('Error', res.message)
        return
      } else {
        if (noChecked.length === 0) {
          onClose(true, true);
        } else {
          onClose(true);
        }
      }
    } catch (e) {
      AlertModalStore.alert('Error', e?.toString())
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const isValid = contacts?.filter(it => it?.checked)?.length > 0

  return (
    <BottomModal
      style={{
        paddingTop: 20, paddingHorizontal: 10,
        height: windowHeight - insets.top - (isAndroid ? 10 : 10),
      }}
      visible={isOpen}
      onClose={onClose}
    >
      <SafeAreaView style={{flex: 1}}>
        <Header
          leftIcon="arrow-left"
          leftIconPreset='normal'
          onLeftPress={onClose}
          preset={"transparent"}
          titlePreset={"headerTitle"}
          headerText={nameGroup}
        />
        <TouchableOpacity style={styles.wrapperTitle} onPress={handleCheckAll}>
          <Text preset="label" style={{color: '#aaaaaa'}}>
            Select Contacts to Delete
          </Text>
          <View style={styles.bgCheckAll}>
            <SvgIcon
              preset={isCheckAll ? 'smallBlue20' : 'square'}
              icon={isCheckAll ? 'check-square' : 'square-regular'}
              theme={isCheckAll ? 'solid' : 'regular'}
            />
            <Text preset="label" style={{color: '#aaaaaa'}}>
              All
            </Text>
          </View>
        </TouchableOpacity>
        <View style={{flex: 1, justifyContent: "space-between", paddingHorizontal: 10}}>
          <View style={{flex: 1, paddingTop: 20}}>
            <ScrollView
              showsVerticalScrollIndicator={false}>
              {contacts.map((item, index) => {
                const fullName = listToStr([item?.user?.firstName, item?.user?.lastName])
                const avatar = `${item?.user?.avatar?.url}`
                return (
                  <UserCard
                    key={`${index}-${item.id}`}
                    onClick={() => toggleSelectItem(item)}
                    isCheck={item.checked}
                    name={fullName || item?.user?.email}
                    avatar={avatar}
                  />
                )
              })}
            </ScrollView>
          </View>
          <View style={{flexDirection: "row", justifyContent: "space-between", paddingTop: 20}}>
            <Button
              disabled={!isValid}
              preset={isValid ? 'brand' : 'disabled'}
              onPress={() => setShowModalConfirm(true)}
              style={{flex: 1}}
              text={"Delete Message(s)"}
            />
          </View>
        </View>
      </SafeAreaView>
      <BackdropModal visible={showModalConfirm} onClose={() => setShowModalConfirm(false)}>
        <View>
          <View style={{
            alignItems: "center",
            justifyContent: 'center',
            padding: 10,
          }}>
            <SvgIcon iconStyle={{width: 80, height: 80}} preset={'alertIcon'}/>
            <View style={{paddingTop: 18, paddingBottom: 8}}>
              <Text preset={'alertTitle'}>Are you sure?</Text>
            </View>
          </View>
          <Button
            style={styles.modalBtn}
            preset='brand'
            text={'Yes'}
            onPress={handleDeleteBulk}
          />
          <Button
            onPress={() => setShowModalConfirm(false)}
            style={styles.modalBtn}
            preset={'destructive'}
            text={'Cancel'}
          />
        </View>
      </BackdropModal>
      <LoadingModal visible={loading}/>
    </BottomModal>
  )
};

export default BulkMessageContactsDelete;

const styles = StyleSheet.create({
  bgCheckAll: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapperTitle: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: "center",
    paddingHorizontal: 10,
    marginTop: 20,
  },
  modalBtn: {
    marginVertical: 8
  },
});
