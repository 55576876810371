import * as React from "react"
import { TouchableOpacity, TouchableOpacityProps, View, ViewStyle } from "react-native";
import { Text } from "../text/text";
import { SvgIcon } from "../icon/svg-icon";
import { IUserMessageModel } from "../../models/app/message";
import { color } from "../../theme";

export interface GroupMessageProps extends TouchableOpacityProps {
  isWorker?: boolean;
  item?: IUserMessageModel
  typeCheck?: boolean
  isCheck?: boolean
  onPress?: () => void
  dot?: boolean
}

export const GroupMessage = (props: GroupMessageProps) => {
  const {isWorker, item, typeCheck, isCheck, dot, ...rest} = props

  return (
    <TouchableOpacity
      style={{
        flexDirection: 'row',
        paddingTop: 4,
        paddingBottom: 20,
        alignItems: 'center',
        paddingHorizontal: 15
      }}
      {...rest}
    >
      <View style={{flex: 1}}>
        <View style={{flex: 1, flexDirection: 'row'}}>
          <Text preset={'groupMessageTitle'} {...rest}>{isWorker ? item.title : item?.group?.name}</Text>
          {dot && <View style={DOT_STYLE}/>}
        </View>
        <View style={{marginTop: 8}}>
          <Text preset={'groupMessageSubtitle'}
                numberOfLines={1}>{isWorker ? item?.createdUser?.businessProfile?.name : item?.title}</Text>
        </View>
      </View>
      {typeCheck ?
        <View>
          <SvgIcon
            preset={isCheck ? 'smallBlue20' : 'square'}
            icon={isCheck ? 'check-square' : 'square-regular'}
            theme={isCheck ? 'solid' : 'regular'}
          />
        </View>
        :
        <SvgIcon icon='chevron-right'/>}
    </TouchableOpacity>
  )
}

const DOT_STYLE: ViewStyle = {
  width: 10, height: 10,
  backgroundColor: color.palette.red,
  borderRadius: 10, borderWidth: 2, borderColor: color.palette.white,
  marginBottom: 0, marginLeft: 2,
}
