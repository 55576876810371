// import {createClient} from '@segment/analytics-react-native';
// import {SegmentClient} from "@segment/analytics-react-native/lib/typescript/src/analytics";
import segmentAnalytics from "react-segment";
import mixpanel from 'mixpanel-browser';
import {initializeApp} from "firebase/app";
import {getAnalytics, logEvent} from "firebase/analytics";

import {IUserAccountModel} from "../models/app/user-account";
import {getCountryCallingCode} from "react-phone-number-input";

interface SegmentClient {
  track: any
  identify: any
}

export const TrackEvents = {
  SignedUp: 'Signed up',
  SignedIn: 'Signed in',
  Industry: 'Industry',
  SetupWorkerProfile: 'Setup Worker Profile',
  SetupBusinessProfile: 'Setup Business Profile',
  CreateBusinessProfile: 'Create Business Profile',
  AppliedForJob: 'Applied for job',
  SearchedForJobs: 'Searched for jobs',
  SetupJobAd: 'Setup Job Ad',
  PostedJobAd: 'Posted Job Ad',
  WalletSetup: 'Wallet setup',
  NewMessage: 'New Message ',
  NewApplication: 'New Application',
  NewJobs: 'New Jobs ',
  DeleteApp: 'Delete app',
  DeleteProfile: 'Delete profile',
  DeleteJobAd: 'Delete Job Ad',
  HiredWorker: 'Hired worker',
  GotHired: 'Got hired',
  SentMessage: 'Sent message',
  ViewedJobAd: 'Viewed job ad',
  CreatedGroup: 'Created group',
  SentGroupMessage: 'Sent group message',
}
type ITrack = {
  segmentClient: SegmentClient
  mixpanel: any
  analytics: any
}
export const Track: ITrack = {
  segmentClient: null,
  mixpanel: null,
  analytics: null,
}

export function setupSegmentClient() {
  segmentAnalytics.default.load(process.env.REACT_APP_SEGMENT_CLIENT_KEY)
  Track.segmentClient = global.analytics
  Track.mixpanel = mixpanel
  Track.mixpanel.init(process.env.REACT_APP_MIXPANEL_CLIENT_KEY, {debug: true});
  const firebaseConfig = {
    apiKey: "AIzaSyAvGkDRkUYQZYVfL3OfK-5m2PClWMJiuUw",
    authDomain: "tap4work-ad168.firebaseapp.com",
    projectId: "tap4work-ad168",
    storageBucket: "tap4work-ad168.appspot.com",
    messagingSenderId: "288881241896",
    appId: "1:288881241896:web:9c408e9f38f3e60372aa1d",
    measurementId: "G-G0X55M0FNX"
  };

  const firebaseApp = initializeApp(firebaseConfig);
// const firebaseAnalytics = getAnalytics(firebaseApp);
  Track.analytics = getAnalytics(firebaseApp);
}

export const identifyWithUserAccount = async (event: string, userAccount: IUserAccountModel) => {
  let callingCode = ''
  try {
    callingCode = userAccount?.countryCode ? getCountryCallingCode(userAccount?.countryCode as any) : ''
  } catch (e) {
    console.log('TRACKE', e)
  }
  // await Track.segmentClient?.identify(String(userAccount?.id), {
  //   email: userAccount?.email,
  //   event: event,
  //   name: event,
  //   firstName: userAccount?.firstName,
  //   lastName: userAccount?.lastName,
  //   businessName: userAccount?.businessProfile?.name,
  //   businessId: userAccount?.businessProfile?.id,
  //   workerId: userAccount?.workerProfile?.id,
  //   phone: ['+', callingCode, userAccount?.mobile || ''].join(''),
  // })

  await trackWithUserAccount(event, userAccount)
  fetch(`${process.env.REACT_APP_AC_CLIENT_HOST}/contact/sync`, {
    method: 'POST',
    headers: {
      "Api-Token": process.env.REACT_APP_AC_CLIENT_TOKEN, "Content-Type": "text/application/json",
      mode: 'cors',
    },
    body: JSON.stringify({
      contact: {
        email: userAccount?.email,
        firstName: userAccount?.firstName,
        lastName: userAccount?.lastName,
        phone: ['+', callingCode, userAccount?.mobile || ''].join(''),
        fieldValues: [
          {field: "1", value: userAccount?.businessProfile?.name},
          {field: "2", value: userAccount?.businessProfile?.id},
          {field: "3", value: userAccount?.workerProfile?.id},
          {field: "7", value: 'Web'},
        ]
      }
    }),
  })
    .then(response => response.text())
    .then(result => console.log('ACI ', result))
    .catch(error => console.log('ACEI error', error));
}

export const trackWithUserAccount = async (event: string, userAccount: IUserAccountModel, extraOptions?: any) => {
  // await Track.segmentClient?.track(event, {
  //   email: userAccount?.email, ...extraOptions
  // })
  Track.mixpanel?.track(event, {
    email: userAccount?.email, ...extraOptions
  })
  await logEvent(Track.analytics, event?.toLowerCase()?.split(' ')?.join('_'), {
    email: userAccount?.email, ...extraOptions
  })

  const formdata = new FormData();
  formdata.append("actid", process.env.REACT_APP_AC_CLIENT_ACT_ID);
  formdata.append("key", process.env.REACT_APP_AC_CLIENT_KEY);
  formdata.append("event", event);
  formdata.append("visit", JSON.stringify({email: userAccount?.email}));
  formdata.append("eventdata", "");
  await fetch("https://trackcmp.net/event", {
    method: 'POST', body: formdata, headers: {mode: 'cors',}
  })
    .then(response => response.text())
    .then(result => console.log('ACE ', result))
    .catch(error => console.log('ACE error', error));

  const dateTs = new Date().getTime()
  await fetch(`https://graph.facebook.com/v14.0/${process.env.REACT_APP_FB_CLIENT_APP_ID}/activities`, {
    method: 'POST', headers: {"Content-Type": "application/json",},
    body: JSON.stringify({
      advertiser_id: "B5372DB0-C21E-11E4-8DFC-AA07A5B093DB",
      advertiser_tracking_enabled: 0,
      application_tracking_enabled: 0,
      custom_events: [
        {_eventName: event, _logTime: dateTs - dateTs % 1000, fb_currency: "USD", email: userAccount?.email}
      ],
      data_processing_options: [],
      event: "CUSTOM_APP_EVENTS",
      extinfo: [
        "i2", "", "", "", "8.1.3", "", "", "", "", 0, 0, "0.0", 0, 0, 0, ""
      ]
    })
  })
    .then(response => response.text())
    .then(result => console.log('FBAE', result))
    .catch(error => console.log('FBAE error', error));
}
