import React, {FC, useEffect} from "react"
import {View, StyleSheet, SafeAreaView, ScrollView, TouchableOpacity, Image} from "react-native"

import {NativeStackNavigationProp} from "@react-navigation/native-stack"
import {observer} from "mobx-react-lite"
import {color, spacing} from "../../../theme"
import {Button, GradientBackground, Header, Text} from "../../../components"
import {SvgIcon} from "../../../components/icon/svg-icon"
import {ListJobTag} from "../../../components/list-div/list-job-tag"
import {useStores} from "../../../models"
import {IKvItemModel} from "../../../models/app/kv-item";
import {IJobTagGroup, JobTags, JobTagType} from "../../../models/app/job-advert";
import {NavigatorParamList} from "../../../navigators";
import {RouteProp} from "@react-navigation/native";
import {PureBottomModal} from "../../../components/backdrop-modal/pure-bottom-modal";
import {AlertModal} from "../../../components/backdrop-modal/alert-modal";

export interface JobFilterScreenProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
  route: RouteProp<NavigatorParamList, "jobFilterScreen">
}

export interface FilterGroupCardProps {
  item: IJobTagGroup
  headerPress: (item: IJobTagGroup) => void
}

const FilterGroupCard: FC<FilterGroupCardProps> = observer(({item, headerPress}) => {
  const {app: {filterJob}} = useStores()
  let items: Array<IKvItemModel> = filterJob[item.id]?.toJSON()
  const _headerPress = () => headerPress(item)

  return (
    <View style={{marginBottom: 18}}>
      <ListJobTag
        header={
          <View style={styles.require}>
            <View style={{flex: 1, marginHorizontal: 20, marginVertical: 20}}>
              <Text preset={"mediumBoldBlue"}>{item.text}</Text>
            </View>
            <TouchableOpacity
              onPress={_headerPress}
              style={{marginRight: 15}}
            >
              <View style={{backgroundColor: color.palette.orange, borderRadius: 30, padding: 1}}>
                <SvgIcon preset={'tinyLight'} icon="plus" style={{padding: spacing[1]}}/>
              </View>
            </TouchableOpacity>
          </View>
        }
        data={items}
        itemValue={(_item) => _item.name}
        renderIcon={() => ({icon: "times-circle", theme: "regular", preset: "default"})}
        onPress={(id) => filterJob.toggleSelectRoleIndex(item.id, id)}
      />
    </View>
  )
})

export const JobFilterScreen: FC<JobFilterScreenProps> = observer(
  ({navigation, route}) => {
    const nextScreen = route.params?.nextScreen
    const replace = route.params?.replace
    const {app} = useStores()
    // const {filterJob} = app

    const loadData = async () => {
    }

    useEffect(() => {
      loadData()
    }, [])

    const renderRoleGroup = (key: string) => (
      <FilterGroupCard
        headerPress={() => navigation.navigate("jobFilterDetailScreen", {tag: key as JobTagType})}
        key={key} item={JobTags[key]}/>
    )

    const handleFindJob = async () => {
      await app.setHasFilterJob(true)
      if (nextScreen) {
        navigation.goBack()
        if (replace) navigation.replace(nextScreen as keyof NavigatorParamList)
        else navigation.navigate(nextScreen as keyof NavigatorParamList)
      } else navigation.goBack()
    }

    return (
      <>
        <PureBottomModal onClose={() => navigation.goBack()}>
          <SafeAreaView style={styles.container}>
            <GradientBackground
              colors={[color.palette.gradientStart, color.palette.gradientStop]}
            />
            <Header
              preset={"modalHeader"}
              header={() => (
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <Image
                    source={require('../../../../assets/images/search-icon.png')}
                    resizeMode={'contain'}
                    style={{width: 20, height: 20, marginRight: 10}}
                  />
                  <Text preset={'headerTitle'}>Search for Job</Text>
                </View>
              )}
              rightIcon={"times"}
              rightIconPreset={"smallBlue"}
              onRightPress={() => navigation.goBack()}
            />
            <ScrollView style={styles.body} showsVerticalScrollIndicator={false}>
              <View style={{marginTop: 20, marginBottom: 100, marginHorizontal: 10}}>
                {Object.keys(JobTags).map(renderRoleGroup)}
              </View>
            </ScrollView>
            <View style={styles.bottomContainer}>
              <Button onPress={handleFindJob} preset={"brand"}>
                <Text preset={"whiteBold"}>Find</Text>
              </Button>
            </View>
          </SafeAreaView>
        </PureBottomModal>
        <AlertModal/>
      </>
    )
  },
)

const styles = StyleSheet.create({
  statusBar: {
    backgroundColor: color.palette.white,
  },
  container: {
    flex: 1,
    backgroundColor: color.palette.white,
  },
  mainContainer: {
    backgroundColor: color.palette.white,
  },
  contentContainerStyle: {
    paddingHorizontal: 10,
    marginVertical: 20,
  },
  modalBtn: {
    marginVertical: 8,
  },
  body: {
    flex: 1,
    paddingHorizontal: 10,
    backgroundColor: color.palette.white,
  },
  bottomContainer: {
    position: "absolute",
    bottom: 0,
    paddingTop: 16,
    paddingBottom: 16,
    paddingHorizontal: 20,
    width: "100%",
    borderTopColor: color.palette.blueLine,
    borderTopWidth: 2,
    backgroundColor: color.palette.white,
  },
  require: {
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 6,
    flexDirection: "row"
  },
})
