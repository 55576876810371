import * as React from "react"
import {
  TouchableOpacity,
  StyleSheet, View
} from "react-native";
import { ImageModal } from "../backdrop-modal/image-modal"
import { useState } from "react"
import {SvgIcon} from "../icon/svg-icon";

interface Props{
  url: string;
  name?: string;
  isClose?: boolean;
  onClose?: () => void;
  styleCustom?: any;
  isInput?: boolean;
};
export const MessageImage = ({url, name, isClose, onClose, styleCustom, isInput}: Props) => {

  const [isShowModal, setShowModal] = useState(false);

  const clickImg = () => {
    setShowModal(true);
  }

  return (
      <View style={[styles.wrapper, isInput && {width: 100}]}>
      {isClose &&  <TouchableOpacity style={styles.btnClose} onPress={onClose}>
        <SvgIcon icon="times" preset="lightMd"/>
      </TouchableOpacity>}
      <TouchableOpacity onPress={clickImg} style={styleCustom}>
        <img alt={name} style={{width: 100, height: 100, borderRadius: 10}} src={url} />
      </TouchableOpacity>
      {isShowModal && <ImageModal url={url} visible={isShowModal} onClose={() => setShowModal(false)} />}
    </View>
  )
}

const styles = StyleSheet.create({
  btnClose:{
    backgroundColor: 'rgba(0,0,0,0.76)',
    borderRadius: 8,
    right: 0,
    position: 'absolute',
    top: 15,
    zIndex: 99,
  },
  wrapper:{
    position: 'relative',
  }
});
