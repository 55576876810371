import React, { FC, useCallback, useState } from 'react';
import { View, FlatList, StyleSheet, SafeAreaView, TouchableOpacity } from 'react-native';

import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useFocusEffect } from "@react-navigation/native";
import { observer } from "mobx-react-lite";
import { ContactSelectItem } from "./contacts-item";
import { useStores } from "../../../models";
import { CONTACT_STATUS, IBusinessContactModel, IBusinessContactModelCheck } from "../../../models/app/contact";
import { constants, DEFAULT_LIMIT } from "../../../utils/constants";
import { NavigatorParamList } from "../../../navigators";
import { Button, Header, Text } from "../../../components";
import { PureBottomModal } from "../../../components/backdrop-modal/pure-bottom-modal";
import { SvgIcon } from "../../../components/icon/svg-icon";
import { BottomContainer } from "../../../components/bottom-container/bottom-container";
import { color } from "../../../theme";
import { BackdropModal } from "../../../components/backdrop-modal/backdrop-modal";
import { LoadingModal } from "../../../components/backdrop-modal/loading-modal";
import { AlertModalStore } from "../../../models/app/alert-modal-store";
import { AlertModal } from "../../../components/backdrop-modal/alert-modal";

export interface DeleteContactScreenProps {
  navigation: NativeStackNavigationProp<NavigatorParamList>
}

export const DeleteContactScreen: FC<DeleteContactScreenProps> = observer(
  ({navigation}) => {
    const {app} = useStores();
    const [loading, setLoading] = useState(false)
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [listItem, setListItem] = useState<Array<IBusinessContactModelCheck>>([]);
    const [refreshing, setRefreshing] = useState(false)
    const [checked, setChecked] = useState(false)

    const loadData = async () => {
      try {
        setRefreshing(true)
        const isManager = app?.user?.role === constants.roles.MANAGER
        const res = await app.environment.api.getContacts({
          'filters[businessProfile][id][$eq]': isManager
            ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          'businessProfileId': isManager
            ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          'filters[status][$ne]': CONTACT_STATUS.rejected.key,
          "pagination[pageSize]": DEFAULT_LIMIT
        })
        if (res.kind === 'ok') {
          setListItem(res.items.map(item => ({...item, checked: false})))
        }
      } catch (e) {
        console.log(e)
      } finally {
        setRefreshing(false)
      }
    }

    useFocusEffect(useCallback(() => {
      loadData()
    }, []))

    const onCheckAll = () => {
      setListItem(listItem.map((it) => ({...it, checked: !checked})))
      setChecked(!checked)
    }

    const toggleSelectItem = (item: IBusinessContactModel) => {
      setListItem(listItem.map((it) => it?.id === item?.id ? {...it, checked: !it.checked} : it))
    }

    const handleRemoveContact = () => {
      setConfirmVisible(true)
    }

    const onRemoveContact = async () => {
      try {
        setConfirmVisible(false)
        setLoading(true)

        const isManager = app?.user?.role === constants.roles.MANAGER
        const res = await app.environment.api.deleteContacts({
          'businessProfileId': isManager ? app?.user?.businessProfile?.id : app?.userAccount?.businessProfile?.id,
          ids: listItem?.filter(it => it.checked)?.map(it => it.id)
        })
        if (res.kind !== 'ok') {
          AlertModalStore.alert('Error', res.message)
          return
        }

        navigation.goBack()
      } catch (e) {
        AlertModalStore.alert('Error', e?.toString())
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    // const renderItem = ({item}) => <ContactItem item={item} navigation={navigation}/>
    const renderItem = ({item}: { item: IBusinessContactModelCheck }) => (
      <ContactSelectItem
        onItemPress={toggleSelectItem} checked={item?.checked} item={item}/>
    )

    const isValid = listItem?.filter(it => it?.checked)?.length > 0
    return (
      <>
        <PureBottomModal onClose={() => navigation.goBack()}>
          <SafeAreaView style={styles.container}>
            <Header
              preset={'modalHeader'}
              headerText={'Delete New Contact(s)'}
              titlePreset={'headerTitle'}
              rightIcon={'times'} rightIconPreset={'smallBlue'} onRightPress={() => navigation.goBack()}
            />
            <View style={{paddingHorizontal: 10}}>
              <TouchableOpacity
                onPress={onCheckAll}>
                <View style={{
                  paddingHorizontal: 10, marginBottom: 20,
                  flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'
                }}>
                  <Text preset="textName" style={{fontWeight: '700'}}>Contact List</Text>
                  <View style={{alignItems: 'center'}}>
                    <SvgIcon
                      icon={checked ? 'check-square' : 'square-regular'}
                      theme={checked ? 'solid' : 'regular'} preset={'primary'}
                    />
                    <View style={{marginTop: 4}}>
                      <Text preset="small" style={{color: color.palette.primary}}>All</Text>
                    </View>
                  </View>
                </View>
              </TouchableOpacity>
              <FlatList
                showsVerticalScrollIndicator={false}
                contentContainerStyle={styles.contentContainerStyle}
                onRefresh={loadData} refreshing={refreshing}
                data={listItem}
                renderItem={renderItem}
                keyExtractor={(item, index) => index.toString()}
                ListFooterComponent={<View style={{height: 200}}/>}
              />
            </View>
            <BottomContainer backgroundColor={color.palette.grey}>
              <Button onPress={handleRemoveContact} disabled={!isValid} preset={isValid ? 'brand' : 'disabled'}>
                <Text preset={'whiteBold'}>Remove Contact(s)</Text>
              </Button>
            </BottomContainer>
          </SafeAreaView>
        </PureBottomModal>
        <BackdropModal visible={confirmVisible} onClose={() => setConfirmVisible(false)}>
          <View>
            <View style={{alignItems: "center", justifyContent: 'center', padding: 10,}}>
              <SvgIcon preset={'alertIcon'}/>
              <View style={{paddingTop: 18, paddingBottom: 8}}>
                <Text preset={'alertTitle'}>Are you sure?</Text>
              </View>
            </View>
            <Button
              onPress={onRemoveContact}
              style={styles.modalBtn}
              disabled={false}
              preset={'brand'}
              text={'Yes'}
            />
            <Button
              onPress={() => setConfirmVisible(false)}
              style={{marginTop: 8}}
              disabled={false}
              preset={'destructive'}
              text={'Cancel'}
            />
          </View>
        </BackdropModal>
        <LoadingModal visible={loading}/>
        <AlertModal/>
      </>
    )
  },
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainerStyle: {
    paddingHorizontal: 10,
  },
  modalBtn: {
    marginVertical: 8
  },
});
